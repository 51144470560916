import { useMutation } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';
import { Form as AntForm, Result, Typography, Alert } from 'antd';
import { NuButton } from 'components/nuspire';
import { NuspireIcon } from 'components/nuspire/nu-icon';
import { FormikInput } from 'components/shared-components';
import baseTheme from 'components/theme';
import { Formik } from 'formik';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import useSearchParams from 'utils/react-hooks/useSearchParams';
import * as yup from 'yup';
import { MAKE_CONNECTION_MUTATION } from '..';
import connector from './qualys-connector-definition';

const validationSchema = yup.object().shape({
  name: yup.string().required(), // name of connection
  qualysApiServerUrl: yup.string().url().required(),
  username: yup.string().required(),
  password: yup.string().required(),
});

type AuthFormFields = {
  qualysApiServerUrl: string;
  name: string;
  username: string;
  password: string;
  testConnection: boolean;
};

interface QualysAuthFormProps {
  onSubmit: (args: {
    values: AuthFormFields;
    setSubmitting: (isSubmitting: boolean) => void;
    setError: (value: string) => void;
  }) => Promise<void>;
}
function QualysAuthForm(props: QualysAuthFormProps) {
  const { onSubmit } = props;
  const [error, setError] = useState<string | undefined>();

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        qualysApiServerUrl: '',
        name: '',
        username: '',
        password: '',
        testConnection: true,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit({ values, setSubmitting, setError });
      }}
    >
      {({ submitForm, isSubmitting, errors, dirty }) => (
        <AntForm layout="vertical" onFinish={() => submitForm()}>
          {error ? (
            <AntForm.Item>
              <Alert type="error" message={error} />
            </AntForm.Item>
          ) : null}

          <FormikInput
            name="name"
            label="Connection Name"
            required
            tooltip="This will be used in myNuspire when referencing this Connection"
          />

          <FormikInput
            name="qualysApiServerUrl"
            label="Qualys Api Server Url"
            required
            tooltip="The Qualys API URL you should use for API requests depends on the Qualys platform
            where your account is located."
          />

          <FormikInput name="username" label="Username" required />
          <FormikInput name="password" label="Password" required type="password" />

          <FormikInput name="testConnection" checkbox>
            Test credentials before creation
          </FormikInput>
          <NuButton
            type="primary"
            htmlType="submit"
            disabled={!dirty || Object.keys(errors).length > 0 || isSubmitting}
            loading={isSubmitting}
          >
            Submit
          </NuButton>
        </AntForm>
      )}
    </Formik>
  );
}

const RedirectLayout = styled.div``;
const RedirectHeader = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid ${(p) => p.theme.token.colorBorder};
`;
const RedirectContent = styled.div`
  padding: 16px 32px;
`;

function QualysRedirect() {
  const { parsed: search } = useSearchParams();

  const { authState: oktaAuthState } = useOktaAuth();

  const [makeConnection] = useMutation(MAKE_CONNECTION_MUTATION);
  const [success, setSuccess] = useState<boolean>(false);

  if (!oktaAuthState?.isAuthenticated) {
    return <Navigate to="/log-in" replace />;
  }
  // render form

  return (
    <RedirectLayout>
      <RedirectHeader>
        <Typography.Title style={{ marginBottom: 0 }} level={2}>
          <NuspireIcon style={{ color: baseTheme.color.primaryBlue, marginRight: '8px' }} />
          Connect to Qualys Account
        </Typography.Title>
      </RedirectHeader>

      <RedirectContent>
        {!success ? (
          <QualysAuthForm
            onSubmit={async ({ values, setSubmitting, setError }) => {
              const { testConnection, ...payload } = values;

              const { data, errors } = await makeConnection({
                variables: {
                  connectorSlug: connector.slug,
                  payloadJson: JSON.stringify(payload),
                  clientId: search?.clientId,
                  testConnection,
                },
              });

              setSubmitting(false);

              if (errors?.length) {
                setError(errors[0].message);
                return;
              }

              const newConnection = data?.makeConnection;

              if (newConnection && !errors) {
                const newEvent = new CustomEvent(`new-connection-${connector.slug}`, {
                  detail: {
                    connection: newConnection,
                  },
                });

                window?.opener?.dispatchEvent(newEvent);

                setSuccess(true);
              }
            }}
          />
        ) : (
          <Result status="success" title="Connection was successfully created!" />
        )}
      </RedirectContent>
    </RedirectLayout>
  );
}

export default QualysRedirect;
