import React from 'react';
import { Select } from 'antd';
import { ActionInputProps } from '../../action-form-field';

const firewallTypes = [
  'barracuda',
  'catoNetworks',
  'checkpoint',
  'ciscoAsa',
  'ciscoMeraki',
  'fortigate',
  'juniper',
  'paloAlto',
  'sonicWall',
  'sophos',
  'watchGuard',
];

const SocReviewFirewallTypesSelect = (props: ActionInputProps) => {
  const { value, onChange } = props;

  return (
    <Select value={value} onChange={(values) => onChange(values)} mode="multiple">
      {firewallTypes.map((f) => (
        <Select.Option key={f} value={f}>
          {f}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SocReviewFirewallTypesSelect;
