export function encodeOAuthState(state: { [key: string]: string | number | null | undefined }) {
  const keyValuePairs = Object.keys(state).reduce((acc: string[], key) => {
    const value = state[key];

    if (value !== undefined && value !== null) {
      const keyValuePair = `${key}:${value}`;

      acc.push(keyValuePair);
    }

    return acc;
  }, []);

  const keyValueString = keyValuePairs.join(',');

  console.log('ENCODE', { keyValueString });

  return encodeURIComponent(keyValueString);
}

export function decodeOAuthState(state: string | null) {
  if (!state) {
    return {};
  }

  const keyValuePairs = state.split(',');

  const decoded = keyValuePairs.reduce((acc: { [key: string]: string }, pairString) => {
    const [key, val] = pairString.split(':');

    acc[key] = val;

    return acc;
  }, {});

  return decoded;
}
