import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import baseTheme from 'components/theme';
import { NuCardFooter } from 'components/nuspire';
import { NspIcon, Incomplete as IncompleteIcon } from 'components/nuspire/nu-icon';
import { GET_OVERALL_SCORE } from './scoring';
import { Col, message, Progress, Row, Skeleton, Typography } from 'antd';
import { useAuthContext } from '../auth-context';
import { INSPOverallScoring, IIndustryControl, IControlScore, IDomainScore, INSPDomain } from '../../types/nsp';
import { GET_ALL_DOMAIN_DATA } from 'components/nsp/nsp-data';
import { useClientContext } from 'components/client-context-provider';
import { GET_MANAGED_CLIENT_SCORES } from './managed-clients';
import { useEffect } from 'react';
import { WidgetComponentProps } from '../widgets/widget';

// Target for domain and top-level scores
//const OVERALL_TARGET_SCORE = 100;
const DOMAIN_TARGET_SCORE = 100;

const ColoredFont = styled.div<{
  color: string;
}>`
  color: ${(p) => p.color};
`;

function getColorFromScore(args: { score: number; target: number }) {
  const { score, target } = args;

  if (score < target) {
    return baseTheme.color.red;
  }

  return baseTheme.color.primaryBlue;
}

function formatExplanation(args: { score: number; target: number }): string {
  const { score, target } = args;

  if (score > target) {
    return 'Above Industry Target';
  }

  if (score === target) {
    return 'Meets Industry Target';
  }

  return 'Below Industry Target';
}

const InfoContainer = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
`;

function OverallProgress(props: { nspOverallScoring: INSPOverallScoring; loading?: boolean }) {
  const { loading, nspOverallScoring: overallScoring } = props;
  const {
    controlsScored = 0,
    controlsAvailable = 0,
    domainsAvailable = 0,
    controlsCompletionPercentage = 0,
  } = overallScoring;

  return (
    <InfoContainer>
      <div>
        {loading ? (
          <Skeleton.Avatar size={100} shape="square" />
        ) : (
          <Progress
            type="dashboard"
            percent={controlsCompletionPercentage}
            gapDegree={140}
            strokeColor={baseTheme.color.primaryBlue}
            format={() => (
              <ColoredFont color={baseTheme.color.primaryBlue}>{`${controlsCompletionPercentage.toFixed(0)}%`}</ColoredFont>
            )}
          />
        )}
      </div>
      <div>
        {loading ? (
          <Skeleton paragraph={{ rows: 2 }} active />
        ) : (
          <div>
            <ul>
              <div>
                <strong>Current Progress</strong>
              </div>
              <li>
                {controlsScored}/{controlsAvailable} Controls Scored
              </li>
              <li>{controlsCompletionPercentage.toFixed(0)}% Complete</li>
              <li>{domainsAvailable} Domains</li>
            </ul>
          </div>
        )}
      </div>
    </InfoContainer>
  );
}

function OverallScore(props: { nspOverallScoring: INSPOverallScoring; loading?: boolean }) {
  const { loading, nspOverallScoring: overallScoring } = props;
  const { domainsScored = 0, domainsAvailable = 0, domainsCompletionPercentage = 0, overallScore = 0 } = overallScoring;

  const color = getColorFromScore({ score: overallScore, target: DOMAIN_TARGET_SCORE });

  return (
    <InfoContainer>
      <div>
        {loading ? (
          <Skeleton.Avatar size={100} shape="square" />
        ) : (
          <Progress
            strokeColor={color}
            format={() => (
              <ColoredFont color={color}>
                {!Number.isNaN(overallScore) ? overallScore : <IncompleteIcon style={{ color: baseTheme.color.gray4 }} />}
              </ColoredFont>
            )}
            type="dashboard"
            percent={domainsCompletionPercentage}
            gapDegree={140}
          />
        )}
      </div>
      <div>
        {loading ? (
          <Skeleton paragraph={{ rows: 2 }} active />
        ) : (
          <div>
            <ul>
              <div>
                <strong>Current Score</strong>
              </div>
              <li>
                <ColoredFont color={color}>
                  {formatExplanation({ score: overallScore, target: DOMAIN_TARGET_SCORE })}
                </ColoredFont>
              </li>
              <li>
                {domainsScored}/{domainsAvailable} Domains Scored
              </li>
              <li>{domainsCompletionPercentage.toFixed(0)}% Complete</li>
            </ul>
          </div>
        )}
      </div>
    </InfoContainer>
  );
}

function ManagedScore(props: { nspOverallScoring: INSPOverallScoring; managedScoring: any; loading?: boolean }) {
  const { loading, nspOverallScoring: overallScoring, managedScoring } = props;
  const { domainsCompletionPercentage = 0 } = overallScoring;

  const color = getColorFromScore({ score: managedScoring?.parentOverallScore, target: DOMAIN_TARGET_SCORE });

  return (
    <InfoContainer>
      <div>
        {loading ? (
          <Skeleton.Avatar size={100} shape="square" />
        ) : (
          <Progress
            strokeColor={color}
            format={() => (
              <ColoredFont color={color}>
                {!Number.isNaN(managedScoring?.parentOverallScore) ? (
                  managedScoring?.parentOverallScore.toFixed(0)
                ) : (
                  <IncompleteIcon style={{ color: baseTheme.color.gray4 }} />
                )}
              </ColoredFont>
            )}
            type="dashboard"
            percent={domainsCompletionPercentage}
            gapDegree={140}
          />
        )}
      </div>
      <div>
        {loading ? (
          <Skeleton paragraph={{ rows: 2 }} active />
        ) : (
          <div>
            <ul>
              <div>
                <strong>Managed Client(s)</strong>
              </div>
              <li>
                <ColoredFont color={color}>
                  {formatExplanation({ score: managedScoring?.parentOverallScore, target: DOMAIN_TARGET_SCORE })}
                </ColoredFont>
              </li>
              <li>{managedScoring?.incompleteClients} Incomplete Client(s)</li>
              <li>{managedScoring?.controlsCompletedPercentage.toFixed(0)}% Controls Complete</li>
            </ul>
          </div>
        )}
      </div>
    </InfoContainer>
  );
}

const FlexCol = styled(Col)`
  display: flex;
`;

export function SecurityProgramPosture({ isReportWidget, setFooterContent }: WidgetComponentProps<any, any>) {
  const { loading: userLoading } = useAuthContext();
  const { clientId, client } = useClientContext();
  const isMultiTenancyEnabled = client?.isMultiTenancyEnabled;

  const industryId = client?.industry?.id;

  const { data, error, loading } = useQuery(GET_ALL_DOMAIN_DATA, { variables: { industryId, clientId } });
  const { data: overallData } = useQuery(GET_OVERALL_SCORE, { variables: { clientId } });
  const { data: managedClientScoresData } = useQuery(GET_MANAGED_CLIENT_SCORES, {
    variables: { clientId },
    skip: !isMultiTenancyEnabled,
  });

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error('An error occurred while attempting to load your security program posture.');
    }
  }, [error]);

  useEffect(() => {
    if (!isReportWidget && setFooterContent) {
      setFooterContent(
        <NuCardFooter
          icon={<NspIcon style={{ color: baseTheme.color.slateBlue }} />}
          label="Manage Security Program"
          url={`/${clientId}/nsp/overview`}
          dataIntercomTarget="manage-nsp"
        />
      );
    }
  }, []);

  const domains: INSPDomain[] = data?.domains || [];
  const domainScores: IDomainScore[] = data?.domainScores || [];
  const industryControls = ((data as any)?.industryControls as IIndustryControl[]) || [];
  const controlScores: IControlScore[] = data?.controlScores || [];

  const controlsScored = controlScores.filter((cs) => cs?.score === null || cs?.score > 0).length;
  const controlsAvailable = industryControls.length;
  const domainsScored = domainScores.length;
  const domainsAvailable = domains.length;

  const overallScoreData = {
    controlsScored,
    controlsAvailable,
    controlsCompletionPercentage: (controlsScored / controlsAvailable) * 100,
    domainsScored,
    domainsAvailable,
    domainsCompletionPercentage: (domainsScored / domainsAvailable) * 100,
    overallScore: overallData?.overallScore?.score,
  };

  const lgSpan = isMultiTenancyEnabled ? 8 : 12;

  return (
    <>
      {/* <CardTitle title="Nuspire Security Program" icon={<ProductTile product="nsp" size="small" />} /> */}
      <Typography.Text>
        The Nuspire Security Program best practices will help your organization protect critical business processes,
        data, and IT assets. It identifies the people, processes, and technology that could impact the security,
        confidentiality, and integrity of your assets.
      </Typography.Text>
      <br />
      <Typography.Text>
        These security practices and your adoption of them will change over time. Here is the percentage of your
        Nuspire Security Program self-assessment that is completed. The higher the percentage of completion, the more
        accurate and valuable the results and recommendations.
      </Typography.Text>
      <Row gutter={24} justify="space-around">
        <FlexCol span={24} sm={12} lg={lgSpan}>
          <OverallProgress nspOverallScoring={overallScoreData} loading={loading || userLoading} />
        </FlexCol>
        <FlexCol span={24} sm={12} lg={lgSpan}>
          <OverallScore nspOverallScoring={overallScoreData} loading={loading || userLoading} />
        </FlexCol>
        {isMultiTenancyEnabled && (
          <FlexCol span={24} lg={8}>
            <ManagedScore
              managedScoring={managedClientScoresData?.managedClientScores}
              nspOverallScoring={overallScoreData}
              loading={loading || userLoading}
            />
          </FlexCol>
        )}
      </Row>
    </>
  );
}
