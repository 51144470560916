export function listify(array: Array<any>, { conjunction = 'and ', stringify = (item: any) => item.toString() } = {}) {
  return array.reduce((list, item, index) => {
    if (index === 0) return stringify(item);

    if (index === array.length - 1) {
      if (index === 1) return `${list} ${conjunction}${stringify(item)}`;

      return `${list}, ${conjunction}${stringify(item)}`;
    }

    return `${list}, ${stringify(item)}`;
  }, '');
}
