import ReactMarkdown from 'react-markdown';

export type TextObject = {
  type: 'plain_text' | 'mrkdwn';
  text: string;
};

/**
 *
 * TODO: Support Markdown
 */
export function TextComposition(props: { text: TextObject }) {
  const {
    text: { text, type },
  } = props;

  if (type === 'mrkdwn') {
    return <ReactMarkdown>{text}</ReactMarkdown>;
  }

  return <>{text}</>;
}
