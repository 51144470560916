import { Layout, SpinProps } from 'antd';
import styled from 'styled-components';
import Spin from './spin';

export const FullPageSpinContainer = styled.div`
  height: 100vh;
  box-sizing: border-box;
  display: grid;

  &&& {
    .ant-spin-text {
      padding-top: 11px;
    }

    .ant-spin {
      max-height: none;
    }
  }
`;

export function FullPageSpin(props: SpinProps) {
  return (
    <Layout>
      <Layout.Content>
        <FullPageSpinContainer>
          <Spin {...props}>
            <div />
          </Spin>
        </FullPageSpinContainer>
      </Layout.Content>
    </Layout>
  );
}

