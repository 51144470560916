import { UnauthenticatedLayout } from 'components/layouts/unauthenticated-layout';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { oktaAuth } from 'utils/okta';

export function LoginPage() {
  useEffect(() => {
    oktaAuth.signInWithRedirect();
  }, []);

  return (
    <UnauthenticatedLayout>
      <Helmet title="Login" />
    </UnauthenticatedLayout>
  );
}
