import { ReactNode } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { ArrowRight } from 'components/nuspire/nu-icon';

const IconWrapper = styled.div`
  position: relative;
  display: grid;
  align-self: center;

  --size: 18px;
  width: var(--size);
  height: var(--size);

  & .anticon {
    position: relative;
    z-index: 1;
    place-self: center;

    width: var(--size);
    height: var(--size);
    & * {
      color: ${p => p.theme.color.primaryBlue};
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 30px;
    height: 30px;
    border-radius: 50%;

    background-color: white;
  }
`;

const FilledCircleIcon = ({ icon }: { icon: ReactNode }) => (
  <IconWrapper>
    {icon}
  </IconWrapper>
);

const CardHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
`;

const Description = styled(Typography.Text)`
  padding-right: 25%;
`;

const Info = styled.div`
  width: 100%;
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;

  padding: 24px;
  padding-top: 16px;

  background-color: ${p => p.theme.color.primaryBlue};
  & .ant-typography {
    color: white;
  }
`;

const StyledLink = styled(Link)`
  color: white;
  :hover {
    color: ${p => p.theme.color.breeze};
  }

  .anticon {
    margin-left: 4px;
    color: inherit;
  }
`;

export interface NuServiceCardProps {
  title: string;
  description: string;
  link: LinkProps;
  icon: ReactNode;
  buttonText?: string;
  className?: string;
}

export default function NuServiceCard(props: NuServiceCardProps) {
  const { title, description, link, icon, className } = props;
  const buttonText = props.buttonText ?? 'Learn More';

  return (
    <StyledCard className={className}>
      <Info>
        <CardHeader>
          <Typography.Text strong>{title}</Typography.Text>
          <FilledCircleIcon
            icon={icon}
          />
        </CardHeader>
        <Description>
          {description}
        </Description>
      </Info>
      <StyledLink {...link}>
        {buttonText} <ArrowRight />
      </StyledLink>
    </StyledCard>
  );
}

