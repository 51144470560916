import React from 'react';

type InputRef = HTMLInputElement;

interface InputProps {
  // disabled: boolean;
  value: string;

  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
}

export const Input = React.forwardRef<InputRef, InputProps>((props, ref) => {
  const { value, onChange, onKeyDown } = props;

  return <input ref={ref} value={value} type="search" onChange={onChange} onKeyDown={onKeyDown} />;
});
