import { gql, useMutation } from '@apollo/client';
import { CLIENT_FEATURE_FLAG_FRAGMENT } from './feature-fragments';
import { NuButton } from 'components/nuspire';
import { Space, Form, message, Checkbox, Modal } from 'antd';
import { useState, useRef } from 'react';
import type { FormInstance } from 'antd/es/form';
import { FeatureClientSelect } from './client-select';

export const ADD_CLIENT_FEATURE_FLAG = gql`
  ${CLIENT_FEATURE_FLAG_FRAGMENT}
  mutation AddClientFeatureFlag($slug: String!, $clientId: String!, $includeChildClients: Boolean) {
    addClientFeatureFlag(slug: $slug, clientId: $clientId, includeChildClients: $includeChildClients) {
      ...ClientFeatureFlagFields
    }
  }
`;

export function AddClientFeatureModal(props: { slug: string; onClose: () => void; modalTitle?: string }) {
  const { slug, onClose, modalTitle } = props;
  const formRef = useRef<FormInstance>(null);

  const [addClientFeatureFlag] = useMutation(ADD_CLIENT_FEATURE_FLAG);

  const handleFinish = async (values: any) => {
    const { clientId, includeChildClients } = values;

    try {
      const results = await addClientFeatureFlag({
        variables: {
          slug,
          clientId,
          includeChildClients,
        },
        refetchQueries: ['ClientFeatureFlags'],
      });

      // show success message;
      message.success(`Successfully added Client to Feature.`);

      // reset form.
      formRef.current?.resetFields();

      // close modal
      props.onClose();
    } catch (err: any) {
      console.error(err);
      message.error(`Failed to add Client to Feature.`);
    }
  };

  return (
    <Modal open title={modalTitle ?? 'Add Feature to Client'} footer={false} onCancel={onClose}>
      <Form layout="vertical" onFinish={handleFinish} ref={formRef}>
        <Form.Item name="clientId" rules={[{ required: true }]}>
          {/* Swap this for FeatureClientSelect */}
          <FeatureClientSelect placeholder="Select Client" featureSlug={slug} />
        </Form.Item>
        <Form.Item name="includeChildClients" valuePropName="checked">
          <Checkbox>Include Managed Clients?</Checkbox>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Space>
            <NuButton type="primary" htmlType="submit">
              Save
            </NuButton>

            <NuButton onClick={onClose}>Cancel</NuButton>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export function AddClientFeatureButton(props: { slug: string; modalTitle?: string }) {
  const { slug } = props;

  const [visible, setVisible] = useState<boolean>(false);

  return (
    <>
      <NuButton onClick={() => setVisible(true)} type="primary">
        Add Client
      </NuButton>
      {visible && <AddClientFeatureModal {...props} onClose={() => setVisible(false)} />}
    </>
  );
}
