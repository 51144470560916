import { JiraActionInputProps } from './jira-issue-type-fields';
import { Select } from 'antd';

function JiraAllowedValuesInput(props: JiraActionInputProps) {
  const {
    value,
    onChange,
    jiraField: {
      allowedValues,
      schema: { type },
    },
  } = props;

  if (!allowedValues) {
    return null;
  }

  const selectValue = type === 'array' ? value?.map((i: { id: string }) => i.id) : value?.id;

  return (
    <Select
      value={selectValue}
      onChange={(val) => {
        const newValue = type === 'array' ? val.map((id: string) => ({ id })) : { id: val };

        onChange(newValue);
      }}
      mode={type === 'array' ? 'multiple' : undefined}
    >
      {allowedValues.map((allowedVal) => {
        const { id, name } = allowedVal;
        return (
          <Select.Option key={id} value={id}>
            {name}
          </Select.Option>
        );
      })}
    </Select>
  );
}

export default JiraAllowedValuesInput;
