import { BlockBase, BlockComponentProps } from '../blocks';
import { TextComposition, TextObject } from '../composition/text';
import styled from 'styled-components';

export type SectionBlockObject = BlockBase & {
  text?: TextObject;

  // fields?:  Slack has a concept of "Fields" that are similar to our meta fields.
};

const SectionRoot = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export function SectionBlock(props: BlockComponentProps<SectionBlockObject>) {
  const {
    block: { text },
  } = props;

  return <SectionRoot>{text && <TextComposition text={text} />}</SectionRoot>;
}
