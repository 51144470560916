import { NuButton } from 'components/nuspire';
import PageHeader from 'components/nuspire/nu-page-header';
import { ClientTaskDetailFieldsFragment } from 'types/graph-codegen/graph-types';
import { ClientTaskStatusButton } from 'components/client-tasks/client-task-card';
import { getCanCreateCase } from './utils';

export function ClientTaskPageHeader(props: {
  clientTask: ClientTaskDetailFieldsFragment;
  headerLevel?: 1 | 2 | 3 | 4 | 5;
  handleSnowCaseCollapse: (visible: boolean) => void;
}) {
  const {
    clientTask,
    clientTask: { id, tags },
    headerLevel = 5,
    handleSnowCaseCollapse,
  } = props;
  const canCreateCase = getCanCreateCase(tags);

  return (
    <PageHeader
      level={headerLevel}
      title={clientTask.label}
      actions={
        <>
          {!clientTask.snowCaseNumber?.length && canCreateCase && (
            <NuButton type="default" style={{ marginRight: '1rem' }} onClick={() => handleSnowCaseCollapse(true)}>
              Open Case
            </NuButton>
          )}

          <ClientTaskStatusButton id={id ?? ''} />
        </>
      }
      styles={{
        root: {
          marginBottom: '18px',
        },
      }}
    />
  );
}
