import { useEffect } from 'react';
import { Table, Typography } from 'antd';
import { WidgetComponentProps } from '../..';
import { useClientContext } from 'components/client-context-provider';
import {
  statuses,
  ClientServiceOfferingCol,
  ClientServiceStatusRoot,
  EmptyServicesState,
  getServiceStatus,
} from 'components/services/client-services';
import { P } from '../../../../shared-components';
import { Link, NuButton } from 'components/nuspire';

const tableColumns = [
  {
    key: 'service',
    title: 'Service',
    render: (_val, service) => {
      return <ClientServiceOfferingCol clientServiceOffering={service} />;
    },
  },
  {
    key: 'status',
    dataIndex: 'status',
    title: 'Status',
    render: (_healthCheckResults, record) => {
      const status = getServiceStatus(record);

      const formattedStatus = statuses.includes(status) ? status : 'Needs Attention';

      return <ClientServiceStatusRoot status={status}>{formattedStatus}</ClientServiceStatusRoot>;
    },
  },
];

function NuspireServicesWidget(props: WidgetComponentProps<any, any>) {
  const { data, setSubAction } = props;

  const { client, clientId } = useClientContext();

  useEffect(() => {
    if (setSubAction)
      setSubAction(
        <Link to={`/${clientId}/services`} style={{ marginRight: '1rem' }} data-intercom-target="all-nuspire-services">
          View in Services
        </Link>,
      );
  }, []);

  if (!data?.tableData?.length) return <EmptyServicesState />;

  if (client?.isMultiTenancyEnabled) {
    const aggregatedServices = data.tableData.reduce((acc, curr) => {
      const item = acc.find((i) => i.serviceOfferingName === curr.serviceOfferingName);
      const accountDetails = {
        accountName: curr.accountName,
        accountSysId: curr.accountSysId,
        clientId: curr.clientId,
        parentAccountName: curr.parentAccountName,
        parentAccountSysId: curr.parentAccountSysId,
      };

      if (!item) {
        acc.push({
          accounts: [accountDetails],
          grandParentServiceName: curr.grandParentServiceName,
          grandParentServiceSysId: curr.grandParentServiceSysId,
          healthCheckResults: curr.healthCheckResults,
          id: curr.id,
          parentServiceName: curr.parentServiceName,
          parentServiceSysId: curr.parentServiceSysId,
          serviceOfferingName: curr.serviceOfferingName,
          serviceOfferingShortDesc: curr.serviceOfferingShortDesc,
          sysId: curr.sysId,
        });
      } else {
        item.accounts.push(accountDetails);
      }

      return acc;
    }, []);

    return (
      <Table
        columns={tableColumns}
        dataSource={aggregatedServices}
        rowKey="id"
        pagination={false}
        expandable={{
          expandRowByClick: true,
          expandedRowRender(record) {
            return (
              <>
                <Typography.Title level={5}>Child Clients With This Service</Typography.Title>
                {record.accounts
                  .sort((a, b) => a.accountName.localeCompare(b.accountName))
                  .map((acc) => {
                    if (acc.clientId) {
                      return (
                        <P key={acc.accountSysId} style={{ marginBottom: '0.25rem' }}>
                          <Link to={`/${acc.clientId}/services`}>
                            <NuButton type="link">{acc.accountName}</NuButton>
                          </Link>
                        </P>
                      );
                    }

                    return (
                      <P key={acc.accountSysId} style={{ marginBottom: '0.25rem' }}>
                        {acc.accountName}
                      </P>
                    );
                  })}
              </>
            );
          },
          rowExpandable(record) {
            return record.accounts?.length > 0;
          },
        }}
      />
    );
  }

  return <Table columns={tableColumns} dataSource={data.tableData} rowKey="id" pagination={false} />;
}

export default NuspireServicesWidget;
