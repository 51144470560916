import { useEffect, useState } from 'react';
import { App, Form, Input, message, Modal, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DocumentNode } from 'graphql';
import { useLazyQuery } from '@apollo/client';
import { ExclamationCircleOutlined, LinkOutlined, SearchOutlined } from '@ant-design/icons';
import { config } from 'config';
import { StyledTable, TableRow } from 'components/shared-components';
import { NuButton } from 'components/nuspire';

type SearchServiceNowModalProps = {
  dataSourceKey: string;
  defaultSearchValue?: string;
  defaultSortBy: string;
  formatDescriptor: Function;
  graphQuery: DocumentNode;
  modalTitle: string;
  rootGraphKey: string;
  setIsVisible: Function;
  tableColumns: ColumnsType<object>;
  updateServiceNowId: Function;
  isVisible: boolean;
};

export default function SearchServiceNowModal(props: SearchServiceNowModalProps) {
  const { paginationDefaults } = config;
  const {
    dataSourceKey,
    defaultSearchValue,
    defaultSortBy,
    formatDescriptor,
    graphQuery,
    modalTitle,
    rootGraphKey,
    setIsVisible,
    tableColumns,
    updateServiceNowId,
    isVisible,
  } = props;
  const [currentPage, setCurrentPage] = useState<number>(paginationDefaults.currentPage);
  const [pageSize, setPageSize] = useState<number>(paginationDefaults.pageSize);
  const [search, setSearch] = useState<string | undefined>(defaultSearchValue);
  const [searchServiceNow, { data, error, loading, refetch }] = useLazyQuery(graphQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: paginationDefaults.pageSize,
      skip: 0,
      sortBy: defaultSortBy,
      sortOrder: paginationDefaults.sortOrder,
      search,
      active: true,
    },
  });

  const { modal } = App.useApp();

  useEffect(() => {
    if (isVisible) {
      searchServiceNow({
        variables: {
          limit: paginationDefaults.pageSize,
          skip: 0,
          sortBy: defaultSortBy,
          sortOrder: paginationDefaults.sortOrder,
          search,
          active: true,
        },
      });
    }
  }, [isVisible, search]);

  useEffect(() => {
    if (error) {
      message.error(error.message, 5);
      console.warn(error);
    }
  }, [error]);

  const dataRoot = data?.[rootGraphKey];
  const dataSource = dataRoot?.[dataSourceKey];
  const total = data?.[rootGraphKey]?.total || 0;

  const onLinkButtonClick = (item: any) => {
    modal.confirm({
      title: `Are you sure you want to link ${formatDescriptor(item)}?`,
      icon: <ExclamationCircleOutlined />,
      okText: `Yes`,
      okType: `primary`,
      cancelText: `No`,
      onOk() {
        updateServiceNowId(item.sys_id);
        setIsVisible(false);
      },
      width: 600,
    });
  };

  const columns: ColumnsType<object> = [
    ...tableColumns,
    {
      title: `Actions`,
      dataIndex: ``,
      key: `actions`,
      render: (item: any) => (
        <NuButton type="primary" icon={<LinkOutlined />} onClick={() => onLinkButtonClick(item)}>
          Link
        </NuButton>
      ),
    },
  ];

  const handlePagination = (pagination: TablePaginationConfig, _filters: any, sorter: any, _extra: any) => {
    let sortBy: string | undefined;
    let sortOrder: 'asc' | 'desc' | undefined;

    if (sorter) {
      const { order, field } = sorter;
      sortBy = field;

      if (order === 'ascend') {
        sortOrder = 'asc';
      } else if (order === 'descend') {
        sortOrder = 'desc';
      }
    }

    if (refetch) {
      refetch({
        limit: pagination.pageSize,
        skip: paginationDefaults.calcSkip(pagination.current as number, pagination.pageSize as number),
        sortBy,
        sortOrder,
        search,
      });
    }
  };

  const paginationSettings: TablePaginationConfig = {
    current: currentPage,
    defaultCurrent: paginationDefaults.currentPage,
    defaultPageSize: paginationDefaults.pageSize,
    onChange: (page: number, numItems?: number | undefined) => {
      setCurrentPage(page);
      setPageSize(numItems as number);
    },
    pageSize,
    showSizeChanger: true,
    showTotal: (t: number) => `Total: ${t}`,
    total,
  };

  const onSearchFormFinish = (values: any) => {
    setSearch(values.search);
  };

  return (
    <Modal open={isVisible} title={modalTitle} onCancel={() => setIsVisible(false)} footer={false} width={1500}>
      <Form
        labelAlign="left"
        layout="inline"
        requiredMark={false}
        onFinish={onSearchFormFinish}
        initialValues={{
          search,
        }}
      >
        <Form.Item name="search" rules={[{ required: false }]} style={{ width: 350 }}>
          <Input placeholder="Search" />
        </Form.Item>
        <Form.Item>
          <NuButton type="primary" htmlType="submit" icon={<SearchOutlined />}>
            Search
          </NuButton>
        </Form.Item>
      </Form>
      <TableRow>
        <StyledTable
          loading={loading}
          dataSource={dataSource?.map((acc: any) => ({
            ...acc,
            key: acc.sys_id,
          }))}
          columns={columns}
          onChange={handlePagination}
          pagination={paginationSettings}
        />
      </TableRow>
    </Modal>
  );
}
