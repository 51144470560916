import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Alert, App, Form, Input, Select, message } from 'antd';
import { NuButton, NuCard, NuCardContent } from 'components/nuspire';
import PhoneInput, { PhoneInputFormValues } from 'components/phone-input';
import { P } from 'components/shared-components';
import baseTheme from 'components/theme';
import { UpdateUserProfileInput } from 'components/user-profile/user-profile-form';
import { CountryCode } from 'libphonenumber-js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UpdateUserFromAdminMutation, UpdateUserFromAdminMutationVariables } from 'types/graph-codegen/graph-types';
import { IUser } from 'types/index';
import { getCountryCodeKey, parsePhoneNumberFromFormattedString } from 'utils/phone-number';
import SearchServiceNowUsersModal from './search-servicenow-users-modal';
import { personas } from '../../../personas';

const UPDATE_USERS_CLIENT = gql`
  mutation UpdateUsersFromAdmin($userId: String!, $currentClientId: String!, $newClientId: String!) {
    updateUsersClient(userId: $userId, currentClientId: $currentClientId, newClientId: $newClientId) {
      id
      email
      firstName
      lastName
      serviceNowUserId
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUserFromAdmin(
    $userId: String!
    $serviceNowUserId: String
    $managedClientIds: [String]
    $force: Boolean
  ) {
    updateUser(
      userId: $userId
      serviceNowUserId: $serviceNowUserId
      managedClientIds: $managedClientIds
      force: $force
    ) {
      id
      email
      firstName
      lastName
      serviceNowUserId
      managedClientIds
    }
  }
`;

const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateProfileFromAdmin($userId: String!, $input: UpdateUserProfileInput!) {
    updateUserProfile(userId: $userId, input: $input) {
      id
      firstName
      lastName
      mobilePhoneNumber
      mobilePhoneNumberCountry
      persona
      phoneNumber
      phoneNumberCountry
      phoneNumberExt
      title
    }
  }
`;

type UserDetailsFormValues = {
  clientId: string;
  email: string;
  firstName: string;
  lastName: string;
  persona: string;
  serviceNowUserId?: string;
  title?: string;
  managedClientIds?: string[];
  phone?: PhoneInputFormValues;
  mobilePhone?: PhoneInputFormValues;
};

export const UserDetailsForm = ({
  user,
  setIsEditing,
  refetch,
}: {
  user?: IUser;
  setIsEditing: (isEditing: boolean) => void;
  refetch: (data?: { userId?: string; clientId?: string }) => void;
}) => {
  const navigate = useNavigate();
  const [updateUsersClientMutation, { loading }] = useMutation(UPDATE_USERS_CLIENT);
  const [updateUserMutation, { loading: updatingUser }] = useMutation<
    UpdateUserFromAdminMutation,
    UpdateUserFromAdminMutationVariables
  >(UPDATE_USER);
  const [updateUserProfileMutation, { loading: updatingProfile }] = useMutation(UPDATE_PROFILE_MUTATION);
  const [isSearchUsersModalVisible, setIsSearchUsersModalVisible] = useState<boolean>(false);
  const [form] = Form.useForm();

  const defaultUserPhoneCountryCodeKey = getCountryCodeKey(user?.phoneNumber, user?.phoneNumberCountry);
  const defaultUserMobilePhoneCountryCodeKey = getCountryCodeKey(
    user?.mobilePhoneNumber,
    user?.mobilePhoneNumberCountry,
  );
  const { nationalNumber: defaultUserPhoneNumber } = parsePhoneNumberFromFormattedString(user?.phoneNumber);
  const { nationalNumber: defaultUserMobilePhoneNumber } = parsePhoneNumberFromFormattedString(user?.mobilePhoneNumber);

  const { modal } = App.useApp();

  const onFinish = async (values: UserDetailsFormValues & { force?: boolean }) => {
    const {
      clientId,
      serviceNowUserId,
      firstName,
      lastName,
      persona,
      title,
      managedClientIds = [],
      phone,
      mobilePhone,
      email,
    } = values;

    try {
      const needsUpdatedClient = clientId !== user?.clientId;
      if (needsUpdatedClient) {
        await updateUsersClientMutation({
          variables: {
            userId: user?.id,
            currentClientId: user?.clientId,
            newClientId: clientId,
          },
        });
      }

      const variables = {
        userId: user!.id,
        serviceNowUserId,
        managedClientIds: Array.from(new Set(managedClientIds)),
        force: values.force,
      };
      const { errors } = await updateUserMutation({
        variables,
      });
      const isDuplicateSnowErrorMsg = (error: Error) =>
        error.message.toLowerCase() === 'a user with this servicenow user id already exists';

      if (errors) {
        const error = errors.at(0);

        if (error && isDuplicateSnowErrorMsg(error)) {
          modal.confirm({
            title: 'A user with this ServiceNow user ID already exists',
            icon: <ExclamationCircleOutlined />,
            content: (
              <>
                <P>Do you still want to link this user with this ServiceNow user?</P>
                <P>
                  You can review the users that have this ServiceNow user ID{' '}
                  <a
                    href={`/admin/users?search=${serviceNowUserId}`}
                    target="_blank"
                    style={{ color: baseTheme.color.primaryBlue }}
                    rel="noreferrer"
                  >
                    here
                  </a>
                  .
                </P>
              </>
            ),
            okText: 'Yes',
            okType: 'primary',
            cancelText: 'No',
            async onOk() {
              return onFinish({ ...values, force: true });
            },
          });
        }
      } else {
        const userProfileInput: UpdateUserProfileInput = {
          firstName,
          lastName,
          persona,
          title,
          email,
        };

        if (phone?.number) {
          const { countryCodeKey, number, extension } = phone;
          const [country, countryCallingCode] = countryCodeKey.split('-');
          const { phoneNumber } = parsePhoneNumberFromFormattedString(
            `${countryCallingCode}${number}`,
            country as CountryCode,
          );

          userProfileInput.phoneNumber = phoneNumber;
          userProfileInput.phoneNumberCountry = country;
          userProfileInput.phoneNumberExt = extension;
        }

        if (mobilePhone?.number) {
          const { countryCodeKey, number } = mobilePhone;
          const [country, countryCallingCode] = countryCodeKey.split('-');
          const { phoneNumber } = parsePhoneNumberFromFormattedString(
            `+${countryCallingCode}${number}`,
            country as CountryCode,
          );

          userProfileInput.mobilePhoneNumber = phoneNumber;
          userProfileInput.mobilePhoneNumberCountry = country;
        }

        await updateUserProfileMutation({
          variables: {
            userId: user?.id,
            input: userProfileInput,
          },
        });

        setIsEditing(false);
        message.success('User updated');
        if (needsUpdatedClient) {
          navigate(`/admin/users/${user?.id}`);
        } else {
          refetch({ userId: user?.id, clientId: user?.clientId });
        }
      }
    } catch (err: any) {
      message.error(`There was an error updating the user: ${err.message}`);
      console.error(`Update failed. ${err.message}`);
    }
  };

  if (!user) {
    return <Alert message="Missing User" type="error" />;
  }

  return (
    <>
      <NuCard title="Update User">
        <NuCardContent>
          <Form layout="vertical" labelAlign="right" onFinish={onFinish} form={form}>
            <Form.Item
              name="email"
              label="Email"
              initialValue={user?.email}
              rules={[
                { required: true, message: 'Please add an Email' },
                {
                  required: true,
                  type: 'email',
                  message: 'The input is not a valid Email!',
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              name="firstName"
              label="First Name"
              initialValue={user?.firstName}
              rules={[{ required: true, message: 'Please add a First Name' }]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              initialValue={user?.lastName}
              rules={[{ required: true, message: 'Please add a Last Name' }]}
            >
              <Input size="large" />
            </Form.Item>
            <PhoneInput
              name="phone"
              initialValues={{
                countryCodeKey: defaultUserPhoneCountryCodeKey ?? undefined,
                number: defaultUserPhoneNumber,
                extension: user.phoneNumberExt,
              }}
              label="Business Phone"
            />
            <PhoneInput
              name="mobilePhone"
              initialValues={{
                countryCodeKey: defaultUserMobilePhoneCountryCodeKey ?? undefined,
                number: defaultUserMobilePhoneNumber,
              }}
              label="Mobile Phone"
              showExtension={false}
            />
            <Form.Item name="title" label="Title" initialValue={user?.title}>
              <Input size="large" />
            </Form.Item>
            <Form.Item
              name="persona"
              label="Persona"
              initialValue={user?.persona ?? personas[1].value}
              rules={[{ required: true, message: 'Please confirm your company role' }]}
            >
              <Select size="large" placeholder="Persona or Role" options={personas} />
            </Form.Item>
            <Form.Item
              name="clientId"
              label="Client Id"
              initialValue={user?.clientId}
              rules={[{ required: true, message: 'Please input the client id' }]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.List name="managedClientIds" initialValue={user?.managedClientIds}>
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item label={index === 0 ? 'Managed Clients' : ''} required={false} key={field.key}>
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please input a client ID or delete this field.',
                          },
                          {
                            validator(_rule, value, callback) {
                              const items: string[] = form.getFieldValue('managedClientIds');
                              const inputClientId: string | null | undefined = form.getFieldValue('clientId');
                              const dupes = items.filter((elem, elemIdx, arr) => arr.indexOf(elem) !== elemIdx);
                              if (user.clientId && value === user.clientId) {
                                return callback('User cannot manage their primary client!');
                              }
                              // Make sure they're not putting in a new clientId value too
                              if (inputClientId && value === inputClientId) {
                                return callback('User cannot manage their primary client!');
                              }
                              if (value && dupes.length) {
                                return callback('No duplicates allowed!');
                              }
                              return callback();
                            },
                          },
                        ]}
                        noStyle
                      >
                        <Input placeholder="Client ID" style={{ width: '90%' }} size="large" />
                      </Form.Item>
                      <MinusCircleOutlined
                        style={{ color: baseTheme.color.red, fontSize: '1.25rem', marginLeft: '0.5rem' }}
                        onClick={() => remove(field.name)}
                      />
                    </Form.Item>
                  ))}
                  <Form.Item
                    label={form.getFieldValue('managedClientIds')?.length === 0 ? 'Managed Clients' : undefined}
                  >
                    <NuButton type="dashed" onClick={() => add()} style={{ width: '100%' }} icon={<PlusOutlined />}>
                      Add a Managed Client
                    </NuButton>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item label="ServiceNow User Id">
              <Form.Item
                name="serviceNowUserId"
                initialValue={user?.serviceNowUserId}
                rules={[{ required: false }]}
                noStyle
              >
                <Input size="large" style={{ width: `70%` }} />
              </Form.Item>
              <NuButton
                type="primary"
                style={{ marginLeft: `0.5rem` }}
                onClick={() => setIsSearchUsersModalVisible(true)}
              >
                Search ServiceNow
              </NuButton>
            </Form.Item>
            <NuButton type="primary" htmlType="submit" loading={loading || updatingProfile || updatingUser}>
              Save
            </NuButton>
            <NuButton type="primary" style={{ marginLeft: '1rem' }} onClick={() => setIsEditing(false)}>
              Cancel
            </NuButton>
          </Form>
        </NuCardContent>
      </NuCard>

      <SearchServiceNowUsersModal
        defaultSearchValue={`${user?.firstName} ${user?.lastName}`.toLowerCase()}
        isVisible={isSearchUsersModalVisible}
        setIsVisible={setIsSearchUsersModalVisible}
        updateServiceNowId={(serviceNowUserId: string) =>
          form.setFieldsValue({
            serviceNowUserId,
          })
        }
      />
    </>
  );
};

export default UserDetailsForm;
