import styled from 'styled-components';
import { Divider } from 'antd';

export const NuDivider = styled(Divider)`
  margin-top: 5px !important;
  margin-bottom: 5px !important;
`;

export const Spacer = styled.div<{
  height?: string;
}>`
  height: ${props => props.height || '20px'};
`;
