export default () => (
  <svg
    version="1.1"
    id="logo"
    xmlns="http://www.w3.org/2000/svg"
    // viewBox="0 0 1000 300"
    viewBox="0 0 500 300"
    width="2em"
    height="1em"
  >
    {/* <path
      id="meraki"
      d="M529.2,234.1l-43.8-108.1v108.1h-9.2V113.9h13.9l44.3,110.2l41.7-110.2h13.4v120.3h-9V126.9
   l-41.3,107.2H529.2z M686.2,210.4c-4.2,15.9-17.5,26.2-36.1,26.2c-23.5,0-41.2-16.2-41.2-45.9s17.2-45.9,39.6-45.9
   c22.2,0,39.2,15,39.2,48.1H618c0.8,24.7,14.9,36.1,32.2,36.1c13,0,23-7,26.4-18.5H686.2z M618.2,185.4h60.1
   c-1.2-22.2-13.7-33.2-29.9-33.2C632.5,152.1,619.8,163.6,618.2,185.4L618.2,185.4z M708.7,234.1v-86.8h7.3l1.3,16.7
   c4.7-13.2,13.5-19.2,24.4-19.2c3,0,6.1,0.5,9,1.3v8.9c-3.1-1-6.3-1.5-9.5-1.5c-12.7,0-23.5,12.4-23.5,33.2v47.4L708.7,234.1z
    M762.2,169.8c1.3-14.9,14.5-25.1,33.7-25.1c19.4,0,32.2,10.9,32.2,28.6v60.8h-7.3l-1.3-14.4c-5.3,9-15.2,16.9-30.7,16.9
   c-16.9,0-29.1-9.2-29.1-24.5c0-14.9,9-23.9,33.4-27.9l26.1-4.3v-7.5c0-12.5-8.8-20.2-23.7-20.2c-13.9,0-22.7,7.2-23.9,17.7
   L762.2,169.8z M819.2,203.2V187l-23.4,4c-20.8,3.5-26.8,10.1-26.8,20.4c0,11.7,9,17.5,22.1,17.5C804.9,229,819.1,218.4,819.2,203.2
   L819.2,203.2z M851.2,234.1V113.9h9v68.8l41.6-35.4H914l-44.6,37.9l50.1,48.9h-12.1l-47.1-45.9v45.9H851.2z M930.9,126.2v-12.4h10.9
   v12.3L930.9,126.2z M931.8,234.1v-86.8h9v86.8L931.8,234.1z"
    /> */}
    <path
      id="cisco"
      d="M150.5,178h14.2v56.1h-14.2V178z M278.8,194c-3.7-2-7.8-3-11.9-3c-9.1,0-15.5,6.4-15.5,15
   c0,8.4,6.1,15,15.5,15c4.2,0,8.3-1,11.9-3v15.1c-4.2,1.3-8.5,2-12.9,2c-16,0-29.9-11-29.9-29.1c0-16.7,12.6-29,29.9-29
   c4.4,0,8.7,0.7,12.9,2V194z M131.1,194c-3.7-2-7.7-3-11.9-3c-9.2,0-15.5,6.4-15.5,15c0,8.4,6,15,15.5,15c4.2,0,8.2-1,11.9-3v15.1
   c-4.2,1.3-8.6,2-12.9,2c-15.9,0-29.8-11-29.8-29.1c0-16.7,12.6-29,29.8-29c4.4,0,8.8,0.7,12.9,2V194z M323.3,191.3
   c-8.2,0-14.8,6.5-14.8,14.7c0,8.2,6.5,14.8,14.7,14.8c8.2,0,14.8-6.5,14.8-14.7c0,0,0-0.1,0-0.1c0.1-8-6.2-14.6-14.2-14.7
   C323.6,191.2,323.5,191.2,323.3,191.3 M353,206c0,16.1-12.4,29.1-29.6,29.1s-29.6-13-29.6-29.1s12.4-29,29.6-29S353,190,353,206
    M218.4,190.7c-3.6-1-7.3-1.6-11-1.7c-5.7,0-8.8,1.9-8.8,4.6c0,3.4,4.1,4.6,6.5,5.4l3.9,1.2c9.2,2.9,13.4,9.2,13.4,16.1
   c0,14.1-12.4,18.8-23.2,18.8c-5.1-0.1-10.2-0.6-15.3-1.5v-12.9c4.3,1.3,8.8,2,13.4,2.1c7,0,10.3-2.1,10.3-5.2c0-2.8-2.8-4.5-6.3-5.6
   l-3-1c-7.9-2.5-14.4-7.1-14.4-16.4c0-10.5,7.9-17.5,20.9-17.5c4.6,0.1,9.3,0.7,13.8,1.8L218.4,190.7z M73.3,117.7c0-3.9-3.1-7-7-7
   s-7,3.1-7,7v14.7c0,3.9,3.1,7,7,7s7-3.1,7-7V117.7z M111.9,98.4c0-3.9-3.1-7-7-7s-7,3.1-7,7v34c0,3.9,3.1,7,7,7s7-3.1,7-7V98.4z
    M150.5,71.9c0-3.9-3.1-7-7-7c-3.9,0-7,3.1-7,7l0,0v74.5c0,3.9,3.1,7,7,7c3.9,0,7-3.1,7-7V71.9z M189.1,98.4c0-3.9-3.1-7-7-7
   s-7,3.1-7,7v34c0,3.9,3.1,7,7,7s7-3.1,7-7V98.4z M227.6,117.7c0-3.9-3.1-7-7-7s-7,3.1-7,7l0,0v14.7c0,3.9,3.1,7,7,7s7-3.1,7-7V117.7
   z M266.3,98.4c0-3.9-3.1-7-7-7s-7,3.1-7,7v34c0,3.9,3.1,7,7,7s7-3.1,7-7V98.4z M304.9,71.9c0-3.9-3.1-7-7-7s-7,3.1-7,7v74.5
   c0,3.9,3.1,7,7,7s7-3.1,7-7V71.9z M343.5,98.4c0-3.9-3.2-7.1-7.1-7.1c-3.9,0-7.1,3.2-7.1,7.1v34c0,3.9,3.2,7.1,7.1,7.1
   c3.9,0,7.1-3.2,7.1-7.1V98.4z M382,117.7c0-3.9-3.1-7-7-7s-7,3.1-7,7v14.7c0,3.9,3.1,7,7,7s7-3.1,7-7V117.7z"
    />
  </svg>
);
