import { message, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';
import { useClientContext } from 'components/client-context-provider';
import { useAuthContext } from 'components/auth-context';
import { Access } from 'types';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { gql, useMutation } from '@apollo/client';
import { useClientIdentifier } from 'utils/react-hooks/use-client-identifier';

export const UPLOAD_CASE_ATTACHMENTS_MUTATION = gql`
  mutation UploadCaseAttachments($input: UploadCaseAttachmentsInput!) {
    uploadCaseAttachments(input: $input) {
      message
      success
    }
  }
`;

interface IFileUploaderProps {
  caseNumber: string;
  loading: boolean;
  onUploadComplete?: (status?: any) => any;
}

export default function FileUploader({ caseNumber, loading, onUploadComplete }: IFileUploaderProps) {
  const { authorize, client, clientId } = useClientContext();
  const serviceNowAccountId = useClientIdentifier({
    clientIdentifiers: client?.clientIdentifiers,
    type: 'serviceNowAccountId',
  });

  const { user: authUser } = useAuthContext();
  const { authorized: canWriteCaseManagement } = authorize({ requiredPermissions: { caseManagement: Access.write } });
  const canUploadFile: boolean = !!authUser?.serviceNowUserId && !!serviceNowAccountId && canWriteCaseManagement;

  const [uploadAttachments, { loading: isUploading }] = useMutation(UPLOAD_CASE_ATTACHMENTS_MUTATION);

  if (onUploadComplete) debounce(onUploadComplete, 2000);

  const onChange = (info: any) => {
    const { status } = info.file;
    // if (status !== 'uploading') {
    //   console.log(info.file, info.fileList);
    // }

    if (status === 'done') {
      // console.log(`${info.file.name} file uploaded successfully.`);
      if (onUploadComplete) onUploadComplete();
    } else if (status === 'error') {
      // console.error(`${info.file.name} file upload failed.`);
      if (onUploadComplete) onUploadComplete();
    }
  };

  const customRequest = async (data: any) => {
    if (!canUploadFile) {
      if (!canWriteCaseManagement) {
        message.error('You do not have the required permissions to upload a file.');
      } else if (!authUser?.serviceNowUserId) {
        message.error('Your user is not linked with ServiceNow. Unable to upload file.');
      } else if (!serviceNowAccountId) {
        message.error('Your client is not linked with ServiceNow. Unable to upload file.');
      } else {
        message.error('Unable to upload file.');
      }

      return;
    }

    const { file, onSuccess, onError } = data;

    try {
      await uploadAttachments({
        variables: {
          input: {
            caseNumber,
            clientId,
            files: [file as File],
          },
        },
      });

      message.success('Attachment(s) uploaded successfully!');
      onSuccess(true);
    } catch (err) {
      message.error('An error occurred while uploading your attachment(s).');
      onError(err);
    }
  };

  return (
    <Upload.Dragger
      style={{
        padding: '1.5rem',
        marginTop: '1.5rem',
        width: '100%',
      }}
      customRequest={customRequest}
      disabled={!canUploadFile || loading || isUploading}
      multiple
      name="file"
      onChange={onChange}
      showUploadList={false}
    >
      {loading || isUploading ? (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      ) : (
        <>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload.</p>
          <p className="ant-upload-hint">Support for a single or bulk upload.</p>
        </>
      )}
    </Upload.Dragger>
  );
}
