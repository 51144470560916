import { message } from 'antd';
import styled from 'styled-components';
import { isImpersonationActive, clearImpersonatedAccessToken } from 'utils/access-tokens';
import { useAuthContext } from 'components/auth-context';
import { IUser } from 'types';
import { Link as ReactRouterLink } from 'react-router-dom';

const WhiteLink = styled(ReactRouterLink)`
  color: white;

  &:hover {
    color: white;
    text-decoration: underline;
  }
`;

const Warning = styled.div`
  background-color: ${(p) => p.theme.color.error};
  color: white;
  font-weight: bold;
  letter-spacing: 0.02rem;
  padding: 10px;
`;

const getDisplayName = (user?: IUser): string => {
  if (!user) {
    return 'Unknown User';
  }

  return user.email || `${user.firstName} ${user.lastName}`;
};

export function ImpersonationBanner() {
  const isImpersonating = isImpersonationActive();
  const { user, refresh: refreshAuthContext } = useAuthContext();

  if (isImpersonating) {
    return (
      <Warning>
        WARNING: You are impersonating {getDisplayName(user)}.{' '}
        <WhiteLink
          to="#"
          onClick={async () => {
            clearImpersonatedAccessToken();
            refreshAuthContext();
            message.success('Impersonation has been stopped. You are now authenticated as yourself.');
          }}
        >
          Click here to stop.
        </WhiteLink>
      </Warning>
    );
  }

  return null;
}
