import { IConnector } from 'components/connections/connectors';
import CbcAuthRedirect from './carbon-black-cloud-redirect';
import CbcDeviceSummaryWidget from './widgets/cbc-devices-summary-widget';
import CbcAlertsWidget from './widgets/cbc-alerts-widget/cbc-alerts-widget';
import { VmWare } from 'components/nuspire/nu-icon';

const CARBON_BLACK_CLOUD_CONNECTOR: IConnector = {
  slug: 'carbon-black-cloud',
  name: 'Carbon Black Cloud',
  description: 'Carbon Black Cloud Api',

  icon: <VmWare />,

  // TODO: on once connections are released, this field becomes outdated.
  connectionTypes: ['action'],

  handleAddConnection: (args) => {
    // we need to make sure we're adding a connection to the right client here.
    const clientId = args?.clientId;
    const sp = new URLSearchParams();
    if (clientId) {
      sp.set('clientId', clientId);
    }

    window.open(
      `${window.location.origin}/redirects/connections/carbon-black-cloud/auth?${sp.toString()}`,
      'Carbon Black Auth',
      'height=1000,width=800',
    );
  },
  redirects: [{ path: 'auth', component: CbcAuthRedirect }],

  widgets: [CbcDeviceSummaryWidget, CbcAlertsWidget],
  updatableAuthFields: [
    { key: 'Hostname', value: 'hostname' },
    { key: 'ORG KEY', value: 'orgKey' },
    { key: 'API ID', value: 'apiId' },
    { key: 'API SecretKey', value: 'apiSecretKey' },
  ],
};

export default CARBON_BLACK_CLOUD_CONNECTOR;
