import { Select } from 'antd';
import { ActionInputProps } from '../../action-form-field';
import { gql, useQuery } from '@apollo/client';

const JIRA_PROJECTS = gql`
  query JiraProjectIssueTypeSelect($connectionId: String!, $projectId: String!) {
    clientConnection(id: $connectionId) {
      id
      jiraProjectIssueTypes(projectId: $projectId) {
        id
        name
      }
    }
  }
`;

function JiraProjectIssueTypeSelect(props: ActionInputProps) {
  // can only select if connection has been selected.
  const { value, onChange, formik } = props;

  const values = formik?.values;
  const connectionId = values?.connection?.id ?? null;
  const projectId = values?.project?.key ?? null;

  const { data } = useQuery(JIRA_PROJECTS, {
    variables: { connectionId, projectId },
    skip: !connectionId || !projectId,
  });

  const issueTypes = data?.clientConnection?.jiraProjectIssueTypes;

  const options = issueTypes ?? (value ? [value] : []);

  return (
    <Select
      value={value?.id}
      onChange={(selectedId) => {
        const selectedOption = options.find((o: any) => o.id === selectedId);

        onChange(selectedOption);
      }}
      disabled={!connectionId}
      placeholder="Select Jira Project"
    >
      {options.map((c: any) => (
        <Select.Option key={c.id} value={c.id}>
          {`${c.name}`}
        </Select.Option>
      ))}
    </Select>
  );
}

export default JiraProjectIssueTypeSelect;
