import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { Link } from 'components/nuspire';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import styled from 'styled-components';
import { WidgetComponentProps } from '../index';

const Status = styled.div<{ status: string }>`
  font-weight: bold;
  text-transform: capitalize;
  color: ${(p) => (p.status === `pass` ? p.theme.color.success : p.theme.color.error)};
`;

const IconContainer = styled.div<{ recommendations: number }>`
  margin-right: 0.5rem;
  display: inline-block;
  color: ${(p) => (p.recommendations > 0 ? p.theme.color.error : p.theme.color.success)};
`;

const TechnologySourceLink = styled(Link)`
  color: ${(p) => p.theme.color.riverBlue};
`;

type TechnologySourceRow = {
  recommendations: number;
  id: string;
  clientId: string;
  name: string;
  status: `pass` | `fail`;
  dashboardId?: string;
  technologyId: string;
};

type Data = {
  tableData: TechnologySourceRow[];
};

const tableColumns = [
  {
    title: 'Source',
    dataIndex: 'name',
    key: 'name',
    render: (name: string, record: TechnologySourceRow) => {
      const path = `/${record.clientId}/reporting-and-analysis/dashboards/${record?.dashboardId}`;

      return (
        <div style={{ fontWeight: 'bold' }}>
          {record?.dashboardId ? <TechnologySourceLink to={path}>{name}</TechnologySourceLink> : name}
        </div>
      );
    },
  },
  {
    title: 'Recommendations',
    dataIndex: 'recommendations',
    key: 'recommendations',
    defaultSortOrder: 'descend' as const,
    sorter: (a, b) => a.recommendations - b.recommendations,
    align: 'center' as const,
    render: (recommendations: number, record: TechnologySourceRow) => {
      const searchParams = new URLSearchParams();
      searchParams.set('status', 'todo,in_progress,review,open');
      searchParams.set('tags', `technology-source-${record.technologyId}`);
      const s = searchParams.toString();

      const link = `/${record.clientId}/case-management/tasks?${s}`;

      return (
        <Link to={link} mode="plain">
          <IconContainer recommendations={recommendations}>
            {recommendations > 0 ? <WarningOutlined /> : <CheckCircleOutlined />}
          </IconContainer>
          {recommendations}
        </Link>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => <Status status={status}>{status === `pass` ? 'Healthy' : 'Needs Attention'}</Status>,
    align: 'center' as const,
  },
];

const TechnologySourceWidget = (props: WidgetComponentProps<void, Data>) => {
  const { data } = props;

  if (!data)
    return (
      <SpinContainer>
        <Spin tip="Fetching Technology Sources...">
          <div className="content" />
        </Spin>
      </SpinContainer>
    );

  return (
    <div>
      <Table columns={tableColumns} dataSource={data?.tableData} pagination={false} size="large" scroll={{ y: 375 }} />
    </div>
  );
};

export default TechnologySourceWidget;
