import { gql, useQuery } from '@apollo/client';
import { Row, Typography, Table, Tag, Tooltip } from 'antd';
import { WidgetDefinition, WidgetRendererProps } from 'components/dashboard/widget-definitions/types';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { NuCardContent } from 'components/nuspire';

const Score = styled(Typography.Title)`
  margin: auto !important;
`;

const ColWidth = styled.div`
  max-width: 150px;
  max-height: 75px;
  overflow-y: auto;
  overflow-x: auto;
`;

const TableScroll = styled.div`
  max-height: 450px;
  overflow-y: auto;
  width: 100%;
`;

const CROWD_INCIDENT_QUERY = gql`
  query GetCrowdIncidents($connectionId: String!) {
    clientConnection(id: $connectionId) {
      id
      publicConnectionDataJson
      crowdIncidents {
        incident_id
        created
        tactics
        state
        techniques
        status
      }
    }
  }
`;

const StateText = styled(Typography.Text)`
  color: ${p => p.theme.color.primary};
`;

const tableColumns = [
  {
    title: 'State',
    dataIndex: 'state',
    render: (state: string, item) => {
      return (
        <Tooltip title={`Incident ID: ${item.incident_id}`}>
          <StateText>{state}</StateText>
        </Tooltip>
      );
    },
  },
  {
    title: 'Received',
    key: 'created',
    render: (created: string) => {
      return <span>{dayjs(created).fromNow()}</span>;
    },
  },
  {
    title: 'Tactics',
    dataIndex: 'tactics',
    render: (tactics: string[]) => {
      return (
        <ColWidth>
          {tactics.map((i) => (
            <Tag color="blue" key={i}>
              {i}
            </Tag>
          ))}
        </ColWidth>
      );
    },
  },
  {
    title: 'Techniques',
    dataIndex: 'techniques',
    render: (techniques: string[]) => {
      return (
        <ColWidth>
          {techniques.map((i) => (
            <Tag color="blue" key={i}>
              {i}
            </Tag>
          ))}
        </ColWidth>
      );
    },
  },
];

function CrowdIncidentWidgetComponent(props: WidgetRendererProps) {
  const connectionId = props?.configuration?.connectionId ?? null;
  const { data, loading } = useQuery(CROWD_INCIDENT_QUERY, {
    variables: {
      connectionId,
    },
  });

  if (!data?.clientConnection?.crowdIncidents && loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  if (data?.clientConnection?.crowdIncidents) {
    return (
      <NuCardContent>
        <Row>
          <Score>Total: {data.clientConnection.crowdIncidents.length}</Score>
        </Row>
        <Row>
          <TableScroll>
            <Table
              columns={tableColumns}
              dataSource={data.clientConnection.crowdIncidents}
              rowKey="incident_id"
              pagination={false}
              size="small"
            />
          </TableScroll>
        </Row>
      </NuCardContent>
    );
  }

  return <div>Unable to fetch incidents.</div>;
}

const CrowdIncidentWidget: WidgetDefinition = {
  name: 'CrowdStrike Incidents',
  slug: 'crowdincidents',
  component: CrowdIncidentWidgetComponent,
  description: 'CrowdStrike Incidents',
  connectorSlug: 'crowdstrike',
};

export default CrowdIncidentWidget;
