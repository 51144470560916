import mixpanel, { Callback, Dict, RequestOptions } from 'mixpanel-browser';
import { isImpersonationActive } from 'utils/access-tokens';

export function mixpanelTrack(
  eventName: string,
  properties?: Dict | undefined,
  optionsOrCallback?: RequestOptions | Callback | undefined,
  callback?: Callback | undefined,
) {
  if (!isImpersonationActive()) {

    return mixpanel.track(eventName, properties, optionsOrCallback, callback);
  } else {
    console.warn('Not tracking impersonating user...');
  }
}
