import { Doughnut } from 'components/nuspire/charts/doughnut-chart';
import styled from 'styled-components';
import { Table, Typography, Badge } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useClientContext } from 'components/client-context-provider';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { Link } from 'components/nuspire';
import baseTheme from 'components/theme';
import { WidgetComponentProps } from '../..';
import dayjs from 'dayjs';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import { ColumnsType } from 'antd/es/table';
import { queryDataTypePath } from 'components/reporting-and-analysis/paths';

dayjs.extend(relativeTimePlugin);

export const Dot = styled.div`
  height: 1rem;
  width: 1rem;
  background-color: ${(props) => props?.color || '#ddd'};
  border-radius: 50%;
  display: inline-block;
`;

const TableHeader = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const LegendItem = (args: { name: string; count: number; color?: string; size: number }) => {
  const { clientId } = useClientContext();
  const { name, count, color, size } = args;
  const link = `${queryDataTypePath({ clientId: clientId ?? '', dataType: 'snow-asset' })}?snow-asset-status=${
    statuses.find((s) => s.name === name)?.slug
  }`;

  if (size === 1) {
    return (
      <div style={{ margin: 'auto 0', display: 'flex' }}>
        <Dot style={{ margin: 'auto 0' }} color={color} />
        <div style={{ margin: 'auto 0 auto 1rem', fontSize: '16px' }}>
          <span style={{ fontWeight: '700' }}>{count}</span>{' '}
          <Link mode="plain" to={link}>
            {name}
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div style={{ margin: '0 2rem' }}>
      <Link mode="plain" to={link} style={{ fontSize: '1.5rem' }}>
        {name}
      </Link>
      <div style={{ display: 'flex' }}>
        <Dot style={{ margin: 'auto 0' }} color={color} />
        <Typography.Text style={{ fontSize: '2rem', margin: 'auto 0 auto .5rem' }}>{count}</Typography.Text>
      </div>
    </div>
  );
};

// Keep columns on the frontend so we can use render
const largeTableColumns: ColumnsType<any> = [
  {
    title: 'Asset Name',
    dataIndex: 'displayName',
    key: 'displayName',
  },
  {
    title: 'Model Type',
    dataIndex: 'model',
    key: 'model',
  },
  {
    title: 'Serial Number',
    key: 'serialNumber',
    dataIndex: 'serialNumber',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => {
      const color = statuses.find((s) => s.name === status)?.color;
      return <Badge text={status} color={color} />;
    },
  },
  {
    title: 'Last Log',
    dataIndex: 'lastLog',
    key: 'lastLog',
    width: 150,
    render: (lastLog: string) => (lastLog ? dayjs(lastLog).fromNow() : '--'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    defaultSortOrder: 'ascend',
    sorter: (a?: any, b?: any) => {
      if (!a?.lastLog && !b?.lastLog) return 0;
      if (!a?.lastLog) return 1;
      if (!b?.lastLog) return -1;

      return dayjs(a?.lastLog).toDate().getTime() - dayjs(b?.lastLog).toDate().getTime();
    },
    showSorterTooltip: false,
  },
];
const smallTableColumns: ColumnsType<any> = [
  {
    title: 'Asset Name',
    dataIndex: 'displayName',
    key: 'displayName',
  },
  {
    title: 'Serial Number',
    key: 'serialNumber',
    dataIndex: 'serialNumber',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => {
      const color = statuses.find((s) => s.name === status)?.color;
      return <Badge text={status} color={color} />;
    },
  },
  {
    title: 'Last Log',
    dataIndex: 'lastLog',
    key: 'lastLog',
    render: (lastLog: string) => (lastLog ? dayjs(lastLog).fromNow() : '--'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    defaultSortOrder: 'ascend',
    sorter: (a?: any, b?: any) => {
      if (!a?.lastLog && !b?.lastLog) return 0;
      if (!a?.lastLog) return 1;
      if (!b?.lastLog) return -1;

      return dayjs(a?.lastLog).toDate().getTime() - dayjs(b?.lastLog).toDate().getTime();
    },
    showSorterTooltip: false,
  },
];

const statuses = [
  { name: 'Offline', slug: 'offline', color: baseTheme.color.red, priority: 1 },
  { name: 'No Logs', slug: 'noLogs', color: baseTheme.color.orange, priority: 2 },
  { name: 'RMA', slug: 'rma', color: baseTheme.color.yellow, priority: 3 },
  { name: 'Lost', slug: 'lost', color: baseTheme.color.slateBlue, priority: 4 },
  { name: 'Stolen', slug: 'stolen', color: baseTheme.color.riverBlue, priority: 5 },
  { name: 'Install Pending', slug: 'installPending', color: baseTheme.color.teal, priority: 6 },
  { name: 'Pending Deployment', slug: 'pendingDeployment', color: baseTheme.color.green, priority: 7 },
  { name: 'Healthy', slug: 'healthy', color: baseTheme.color.green300, priority: 8 },
];

interface ManagedAssetHealthTableRow {
  model: string;
  displayName: string;
  status: string;
  lastLog: string;
  serialNumber: string;
}

interface AssetStatusCounts {
  offline: number;
  noLogs: number;
  rma: number;
  lost: number;
  stolen: number;
  installPending: number;
  pendingDeployment: number;
  healthy: number;
}

interface ManagedAssetHealthWidgetData {
  tableData: ManagedAssetHealthTableRow[];
  counts: AssetStatusCounts;
}

const ManagedAssetHealthWidget = (props: WidgetComponentProps<void, ManagedAssetHealthWidgetData>) => {
  const navigate = useNavigate();
  const { data, size = 1 } = props;
  const { clientId } = useClientContext();

  if (!data)
    return (
      <SpinContainer>
        <Spin tip="Fetching Assets...">
          <div className="content" />
        </Spin>
      </SpinContainer>
    );

  // map counts to status config
  const statusCounts = statuses.map((s) => ({ ...s, count: data?.counts[`${s.slug}`] }));

  return (
    <>
      <div style={{ display: 'flex', marginBottom: '1rem' }}>
        <div style={{ height: '50%' }}>
          <Doughnut
            options={{
              onClick: (_event, array) => {
                if (array.length) {
                  // Gives the index in the data array
                  navigate(
                    `${queryDataTypePath({ clientId: clientId ?? '', dataType: 'snow-asset' })}?snow-asset-status=${
                      statuses[array[0].index].slug
                    }`,
                  );
                }
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
            data={{
              labels: statusCounts.map((s) => s.name),
              datasets: [
                {
                  data: statusCounts.map((s) => s.count),
                  backgroundColor: statusCounts.map((s) => s.color),
                },
              ],
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: `${size === 2 ? 'row' : 'column'}` }}>
          {[...statusCounts]
            .sort((a, b) => b.count - a.count)
            .slice(0, 4)
            .map((v) => (
              <LegendItem key={v.name} name={v.name} count={v.count} color={v.color} size={size} />
            ))}
        </div>
      </div>
      <div>
        <TableHeader>
          <Typography.Title level={5} style={{ margin: '0' }}>
            Managed Assets
          </Typography.Title>
          <Link
            to={`${queryDataTypePath({ clientId: clientId ?? '', dataType: 'snow-asset' })}`}
            style={{ margin: '0 .5rem 0 auto' }}
          >
            View All Assets
          </Link>
        </TableHeader>
        <Table
          columns={size === 2 ? largeTableColumns : smallTableColumns}
          dataSource={data?.tableData}
          pagination={false}
          size="small"
          scroll={{ y: 185 }}
        />
      </div>
    </>
  );
};

export default ManagedAssetHealthWidget;
