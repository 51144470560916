import React from 'react';
import styled from 'styled-components';

import { baseTheme } from 'components/theme';
import { Skeleton } from 'antd';

import * as NuIcon from 'components/nuspire/nu-icon';

export type PillarName = 'nsp' | 'tmt' | 'xcp' | 'case-management';

export interface NuTileProps {
  pillar: PillarName;
  count: string | number;
  description: string;
  loading?: boolean;
  footer?: FooterOptions;
}

interface FooterOptions {
  url: string;
  label: string;
}
interface PillarConfig {
  name: PillarName;
  color: string;
  icon: React.ReactNode;
}

const pillars: PillarConfig[] = [
  {
    name: 'nsp',
    color: baseTheme.color.primaryBlue,
    icon: (
      <NuIcon.NspIcon
        style={{
          fontSize: '32px',
        }}
      />
    ),
  },
  {
    name: 'tmt',
    color: baseTheme.color.red,
    icon: (
      <NuIcon.ThreatIcon
        style={{
          fontSize: '32px',
        }}
      />
    ),
  },
  {
    name: 'case-management',
    color: baseTheme.color.green,
    icon: (
      <NuIcon.CaseManagementIcon
        style={{
          fontSize: '32px',
        }}
      />
    ),
  },
];

/**
 * Styled Componnets.
 */
const Content = styled.div`
  min-height: 130px;
  padding: 8px;
  display: flex;
`;
const BigNumber = styled.div`
  padding-right: 50px;
  font-size: 46px;
  font-weight: bold;
  line-height: 1em;
  padding-bottom: 6px;
  margin-top: -6px;
  color: ${(p) => p.theme.token.colorText};
`;
const Divider = styled.div`
  height: 2px;
  width: 60px;
  background-color: ${(p) => p.theme.color.primaryGray};
  margin-bottom: 12px;
`;
const Description = styled.div`
  font-size: 16px;
  color: ${baseTheme.color.slateBlue};
  font-weight: bold;
`;
const IconColumn = styled.div`
  padding-right: 16px;
`;
const IconContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['backgroundColor'].includes(prop),
})<{
  backgroundColor: string;
}>`
  background-color: ${(p) => p.backgroundColor};
  width: 48px;
  height: 48px;
  color: ${(p) => p.theme.token.colorBgContainer};
  display: flex;
  flex-direction: colunn;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;
const StatColumn = styled.div``;

const LoadingSkeleton = styled(Skeleton.Avatar)`
  padding-bottom: 6px;
`;

function NuTile(props: NuTileProps) {
  const { count, description, pillar, loading = false } = props;
  const pillarConfig = pillars.find((p) => p.name === pillar);

  if (!pillarConfig) {
    return null;
  }

  return (
    <Content>
      <IconColumn>
        <IconContainer backgroundColor={pillarConfig.color}>{pillarConfig.icon}</IconContainer>
      </IconColumn>

      <StatColumn>
        {!count && loading ? <LoadingSkeleton shape="square" /> : <BigNumber>{`${count}`}</BigNumber>}
        <Divider />
        <Description>{description}</Description>
      </StatColumn>
    </Content>
  );
}

export default NuTile;
