import { wrapCreateBrowserRouter } from '@sentry/react';
import ClientHomePage from 'components/home/home';
import { Navigate, RouteObject, createBrowserRouter, redirect, useLocation, useNavigate } from 'react-router-dom';
import { RequireAdmin } from './components/admin/require-admin';
import { AppErrorBoundary } from './components/app-error-boundary';
import { useAuthContext } from './components/auth-context';
import { useClientContext } from './components/client-context-provider';
import { CONNECTORS } from './components/connections/connectors';
import { DefaultClientRedirect } from './components/default-client';
import { Features, useFeatureFlag } from './components/feature-flags';
import { LoginPage } from './components/login';
import { LoginCallback } from './components/login-callback';
import { LogoutPage } from './components/logout';
import { NutronLayout } from './components/nutron/common';
import SAMLCallback from './components/saml-callback';
import { SettingsLayout } from './components/settings/settings';
import SignUpPage from './components/sign-up';
import { SignUpConfirmationPage } from './components/sign-up-confirmation';
import { RequireAuthentication } from './require-authentication';

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const RedirectToClientRoute = () => {
  const location = useLocation();
  const { loading, user } = useAuthContext();

  if (!user?.clientId) {
    return <Navigate to="/" replace />;
  }

  if (loading) {
    // allow auth context to load if need be
    return null;
  }

  let subpath = location.pathname;
  // If we're trying to visit /:clientId/, go to /:clientId/home
  if (subpath === '/') {
    subpath = '/home';
  }

  return (
    <Navigate
      to={{
        ...location,
        pathname: `/${user.clientId}${subpath}`,
      }}
      replace
    />
  );
};

const connectionRedirectsRoutes = CONNECTORS.reduce((acc: RouteObject[], connector) => {
  if (connector.redirects) {
    const connectorRoutes = connector.redirects.map((r): RouteObject => {
      return {
        path: `/redirects/connections/${connector.slug}/${r.path}`,
        element: <r.component connectorSlug={connector.slug} />,
      };
    });

    acc.push(...connectorRoutes);
  }

  return acc;
}, []);

/**
 * Some notes:
 * 0.  Let's try and keep all of the routes in here for now. I had a hard time
 *     locating all of the routes necessary since they were nested 4-6 imports deep
 *     in some spots. If there's a really good reason to break it out, then we can
 *     talk about it.
 * 1.  If we need to redirect a route to another inside the router object here (ie: not in a component)
 *     and you need access to the route params (ex: :clientId), you can use the [loader] method
 *     to do so (can see examples below).
 * 2a. for lazy loading, you need to return the following structure:
 *     return { Component: MyComponent }
 *     it needs to be named Component according to the react-router docs
 * 2b. for lazy loading, try to stay away from default exports. I've seen issues
 *     already of it not loading properly when importing a default export, resulting
 *     in a blank screen.
 */
export const router = sentryCreateBrowserRouter([
  // Unauthenticated routes.
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/login/callback',
    element: <LoginCallback />,
  },
  {
    path: '/login/saml',
    element: <SAMLCallback />,
  },
  {
    path: '/logout',
    element: <LogoutPage />,
  },
  {
    path: '/sign-up',
    element: <SignUpPage />,
  },
  {
    path: '/sign-up/confirmation',
    element: <SignUpConfirmationPage />,
  },

  // Authenticated routes.
  {
    element: <RequireAuthentication />,
    errorElement: <AppErrorBoundary />,
    children: [
      {
        path: '/',
        element: <DefaultClientRedirect />,
      },
      // Admin routes
      {
        path: '/admin',
        element: <RequireAdmin />,
        children: [
          {
            path: '',
            async lazy() {
              const { AdminDashboard } = await import('components/admin/admin-dashboard');
              // Has to be named "Component" according to react-router-dom docs...
              return { Component: AdminDashboard };
            },
          },
          {
            path: 'audit-logs',
            async lazy() {
              const { AdminAuditLogs } = await import('components/admin/audit-logs');
              return { Component: AdminAuditLogs };
            },
          },
          {
            path: 'task-handler',
            loader: async () => redirect('/admin/task-handler/overview', { status: 301 }),
          },
          {
            path: 'task-handler/overview',
            async lazy() {
              const { TaskHandlerOverview } = await import(
                'components/admin/task-handler/task-handler-overview/task-handler-overview'
              );
              return { Component: TaskHandlerOverview };
            },
          },
          {
            path: 'task-handler/errors',
            async lazy() {
              const { TaskErrorList } = await import('components/admin/task-handler/task-error-list');
              return { Component: TaskErrorList };
            },
          },
          {
            path: 'task-handler/errors/:id',
            async lazy() {
              const { TaskErrorDetail } = await import('components/admin/task-handler/task-error-detail');
              return { Component: TaskErrorDetail };
            },
          },
          {
            path: 'task-handler/logs',
            async lazy() {
              const { TaskLogList } = await import('components/admin/task-handler/task-error-list');
              return { Component: TaskLogList };
            },
          },
          {
            path: 'task-handler/logs/:id',
            async lazy() {
              const { TaskLogDetailRoute } = await import('components/admin/task-handler/task-error-detail');
              return { Component: TaskLogDetailRoute };
            },
          },
          {
            path: 'task-handler/tasks',
            async lazy() {
              const { TaskListPage } = await import('components/admin/task-handler/task-list/task-list-route');
              return { Component: TaskListPage };
            },
          },
          {
            path: 'schedules',
            async lazy() {
              const { SchedulesListingPage } = await import('components/admin/schedules/schedules-table');
              return { Component: SchedulesListingPage };
            },
          },
          {
            path: 'clients',
            async lazy() {
              const { AllClients } = await import('components/admin/clients/clients');
              return { Component: AllClients };
            },
          },
          {
            path: 'clients/import',
            async lazy() {
              const { ImportClientsPage } = await import('components/admin/clients/import-clients');
              return { Component: ImportClientsPage };
            },
          },
          {
            path: 'clients/:clientId',
            async lazy() {
              const { AdminClientDetailsView } = await import('components/admin/clients/client-details');
              return { Component: AdminClientDetailsView };
            },
          },
          {
            path: 'clients/:clientId/user-groups/:userGroupId',
            async lazy() {
              const { AdminUserGroupDetail } = await import('components/admin/clients/client-user-groups');
              return { Component: AdminUserGroupDetail };
            },
          },
          {
            path: 'schedules/create',
            async lazy() {
              const { CreateSchedulePage } = await import('components/admin/schedules/create-schedule');
              return { Component: CreateSchedulePage };
            },
          },
          {
            path: 'schedules/:clientId/:scheduleId',
            async lazy() {
              const { ScheduleDetailsPage } = await import('components/admin/schedules/schedule-details');
              return { Component: ScheduleDetailsPage };
            },
          },
          {
            path: 'cache',
            async lazy() {
              const { CacheAdminPage } = await import('components/admin/cache');
              return { Component: CacheAdminPage };
            },
          },
          {
            path: 'users',
            async lazy() {
              const { AllUsers } = await import('components/admin/users/users');
              return { Component: AllUsers };
            },
          },
          {
            path: 'users/import',
            async lazy() {
              const { ImportUsersPage } = await import('components/admin/users/import-users');
              return { Component: ImportUsersPage };
            },
          },
          {
            path: 'users/:userId',
            async lazy() {
              const { UserDetails } = await import('components/admin/users/user-details');
              return { Component: UserDetails };
            },
          },
          {
            path: 'rbac',
            async lazy() {
              const { RBACPage } = await import('components/admin/rbac');
              return { Component: RBACPage };
            },
          },
          {
            path: 'superusers',
            async lazy() {
              const { Superusers } = await import('components/admin/users/superusers');
              return { Component: Superusers };
            },
          },
          {
            path: 'dashboards',
            async lazy() {
              const { AdminDashboards } = await import('components/admin/dashboards');
              return { Component: AdminDashboards };
            },
          },
          {
            path: 'health-checks',
            async lazy() {
              const { HealthChecksPage } = await import('components/admin/health-checks');
              return { Component: HealthChecksPage };
            },
          },
          {
            path: 'industries',
            async lazy() {
              const { Industries } = await import('components/admin/nsp/industries');
              return { Component: Industries };
            },
          },
          {
            path: 'recommendations',
            async lazy() {
              const { RecommendationsTable } = await import(
                'components/admin/nsp/recommendations/recommendations-table'
              );
              return { Component: RecommendationsTable };
            },
          },
          {
            path: 'recommendations/recommendation',
            async lazy() {
              const { RecommendationForm } = await import('components/admin/nsp/recommendations/recommendation-form');
              return { Component: RecommendationForm };
            },
          },
          {
            path: 'recommendations/recommendation/:recommendationId',
            async lazy() {
              const { RecommendationForm } = await import('components/admin/nsp/recommendations/recommendation-form');
              return { Component: RecommendationForm };
            },
          },
          {
            path: 'domains',
            async lazy() {
              const { DomainsTable } = await import('components/admin/nsp/domains/domains-table');
              return { Component: DomainsTable };
            },
          },
          {
            path: 'domains/domain',
            async lazy() {
              const { DomainForm } = await import('components/admin/nsp/domains/domains-form');
              return { Component: DomainForm };
            },
          },
          {
            path: 'domains/:domainId',
            async lazy() {
              const { DomainForm } = await import('components/admin/nsp/domains/domains-form');
              return { Component: DomainForm };
            },
          },
          {
            path: 'controls',
            async lazy() {
              const { ControlsTable } = await import('components/admin/nsp/controls/controls-table');
              return { Component: ControlsTable };
            },
          },
          {
            path: 'controls/control',
            async lazy() {
              const { ControlForm } = await import('components/admin/nsp/controls/controls-form');
              return { Component: ControlForm };
            },
          },
          {
            path: 'controls/control/:controlId',
            async lazy() {
              const { ControlForm } = await import('components/admin/nsp/controls/controls-form');
              return { Component: ControlForm };
            },
          },
          {
            path: 'capabilities',
            async lazy() {
              const { CapabilitiesTable } = await import('components/admin/nsp/capabilities/capabilities-table');
              return { Component: CapabilitiesTable };
            },
          },
          {
            path: 'capabilities/capability',
            async lazy() {
              const { CapabilityForm } = await import('components/admin/nsp/capabilities/capabilities-form');
              return { Component: CapabilityForm };
            },
          },
          {
            path: 'capabilities/capability/:capabilityId',
            async lazy() {
              const { CapabilityForm } = await import('components/admin/nsp/capabilities/capabilities-form');
              return { Component: CapabilityForm };
            },
          },
          {
            path: 'industry-controls',
            async lazy() {
              const { IndustryControlsTable } = await import(
                'components/admin/nsp/industry-controls/industry-controls-table'
              );
              return { Component: IndustryControlsTable };
            },
          },
          {
            path: 'industry-controls/industry-control',
            async lazy() {
              const { IndustryControlForm } = await import(
                'components/admin/nsp/industry-controls/industry-controls-form'
              );
              return { Component: IndustryControlForm };
            },
          },
          {
            path: 'industry-controls/industry-control/:controlId/industry/:industryId',
            async lazy() {
              const { IndustryControlForm } = await import(
                'components/admin/nsp/industry-controls/industry-controls-form'
              );
              return { Component: IndustryControlForm };
            },
          },
          {
            path: 'notifications',
            loader: async () => redirect('/admin/notifications/overview', { status: 301 }),
          },
          {
            path: 'notifications/overview',
            async lazy() {
              const { AdminNotificationsOverview } = await import(
                'components/admin/notifications/admin-notifications-overview'
              );
              return { Component: AdminNotificationsOverview };
            },
          },
          {
            path: 'notifications/publish',
            async lazy() {
              const { AdminNotificationsPublishPage } = await import(
                'components/admin/notifications/admin-notifications-publish'
              );
              return { Component: AdminNotificationsPublishPage };
            },
          },
          {
            path: 'features',
            loader: async () => redirect('/admin/features/overview', { status: 301 }),
          },
          {
            path: 'features/overview',
            async lazy() {
              const { AdminFeatureListPage: AdminFeaturesOverviewPage } = await import(
                'components/admin/features/feature-list'
              );
              return { Component: AdminFeaturesOverviewPage };
            },
          },
          {
            path: 'features/:slug',
            async lazy() {
              const { AdminFeatureDetailsPage } = await import('components/admin/features/feature-details');
              return { Component: AdminFeatureDetailsPage };
            },
          },
          {
            path: 'compliance-framework',
            async lazy() {
              const { ComplianceFrameworkPage } = await import(
                'components/admin/compliance-framework/compliance-framework'
              );
              return { Component: ComplianceFrameworkPage };
            },
          },
          {
            path: 'compliance-framework/framework',
            async lazy() {
              const { FrameworkPage } = await import('components/admin/compliance-framework/framework');
              return { Component: FrameworkPage };
            },
          },
          {
            path: 'compliance-framework/framework/:frameworkId',
            async lazy() {
              const { FrameworkPage } = await import('components/admin/compliance-framework/framework');
              return { Component: FrameworkPage };
            },
          },
          {
            path: 'compliance-framework/framework/:frameworkId/control',
            async lazy() {
              const { FrameworkControlPage } = await import('components/admin/compliance-framework/control');
              return { Component: FrameworkControlPage };
            },
          },
          {
            path: 'compliance-framework/framework/:frameworkId/control/:controlId',
            async lazy() {
              const { FrameworkControlPage } = await import('components/admin/compliance-framework/control');
              return { Component: FrameworkControlPage };
            },
          },
          {
            path: 'compliance-framework/requirement',
            async lazy() {
              const { FrameworkRequirementPage } = await import('components/admin/compliance-framework/requirement');
              return { Component: FrameworkRequirementPage };
            },
          },
          {
            path: 'compliance-framework/requirement/:requirementId',
            async lazy() {
              const { FrameworkRequirementPage } = await import('components/admin/compliance-framework/requirement');
              return { Component: FrameworkRequirementPage };
            },
          },
          {
            path: 'neces',
            async lazy() {
              const { NECESAdminPage } = await import('components/admin/neces');
              return { Component: NECESAdminPage };
            },
          },
          {
            path: 'external-news',
            async lazy() {
              const { ExternalNewsAdminPage } = await import('components/admin/external-news');
              return { Component: ExternalNewsAdminPage };
            },
          },
          {
            path: 'parsers',
            async lazy() {
              const { TechnologySourceParsersPage } = await import('components/admin/technology-source-parsers/table');
              return { Component: TechnologySourceParsersPage };
            },
          },
          {
            path: 'parsers/create',
            async lazy() {
              const { ParserForm } = await import('components/admin/technology-source-parsers/form');
              return { Component: ParserForm };
            },
          },
          {
            path: 'parsers/edit/:id',
            async lazy() {
              const { ParserForm } = await import('components/admin/technology-source-parsers/form');
              return { Component: ParserForm };
            },
          },
        ],
      },
      {
        path: '/case-management/*',
        element: <RedirectToClientRoute />,
      },
      {
        path: '/connections',
        element: <RedirectToClientRoute />,
      },
      {
        path: '/cyberx',
        element: <RedirectToClientRoute />,
      },
      {
        path: '/dashboard',
        element: <RedirectToClientRoute />,
      },
      {
        path: '/knowledge/:id',
        element: <RedirectToClientRoute />,
      },
      {
        path: '/neces/activate',
        element: <RedirectToClientRoute />,
      },
      {
        path: '/notifications',
        async lazy() {
          const { NotificationsList } = await import('components/notifications/notifications-list/notifications-list');
          return { Component: NotificationsList };
        },
      },
      {
        path: '/notifications/settings',
        async lazy() {
          const { NotificationSettings } = await import(
            'components/notifications/notifications-list/notification-settings/notification-settings'
          );
          return { Component: NotificationSettings };
        },
      },
      {
        path: '/nsp',
        element: <RedirectToClientRoute />,
      },
      {
        path: '/profile',
        async lazy() {
          const { UserProfilePage } = await import('components/user-profile/user-profile');
          return { Component: UserProfilePage };
        },
      },
      ...connectionRedirectsRoutes,
      {
        path: '/services',
        async lazy() {
          const ServicesOverview = await import('components/services/services-overview');
          return { Component: ServicesOverview };
        },
      },
      {
        path: '/settings',
        element: <RedirectToClientRoute />,
      },
      // Assuming this has to be last in the list since it'll match "/*"
      // Client-contexted routes
      {
        path: '/:clientId',
        children: [
          // These are temporary redirects to help the transition to the new routing/navigation. Remove when we can.
          {
            path: 'dashboard/:dashboardId?',
            loader: async ({ params }) =>
              redirect(
                `/${params.clientId}/reporting-and-analysis/dashboards${params.dashboardId ? `/${params.dashboardId}` : ''}`,
                { status: 301 },
              ),
          },
          {
            path: 'data-explorer',
            loader: async ({ params }) =>
              redirect(`/${params.clientId}/reporting-and-analysis/data-explorer/query-builder`, { status: 301 }),
          },
          {
            path: 'widgets/:widgetId?',
            loader: async ({ params }) =>
              redirect(
                `/${params.clientId}/reporting-and-analysis/widgets${params.widgetId ? `/${params.widgetId}` : ''}`,
                { status: 301 },
              ),
          },
          {
            path: 'reports',
            loader: async ({ params }) =>
              redirect(`/${params.clientId}/reporting-and-analysis/reports`, { status: 301 }),
          },
          {
            path: 'escalations',
            loader: async ({ params }) => redirect(`/${params.clientId}/settings/escalations`, { status: 301 }),
          },
          {
            path: 'escalations',
            loader: async ({ params }) => redirect(`/${params.clientId}/settings/escalations`, { status: 301 }),
          },
          {
            path: 'users',
            loader: async ({ params }) => redirect(`/${params.clientId}/settings/users`, { status: 301 }),
          },
          {
            path: 'case-management',
            loader: async ({ params }) => redirect(`/${params.clientId}/case-management/overview`, { status: 301 }),
          },
          {
            path: 'connections',
            loader: async ({ params }) =>
              redirect(`/${params.clientId}/settings/integrations/connectors`, { status: 301 }),
          },
          {
            path: 'workflows',
            loader: async ({ params }) =>
              redirect(`/${params.clientId}/settings/automations/instances`, { status: 301 }),
          },
          {
            path: 'neces',
            loader: async ({ params }) => redirect(`/${params.clientId}/settings/neces/installs`, { status: 301 }),
          },
          {
            path: 'threats',
            loader: async ({ params }) => redirect(`/${params.clientId}/threats/threat-brief`, { status: 301 }),
          },

          // "normal" client routes
          {
            path: 'case-management/overview',
            async lazy() {
              const { CaseManagementOverview } = await import(
                'components/case-management/case-management-overview/case-management-overview'
              );
              return { Component: CaseManagementOverview };
            },
          },
          {
            path: 'case-management/cases',
            async lazy() {
              const { CaseManagement } = await import('components/case-management/cases-overview');
              return { Component: () => <CaseManagement type="cases" /> };
            },
          },
          {
            path: 'case-management/cases/new',
            async lazy() {
              const { OpenCasePage } = await import('components/case-management/open-case');
              return { Component: OpenCasePage };
            },
          },
          {
            path: 'case-management/cases/:number',
            async lazy() {
              const { CaseDetailPage } = await import('components/case-management/case-detail');
              return { Component: CaseDetailPage };
            },
          },
          {
            path: 'case-management/incidents',
            async lazy() {
              const { CaseManagement } = await import('components/case-management/cases-overview');
              return { Component: () => <CaseManagement type="incidents" /> };
            },
          },
          {
            path: 'case-management/incidents/:number',
            async lazy() {
              const { IncidentDetailPage } = await import('components/case-management/incident-detail');
              return { Component: IncidentDetailPage };
            },
          },
          {
            path: 'case-management/tasks',
            async lazy() {
              const { TaskList } = await import('components/case-management/tasks/task-list');
              return { Component: TaskList };
            },
          },
          {
            path: 'case-management/tasks/create',
            async lazy() {
              const { CreateClientTask } = await import('components/client-tasks/create-client-task');
              return { Component: CreateClientTask };
            },
          },
          {
            path: 'case-management/tasks/:taskId',
            async lazy() {
              const { ClientTaskDetails } = await import('components/client-tasks/client-task-details');
              return { Component: ClientTaskDetails };
            },
          },
          {
            path: 'cyberx',
            async lazy() {
              const { CyberXDashboard } = await import('components/cyberx/cyberx-dashboard');
              return { Component: CyberXDashboard };
            },
          },
          {
            path: 'cyberx/automations',
            async lazy() {
              const { AutomationListPage } = await import(
                'components/cyberx/automations/automation-list/automation-list'
              );
              return { Component: AutomationListPage };
            },
          },
          {
            path: 'cyberx/automations/:automationId',
            async lazy() {
              const { AutomationDetailPage } = await import(
                'components/cyberx/automations/automation-detail/automation-detail'
              );
              return { Component: AutomationDetailPage };
            },
          },
          {
            path: 'cyberx/data-sources',
            async lazy() {
              const { DataSourcesList } = await import(
                'components/cyberx/data-sources/data-sources-overview/data-sources-overview'
              );
              return { Component: DataSourcesList };
            },
          },
          {
            path: 'cyberx/data-sources/add',
            async lazy() {
              const { CreateDataSourcePage } = await import(
                'components/cyberx/data-sources/data-source-create/data-source-create'
              );
              return { Component: CreateDataSourcePage };
            },
          },
          {
            path: 'cyberx/data-sources/explore',
            async lazy() {
              const { ExploreDataSourcesPage } = await import(
                'components/cyberx/data-sources/data-sources-explore/data-sources-explore'
              );
              return { Component: ExploreDataSourcesPage };
            },
          },
          {
            path: 'cyberx/data-sources/:dataSourceId',
            async lazy() {
              const { DataSourceDetailPage } = await import(
                'components/cyberx/data-sources/data-source-detail/data-source-detail'
              );
              return { Component: DataSourceDetailPage };
            },
          },
          {
            path: 'cyberx/test-event',
            async lazy() {
              const { TestEventPage } = await import('components/cyberx/test-event/test-event');
              return { Component: TestEventPage };
            },
          },
          {
            path: 'cyberx/event/:eventId',
            async lazy() {
              const { EventDetailsPage } = await import('components/cyberx/event/event-details/event-details');
              return { Component: EventDetailsPage };
            },
          },
          {
            path: 'cyberx/event/:eventId/execute/:actionId',
            async lazy() {
              const { EventExecuteActionPage } = await import(
                'components/cyberx/event/event-execute-action/event-execute-action'
              );
              return { Component: EventExecuteActionPage };
            },
          },
          {
            path: 'cyberx/block-list',
            async lazy() {
              const { BlockListPage } = await import('components/cyberx/block-list/block-list');
              return { Component: BlockListPage };
            },
          },
          {
            path: 'home',
            element: <ClientHomePage />,
          },
          {
            path: 'knowledge',
            async lazy() {
              const { KnowledgeListPage } = await import('components/knowledge/knowledge-list');
              return { Component: KnowledgeListPage };
            },
          },
          {
            path: 'knowledge/:number',
            async lazy() {
              const { KnowledgeDetailPage } = await import('components/knowledge/knowledge-detail');
              return { Component: KnowledgeDetailPage };
            },
          },
          {
            path: 'nutron',
            element: <NutronLayout />,
            children: [
              {
                path: '',
                async lazy() {
                  const { NewChatPage } = await import('components/nutron/new-chat');

                  return {
                    Component: () => {
                      const isNutronEnabled = useFeatureFlag(Features.Nutron);
                      const navigate = useNavigate();
                      const { clientId } = useClientContext();

                      if (!isNutronEnabled) {
                        navigate(`/${clientId ?? ''}`);
                        return;
                      }

                      // eslint-disable-next-line consistent-return
                      return <NewChatPage />;
                    },
                  };
                },
              },
              {
                path: 'chat/:chatId',
                async lazy() {
                  const { NutronChatDetailsPage } = await import('components/nutron/chat-details');

                  return {
                    Component: () => {
                      const isNutronEnabled = useFeatureFlag(Features.Nutron);
                      const navigate = useNavigate();
                      const { clientId } = useClientContext();

                      if (!isNutronEnabled) {
                        navigate(`/${clientId ?? ''}`);
                        return;
                      }

                      // eslint-disable-next-line consistent-return
                      return <NutronChatDetailsPage />;
                    },
                  };
                },
              },
            ],
          },
          {
            path: 'nsp',
            loader: async ({ params }) => redirect(`/${params.clientId}/nsp/overview`, { status: 301 }),
          },
          {
            path: 'nsp/overview',
            async lazy() {
              const { NspOverviewPage } = await import('components/nsp/overview');
              return { Component: NspOverviewPage };
            },
          },
          {
            path: 'nsp/roadmap',
            async lazy() {
              const { NspRoadmapPage } = await import('components/nsp/roadmap');
              return { Component: NspRoadmapPage };
            },
          },
          {
            path: 'nsp/self-assessment',
            async lazy() {
              const { NspSelfAssessmentPage } = await import('components/nsp/self-assessment');
              return { Component: NspSelfAssessmentPage };
            },
          },
          {
            path: 'nsp/managed-clients',
            async lazy() {
              const { NspManagedClientsPage } = await import('components/nsp/managed-clients');
              return { Component: NspManagedClientsPage };
            },
          },
          {
            path: 'nsp/domain/:domainId',
            async lazy() {
              const { NspDomainDetailPage } = await import('components/nsp/domain-detail');
              return { Component: NspDomainDetailPage };
            },
          },
          {
            path: 'nsp/domain/:domainId/control/:controlId',
            async lazy() {
              const { NspControlDetailPage } = await import('components/nsp/control-detail');
              return { Component: NspControlDetailPage };
            },
          },
          {
            path: 'reporting-and-analysis',
            loader: async ({ params }) =>
              redirect(`/${params.clientId}/reporting-and-analysis/overview`, { status: 301 }),
          },
          {
            path: 'reporting-and-analysis/overview',
            async lazy() {
              const { ReportingAndAnalysisOverviewPage } = await import(
                'components/reporting-and-analysis/reporting-and-analysis-overview/reporting-and-analysis-overview'
              );
              return { Component: ReportingAndAnalysisOverviewPage };
            },
          },
          {
            path: 'reporting-and-analysis/dashboards/report-cover-page',
            async lazy() {
              const { DashboardReportCoverPageView } = await import('components/reports/cover-page');
              return { Component: DashboardReportCoverPageView };
            },
          },
          {
            path: 'reporting-and-analysis/dashboards/report-back-page',
            async lazy() {
              const { DashboardReportBackPageView } = await import('components/reports/back-page');
              return { Component: DashboardReportBackPageView };
            },
          },
          {
            path: 'reporting-and-analysis/dashboards/:dashboardId/report',
            async lazy() {
              const { DashboardReport } = await import('components/dashboard/dashboard-report');
              return { Component: DashboardReport };
            },
          },
          {
            path: 'reporting-and-analysis/dashboards/:dashboardId?',
            async lazy() {
              const { DashboardDetailsPage } = await import('components/dashboard/dashboard-details');
              return { Component: DashboardDetailsPage };
            },
          },
          {
            path: 'reporting-and-analysis/data-explorer',
            loader: async ({ params }) =>
              redirect(`/${params.clientId}/reporting-and-analysis/data-explorer/query-builder`, { status: 301 }),
          },
          {
            path: 'reporting-and-analysis/data-explorer/query-builder',
            async lazy() {
              const { DataExplorerPage } = await import('components/data-explorer/data-explorer');
              return { Component: DataExplorerPage };
            },
          },
          {
            path: 'reporting-and-analysis/data-explorer/query-builder/:dataType',
            async lazy() {
              const { DataExplorerPage } = await import('components/data-explorer/data-explorer');
              return { Component: DataExplorerPage };
            },
          },
          {
            path: 'reporting-and-analysis/reports',
            async lazy() {
              const { ReportsPage } = await import('components/reporting-and-analysis/reports/reports');
              return { Component: ReportsPage };
            },
          },
          {
            path: 'reporting-and-analysis/widgets/create/:widgetSlug',
            async lazy() {
              const { CreateWidgetPage } = await import('components/widgets/create-widget');
              return { Component: CreateWidgetPage };
            },
          },
          {
            path: 'reporting-and-analysis/widgets/:widgetId',
            async lazy() {
              const { WidgetDetailsPage } = await import('components/widgets/widget-details');
              return { Component: WidgetDetailsPage };
            },
          },
          {
            path: 'services',
            async lazy() {
              const { ClientServicesPage } = await import('components/services/client-services');
              return { Component: ClientServicesPage };
            },
          },
          {
            path: 'settings',
            element: <SettingsLayout />,
            children: [
              {
                path: '',
                loader: async ({ params }) => redirect(`/${params.clientId}/settings/overview`, { status: 301 }),
              },
              {
                path: 'overview',
                async lazy() {
                  const { SettingsOverview } = await import('components/settings/settings-overview/settings-overview');
                  return { Component: SettingsOverview };
                },
              },
              {
                path: 'automations',
                loader: async ({ params }) =>
                  redirect(`/${params.clientId}/settings/automations/instances`, { status: 301 }),
              },
              {
                path: 'automations/instances',
                async lazy() {
                  const { AutomationsListPage } = await import('components/automations/automations-list');
                  return { Component: AutomationsListPage };
                },
              },
              {
                path: 'automations/instances/:id',
                async lazy() {
                  const { AutomationDetailPage } = await import(
                    'components/automations/automation-detail/automation-detail'
                  );
                  return { Component: AutomationDetailPage };
                },
              },
              {
                path: 'automations/explore',
                async lazy() {
                  const { ExploreAutomationsPage } = await import('components/automations/explore-automations');
                  return { Component: ExploreAutomationsPage };
                },
              },
              {
                path: 'automations/add',
                async lazy() {
                  const { CreateAutomationPage } = await import('components/automations/create-automation');
                  return { Component: CreateAutomationPage };
                },
              },
              {
                path: 'automations/def/:slug',
                async lazy() {
                  const { AutomationDefinitionDetailsPage } = await import(
                    'components/automations/automation-definition-details'
                  );
                  return { Component: AutomationDefinitionDetailsPage };
                },
              },
              {
                path: 'assets',
                async lazy() {
                  const { AssetSettingsPage } = await import('components/settings/assets');
                  return { Component: AssetSettingsPage };
                },
              },
              {
                path: 'block-list',
                async lazy() {
                  const { BlockListSettings } = await import('components/settings/block-list');
                  return { Component: BlockListSettings };
                },
              },
              {
                path: 'escalations',
                async lazy() {
                  const { ClientRunbookEscalationsList } = await import(
                    'components/runbook-escalations/client-runbook-escalations-list'
                  );
                  return { Component: ClientRunbookEscalationsList };
                },
              },
              {
                path: 'escalations/user/:snowUserId',
                async lazy() {
                  const { EditRunbookEscalationPage } = await import(
                    'components/runbook-escalations/edit-runbook-escalation'
                  );
                  return { Component: EditRunbookEscalationPage };
                },
              },
              {
                path: 'neces',
                loader: async ({ params }) => redirect(`/${params.clientId}/settings/neces/installs`, { status: 301 }),
              },
              {
                path: 'neces/activate',
                async lazy() {
                  const { NECESActivationPage } = await import('components/neces/activation');
                  return { Component: NECESActivationPage };
                },
              },
              {
                path: 'neces/installs',
                async lazy() {
                  const { NECESListPage } = await import('components/neces/list');
                  return { Component: NECESListPage };
                },
              },
              {
                path: 'neces/installs/:necesId',
                async lazy() {
                  const { NECESInstallDetailsPage } = await import('components/neces/install-details');
                  return { Component: NECESInstallDetailsPage };
                },
              },
              {
                path: 'integrations',
                loader: async ({ params }) =>
                  redirect(`/${params.clientId}/settings/integrations/connectors`, { status: 301 }),
              },
              {
                path: 'integrations/connectors',
                async lazy() {
                  const { ConnectionsListPage } = await import('components/connections/connections-list');
                  return { Component: ConnectionsListPage };
                },
              },
              {
                path: 'integrations/connectors/:connectorSlug',
                async lazy() {
                  const { ConnectorClientConnectionsList } = await import(
                    'components/connections/connector-detail/connector-detail'
                  );
                  return { Component: ConnectorClientConnectionsList };
                },
              },
              {
                path: 'integrations/connectors/:connectorSlug/:connectionId',
                async lazy() {
                  const { ConnectionDetail } = await import(
                    'components/connections/connector-detail/connection-detail/connection-detail'
                  );
                  return { Component: ConnectionDetail };
                },
              },
              {
                path: 'sso',
                async lazy() {
                  const { SSOSettingsPage } = await import('components/settings/sso');
                  return { Component: SSOSettingsPage };
                },
              },
              {
                path: 'managed-clients',
                async lazy() {
                  const { ManagedClientsSettingsPage } = await import('components/settings/managed-clients');
                  return { Component: ManagedClientsSettingsPage };
                },
              },
              {
                path: 'managed-clients/add',
                async lazy() {
                  const { AddManagedClient } = await import('components/settings/managed-clients');
                  return { Component: AddManagedClient };
                },
              },
              {
                path: 'groups',
                async lazy() {
                  const { UserGroupsListSettingsPage } = await import('components/settings/user-groups');
                  return { Component: UserGroupsListSettingsPage };
                },
              },
              {
                path: 'groups/:userGroupId',
                async lazy() {
                  const { UserGroupDetailsPage } = await import('components/settings/user-groups');
                  return { Component: UserGroupDetailsPage };
                },
              },
              {
                path: 'users',
                async lazy() {
                  const { OrganizationUsersList } = await import('components/settings/organization-users');
                  return { Component: OrganizationUsersList };
                },
              },
              {
                path: 'users/:userId',
                async lazy() {
                  const { OrganizationUserDetailPage } = await import('components/settings/organization-users');
                  return { Component: OrganizationUserDetailPage };
                },
              },
            ],
          },
          {
            path: 'threats/threat-brief',
            async lazy() {
              const { ThreatBriefListingPage } = await import('components/threats/threat-brief/threat-brief-listing');
              return { Component: ThreatBriefListingPage };
            },
          },
          {
            path: 'threats/threat-brief/:publicationNumber',
            async lazy() {
              const { ThreatBriefDetailPage } = await import('components/threats/threat-brief/threat-brief-detail');
              return { Component: ThreatBriefDetailPage };
            },
          },
          {
            path: 'threats/threat-modeling-tool',
            async lazy() {
              const { ThreatModelingToolPage } = await import('components/threats/threat-modeling-tool-page');
              return { Component: ThreatModelingToolPage };
            },
          },
        ],
      },
      {
        // TODO: redirect to /:clientId/home
        path: '*',
      },
    ],
  },
]);
