import { forwardRef, MutableRefObject, ReactNode, useState } from 'react';
import { Chart, ChartOptions } from 'chart.js';
import { Doughnut as BaseDoughnut, ChartProps } from 'react-chartjs-2';
import styled, { useTheme } from 'styled-components';
import merge from 'lodash.merge';

const DoughnutContainer = styled.div<{ height: number }>`
  width: 100%;
  height: ${(props) => props.height}%;
  position: relative;
`;

const CenterContent = styled.div<{
  left: number;
  top: number;
}>`
  position: absolute;
  left: ${({ left }) => `${left}px`};
  top: ${({ top }) => `${top}px`};
  transform: translate(-50%, -50%);
`;

export interface DoughnutProps extends Omit<ChartProps<'doughnut'>, 'type'> {
  centerContent?: ReactNode;
  doughnutHeight?: number;
}

export const Doughnut = forwardRef((props: DoughnutProps, ref) => {
  const { centerContent, doughnutHeight, ...newProps } = props;
  const [centerContentWrapper, setCenterContentWrapper] = useState<JSX.Element>();
  const theme = useTheme();

  const renderCenterContent = (chart: Chart<'doughnut'>) => {
    if (centerContent && chart?.chartArea) {
      const { left, top, width, height } = chart.chartArea;

      setCenterContentWrapper(
        <CenterContent left={left + width / 2} top={top + height / 2}>
          {centerContent}
        </CenterContent>,
      );
    }
  };

  const chartCallback = (chart?: Chart<'doughnut', unknown> | null) => {
    if (!chart) return;

    if (ref) {
      if (typeof ref === 'function') ref(chart);
      (ref as MutableRefObject<any>).current = chart;
    }

    renderCenterContent(chart as Chart<'doughnut'>);
  };

  const onResize = (chart: Chart<'doughnut'>, size: { width: number; height: number }) => {
    setTimeout(() => {
      renderCenterContent(chart);
    }, 0);
    props.options?.onResize?.(chart as Chart, size);
  };

  const baseOptions: ChartOptions<'doughnut'> = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        maxHeight: 50,
      },
    },
    borderColor: theme.token.colorBgContainer,
  };

  const options: ChartOptions<'doughnut'> = merge(baseOptions, props.options, { onResize });
  newProps.options = options;

  return (
    <DoughnutContainer height={doughnutHeight ?? 100}>
      <BaseDoughnut ref={chartCallback} {...newProps} />
      {centerContentWrapper}
    </DoughnutContainer>
  );
});

Doughnut.displayName = 'Doughnut';
