import { ComponentProps, useState } from 'react';
import styled from 'styled-components';
import { Time as TimeIcon } from 'components/nuspire/nu-icon';
import { NuButton } from 'components/nuspire';
import { Popover } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '../../date-picker';

const { RangePicker } = DatePicker;
const TrlTitle = styled.div`
  padding: 4px 8px;
  font-weight: 700;
`;

/**
 * Absolute Range Picker
 */
const ArpRoot = styled.div``;

interface InternalProps extends TimeRangeButtonProps {
  onCalendarChange: ComponentProps<typeof RangePicker>['onCalendarChange'];
}

function AbsoluteRangePicker(props: InternalProps) {
  const { to, from, onRangeChange, disabledDate, onCalendarChange, relativeOptions = timeRangeOptions } = props;
  const matchingOption = relativeOptions.find((opt) => opt.from === from && opt.to === to);

  return (
    <ArpRoot>
      <TrlTitle>Absolute</TrlTitle>
      <RangePicker
        showTime // allow user to select time.
        value={!matchingOption && from && to ? [dayjs(from), dayjs(to)] : undefined}
        onChange={(values) => {
          const fromDayjs = values?.[0];
          const toDayjs = values?.[1];

          if (fromDayjs && toDayjs) {
            const fromISO = fromDayjs.toISOString();
            const toISO = toDayjs.toISOString();
            onRangeChange({
              from: fromISO,
              to: toISO,
              rawFrom: fromISO,
              rawTo: toISO,
            });
          }
        }}
        disabledDate={disabledDate}
        onCalendarChange={onCalendarChange}
      />
    </ArpRoot>
  );
}

export const disabledDateOptions = {
  '-now': (currentDate: Dayjs) => {
    return currentDate && currentDate > dayjs();
  },
  '-nowEOD': (currentDate: Dayjs) => {
    return currentDate && currentDate > dayjs().endOf('day');
  },
  '3months-': (currentDate: Dayjs) => {
    return currentDate && currentDate.isBefore(dayjs().subtract(3, 'months').startOf('day'));
  },
  '3months-now': (currentDate: Dayjs) => {
    return disabledDateOptions['-now'](currentDate) || disabledDateOptions['3months-'](currentDate);
  },
  '3months-nowEOD': (currentDate: Dayjs) => {
    return disabledDateOptions['-nowEOD'](currentDate) || disabledDateOptions['3months-'](currentDate);
  },
} as const;

export type TimeRangeOption = {
  key: string;
  label: string;
  from: string;
  to: string;
  toISOString: () => { from: string; to: string };
};

export const timeRangeOptions: TimeRangeOption[] = [
  {
    key: 'now-5m',
    label: 'Last 5 Minutes',
    from: 'now-5m',
    to: 'now',
    toISOString: () => ({
      from: dayjs().subtract(5, 'minutes').toISOString(),
      to: dayjs().toISOString(),
    }),
  },
  {
    key: 'now-15m',
    label: 'Last 15 Minutes',
    from: 'now-15m',
    to: 'now',
    toISOString: () => ({
      from: dayjs().subtract(15, 'minutes').toISOString(),
      to: dayjs().toISOString(),
    }),
  },
  {
    key: 'now-30m',
    label: 'Last 30 Minutes',
    from: 'now-30m',
    to: 'now',
    toISOString: () => ({
      from: dayjs().subtract(30, 'minutes').toISOString(),
      to: dayjs().toISOString(),
    }),
  },
  {
    key: 'now-1hr',
    label: 'Last 1 hour',
    from: 'now-1hr',
    to: 'now',
    toISOString: () => ({
      from: dayjs().subtract(1, 'hour').toISOString(),
      to: dayjs().toISOString(),
    }),
  },
  {
    key: 'now-6hr',
    label: 'Last 6 hours',
    from: 'now-6hr',
    to: 'now',
    toISOString: () => ({
      from: dayjs().subtract(6, 'hours').toISOString(),
      to: dayjs().toISOString(),
    }),
  },
  {
    key: 'now-24hr',
    label: 'Last 24 hours',
    from: 'now-24hr',
    to: 'now',
    toISOString: () => ({
      from: dayjs().subtract(24, 'hours').toISOString(),
      to: dayjs().toISOString(),
    }),
  },
  {
    key: 'now-7d',
    label: 'Last 7 days',
    from: 'now-7d',
    to: 'now',
    toISOString: () => ({
      from: dayjs().subtract(7, 'days').toISOString(),
      to: dayjs().toISOString(),
    }),
  },
];

export const parseRelativeTimeRangeKey = (key: string | null, relativeOptions: TimeRangeOption[]) => {
  return relativeOptions.find((o) => o.key === key)?.toISOString();
};

export type DateRange = {
  from: string | null;
  to: string | null;
};

const TrlRoot = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
`;
const TrlOption = styled.div`
  padding: 4px 8px;
  cursor: pointer;
  &.active,
  &:hover {
    background-color: ${(p) => p.theme.token.colorBgTextHover};
  }
`;

function TimeRangeList(props: TimeRangeButtonProps) {
  const { from, to, onRangeChange, relativeOptions = timeRangeOptions } = props;
  const matchingOption = relativeOptions.find((opt) => opt.from === from && opt.to === to);

  return (
    <TrlRoot>
      <TrlTitle>Relative</TrlTitle>

      {relativeOptions.map((opt) => (
        <TrlOption
          key={opt.key}
          className={matchingOption && matchingOption.key === opt.key ? 'active' : undefined}
          onClick={() => {
            const rangeIso = opt.toISOString();
            onRangeChange({
              from: rangeIso.from,
              to: rangeIso.to,
              rawFrom: opt.from,
              rawTo: opt.to,
            });
          }}
        >
          {opt.label}
        </TrlOption>
      ))}
    </TrlRoot>
  );
}

/**
 * Popover Content
 */
const TrsRoot = styled.div``;
const TrsList = styled.div`
  width: 140px;
  margin-right: 16px;
  border-right: 1px solid ${(p) => p.theme.token.colorBorder};
`;
const TrsAbsolute = styled.div`
  flex: 1;
  height: 100%;
  & .ant-picker {
    width: 100%;
  }
  margin-bottom: 16px;
`;
const TrsSelectors = styled.div`
  display: flex;
  position: relative;
  width: 400px;
  max-height: 200px;
  border-bottom: 1px solid ${(p) => p.theme.token.colorBorder};
`;
const TrsFooter = styled.div`
  padding-top: 16px;
`;

function TimeRangeSelect(props: InternalProps) {
  const { showTimeRangeList = true, onRangeChange } = props;

  return (
    <TrsRoot>
      <TrsSelectors>
        {showTimeRangeList && (
          <TrsList>
            <TimeRangeList {...props} />
          </TrsList>
        )}

        <TrsAbsolute>
          <AbsoluteRangePicker {...props} />
        </TrsAbsolute>
      </TrsSelectors>
      <TrsFooter>
        <NuButton size="small" onClick={() => onRangeChange({ from: null, to: null, rawFrom: null, rawTo: null })}>
          Clear Filter
        </NuButton>
      </TrsFooter>
    </TrsRoot>
  );
}

/**
 * Button
 */
function getButtonLabel({ from, to }: DateRange) {
  // look for match from options
  const matchingOption = timeRangeOptions.find((opt) => opt.from === from && opt.to === to);
  if (matchingOption) {
    return matchingOption.label;
  }

  if (from && to) {
    return `${dayjs(from).format('lll')} to ${dayjs(to).format('lll')}`;
  }

  return `Select Time Range`;
}

export interface OnRangeChangeArgs {
  /**
   * ISO String (will not be a relative key such as 'now-15m')
   */
  from: string | null;
  /**
   * ISO String (will not be a relative key such as 'now-15m')
   */
  to: string | null;
  /**
   * ISO String or relative key (e.g. 'now-15m')
   */
  rawFrom: string | null;
  /**
   * ISO String or relative key (e.g. 'now-15m')
   */
  rawTo: string | null;
}

export interface TimeRangeButtonProps extends DateRange {
  onRangeChange: (args: OnRangeChangeArgs) => void;

  // eslint-disable-next-line react/no-unused-prop-types
  disabledDate?: ComponentProps<typeof RangePicker>['disabledDate'];

  // eslint-disable-next-line react/no-unused-prop-types
  showTimeRangeList?: boolean;

  relativeOptions?: TimeRangeOption[];
}

export function TimeRangeButton(props: TimeRangeButtonProps) {
  const { from, to, onRangeChange, showTimeRangeList = true, relativeOptions = timeRangeOptions } = props;
  const buttonLabel = getButtonLabel({ from, to });
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [firstCalendar, setFirstCalendar] = useState<'start' | 'end'>();

  const hidePopover = () => {
    setIsPopoverOpen(false);
    setFirstCalendar(undefined);
  };

  return (
    <Popover
      onOpenChange={(open) => setIsPopoverOpen(open)}
      open={isPopoverOpen}
      content={
        <TimeRangeSelect
          {...props}
          onRangeChange={(args) => {
            onRangeChange(args);
            if (parseRelativeTimeRangeKey(args.rawFrom, relativeOptions)) {
              hidePopover();
            }
          }}
          showTimeRangeList={showTimeRangeList}
          onCalendarChange={(_values, _formatString, info) => {
            if (!firstCalendar) {
              setFirstCalendar(info.range);
              return;
            }

            if (info.range !== firstCalendar) {
              hidePopover();
            }
          }}
        />
      }
      trigger={['click']}
      placement="bottomRight"
    >
      <NuButton icon={<TimeIcon />}>{buttonLabel}</NuButton>
    </Popover>
  );
}

export default TimeRangeButton;
