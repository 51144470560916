import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';
import { Table } from 'antd';
import baseTheme from 'components/theme';
import { WidgetComponentProps } from '../..';
import EmptyState from 'components/nuspire/nu-empty-state';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopBlockedCategories = (props: WidgetComponentProps<any, any> /* type these once we get real data */) => {
  const { data, configuration } = props;

  if (!data) return <EmptyState>No Data Found.</EmptyState>;

  const labels = data?.tableData?.map((s) => s.category);

  return (
    <FlexContainer>
      <div>
        <Bar
          height={225}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
              },
            },
          }}
          data={{
            labels,
            datasets: [
              {
                label: 'Top Source',
                data: data?.tableData?.map((d) => d.blockedRequestCount * d.topSourcePercent),
                backgroundColor: baseTheme.color.nuspireBlue,
              },
              {
                label: 'All Other Sources',
                data: data?.tableData?.map((d) => d.blockedRequestCount * (1 - d.topSourcePercent)),
                backgroundColor: baseTheme.color.slateBlue,
              },
            ],
          }}
        />
      </div>
      <div data-intercom-target="top-blocked-categories-table">
        <Table columns={configuration?.tableColumns} dataSource={data?.tableData} pagination={false} size="small" />
      </div>
    </FlexContainer>
  );
};

export default TopBlockedCategories;
