import { Select } from 'antd';
import { ActionInputProps } from '../action-form-field';

const healthCheckTypes = [
  {
    label: 'Device Health Check',
    value: 'mgsDeviceHealth',
  },
  {
    label: 'Base Health Checks (default)',
    value: 'healthChecks',
  },
  {
    label: 'RISE Non Compliance Check',
    value: 'nonComplianceCheck',
  },
];

const HealthCheckTypesSelect = (props: ActionInputProps) => {
  const { value, onChange } = props;

  return (
    <Select value={value} onChange={(values) => onChange(values)}>
      {healthCheckTypes.map((f) => (
        <Select.Option key={f.value} value={f.value}>
          {f.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default HealthCheckTypesSelect;
