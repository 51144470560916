import { Meta, StoryFn } from '@storybook/react';
import styled from 'styled-components';
import EmptyState, { NuEmptyStateProps } from './nu-empty-state';
import { NuButton, NuCard } from 'components/nuspire';
import { Space, Typography } from 'antd';

export default {
  title: 'My Nuspire/Common/NuEmptyState',
  component: EmptyState,
} as Meta;

/**
 * This should be a reusable component....
 */
const Layout = styled.div`
  display: flex;
  justify-content: center;
`;
const Content = styled.div`
  max-width: 1200px;
  width: 100%;
`;

const Template: StoryFn<NuEmptyStateProps> = (args) => (
  <Layout>
    <Content>
      <EmptyState {...args} />
    </Content>
  </Layout>
);

export const NuEmptyState = Template.bind({});
NuEmptyState.args = {
  children: `We couldn't find any data for this right now. would you like to go ahead and do something awesome?`,
  actions: <NuButton type="primary">Do something awesome!</NuButton>,
};

const MESSAGE = `We couldn't find any data for this right now. would you like to go ahead and do something awesome?`;

export const NuEmptyStates = () => (
  <Layout>
    <Content>
      <Space direction="vertical" size={100} style={{ width: '100%' }}>
        <div>
          <Typography.Title level={5}>Use `gray` variant when empty state is outside any card.</Typography.Title>
          <EmptyState
            actions={
              <>
                <NuButton type="primary">Do something awesome!</NuButton>
                <NuButton>Do something else.</NuButton>
              </>
            }
          >
            {MESSAGE}
          </EmptyState>
        </div>

        <div>
          <Typography.Title level={5}>Use transparent variant inside cards.</Typography.Title>

          <NuCard title="Card Title">
            <EmptyState
              variant="transparent"
              actions={
                <>
                  <NuButton type="primary">Do something awesome!</NuButton>
                  <NuButton>Do something else.</NuButton>
                </>
              }
            >
              {MESSAGE}
            </EmptyState>
          </NuCard>
        </div>
      </Space>
    </Content>
  </Layout>
);
