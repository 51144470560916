import { CheckOutlined, CloudUploadOutlined, EditOutlined, LinkOutlined, StopOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Alert, Checkbox, Divider, Space, Table, Tabs, TabsProps, Tag, Tooltip, Typography, message } from 'antd';
import ClientActionButtons from 'components/admin/clients/client-action-buttons';
import { useAuthContext } from 'components/auth-context';
import { useAuthorize } from 'components/authorization';
import { useClientContext } from 'components/client-context-provider';
import { partnerBusinessTypes, partnerTypes } from 'components/clients/partner-client-form-items';
import MutationButton from 'components/mutation-button';
import { EmptyState, Link, NuButton, NuCard, NuCardContent, Spacer } from 'components/nuspire';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { CopyToClipboardIcon } from 'components/shared-components';
import baseTheme from 'components/theme';
import ExternalUserActionButtons from 'components/users/user-management/external-user-buttons';
import UserActionButtons from 'components/users/user-management/user-action-buttons';
import UserGroups from 'components/users/user-management/user-groups';
import UserStatus from 'components/users/user-management/user-status';
import dayjs from 'dayjs';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Access, ClientType, IClient, IUser, PartnerDetails } from 'types/index';
import { formatPhoneNumber } from 'utils/phone-number';
import { config } from '../../../config';
import { getServiceNowAccountCategory } from '../../../utils/servicenow';
import { humanReadableBoolean } from '../../../utils/strings';
import { DetailsItem } from '../../details-item';
import UserInviteForm from '../../users/user-invite-form';
import { ChangeParentClientButtonAndModal } from './change-parent-client-button-and-modal';
import ClientApplications from './client-applications';
import ClientContextButton from './client-context-button';
import { UPDATE_CLIENT, anomaliThresholdOptions } from './client-details-form';
import { ClientFeatureManagement } from './client-feature-management';
import { ClientIdentifiers } from './client-identifiers';
import CreateAppModal from './create-app-modal';

const APPROVE_PARTNER_CLIENT_MUTATION = gql`
  mutation ApprovePartnerClient($clientId: String!) {
    approvePartnerClient(clientId: $clientId) {
      id
    }
  }
`;

const REVOKE_PARTNER_CLIENT_MUTATION = gql`
  mutation RevokePartnerClient($clientId: String!) {
    revokePartnerClient(clientId: $clientId) {
      id
    }
  }
`;

interface ClientDetailsInfoProps {
  client?: IClient;
  clientId: string;
  groups?: any[];
  loading: boolean;
  refetch: () => void;
  refetchUsers: () => void;
  setIsEditing: (isEditing: boolean) => void;
  tabKeysToShow?: string[];
  users?: IUser[];
}

const ClientDetails = styled.div`
  display: flex;
  flex-wrap: wrap;

  .nu-card {
    display: flex;
    flex-basis: 500px;
    height: 100%;

    .nu-paper {
      flex: 1;
    }
  }
`;

const ClientMainDetails = styled.div`
  min-width: 500px;
  margin-right: 2rem;
`;

const ClientPartnerDetails = styled.div`
  max-width: 500px;
`;

const ActionsRow = styled(Space)`
  margin-top: 1rem;
`;

const ColoredSpan = styled.span`
  color: ${(props) => props.color || 'red'};
`;

type ApprovePartnerClientButtonProps = {
  authorized: boolean;
  clientId: string;
  refetchClientData: () => void;
};

function ApprovePartnerClientButton(props: ApprovePartnerClientButtonProps) {
  const { authorized, clientId, refetchClientData } = props;

  const onApproveSuccess = () => {
    message.success('Partner client approved!');
    refetchClientData();
  };

  const onError = (err?: any) => {
    message.error(`There was an error approving this partner client: ${err?.message}`);
  };

  return (
    <MutationButton
      authorized={authorized}
      buttonIcon={<CheckOutlined />}
      modalTitle="Approve Partner Client"
      modalContent="Do you wish to approve this client as a partner?"
      mutation={APPROVE_PARTNER_CLIENT_MUTATION}
      buttonType="primary"
      modalOkType="primary"
      mutationVariables={{ clientId }}
      onCompleted={onApproveSuccess}
      onError={onError}
      unauthorizedMessage="You do not have the required permissions to approve partner clients."
    >
      Approve Partner Client
    </MutationButton>
  );
}

type RevokePartnerStatusButtonProps = {
  authorized: boolean;
  clientId: string;
  refetchClientData: () => void;
};

function RevokePartnerStatusButton(props: RevokePartnerStatusButtonProps) {
  const { authorized, clientId, refetchClientData } = props;

  const onRevokeSuccess = () => {
    message.success('Partner client status revoked!');
    refetchClientData();
  };

  const onError = (err?: any) => {
    message.error(`There was an error revoking the client's partner status: ${err?.message}`);
  };

  return (
    <MutationButton
      authorized={authorized}
      buttonIcon={<StopOutlined />}
      danger
      modalTitle="Revoke Partner Approval"
      modalContent="Are you sure you want to revoke this client's partner status?"
      modalOkType="danger"
      mutation={REVOKE_PARTNER_CLIENT_MUTATION}
      mutationVariables={{ clientId }}
      onCompleted={onRevokeSuccess}
      onError={onError}
      unauthorizedMessage="You do not have the required permissions to revoke partner client approvals."
    >
      Revoke Partner Approval
    </MutationButton>
  );
}

type PartnerClientDetailsProps = {
  canExecuteAllClients: boolean;
  clientId?: string;
  partnerDetails?: PartnerDetails | null;
  refetchClientData: () => void;
};

function PartnerClientDetails(props: PartnerClientDetailsProps) {
  const { canExecuteAllClients, clientId, partnerDetails, refetchClientData } = props;

  const formattedBusinessPhone = formatPhoneNumber(partnerDetails?.businessPhone, partnerDetails?.businessPhoneExt);
  const partnerType = partnerTypes.find((pt) => pt.value === partnerDetails?.partnerType);
  const businessType = partnerBusinessTypes.find((bt) => bt.value === partnerDetails?.businessType);

  return (
    <ClientPartnerDetails>
      <Typography.Title level={3}>
        Partner Details {partnerDetails?.isApproved && <CheckOutlined style={{ color: baseTheme.color.green }} />}
      </Typography.Title>
      <DetailsItem title="Approval State" value={partnerDetails?.isApproved ? 'Approved' : 'Not Approved'} />
      <DetailsItem title="Partner Type" value={partnerType?.label ?? 'Unknown'} />
      <DetailsItem title="Business Type" value={businessType?.label ?? 'Unknown'} />
      <DetailsItem title="Country" value={partnerDetails?.businessCountry ?? 'Unknown'} />
      <DetailsItem title="Business Phone" value={formattedBusinessPhone ?? 'Unknown'} />
      {partnerDetails?.businessSalesRepresentative && (
        <DetailsItem title="Sales Representative" value={partnerDetails?.businessSalesRepresentative} />
      )}
      {partnerDetails?.businessWebsite && (
        <DetailsItem title="Website" value={partnerDetails.businessWebsite} url={partnerDetails.businessWebsite} />
      )}
      <DetailsItem
        title="Business Email"
        value={<a href={`mailto:${partnerDetails?.businessEmail}`}>{partnerDetails?.businessEmail ?? 'Unknown'}</a>}
      />
      <DetailsItem title="Additional Info" value={partnerDetails?.additionalInfo} />
      {clientId && !partnerDetails?.isApproved && (
        <ApprovePartnerClientButton
          authorized={canExecuteAllClients}
          clientId={clientId}
          refetchClientData={refetchClientData}
        />
      )}
      {clientId && partnerDetails?.isApproved && (
        <RevokePartnerStatusButton
          authorized={canExecuteAllClients}
          clientId={clientId}
          refetchClientData={refetchClientData}
        />
      )}
    </ClientPartnerDetails>
  );
}

type ServiceNowAccountDetailsProps = {
  account?: {
    number: string;
    name: string;
    sys_id: string;
    u_client_category?: string;
    u_parent_escalation_contacts?: boolean;
    u_parent_passphrase?: boolean;
  };
};

const ServiceNowAccountDetails: FC<ServiceNowAccountDetailsProps> = (props) => {
  if (!props.account) {
    return (
      <>
        <Typography.Title level={3}>ServiceNow Account Details</Typography.Title>
        <Alert type="warning" message="This client is not linked with a ServiceNow account." />
      </>
    );
  }

  return (
    <>
      <Typography.Title level={3}>ServiceNow Account Details</Typography.Title>
      <Table
        columns={[
          {
            title: 'Number',
            dataIndex: 'number',
            key: 'number',
          },
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Account Type',
            dataIndex: 'u_client_category',
            key: 'u_client_category',
            render: getServiceNowAccountCategory,
          },
          {
            title: 'Escalations on Parent?',
            dataIndex: 'u_parent_escalation_contacts',
            key: 'u_parent_escalation_contacts',
            render: humanReadableBoolean,
          },
          {
            title: 'Passphrase on Parent?',
            dataIndex: 'u_parent_passphrase',
            key: 'u_parent_passphrase',
            render: humanReadableBoolean,
          },
          {
            title: 'Sys ID',
            dataIndex: 'sys_id',
            key: 'sys_id',
            render(sysId: string) {
              return (
                <>
                  <CopyToClipboardIcon copyText={sysId} />
                  <span style={{ marginLeft: '0.5rem' }}>{sysId}</span>
                </>
              );
            },
          },
          {
            title: 'Actions',
            dataIndex: 'sys_id',
            key: 'actions',
            render(sysId: string) {
              return (
                <NuButton
                  type="primary"
                  href={`${config.serviceNowBaseUrl}/nav_to.do?uri=customer_account.do?sys_id=${sysId}%26sysparm_view=case`}
                  target="_blank"
                  icon={<LinkOutlined />}
                >
                  View In ServiceNow
                </NuButton>
              );
            },
          },
        ]}
        dataSource={props.account ? [props.account] : undefined}
        pagination={false}
      />
    </>
  );
};

const ClientDetailsInfo = ({
  client,
  clientId,
  groups = [],
  loading,
  refetch,
  refetchUsers,
  setIsEditing,
  tabKeysToShow,
  users = [],
}: ClientDetailsInfoProps) => {
  const navigate = useNavigate();
  const [isCreateAppModalOpen, setIsCreateAppModalOpen] = useState<boolean>(false);
  const [updateClient, { loading: isUpdateLoading }] = useMutation(UPDATE_CLIENT);
  const { user: authUser } = useAuthContext();
  const { client: authClient } = useClientContext();
  const effectivePermissions = authClient?.effectivePermissions;
  const authorize = useAuthorize({ effectivePermissions });

  const { authorized: canWriteAllClients } = authorize({ requiredPermissions: { allClients: Access.write } });
  const { authorized: canExecuteAllClients } = authorize({ requiredPermissions: { allClients: Access.execute } });

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  if (!client) {
    return <EmptyState>Could not find client</EmptyState>;
  }

  const isExternalUser: (user: IUser) => boolean = (user: IUser) =>
    !!client && !!user.managedClientIds?.includes(client.id);

  const tableColumns = [
    {
      title: 'User',
      key: 'id',
      render: (_id: string, user: IUser) => {
        const { firstName, lastName, email } = user;

        if (isExternalUser(user))
          return (
            <span>
              {firstName} {lastName} {`<${email}>`} <strong>(External)</strong>
            </span>
          );

        return <Link to={`/admin/users/${user.id}`}>{`${user.firstName} ${user.lastName} <${user.email}>`}</Link>;
      },
    },
    {
      title: `Status`,
      dataIndex: `status`,
      key: `status`,
      render: (status: string) => <UserStatus status={status} />,
    },
    {
      title: 'Access',
      dataIndex: 'isSuperUser',
      key: `isSuperUser`,
      render: (_name: string, item: IUser) => {
        const memberOf = groups.filter((g) => g.members.find((m) => m.id === item.id));
        let memberString = memberOf.map((g) => g.name)?.join(', ') || 'None';
        if (item?.isSuperUser) {
          memberString += ', Superuser';
        }

        return <p>{memberString}</p>;
      },
    },
    {
      title: `Actions`,
      dataIndex: ``,
      key: `actions`,
      render: (user: IUser) => {
        if (isExternalUser(user))
          return <ExternalUserActionButtons clientId={client?.id} user={user} onActionSuccess={() => refetch()} />;

        return <UserActionButtons user={user} onActionSuccess={() => refetchUsers()} />;
      },
    },
  ];

  const isPartnerClient = client?.type === ClientType.partner;
  const partnerDetails = client?.partnerDetails;

  const defaultTabs: TabsProps['items'] = [
    {
      key: 'all-users',
      label: 'All Users',
      children: (
        <Table columns={tableColumns} dataSource={users} rowKey="id" pagination={false} size="small" bordered />
      ),
    },
    {
      key: 'invite-new-user',
      label: 'Invite New User',
      children: <UserInviteForm clientId={client?.id} onFinish={() => refetchUsers()} />,
    },
    {
      key: 'groups',
      label: 'Groups',
      children: <UserGroups clientId={clientId} userGroupsBaseUrl={`/admin/clients/${clientId}/user-groups`} />,
    },
    {
      key: 'applications',
      label: 'Configured Applications',
      children: (
        <>
          <NuButton
            disabled={!authUser?.isSuperUser || loading}
            type="default"
            onClick={() => setIsCreateAppModalOpen(true)}
            icon={<CloudUploadOutlined style={{ fontSize: '18px' }} />}
            style={{ marginBottom: '1rem' }}
          >
            Create Application
          </NuButton>
          <ClientApplications client={client} />
        </>
      ),
    },
    {
      key: 'feature-management',
      label: 'Feature Management',
      children: <ClientFeatureManagement clientId={client?.id ?? ''} />,
    },
  ];
  let tabs = defaultTabs;
  if (tabKeysToShow) {
    tabs = defaultTabs.filter((t) => tabKeysToShow.includes(t.key));
  }

  return (
    <>
      <NuCard title="Client Details">
        <NuCardContent>
          <ClientDetails>
            <ClientMainDetails>
              <Typography.Title level={3}>Basic Information</Typography.Title>
              <DetailsItem
                value={
                  <>
                    <span style={{ marginRight: '0.25rem' }}>{client?.name}</span>
                    <CopyToClipboardIcon copyText={client?.id} altTooltip="Copy ID" />
                  </>
                }
                title="Client Name"
                trailing={client?.deletedAt ? <Tag color="red">Deleted</Tag> : undefined}
              />
              <DetailsItem value={getServiceNowAccountCategory(client?.type ?? '')} title="Client Type" />
              <DetailsItem
                value={
                  <Tooltip overlay={client?.createdAt}>
                    {client?.createdAt ? dayjs(client?.createdAt).format('MM/DD/YYYY') : ''}
                  </Tooltip>
                }
                title="Created On"
              />
              <DetailsItem
                value={
                  client?.parentId ? (
                    <>
                      <Link to={`/admin/clients/${client.parentId}`}>{client?.parent?.name ?? 'Not Set'}</Link>
                      <CopyToClipboardIcon copyText={client?.parentId} style={{ marginLeft: '0.5rem' }} />
                    </>
                  ) : (
                    <ColoredSpan>Not Set</ColoredSpan>
                  )
                }
                title="Parent Client"
              />
              <DetailsItem value={client?.oktaGroupId || ''} title="Okta Group Id" showCopy />
              <DetailsItem value={client?.ownerId || ''} title="Owner Id" showCopy />
              <DetailsItem value={client?.apiKeyId || ''} title="Api Key Id" showCopy />
              <DetailsItem value={client?.industry?.name || ''} title="Industry" />
              <DetailsItem
                title="Multitenancy"
                value={
                  <Checkbox
                    disabled={loading || isUpdateLoading}
                    checked={client?.isMultiTenancyEnabled}
                    onChange={async () => {
                      try {
                        await updateClient({
                          variables: {
                            input: { id: clientId, isMultiTenancyEnabled: !client?.isMultiTenancyEnabled },
                          },
                        });
                        message.success('Updated Multitenancy!');
                        refetch();
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                  />
                }
                showLoading={loading || isUpdateLoading}
              />

              <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
              <ServiceNowAccountDetails account={(client as any)?.serviceNowAccount} />
              <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />

              <Typography.Title level={3}>ML Settings</Typography.Title>
              <DetailsItem
                value={
                  client?.mlOptions?.anomalyThreshold
                    ? anomaliThresholdOptions.find((option) => option.value === client?.mlOptions?.anomalyThreshold)
                        ?.key
                    : 'Medium - 5%'
                }
                title="Anomaly Threshold"
              />
            </ClientMainDetails>
            {isPartnerClient && (
              <PartnerClientDetails
                canExecuteAllClients={canExecuteAllClients}
                clientId={client?.id}
                partnerDetails={partnerDetails}
                refetchClientData={refetch}
              />
            )}
          </ClientDetails>

          <ClientIdentifiers clientId={clientId} clientName={client?.name} onUpdateComplete={refetch} />

          <ActionsRow>
            {client?.id && <ClientContextButton clientId={client!.id} buttonType="default" />}
            <NuButton
              disabled={!canWriteAllClients || loading}
              type="primary"
              onClick={() => setIsEditing(true)}
              icon={<EditOutlined style={{ fontSize: '18px' }} />}
            >
              Edit Profile
            </NuButton>
            <ChangeParentClientButtonAndModal
              client={client}
              onSuccess={() => {
                refetch();
              }}
            />
            <div>
              {loading ? (
                <Spin />
              ) : (
                client && (
                  <ClientActionButtons
                    client={client}
                    onActionSuccess={() => refetch()}
                    onForceDelete={() => navigate(`/admin/clients`)}
                  />
                )
              )}
            </div>
          </ActionsRow>
        </NuCardContent>
      </NuCard>
      <Spacer height="24px" />
      <Tabs defaultActiveKey="all-users" items={tabs} />
      <CreateAppModal client={client} onClose={() => setIsCreateAppModalOpen(false)} visible={isCreateAppModalOpen} />
    </>
  );
};

export default ClientDetailsInfo;
