import React, { useState } from 'react';
import { message, Badge, Modal, Typography, Tooltip, Skeleton, Collapse, Space, Tag } from 'antd';
import { Link, EmptyState, NuButton } from 'components/nuspire';
import baseTheme from 'components/theme';
import { CalendarOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { gql, useQuery, useMutation } from '@apollo/client';
import { getPriority } from 'components/client-tasks/client-task-card';
import { NuEmptyState } from 'components/nuspire/nu-empty-state/nu-empty-state.stories';
import { OpenCase } from 'components/case-management/open-case';
import { CASE_BY_NUMBER_QUERY } from 'components/case-management/case-detail';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { TaskMembers } from './task-members';
import { useClientContext } from 'components/client-context-provider';
import { INCIDENT_BY_NUMBER_QUERY } from 'components/case-management/incident-detail';
import { ClientTaskDetailFieldsFragment, ClientTaskModalQuery } from 'types/graph-codegen/graph-types';
import ReactMarkdown from 'react-markdown';
import dayjs from 'dayjs';
import { ClientTaskActions } from './client-task-actions/client-task-actions';
import { TaskActivity } from './client-task-activity/client-task-activity';
import { AddComment } from './add-comment';
import { useTimeMixpanelEvent } from 'utils/mixpanel/mixpanel-page-timer';
import { ClientTaskPageHeader } from './client-task-page-header';
import { getCanCreateCase } from './utils';
import { ClientTaskWidgets, ClientTask } from './client-task-details';

export const CLIENT_TASK_MODAL = gql`
  query ClientTaskModal($id: String!, $clientId: String!) {
    clientTask(id: $id) {
      id
      shortId
      type
      clientId
      label
      status
      snowCaseNumber
      snowIncidentNumber
      members

      startDate
      endDate
      createdAt

      summary

      impact
      remediation
      info
      tags
      priority

      actions {
        id
        title
        blocks
        status
        createdAt
        taskId
        clientId
      }
      activity {
        id
        clientId
        text
        type
        ok
        taskId
        edited
        user {
          id
          firstName
          lastName
          email
        }
        data
        createdAt
      }
      widgets {
        id
        name
        description
        widgetSlug
        configuration
        widgetDefinition {
          id
          name
          type
        }
        data(viewingClientId: $clientId)
      }
    }
  }
`;

const Text = styled(Typography.Text)``;

const DetailItem = styled.div`
  margin-top: 2rem;
`;

const SubDetailItem = styled.div`
  margin-right: 2rem;
`;

const SubDetailTitle = styled.div`
  margin-bottom: 1rem;
  color: ${baseTheme.color.gray6};
  font-weight: 700;
`;

export function TaskImpactAndRemediation(props: { task: ClientTaskDetailFieldsFragment }) {
  const {
    task: { impact, remediation },
  } = props;

  return (
    <DetailItem>
      {impact && (
        <>
          <Typography.Title level={5}>Impact</Typography.Title>
          <Typography>
            <ReactMarkdown>{impact}</ReactMarkdown>
          </Typography>
        </>
      )}

      {remediation && (
        <>
          <Typography.Title level={5}>Remediation</Typography.Title>
          <Typography>
            <ReactMarkdown>{remediation}</ReactMarkdown>
          </Typography>
        </>
      )}
    </DetailItem>
  );
}

const ScchRoot = styled.div`
  display: flex;
  flex: 1;

  > * {
    flex: 1;
    display: block;
  }
`;

function SnowHeader(props: { label: string; number?: string; emptyMessage: string }) {
  const { label, number, emptyMessage } = props;

  return (
    <ScchRoot>
      <Typography.Text strong>{label}</Typography.Text>

      <Typography.Text>{number ?? emptyMessage}</Typography.Text>
    </ScchRoot>
  );
}

const SnowCaseTable = styled.table``;
const ScBody = styled.tbody``;
const ScRow = styled.tr``;
const ScCell = styled.td`
  padding-bottom: 24px;
  vertical-align: top;
  &.sc-label {
    padding-right: 40px;
    font-weight: 700;
    color: ${baseTheme.color.gray6};
  }
`;

function SnowTableRow(props: { label: string; children: React.ReactNode }) {
  const { label, children } = props;

  return (
    <ScRow>
      <ScCell className="sc-label">{label}</ScCell>
      <ScCell>{children}</ScCell>
    </ScRow>
  );
}

function displaySnowDate(date?: string) {
  if (!date) {
    return '--';
  }

  return dayjs(date).format('MMMM Do YYYY');
}

function displaySnowText(text?: string) {
  if (!text?.length) {
    return '--';
  }

  return text;
}

function SnowCaseModalView(props: { snowCase: any; clientId: string }) {
  const { snowCase, clientId } = props;

  return (
    <Space direction="vertical">
      <SnowCaseTable>
        <ScBody>
          <SnowTableRow label="Created">{displaySnowDate(snowCase.sys_created_on)}</SnowTableRow>
          <SnowTableRow label="Updated">{displaySnowDate(snowCase.sys_updated_on)}</SnowTableRow>
          <SnowTableRow label="Resolved">{displaySnowDate(snowCase.resolved_at)}</SnowTableRow>
          <SnowTableRow label="Assigned To">{displaySnowText(snowCase.assigned_to)}</SnowTableRow>
          <SnowTableRow label="State">{displaySnowText(snowCase.state)}</SnowTableRow>
          {snowCase.closed_by && <SnowTableRow label="Closed By">{snowCase.closed_by ?? '--'}</SnowTableRow>}
          {snowCase.resolution_code && (
            <SnowTableRow label="Resolution Code">{snowCase.resolution_code ?? '--'}</SnowTableRow>
          )}
          {snowCase.close_notes && <SnowTableRow label="Close Notes">{snowCase.close_notes ?? '--'}</SnowTableRow>}
          <SnowTableRow label="Priority">{displaySnowText(snowCase.priority)}</SnowTableRow>
          <SnowTableRow label="Contract">{displaySnowText(snowCase.contract)}</SnowTableRow>
          <SnowTableRow label="Asset">{displaySnowText(snowCase.asset)}</SnowTableRow>
          <SnowTableRow label="Product">{displaySnowText(snowCase.product)}</SnowTableRow>
          <SnowTableRow label="Product">{displaySnowText(snowCase.product)}</SnowTableRow>
          <SnowTableRow label="Entitlement">{displaySnowText(snowCase.entitlement)}</SnowTableRow>
          <SnowTableRow label="Category">{displaySnowText(snowCase.category)}</SnowTableRow>
          <SnowTableRow label="Subcategory">{displaySnowText(snowCase.subcategory)}</SnowTableRow>
          <SnowTableRow label="Description">{displaySnowText(snowCase.description)}</SnowTableRow>
        </ScBody>
      </SnowCaseTable>

      <Link to={`/${clientId}/case-management/cases/${snowCase.number}`} target="_blank">
        <NuButton type="primary" ghost style={{ width: '100%' }}>
          View in Case Management
        </NuButton>
      </Link>
    </Space>
  );
}

function SnowCaseCollapseDetails(props: { snowCaseNumber: string; clientId: string }) {
  const { snowCaseNumber, clientId } = props;

  const { data, loading } = useQuery(CASE_BY_NUMBER_QUERY, {
    variables: {
      caseNumber: snowCaseNumber,
      clientId,
    },
  });
  const snowCase = data?.case;

  if (snowCase) {
    return <SnowCaseModalView snowCase={snowCase} clientId={clientId} />;
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return <EmptyState>There was an issue getting Case Info</EmptyState>;
}

function SnowIncidentModalView(props: { snowIncident: any; clientId: string }) {
  const { snowIncident, clientId } = props;

  return (
    <Space direction="vertical">
      <SnowCaseTable>
        <ScBody>
          <SnowTableRow label="Created">{displaySnowDate(snowIncident.sys_created_on)}</SnowTableRow>
          <SnowTableRow label="Updated">{displaySnowDate(snowIncident.sys_updated_on)}</SnowTableRow>
          <SnowTableRow label="Resolved">{displaySnowDate(snowIncident.resolved_at)}</SnowTableRow>
          <SnowTableRow label="State">{displaySnowText(snowIncident.state)}</SnowTableRow>
          {snowIncident.closed_by?.display_value && (
            <SnowTableRow label="Closed By">{snowIncident.closed_by.display_value ?? '--'}</SnowTableRow>
          )}
          {snowIncident.close_notes && (
            <SnowTableRow label="Close Notes">{snowIncident.close_notes ?? '--'}</SnowTableRow>
          )}
          <SnowTableRow label="Priority">{displaySnowText(snowIncident.priority)}</SnowTableRow>
          <SnowTableRow label="Category">{displaySnowText(snowIncident.category)}</SnowTableRow>
          <SnowTableRow label="Subcategory">{displaySnowText(snowIncident.subcategory)}</SnowTableRow>
          <SnowTableRow label="Description">{displaySnowText(snowIncident.description)}</SnowTableRow>
        </ScBody>
      </SnowCaseTable>

      <Link to={`/${clientId}/case-management/incidents/${snowIncident.number}`} target="_blank">
        <NuButton type="primary" ghost style={{ width: '100%' }}>
          View in Case Management
        </NuButton>
      </Link>
    </Space>
  );
}

function SnowIncidentCollapseDetails(props: { snowIncidentNumber: string; clientId: string }) {
  const { snowIncidentNumber, clientId } = props;

  const { data, loading } = useQuery(INCIDENT_BY_NUMBER_QUERY, {
    variables: {
      incidentNumber: snowIncidentNumber,
      clientId,
    },
  });
  const snowIncident = data?.incident;

  if (snowIncident) {
    return <SnowIncidentModalView snowIncident={snowIncident} clientId={clientId} />;
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return <EmptyState>There was an issue getting Incident Details</EmptyState>;
}

const formLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const UPDATE_TASK_CASE_NUMBER = gql`
  mutation UpdateClientTaskCaseNumber($id: String!, $snowCaseNumber: String) {
    updateClientTask(id: $id, snowCaseNumber: $snowCaseNumber) {
      id
      snowCaseNumber
    }
  }
`;

function CreateClientTaskCase(props: { task: any }) {
  const { task } = props;

  const [updateClientTask] = useMutation(UPDATE_TASK_CASE_NUMBER);

  const handleSubmit = async (success: boolean, newCase?: { number: string }) => {
    if (!success) {
      message.error('Failed to create a case. Please contact Support.');
    }

    const snowCaseNumber = newCase?.number;
    if (snowCaseNumber) {
      await updateClientTask({
        variables: {
          id: task.id,
          snowCaseNumber,
        },
      });

      message.success(`Case was successfully created.`);
    }
  };

  return (
    <div>
      <OpenCase
        clientId={undefined}
        layout={formLayout}
        defaults={{
          shortDescription: `Help with Task #${task.shortId}`, // presented as "Subject"
          description: `Task Summary: "${task.summary}"`,
          // priority: task.priority,
        }}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export function SnowCaseCollapseContent(props: { task: any }) {
  const {
    task: { snowCaseNumber },
    task,
  } = props;

  if (snowCaseNumber) {
    return <SnowCaseCollapseDetails clientId={task.clientId} snowCaseNumber={snowCaseNumber} />;
  }

  return <CreateClientTaskCase task={task} />;
}

export function SnowCaseCollapse(props: { task: any; expanded: boolean; onCollapseChange: (open: boolean) => void }) {
  const { task, expanded, onCollapseChange } = props;

  return (
    <Collapse
      expandIconPosition="end"
      activeKey={expanded ? 'case-collapse' : undefined}
      onChange={(keys) => {
        if (keys?.includes('case-collapse')) {
          onCollapseChange(true);
        } else {
          onCollapseChange(false);
        }
      }}
    >
      <Collapse.Panel
        key="case-collapse"
        header={<SnowHeader emptyMessage="No Case" label="Case Details" number={task.snowCaseNumber} />}
      >
        <SnowCaseCollapseContent task={task} />
      </Collapse.Panel>
    </Collapse>
  );
}

function SnowIncidentCollapse(props: { snowIncidentNumber: string }) {
  const { snowIncidentNumber } = props;
  const { clientId } = useClientContext();

  return (
    <Collapse expandIconPosition="end">
      <Collapse.Panel
        key="incident-collapse"
        header={<SnowHeader emptyMessage="No Incident" label="Incident Details" number={snowIncidentNumber} />}
      >
        <SnowIncidentCollapseDetails clientId={clientId ?? ''} snowIncidentNumber={snowIncidentNumber} />
      </Collapse.Panel>
    </Collapse>
  );
}

// do not show collapse if incident does not exist
export function SnowIncidentCollapseShow(props: { snowIncidentNumber?: string }) {
  const { snowIncidentNumber } = props;

  if (!snowIncidentNumber) {
    return null;
  }

  return <SnowIncidentCollapse snowIncidentNumber={snowIncidentNumber} />;
}

const TaskModalLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;
const TaskModalMainGrid = styled.div`
  flex: 1;

  display: grid;

  grid-template-columns: 1fr 24px 432px;
  grid-template-areas: 'task-modal-body-col . task-modal-meta-col';
`;
const TaskModalBodyCol = styled.div`
  grid-area: task-modal-body-col;
  overflow-y: auto;
`;
const TaskModalMetaCol = styled.div`
  grid-area: task-modal-meta-col;
  position: relative;
`;
const TaskModalMetaColOverflow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

function getTypeLabel(type: string) {
  if (type === 'recommendation') {
    return 'Recommendation';
  }
  if (type === 'issue') {
    return 'Issue';
  }

  return type;
}

export function getShortIdLabel(task: any) {
  const { type, shortId } = task;

  if (!type && !shortId) {
    return null;
  }

  let str = '';
  if (type) {
    const typeLabel = getTypeLabel(type);
    str = `${typeLabel}`;
  }

  if (shortId) {
    str += ` #${shortId}`;
  }

  return str;
}

const TextBtn = styled.p`
  color: ${baseTheme.color.nuspireBlue};

  &:hover {
    cursor: pointer;
  }
`;

export function ClientTaskTags(props: { task: ClientTaskDetailFieldsFragment; onSelect?: () => void }) {
  const {
    task: { clientId, tags },
    onSelect,
  } = props;
  const [showTags, setShowTags] = useState<boolean>(false);

  const handleToggleShowTags = () => {
    setShowTags(!showTags);
  };

  if (!tags) {
    return null;
  }

  return (
    <DetailItem>
      {showTags && (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '.5rem', marginBottom: '8px' }}>
          {tags?.map((t) => {
            const searchParams = new URLSearchParams();
            searchParams.set('tags', t);
            const s = searchParams.toString();
            const path = `/${clientId}/case-management/tasks?${s}`;

            return (
              <Link
                to={path}
                key={t}
                onClick={() => {
                  if (onSelect) onSelect();
                }}
              >
                <Tag color="blue">{t}</Tag>
              </Link>
            );
          })}
        </div>
      )}

      <TextBtn onClick={handleToggleShowTags}>{showTags ? 'Hide Tags' : 'Show Tags'}</TextBtn>
    </DetailItem>
  );
}

type ClientTaskModalDetailProps = { task: ClientTask; onCloseModal: () => void };

function formatTimeStrings(str: string): string {
  const isoRegExp = /\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d.\d\d\dZ/g; // from date.toISOstring();

  const formatted = str.replaceAll(isoRegExp, (match) => dayjs(match).format('M/D/YYYY - h:mm A'));

  return formatted;
}

export function ClientTaskSubDetailItem(props: { label: string | JSX.Element; children: React.ReactNode }) {
  const { label, children } = props;

  return (
    <SubDetailItem>
      <SubDetailTitle>
        <Text>{label}</Text>
      </SubDetailTitle>
      {children}
    </SubDetailItem>
  );
}

export function ClientTaskSubDetailItems(props: { clientTask: ClientTaskDetailFieldsFragment }) {
  const {
    clientTask,
    clientTask: { type },
  } = props;
  const priority = getPriority(clientTask?.priority);
  const showScheduling = type && ['recommendation'].includes(type);

  return (
    <div style={{ display: 'flex' }}>
      <ClientTaskSubDetailItem label={'Priority'}>
        <div>
          <Badge color={priority?.color} />
          <Text>{priority?.label}</Text>
        </div>
      </ClientTaskSubDetailItem>

      <SubDetailItem>
        <SubDetailTitle>
          <Text>Members</Text>
        </SubDetailTitle>

        <TaskMembers clientTask={clientTask} />
        {/* <div>
            <PlusCircleOutlined style={{ fontSize: '20px', color: theme.color.gray6 }} />
          </div> */}
      </SubDetailItem>

      {showScheduling && (
        <SubDetailItem>
          <Tooltip overlay="Coming Soon">
            <SubDetailTitle>
              <Text>Date</Text>
            </SubDetailTitle>
            <div>
              <CalendarOutlined style={{ color: baseTheme.color.gray6 }} />
              <Text style={{ marginLeft: '.5rem' }}>Unscheduled</Text>
            </div>
          </Tooltip>
        </SubDetailItem>
      )}
    </div>
  );
}

export function ClientTaskSummary(props: { clientTask: ClientTaskDetailFieldsFragment }) {
  const {
    clientTask: { summary },
  } = props;
  if (!summary) return null;
  return (
    <DetailItem>
      <Typography.Title level={5}>Summary</Typography.Title>
      <Typography>
        <ReactMarkdown>{formatTimeStrings(summary)}</ReactMarkdown>
      </Typography>
    </DetailItem>
  );
}

export function ClientTaskInfo(props: { clientTask: ClientTaskDetailFieldsFragment }) {
  const {
    clientTask: { info },
  } = props;
  if (!info) return null;
  return (
    <DetailItem>
      <Typography.Title level={5}>Info</Typography.Title>
      <Typography>
        <ReactMarkdown>{formatTimeStrings(info)}</ReactMarkdown>
      </Typography>
    </DetailItem>
  );
}

export function ClientTaskActionsDetailItem(props: { clientTask: ClientTaskDetailFieldsFragment }) {
  const {
    clientTask: { actions },
  } = props;

  return (
    <DetailItem>
      <ClientTaskActions actions={actions} />
    </DetailItem>
  );
}

export function ClientTaskActivityDetailItem(props: { clientTask: ClientTaskDetailFieldsFragment }) {
  const {
    clientTask: { id, activity },
  } = props;

  return (
    <DetailItem>
      <Typography.Title level={5} style={{ marginBottom: '14px' }}>
        Activity
      </Typography.Title>
      <AddComment taskId={id} />

      <TaskActivity activity={activity} />
    </DetailItem>
  );
}

function ClientTaskModalDetails(props: ClientTaskModalDetailProps) {
  const {
    task,
    task: { tags },
  } = props;
  // get priority def
  const canCreateCase = getCanCreateCase(tags);

  useTimeMixpanelEvent('client_task_modal_opened', { ...task });

  const [snowCaseExpanded, setSnowCaseExpanded] = useState<boolean>(false);

  const shortIdLabel = getShortIdLabel(task);

  return (
    <TaskModalLayout>
      {shortIdLabel && (
        <Typography.Text style={{ marginBottom: '8px' }} type="secondary">
          {shortIdLabel}
        </Typography.Text>
      )}

      <ClientTaskPageHeader headerLevel={5} clientTask={task} handleSnowCaseCollapse={setSnowCaseExpanded} />

      <TaskModalMainGrid>
        {/* Main Col of content */}
        <TaskModalBodyCol>
          <ClientTaskSubDetailItems clientTask={task} />

          <ClientTaskSummary clientTask={task} />

          <ClientTaskInfo clientTask={task} />

          <ClientTaskActionsDetailItem clientTask={task} />

          <TaskImpactAndRemediation task={task} />

          <ClientTaskWidgets widgets={task?.widgets} />

          <ClientTaskTags task={task} onSelect={() => props.onCloseModal()} />

          <ClientTaskActivityDetailItem clientTask={task} />
        </TaskModalBodyCol>

        <TaskModalMetaCol>
          <TaskModalMetaColOverflow>
            <Space direction="vertical" style={{ display: 'flex' }}>
              <SnowIncidentCollapseShow snowIncidentNumber={task.snowIncidentNumber} />
              {canCreateCase && (
                <SnowCaseCollapse task={task} expanded={snowCaseExpanded} onCollapseChange={setSnowCaseExpanded} />
              )}
            </Space>
          </TaskModalMetaColOverflow>
        </TaskModalMetaCol>
      </TaskModalMainGrid>
    </TaskModalLayout>
  );
}

function ClientTaskModalContent(props: { id: string; onCloseModal: () => void }) {
  const { id } = props;
  const { clientId } = useClientContext();
  const { data, loading } = useQuery<ClientTaskModalQuery>(CLIENT_TASK_MODAL, { variables: { id, clientId } });

  const task = data?.clientTask as ClientTask;

  if (task) {
    return <ClientTaskModalDetails task={task} onCloseModal={props.onCloseModal} />;
  }

  if (loading) {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <Skeleton.Input active />
          <div style={{ margin: 'auto 0 auto auto' }}>
            <Skeleton.Button active style={{ marginRight: '1rem' }} />
            <Skeleton.Button active />
          </div>
        </div>
        <Skeleton.Input size="large" active style={{ marginTop: '1rem' }} />
        <Skeleton active style={{ marginTop: '1rem' }} />
        <Skeleton active style={{ marginTop: '1rem' }} />
      </>
    );
  }

  return <NuEmptyState>{`Could not Find Task: ${id}`}</NuEmptyState>;
}

export function ClientTaskModal(props: { id?: string; onClose: () => void }) {
  const { id, onClose } = props;

  return (
    <Modal
      open={Boolean(id)}
      onCancel={onClose}
      footer={null}
      width={1280}
      destroyOnClose
      styles={{
        body: {
          height: '80vh',
        },
      }}
    >
      {id && <ClientTaskModalContent id={id} onCloseModal={onClose} />}
    </Modal>
  );
}

export default ClientTaskModal;
