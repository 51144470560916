import { Dropdown, Menu } from 'antd';
import { CONNECTORS } from 'components/connections/connectors';
import { Link, NuButton, NuPaper } from 'components/nuspire';
import { useState } from 'react';
import styled from 'styled-components';
import { Ellipsis } from 'components/nuspire/nu-icon';
import { useClientContext } from 'components/client-context-provider';

const CdRoot = styled.div``;
const CdContent = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`;
const EmptyIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${(p) => p.theme.token.colorFillContent};
  border-radius: 8px;
`;
const CdCol = styled.div`
  flex: 2;
`;
const CdIconCol = styled.div`
  flex: 1;
`;
const CdIcon = styled.div`
  width: 50px;
  height: 50px;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CdName = styled.div`
  font-weight: 700;
`;

function ConnectionDisplay(props: { id: string; name: string; connectorSlug: string }) {
  const { id, name, connectorSlug } = props;
  const connectorDef = CONNECTORS.find((c) => c.slug === connectorSlug) ?? null;
  const { clientId } = useClientContext();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const openMenu = () => setIsMenuOpen(true);

  return (
    <CdRoot>
      <NuPaper variation="gray" hoverable onClick={() => openMenu()}>
        <CdContent>
          <CdIconCol>
            <CdIcon>{connectorDef?.icon ?? <EmptyIcon />}</CdIcon>
          </CdIconCol>

          <CdCol>
            <CdName>{name}</CdName>
          </CdCol>

          <CdIcon>
            <Dropdown
              open={isMenuOpen}
              trigger={['click']}
              onOpenChange={(v) => setIsMenuOpen(v)}
              menu={{
                items: [{
                  key: 'manage',
                  label: <Link to={`/${clientId}/connections/${connectorSlug}/${id}`} mode="plain">Manage</Link>,
                }],
              }}
              placement="bottom"
            >
              <NuButton
                onClick={() => openMenu()}
                icon={<Ellipsis style={{ fontSize: '24px' }} />}
                shape="circle"
                type="text"
              />
            </Dropdown>
          </CdIcon>
        </CdContent>
      </NuPaper>
    </CdRoot>
  );
}

export default ConnectionDisplay;
