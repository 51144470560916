import { MailOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { message } from 'antd';
import { useAuthContext } from 'components/auth-context';
import MutationButton from 'components/mutation-button';
import { UserActionButtonProps } from 'types';
import { formatUserName } from 'utils/users';

const RESEND_ACTIVATION_EMAIL_MUTATION = gql`
  mutation ResendActivationEmail($clientId: String, $userId: String!) {
    resendActivationEmail(clientId: $clientId, userId: $userId) {
      result
    }
  }
`;

type ResendActivationEmailData = {
  resendActivationEmail?: {
    activationToken?: string;
    activationUrl?: string;
    result?: boolean;
  };
};

export default function ResendActivationEmailButton(props: UserActionButtonProps) {
  const { onSuccess, user, ...buttonProps } = props;
  const { user: authUser } = useAuthContext();

  const userName = formatUserName(user);
  const { id: userId } = user;
  const variables = {
    userId,
  };
  const modalTitle = `Are you sure you want to resend activation email to ${userName}?`;

  const onCompleted = (data: ResendActivationEmailData) => {
    if (data.resendActivationEmail !== undefined) {
      const {
        resendActivationEmail: { result },
      } = data;

      // Resend was unsuccessful.
      if (!result) {
        onError();
        return;
      }

      // Resend was successful.
      message.success(`Activation email resent to ${userName}!`);
      if (onSuccess) onSuccess();
    }
  };

  const onError = (error?: any) => {
    let errorMessage = `There was an error resending the activation email to ${userName}.`;
    if (authUser?.isSuperUser) {
      errorMessage = `${errorMessage}${error ? ` Error: ${error.message}` : ``}`;
    }

    message.error(errorMessage);
  };

  return (
    <MutationButton
      buttonIcon={<MailOutlined />}
      modalTitle={modalTitle}
      mutation={RESEND_ACTIVATION_EMAIL_MUTATION}
      mutationVariables={variables}
      onCompleted={onCompleted}
      onError={onError}
      tooltipOverlay="Resend Activation Email"
      {...buttonProps}
    />
  );
}
