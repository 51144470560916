import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Typography } from 'antd';
import { Link } from 'components/nuspire';

const LsrRoot = styled.div`
  display: flex;

  margin-bottom: 16px;
  border-radius: 8px;
  padding: 8px;
  transition: all 0.2s ease;

  position: relative;

  &.icon-position-top {
    flex-direction: column;
    align-items: flex-start;
  }

  &.icon-position-bottom {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      background-color: ${(p) => p.theme.token.colorBgTextHover};
    }
  }

  &.variation-white {
    background-color: ${(p) => p.theme.token.colorBgContainer};

    &.clickable:hover {
      background-color: ${(p) => p.theme.token.colorBgContainer};
      filter: drop-shadow(0px 4px 4px rgba(59, 120, 230, 0.6));
    }

    &.selected {
      filter: drop-shadow(0px 4px 4px rgba(59, 120, 230, 0.4));
    }
  }

  &.disabled {
    pointer-events: none;
  }

  &.full-height {
    height: 100%;
  }
`;
const LsrIcon = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  font-size: 32px;

  .icon-position-top & {
    margin-bottom: 6px;
    margin-right: 0;
  }
  .icon-position-bottom & {
    margin-top: 10px;
    margin-right: 0;
  }
`;
const EmptyIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${(p) => p.theme.token.colorFillContent};
  border-radius: 8px;
`;
const LsrMain = styled.div`
  flex: 1;
`;
const LsrActions = styled.div``;

function SearchResultIcon(props: { icon?: React.ReactNode; emptyIcon: boolean; style?: React.CSSProperties }) {
  const { icon, emptyIcon, style } = props;

  if (icon) {
    return <LsrIcon style={style}>{icon}</LsrIcon>;
  }

  if (emptyIcon) {
    return (
      <LsrIcon style={style}>
        <EmptyIcon />
      </LsrIcon>
    );
  }

  return null;
}

interface LibrarySearchResultProps {
  title: string;
  description?: string;
  onClick?: () => void;
  pathname?: string;
  disabled?: boolean;

  icon?: React.ReactNode;
  iconPosition?: 'left' | 'top' | 'bottom';
  emptyIcon?: boolean;

  actions?: React.ReactNode;

  variation?: 'white' | 'default';
  fullHeight?: boolean;
  selected?: boolean;

  styles?: {
    root?: React.CSSProperties;
    main?: React.CSSProperties;
    icon?: React.CSSProperties;
  };
}

function LibrarySearchResult(props: LibrarySearchResultProps) {
  const {
    title,
    description,
    icon,
    onClick,
    pathname,
    disabled,
    emptyIcon = true,
    iconPosition = 'left',
    styles = {},
    actions,
    variation = 'default',
    selected,
    fullHeight = true,
  } = props;

  const rootClasses = classNames('library-search-result', `icon-position-${iconPosition}`, `variation-${variation}`, {
    clickable: pathname || onClick,
    disabled,
    selected,
    'full-height': fullHeight,
  });

  const li = (
    <LsrRoot className={rootClasses} onClick={onClick} style={styles.root}>
      <SearchResultIcon icon={icon} emptyIcon={emptyIcon} style={styles.icon} />

      <LsrMain style={styles.main}>
        <Typography.Title level={5} style={{ marginBottom: '0' }} type={!disabled ? undefined : 'secondary'}>
          {title}
        </Typography.Title>
        {description && (
          <Typography.Paragraph type={!disabled ? undefined : 'secondary'} style={{ marginBottom: 0 }}>
            {description}
          </Typography.Paragraph>
        )}
      </LsrMain>

      {actions && <LsrActions>{actions}</LsrActions>}
    </LsrRoot>
  );

  if (!pathname || disabled) {
    return li;
  }

  return (
    <Link to={pathname} onClick={onClick} mode="plain">
      {li}
    </Link>
  );
}

export default LibrarySearchResult;
