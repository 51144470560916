import { gql } from '@apollo/client';
import { message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { ButtonGroupProps } from 'antd/es/button';
import { IClient } from 'types';
import MutationButton from 'components/mutation-button';

const DELETE_CLIENT_MUTATION = gql`
  mutation DeleteClient($clientId: String!) {
    deleteClient(clientId: $clientId)
  }
`;

type DeleteClientButtonProps = ButtonGroupProps & {
  authorized: boolean;
  client: IClient;
  onSuccess?: Function;
};

type DeleteClientData = {
  deleteClient?: boolean;
};

export default function DeleteClientButton(props: DeleteClientButtonProps) {
  const { authorized, client, onSuccess, ...buttonProps } = props;

  const { id: clientId } = client;
  const variables = {
    clientId,
  };
  const modalTitle = `Are you sure you want to delete ${client.name}?`;

  const onCompleted = (data: DeleteClientData) => {
    if (data.deleteClient !== undefined) {
      const { deleteClient: result } = data;

      // Delete was unsuccessful.
      if (!result) {
        onError();
        return;
      }

      // Delete was successful.
      message.success(`${client.name} has been deleted!`);
      if (onSuccess) onSuccess();
    }
  };

  const onError = (error?: any) => {
    // Display the error message if we have one.
    const errorMessage = `There was an error deleting ${client.name}. ${error ? ` Error: ${error.message}` : `.`}`;

    message.error(errorMessage);
  };

  return (
    <MutationButton
      authorized={authorized}
      buttonIcon={<DeleteOutlined />}
      danger
      modalOkType="danger"
      modalTitle={modalTitle}
      mutation={DELETE_CLIENT_MUTATION}
      mutationVariables={variables}
      onCompleted={onCompleted}
      onError={onError}
      tooltipOverlay="Delete Client"
      unauthorizedMessage="You do not have the required permissions to delete clients."
      {...buttonProps}
    />
  );
}
