import { PrismaCloud as PrismaCloudIcon } from 'components/nuspire/nu-icon';
import { IConnector } from 'components/connections/connectors';
import PrismaCloudRedirect from './prisma-cloud-redirect';
import { encodeOAuthState } from '../utils/oauth';
import AlertsBySeverityWidget from './widgets/alerts-by-severity';
import AlertCoverageyWidget from './widgets/alert-coverage';
import DetailsByTopAlertsWidget from './widgets/details-by-top-alerts';
import TopIncidentsAndRisksWidget from './widgets/top-incidents-and-risks';

const PRISMA_CLOUD_CONNECTOR_DEFINITION: IConnector = {
  slug: 'prisma-cloud',
  name: 'Prisma Cloud',
  description: 'Complete Security Across The Development Lifecycle On Any Cloud',
  icon: <PrismaCloudIcon />,
  connectionTypes: ['action'],
  handleAddConnection: ({ clientId }) => {
    if (!clientId) {
      throw new Error('Client Id must be passed to connector');
    }
    const authState = encodeOAuthState({ clientId });

    window.open(
      `${window.location.origin}/redirects/connections/prisma-cloud/auth?state=${authState}`,
      'Prisma Cloud Auth',
      'height=1000,width=800',
    );
  },
  redirects: [{ path: 'auth', component: PrismaCloudRedirect }],
  widgets: [AlertCoverageyWidget, AlertsBySeverityWidget, DetailsByTopAlertsWidget, TopIncidentsAndRisksWidget],
  updatableAuthFields: [
    { key: 'Access Key ID', value: 'accessKeyId' },
    { key: 'Secret Key', value: 'secretKey' },
    { key: 'Instance URL', value: 'baseURL' },
  ],
};

export default PRISMA_CLOUD_CONNECTOR_DEFINITION;
