export type DownloadBase64ContentAsFileArgs = {
  content: string; // base64 encoded content.
  contentType: string;
  filename: string;
};

export function downloadBase64ContentAsFile(args: DownloadBase64ContentAsFileArgs) {
  const { content, contentType, filename } = args;

  const linkSrc = `data:${contentType};base64,${content}`;
  const downloadLink = document.createElement('a');

  downloadLink.href = linkSrc;
  downloadLink.download = filename;
  downloadLink.click();
  downloadLink.remove();
}
