import { IUser } from 'types';

const UserStatus = {
  ACTIVE: {
    description: `This user is active and has a password set.`,
    displayText: `Active`,
  },
  DEPROVISIONED: {
    description: `This user has been deactivated.`,
    displayText: `Deactivated`,
  },
  LOCKED_OUT: {
    description: `This user has exceeded the number of login attempts and has been locked out.`,
    displayText: `Locked out`,
  },
  PASSWORD_EXPIRED: {
    description: `This user's password has expired.`,
    displayText: `Password expired`,
  },
  PROVISIONED: {
    description: `This user has been created, but is not active yet.`,
    displayText: `Pending user action`,
  },
  RECOVERY: {
    description: `This user has either requested a password reset or an admin has initiated one.`,
    displayText: `Recovery`,
  },
  STAGED: {
    description: `This user has been created, but the activation flow has not been initiated yet.`,
    displayText: `Staged`,
  },
  SUSPENDED: {
    description: `This user has been suspended by an admin.`,
    displayText: `Suspended`,
  },
  UNKNOWN: {
    description: `The status of this user is unknown.`,
    displayText: `Unknown`,
  },
};

export function formatUserName(user?: IUser): string {
  if (!user) return '';

  return `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim();
}

export function describeUserStatus(status?: string): { description: string; displayText: string } {
  if (!status) return UserStatus.UNKNOWN;
  return UserStatus[status] || UserStatus.UNKNOWN;
}
