import { gql, useQuery } from '@apollo/client';
import { Row, Typography } from 'antd';
import { WidgetDefinition, WidgetRendererProps } from 'components/dashboard/widget-definitions/types';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import styled from 'styled-components';

const FlexRow = styled(Row)`
  display: flex;
  height: 100%;
`;

const Score = styled(Typography.Title)`
  font-size: 75px !important;
  margin: auto !important;
`;

const CROWDSCORE_QUERY = gql`
  query GetCrowdScore($connectionId: String!) {
    clientConnection(id: $connectionId) {
      id
      publicConnectionDataJson
      crowdScore {
        id
        timestamp
        score
        adjusted_score
      }
    }
  }
`;

function CrowdScoreWidgetComponent(props: WidgetRendererProps) {
  const connectionId = props?.configuration?.connectionId ?? null;
  const { data, loading } = useQuery(CROWDSCORE_QUERY, {
    variables: {
      connectionId,
    },
  });

  if (!data?.clientConnection?.crowdScore && loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  if (data?.clientConnection?.crowdScore) {
    return (
      <FlexRow>
        <Score>{`${data.clientConnection.crowdScore.score}/100`}</Score>
      </FlexRow>
    );
  }

  return <div>Unable to fetch CrowdScore.</div>;
}

const CrowdScoreWidget: WidgetDefinition = {
  name: 'CrowdScore',
  slug: 'crowdscore',
  connectorSlug: 'crowdstrike',
  component: CrowdScoreWidgetComponent,
  description: 'CrowdScore',
};

export default CrowdScoreWidget;
