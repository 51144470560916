import { useState } from 'react';
import * as yup from 'yup';
import { NuButton } from 'components/nuspire';
import { Copy as CopyIcon } from 'components/nuspire/nu-icon';
import { CREATE_WIDGET } from './widget-editor';
import { IWidget } from 'components/dashboard/dashboard-details';
import { Formik, FormikContextType, useField } from 'formik';
import { Form as AntForm, message, Modal, Tooltip } from 'antd';
import Input from 'components/nuspire/input';
import { useClientContext } from 'components/client-context-provider';
import { useMutation } from '@apollo/client';

function NameInput(props: {
  fieldName: string; // 'name'
}) {
  const { fieldName } = props;

  const [{ value, onBlur }, { touched, error }, { setValue }] = useField({ name: fieldName });
  const hasError = Boolean(touched && error);

  return (
    <AntForm.Item
      label="Name"
      hasFeedback={hasError}
      validateStatus={hasError ? 'error' : undefined}
      status={hasError ? 'error' : undefined}
      required
    >
      <Input value={value} onChange={(e) => setValue(e.target.value)} onBlur={onBlur} />
    </AntForm.Item>
  );
}

interface DuplicateWidgetButtonProps {
  widget: IWidget;
  formikContext?: FormikContextType<any>;
}

const schema = yup.object().shape({
  name: yup.string().required(),
});

interface DuplicateWidgetModalProps extends DuplicateWidgetButtonProps {
  onClose: () => void;
}

function DuplicateWidgetModal(props: DuplicateWidgetModalProps) {
  const {
    widget: { name, widgetSlug },
    onClose,
  } = props;
  const { clientId } = useClientContext();

  // mutation
  const [createWidget] = useMutation(CREATE_WIDGET);

  // current settings in form.
  const settings = props.formikContext?.values;

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        name: `${name} (copy)`,
      }}
      onSubmit={async (values, helpers) => {
        try {
          const input = {
            clientId,
            name: values.name,
            widgetSlug,
            settings,
          };

          const createResult = await createWidget({
            variables: { input },
          });
          const newWidgetId = createResult?.data?.createWidget?.id;

          helpers.setSubmitting(false);

          if (newWidgetId) {
            message.success(`Successfully created widgets.`);

            const href = `${window.location.origin}/${clientId}/widgets/${newWidgetId}`;

            window.open(href);

            onClose();
          }
        } catch (err: any) {
          console.error(err);
          message.error('Failed to duplicate widget');
        }
      }}
    >
      {({ isSubmitting, errors, handleSubmit }) => {
        const disabled = isSubmitting || Boolean(Object.keys(errors).length);

        return (
          <Modal
            open
            title="Duplicate Widget"
            okText="Duplicate"
            okButtonProps={{
              disabled,
              onClick: () => handleSubmit(),
              loading: isSubmitting,
            }}
            onCancel={onClose}
          >
            <AntForm layout="vertical" onSubmitCapture={() => handleSubmit()}>
              <NameInput fieldName="name" />
            </AntForm>
          </Modal>
        );
      }}
    </Formik>
  );
}

export function DuplicateWidgetButton(props: DuplicateWidgetButtonProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Tooltip overlay="Duplicate Widget" placement="bottom">
        <div>
          <NuButton size="large" type="text" onClick={handleOpen}>
            <CopyIcon />
          </NuButton>
        </div>
      </Tooltip>

      {isOpen && <DuplicateWidgetModal {...props} onClose={handleClose} />}
    </>
  );
}

export default DuplicateWidgetButton;
