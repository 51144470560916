import { AccessToken } from '@okta/okta-auth-js';
import { oktaAuth } from './okta';
import { IMPERSONATED_ACCESS_TOKEN_KEY, INTERNAL_ACCESS_TOKEN_KEY } from '../localstorage';

export function parseJwt(token: string): Record<string, any> {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

export function getTokenExpiration(token: string): Date | null {
  const parsed = parseJwt(token);

  if (!('exp' in parsed)) {
    console.warn('Token does not have an expiration set on it. Cannot determine if it is expired.');
    return null;
  }

  return new Date(parsed.exp * 1000);
}

export function isTokenExpired(token: string): boolean {
  const exp = getTokenExpiration(token);
  if (exp === null) {
    return false;
  }

  const isExpired = Date.now() >= exp.getTime();
  return isExpired;
}

export async function getAccessToken(): Promise<string | null> {
  const token = await oktaAuth.tokenManager.get('accessToken');

  const impersonatedAccessToken: string | null = getImpersonatedAccessToken();
  const internalToken: string | null = getInternalAccessToken();
  const oktaAccessToken: string | undefined = (token as AccessToken)?.accessToken;

  return impersonatedAccessToken ?? internalToken ?? oktaAccessToken;
}

/**
 * Impersonated tokens are stored in sessionStorage instead of localStorage
 * so they are cleared when the browser session ends. They'll still survive
 * page refreshes, but not when the tab/browser is closed.
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage
 */
export function getImpersonatedAccessToken(): string | null {
  return window.sessionStorage.getItem(IMPERSONATED_ACCESS_TOKEN_KEY);
}

export function setImpersonatedAccessToken(accessToken: string): void {
  window.sessionStorage.setItem(IMPERSONATED_ACCESS_TOKEN_KEY, accessToken);
}

export function clearImpersonatedAccessToken(): void {
  window.sessionStorage.removeItem(IMPERSONATED_ACCESS_TOKEN_KEY);
}

export function isImpersonationActive(): boolean {
  return getImpersonatedAccessToken() !== null;
}

export function getInternalAccessToken(): string | null {
  return window.localStorage.getItem(INTERNAL_ACCESS_TOKEN_KEY);
}

function clearInternalAccessToken(): void {
  window.localStorage.removeItem(INTERNAL_ACCESS_TOKEN_KEY);
}
