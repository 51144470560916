
export default () => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0369 0.599976H27.403C28.5044 0.599976 29.4001 1.49566 29.4001 2.5971V9.96317C29.4001 10.3971 29.0462 10.7501 28.6123 10.7501C28.1784 10.7501 27.8251 10.3968 27.8251 9.96286V3.28766L19.6904 11.4221C19.5416 11.5709 19.3441 11.6528 19.1339 11.6528C18.9236 11.6528 18.7262 11.5709 18.5774 11.4221C18.4286 11.2733 18.3464 11.0755 18.3467 10.8649C18.3467 10.655 18.4289 10.4576 18.578 10.3091L26.7124 2.17438H20.0369C19.6027 2.17438 19.2494 1.8211 19.2494 1.38718C19.2494 0.953256 19.603 0.599976 20.0369 0.599976Z"
      fill="currentColor"
    />
    <path
      d="M27.8254 20.0368C27.8254 19.6026 28.1787 19.2493 28.6126 19.2493C29.0465 19.2493 29.3998 19.6029 29.3998 20.0368V27.4029C29.3998 28.5043 28.5041 29.4 27.4027 29.4H20.0366C19.6027 29.4 19.2491 29.0467 19.2491 28.6128C19.2491 28.1789 19.6027 27.8256 20.0366 27.8256H26.7121L18.5777 19.6909C18.4286 19.5424 18.3467 19.3447 18.3467 19.1344C18.3467 18.9245 18.4286 18.7264 18.5777 18.5776C18.874 18.2807 19.3934 18.2803 19.691 18.5783L27.8264 26.7123L27.8254 20.0368Z"
      fill="currentColor"
    />
    <path
      d="M10.3092 18.5782L2.1745 26.712V20.0368C2.1745 19.6029 1.82122 19.2493 1.3873 19.2493C0.953378 19.2493 0.600098 19.6029 0.600098 20.0368V27.4029C0.600098 28.5043 1.49578 29.4 2.59722 29.4H9.9633C10.3972 29.4 10.7505 29.0467 10.7505 28.6128C10.7505 28.1789 10.3972 27.8256 9.9633 27.8256H3.28746L11.4222 19.6912C11.571 19.5424 11.6529 19.3446 11.6529 19.1344C11.6529 18.9245 11.571 18.7264 11.4225 18.5782C11.1249 18.2803 10.6055 18.2803 10.3092 18.5782Z"
      fill="currentColor"
    />
    <path
      d="M9.9633 2.17438H3.28746L11.4222 10.3101C11.5707 10.4579 11.6529 10.6553 11.6529 10.8653C11.6532 11.0758 11.571 11.2736 11.4222 11.4227C11.2737 11.5712 11.0759 11.6531 10.8657 11.6531C10.6555 11.6531 10.4577 11.5712 10.3092 11.4221L2.17354 3.2867L2.1745 9.96317C2.1745 10.3971 1.82122 10.7504 1.3873 10.7504C0.953378 10.7504 0.600098 10.3971 0.600098 9.96317V2.5971C0.600098 1.49566 1.49578 0.599976 2.59722 0.599976H9.9633C10.3972 0.599976 10.7505 0.953256 10.7505 1.38718C10.7505 1.8211 10.3972 2.17438 9.9633 2.17438Z"
      fill="currentColor"
    />
  </svg>
);
