import { Collapse } from 'antd';
import { ComponentProps } from 'react';
import styled, { css } from 'styled-components';

/**
 * this was needed to unify the expandIcon between Collapse & Menu.
 *
 * copied from antd v5 menu.
 * supports Collapse['expandIcon'] and Menu['expandIcon']
 */
const ExpandArrow = styled.div<{
  $isExpanded: boolean
}>`
  position: absolute;
  top: 50%;
  inset-inline-end: 16px;
  width: 10px;
  color: currentcolor;
  transform: translateY(-50%);
  transition:
    transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.3s;

  &::before,
  &::after {
    position: absolute;
    width: 6px;
    height: 1.5px;
    background-color: currentcolor;
    border-radius: 6px;
    transition:
      background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content: '';
  }

  &::before {
    transform: rotate(-45deg) translateX(2.5px);
  }

  &::after {
    transform: rotate(45deg) translateX(-2.5px);
  }

  ${(p) => p.$isExpanded && css`
    transform: translateY(-2px);
    &::before {
      transform: rotate(45deg) translateX(2.5px);
    }
    &::after {
      transform: rotate(-45deg) translateX(-2.5px);
    }
  `}
`;

type CollapsePanelProps = Parameters<NonNullable<ComponentProps<typeof Collapse>['expandIcon']>>[0];
interface ExpandIconProps extends CollapsePanelProps {
  isOpen?: boolean;
}

export function ExpandIcon(props: ExpandIconProps) {
  const { isOpen, isActive } = props;
  const isExpanded = isOpen ?? isActive ?? true;

  return (
    <ExpandArrow $isExpanded={isExpanded} />
  );
}
