import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { gql, useQuery } from '@apollo/client';
import { Button, Card, Form, FormItemProps, message, Space, Spin, Upload } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { GetClientLogoQuery } from '../../types/graph-codegen/graph-types';
import { SpinContainer } from '../nuspire/spin';

const { Dragger } = Upload;

export const UPDATE_CLIENT_LOGO = gql`
  mutation UpdateClientLogo($input: UpdateLogoInput!) {
    updateClientLogo(input: $input) {
      id
      logoUrl
    }
  }
`;

export const GET_CLIENT_LOGO = gql`
  query GetClientLogo($clientId: String!, $shallow: Boolean) {
    getClientById(id: $clientId) {
      id
      logoUrl(shallow: $shallow)
    }
  }
`;

export const DELETE_CLIENT_LOGO = gql`
  mutation DeleteClientLogo($clientId: String!) {
    deleteClientLogo(clientId: $clientId) {
      id
    }
  }
`;

const StyledDragger = styled(Dragger)<{ $hasFile: boolean }>`
  ${(p) => p.$hasFile && css`
    .ant-upload {
      display: none;
    }
  `}
`;

const LogoCard = styled(Card)`
  .ant-card-body {
    padding: 8px;
  }
  img {
    max-height: 48px;
  }
`;

interface ClientLogoUploadFormItemProps extends Omit<FormItemProps, 'valuePropName' | 'getValueFromEvent'> {
  loading?: boolean;
}
export function ClientLogoUploadFormItem(props: ClientLogoUploadFormItemProps) {
  const { loading, ...formItemProps } = props;
  const [file, setFile] = useState<File>();

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  // https://ant.design/components/upload/#components-upload-demo-upload-manually
  const beforeUpload = (f: File) => {
    const isJpgOrPng = f.type === 'image/jpeg' || f.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return Upload.LIST_IGNORE;
    }

    const isTooBig = f.size / 1024 / 1024 < 10;
    if (!isTooBig) {
      message.error('Image must smaller than 10MB!');
      return Upload.LIST_IGNORE;
    }

    setFile(f);
    return false;
  };

  const onRemove = () => {
    setFile(undefined);
  };

  return (
    <Form.Item
      valuePropName="fileList"
      getValueFromEvent={normFile}
      {...formItemProps}
    >
      <StyledDragger
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        disabled={loading}
        maxCount={1}
        listType="picture"
        $hasFile={file !== undefined}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload client logo.</p>
      </StyledDragger>
    </Form.Item>
  );
}

interface ExistingClientLogoFormItemProps {
  name: string;
  label?: ReactNode;
  onDelete: () => void;
  logoUrl?: string;
}

export function ExistingClientLogoFormItem(props: ExistingClientLogoFormItemProps) {
  const { name, label, logoUrl, onDelete } = props;

  const form = Form.useFormInstance();
  const onDeleteClick = () => {
    onDelete();
    form.setFieldValue(props.name, true);
  };

  return (
    <Form.Item
      name={name}
      label={label}
      noStyle={logoUrl === undefined}
    >
      {logoUrl && (
        <LogoCard>
          <Space
            direction="horizontal"
            style={{
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <img src={logoUrl} alt="Client Logo" />
            <Button icon={<DeleteOutlined />} type="text" size="small" onClick={onDeleteClick} />
          </Space>
        </LogoCard>
      )}
    </Form.Item>
  );
}

interface ClientLogoUploadProps extends ClientLogoUploadFormItemProps {
  deleteName: string;
  clientId?: string;
}

export function ClientLogoUpload(props: ClientLogoUploadProps) {
  const { clientId, deleteName, ...formItemProps } = props;

  const [logoUrl, setLogoUrl] = useState<string>();
  const { data, loading } = useQuery<GetClientLogoQuery>(GET_CLIENT_LOGO, {
    variables: {
      clientId,
    },
    skip: clientId === undefined,
  });

  useEffect(() => {
    setLogoUrl(data?.getClientById?.logoUrl ?? undefined);
  }, [data]);

  const onDelete = () => {
    setLogoUrl(undefined);
  };

  return loading ? (
    <>
      {formItemProps.label && <div style={{ marginBottom: '0.5rem' }}>{formItemProps.label}</div>}
      <SpinContainer>
        <Spin />
      </SpinContainer>
    </>
  ) : (
    <>
      <ExistingClientLogoFormItem name={deleteName} label={formItemProps.label} logoUrl={logoUrl} onDelete={onDelete} />
      {!logoUrl && <ClientLogoUploadFormItem {...formItemProps} />}
    </>
  );
}
