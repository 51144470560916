import React, { useCallback, useEffect, useState } from 'react';
import { DataType, IInput } from './data-explorer';
import styled from 'styled-components';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import baseTheme from 'components/theme';
import { useClientContext } from 'components/client-context-provider';
import useSearchParams from 'utils/react-hooks/useSearchParams';
import { getInput } from 'components/cyberx/actions/action-form/action-form-field/action-form-field';
import { Radio, Select } from 'antd';
import { useSortParams } from './data-explorer-table';
import { parseSearchParam } from 'components/data-explorer/search-params';
import debounce from 'lodash.debounce';
import { DataExplorerFilters } from './data-explorer-filters';
import { FilterInputOperator } from 'types/graph-codegen/graph-types';
import { FilterInput } from 'components/widgets/widget/types';

const FilterListItemRoot = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-bottom: 1px solid ${(p) => p.theme.token.colorBorderSecondary};
  margin-bottom: 20px;

  &:last-child {
    border-bottom: none;
  }
`;

const FilterListItemHeader = styled.div`
  padding: 8px 20px;
  display: flex;

  &:hover {
    background-color: ${(p) => p.theme.token.colorBgTextHover};
  }
`;

const FilterListItemLabel = styled.div`
  flex: 1;
  font-weight: 600;
  color: ${baseTheme.color.gray6};
`;

const FilterOptionsDropdown = styled.div`
  padding: 8px 20px;
  display: flex;
`;

export function checkShouldRemoveFilter(val: any, operator?: string) {
  if (operator && ['IS_SET', 'IS_NOT_SET'].includes(operator)) {
    // value can be empty in this case.
    return false;
  }

  if (typeof val === 'string' && val.length === 0) {
    return true;
  }

  if (Array.isArray(val) && val.length === 0) {
    return true;
  }

  return false;
}

/**
 * Filter InputListItem is being used by both DataExplorer and CaseManagement.
 * Need to keep anything data explorer specific out of here and just have generic props.
 *
 * @param props
 * filterInput: IInput this is the Input Definition from the backend.
 *
 * handleAddFilter...
 *
 * @returns
 */
export function FilterInputListItem(props: {
  filterInput: IInput;
  handleAddFilter: (key: string, value: string) => void;
  handleRemoveFilter: (key: string) => void;
}) {
  const { filterInput, handleAddFilter, handleRemoveFilter } = props;
  const { clientId } = useClientContext();

  // Pull value out of search params.
  const { parsed: params } = useSearchParams();
  const searchParamsValue = params?.[filterInput.key];

  // Saving value as state... parsing comma separated values.
  const [value, setValue] = useState<any>(searchParamsValue);
  const [operator, setOperator] = useState<string | undefined>();

  // using effect so we don't split unnecessarily.
  // should also parse out operators here?
  useEffect(() => {
    if (searchParamsValue) {
      const [val, op] = parseSearchParam(searchParamsValue);

      setOperator(op || undefined);
      setValue(val);
    } else {
      setValue(undefined);
    }
  }, [searchParamsValue]);

  const inputDef = getInput({
    type: filterInput.type,
    inputType: filterInput.inputType,
  });
  const FilterInputComponent = inputDef.input;

  // Change should always be a string since we're using search params
  const handleChange = (val: string | boolean | any[], operator?: string) => {
    const shouldRemove = checkShouldRemoveFilter(val, operator);
    if (shouldRemove) {
      return handleRemoveFilter(filterInput.key);
    }

    let filterString = JSON.stringify(val);

    if (operator && ['IS_SET', 'IS_NOT_SET'].includes(operator)) {
      filterString = `${operator}:`;

      return handleAddFilter(filterInput.key, filterString);
    }

    // we want filters to go away when they are empty.
    if (filterString && operator) {
      filterString = `${operator}:${filterString}`;
    }

    handleAddFilter(filterInput.key, filterString);
  };

  return (
    <FilterListItemRoot>
      <FilterListItemHeader>
        <FilterListItemLabel>
          {filterInput.required ? `${filterInput.label} (required)` : filterInput.label}
        </FilterListItemLabel>
      </FilterListItemHeader>
      <FilterOptionsDropdown>
        <FilterInputComponent
          clientId={clientId}
          field={filterInput}
          name={filterInput.key}
          onChange={handleChange}
          operator={operator}
          required={filterInput.required}
          value={value}
        />
      </FilterOptionsDropdown>
    </FilterListItemRoot>
  );
}

/**
 * Filter InputListItem is being used by both DataExplorer and CaseManagement.
 * Need to keep anything data explorer specific out of here and just have generic props.
 *
 * @param props
 * filterInput: IInput this is the Input Definition from the backend.
 *
 * handleAddFilter...
 *
 * @returns
 */
export function FilterInputListItemRaw(props: {
  filterInput: IInput;

  // Currently this is the value from the SearchParamFilters class. (which relies on parsing search params.)
  value: any;
  operator?: FilterInputOperator;

  // Trying to debug search params not updating...
  dataExplorerFilters: DataExplorerFilters;

  onChange: (value: any, operator?: FilterInputOperator) => void; // should pass actual value of the filter.
  onRemove: () => void; //
}) {
  const { filterInput, onChange, onRemove, value, operator, dataExplorerFilters } = props;
  const { clientId } = useClientContext();

  const handleLocalChange = (newLocalValue: any, newLocalOperator?: string) => {
    onChange(newLocalValue, newLocalOperator as FilterInputOperator);
  };

  const inputDef = getInput({
    type: filterInput.type,
    inputType: filterInput.inputType,
  });
  const FilterInputComponent = inputDef.input;

  return (
    <FilterListItemRoot>
      <FilterListItemHeader>
        <FilterListItemLabel>
          {filterInput.required ? `${filterInput.label} (required)` : filterInput.label}
        </FilterListItemLabel>
      </FilterListItemHeader>
      <FilterOptionsDropdown>
        <FilterInputComponent
          clientId={clientId}
          field={filterInput}
          name={filterInput.key}
          onChange={handleLocalChange}
          operator={operator}
          required={filterInput.required}
          value={value}
        />
      </FilterOptionsDropdown>
    </FilterListItemRoot>
  );
}

const SortSelect = styled(Select<string>).attrs({
  allowClear: true,
})`
  flex: 1;
`;

const SortRadioGroup = styled(Radio.Group).attrs({
  buttonStyle: 'solid',
})`
  margin-left: 8px;
`;

interface SortFilterInputListItemProps {
  attributes: {
    key: string;
    title?: string;
    sorter?: boolean;
    defaultSortOrder?: 'ascend' | 'descend';
  }[];
}

export function SortFilterInputListItem(props: SortFilterInputListItemProps) {
  const { attributes } = props;
  const { onSortChange, sort, sortBy } = useSortParams();

  const selectOptions = attributes
    .filter(({ title, sorter }) => title !== undefined && sorter === true)
    .map((attribute) => ({
      value: attribute.key,
      label: attribute.title,
    }));

  return (
    <FilterListItemRoot>
      <FilterListItemHeader>
        <FilterListItemLabel>Sort</FilterListItemLabel>
      </FilterListItemHeader>
      <FilterOptionsDropdown>
        <SortSelect
          onChange={(value) => {
            const attributeDefaultSortOrder = attributes.find((attribute) => attribute.key === value)?.defaultSortOrder;

            onSortChange({
              sortBy: value,
              sort: sort ?? attributeDefaultSortOrder ?? 'descend',
            });
          }}
          options={selectOptions}
          value={sortBy}
          placeholder="Sort by Attribute"
        />
        <SortRadioGroup
          onChange={(e) => onSortChange({ sort: e.target.value })}
          value={sort}
          disabled={sortBy === undefined}
        >
          <Radio.Button value="ascend">Ascending</Radio.Button>
          <Radio.Button value="descend">Descending</Radio.Button>
        </SortRadioGroup>
      </FilterOptionsDropdown>
    </FilterListItemRoot>
  );
}

export const FilterListRoot = styled.div`
  padding: 8px 0;
`;

function DataExplorerFilterList(props: { dataType: DataType; dataExplorerFilters: DataExplorerFilters }) {
  const { dataType, dataExplorerFilters } = props;
  const { filterInputs, columns = [] } = dataType;

  // I wonder if returning this method is somehow locking the instance of dataExplorerFilters up?.
  // like we're creating this variable `handleChange`
  const handleChange = (key: string) => (value: any, operator?: FilterInputOperator) => {
    console.log('DataExplorerHandleChange', { key, value, operator });
    dataExplorerFilters.updateFilter({
      key,
      value,
      operator,
    });
  };

  const handleRemove = (key: string) => () => {
    dataExplorerFilters.removeFilter(key);
  };

  return (
    <FilterListRoot>
      {filterInputs?.map((filterInput) => (
        <FilterInputListItemRaw
          key={`filter-input-${filterInput.key}`}
          value={dataExplorerFilters.filtersByKey[filterInput.key]?.value}
          operator={dataExplorerFilters.filtersByKey[filterInput.key]?.operator}
          filterInput={filterInput}
          dataExplorerFilters={dataExplorerFilters}
          onChange={handleChange(filterInput.key)}
          onRemove={handleRemove(filterInput.key)}
        />
      ))}
      <SortFilterInputListItem attributes={columns} />
    </FilterListRoot>
  );
}

export const FilterDropdownRoot = styled.div`
  background-color: ${(p) => p.theme.token.colorBgContainer};
  border: 1px solid ${(p) => p.theme.token.colorBorder};
  border-radius: 4px;
  max-height: 500px;
  overflow-y: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
`;

// specific to DataExplorer FilterDropdown
export function FilterDropdown(props: {
  dataType?: DataType;
  handleAddFilter: (key: string, value: string) => void;
  handleRemoveFilter: (key: string) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  dataExplorerFilters: DataExplorerFilters;
}) {
  const { dataType } = props;

  return (
    <FilterDropdownRoot onClick={props.onClick}>
      {!dataType ? (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      ) : (
        <DataExplorerFilterList {...props} dataType={dataType} />
      )}
    </FilterDropdownRoot>
  );
}
