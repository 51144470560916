export const getServiceNowAccountCategory = (categoryValue?: string): string | undefined => {
  switch (categoryValue?.toLowerCase()) {
    case 'direct':
      return 'Direct';
    case 'nuspire_internal':
      return 'Nuspire Internal';
    case 'partner':
      return 'Partner';
    case 'program':
      return 'Program';
    default:
      return undefined;
  }
};
