import { useQuery } from '@apollo/client';
import ForceDeleteUserButton from 'components/admin/users/force-delete-user-button';
import RestoreUserButton from 'components/admin/users/restore-user-button';
import SetPasswordAndActivateUserButton from 'components/admin/users/set-password-and-activate-user-button';
import { useAuthContext } from 'components/auth-context';
import { useAuthorize } from 'components/authorization';
import { CLIENT_CONTEXT } from 'components/client-context-provider';
import DeleteUserButton from 'components/users/user-management/delete-user-button';
import ResendActivationEmailButton from 'components/users/user-management/resend-activation-email-button';
import UnlockUserButton from 'components/users/user-management/unlock-user-button';
import { Access, IUser } from 'types';
import { UserAuthenticationType } from '../../../types';
import ResetMultifactorForUserButton from './reset-multifactor-for-user-button';

type UserActionButtonsProps = {
  onActionSuccess?: () => void;
  onForceDelete?: () => void;
  user: IUser;
};

export default function UserActionButtons(props: UserActionButtonsProps) {
  const { onActionSuccess, onForceDelete = onActionSuccess, user } = props;
  const { user: authUser } = useAuthContext();
  const { data } = useQuery(CLIENT_CONTEXT, { variables: { clientId: authUser?.clientId } });
  const isSuperUser = authUser?.isSuperUser === true;
  const authorize = useAuthorize({
    effectivePermissions: data?.getClientById?.effectivePermissions,
  });
  const { authorized } = authorize({
    requiredPermissions: {
      users: Access.write,
    },
  });

  // Safeguard in case the user doesn't have permission to perform an action on a user.
  if (!authorized && !isSuperUser) return null;

  // Can't modify your own user.
  if (authUser && authUser.id === user.id) return null;

  // Make sure the user is a email/username user
  const isEmailAndPasswordUser = user.authenticationType === UserAuthenticationType.EMAIL_AND_PASSWORD;

  const { authorized: canWriteAllUsers } = authorize({
    requiredPermissions: {
      allUsers: Access.write,
    },
  });
  const { authorized: canExecuteAllUsers } = authorize({
    requiredPermissions: {
      allUsers: Access.execute,
    },
  });

  // Admin actions.
  if (!user.deletedAt) {
    const canResendActivationEmail = user.status === 'PROVISIONED';
    const isUserLockedOut = user.status === 'LOCKED_OUT';

    return (
      <>
        <UnlockUserButton
          disabled={!isEmailAndPasswordUser || !isUserLockedOut}
          onSuccess={onActionSuccess}
          style={{ marginRight: '0.25rem' }}
          user={user}
        />
        <SetPasswordAndActivateUserButton
          disabled={!isEmailAndPasswordUser || !canWriteAllUsers || !canResendActivationEmail}
          onSuccess={onActionSuccess}
          style={{ marginRight: '0.25rem' }}
          user={user}
        />
        <ResendActivationEmailButton
          disabled={!isEmailAndPasswordUser || !canResendActivationEmail}
          onSuccess={onActionSuccess}
          style={{ marginRight: '0.25rem' }}
          user={user}
        />
        <ResetMultifactorForUserButton onSuccess={onActionSuccess} style={{ marginRight: '0.25rem' }} user={user} />
        <DeleteUserButton onSuccess={onActionSuccess} user={user} />
      </>
    );
  }

  if (canWriteAllUsers || canExecuteAllUsers) {
    return (
      <>
        <RestoreUserButton disabled={!canWriteAllUsers} onSuccess={onActionSuccess} user={user} />
        <ForceDeleteUserButton
          disabled={!canExecuteAllUsers}
          onSuccess={onForceDelete}
          style={{ marginLeft: '0.25rem' }}
          user={user}
        />
      </>
    );
  }

  return null;
}
