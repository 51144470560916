import { gql } from '@apollo/client';

export const FILTER_INPUTS_FRAGMENT = gql`
  fragment FilterInputFields on ActionFormField {
    key
    label
    type
    jsonSchema
    required
    editable
    inputType
    parameters
    inputType
    hasGetOptions
    defaultString
    filterInputOperators
  }
`;

export const DATA_TYPE_FILTERS_QUERY = gql`
  ${FILTER_INPUTS_FRAGMENT}

  query DataTypeFiltersAndColumns($dataTypeSlug: String!, $clientId: String) {
    dataType(slug: $dataTypeSlug) {
      id

      # does this data type support text search?
      supportSearch

      # Support Date Range?
      supportsDateRange

      # If present check time selected to make sure user isn't going back too far.
      minTime

      columns(clientId: $clientId) {
        key
        title
        sorter
        defaultSortOrder
      }

      # filterInputs {
      filterInputs {
        ...FilterInputFields
      }
    }
  }
`;
