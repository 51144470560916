import { Card } from 'antd';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface INSPInfoCardProps {
  className?: string;
  style?: React.CSSProperties;
  title: string | JSX.Element;
  children: ReactNode;
}

const StyledInfoCard = styled(Card)`
  border-radius: 2px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-top: 6px solid ${(p) => p.theme.color.primary};
`;

function InfoCard({ className, children, style, title }: INSPInfoCardProps) {
  return (
    <StyledInfoCard
      title={title}
      bordered={false}
      styles={{ header: { fontSize: '18px' }, body: { padding: 0 } }}
      className={className}
      style={style}
    >
      {children}
    </StyledInfoCard>
  );
}

export default InfoCard;
