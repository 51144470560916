import { useState, useEffect, useCallback, useRef, RefObject } from 'react';

const useElementSize = (elementRef: RefObject<HTMLElement>) => {
  const [elementSize, setElementSize] = useState({
    width: 0,
    height: 0,
  });

  const observer = useRef({} as ResizeObserver);

  const listener = useCallback(
    () =>
      elementRef?.current &&
      setElementSize({
        width: elementRef.current.offsetWidth,
        height: elementRef.current.offsetHeight,
      }),
    [elementRef],
  );

  useEffect(() => {
    if (!elementRef.current) return;
    observer.current = new ResizeObserver(() => {
      listener();
    });

    observer.current.observe(elementRef.current);

    // eslint-disable-next-line consistent-return
    return () => {
      if (elementRef.current) {
        observer.current.unobserve(elementRef.current);
      }
    };
  }, []);

  return elementSize;
};

export default useElementSize;
