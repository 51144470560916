import { gql, useQuery } from '@apollo/client';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import baseTheme from 'components/theme';
import { Doughnut } from 'components/nuspire/charts/doughnut-chart';
import { Alert, Row } from 'antd';
import { WidgetDefinition, WidgetRendererProps } from 'components/dashboard/widget-definitions/types';
import { NuCardContent } from 'components/nuspire';
import { DoughnutFlex, DoughnutWrap } from 'components/widgets/widget/widget-types/doughnut';

const CBC_DEVICES_SUMMARY_WIDGET = gql`
  query CbcDevicesSummaryWidget($connectionId: String!) {
    clientConnection(id: $connectionId) {
      id

      publicConnectionDataJson

      carbonBlackCloudDeviceSummary {
        active {
          count
          percentage
        }
        bypass {
          count
          percentage
        }
        deregistered {
          count
          percentage
        }
        inactive {
          count
          percentage
        }
        quarantine {
          count
          percentage
        }
        sensorOutofdate {
          count
          percentage
        }
      }
    }
  }
`;

type SummaryStats = {
  count: number;
  percentage: number;
};
interface CarbonBlackCloudDeviceSummary {
  active: SummaryStats;
  bypass: SummaryStats;
  deregistered: SummaryStats;
  inactive: SummaryStats;
  quarantine: SummaryStats;
  sensorOutofdate: SummaryStats;
}

interface DoughnutDataset {
  label: string;
  data: number[];
  backgroundColor: string[];
  borderColor?: string[];
  borderWidth?: number;
}

const STATUS_MAP: {
  [key: string]: {
    label: string;
    backgroundColor: string;
  };
} = {
  active: {
    label: 'Active',
    backgroundColor: baseTheme.color.green,
  },
  inactive: {
    label: 'Inactive',
    backgroundColor: baseTheme.color.slate,
  },
  quarantine: {
    label: 'Quarantine',
    backgroundColor: baseTheme.color.orange,
  },
  bypass: {
    label: 'Bypass',
    backgroundColor: baseTheme.color.primaryGray,
  },
};

function transformDoughnutData(summary: CarbonBlackCloudDeviceSummary) {
  const doughnutData = Object.keys(STATUS_MAP).reduce(
    (
      acc: {
        dataset: DoughnutDataset;
        labels: string[];
        total: number;
      },
      key,
    ) => {
      const mapping = STATUS_MAP[key];
      const statusSummary = summary[key];

      if (statusSummary) {
        acc.labels.push(mapping.label);
        acc.dataset.backgroundColor.push(mapping.backgroundColor);
        acc.dataset.data.push(statusSummary.count);
        acc.total += statusSummary.count;
      }

      return acc;
    },
    {
      dataset: {
        label: 'Endpoint Status',
        data: [],
        backgroundColor: [],
        borderWidth: 1,
      },
      labels: [],
      total: 0,
    },
  );

  return doughnutData;
}

function SummaryDoughnut(props: { summary: CarbonBlackCloudDeviceSummary }) {
  const { summary } = props;
  const doughnutData = transformDoughnutData(summary);

  return (
    <Doughnut
      options={{
        cutout: 90,
      }}
      data={{
        labels: doughnutData.labels,
        datasets: [doughnutData.dataset],
      }}
      centerContent={<h1>{`Total: ${doughnutData.total}`}</h1>}
      style={{ minHeight: '300px' }}
    />
  );
}

function CbcDevicesSummaryWidgetContent(props: WidgetRendererProps) {
  const connectionId = props.configuration?.connectionId ?? null;
  const { data, loading } = useQuery(CBC_DEVICES_SUMMARY_WIDGET, { variables: { connectionId }, skip: !connectionId });
  const summary =
    data?.clientConnection?.carbonBlackCloudDeviceSummary ?? (null as CarbonBlackCloudDeviceSummary | null);

  if (!connectionId) {
    return <Alert type="warning" message="Cannot render without Connection" />;
  }

  if (!data && loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  if (summary) {
    return (
      <DoughnutFlex>
        <DoughnutWrap>
          <SummaryDoughnut summary={summary} />
        </DoughnutWrap>
      </DoughnutFlex>
    );
  }

  /**
   * TODO:
   */

  return <div>here...</div>;
}

function CbcDevicesSummaryWidgetComponent(props: WidgetRendererProps) {
  return (
    <NuCardContent>
      <CbcDevicesSummaryWidgetContent {...props} />
    </NuCardContent>
  );
}

const CbcDeviceSummaryWidget: WidgetDefinition = {
  name: 'Carbon Black Device Summary',
  slug: 'cbc-device-summary',
  connectorSlug: 'carbon-black-cloud',
  component: CbcDevicesSummaryWidgetComponent,
  description: 'Summary of Carbon Black Devices and their statuses.',
};

export default CbcDeviceSummaryWidget;
