import { gql, useQuery } from '@apollo/client';
import { Alert, Spin, Table, TableColumnProps, Typography } from 'antd';
import { SpinContainer } from 'components/nuspire/spin';
import { NuEmptyState } from 'components/nuspire/nu-empty-state/nu-empty-state.stories';
import styled from 'styled-components';
import baseTheme from 'components/theme';
import { WidgetRendererProps } from 'components/dashboard/widget-definitions/types';
import { NuCardContent } from 'components/nuspire';
import { WidgetComponentProps } from '../../../../../widgets/widget';

const CBC_ALERTS_WIDGET_QUERY = gql`
  query CbcAlertsWidget($connectionId: String!) {
    clientConnection(id: $connectionId) {
      id

      publicConnectionDataJson

      carbonBlackCloudAlerts {
        numFound
        numAvailable
        results {
          id
          legacyAlertId
          type
          orgKey
          createTime
          lastUpdateTime
          firstEventTime
          threatId
          severity
          category
          deviceId
          deviceOs
          deviceOsVersion
          deviceName
          deviceUsername
          policyId
          policyName
          targetValue
          runState
          reason
        }
      }
    }
  }
`;

interface CbcAlert {
  id?: string;
  legacyAlertId?: string;
  type?: string;
  orgKey?: string;
  createTime?: string;
  lastUpdateTime?: string;
  firstEventTime?: string;
  threatId?: string;
  severity?: number;
  category?: string;
  deviceId?: number;
  deviceOs?: string;
  deviceOsVersion?: string;
  deviceName?: string;
  deviceUsername?: string;
  policyId?: number;
  policyName?: string;
  targetValue?: string;
  runState?: string;
  reason?: string;
}

const StyledAnchor = styled.a`
  color: ${baseTheme.color.nuspireBlue};
`;

function getTableColumns(hostname?: string): TableColumnProps<CbcAlert>[] {
  const tableColumns: TableColumnProps<CbcAlert>[] = [
    {
      title: 'Device',
      dataIndex: 'deviceName',

      // https://defense-prod05.conferdeploy.net/cb/investigate/events?searchWindow=ONE_MONTH&query=device_id%3A49778997
      render: (value, record) => {
        const deviceUrl = hostname
          ? new URL(`${hostname}/cb/investigate/events`)
          : null;

        if (deviceUrl) {
          deviceUrl.searchParams.set('query', `device_id:${record.deviceId}`);
          deviceUrl.searchParams.set('searchWindow', 'ONE_MONTH');
        }

        return (
          <div>
            <Typography.Text>{record.deviceUsername}</Typography.Text>
            <br />
            <Typography.Text>
              {deviceUrl ? (
                <StyledAnchor href={deviceUrl.toString()} target="_blank" rel="noreferrer">
                  {value}
                </StyledAnchor>
              ) : (
                value
              )}
            </Typography.Text>
          </div>
        );
      },
      fixed: 'left',
    },
    {
      title: 'Status',
      dataIndex: 'runState',
      width: 75,
    },
    {
      title: 'First Seen',
      dataIndex: 'firstEventTime',
      width: 125,
      render: (value) => {
        const d = new Date(value);

        return (
          <div>
            <Typography.Text>{d.toLocaleTimeString()}</Typography.Text>
            <br />
            <Typography.Text>{d.toLocaleDateString()}</Typography.Text>
          </div>
        );
      },
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
    },
    {
      title: 'Severity (1-10)',
      dataIndex: 'severity',
      width: 125,
    },
  ];
  return tableColumns;
}

function CbcAlertsTable(props: { alerts: CbcAlert[]; hostname?: string }) {
  const { alerts, hostname } = props;

  return <Table size="small" dataSource={alerts} columns={getTableColumns(hostname)} scroll={{ x: 800, y: 300 }} />;
}

function CbcAlertsWidgetContent(props: WidgetRendererProps) {
  const connectionId = props?.configuration?.connectionId ?? null;
  const { data, loading } = useQuery(CBC_ALERTS_WIDGET_QUERY, { variables: { connectionId }, skip: !connectionId });
  const clientConnection = data?.clientConnection;
  const alerts = data?.clientConnection?.carbonBlackCloudAlerts?.results;

  const publicConnectionData = clientConnection?.publicConnectionDataJson
    ? JSON.parse(clientConnection.publicConnectionDataJson)
    : null;

  const hostname = publicConnectionData?.hostname;

  if (!connectionId) {
    return <Alert type="warning" message="Cannot render without Connection" />;
  }

  if (clientConnection && alerts) {
    return <CbcAlertsTable alerts={alerts} hostname={hostname} />;
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  // TODO: HANDLE ERROR MESSAGE
  return <NuEmptyState>Could not query Carbon Black Alerts.</NuEmptyState>;
}

function CbcAlertsWidgetComponent(props: WidgetRendererProps) {
  return (
    <NuCardContent>
      <CbcAlertsWidgetContent {...props} />
    </NuCardContent>
  );
}

export function CbcAlertsWidget(props: WidgetComponentProps<any, any>) {
  const { data } = props;

  return (
    <CbcAlertsTable alerts={data.tableData} hostname={data.hostname} />
  );
}

export default {
  name: 'Carbon Black Alerts',
  slug: 'cbc-alerts',
  connectorSlug: 'carbon-black-cloud',
  component: CbcAlertsWidgetComponent,
  description: 'Most recent Carbon Black Alerts',
};

