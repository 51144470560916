import styled from 'styled-components';
import { Select } from 'antd';
import { isMyNuspireDev } from '../feature-flags';
import { DataTypeDefinition } from './data-explorer';
import { useAuthContext } from 'components/auth-context';

const DataTypeSelectRoot = styled.div`
  & .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    // background-color: #fafafa;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
`;

export function DataTypeSelect(props: {
  selectedDataTypeSlug?: string;
  dataTypeDefinitions?: DataTypeDefinition[];
  onDataTypeChange: (label: string, slug: string) => void;
}) {
  const { selectedDataTypeSlug, dataTypeDefinitions = [], onDataTypeChange } = props;
  const selectedDataTypeDefinition = dataTypeDefinitions.find((def) => def.id === selectedDataTypeSlug);
  const authContext = useAuthContext();

  const showOtherTechnologies = isMyNuspireDev({ user: authContext?.user });

  const { supportedOptions, unsupportedOptions } = dataTypeDefinitions.reduce(
    (
      acc: {
        supportedOptions: { value: string; label: string }[];
        unsupportedOptions: { value: string; label: string }[];
      },
      dt,
    ) => {
      const option = {
        label: dt.name,
        value: dt.id,
      };

      if (dt.isSupported) {
        acc.supportedOptions.push(option);
      } else {
        acc.unsupportedOptions.push(option);
      }

      return acc;
    },
    { supportedOptions: [], unsupportedOptions: [] },
  );

  const sortedSupportedOptions = supportedOptions.sort((a, b) => a?.label?.localeCompare(b?.label));
  const sortedUnsupportedOptions = unsupportedOptions.sort((a, b) => a?.label?.localeCompare(b?.label));

  let options: any[] = [];

  if (supportedOptions?.length) {
    options = [
      {
        label: 'Your Technologies',
        options: sortedSupportedOptions,
      },
    ];

    // https://sd-at-nuspire.atlassian.net/browse/MYN-3085
    // other technologies are feature flagged such that we only show them to the myNuspire team
    if (showOtherTechnologies) {
      options.push({
        label: 'Other Technologies',
        options: sortedUnsupportedOptions,
      });
    }
  } else {
    options = unsupportedOptions;
  }

  const filterSelect = (input: string, option: any) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const defaultValue =
    selectedDataTypeSlug && selectedDataTypeDefinition
      ? { label: selectedDataTypeDefinition.name!, value: selectedDataTypeSlug! }
      : null;

  return (
    <DataTypeSelectRoot className="data-type-select">
      <Select
        // loading={loading}
        filterOption={filterSelect}
        labelInValue
        onChange={(value: { value: string; label: string }) => {
          onDataTypeChange(value.label, value.value);
        }}
        options={options}
        placeholder="Select Data Type"
        showSearch
        size="large"
        style={{
          width: '350px',
        }}
        value={defaultValue}
      />
    </DataTypeSelectRoot>
  );
}
