import React, { useEffect, useState } from 'react';
import { ActionInputProps, TextInput } from '../../action-form-field';
import styled from 'styled-components';
import { NuButton } from 'components/nuspire';
import { Dropdown, MenuProps, Menu } from 'antd';
import { SelectInput } from '../select';
import { getOperatorLabel } from 'components/data-explorer/search-params';
import { ActionFormFieldType } from 'types/graph-codegen/graph-types';

const OperatorFilterRoot = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  width: 100%;
`;
const OperatorButtonRoot = styled.div`
  margin-right: 12px;
`;
const OperatorInputRoot = styled.div`
  flex: 1;
`;

function OperatorButton(props: { value: string; operators: string[]; onChange: (value: string) => void }) {
  const { value, operators, onChange } = props;

  const label = getOperatorLabel(value);

  const menuItems: MenuProps['items'] = operators.map((o) => ({
    key: o,
    label: getOperatorLabel(o),
  }));

  return (
    <Dropdown
      menu={{
        items: menuItems,
        onClick: (e) => {
          const selected = e.key;
          onChange(selected);
        },
      }}
      trigger={['click']}
    >
      <NuButton type="text">{label}</NuButton>
    </Dropdown>
  );
}

/**
 * IS, IS_NOT should use a select with tags if the field supports multiple.
 *
 * CONTAIN, NOT_CONTAIN should be a text.
 *
 * IS_SET, IS_NOT_SET does not have an input.
 */
export function OperatorInput(props: { filterInputOperator?: string; actionInputProps: ActionInputProps }) {
  const {
    actionInputProps,
    actionInputProps: { field, name },
  } = props;

  let { filterInputOperator } = props;

  if (!filterInputOperator) {
    filterInputOperator = 'IS';
  }

  if (['IS_SET', 'IS_NOT_SET'].includes(filterInputOperator)) {
    return null;
  }

  if (['IS', 'IS_NOT'].includes(filterInputOperator)) {
    return (
      <SelectInput
        {...actionInputProps}
        field={{
          ...(actionInputProps.field ?? {
            key: name,
            label: field?.label ?? name,
            type: ActionFormFieldType.Array,
          }),
          parameters: field?.parameters ?? {
            mode: 'tags',
            placeholder: `Filter by ${field?.label ?? name}`,
          },
        }}
      />
    );
  }

  if (['CONTAIN', 'NOT_CONTAIN'].includes(filterInputOperator)) {
    return <TextInput {...actionInputProps} />;
  }

  // not sure what to do here...
  return null;
}

export function OperatorFilter(props: ActionInputProps) {
  const { field, onChange } = props;
  let { value } = props;

  const operators = field?.filterInputOperators ?? ['IS'];
  const { operator = operators[0] } = props;
  // use first operator as the default for now
  const [operatorState, setOperator] = useState<string>(operators[0]);

  const handleOperator = (newOperator: string) => {
    setOperator(newOperator);

    let newVal = value;

    if (['IS_SET', 'IS_NOT_SET'].includes(newOperator)) {
      // IS AND IS NOT SET DO NOT NEED ANY MORE VALUES.
      newVal = null;

      return onChange(newVal, newOperator);
    }

    if (newVal) {
      return onChange(newVal, newOperator);
    }
  };

  // try to keep local state and search params in sync...
  useEffect(() => {
    if (operator !== operatorState) {
      setOperator(operator);
    }
  }, [operator]);

  return (
    <OperatorFilterRoot>
      <OperatorButtonRoot>
        <OperatorButton value={operatorState} operators={operators} onChange={handleOperator} />
      </OperatorButtonRoot>

      <OperatorInputRoot>
        <OperatorInput
          filterInputOperator={operatorState}
          actionInputProps={{
            ...props,
            onChange: (v: any) => {
              return onChange(v, operatorState);
            },
          }}
        />
      </OperatorInputRoot>
    </OperatorFilterRoot>
  );
}
