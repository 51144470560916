import { gql, useQuery } from '@apollo/client';
import { NuButton } from './nuspire';
import { message, Spin } from 'antd';
import styled, { useTheme } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { useClientContext } from './client-context-provider';
import { useEffect } from 'react';
import { mixpanelTrack } from 'utils/mixpanel/mixpanel-track';

export const GET_ENTITLEMENTS = gql`
  query GetEntitlements($clientId: String) {
    entitlements(clientId: $clientId) {
      sys_id
      entitlement_name
      u_url
    }
  }
`;

export type Entitlement = {
  active?: boolean;
  end_date?: string;
  entitlement_name: string;
  start_date?: string;
  sys_id: string;
  u_service_description?: string;
  u_url?: string;
};

export type GetEntitlementsQuery = {
  entitlements?: Entitlement[];
};

export type GetEntitlementsQueryVariables = {
  clientId?: string;
};

const PaddingRight = styled.span`
  padding-right: 8px;
`;

const PaddingLeftAndRight = styled.span`
  padding-left: 8px;
  padding-right: 8px;
`;

const EntitlementButton = styled(NuButton)`
  background-color: white !important;
  border-color: ${(p) => p.theme.color.primaryBlue} !important;
  color: ${(p) => p.theme.color.primaryBlue} !important;

  &:hover {
    color: #fff !important;
    background-color: ${(p) => p.theme.color.primaryBlue} !important;
  }
`;

const EmergencyButton = styled(NuButton)``;

function openInNewTab(url?: string) {
  if (!url) {
    return;
  }

  const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
  if (newWindow) {
    newWindow.opener = null;
  }
}

const handleEmergencyClick = () => {
  openInNewTab('https://www.nuspire.com/emergency-breach-response/');
  mixpanelTrack('emergency-breach-response');
};

export function EntitlementButtons() {
  const theme = useTheme();
  const { clientId } = useClientContext();
  const { data, error, loading } = useQuery<GetEntitlementsQuery, GetEntitlementsQueryVariables>(GET_ENTITLEMENTS, {
    variables: {
      clientId: clientId ?? undefined,
    },
  });

  if (loading && !data) {
    return <Spin />;
  }

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error('An error occurred while attempting to load your entitlements.');
    }
  }, [error]);

  // Remove duplicate entitlements
  const entitlements =
    data?.entitlements?.reduce((acc, curr) => {
      if (acc && acc.findIndex((a) => a.entitlement_name === curr.entitlement_name) < 0) {
        acc.push(curr);
      }
      return acc;
    }, [] as GetEntitlementsQuery['entitlements']) ?? [];

  const [firstEntitlement = null, secondEntitlement = null] = entitlements;

  return (
    <>
      <PaddingRight>
        {!isMobile ? (
          <EmergencyButton type="primary" danger onClick={handleEmergencyClick}>
            Emergency Incident Response
          </EmergencyButton>
        ) : (
          <a href="tel:877-435-1640">
            <EmergencyButton type="primary">Emergency Incident Response</EmergencyButton>
          </a>
        )}
      </PaddingRight>

      {firstEntitlement && (
        <PaddingLeftAndRight>
          <EntitlementButton onClick={() => openInNewTab(firstEntitlement.u_url)}>
            {firstEntitlement.entitlement_name}
          </EntitlementButton>
        </PaddingLeftAndRight>
      )}

      {secondEntitlement && (
        <PaddingLeftAndRight>
          <EntitlementButton
            style={{ backgroundColor: theme.color.slateBlue }}
            onClick={() => openInNewTab(secondEntitlement.u_url)}
          >
            {secondEntitlement.entitlement_name}
          </EntitlementButton>
        </PaddingLeftAndRight>
      )}
    </>
  );
}
