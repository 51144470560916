import { useTheme } from 'styled-components';
import { ChartProps, Group, Text } from './common-components';
import { useMemo } from 'react';
import { scaleLinear, scaleLog } from '@visx/scale';
import { curveBasis } from '@visx/curve';
import { LinearGradient } from '@visx/gradient';
import { Area } from '@visx/shape';
import { Link } from 'react-router-dom';

// controls the areas rendered and creates a drop shadow effect
export const AREAS = [
  { pad: 0, opacity: 1 },
  { pad: 10, opacity: 0.2 },
  { pad: 20, opacity: 0.1 },
];

export type FunnelChartData = {
  label?: string;
  link?: string;
  value: number;
};

export type InterpolatedData = FunnelChartData & {
  index: number;
};

/*
 * controls the thinness towards the end of the funnel.
 * - lower = thicker.
 * - must be less than 1.0.
 */
const MIN_DOMAIN_VALUE_PADDING = 0.75;
// leaves room above and below the rendered area.
const MARGIN_PX = 50;
// this must be 1 because log(0) = undefined.
const MIN_VALUE = 1;

function interpolatePoints(args: {
  current: FunnelChartData;
  next?: FunnelChartData;
  index: number;
}): InterpolatedData[] {
  const { index, current, next } = args;
  if (!next)
    return [
      {
        index: index + 1,
        value: current.value,
      },
    ];

  const xStep = 0.25;
  const yStep = Math.abs(next.value - current.value) * 0.03;
  const yMid1 = Math.max(Math.abs(current.value - yStep), MIN_VALUE);
  const yMid2 = Math.abs(next.value + yStep);
  const xMid1 = Math.abs(index + xStep);
  const xMid2 = Math.abs(index + 1 - xStep);
  return [
    { ...current, index },
    { label: current.label, index: xMid1, value: yMid1 },
    { label: next.label, index: xMid2, value: yMid2 },
  ];
}

interface SegmentLabelProps {
  xScale: ReturnType<typeof scaleLinear<number>>;
  yScale: ReturnType<typeof scaleLinear<number>>;
  index: number;
  height: number;
  data: FunnelChartData;
}

function SegmentLabel(props: SegmentLabelProps) {
  const { xScale, yScale, index, height, data } = props;
  const { label, link, value } = data;

  const theme = useTheme();
  const x = xScale(index);
  const segmentWidth = xScale(1);
  const maxValue = yScale.domain().at(-1) ?? data.value;

  const labelGroup = (
    <>
      <Group left={x} top={height * 0.85}>
        {label && (
          <Text
            fontSize={14}
            x={segmentWidth / 2}
            width={segmentWidth}
            textAnchor="middle"
            fill={theme.token.colorTextSecondary}
          >
            {label}
          </Text>
        )}
        <Text x={segmentWidth / 2} width={segmentWidth} y={20} textAnchor="middle" fill={theme.token.colorText}>
          {value.toLocaleString()}
        </Text>
      </Group>
      {index > 0 && (
        <line
          x1={x}
          x2={x}
          y1={yScale(maxValue) + height / 2}
          y2={-yScale(maxValue) + height / 2}
          stroke={theme.token.colorTextSecondary}
          strokeDasharray={4}
          strokeWidth={1}
        />
      )}
    </>
  );

  if (link) {
    return <Link to={link}>{labelGroup}</Link>;
  }

  return labelGroup;
}

export function FunnelChart(props: ChartProps<FunnelChartData[]>) {
  const { width, height, data, ref, style } = props;

  const theme = useTheme();

  // replace 0 with 1 because log(0) = undefined
  const normalizedData = data.map((d) => ({
    ...d,
    value: Math.max(d.value, MIN_VALUE),
  }));

  const minDomainValue = Math.min(...normalizedData.map((d) => d.value));
  const maxValue = Math.max(...normalizedData.map((d) => d.value));

  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, width],
        domain: [0, data.length],
      }),
    [width, data],
  );

  const yScale = useMemo(
    () =>
      scaleLog<number>({
        range: [0, height / 2 - MARGIN_PX],
        domain: [minDomainValue * MIN_DOMAIN_VALUE_PADDING, maxValue],
      }),
    [height, data],
  );

  const interpolatedData = normalizedData.flatMap((d, i) =>
    interpolatePoints({
      current: d,
      next: normalizedData[i + 1],
      index: i,
    }),
  );

  return (
    <svg width={width} height={height} ref={ref} style={style}>
      <LinearGradient id="gradient" from={theme.color.primary} fromOffset="25%" to="#9b58bb" vertical={false} />
      {AREAS.map((area, i) => {
        return (
          <Area
            key={`area-${i}`}
            data={interpolatedData}
            curve={curveBasis}
            x={(d) => xScale(d.index)}
            y0={(d) => -yScale(d.value) - area.pad + height / 2}
            y1={(d) => yScale(d.value) + area.pad + height / 2}
            fill="url(#gradient)"
            fillOpacity={area.opacity}
            stroke="transparent"
            className="monthly-event-summary-loaded"
          />
        );
      })}
      {data.map((data, index) => (
        <SegmentLabel
          key={`segment-label-${index}`}
          xScale={xScale}
          yScale={yScale}
          height={height}
          data={data}
          index={index}
        />
      ))}
    </svg>
  );
}
