import { gql } from '@apollo/client';
import InfiniteTable from 'components/nuspire/infinite-table';
import EmptyState from 'components/nuspire/nu-empty-state';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { useState, useEffect } from 'react';
import { client } from 'utils/graphql';

const INVENTORY_BY_CLIENT_ID = gql`
  query AssetsByClientId($clientId: String!, $options: AssetsQueryOpts) {
    assetsByClientId(clientId: $clientId, options: $options) {
      items {
        id
        ip

        qualys {
          host {
            id
            ip
            trackingMethod
            os
            dns
            dnsData {
              hostname
              domain
              fqdn
            }
          }

          activeDetectionCount
          severityCounts {
            severity
            count
          }
          latestDetection {
            severity
            qid
            vulnerability {
              qid
              vulnType
              title
            }
          }
        }
      }
      nextKey
    }
  }
`;

interface InventoryOptions {
  pageSize?: number;
  hasQualys?: boolean;
  hasSentinelOne?: boolean;
}

function InventoryInfiniteTable(props: { clientId: string; options: InventoryOptions; columns; scrollHeight: number }) {
  const { clientId, options, columns, scrollHeight } = props;
  const [nextKey, setNextKey] = useState();
  const [inventory, setInventory] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);

    const { data } = await client.query({
      query: INVENTORY_BY_CLIENT_ID,
      variables: { clientId, options: { ...options, nextKey } },
      fetchPolicy: 'network-only',
    });

    const nextInventoryItems = data?.assetsByClientId?.items;

    if (nextInventoryItems) {
      setInventory([...inventory, ...nextInventoryItems]);
    }

    setNextKey(data?.assetsByClientId?.nextKey);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [clientId]);

  const handleScroll = async () => {
    if (nextKey) {
      await getData();
    }
  };

  if (inventory) {
    return (
      <InfiniteTable
        dataSource={inventory}
        rowKey="id"
        onFetch={handleScroll}
        lastId={inventory[inventory.length - 1]?.id}
        debug
        loading={loading}
        scroll={{
          x: true as true,
          y: scrollHeight,
        }}
        columns={columns}
      />
    );
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return <EmptyState>Could not find any Inventory.</EmptyState>;
}

export default InventoryInfiniteTable;
