import { Tooltip } from 'antd';
import { describeUserStatus } from 'utils/users';

type UserStatusProps = {
  status?: string;
};

export default function UserStatus(props: UserStatusProps) {
  const { status } = props;
  const { description, displayText } = describeUserStatus(status);

  return <Tooltip overlay={description}>{displayText}</Tooltip>;
}
