import { gql, useQuery } from '@apollo/client';
import { Row } from 'antd';
import { WidgetDefinition, WidgetRendererProps } from 'components/dashboard/widget-definitions/types';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import baseTheme from 'components/theme';
import { ISentinelOneItemCount, CountsDoughnut, IDoughnutMapping } from '../sentinelone-doughnut';
import { EmptyState, NuCardContent } from 'components/nuspire';
import ApiTokenError from '../api-token-error';

const SENTINELONE_UNPATCHED_DEVICES_QUERY = gql`
  query SentinelOneUnpatchedDeviceCountWidget($connectionId: String!) {
    clientConnection(id: $connectionId) {
      id
      publicConnectionDataJson
      sentinelOneUnpatchedDeviceCount {
        key
        title
        values {
          count
          title
          value
        }
      }
    }
  }
`;

const UNPATCHED_DEVICE_MAP: IDoughnutMapping = {
  patch_required: {
    label: 'Patch Required',
    backgroundColor: baseTheme.color.red,
  },
  up_to_date: {
    label: 'Up to Date',
    backgroundColor: baseTheme.color.green,
  },
  not_applicable: {
    label: 'N/A',
    backgroundColor: baseTheme.color.gray5,
  },
};

function SentinelOneUnpatchedDeviceCountWidgetComponent(props: WidgetRendererProps) {
  const connectionId = props?.configuration?.connectionId ?? null;
  const { data, loading, error } = useQuery(SENTINELONE_UNPATCHED_DEVICES_QUERY, {
    variables: {
      connectionId,
    },
  });
  const unpatchedDeviceCounts = data?.clientConnection?.sentinelOneUnpatchedDeviceCount as ISentinelOneItemCount;

  if (!data && loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  if (unpatchedDeviceCounts) {
    return (
      <Row justify="center">
        <CountsDoughnut counts={unpatchedDeviceCounts.values} map={UNPATCHED_DEVICE_MAP} />
      </Row>
    );
  }

  const errorMsg = error?.message;
  if (errorMsg === 'Your API Key has expired.' || errorMsg === 'API token does not exist') {
    return <ApiTokenError connectionId={connectionId} errorMsg={errorMsg} />;
  }

  return (
    <NuCardContent>
      <EmptyState>Unable to fetch unpatched device count.</EmptyState>
    </NuCardContent>
  );
}

const SentinelOneUnpatchedDeviceCountWidget: WidgetDefinition = {
  name: 'SentinelOne Unpatched Device Count',
  description: 'SentinelOne Unpatched Device Count',
  slug: 'sentinelone-unpatched-device-count',
  connectorSlug: 'sentinelone',
  component: SentinelOneUnpatchedDeviceCountWidgetComponent,
};

export default SentinelOneUnpatchedDeviceCountWidget;
