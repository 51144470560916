import { useEffect } from 'react';
import { WidgetComponentProps } from '..';
import Tile from 'components/nuspire/nu-tile';
import { PillarName } from 'components/nuspire/nu-tile/nu-tile';
import { Link } from 'components/nuspire';

export interface Values {
  count: number;
  title: string;
  value: string;
  backgroundColor?: string;
}

export interface CountPillarData {
  count: number;
}

interface CountPillarConfig {
  pillar: PillarName;
  label: string;
  url: string;
  description: string;
}

export default function CountPillar(props: WidgetComponentProps<CountPillarConfig, CountPillarData>) {
  const {
    data: { count },
    configuration: { pillar, label, url, description },
    clientId,
    setSubAction,
  } = props;

  useEffect(() => {
    if (setSubAction && label && url) {
      setSubAction(
        <Link to={`/${clientId}${url}`} style={{ marginRight: '1rem' }} mode="plain">
          {label}
        </Link>,
      );
    }
  }, []);

  return <Tile count={count} description={description} pillar={pillar} />;
}
