import { Duo as DuoIcon } from 'components/nuspire/nu-icon';
import { IConnector } from 'components/connections/connectors';
import DuoRedirect from './duo-redirect';
import { encodeOAuthState } from '../utils/oauth';

const DUO_CONNECTOR_DEFINITION: IConnector = {
  slug: 'duo',
  name: 'Duo',
  description: 'Two factor authentication',
  icon: <DuoIcon />,
  connectionTypes: ['action'],
  handleAddConnection: ({ clientId }) => {
    if (!clientId) {
      throw new Error('Client Id must be passed to connector');
    }
    const authState = encodeOAuthState({ clientId });

    window.open(
      `${window.location.origin}/redirects/connections/duo/auth?state=${authState}`,
      'Duo Auth',
      'height=1000,width=800',
    );
  },
  redirects: [{ path: 'auth', component: DuoRedirect }],
  widgets: [],
  updatableAuthFields: [
    { key: 'Secret Key', value: 'secretKey' },
    { key: 'Integration Key', value: 'integrationKey' },
    { key: 'Hostname', value: 'host' },
  ],
};

export default DUO_CONNECTOR_DEFINITION;
