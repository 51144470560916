import { gql, useMutation } from '@apollo/client';
import { Checkbox, message } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

export const UPDATE_CLIENT_FEATURE_CHILD_CLIENTS = gql`
  mutation UpdateClientFeatureChildClients($slug: String!, $clientId: String!, $includeChildClients: Boolean) {
    updateClientFeatureFlag(slug: $slug, clientId: $clientId, includeChildClients: $includeChildClients) {
      id
      includeChildClients # This SHOULD update the cache.
      clientId
      slug
    }
  }
`;

export function ManagedClientFeatureCheckbox(props: {
  includeChildClients?: boolean; // current value
  clientId: string;
  slug: string; // slug of feature flag.
}) {
  const { includeChildClients, clientId, slug } = props;

  // mutation
  const [update, { loading }] = useMutation(UPDATE_CLIENT_FEATURE_CHILD_CLIENTS);

  const handleChange = async (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;

    try {
      await update({ variables: { clientId, slug, includeChildClients: checked } });

      message.success(`Updated`);
    } catch (err) {
      console.error(err);
      message.error(`Failed to update child client features.`);
    }
  };

  return <Checkbox checked={includeChildClients} onChange={handleChange} disabled={loading} />;
}
