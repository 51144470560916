import { CURRENT_DASHBOARD_ID_SP } from "components/widgets/widget/widget";

type ClientContextArgs = {
  clientId: string; 
}
export const reportingAndAnalysisPath = ({ clientId }: ClientContextArgs) => `/${clientId}/reporting-and-analysis`
/**
 * Overview
 */
export const reportingAndAnalysisOverviewPath = (args: ClientContextArgs) => `${reportingAndAnalysisPath(args)}/overview`


/**
 * Dashboards
 */
export const dashboardsPath = (args: ClientContextArgs) => `${reportingAndAnalysisPath(args)}/dashboards`;
export const dashboardDetailPath = (args: ClientContextArgs & { id: string }) => `${dashboardsPath(args)}/${args.id}`

export const createWidgetPath = (args: ClientContextArgs & { widgetType: string, dashboardId?: string; }) => {
  const { widgetType, dashboardId } = args; 
  let path = `${reportingAndAnalysisPath(args)}/widgets/create/${widgetType}`; 

  if (dashboardId) {
    // pass the dashboardId in search params so widget editor can add newly created widget to dashboard if user chooses to do so.
    path += `?${CURRENT_DASHBOARD_ID_SP}=${dashboardId}`; 
  }

  return path; 
}

export const widgetDetailPath = (args: ClientContextArgs & { id: string; dashboardId?: string }) => {
  const { id, dashboardId } = args;

  let path = `${reportingAndAnalysisPath(args)}/widgets/${args.id}`;

  if (dashboardId) {
    path += `?${CURRENT_DASHBOARD_ID_SP}=${dashboardId}`
  }
  
  return path;
}

/**
 * Reports
 */
export const reportsPath = (args: ClientContextArgs) => `${reportingAndAnalysisPath(args)}/reports`; 
export const scheduledReportsPath = (args: ClientContextArgs) => `${reportsPath(args)}/scheduled`

/**
 * Data Explorer 
 */
export const dataExplorerPath = (args: ClientContextArgs) =>`${reportingAndAnalysisPath(args)}/data-explorer`

export const queryBuilderPath = (args: ClientContextArgs) => `${dataExplorerPath(args)}/query-builder`; 
export const queryDataTypePath = (args: ClientContextArgs & { dataType: string }) => `${queryBuilderPath(args)}/${args.dataType}`