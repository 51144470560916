import { gql } from '@apollo/client';
import { message } from 'antd';
import { UsergroupDeleteOutlined } from '@ant-design/icons';
import { ButtonGroupProps } from 'antd/es/button';
import { IClient } from 'types';
import { P } from 'components/shared-components';
import MutationButton from 'components/mutation-button';

const FORCE_DELETE_CLIENT_MUTATION = gql`
  mutation ForceDeleteClient($clientId: String!) {
    forceDeleteClient(clientId: $clientId)
  }
`;

type ForceDeleteClientButtonProps = ButtonGroupProps & {
  authorized: boolean;
  client: IClient;
  onSuccess?: Function;
};

type ForceDeleteClientData = {
  forceDeleteClient?: boolean;
};

export default function ForceDeleteClientButton(props: ForceDeleteClientButtonProps) {
  const { authorized, client, onSuccess, ...buttonProps } = props;

  const { id: clientId } = client;
  const variables = {
    clientId,
  };
  const modalTitle = (
    <P>
      Are you sure you want to <strong>completely</strong> delete {client.name}?
    </P>
  );

  const onCompleted = (data: ForceDeleteClientData) => {
    if (data.forceDeleteClient !== undefined) {
      const { forceDeleteClient: result } = data;

      // Force delete was unsuccessful.
      if (!result) {
        onError();
        return;
      }

      // Force delete was successful.
      message.success(`${client.name} has been completely deleted!`);
      if (onSuccess) onSuccess();
    }
  };

  const onError = (error?: any) => {
    // Display the error message if we have one.
    const errorMessage = `There was an error completely deleting ${client.name}. ${
      error ? ` Error: ${error.message}` : `.`
    }`;

    message.error(errorMessage);
  };

  return (
    <MutationButton
      authorized={authorized}
      buttonIcon={<UsergroupDeleteOutlined />}
      danger
      modalOkType="danger"
      modalTitle={modalTitle}
      modalContent="This action cannot be undone."
      mutation={FORCE_DELETE_CLIENT_MUTATION}
      mutationVariables={variables}
      onCompleted={onCompleted}
      onError={onError}
      tooltipOverlay="Force Delete Client"
      unauthorizedMessage="You do not have the required permissions to force delete clients."
      {...buttonProps}
    />
  );
}
