import { gql, useMutation, useQuery } from '@apollo/client';
import { Popover, Menu, Avatar, message, Tooltip, Input } from 'antd';
import { EmptyState, NuButton } from 'components/nuspire';
import { Checkmark, Plus, Search } from 'components/nuspire/nu-icon';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';
import baseTheme from 'components/theme';

const TaskMembersRoot = styled.div`
  display: flex;

  .ant-popover-title {
    font-weight: 600;
  }
`;

const TASK_MEMBER_USER_SEARCH = gql`
  query TaskMemberUserSearch($clientId: String!, $search: String) {
    getUsersByClientId(id: $clientId, search: $search) {
      id
      email
      firstName
      lastName
    }
    externalUsersByClientId(id: $clientId, search: $search) {
      id
      email
      firstName
      lastName
    }
  }
`;

const TaskUsersListRoot = styled.div`
  display: flex;
  flex-direction: vertical;
  // width: 400px;
  max-height: 500px;
  overflow-y: auto;
  margin-left: -8px;
  margin-right: -8px;
`;
const TuliRoot = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
const TuliLabel = styled.div`
  flex: 1;
`;

function TaskUserListItem(props: { user: any; isMember: boolean; onAdd: () => void; onRemove: () => void }) {
  const { user, isMember, onAdd, onRemove } = props;
  const firstName = user?.firstName;
  const lastName = user?.lastName;

  const handleClick = () => {
    if (isMember) {
      onRemove();
    } else {
      onAdd();
    }
  };

  // some external users don't have first and last names so fall back on email
  const hasFirstAndLastName = firstName && lastName;
  let label = ``;
  if (hasFirstAndLastName) {
    label = `${user.firstName} ${user.lastName} `;
  } else {
    label = user?.email;
  }

  return (
    <TuliRoot onClick={handleClick}>
      {hasFirstAndLastName ? (
        <Avatar style={{ marginRight: '8px' }} size={30}>{`${user.firstName[0]}${user.lastName[0]}`}</Avatar>
      ) : null}
      <TuliLabel>{label}</TuliLabel>
      {isMember && <Checkmark style={{ color: baseTheme.color.success }} />}
    </TuliRoot>
  );
}

function TaskUsersList(props: {
  users: any[];
  members: any[];
  onAddMember: (user: any) => void;
  onRemoveMember: (user: any) => void;
}) {
  const { users, members = [], onAddMember, onRemoveMember } = props;

  // place members at top of list.
  const usersSorted = useMemo(() => {
    const memberUsers: any = [];

    const nonMemberUsers = users.filter((u) => {
      const member = members?.find((m) => m.id === u.id);

      if (member) {
        memberUsers.push(u);
        return false;
      }

      return true;
    });

    return [...memberUsers, ...nonMemberUsers];
  }, [users]);

  return (
    <TaskUsersListRoot>
      <Menu
        items={usersSorted.map((u) => ({
          key: u.id,
          label: (
            <TaskUserListItem
              user={u}
              isMember={Boolean(members?.find((m) => m.id === u.id))}
              onAdd={() => onAddMember(u)}
              onRemove={() => onRemoveMember(u)}
            />
          ),
        }))}
        style={{
          border: 'none',
          width: '100%',
        }}
      />
    </TaskUsersListRoot>
  );
}

const UPDATE_TASK_MEMBERS = gql`
  mutation UpdateTaskMembers($id: String!, $members: [JSONObject!]) {
    updateClientTask(id: $id, members: $members) {
      id
      members
    }
  }
`;

function TaskMembersListResults(props: {
  users?: any[];
  members: any[];
  loading?: boolean;
  onAddMember: (user: any) => void;
  onRemoveMember: (user: any) => void;
}) {
  const { users, members, loading, onAddMember, onRemoveMember } = props;

  if (users?.length) {
    return <TaskUsersList members={members} users={users} onAddMember={onAddMember} onRemoveMember={onRemoveMember} />;
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin tip="Loading Users">
          <div className="content" />
        </Spin>
      </SpinContainer>
    );
  }

  return <EmptyState>Could not find team members. Please contact support.</EmptyState>;
}

const TaskMembersMenuRoot = styled.div`
  width: 400px;
`;

function TaskMembersMenu(props: { clientTask: any }) {
  const {
    clientTask: { id, clientId },
    clientTask,
  } = props;

  // ========== STATE ==========
  // state for user entered search
  const [queryString, setQueryString] = useState<string | undefined>(undefined);
  // value actually sent over query
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const handleQueryString = (val?: string) => {
    if (val?.length) {
      setQueryString(val);
    } else {
      setQueryString(undefined);
    }
  };

  // debounce search
  const debouncedSearch = useCallback(
    debounce((newSearch: string | undefined) => {
      setSearchValue(newSearch);
    }, 600),
    [],
  );

  useEffect(() => {
    debouncedSearch(queryString);
  }, [queryString]);

  // ============ GraphQL ==========
  const { data, loading } = useQuery(TASK_MEMBER_USER_SEARCH, { variables: { clientId, search: searchValue } });
  let users = data?.getUsersByClientId;
  const externalUsers = data?.externalUsersByClientId;

  if (users && externalUsers) {
    users = [...users, ...externalUsers];
  }

  const [updateMembers] = useMutation(UPDATE_TASK_MEMBERS);

  const members = clientTask.members ?? [];

  const handleAddMember = async (user: any) => {
    const newMembers = [...members];
    newMembers.push(user);

    try {
      await updateMembers({
        variables: { id, members: newMembers },
        optimisticResponse: {
          updateClientTask: {
            id,
            members: newMembers,
            __typename: 'ClientTask',
          },
        },
      });
    } catch (err) {
      console.error(err);
      // there was a
      message.error('There was a problem adding Team member.');
    }
  };

  const handleRemoveMember = async (user: any) => {
    const newMembers = members.filter((m) => m.id !== user.id);

    try {
      await updateMembers({
        variables: { id, members: newMembers },
        optimisticResponse: {
          updateClientTask: {
            id,
            members: newMembers,
            __typename: 'ClientTask',
          },
        },
      });
    } catch (err) {
      console.error(err);
      // there was a
      message.error('There was a problem adding Team member.');
    }
  };

  return (
    <TaskMembersMenuRoot>
      <Input
        prefix={<Search />}
        style={{ marginBottom: '8px' }}
        value={queryString}
        onChange={(e) => {
          const val = e.target.value;

          handleQueryString(val);
        }}
      />
      <TaskMembersListResults
        members={members}
        users={users}
        onAddMember={handleAddMember}
        onRemoveMember={handleRemoveMember}
        loading={loading}
      />
    </TaskMembersMenuRoot>
  );
}

function AddTaskMembersButton(props: { clientTask: any }) {
  const { clientTask } = props;

  const menu = <TaskMembersMenu clientTask={clientTask} />;

  return (
    <Popover
      placement="bottomLeft"
      content={menu}
      trigger={['click']}
      title="Members"
      style={{
        maxHeight: '300px',
        overflowY: 'auto',
      }}
    >
      <div>
        <NuButton shape="circle" type="text">
          <Plus />
        </NuButton>
      </div>
    </Popover>
  );
}

export function TaskMembersAvatars(props: { members?: any[] }) {
  const { members } = props;

  if (!members?.length) {
    return <Avatar>?</Avatar>;
  }

  return (
    <Avatar.Group maxCount={1}>
      {members.map((member) => (
        <Tooltip key={member.id} title={`${member.firstName} ${member.lastName}`}>
          <Avatar key={member.id}>{`${member.firstName[0]}${member.lastName[0]}`}</Avatar>
        </Tooltip>
      ))}
    </Avatar.Group>
  );
}

export function TaskMembers(props: { clientTask: any }) {
  const { clientTask } = props;

  return (
    <TaskMembersRoot>
      {clientTask.members?.length && <TaskMembersAvatars members={clientTask.members} />}
      <AddTaskMembersButton clientTask={clientTask} />
    </TaskMembersRoot>
  );
}
