import React from 'react';
import { Tooltip } from 'antd';
import { ButtonType } from 'antd/es/button';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { TooltipPlacement } from 'antd/es/tooltip';
import { Link, NuButton } from 'components/nuspire';
import * as NuIcon from 'components/nuspire/nu-icon';

type ClientContextButtonProps = {
  buttonSize?: SizeType;
  buttonType?: ButtonType;
  clientId: string;
  style?: React.CSSProperties;
  tooltipPlacement?: TooltipPlacement;
};

export default function ClientContextButton(props: ClientContextButtonProps) {
  const { buttonSize, buttonType, clientId, style, tooltipPlacement } = props;

  return (
    <Link to={`/${clientId}`} style={style} target="_blank">
      <Tooltip overlay="View Client's context" placement={tooltipPlacement}>
        <NuButton type={buttonType} size={buttonSize}>
          <NuIcon.Search />
        </NuButton>
      </Tooltip>
    </Link>
  );
}
