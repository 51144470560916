import { useClientContext } from 'components/client-context-provider';
import SubNav, { SubNavItemType } from 'components/sub-nav';
import { Access } from 'types';
import {
  assetsPath,
  automationsPath,
  blockListPath,
  clientUserGroupsPath,
  clientUsersPath,
  escalationsPath,
  integrationsPath,
  managedClientsPath,
  necesPath,
  selfServiceSSOPath,
  settingsOverviewPath,
} from './paths';

export default function SettingsSubNav(props: { clientId: string }) {
  const { client, authorize } = useClientContext();
  const { authorized: canReadSSO } = authorize({ requiredPermissions: { sso: Access.read } });

  const items: SubNavItemType[] = [
    {
      key: 'overview',
      title: 'Overview',
      to: settingsOverviewPath(props),
    },
    {
      key: 'escalations',
      title: 'Escalations',
      to: escalationsPath(props),
      'data-intercom-target': 'nav-tour-escalations',
    },
    {
      key: 'integrations',
      title: 'Integrations',
      to: integrationsPath(props),
      'data-intercom-target': 'nav-tour-integrations',
    },
    {
      key: 'automations',
      title: 'Automations',
      to: automationsPath(props),
      'data-intercom-target': 'nav-tour-automations',
    },
    {
      key: 'assets',
      title: 'Assets',
      to: assetsPath(props),
      'data-intercom-target': 'nav-tour-assets',
    },
    {
      key: 'neces',
      title: 'NECES',
      to: necesPath(props),
      'data-intercom-target': 'nav-tour-neces',
    },
    {
      key: 'block-list',
      title: 'Block List',
      to: blockListPath(props),
      'data-intercom-target': 'nav-tour-block-list',
    },
  ];

  if (canReadSSO) {
    items.push({
      key: 'sso',
      title: 'SSO',
      to: selfServiceSSOPath(props),
      'data-intercom-target': 'nav-tour-sso',
    });
  }

  items.push({
    key: 'organization',
    title: 'Organization',
    type: 'group',
    items: [
      {
        key: 'managed-clients',
        title: 'Managed Clients',
        to: managedClientsPath(props),
        'data-intercom-target': 'nav-tour-managed-clients',
      },
      {
        key: 'users',
        title: 'Users',
        to: clientUsersPath(props),
        'data-intercom-target': 'nav-tour-users',
      },
      {
        key: 'groups',
        title: 'Groups',
        to: clientUserGroupsPath(props),
        'data-intercom-target': 'nav-tour-groups',
      },
    ],
  });

  return <SubNav items={items} />;
}
