import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { DomainsForMainMenuQuery } from '../../../../types/graph-codegen/graph-types';
import { isDefined } from '../../../../utils/is-defined';
import { useAuthContext } from '../../../auth-context';
import { useClientContext } from '../../../client-context-provider';
import { LinkedMenuItem, MenuItem } from './get-main-menu-items';

const GET_DOMAINS = gql`
  query DomainsForMainMenu {
    domains {
      id
      name
      controls {
        id
        name
      }
    }
  }
`;

export function useNspDomainsSubmenuItems() {
  const clientContext = useClientContext();
  const { user } = useAuthContext();
  const [items, setItems] = useState<MenuItem[]>([]);
  const { data, loading } = useQuery<DomainsForMainMenuQuery>(GET_DOMAINS);

  const domains = data?.domains?.filter(isDefined);
  const clientId = clientContext.clientId ?? user?.clientId;

  useEffect(() => {
    if (!domains || !clientId) {
      setItems([]);
      return;
    }

    const submenuItems = domains
      .map<LinkedMenuItem>((domain) => ({
        key: `nsp|domains|${domain.id}`,
        label: domain.name,
        to: `/${clientId}/nsp/domain/${domain.id}`,
        children: domain.controls?.filter(isDefined)
          .map((control) => ({
            key: `nsp|domains|${domain.id}|${control.id}`,
            label: control.name,
            to: `/${clientId}/nsp/domain/${domain.id}/control/${control.id}`,
          })),
      }));

    setItems(submenuItems);
  }, [data]);

  return {
    submenuItems: items,
    loading,
  };
}

