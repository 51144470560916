import { Select } from 'antd';
import { ActionInputProps } from '../action-form-field';

const firewallTypes = ['fortinet', 'sonicwall', 'watchguard', 'barracuda'];

const NusiemSourceTypesSelect = (props: ActionInputProps) => {
  const { value, onChange } = props;

  return (
    <Select value={value} onChange={(values) => onChange(values)} mode="multiple">
      {firewallTypes.map((f) => (
        <Select.Option key={f} value={f}>
          {f}
        </Select.Option>
      ))}
    </Select>
  );
};

export default NusiemSourceTypesSelect;
