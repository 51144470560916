import { Table, TableProps } from 'antd';
import startCase from 'lodash.startcase';
import { Check, Slash } from 'lucide-react';
import { MutableRefObject, forwardRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { Access, IPermissions, humanReadablePermissionTypes, type HumanReadableAccess } from 'types';

const CellContent = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
`;

const StyledTable: typeof Table = styled(Table)`
  td.ant-table-column-sort {
    background: none;
  }
`;

export type FormattedPermission = {
  label: string;
  access: HumanReadableAccess[];
};

export interface RolePermissionsTableProps extends TableProps<FormattedPermission> {
  permissions: FormattedPermission[] | IPermissions;
  /**
   * @default 100
   */
  colWidth?: number;
}

function isFormattedPermissions(o: any): o is FormattedPermission[] {
  return (o[0] as FormattedPermission)?.access !== undefined;
}

function formatPermissions(p: IPermissions): FormattedPermission[] {
  return Object.entries(p).map(([key, bitMask]) => {
    const access: HumanReadableAccess[] = [];

    if ((bitMask & Access.read) === Access.read) {
      access.push('read');
    }
    if ((bitMask & Access.write) === Access.write) {
      access.push('write');
    }
    if ((bitMask & Access.execute) === Access.execute) {
      access.push('execute');
    }

    return {
      label: humanReadablePermissionTypes[key] ?? startCase(key),
      access,
    };
  });
}

function renderHasAccess(accessLevel: HumanReadableAccess) {
  const theme = useTheme();

  const renderPermissionIcon = (value: HumanReadableAccess) => {
    const hasAccess = value.includes('all') || value.includes(accessLevel);

    if (hasAccess) {
      return (
        <CellContent>
          <Check color={theme.color.primary} />
        </CellContent>
      );
    }

    return (
      <CellContent>
        <Slash color={theme.color.breeze} size={18} />
      </CellContent>
    );
  };

  return renderPermissionIcon;
}

export const RolePermissionsTable = forwardRef<HTMLDivElement, RolePermissionsTableProps>((props, ref) => {
  const { permissions } = props;

  const formattedPermissions = isFormattedPermissions(permissions) ? permissions : formatPermissions(permissions);

  const colWidth = props.colWidth ?? 100;

  return (
    <StyledTable
      ref={(tableRef) => {
        if (ref && tableRef?.nativeElement) {
          if (typeof ref === 'function') ref(tableRef.nativeElement);
          (ref as MutableRefObject<HTMLDivElement>).current = tableRef.nativeElement;
        }
      }}
      {...props}
      rowKey="label"
      pagination={props.pagination ?? false}
      dataSource={formattedPermissions}
      columns={[
        {
          key: 'permission',
          dataIndex: 'label',
          title: 'Permission',
          sorter: (a, b) => a.label.localeCompare(b.label),
          defaultSortOrder: 'ascend',
        },
        {
          key: 'read',
          dataIndex: 'access',
          title: 'Read',
          render: renderHasAccess('read'),
          width: colWidth,
          align: 'center',
        },
        {
          key: 'write',
          dataIndex: 'access',
          title: 'Write',
          render: renderHasAccess('write'),
          width: colWidth,
          align: 'center',
        },
        {
          key: 'execute',
          dataIndex: 'access',
          title: 'Execute',
          render: renderHasAccess('execute'),
          width: colWidth,
          align: 'center',
        },
      ]}
    />
  );
});

RolePermissionsTable.displayName = 'RolePermissionsTable';
