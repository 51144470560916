import { gql, useQuery } from '@apollo/client';
import { Badge, Button, Card, Col, Collapse, Descriptions, Progress, ProgressProps, Row, Tooltip } from 'antd';
import { GetActiveReportsQuery, PollReportsQuery } from 'types/graph-codegen/graph-types';
import { capitalize } from 'utils/strings';
import { useClientContext } from '../client-context-provider';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { CheckCircleFilled, CloseCircleFilled, DownloadOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'components/nuspire';
import { useAdminPage } from '../authorization';
import { useReportDownload } from './use-report-download';

export const GET_ACTIVE_REPORTS = gql`
  query GetActiveReports($clientId: String!) {
    activeReports(clientId: $clientId) {
      id
      name
      clientId

      startedAt

      timezone
      humanReadableCron
      scheduleId
    }
  }
`;

const POLL_REPORTS = gql`
  query PollReports($clientId: String!, $ids: [String!]!) {
    pollReports(clientId: $clientId, ids: $ids) {
      id
      status

      startedAt
      completedAt
    }
  }
`;

const ReportCard = styled(Card)`
  height: 100%;
  p {
    margin: 0;
  }
`;

const ReportProgress = styled(Progress)`
  margin-left: 1rem;
  .ant-progress-steps-outer {
    justify-content: end;
  }

  .ant-progress-text {
    flex: 0;
  }
`;

type ReportMetadata = Omit<GetActiveReportsQuery['activeReports'][number], '__typename'> &
  Omit<PollReportsQuery['pollReports'][number], '__typename'>;

const safeCapitalize = (s: string | undefined) => {
  if (!s || s.length < 2) {
    return s;
  }

  return capitalize(s);
};

interface ActiveReportCardProps extends ReturnType<typeof useReportDownload> {
  reportMetadata: ReportMetadata;
  canViewAdmin: boolean;
}

export function ActiveReportCard(props: ActiveReportCardProps) {
  const { canViewAdmin, downloadsLoading, handleDownload } = props;
  const {
    name,
    status = 'pending',
    startedAt,
    completedAt,
    timezone,
    humanReadableCron,
    scheduleId,
    clientId,
    id,
  } = props.reportMetadata;

  const startedAtString = dayjs(startedAt).format('LTS');

  const taskProgressStatus = ['pending', 'running', 'success'];
  const taskProgress = ((taskProgressStatus.indexOf(status) + 1) / 3) * 100;

  let progressStatus: ProgressProps['status'] = 'normal';
  if (status === 'failed') {
    progressStatus = 'exception';
  } else if (status === 'success') {
    progressStatus = 'success';
  }

  const progressFormat = useCallback(() => {
    if (progressStatus === 'exception') {
      return <CloseCircleFilled />;
    }
    if (progressStatus === 'success') {
      return (
        <>
          <CheckCircleFilled />
          <Button
            loading={downloadsLoading.find((currId) => currId === id) !== undefined}
            onClick={() => {
              handleDownload({
                id,
                clientId,
                completedAt,
              });
            }}
            type="text"
            icon={<DownloadOutlined />}
          />
        </>
      );
    }
    return <Badge status="processing" />;
  }, [progressStatus]);

  const scheduleString = `${humanReadableCron} (${timezone})`;
  const scheduledDescriptionItem = (
    <Descriptions.Item label="Scheduled">
      {canViewAdmin ? (
        <Link to={`/admin/schedules/${clientId}/${scheduleId}`} mode="plain">
          {scheduleString}
        </Link>
      ) : (
        <p>{scheduleString}</p>
      )}
    </Descriptions.Item>
  );

  return (
    <ReportCard
      title={name}
      extra={
        <Tooltip title={safeCapitalize(status)}>
          <ReportProgress
            percent={taskProgress}
            steps={3}
            size="small"
            status={progressStatus}
            format={progressFormat}
          />
        </Tooltip>
      }
    >
      <Descriptions size="small" column={1}>
        {startedAt && startedAt !== 'false' && <Descriptions.Item label="Started">{startedAtString}</Descriptions.Item>}
        {timezone && humanReadableCron && scheduleId && scheduledDescriptionItem}
        {canViewAdmin && (
          <Descriptions.Item>
            <Link to={`/admin/task-handler/logs?task_id=${id}`} mode="plain">
              View Task Logs
            </Link>
          </Descriptions.Item>
        )}
      </Descriptions>
    </ReportCard>
  );
}

interface ActiveReportsViewProps extends ReturnType<typeof useReportDownload> {
  reports: ReportMetadata[];
  canViewAdmin: boolean;
}

export function ActiveReportsView(props: ActiveReportsViewProps) {
  const { reports, canViewAdmin, downloadsLoading, handleDownload } = props;
  return (
    <Row gutter={[16, 16]}>
      {reports.map((r) => (
        <Col span={8} key={r.id}>
          <ActiveReportCard
            reportMetadata={r}
            canViewAdmin={canViewAdmin}
            downloadsLoading={downloadsLoading}
            handleDownload={handleDownload}
          />
        </Col>
      ))}
    </Row>
  );
}

export function ActiveReports() {
  const { clientId } = useClientContext();
  const { canViewAdmin } = useAdminPage();
  const [ids, setTaskIds] = useState<string[]>([]);
  const { data } = useQuery<GetActiveReportsQuery>(GET_ACTIVE_REPORTS, {
    variables: {
      clientId,
    },
  });

  const activeReports = data?.activeReports;

  const { data: pollData } = useQuery<PollReportsQuery>(POLL_REPORTS, {
    variables: {
      clientId,
      ids,
    },
    // poll every 5 seconds
    pollInterval: 5000,
    skip: !activeReports || activeReports.length === 0,
  });

  useEffect(() => {
    if (!activeReports || activeReports.length === 0) return;
    setTaskIds(activeReports.map((r) => r.id));
  }, [data]);

  const { handleDownload, downloadsLoading } = useReportDownload();

  const polledStatuses = pollData?.pollReports;
  if (!activeReports || !polledStatuses) return null;

  const reports = activeReports.map((r) => {
    const polledStatus = polledStatuses.find((ar) => ar.id === r.id)!;

    return {
      ...r,
      ...polledStatus,
    };
  });

  if (reports.length === 0) return null;

  return (
    <ActiveReportsView
      reports={reports}
      canViewAdmin={canViewAdmin}
      downloadsLoading={downloadsLoading}
      handleDownload={handleDownload}
    />
  );
}
