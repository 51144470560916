/**
 * Case Management Path building functions.
 */
export const caseManagementPath = ({ clientId }: { clientId: string }) => `/${clientId}/case-management`;

export const caseManagementOverviewPath = ({ clientId }) => `${caseManagementPath({ clientId })}/overview`;

export type CasePriority = 'planning' | 'low' | 'moderate' | 'high' | 'critical';

const casePriorityMap = {
  planning: '5 - Planning',
  low: '4 - Low',
  moderate: '3 - Moderate',
  high: '2 - High',
  critical: '1 - Critical',
};

export const caseListPath = (args: { clientId: string; priorities?: string[] }) => {
  // Build up search params for filtering.
  const sp = new URLSearchParams();

  if (args?.priorities) {
    sp.set('priorities', JSON.stringify(args.priorities.map((key) => casePriorityMap[key])));
  }

  const path = `${caseManagementPath(args)}/cases?${sp.toString()}`;

  return path;
};

export const taskListPath = (args: { clientId: string }) => {
  return `${caseManagementPath(args)}/tasks`;
};
