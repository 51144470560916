import { CrowdStrike as CrowdStrikeIcon } from 'components/nuspire/nu-icon';
import { IConnector } from 'components/connections/connectors';
import CrowdStrikeRedirect from './crowd-strike-redirect';
import CrowdScoreWidget from './widgets/crowdscore';
import CrowdIncidentsWidget from './widgets/crowdincidents';
import { encodeOAuthState } from '../utils/oauth';

const CROWDSTRIKE_CONNECTOR_DEFINITION: IConnector = {
  slug: 'crowdstrike',
  name: 'crowdstrike',
  description: 'Cloud-Native Endpoint Protection',
  icon: <CrowdStrikeIcon />,
  connectionTypes: ['action'],
  handleAddConnection: ({ clientId }) => {
    if (!clientId) {
      throw new Error('Client Id must be passed to connector');
    }
    const authState = encodeOAuthState({ clientId });

    window.open(
      `${window.location.origin}/redirects/connections/crowdstrike/auth?state=${authState}`,
      'CrowdStrike Auth',
      'height=1000,width=800',
    );
  },
  redirects: [{ path: 'auth', component: CrowdStrikeRedirect }],
  widgets: [CrowdScoreWidget, CrowdIncidentsWidget],
  updatableAuthFields: [
    { key: 'Client ID', value: 'clientId' },
    { key: 'Client Secret', value: 'clientSecret' },
    { key: 'Instance URL', value: 'baseURL' },
  ],
};

export default CROWDSTRIKE_CONNECTOR_DEFINITION;
