import styled from 'styled-components'; 
import { gql, useQuery } from "@apollo/client";
import { EmptyState, NuButton, NuCard, NuCardContent, NuCardTitle } from "components/nuspire";
import { dashboardDetailPath } from "components/reporting-and-analysis/paths";
import { Link } from "react-router-dom";
import { navItemMixin } from './favorite-dashboards-card'; 
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { DashboardsModal } from 'components/dashboard/dashboards-modal'; 
import { useState } from 'react';

export const RECENTLY_VIEWED_DASHBOARDS_CARD = gql`
  query RecentlyViewedDashboardsCard($clientId: String!) {
    getUserDashboardsData(clientId: $clientId) {
      recentDashboards {
        id
        name
      }
    }
  }
`; 

const RecentNavItem = styled(Link)`
  display: flex; 
  align-items: center; 
  ${navItemMixin}
`

function RecentDashboardNavItem(props: {
  id: string; 
  name: string; 
  clientId: string; 
}) {
  const { id, name, clientId } = props; 

  return (
    <RecentNavItem to={dashboardDetailPath({ clientId, id })}>
      {name}
    </RecentNavItem>
  )
}

function RecentlyViewedDashboardsList(props: {
  dashboards?: any[];
  loading: boolean;
  onOpenModal: () => void; 
  clientId: string; 
}) {
  const { clientId, dashboards, loading, onOpenModal } = props;

  if (dashboards?.length) {
    return (
      <>
        {dashboards.map(i => <RecentDashboardNavItem key={i.id} id={i.id} name={i.name} clientId={clientId} />)}
      </>
    )
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    )
  }

  return (
    <EmptyState actions={(
      <NuButton onClick={onOpenModal}>
        Search Dashboards
      </NuButton>
    )}>
      Looks like you don't have any recently viewed dashboards. 
    </EmptyState>
  )
}

export default function RecentlyViewedDashboardsCard(props: {
  clientId: string; 
}) {
  const { clientId } = props;

  // graphql
  const { data, loading } = useQuery(RECENTLY_VIEWED_DASHBOARDS_CARD, { 
    variables: { clientId }
  }); 
  const recentDashboards = data?.getUserDashboardsData?.recentDashboards ?? []; 

  // state
  const [modalVisible, setModalVisible] = useState<boolean>(false); 
  const handleOpenModal = () => setModalVisible(true);
  const handleCloseModal = () => setModalVisible(false); 

  return (
    <>
      <NuCard fullHeight>
        <NuCardTitle
          title="Recently Viewed Dashboards"
          actions={(
            <NuButton
              onClick={handleOpenModal}
              type="link"
            >
              View All 
            </NuButton>
          )}
        />
        <NuCardContent>
            <RecentlyViewedDashboardsList 
              dashboards={recentDashboards}
              loading={loading}
              onOpenModal={handleOpenModal}
              clientId={clientId}
            />
        </NuCardContent>
      </NuCard>
      <DashboardsModal visible={modalVisible} onClose={handleCloseModal} />
    </>
  );
}