import PageSection from '../../page-section'
import { CaseManagementIcon } from 'components/nuspire/nu-icon'; 
import { Link } from 'react-router-dom';
import { caseManagementOverviewPath } from 'components/case-management/paths';
import { NuButton } from 'components/nuspire';
import { Row, Col } from 'antd'; 
import { HighPriorityCasesCard } from './high-priority-cases-card';
import { RecentTasksCard } from './recent-tasks-card';
import { useTheme } from 'styled-components';

export default function CaseManagementSection(props: {
  clientId: string;
}) {
  const { clientId } = props;
  const theme = useTheme();

  return (
    <PageSection
      title="Case Management"
      icon={<CaseManagementIcon style={{ color: theme.token.colorPrimary }} />}
      actions={(
        <Link to={caseManagementOverviewPath({ clientId })}>
          <NuButton type="link">
            View in Case Management
          </NuButton>
        </Link>
      )}
    >
      <Row gutter={20} style={{ height: '400px' }}>
        <Col lg={12} style={{ height: '100%'}}>
          <HighPriorityCasesCard clientId={clientId} />
        </Col>
        <Col lg={12} style={{ height: '100%'}}>
          <RecentTasksCard clientId={clientId} />
        </Col>
      </Row>
    </PageSection>
  )
}
