import { ApolloError } from '@apollo/client';
import { message, Timeline, TimelineProps, Tooltip, Typography } from 'antd';
import { NuButton } from 'components/nuspire';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { htmlDecode } from 'utils/strings';
import { useAuthContext } from '../auth-context';
import Spin, { SpinContainer } from '../nuspire/spin';
import { P } from '../shared-components';
import { Description } from './case-detail';
import { ServiceNowCaseAdditionalDetails } from '../../types/graph-codegen/graph-types';

interface ICaseDetailsTimelineProps {
  caseNumber: string;
  createdOn?: string;
  details?: ServiceNowCaseAdditionalDetails[];
  error?: ApolloError;
  loading: Boolean;
}

interface IDetailCommentLineProps {
  date?: string;
  text: string;
  user?: string;
}

const DetailLineItem = styled(P)`
  // margin-bottom: 0.25rem;
`;

function DetailCommentLine({ date, text, user: givenUser }: IDetailCommentLineProps) {
  let user = givenUser;
  let commentBody = text;

  // Need to keep this in for now to account for legacy comments before
  // the impersonation API.
  if (commentBody.startsWith('|User: ')) {
    const splitText = text.split(/(\|User: .*\|)/).filter((t) => t.trim() !== '');

    if (splitText.length > 0) {
      if (splitText[0].startsWith('|User: ')) {
        user = splitText[0].replace('|User: ', '').replace('|', '');

        if (splitText.length > 1) {
          [, commentBody] = splitText;
        }
      } else {
        [commentBody] = splitText;
      }
    }
  }

  return (
    <>
      {!!user?.trim() && (
        <DetailLineItem>
          <strong>{user}</strong>
        </DetailLineItem>
      )}
      <DetailLineItem style={{ marginBottom: 0 }}>
        <Tooltip title={dayjs.utc(date).local().format('MMMM Do YYYY, h:mm:ss a')}>
          <Typography.Text type="secondary">{dayjs.utc(date).local().fromNow()}</Typography.Text>
        </Tooltip>
      </DetailLineItem>
      <Description style={{ marginTop: 0 }}>{htmlDecode(commentBody)}</Description>
    </>
  );
}

const EmailBody = (props: { content: string; title: string }) => {
  const { content, title } = props;
  const theme = useTheme();

  const writeHTML = (frame: HTMLIFrameElement) => {
    if (!frame) {
      return;
    }

    const doc = frame.contentDocument;

    const parser = new DOMParser();
    const parsedContent = parser.parseFromString(content, `text/html`);

    // Remove images. Some may be broken paths.
    const images = parsedContent.getElementsByTagName(`img`);
    Array.from(images).forEach((img) => {
      img.remove();
    });

    // Replace links with just the text content. Some may be broken paths.
    const links = parsedContent.getElementsByTagName(`a`);
    Array.from(links).forEach((link) => {
      const newElement = document.createElement(`span`);
      newElement.innerHTML = link.innerHTML;

      link.parentNode?.replaceChild(newElement, link);
    });

    parsedContent.body.style.color = theme.token.colorText;

    doc?.open();
    doc?.write(parsedContent.documentElement.outerHTML);
    doc?.close();

    frame.style.width = '100%';
    frame.style.height = `${frame.contentWindow?.document.body.scrollHeight}px`;
  };

  return <iframe frameBorder="0" scrolling="no" title={title} ref={writeHTML} />;
};

interface IDetailEmailLineProps {
  date: string;
  emailBody: string;
  meta?: any;
}

function DetailEmailLine(props: IDetailEmailLineProps) {
  const { date, emailBody, meta } = props;
  const [isShowingEmailBody, setIsShowingEmailBody] = useState<boolean>(false);

  const from: string | undefined = meta?.headers
    ?.split(`\r\n`)
    .find((header: string) => header.toLowerCase().startsWith(`from: `));

  return (
    <>
      <DetailLineItem>
        <strong>Email Sent</strong>
      </DetailLineItem>
      <DetailLineItem>
        <Tooltip title={dayjs.utc(date).local().format('MMMM Do YYYY, h:mm:ss a')}>
          <Typography.Text type="secondary">{dayjs.utc(date).local().fromNow()}</Typography.Text>
        </Tooltip>
      </DetailLineItem>
      {meta?.subject && meta!.subject.trim() !== `` && (
        <DetailLineItem>
          <strong>Subject:</strong> {meta!.subject}
        </DetailLineItem>
      )}
      {from && from.trim() !== `` && (
        <DetailLineItem>
          <strong>From:</strong> {from.replace(`From: `, ``)}
        </DetailLineItem>
      )}
      {meta?.direct && meta!.direct.trim() !== `` && (
        <DetailLineItem>
          <strong>To:</strong> {meta!.direct.replace(/,/g, `, `)}
        </DetailLineItem>
      )}
      {meta?.copied && meta!.copied.trim() !== `` && (
        <DetailLineItem>
          <strong>CC:</strong> {meta!.copied.replace(/,/g, `, `)}
        </DetailLineItem>
      )}
      <NuButton type="link" onClick={() => setIsShowingEmailBody(!isShowingEmailBody)}>
        {isShowingEmailBody ? `Hide` : `Show`} email details
      </NuButton>
      {isShowingEmailBody && <EmailBody content={emailBody} title={meta?.subject} />}
    </>
  );
}

export default function CaseDetailsTimeline({
  caseNumber,
  createdOn,
  details,
  error,
  loading,
}: ICaseDetailsTimelineProps) {
  const { user } = useAuthContext();

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error('An error occurred while attempting to load case details.');
    }
  }, [error]);

  const isNuspireEmail = (email: string) => email.endsWith('@nuspire.com') || email === 'nuspiredev@service-now.com';

  const detailItems: TimelineProps['items'] = (details ?? []).map((d) => {
    const itemPosition = isNuspireEmail(d.user_email ?? '') ? 'right' : 'left';

    const itemColor = d.user_sys_id === user?.serviceNowUserId ? '#18B0B7' : '#90B4E0';

    return {
      className: itemPosition,
      color: itemColor,
      key: d.sys_id,
      children:
        d.type === 'email' ? (
          <DetailEmailLine date={d.sys_created_on} emailBody={d.detail || ``} meta={d.meta} />
        ) : (
          <DetailCommentLine date={d.sys_created_on} text={d.detail || ``} user={d.user_name} />
        ),
      position: itemPosition,
    };
  });

  const timelineItems: TimelineProps['items'] = [
    ...detailItems,
    {
      key: 'created-item',
      children: <DetailCommentLine date={dayjs.utc(createdOn).utc().toString()} text={`${caseNumber} created`} />,
      position: 'right',
    },
  ];

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return <Timeline mode="alternate" items={timelineItems} />;
}
