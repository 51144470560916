export function getCanCreateCase(tags?: string[]): boolean {
  // recommendations in OAS, MDR, and OAS mirrored shouldn't see opening a case
  if (!tags) {
    return true;
  }

  if (
    tags.includes(`mgs-oas-service-health:oas-missing-data-visibility`) ||
    tags.includes(`service-e4275b8087985150386d2f49cebb3537`) ||
    tags.includes(`service-e11553cc87585150386d2f49cebb3500`)
  ) {
    return false;
  }

  return true;
}
