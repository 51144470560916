import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import classnames from 'classnames';
import baseTheme from 'components/theme';

export interface OptionalPaperProps {
  styles?: {
    root?: CSSProperties;
    content?: CSSProperties;
  };
  classes?: {
    root?: string;
    content?: string;
  };

  hoverable?: boolean; // default: false
  fullHeight?: boolean; // default: false
  /**
   * Callback function when clicked
   */
  onClick?: () => void;

  level?: 0 | 1 | 2;

  sideHighlightColor?: string;
  dataIntercomTarget?: string;

  variation?: 'gray' | 'default';
}
export type NuPaperProps = {
  children: React.ReactNode;
} & OptionalPaperProps

const Root = styled.div<{
  sideHighlightColor?: string;
}>`
  transition: all 0.2s ease;
  position: relative;

  &.full-height {
    height: 100%;
  }

  &.with-side-highlight {
    z-index: 10;

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 6px;
      height: 100%;
      background-color: ${(p) => p.sideHighlightColor};
    }
  }
`;

const PaperContent = styled.div`
  height: 100%;
  overflow: hidden;

  border-radius: 8px;

  .with-side-highlight & {
    padding-left 5px;
    border-radius: 0 8px 8px 0;
  }

  .hoverable & {
    cursor: pointer;
  }

  .level-1 > &,
  .level-2 > &  {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.125);
  }

  .level-2 > & {
    border: 1px solid ${(p) => p.theme.token.colorBorder};
  }
  .level-2.hoverable:hover > & {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  }

  background-color: ${(p) => p.theme.token.colorBgContainer};

  &.variation-gray {
    background-color: ${p => p.theme.token.colorBgContainerDisabled};
    border: none;
  }
`;

function NuPaper(props: NuPaperProps) {
  const {
    children,
    styles = {},
    hoverable = false,
    fullHeight = false,
    onClick,
    classes = {},
    level = 2,
    sideHighlightColor,
    dataIntercomTarget,
    variation = 'default',
  } = props;

  const rootClasses = classnames('nu-paper', classes.root, `level-${level}`, {
    hoverable,
    'full-height': fullHeight,
    'with-side-highlight': Boolean(sideHighlightColor),
  });

  const contentClasses = classnames('nu-paper-content', `variation-${variation}`, classes.content);

  return (
    <Root
      className={rootClasses}
      style={styles.root}
      onClick={onClick}
      sideHighlightColor={sideHighlightColor}
      data-intercom-target={dataIntercomTarget}
    >
      <PaperContent style={styles.content} className={contentClasses}>
        {children}
      </PaperContent>
    </Root>
  );
}

export default NuPaper;
