import { List } from 'antd';
import { ComponentProps, forwardRef, ReactNode, Ref } from 'react';
import { DragHandle, RenderArgs } from './sortable-list';

interface DraggableItemWithHandleProps extends RenderArgs {
  listItemProps?: ComponentProps<typeof List.Item>;
  className?: string;
  children?: ReactNode;
}

export const DraggableItemWithHandle = forwardRef((props: DraggableItemWithHandleProps, ref: Ref<HTMLElement>) => {
  const { children, listItemProps, style, attributes, listeners, setActivatorNodeRef, className } = props;

  return (
    <List.Item ref={ref as any} style={style} className={className} {...attributes} {...listItemProps}>
      {children}
      <DragHandle ref={setActivatorNodeRef} {...listeners} />
    </List.Item>
  );
});

DraggableItemWithHandle.displayName = 'DraggableItemWithHandle';
