import styled from 'styled-components';
import NspLogoUrl from 'resources/images/products/nsp-logo.png';
import TmtLogoUrl from 'resources/images/products/tmt-logo.png';
import CxpLogoUrl from 'resources/images/products/cxp-logo.png';
import { gql, useQuery } from '@apollo/client';
import { ClientLogoUrlQuery } from '../../types/graph-codegen/graph-types';

const ProductLogo = styled.div`
  width: 230px;
  height: 100px;
  // background-color: #eee;
  background-size: 300px auto;
  background-position: center;
  position: relative;
  left: -16px;
`;

export const NspLogo = styled(ProductLogo)`
  background-image: url('${NspLogoUrl}');
`;

export const TmtLogo = styled(ProductLogo)`
  background-image: url('${TmtLogoUrl}');
  background-position-x: 70%;
`;

export const CxpLogo = styled(ProductLogo)`
  background-image: url('${CxpLogoUrl}');
  background-position-x: 90%;
`;

const CLIENT_PARTNER_LOGO = gql`
  query ClientLogoUrl($id: String!) {
    getClientById(id: $id) {
      id
      logoUrl
    }
  }
`;

type PartnerLogoProps = {
  clientId?: string;
};

export function PartnerLogo(props: PartnerLogoProps) {
  const { clientId } = props;
  const { data } = useQuery<ClientLogoUrlQuery>(CLIENT_PARTNER_LOGO, {
    variables: { id: clientId },
  });

  const partnerLogo = data?.getClientById?.logoUrl;
  if (!partnerLogo) return null;

  return (
    <img
      style={{ maxWidth: '100%' }}
      src={partnerLogo}
      className="ant-menu-item-group-title ant-menu-inline"
      onError={(e) => {
        e.currentTarget.height = 0;
        e.currentTarget.className = '';
      }}
    />
  );
}
