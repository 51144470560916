import { gql, useQuery } from '@apollo/client';
import { Space } from 'antd';
import { taskListPath } from 'components/case-management/paths';
import { EmptyState, NuButton, NuCard, NuCardContent, NuCardTitle } from 'components/nuspire';
import { Link } from 'react-router-dom';
import { ClientTaskCard } from 'components/client-tasks/client-task-card';
import Spin, { SpinContainer } from 'components/nuspire/spin';

const RECENT_TASKS_CARD = gql`
  query RecentTasksCard($clientId: String!, $filters: [QueryDataTypeFilter], $next: String) {
    searchClientTasks(clientId: $clientId, filters: $filters, next: $next) {
      items {
        id
        type
        shortId
        clientId
        label
        status
        startDate
        endDate
        createdAt
        summary
        tags
        priority
        members
      }
      total
      next
    }
  }
`;

function TaskList(props: { tasks?: any[]; loading: boolean }) {
  const { tasks, loading } = props;

  if (tasks?.length) {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        {tasks.map((task) => (
          <ClientTaskCard key={task.id} {...task} />
        ))}
      </Space>
    );
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return <EmptyState>No Open Tasks were Found.</EmptyState>;
}

export function RecentTasksCard(props: { clientId: string }) {
  const { clientId } = props;

  const { data, loading } = useQuery(RECENT_TASKS_CARD, {
    variables: {
      clientId,
      filters: [
        {
          key: 'status',
          value: 'todo,in_progress,review,open',
        },
      ],
    },
  });

  const tasks = data?.searchClientTasks?.items;

  return (
    <NuCard fullHeight>
      <NuCardTitle
        title="Recent Tasks"
        actions={
          <Link to={taskListPath({ clientId })}>
            <NuButton type="link">View All</NuButton>
          </Link>
        }
      />
      <NuCardContent>
        <TaskList tasks={tasks} loading={loading} />
      </NuCardContent>
    </NuCard>
  );
}
