import { NuButton } from 'components/nuspire';
import { useState } from 'react';
import { useBlockContext } from '../blocks';
import { TextComposition, TextObject } from '../composition/text';
import { ElementComponentProps } from './actions';
import { ConfirmObject, showConfirm } from './confirm';
import { App } from 'antd';

export type ButtonStyle = 'primary' | 'default' | 'danger';

export type ButtonObject = {
  type: 'button';
  text: TextObject; // Button Text
  action_id: string; // id of action to be executed.
  style?: ButtonStyle;
  value?: string;
  confirm?: ConfirmObject;
};

/**
 * Confirm
 *
 * TODO: primary button should be green? Should be designed.
 */

export function ButtonElement(props: ElementComponentProps<ButtonObject>) {
  const {
    element,
    element: { text, style, confirm },
  } = props;
  const { modal } = App.useApp();

  // === State ===
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // =========== Block Context ===========
  const { onAction } = useBlockContext();

  const handleAction = async () => {
    setIsSubmitting(true);

    try {
      await onAction({
        action: element,
      });
    } catch (err) {
      console.log('onAction Failed', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  // =========== Handle Click ===========
  const handleClick = async () => {
    if (confirm) {
      return showConfirm({
        modal,
        confirm,
        onOk: handleAction,

        onCancel: () => {
          console.log('User Cancelled...?');
        },
      });
    } else {
      return handleAction();
    }
  };

  const type = style === 'primary' ? 'primary' : undefined;

  return (
    <NuButton loading={isSubmitting} onClick={handleClick} type={type} danger={style === 'danger'}>
      <TextComposition text={text} />
    </NuButton>
  );
}
