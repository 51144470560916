import { DownloadOutlined } from '@ant-design/icons';
import { useAuthContext } from 'components/auth-context';
import { useDataExplorerContext } from 'components/data-explorer/data-explorer';
import { useSearchParamFilters, useSortParams } from 'components/data-explorer/data-explorer-table';
import { NuButton } from 'components/nuspire';
import { FC, useState } from 'react';
import { NewScheduledReportModal } from '../reports/scheduled-report-modal';
import { parseTimeString } from '../nuspire/nu-time-range-button/time';

export type ExportAsReportButtonProps = {
  dataTypeSlug?: string;
};

export const ExportAsReportButton: FC<ExportAsReportButtonProps> = (props) => {
  const { user } = useAuthContext();
  const dataExplorerContext = useDataExplorerContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters, time] = useSearchParamFilters();
  const { sort, sortBy } = useSortParams();
  const searchFunction = dataExplorerContext?.searchFunction;
  const dataExplorerLoading = dataExplorerContext?.loading;

  const { fromIso: startDate, toIso: endDate } = parseTimeString({ time });

  return (
    <>
      <NewScheduledReportModal
        initialValues={{
          dataTypeSlug: dataExplorerContext?.dataTypeSlug,
          name: dataExplorerContext?.dataTypeDefinition?.name,
          description: dataExplorerContext?.dataTypeDefinition?.description,
          recipients: user?.email,
          reportType: 'dataExplorer',
          mode: 'once',
          url: window.location.href,
        }}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        filters={filters}
        startDate={startDate}
        endDate={endDate}
        sort={sort}
        sortBy={sortBy}
      />
      <NuButton
        type="default"
        size="large"
        onClick={() => setIsModalOpen(true)}
        disabled={!searchFunction || dataExplorerLoading}
        loading={dataExplorerLoading}
        icon={<DownloadOutlined />}
      >
        Export
      </NuButton>
    </>
  );
};
