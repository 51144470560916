import styled from 'styled-components';
import { ReactNode } from 'react';

const CenteredContentRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const CenterColumn = styled.div`
  max-width: 900px;
  width: 100%;
`;
export function CenteredContent({
  children,
  styles = {},
}: {
  children: ReactNode;
  styles?: {
    column?: React.CSSProperties;
  };
}) {
  return (
    <CenteredContentRoot>
      <CenterColumn style={styles.column}>{children}</CenterColumn>
    </CenteredContentRoot>
  );
}
