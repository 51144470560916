// will validate all emails in a comma separated list
export function emailListValidator(value: string): Promise<any> {
  let isValid: boolean = true;
  const emails: string[] = value.split(',');
  for (const email of emails) {
    if (
      !email
        .trim()
        .toLowerCase()
        // email validation -> https://stackoverflow.com/a/46181
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
    ) {
      isValid = false;
      break;
    }

    continue;
  }

  if (isValid) return Promise.resolve();

  return Promise.reject(new Error('Please enter valid email addresses.'));
}
