import { Typography } from 'antd';
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';

export type LinkMode = 'plain' | 'default';

const StyledLink = styled(ReactRouterLink).attrs<{ $mode?: LinkMode }>({
  component: Typography.Link,
})`
  ${(p) => p.$mode === 'plain' ? css`
    && {
      color: ${p.theme.token.colorText};
      &:hover {
        color: ${p.theme.color.primary};
      }
    }
  ` : ''}
`;

export interface LinkProps extends Omit<ReactRouterLinkProps, 'component'> {
  /**
   * default: Typography.Link
   * plain: normal text color (black/white) with link hover
   *
   * @default default
   */
  mode?: LinkMode;
}

/**
 * Link from `react-router-dom` styled with antd `Typography.Link`.
 */
export function Link(props: LinkProps) {
  const { mode = 'default', children, ...restProps } = props;

  return <StyledLink $mode={mode} {...restProps}>{children}</StyledLink>
}

