import * as NuIcon from 'components/nuspire/nu-icon';
import { IConnector } from 'components/connections/connectors';
import OauthRedirect from './jira-redirect';
import { encodeOAuthState } from 'components/connections/connectors/utils/oauth';
import { config } from 'config';
/**
 * JIRA CONNECTOR
 */
const JIRA_ENV_MAP: { [key: string]: { clientId: string; redirectUrl: string } } = {
  local: { clientId: 'AVbvBwbjOoE8dfeZEKDPAbjDxlRtp2Jn', 
  redirectUrl: 'http://localhost:3000/redirects/connections/jira/oauth-redirect',
},
  development: { clientId: 'QmuTTaRq0bIOIfhnR8xyUP428JXbxnsS',
  redirectUrl: 'https://beta.mynuspire.io/redirects/connections/jira/oauth-redirect'
},
};

// client id(jira app) varies per env.
export const CLIENT_ID = JIRA_ENV_MAP[config.isLocal ? 'local' : import.meta.env.MODE]?.clientId ?? null;
// export const REDIRECT_URL = `${window.location.origin}/redirects/connections/jira/oauth-redirect`;
export const REDIRECT_URL = JIRA_ENV_MAP[config.isLocal ? 'local' : import.meta.env.MODE]?.redirectUrl ?? null;

// https://developer.atlassian.com/cloud/jira/platform/scopes-for-connect-and-oauth-2-3LO-apps/
const SCOPES = [
  // VIEW USER PROFILES.
  // 'read:jira-user',

  // Read Jira project and issue data, search for issues and objects associated with issues like attachments and worklogs.
  // 'read:jira-work',
  // create and manage issues.
  // 'write:jira-work',

  // 'offline_access',



  'read:servicedesk-request', // Scopes need to be added to app in developer console. 
  'write:servicedesk-request', // Scopes need to be added to app in developer console. 

  // granular?
];

function getOauthUrl(args: { clientId?: string | null; sessionId?: string | null }) {
  const { clientId, sessionId } = args;

  // encode state to pass as search param to oAuth client.
  const state = encodeOAuthState({ clientId, sessionId });

  const OAUTH_URL = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&scope=${encodeURIComponent(SCOPES.join(' '))}&client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_URL)}&response_type=code&prompt=consent&state=${state}`;

  return OAUTH_URL;
}

const JIRA_CONNECTOR_DEFINITION: IConnector = {
  slug: 'jira',
  name: 'Jira',
  description: `Jira Api's`,
  connectionTypes: ['action'],
  icon: <NuIcon.Jira />,
  renderId: (props) => {
    const { clientConnection } = props;

    return <>{`Account: ${clientConnection.meta.accountId}`}</>;
  },
  handleAddConnection: (args) => {
    const oAuthUrl = getOauthUrl(args);

    window.open(oAuthUrl, 'Jira Oauth', 'height=1000,width=800');
  },
  redirects: [{ path: 'oauth-redirect', component: OauthRedirect }],
};

export default JIRA_CONNECTOR_DEFINITION;
