import { Select } from 'antd';
import { ActionInputProps } from '../../action-form-field';
import { gql, useQuery } from '@apollo/client';

const JIRA_PROJECTS = gql`
  query JiraProjectSelect($connectionId: String!) {
    clientConnection(id: $connectionId) {
      id
      jiraProjects {
        id
        name
        key
      }
    }
  }
`;

function SlackChannelSelect(props: ActionInputProps) {
  // can only select if connection has been selected.
  const { value, onChange, formik } = props;

  const values = formik?.values;
  const connectionId = values?.connection?.id ?? null;

  const { data } = useQuery(JIRA_PROJECTS, {
    variables: { connectionId },
    skip: !connectionId,
  });

  const projects = data?.clientConnection?.jiraProjects;

  const options = projects ?? (value ? [value] : []);

  return (
    <Select
      value={value?.id}
      onChange={(selectedId) => {
        const selectedOption = options.find((o: any) => o.id === selectedId);

        onChange(selectedOption);
      }}
      disabled={!connectionId}
      placeholder="Select Jira Project"
    >
      {options.map((c: any) => (
        <Select.Option key={c.id} value={c.id}>
          {`(${c.key}) ${c.name}`}
        </Select.Option>
      ))}
    </Select>
  );
}

export default SlackChannelSelect;
