import { Spin, Breadcrumb, message } from 'antd';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { useEffect } from 'react';
import { Link } from 'components/nuspire';
import styled from 'styled-components';

const StyledBreadcrumb = styled(Breadcrumb)`
  && {
    margin-bottom: 1rem;
  }
`;

const Navbar = styled.nav`
  position: relative;

  & .ant-spin {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export interface BreadcrumbBarItem {
  text: string;
  to?: string;
}

export function BreadcrumbBar({
  items,
  isLoading,
  error,
}: {
  items: BreadcrumbBarItem[];
  isLoading?: boolean;
  error?: any;
}) {
  useEffect(() => {
    if (error) {
      console.error(error);
      message.error('An error occurred while attempting to load your breadcrumbs.');
    }
  }, [error]);

  return (
    <Navbar aria-label="breadcrumb">
      <StyledBreadcrumb
        items={items?.map((item): ItemType => {
          const { text, to } = item;

          return {
            key: `${to}-${text}`,
            title: to ? <Link to={to}>{text}</Link> : text,
          };
        })}
      />
      {isLoading && <Spin />}
    </Navbar>
  );
}
