import React from 'react';
import styled from 'styled-components';
import { Link, NuPaper } from 'components/nuspire';
import baseTheme from 'components/theme';
import * as NuIcon from 'components/nuspire/nu-icon';

const SliContent = styled.div`
  display: flex;
`;
export const StepListItemLabel = styled.div`
  color: ${baseTheme.color.slateBlue};
  font-weight: bold;
  font-size: 14px;
  flex: 1;
  padding-right: 16px;
`;

export const StepListItemIcon = styled.div`
  height: 24px;
  width: 32px;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -4px;
  margin-right: 4px;
`;
export const StepListStatus = styled.div<{ enabled: boolean }>`
  color: ${(p) => (p.enabled ? baseTheme.color.green : baseTheme.color.gray5)};
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
`;
export const StepListItemMeta = styled.div`
  display: flex;
  > * {
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const EmptyIcon = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 4px;
  background-color: ${baseTheme.color.gray2};
`;

const CcRoot = styled.div`
  display: flex;
  align-items: center;
`;
const CcCount = styled.div``;

export function ConnectionsCount(props: { connectionsCount: number }) {
  const { connectionsCount } = props;
  return (
    <CcRoot>
      <NuIcon.ConnectionsIcon style={{ marginRight: '4px', color: baseTheme.color.gray5 }} />
      <CcCount>{connectionsCount}</CcCount>
    </CcRoot>
  );
}

function StepListItem(props: {
  children: React.ReactNode;
  onClick?: () => void;
  linkTo?: string;
  dataIntercomTarget?: string;
}) {
  const { children, linkTo, onClick, dataIntercomTarget } = props;

  const paper = (
    <NuPaper
      onClick={onClick}
      hoverable={Boolean(linkTo || onClick)}
      styles={{ content: { padding: '8px', marginBottom: '8px' } }}
      dataIntercomTarget={dataIntercomTarget}
    >
      <SliContent>{children}</SliContent>
    </NuPaper>
  );

  if (!linkTo) {
    return paper;
  }

  return <Link to={linkTo}>{paper}</Link>;
}

export default StepListItem;
