import { Tag, Typography } from 'antd';
import { useClientContext } from 'components/client-context-provider';
import { Link, NuPageHeader } from 'components/nuspire';
import InventoryInfiniteTable from 'components/insights/insights-infinite-table';
import { useTheme } from 'styled-components';

export function getSeverity(severity: number) {
  const theme = useTheme();
  // type --> https://qualysguard.qg2.apps.qualys.com/portal-help/en/was/knowledgebase/severity_levels.htm
  if (severity >= 5) {
    return { color: theme.color.error, type: 'Urgent' };
  }
  if (severity === 4) {
    return { color: theme.color.orange, type: 'Critical' };
  }
  if (severity === 3) {
    return { color: theme.color.softYellow, type: 'Serious' };
  }
  if (severity === 2) {
    return { color: theme.color.primary, type: 'Medium' };
  }

  return { color: theme.color.indigo, type: 'Minimal' };
}

function InventoryDescription() {
  const { clientId } = useClientContext();

  return (
    <Typography.Paragraph type="secondary" style={{ marginBottom: '24px' }}>
      Nuspire Insights leverages your existing security technology available in the{' '}
      <Link to={`/${clientId}/connections`} mode="plain">Connections</Link> library to combine data in unique ways to improve your
      security program. See existing sources that fuel the{' '}
      <Link to={`/${clientId}/insights/combined`} mode="plain">Combined Insights</Link>.
    </Typography.Paragraph>
  );
}

function InventoryList() {
  const { clientId } = useClientContext();

  return (
    <>
      <NuPageHeader level={4} title="Inventory" subHeading={<InventoryDescription />} />
      <InventoryInfiniteTable
        options={{ pageSize: 20 }}
        clientId={clientId || ''}
        scrollHeight={500}
        columns={[
          {
            title: 'IP',
            key: 'ip',
            render: (record) => {
              return <Link to={`/${clientId}/insights/inventory/${record.id}`} mode="plain">{record.ip}</Link>;
            },
          },

          {
            title: 'Operating System',
            render: (record) => record.qualys?.host?.os ?? '--',
          },

          //  These could be placed in a vertical meta list
          {
            title: 'Hostname',
            render: (record) => record.qualys?.host?.dnsData?.hostname ?? '--',
          },
          {
            title: 'Domain',
            render: (record) => record.qualys?.host?.dnsData?.domain ?? '--',
          },
          {
            title: 'Active Detections',
            render: (record) => record?.qualys?.activeDetectionCount ?? '--',
          },
          {
            title: 'Severity (Counts)',
            render: (record) => {
              const sorted = record.qualys?.severityCounts ? [...record.qualys?.severityCounts] : [];

              sorted?.sort((a, b) => {
                if (a.severity < b.severity) {
                  return 1;
                }
                if (a.severity > b.severity) {
                  return -1;
                }
                return 0;
              });

              if (!sorted) {
                return '--';
              }

              return (
                <>
                  {sorted.map(({ severity, count }) => {
                    const severityData = getSeverity(severity);
                    return (
                      <div key={severity}>
                        <Tag color={severityData.color}>{`${severityData.type} (${count})`}</Tag>
                      </div>
                    );
                  })}
                </>
              );
            },
          },
        ]}
      />
    </>
  );
}

export default InventoryList;
