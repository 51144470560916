import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { App, message, Tooltip } from 'antd';
import { ButtonType } from 'antd/es/button';
import { LegacyButtonType } from 'antd/es/button/button';
import { NuButton, NuButtonProps } from 'components/nuspire';
import { DocumentNode } from 'graphql';
import { ReactNode, useEffect } from 'react';

type MutationButtonProps = NuButtonProps & {
  authorized?: boolean;
  buttonIcon: ReactNode;
  buttonType?: ButtonType;
  danger?: boolean;
  disabled?: boolean;
  modalContent?: ReactNode;
  modalOkType?: LegacyButtonType;
  modalTitle: ReactNode;
  modalWidth?: number;
  mutation: DocumentNode;
  mutationVariables?: any;
  refetchQueries?: any;
  onCompleted?: (data?: any) => void;
  onError?: (error: any) => void;
  tooltipOverlay?: any;
  type?: string;
  unauthorizedMessage?: string;
};

const defaultModalWidth = 600;

export default function MutationButton(props: MutationButtonProps) {
  const {
    authorized = true,
    buttonIcon,
    buttonType,
    children,
    danger,
    disabled,
    modalContent,
    modalOkType = `default`,
    modalTitle,
    modalWidth = defaultModalWidth,
    mutation,
    mutationVariables,
    refetchQueries,
    onCompleted,
    onError,
    tooltipOverlay,
    type,
    unauthorizedMessage,
    ...buttonProps
  } = props;
  const [doAction, { data, error, loading }] = useMutation(mutation);
  const { modal } = App.useApp();

  useEffect(() => {
    if (!data) return;
    if (onCompleted) onCompleted(data);
  }, [data]);

  useEffect(() => {
    if (!error) return;
    if (onError) onError(error);
  }, [error]);

  const onButtonClick = () => {
    modal.confirm({
      title: modalTitle,
      content: modalContent,
      icon: <ExclamationCircleOutlined />,
      okText: `Yes`,
      okType: modalOkType,
      cancelText: `No`,
      onOk: async () => {
        if (!authorized) {
          message.error(unauthorizedMessage ?? 'You do not have the required permissions to perform this action.');
        }

        doAction({
          variables: mutationVariables,
          refetchQueries,
        });
      },
      width: modalWidth,
    });
  };

  if (tooltipOverlay) {
    return (
      <Tooltip overlay={tooltipOverlay}>
        <NuButton
          danger={danger}
          disabled={!authorized || loading || disabled}
          icon={buttonIcon}
          loading={loading}
          onClick={onButtonClick}
          type={buttonType}
          {...buttonProps}
        >
          {children}
        </NuButton>
      </Tooltip>
    );
  }

  return (
    <NuButton
      danger={danger}
      disabled={!authorized || loading || disabled}
      icon={buttonIcon}
      loading={loading}
      onClick={onButtonClick}
      type={buttonType}
      {...buttonProps}
    >
      {children}
    </NuButton>
  );
}
