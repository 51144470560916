export const downloadUrlAsFile = (args: { url: string; fileName: string, newTab?: boolean }) => {
  const { url, fileName, newTab } = args;

  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;

  if (newTab) {
    a.target = '_blank';
  }

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
