import { useClientContext } from 'components/client-context-provider';
import EmptyState from 'components/nuspire/nu-empty-state';
import useSearchParams from 'utils/react-hooks/useSearchParams';
import { WidgetComponentProps } from '../index';
import { ParentSize } from '@visx/responsive';
import { FunnelChart, FunnelChartData } from 'components/nuspire/charts/funnel-chart';
import styled from 'styled-components';

const Footer = styled.div`
  position: absolute;
  color: ${(p) => p.theme.token.colorTextSecondary};
  font-size: ${(p) => p.theme.token.fontSizeSM}px;
  font-style: italic;
  height: 20px;
  bottom: 20px;
`;

export type MonthlyEventSummaryWidgetData = {
  alertsGenerated?: number;
  casesClosed?: number;
  casesOpened?: number;
  logsCollected?: number;
};

export const MonthlyEventSummaryWidget = (props: WidgetComponentProps<any, MonthlyEventSummaryWidgetData>) => {
  const { data } = props;
  const { client, clientId } = useClientContext();
  const { parsed } = useSearchParams();

  if (!data) return <EmptyState>No Data Found.</EmptyState>;

  const caseManagementBaseURI = `/${clientId}/case-management`;
  const incidentsURI = `${caseManagementBaseURI}/incidents`;
  const casesURI = `${caseManagementBaseURI}/cases`;

  const closedCaseStates = ['Closed', 'Resolved', 'Cancelled'];
  const time: string | null = parsed?.time ?? null;
  const includeChildren: 'true' | null = client?.isMultiTenancyEnabled ? 'true' : null;
  const createdIncidentsParams = new URLSearchParams({ dateField: 'sys_created_on' });
  const createdCasesParams = new URLSearchParams({ dateField: 'sys_created_on' });
  const closedCasesParams = new URLSearchParams({ dateField: 'closed_at', states: closedCaseStates.join(',') });

  if (time) {
    createdIncidentsParams.append('time', time);
    createdCasesParams.append('time', time);
    closedCasesParams.append('time', time);
  }
  if (includeChildren) {
    createdIncidentsParams.append('includeChildren', includeChildren);
    createdCasesParams.append('includeChildren', includeChildren);
    closedCasesParams.append('includeChildren', includeChildren);
  }

  const funnelData: FunnelChartData[] = [
    {
      label: 'Logs Collected',
      value: data?.logsCollected ?? 0,
    },
    {
      label: 'Alerts Generated',
      value: data?.alertsGenerated ?? 0,
      link: `${incidentsURI}?${createdIncidentsParams.toString()}`,
    },
    {
      label: 'Cases Opened',
      value: data?.casesOpened ?? 0,
      link: `${casesURI}?${createdCasesParams?.toString()}`,
    },
    {
      label: 'Cases Closed',
      value: data?.casesClosed ?? 0,
      link: `${casesURI}?${closedCasesParams?.toString()}`,
    },
  ];

  return (
    <>
      <ParentSize>
        {({ width, height }) => <FunnelChart width={width} height={height - 20} data={funnelData} />}
      </ParentSize>
      <Footer>This data is based on the past month.</Footer>
    </>
  );
};
