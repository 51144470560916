import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { App, Form, message, Modal, Select, Spin } from 'antd';
import { useAuthContext } from 'components/auth-context';
import { NuButton } from 'components/nuspire';
import InlineInput from 'components/nuspire/form/inline-input';
import { SpinContainer } from 'components/nuspire/spin';
import { CSSProperties, FC, useState } from 'react';
import {
  ClientRunbookEscalation,
  CreateClientRunbookEscalationMutation,
  CreateClientRunbookEscalationMutationVariables,
  UpdateClientRunbookEscalationMutation,
  UpdateClientRunbookEscalationMutationVariables,
} from 'types/graph-codegen/graph-types';
import { mixpanelTrack } from 'utils/mixpanel/mixpanel-track';
import { ACCOUNT_CONTACTS_QUERY } from 'components/case-management/open-case';
import { useClientContext } from 'components/client-context-provider';

export const USERS_CLIENT_RUNBOOK_ESCALATION_ORDER_QUERY = gql`
  query UsersClientRunbookEscalationOrder($userId: String) {
    clientRunbookEscalationsForUser(userId: $userId) {
      account {
        name
      }
      accountSysId
      contactType
      escalationOrder
      runbookSysId
      sysId
      userSysId
    }
  }
`;

export const UPDATE_CLIENT_RUNBOOK_ESCALATION = gql`
  mutation UpdateClientRunbookEscalation(
    $userId: String
    $escalationId: String!
    $input: UpdateClientRunbookEscalationInput!
    $snowUserId: String
  ) {
    updateClientRunbookEscalation(
      userId: $userId
      escalationId: $escalationId
      input: $input
      snowUserId: $snowUserId
    ) {
      accountSysId
      businessPhone
      contactType
      email
      escalationOrder
      firstName
      lastName
      mobilePhone
      runbookSysId
      sysId
      userSysId
    }
  }
`;

export const DELETE_CLIENT_RUNBOOK_ESCALATION = gql`
  mutation DeleteClientRunbookEscalation($userId: String, $escalationId: String!, $snowUserId: String) {
    deleteClientRunbookEscalation(userId: $userId, escalationId: $escalationId, snowUserId: $snowUserId)
  }
`;

export const CREATE_CLIENT_RUNBOOK_ESCALATION_MUTATION = gql`
  mutation CreateClientRunbookEscalation(
    $userId: String
    $input: CreateClientRunbookEscalationInput!
    $snowUserId: String
    $serviceNowAccountId: String
  ) {
    createClientRunbookEscalation(
      userId: $userId
      input: $input
      snowUserId: $snowUserId
      serviceNowAccountId: $serviceNowAccountId
    ) {
      accountSysId
      businessPhone
      contactType
      email
      escalationOrder
      firstName
      lastName
      mobilePhone
      runbookSysId
      sysId
      userSysId
    }
  }
`;

export const contactTypes = ['NOC', 'SOC'];
export const escalationOrderValues = Array.from({ length: 10 }, (_, i) => i + 1);

export type ClientRunbookEscalationsInlineInputsProps = {
  clientRunbookEscalations?: ClientRunbookEscalation[] | null;
  disabled?: boolean;
  loading?: boolean;
  onSuccess?: () => void;
  userId?: string;
  snowUserId?: string;
  serviceNowAccountId?: string;
};

export const ClientRunbookEscalationsInlineInputs: FC<ClientRunbookEscalationsInlineInputsProps> = (props) => {
  const {
    clientRunbookEscalations = [],
    disabled = false,
    loading,
    onSuccess,
    userId,
    snowUserId,
    serviceNowAccountId,
  } = props;
  const { user: authUser } = useAuthContext();
  const [updateClientRunbookEscalation] = useMutation<
    UpdateClientRunbookEscalationMutation,
    UpdateClientRunbookEscalationMutationVariables
  >(UPDATE_CLIENT_RUNBOOK_ESCALATION);
  const [deleteClientRunbookEscalation] = useMutation(DELETE_CLIENT_RUNBOOK_ESCALATION);
  const { modal } = App.useApp();

  const isEditingSelf = authUser?.id === userId;

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return (
    <>
      {clientRunbookEscalations?.map((rbe) => {
        const label = `${rbe.contactType} (${rbe.account?.name ?? 'Unknown Account'})`;

        return (
          <InlineInput
            key={rbe.sysId}
            readView={
              <span style={{ display: 'block' }} key={rbe.sysId!}>
                <strong>{label}:</strong> {rbe.escalationOrder}
              </span>
            }
            editView={({ formikProps }) => (
              <div style={{ display: 'flex' }}>
                <Form.Item key={rbe.sysId} label={label} style={{ flexGrow: 1, marginRight: '.5rem' }}>
                  <Select
                    value={formikProps.values[rbe.sysId]?.escalationOrder ?? ''}
                    onChange={(newValue) => {
                      const newEscalationOrder = !Number.isNaN(parseInt(`${newValue}`))
                        ? parseInt(`${newValue}`)
                        : undefined;

                      const newEscalation: ClientRunbookEscalation = { ...rbe };

                      newEscalation.escalationOrder = newEscalationOrder;
                      formikProps.setFieldValue(rbe.sysId, newEscalation);
                    }}
                  >
                    <Select.Option value="" disabled>
                      Select an escalation order
                    </Select.Option>
                    {escalationOrderValues.map((value) => (
                      <Select.Option key={value} value={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <NuButton
                  icon={<DeleteOutlined />}
                  type="text"
                  onClick={() =>
                    modal.confirm({
                      title: `Remove this runbook escalation?`,
                      okText: `Yes`,
                      okType: 'primary',
                      cancelText: `No`,
                      onOk: async () => {
                        await deleteClientRunbookEscalation({
                          variables: {
                            userId,
                            escalationId: rbe.sysId,
                            snowUserId,
                          },
                        });

                        mixpanelTrack('runbook-escalation-deleted');

                        onSuccess?.();
                      },
                    })
                  }
                />
              </div>
            )}
            canEdit={!disabled}
            useEditButton
            closeOnOutsideClick={false}
            onFinish={async ({ values }) => {
              try {
                const runbookEscalation: ClientRunbookEscalation = values[rbe.sysId];

                await updateClientRunbookEscalation({
                  variables: {
                    userId,
                    escalationId: runbookEscalation.sysId,
                    input: {
                      escalationOrder: runbookEscalation.escalationOrder,
                    },
                    snowUserId,
                  },
                });

                message.success(`Runbook escalation updated!`);
                mixpanelTrack('runbook-escalation-updated');
                onSuccess?.();
              } catch (err) {
                message.error(
                  `An error occurred while updating ${isEditingSelf ? 'your' : `this user's`} runbook escalations.`,
                );
              }
            }}
            initialValues={{ [rbe.sysId]: rbe }}
          />
        );
      })}
      <CreateClientRunbookEscalationButtonAndModal
        buttonStyle={{ marginTop: '0.5rem' }}
        userId={userId}
        isAuthorizedToCreate={!disabled}
        onSuccess={onSuccess}
        serviceNowAccountId={serviceNowAccountId}
        snowUserId={snowUserId}
      />
    </>
  );
};

export type CreateClientRunbookEscalationButtonAndModalProps = {
  buttonStyle?: CSSProperties;
  buttonType?: 'link' | 'text' | 'primary' | 'default' | 'dashed';
  isAuthorizedToCreate?: boolean;
  onSuccess?: () => void;
  userId?: string;
  serviceNowAccountId?: string;
  snowUserId?: string;
  includeContactSelect?: boolean;
};

export type CreateClientRunbookEscalationFormValues = {
  contactType: string;
  escalationOrder: number;
  contact?: string;
};

export const CreateClientRunbookEscalationButtonAndModal: FC<CreateClientRunbookEscalationButtonAndModalProps> = (
  props,
) => {
  const {
    buttonStyle,
    buttonType = 'primary',
    isAuthorizedToCreate = false,
    onSuccess,
    userId,
    serviceNowAccountId,
    snowUserId,
    includeContactSelect = false,
  } = props;
  const [createClientRunbookEscalation, { loading }] = useMutation<
    CreateClientRunbookEscalationMutation,
    CreateClientRunbookEscalationMutationVariables
  >(CREATE_CLIENT_RUNBOOK_ESCALATION_MUTATION);
  const [isVisible, setIsVisible] = useState(false);
  const [form] = Form.useForm();
  const { clientId } = useClientContext();
  const { data, loading: contactsLoading } = useQuery(ACCOUNT_CONTACTS_QUERY, {
    variables: { clientId },
    skip: !includeContactSelect,
  });

  const onFormFinish = async (values: CreateClientRunbookEscalationFormValues) => {
    try {
      const { contactType, escalationOrder, contact } = values;

      await createClientRunbookEscalation({
        variables: {
          userId,
          input: {
            contactType,
            escalationOrder,
          },
          serviceNowAccountId,
          snowUserId: contact ?? snowUserId,
        },
      });

      form.resetFields();
      message.success('Runbook escalation order successfully created!');

      mixpanelTrack('rubook-escalation-order-created');

      onSuccess?.();
    } catch (err) {
      console.error(err.message);
      message.error('An error occurred while attempting to create a runbook escalation order.');
    } finally {
      setIsVisible(false);
    }
  };

  return (
    <>
      <Modal
        open={isVisible}
        title="Create New Client Runbook Escalation"
        onCancel={() => setIsVisible(false)}
        footer={false}
        width={1200}
      >
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={onFormFinish}
          initialValues={{
            contactType: '',
            escalationOrder: '',
          }}
        >
          <Form.Item name="contactType" label="Contact Type" rules={[{ required: true }]}>
            <Select disabled={loading}>
              <Select.Option value="" disabled>
                Select a contact type
              </Select.Option>
              {contactTypes.map((ct) => (
                <Select.Option key={ct} value={ct}>
                  {ct}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {includeContactSelect ? (
            <Form.Item label="Contact" name="contact">
              <Select
                showSearch
                allowClear
                placeholder="Contact"
                loading={contactsLoading}
                disabled={loading || contactsLoading}
              >
                {data?.accountContacts?.map((c) => (
                  <Select.Option key={c.id} value={c.id}>
                    {c.name} &lt;{c.email}&gt;
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : null}
          <Form.Item name="escalationOrder" label="Escalation Order" rules={[{ required: true }]}>
            <Select disabled={loading}>
              <Select.Option value="" disabled>
                Select an escalation order
              </Select.Option>
              {escalationOrderValues.map((eo) => (
                <Select.Option key={eo} value={eo}>
                  {eo}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <NuButton type="primary" htmlType="submit" disabled={!isAuthorizedToCreate || loading} loading={loading}>
              Create
            </NuButton>
          </Form.Item>
        </Form>
      </Modal>
      <NuButton
        type={buttonType}
        onClick={() => setIsVisible(true)}
        disabled={!isAuthorizedToCreate || loading}
        loading={loading}
        style={buttonStyle}
        icon={<PlusCircleOutlined />}
      >
        Create New Escalation Order
      </NuButton>
    </>
  );
};
