import React, { createContext, useContext } from 'react';
import styled from 'styled-components';
import { ActionsBlock, ActionsBlockObject, ElementObject } from './actions/actions';
import { SectionBlock, SectionBlockObject } from './section/section';

export type BlockBase = {
  type: string;
};

export type BlockBlank<T> = {
  type: string;
} & T;

export type BlockObject = ActionsBlockObject | SectionBlockObject;

export type BlockComponentProps<T> = {
  key: string;
  block: T;
};

export type BlockComponent<T> = (props: BlockComponentProps<T>) => JSX.Element;

// Args passed when any action is taken.
export type HandleActionArgs = {
  actions: ElementObject[];
};

export type HandleAction = (args: HandleActionArgs) => void;

export type BlocksProps = {
  blocks: BlockObject[];

  onAction?: HandleAction;
};

const BLOCK_MAP: {
  [key: string]: BlockComponent<any>;
} = {
  actions: ActionsBlock,
  section: SectionBlock,
};

/**
 * Block Component
 */
const BlockRoot = styled.div``;

export function Block(props: { key: string; block: BlockObject }) {
  const { key, block } = props;

  const BlockComponent = BLOCK_MAP[block.type];

  if (!BlockComponent) {
    throw new Error(`Could not find block type: ${block.type}.`);
  }

  return (
    <BlockRoot>
      <BlockComponent key={key} block={block} />
    </BlockRoot>
  );
}

/**
 * Blocks Component
 * @param props
 */
export const BlocksRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

/**
 * We need to know
 *  - What button was clicked.
 *  - Future: (state) Value of other inputs (text, selects, etc...).
 */

export type ActionArgs = {
  action: ElementObject;
};

export type IBlockContext = {
  onAction: (args: ActionArgs) => void; // button will call this when confirmed.
};

export const BlockContext = createContext<IBlockContext>({
  onAction: () => {},
});

// elements (ie buttons) can use this to gain block context and execute actions
export function useBlockContext(): IBlockContext {
  return useContext(BlockContext);
}

export function BlockContextProvider(props: { children: React.ReactNode; onAction?: HandleAction }) {
  const { children, onAction } = props;

  // TODO: State (form elements)
  const handleAction = async (args: ActionArgs) => {
    console.log('Handle Action in Provider');

    console.log({ args });
    const { action } = args;

    if (onAction) {
      await onAction({
        actions: [action],
      });
    }
  };

  // Context
  const context: IBlockContext = {
    onAction: handleAction,
  };

  return <BlockContext.Provider value={context}>{children}</BlockContext.Provider>;
}

export function Blocks(props: BlocksProps) {
  const { blocks, onAction } = props;

  const formattedBlocks = formatBlocks(blocks);

  return (
    <BlockContextProvider onAction={onAction}>
      <BlocksRoot>
        {formattedBlocks.map((formattedBlock) => {
          const { key, ...block } = formattedBlock;

          return <Block key={key} block={block} />;
        })}
      </BlocksRoot>
    </BlockContextProvider>
  );
}

/**
 * Format Blocks with a unique Key
 * we need unique keys so we can safely map over blocks in react;
 */
export type FormattedBlockObject = {
  key: string;
} & BlockObject;

export function formatBlocks(blocks: BlockObject[]): FormattedBlockObject[] {
  const blockKeys: {
    [key: string]: number;
  } = {};

  const formattedBlocks: FormattedBlockObject[] = blocks.map((block) => {
    const { type } = block;

    let typeCount = 1;
    if (blockKeys[type]) {
      typeCount = blockKeys[type] += 1;
    } else {
      blockKeys[type] = typeCount;
    }

    const key = `${type}_${typeCount}`;

    return {
      ...block,
      key,
    };
  });

  return formattedBlocks;
}
