import { FilterInputFieldsFragment, FilterInputOperator } from 'types/graph-codegen/graph-types';
import useSearchParams, { ParamConfig, SearchParamsInterface } from './useSearchParams';

export const SEARCH = 'search';
export const TIME = 'time';
export type SearchParams = SearchParamsInterface['parsed'];

// rename for sake of brevity
export type FilterInput = FilterInputFieldsFragment;

export type SearchParamFilter = {
  key: string;
  filterTagLabel: string;
  value?: any; // this should always be json stringified values.
  searchParamValue?: string | null;
  operator?: FilterInputOperator;
  filterInput?: FilterInput;
};

export type FiltersByKey = { [key: string]: SearchParamFilter };

export type FilterUpdate = { key: string; value: any; operator?: FilterInputOperator };

// Maybe this class is only responsilbe for parsing?
export class SearchParamFilters {
  searchParamsApi: SearchParamsInterface;
  filterInputs?: FilterInput[];
  filters: SearchParamFilter[];
  filtersByKey: FiltersByKey;

  searchQuery?: string;
  time?: string;

  // Allow update function to override the classes default updateFilter method.
  // this should be used if saving filter values in state and we don't want this class
  // to be updating search params.
  onUpdateFilter?: (args: FilterUpdate) => void;

  onUpdateFilters?: (updates: FilterUpdate[]) => void;

  onRemoveFilter?: (key: string) => void;

  constructor(args: {
    // api used to interact with search params.
    searchParamsApi: SearchParamsInterface;

    // these are the filter definitions (typically from data types in data explorer);
    // but there is some useful info on the type of data expected that can help in parsing.
    filterInputs?: FilterInput[];

    /**
     * Need a way to allow state to manage filters for optimized ux.
     * this way we can save the filters in state,
     * use this class to parse search params and updates,
     * allow the hook to save filters in state
     * but pass this class down to child components for simple usage.
     * that's the idea atleast lol.
     */
    filtersByKey?: FiltersByKey;

    // methods that will override classes default utils for these common actions.
    onUpdateFilter?: (args: FilterUpdate) => void;
    onUpdateFilters?: (updates: FilterUpdate[]) => void;
    onRemoveFilter?: (key: string) => void;
  }) {
    this.searchParamsApi = args.searchParamsApi;
    this.filterInputs = args.filterInputs;

    // class overrides update method.
    // when this is the case, this class doesn't touch search params.
    this.onUpdateFilter = args.onUpdateFilter;
    this.onRemoveFilter = args.onRemoveFilter;
    this.onUpdateFilters = args.onUpdateFilters;

    if (args.filtersByKey) {
      this.filtersByKey = args.filtersByKey;

      // Filters is the array that should be used for displaying
      // we only want to show filters with an operator or value. searchParamValue is an easy way to filter.
      // can turn this into a utility method with tests around it for clarity.
      this.filters = Object.values(this.filtersByKey).filter((filter) => filter.searchParamValue);
    } else {
      // we can try and parse the filters from the search params api that's been passed.
      // parse search params for queries.
      this.filters = this.parseSearchParamFilters({
        filterInputs: this.filterInputs,
        searchParams: this.searchParamsApi.parsed,
      });

      this.filtersByKey = this.mapFiltersToKey(this.filters);
    }

    this.searchQuery = this.parseSearchQuery();
    this.time = this.parseTime();
  }

  mapFiltersToKey(filters: SearchParamFilter[]) {
    return filters.reduce((acc, f) => {
      acc[f.key] = f;

      return acc;
    }, {});
  }

  /**
   * parse query params into filters.
   * @param args
   * @returns
   */
  parseSearchParamFilters(args: { filterInputs?: FilterInput[]; searchParams: SearchParams }): SearchParamFilter[] {
    const { searchParams } = args;

    const filterInputs = args.filterInputs ?? [];

    // Map over search param keys.
    const filters = filterInputs.map((filterInput) => {
      const { key } = filterInput;
      // stringified value from search params.
      const searchParamValue = searchParams[key];

      const filter = this.parseSearchParam({
        key,
        filterInput,
        searchParamValue,
      });

      return filter;
    });

    return filters;
  }

  parseSearchQuery() {
    const searchQuery = this.searchParamsApi.parsed?.[SEARCH];

    return searchQuery;
  }

  parseTime() {
    const time = this.searchParamsApi.parsed?.[TIME];

    return time;
  }

  parseSearchParam(args: { key: string; searchParamValue?: string; filterInput?: FilterInput }): SearchParamFilter {
    const { key, searchParamValue, filterInput } = args;

    let [value, operator] = this.separateValueAndOperator({ searchParamValue });

    value = this.parseFilterStringValue({ value, filterInput });

    const filterTagLabel = this.parseFilterTagLabel({ key, value, operator, filterInput });

    const filter: SearchParamFilter = {
      key,
      filterInput,
      value,
      operator,
      searchParamValue,
      filterTagLabel,
    };

    return filter;
  }

  parseFilterTagLabel(args: { key: string; value: any; operator?: FilterInputOperator; filterInput?: FilterInput }) {
    const { key, value, operator, filterInput } = args;

    const inputLabel = filterInput?.label ?? key;

    const operatorLabel = this.getOperatorLabel(operator);

    const valueLabels = filterInput ? this.getValueLabels({ value, filterInput }) : JSON.stringify(value);

    const label = `${inputLabel} ${operatorLabel} ${valueLabels}`;

    return label;
  }

  getOperatorLabel(operator?: FilterInputOperator) {
    if (operator === 'IS') {
      return 'IS';
    }

    if (operator === 'IS_NOT') {
      return 'IS NOT';
    }

    if (operator === 'CONTAIN') {
      return 'CONTAINS';
    }

    if (operator === 'NOT_CONTAIN') {
      return 'DOES NOT CONTAIN';
    }

    if (operator === 'IS_SET') {
      return 'IS SET';
    }

    if (operator === 'IS_NOT_SET') {
      return 'IS NOT SET';
    }

    return 'IS';
  }

  getValueLabels(args: { value: any; filterInput: FilterInput }) {
    const {
      value,
      filterInput: { parameters },
    } = args;

    // In the case of a connection, value is an object

    // go ahead and make everything an array to make it consistent.
    const valueArr = Array.isArray(value) ? value : [value];

    // check to see if we have matching options for the givent input. These should be in parameters.options if
    // they are static options.

    const labels = valueArr.map((v) => {
      // In a few cases we might store the entire object.
      // we can attempt to see if the object has a name or id worth showing.

      if (v?.name) return v.name;
      if (v?.label) return v.label;
      if (v?.id) return v.id;

      // otherwise check to see if input has fixed options we can display the label of
      if (!Array.isArray(parameters?.options)) return v;

      // try to find a matching option otherwise just return the value.
      return (
        parameters?.options.find((opt) => {
          return opt.value === v || opt.label == v;
        })?.label ?? JSON.stringify(v)
      );
    });

    return labels;
  }

  // search param values are stored as JSON strings. Ino order to display actual values we need to parse
  parseFilterStringValue(args: { value?: string; filterInput?: FilterInput }): any {
    const { value, filterInput } = args;

    if (!value) return value;

    try {
      const parsed = JSON.parse(value);

      // if this worked no further massaging is required.
      return parsed;
    } catch (err: any) {
      // value is not valid JSON.
      if (typeof value === 'string') {
        if (filterInput?.type === 'array') {
          // Could be an old comma separated string. let's split.
          return value.split(',');
        }

        if (filterInput?.type === 'boolean') {
          return Boolean(value);
        }

        if (filterInput?.type === 'number') {
          return Number(value);
        }

        // No other ways to try and correct here that I can think of.
        return value;
      }
    }
  }

  matchOperatorAndValuePattern(searchParamValue: string): [string | undefined, string | undefined] {
    const [_match, operatorMatch, valueMatch] =
      searchParamValue?.match(/(IS|IS_NOT|CONTAIN|NOT_CONTAIN|IS_SET|IS_NOT_SET):(.*)/) ?? [];

    return [valueMatch, operatorMatch];
  }

  // Some data types support `key=IS_NOT:VALUE` pattern.
  separateValueAndOperator(args: { searchParamValue?: string }): [string | undefined, FilterInputOperator | undefined] {
    const { searchParamValue } = args;
    if (!searchParamValue) return [undefined, undefined];

    let value: string = searchParamValue;

    // Check to see if filter input is using operators.
    const [valueMatch, operatorMatch = 'IS'] = this.matchOperatorAndValuePattern(searchParamValue);
    if (valueMatch) value = valueMatch;

    return [value, operatorMatch as FilterInputOperator];
  }

  public updateFilter(args: { key: string; value: any; operator?: FilterInputOperator }) {
    // update filter has been overrided in this instance of the class.
    if (this.onUpdateFilter) {
      return this.onUpdateFilter(args);
    }

    const { key, value, operator } = args;
    const shouldRemove = this.checkShouldRemoveFilter(value, operator);

    // Check to make sure the filter shouldn't be removed;
    // value is null and no valid operator. Remove filter from search params.
    if (shouldRemove) return this.removeFilter(key);

    this.updateFilterSearchParam(args);
  }

  public updateFilters(updates: FilterUpdate[]) {
    if (this.onUpdateFilters) return this.onUpdateFilters(updates);

    console.log('No Default handler to handle multipile filter updates yet.');
  }

  // this won't update search params.
  // we're going to have a hook that does this all in one fell swoop.
  public updateFilterValue(args: {
    // this will be from the hooks state.
    key: string;
    value: any; // non json stringified values for the filter.
    operator?: FilterInputOperator;
  }) {
    // we know the value and can leave as is.
    const { key, value, operator } = args;

    // hopefully, we don't have any filter inputs that change without a filterInput definition.
    const filterInput = this.filterInputs?.find((i) => i.key === key);

    // get updated search param string for this filter.
    const searchParamValue = this.getUpdatedFilterSearchParam({ value, operator });

    // get the updated filterTagValue to display.
    const filterTagLabel = this.parseFilterTagLabel({ key, value, operator, filterInput });

    const filter: SearchParamFilter = {
      key,
      filterInput,
      value,
      operator,
      searchParamValue,
      filterTagLabel,
    };

    return filter;
  }

  getUpdatedFilterSearchParam(args: { value?: any; operator?: FilterInputOperator }) {
    const { value, operator } = args;

    const shouldRemove = this.checkShouldRemoveFilter(value, operator);

    if (shouldRemove) return null;

    let filterString = JSON.stringify(value);

    if (operator && ['IS_SET', 'IS_NOT_SET'].includes(operator)) {
      // operators in this list don't need/accept a value. their operator stands alone.
      filterString = `${operator}:`;
    } else if (filterString && operator) {
      filterString = `${operator}:${filterString}`;
    }

    return filterString;
  }

  // this might not be used if we end up updated search params in the hook.
  // or... may be the hook uses this method. that might be better.
  updateFilterSearchParam(args: { key: string; value?: any; operator?: FilterInputOperator }) {
    const { key } = args;

    // JSON stringify value
    let filterString = this.getUpdatedFilterSearchParam(args);

    // todo: make sure this debounced so we don't bog down the UI with lags.
    return this.searchParamsApi.setParameter(key, filterString);
  }

  // Update all search params with current filters.
  updateFilterSearchParams(args?: { filtersByKey?: FiltersByKey }) {
    const { filtersByKey = this.filtersByKey } = args ?? {};

    // format for search params api
    const paramConfigs: ParamConfig[] = Object.values(filtersByKey).map((filter) => {
      const paramConfig: ParamConfig = {
        name: filter.key,
        value: filter.searchParamValue ?? this.getUpdatedFilterSearchParam(filter),
      };
      return paramConfig;
    });

    this.searchParamsApi.setParameters(paramConfigs);
  }

  removeFilter(key: string) {
    if (this.onRemoveFilter) {
      console.log('this.onRemoveFilter...');
      return this.onRemoveFilter(key);
    } else {
      console.log('default removeFilter');
    }

    this.searchParamsApi.setParameter(key, null);
  }

  checkShouldRemoveFilter(val: any, operator?: string) {
    if (operator && ['IS_SET', 'IS_NOT_SET'].includes(operator)) {
      // value can be empty in this case.
      return false;
    }

    if (typeof val === 'string' && val.length === 0) {
      return true;
    }

    if (Array.isArray(val) && val.length === 0) {
      return true;
    }

    if (val === undefined || val === null) return true;

    return false;
  }
}

export function useSearchParamFilters(args: { filterInputs?: FilterInput[] }) {
  const { filterInputs } = args;
  const searchParamsApi = useSearchParams();

  const searchParamFilters = new SearchParamFilters({
    searchParamsApi,
    filterInputs,
  });

  return searchParamFilters;
}
