import { motion } from 'framer-motion';
import { Bar as VisxBar } from '@visx/shape';
import { Group as VisxGroup } from '@visx/group';
import { Text as VisxText } from '@visx/text';
import { CSSProperties, ComponentProps, RefObject, forwardRef } from 'react';

export interface ChartProps<Data> {
  width: number;
  height: number;
  data: Data;

  ref?: RefObject<SVGSVGElement>;
  style?: CSSProperties;
}

export const Group = motion(
  forwardRef<SVGGElement, ComponentProps<typeof VisxGroup>>(
    (props, ref) => <VisxGroup {...props} innerRef={ref} />,
  ),
);

export const Bar = motion(
  forwardRef<SVGRectElement, ComponentProps<typeof VisxBar>>(
    (props, ref) => <VisxBar {...props} innerRef={ref} />,
  ),
);

export const Text = motion(
  forwardRef<SVGTextElement, ComponentProps<typeof VisxText>>(
    (props, ref) => <VisxText {...props} innerTextRef={ref} />,
  ),
);

