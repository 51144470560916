import styled from 'styled-components';
import { Table } from 'antd';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import baseTheme from 'components/theme';
import { WidgetComponentProps } from '../index';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { ClientTaskCard } from 'components/client-tasks/client-task-card';
import { IClientTask } from 'components/client-tasks/types';

dayjs.extend(LocalizedFormat);

const Status = styled.div<{ status: string }>`
  font-weight: bold;
  text-transform: capitalize;
  color: ${(props) => (props.status === `pass` ? baseTheme.color.green : baseTheme.color.red)};
`;

const largeTableColumns = [
  {
    title: 'Device',
    dataIndex: 'name',
    key: 'name',
    render: (name: string) => <div style={{ fontWeight: 'bold' }}>{name}</div>,
  },
  {
    title: 'IP',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => <Status status={status}>{status === `pass` ? 'Healthy' : 'Needs Attention'}</Status>,
    align: 'center' as const,
    sorter: (a, b) => (a.status > b.status ? 1 : -1),
  },
  {
    title: 'Recommendation',
    dataIndex: 'recommendation',
    key: 'recommendation',
    align: 'center' as const,
    render: (recommendation) => (
      <div>
        {recommendation ? (
          <ClientTaskCard
            id={recommendation.id}
            shortId={recommendation.shortId}
            label={recommendation.label}
            type={recommendation?.type}
            showSchedule={false}
            showUsers={false}
            size="small"
          />
        ) : (
          <div>--</div>
        )}
      </div>
    ),
  },
];

const smallTableColumns = [
  {
    title: 'Device',
    dataIndex: 'name',
    key: 'name',
    render: (name: string) => <div style={{ fontWeight: 'bold' }}>{name}</div>,
  },
  {
    title: 'IP',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => <Status status={status}>{status === `pass` ? 'Healthy' : 'Needs Attention'}</Status>,
    align: 'center' as const,
    sorter: (a, b) => (a.status > b.status ? 1 : -1),
  },
];

type Data = {
  tableData: {
    recommendation: IClientTask;
    id: string;
    clientId: string;
    address: string;
    name: string;
    technologyName: string;
    lastLogTime: string;
    lastHealthCheckRunTime: string;
    status: `pass` | `fail`;
  }[];
};

const TechnologySourceDeviceWidget = (props: WidgetComponentProps<void, Data>) => {
  const { data, size = 1 } = props;

  if (!data)
    return (
      <SpinContainer>
        <Spin tip="Fetching Technology Source Devices...">
          <div className="content" />
        </Spin>
      </SpinContainer>
    );

  return (
    <div>
      <Table
        columns={size === 2 ? largeTableColumns : smallTableColumns}
        dataSource={data?.tableData}
        pagination={false}
        size="large"
        scroll={{ y: 375 }}
      />
    </div>
  );
};

export default TechnologySourceDeviceWidget;
