import { AliasToken, OverrideToken } from 'antd/es/theme/interface';
import * as NuIcon from 'components/nuspire/nu-icon';
import { createGlobalStyle } from 'styled-components';
import merge from 'lodash.merge';

export const themeModes = ['light', 'dark'] as const;
export type ThemeMode = typeof themeModes[number];

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: Helvetica Neue, Verdana, sans-serif;
    margin: 0;
  }

  a {
    color: ${(p) => p.theme.token.colorText};
    &:hover {
      color: ${(p) => p.theme.color.nuspireBlue};
    }
  }

  .ant-layout {
    min-height: 100vh !important;
    min-width: 0;
  }

  .ant-layout-header {
    padding: 0 !important;
    line-height: normal;
    height: auto;
  }

  .ant-layout-footer {
    padding: 0 !important;
  }

  .ant-layout.ant-layout-has-sider > .ant-layout,
  .ant-layout.ant-layout-has-sider > .ant-layout-content {
    overflow-x: initial !important;
  }

  .ant-notification {
    max-width: calc(100vw - 48px);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
  }
`;

// export const headingText1 = '#232A4E';
export const headingText2 = '#363636';
export const headingText3 = '#696969';

// nuspire color palette according to Feb 2022 Brand Guide
const brandColors = {
  black: '#000',
  nuspireBlue: '#3b78e6',
  slate: '#4b5e77',
  jade: '#00c46f',
  indigo: '#392080',
  breeze: '#c6d6f4',
  iceGrey: '#f2f2f2',
  sunYellow: '#f9e26d',
  orange: '#ed8c00',
  alertRed: '#eb3d2e',
} as const;

/*
 * this is where we override antd theme variables
 * @see {@link https://ant.design/docs/react/customize-theme}
 */
const token: Partial<AliasToken> = {
  fontFamily: 'Helvetica Neue, Verdana, sans-serif',

  colorPrimary: brandColors.nuspireBlue,
  colorPrimaryHover: brandColors.slate,
  colorError: brandColors.alertRed,
  colorErrorHover: brandColors.slate,
  colorSuccess: brandColors.jade,
  colorWarning: brandColors.orange,
  colorInfo: brandColors.nuspireBlue,
  colorLink: brandColors.nuspireBlue,
};

const componentOverrides: OverrideToken = {
  Typography: {
    titleMarginBottom: '0.5em',
  },
  Segmented: {
    itemSelectedBg: brandColors.nuspireBlue,
  },
};

export const baseTheme = {
  color: {
    primary: brandColors.nuspireBlue,
    primaryGray: brandColors.iceGrey,
    success: brandColors.jade,
    error: brandColors.alertRed,
    slate: brandColors.slate,
    breeze: brandColors.breeze,
    indigo: brandColors.indigo,

    headingText1: '#30475E',
    headingText2,

    // primary palette
    primaryBlue: brandColors.nuspireBlue,
    black: '#000',

    // secondary palette
    nuspireBlue: brandColors.nuspireBlue,
    nuspireBlue300: '#90B4E0',
    teal: '#18B0B7',
    teal300: '#74CFD3',

    // tertiary:
    slateBlue: '#30475E',
    slateBlue300: '#5982AA',
    slateBlue100: '#9AB3CC',
    softYellow: '#F7E05F',
    softYellow300: '#9AB3CC',
    green: brandColors.jade,
    green300: '#8FDEA1',
    orange: brandColors.orange,
    red: brandColors.alertRed,
    riverBlue: '#3498db',
    yellow: brandColors.sunYellow,
    mediumBlue: '#7fafea',

    purple: '#531DAB',
    purple300: '#8241EC',

    midnight: '#2c3e50',
    midnight300: '#34495e',

    // grays
    gray1: '#f2f4f8',
    gray2: '#e4e9f0',
    gray3: '#d9dee9',
    gray4: '#c8c4db',
    gray5: '#a1a1c2',
    gray6: '#30475E',
  },
  palette: {
    // palette only contains 4 colors in order of priority
    [`priority-4`]: [
      brandColors.alertRed,
      brandColors.orange,
      brandColors.sunYellow,
      brandColors.jade,
    ],
    [`common-chart`]: [
      brandColors.nuspireBlue,
      brandColors.slate,
      brandColors.breeze,
      brandColors.jade,
      brandColors.orange,
      brandColors.indigo,
      brandColors.sunYellow,
      'rgb(215,0,217)',
      'rgb(69,162,255)',
      'rgb(81,130,255)',
      'rgb(43,6,160)',
      'rgb(175,142,239)',
      'rgb(94,227,234)',
      'rgb(79,89,98)',
      'rgb(149,158,161)',
      'rgb(198,202,203)',
      'rgb(81,130,155)',
      'rgb(200,250,150)',
      'rgb(150,50,90)',
      'rgb(100,150,150)',
      'rgb(150,90,150)',
      'rgb(60,60,255)',
      'rgb(255,90,150)',
      'rgb(100,130,255)',
      'rgb(70,80,90)',
      'rgb(53,150,150)',
      'rgb(255,130,255)',
      'rgb(100,150,100)',
      'rgb(100,100,255)',
      'rgb(0,0,0)',
      'rgb(200,100,45)',
      'rgb(34,200,67)',
      'rgb(78,100,170)',
      'rgb(110,42,240)',
      'rgb(200,40,200)',
    ],
  },
  bp: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px',
  },
  bpInt: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
  },
  token,
  componentOverrides,
} as const;

export const lightModeTheme = merge({}, baseTheme, {
  token: {
    colorBgElevated: baseTheme.color.gray1,
  } satisfies Partial<AliasToken>,
  componentOverrides: {
    Segmented: {
      colorBgLayout: baseTheme.color.gray2,
      itemSelectedColor: 'white',
    },
  } satisfies OverrideToken,
});

export const darkModeTheme = merge({}, baseTheme, {
  token: {
  } satisfies Partial<AliasToken>,
});

interface ProductBrand {
  slug: string;
  name: string;
  color: string;
  icon: (props: NuIcon.CustomIconComponentProps) => JSX.Element;
}

export const productBrands: {
  [key: string]: ProductBrand;
} = {
  nsp: {
    name: 'Nuspire Security Program',
    slug: 'nsp',
    color: baseTheme.color.primary,
    icon: NuIcon.NspIcon,
  },
  tmt: {
    name: 'Threats',
    slug: 'tmt',
    color: baseTheme.color.red,
    icon: NuIcon.ThreatIcon,
  },
  'case-management': {
    name: 'Case Management',
    slug: 'case-management',
    color: baseTheme.color.green,
    icon: NuIcon.CaseManagementIcon,
  },
  cxp: {
    name: 'Cyber X Platform',
    slug: 'cxp',
    color: baseTheme.color.orange,
    icon: NuIcon.CxpIcon,
  },
  insights: {
    name: 'Insights',
    slug: 'insights',
    color: baseTheme.color.slateBlue,
    icon: NuIcon.InsightsIcon,
  },
};

