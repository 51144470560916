import React, { createContext, useState } from 'react';

import BehindUpgradeModal from 'components/subscription/upgrade-modal';

interface SubscriptionApi {
  isUpgradeModalOpen: boolean;
  setIsUpgradeModalOpen: (open: boolean) => void;
}

export const SubscriptionContext = createContext<SubscriptionApi>({
  isUpgradeModalOpen: false,
  setIsUpgradeModalOpen: () => null,
});

interface SubscriptionContextProviderProps {
  children: React.ReactNode;
}

function SubscriptionContextProvider(props: SubscriptionContextProviderProps) {
  const { children } = props;
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState<boolean>(false);

  const api: SubscriptionApi = {
    isUpgradeModalOpen,
    setIsUpgradeModalOpen,
  };

  return (
    <SubscriptionContext.Provider value={api}>
      <BehindUpgradeModal />
      {children}
    </SubscriptionContext.Provider>
  );
}

export default SubscriptionContextProvider;
