import { EditOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, ConfigProvider, Menu, Tooltip, message } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { GetMenuOrderQuery, UpdateMenuOrderMutation } from '../../../../types/graph-codegen/graph-types';
import { useClientIdentifier } from '../../../../utils/react-hooks/use-client-identifier';
import { useAuthContext } from '../../../auth-context';
import { IClientContext, useClientContext } from '../../../client-context-provider';
import { Features, isChildOf, isProgramClient, useFeatureFlag } from '../../../feature-flags';
import { PartnerLogo } from '../../../logos';
import { WideSpace } from '../../../nuspire/wide-space';
import { DashboardModals } from './dashboard-menu';
import { IDashboardMenuContext, useDashboardMenuContext } from './dashboard-menu-context';
import { useEntitlementsSubmenuItems } from './entitlements-submenu';
import { useViewableAdminKeys } from './get-admin-menu-items';
import {
  GetMainMenuItemsArgs,
  LinkedMenuItem,
  MenuItem,
  MenuItemFilters,
  RootItemKey,
  getMainMenuItems,
  getRawMainMenuItems,
  isLinkedMenuItem,
  rootItemKeys,
} from './get-main-menu-items';
import { MainMenuReorderListView } from './main-menu-reorder-list';
import { useNspDomainsSubmenuItems } from './nsp-domains-submenu';
import { ClientType, IAuthContext } from 'types';

const GET_MENU_ORDER = gql`
  query GetMenuOrder {
    currentUser {
      id
      settings
    }
  }
`;

const UPDATE_MENU_ORDER = gql`
  mutation UpdateMenuOrder($settings: JSONObject!) {
    updateUserSettings(settings: $settings) {
      id
      settings
    }
  }
`;

type KeyPath = [RootItemKey, ...string[]] | [];
function findActiveKeyPath(items: MenuItem[], pathname: string, parentKeys?: KeyPath): KeyPath {
  let activeKeyPath: KeyPath = [];
  items.find((item) => {
    if (!isLinkedMenuItem(item)) return false;
    const keyPath = [...(parentKeys ?? []), item.key] as KeyPath;

    if (item.children) {
      const foundKeyPath = findActiveKeyPath(item.children, pathname, keyPath);
      if (foundKeyPath.length > 0) {
        activeKeyPath = foundKeyPath;
        return true;
      }
    }

    if (item.to) {
      const activeExp = new RegExp(`^${item.to}`);
      const isActive = activeExp.test(pathname);

      if (isActive) {
        activeKeyPath = keyPath;
        return true;
      }
    }

    return false;
  });

  return activeKeyPath;
}

const PreDashboardMenu = styled(Menu)``;
const BEGIN_EDITING_CLASSNAME = 'begin-editing';
const FINISH_EDITING_CLASSNAME = 'finish-editing';

const MenuViewWrapper = styled.div`
  padding: 16px 0;
  height: calc(100% - var(--footer-height));
  overflow: auto;
  transition:
    border-color 0.3s,
    background 0.3s,
    padding 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

  &&& {
    &.${BEGIN_EDITING_CLASSNAME} {
      .ant-menu,
      .ant-collapse {
        .ant-menu-submenu,
        .ant-menu-item {
          animation: editing 250ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
        }
      }
    }
    &.${FINISH_EDITING_CLASSNAME} {
      .ant-menu,
      .ant-collapse {
        .ant-menu-submenu,
        .ant-menu-item {
          animation: editing 250ms cubic-bezier(0.18, 0.67, 0.6, 1.22) reverse;
        }
      }
    }
    .ant-menu,
    .ant-collapse {
      .ant-menu-submenu,
      .ant-menu-item {
        animation-fill-mode: both !important;
      }
    }

    ${PreDashboardMenu} {
      & > :first-child {
        margin-block-start: 0 !important;
      }
    }
  }

  @keyframes editing {
    0% {
      margin-block: 4px;
    }
    100% {
      margin-block: 14px;
    }
  }
`;

interface MainMenuViewProps extends GetMainMenuItemsArgs {
  dashboardMenuContext: IDashboardMenuContext;
  clientId: string;
  pathname?: string;
  onDashboardSearchClick?: () => void;
  onNewDashboardClick?: () => void;
  beginEditing?: boolean;
  finishEditing?: boolean;
}

export function MainMenuView(props: MainMenuViewProps) {
  const {
    dashboardMenuContext,
    clientId,
    pathname,
    onNewDashboardClick,
    onDashboardSearchClick,
    beginEditing,
    finishEditing,
    ...getItemsArgs
  } = props;

  const { preDashboardItems, postDashboardItems } = getMainMenuItems({ clientId, ...getItemsArgs });
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    // if route is updated and nothing is open, lets find a drawer to open
    if (openKeys.length === 0) {
      openRouteDrawer();
    }

    selectRouteKeys();
  }, [pathname]);

  useEffect(() => {
    if (beginEditing) {
      setOpenKeys([]);
      setSelectedKeys([]);
    }
  }, [beginEditing]);

  const allRawItems = getRawMainMenuItems({ clientId, ...getItemsArgs });

  const openRouteDrawer = () => {
    if (!pathname) return;
    const activeKeyPath = findActiveKeyPath(allRawItems, pathname);
    const rootKey = activeKeyPath[0];
    if (rootKey) {
      setOpenKeys([rootKey]);
    }
    setSelectedKeys(activeKeyPath);
  };

  const selectRouteKeys = () => {
    if (!pathname) return;
    const activeKeyPath = findActiveKeyPath(allRawItems, pathname);
    setSelectedKeys(activeKeyPath);
  };

  const onOpenChange = (strKeys: string[]) => {
    const keys = strKeys as RootItemKey[];
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootItemKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const inlineIndent = 16;

  const wrapperClassNames: string[] = [];
  if (beginEditing) {
    wrapperClassNames.push(BEGIN_EDITING_CLASSNAME);
  }

  if (finishEditing) {
    wrapperClassNames.push(FINISH_EDITING_CLASSNAME);
  }

  return (
    <MenuViewWrapper className={wrapperClassNames.join(' ')}>
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              lineType: 'none',
              itemMarginInline: 4,
            },
          },
        }}
      >
        <Menu
          items={postDashboardItems}
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          onOpenChange={onOpenChange}
          inlineIndent={inlineIndent}
          mode="inline"
        />
      </ConfigProvider>
    </MenuViewWrapper>
  );
}

const Footer = styled(WideSpace)`
  position: absolute;
  bottom: 0;
  height: var(--footer-height);
  padding: 0 20px;

  border-top: 1px solid ${(p) => p.theme.token.colorBorder};

  flex-direction: row;
  align-items: center;
  justify-content: end;

  .anticon {
    vertical-align: middle;
  }
`;

interface MainMenuFooterProps {
  isEditing?: boolean;
  beginEditing?: boolean;
  onEditClick?: () => void;
  onSaveClick?: () => void;
  onCancelClick?: () => void;
}

export function MainMenuFooter({
  isEditing,
  beginEditing,
  onEditClick,
  onSaveClick,
  onCancelClick,
}: MainMenuFooterProps) {
  return (
    <Footer>
      {!isEditing && (
        <Tooltip title="Reorder menu items" placement="topLeft">
          <Button type="text" icon={<EditOutlined />} onClick={onEditClick} disabled={beginEditing} />
        </Tooltip>
      )}
      {isEditing && <Button onClick={onCancelClick}>Cancel</Button>}
      {isEditing && (
        <Button type="primary" onClick={onSaveClick}>
          Save
        </Button>
      )}
    </Footer>
  );
}

const MenuWrapper = styled.div`
  --footer-height: 48px;

  height: 100%;
  position: relative;
  overflow: hidden;
  border-right: 1px solid ${(p) => p.theme.token.colorBorder};
`;

export function MainMenu() {
  const clientContext = useClientContext();
  const { user } = useAuthContext();
  const { pathname } = useLocation();
  const clientId = clientContext.clientId ?? user?.clientId;

  // Fetch entitlements by clientid and build menu items out of them.
  const { submenuItems: entitlementItems } = useEntitlementsSubmenuItems();
  const { submenuItems: nspDomainItems } = useNspDomainsSubmenuItems();

  const dashboardMenuContext = useDashboardMenuContext();
  const clientIsProgramClient = isProgramClient({ clientContext });
  const isNECESEnabled = useFeatureFlag(Features.NECES);
  const isNutronEnabled = useFeatureFlag(Features.Nutron);

  const [isDashboardSearchModalOpen, setIsDashboardSearchModalOpen] = useState(false);
  const [isNewDashboardModalOpen, setIsNewDashboardModalOpen] = useState(false);
  const [isDashboardPath, setIsDashboardPath] = useState(false);

  const [menuItemsForOrdering, setMenuItemsForOrdering] = useState<NonNullable<LinkedMenuItem>[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [hasBegunEditing, setHasBegunEditing] = useState(false);
  const [finishEditing, setFinishEditing] = useState(false);

  const { data: userSettingsData } = useQuery<GetMenuOrderQuery>(GET_MENU_ORDER);
  const [updateUserSettings] = useMutation<UpdateMenuOrderMutation>(UPDATE_MENU_ORDER);

  const viewableAdminKeys = useViewableAdminKeys();
  const serviceNowAccountId = useClientIdentifier({
    clientIdentifiers: clientContext?.client?.clientIdentifiers,
    type: 'serviceNowAccountId',
  })?.value;

  const filters: MenuItemFilters = {
    viewableAdminKeys,
    showManagedClients: clientContext?.client?.isMultiTenancyEnabled,
    showEscalations: !clientIsProgramClient && serviceNowAccountId !== undefined,
    showNECES: isNECESEnabled,
    showNutron: isNutronEnabled,
  };

  const [menuOrder, setMenuOrder] = useState<RootItemKey[]>([]);

  useEffect(() => {
    setMenuItemsForOrdering(
      getRawMainMenuItems({ clientId: '', filters, menuOrder, entitlementItems }).filter(isLinkedMenuItem),
    );
  }, [menuOrder, entitlementItems]);

  useEffect(() => {
    const dashboardPathExp = new RegExp(`^/${clientId}/dashboard/`);
    const match = dashboardPathExp.test(pathname);
    setIsDashboardPath(match);

    if (!match) {
      dashboardMenuContext.setCurrentDashboardId(undefined);
    }
  }, [pathname]);

  /**
   * menuOrder is saved on user.settings as a stringified object.
   * this effect simply parses the object and sets the local menuOrder state as a result.
   * menuOrder is then used
   */
  useEffect(() => {
    const menuOrderJson = userSettingsData?.currentUser?.settings?.menuOrder;

    try {
      setMenuOrder(menuOrderJson ? JSON.parse(menuOrderJson) : []);
    } catch (e) {
      console.error('Failed to JSON.parse() `menuOrderJson`', menuOrderJson);
    }
  }, [userSettingsData]);

  if (!clientId) return null;

  const beginEditing = () => {
    setHasBegunEditing(true);
    setIsEditing(false);
    setFinishEditing(false);

    setTimeout(() => {
      setIsEditing(true);
      setHasBegunEditing(false);
    }, 300);
  };

  const cancelEditing = () => {
    setHasBegunEditing(false);
    setIsEditing(false);
    setFinishEditing(true);

    setTimeout(() => setFinishEditing(false), 300);
  };

  const saveMenuOrder = async () => {
    const orderedMenuKeys = menuItemsForOrdering.map(({ key }) => key as RootItemKey);

    let stringifiedKeys: string | undefined;
    try {
      stringifiedKeys = JSON.stringify(orderedMenuKeys);
    } catch (e) {
      console.error('Failed to JSON.stringify() `orderedMenuKeys`', orderedMenuKeys);
    }

    if (!stringifiedKeys || !user) return;

    try {
      await updateUserSettings({
        variables: {
          settings: {
            menuOrder: stringifiedKeys,
          },
        },
        optimisticResponse: {
          updateUserSettings: {
            id: user.id,
            __typename: 'User',
            settings: {
              ...user.settings,
              menuOrder: stringifiedKeys,
            },
          },
        },
      });
    } catch (e) {
      message.error('There was an issue sorting menu items. Refresh and try again.');
    }

    cancelEditing();
  };

  return (
    <MenuWrapper>
      <DashboardModals
        searchModalOpen={isDashboardSearchModalOpen}
        newDashboardModalOpen={isNewDashboardModalOpen}
        onSearchModalClose={() => setIsDashboardSearchModalOpen(false)}
        onNewDashboardModalClose={() => setIsNewDashboardModalOpen(false)}
      />

      {isDashboardPath && <PartnerLogo clientId={clientId} />}

      {!isEditing && (
        <MainMenuView
          clientId={clientId}
          pathname={pathname}
          dashboardMenuContext={dashboardMenuContext}
          filters={filters}
          entitlementItems={entitlementItems}
          nspDomainItems={nspDomainItems}
          onDashboardSearchClick={() => setIsDashboardSearchModalOpen(true)}
          onNewDashboardClick={() => setIsNewDashboardModalOpen(true)}
          beginEditing={hasBegunEditing}
          finishEditing={finishEditing}
          menuOrder={menuOrder}
        />
      )}
      {isEditing && (
        <MainMenuReorderListView
          menuItems={menuItemsForOrdering}
          onReordered={(items) => setMenuItemsForOrdering(items)}
        />
      )}
      <MainMenuFooter
        isEditing={isEditing}
        beginEditing={hasBegunEditing}
        onEditClick={beginEditing}
        onCancelClick={cancelEditing}
        onSaveClick={saveMenuOrder}
      />
    </MenuWrapper>
  );
}
