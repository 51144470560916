import { Divider, Tooltip, Typography } from 'antd';
import { Link, NuCardBody } from 'components/nuspire';
import { useEffect } from 'react';
import styled from 'styled-components';
import { mixpanelTrack } from 'utils/mixpanel/mixpanel-track';
import { useClientContext } from 'components/client-context-provider';
import { ServiceNowActivePublicationsQuery } from 'types/graph-codegen/graph-types';
import { WidgetComponentProps } from '../index';
import dayjs from 'dayjs';

const NewsDivider = styled(Divider)`
  margin: 8px !important;
`;

type NewsItemProps = {
  clientId: string | undefined;
  number: string;
  subText: JSX.Element;
  title: string;
};

function NewsItem(props: NewsItemProps) {
  const { clientId, number, subText, title } = props;

  return (
    <>
      <li>
        <div>
          <Link
            onClick={() => mixpanelTrack('open-threat-brief', { title })}
            to={`/${clientId}/threats/threat-brief/${number}`}
          >
            <div style={{ marginBottom: '1rem' }}>
              <Typography.Text strong style={{ color: 'inherit' }}>
                {title}
              </Typography.Text>
              <br />
              <small>{subText}</small>
            </div>
          </Link>
        </div>
      </li>
      <NewsDivider />
    </>
  );
}

type Publications = NonNullable<
  ServiceNowActivePublicationsQuery['serviceNowActivePublications']
>['activePublications'];

interface ThreatBriefWidgetViewProps {
  clientId?: string;
  publications: Publications;
}

export function ThreatBriefWidgetView({ clientId, publications }: ThreatBriefWidgetViewProps) {
  return (
    <div data-intercom-target="threat-brief">
      <NuCardBody>
        <ul style={{ paddingLeft: 0, listStyle: 'none' }}>
          {publications.map((item) => {
            const subText = (
              <span>
                {item?.number} ••{' '}
                <Tooltip title={dayjs.utc(item?.publish_date).local().format('MMMM Do YYYY, h:mm:ss a')}>
                  Published {dayjs.utc(item?.publish_date).local().fromNow()}
                </Tooltip>
              </span>
            );

            return (
              <NewsItem
                clientId={clientId}
                key={item?.number}
                number={item?.number ?? ''}
                title={item?.short_description ?? ''}
                subText={subText}
              />
            );
          })}
        </ul>

        <Link to={`/${clientId}/threats/threat-brief`} mode="plain">View more</Link>
      </NuCardBody>
    </div>
  );
}

export default function ThreatBriefsWidget(props: WidgetComponentProps<any, any>) {
  const { setSubAction, data } = props;
  const { clientId } = useClientContext();

  useEffect(() => {
    if (setSubAction) {
      setSubAction(<Link to={`/${clientId}/threats/threat-brief`} mode="plain">View All</Link>);
    }
  }, []);

  const publications = data?.activePublications ?? [];

  return <ThreatBriefWidgetView clientId={clientId} publications={publications} />;
}
