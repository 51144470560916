import { gql, useQuery } from '@apollo/client';
import { Row } from 'antd';
import { ISentinelOneItemCount, CountsDoughnut, IDoughnutMapping } from '../sentinelone-doughnut';
import { WidgetDefinition, WidgetRendererProps } from 'components/dashboard/widget-definitions/types';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import baseTheme from 'components/theme';
import { EmptyState, NuCardContent } from 'components/nuspire';
import ApiTokenError from '../api-token-error';

const SENTINELONE_POTENTIALLY_VULNERABLE_APPLICATIONS_COUNT_QUERY = gql`
  query SentinelOnePotentiallyVulnerableApplicationsCountWidget($connectionId: String!) {
    clientConnection(id: $connectionId) {
      id
      publicConnectionDataJson
      sentinelOnePotentiallyVulnerableApplicationsCount {
        key
        title
        values {
          count
          title
          value
        }
      }
    }
  }
`;

const POTENTIALLY_VULNERABLE_APPLICATIONS_MAP: IDoughnutMapping = {
  low: {
    label: 'Low',
    backgroundColor: baseTheme.color.green,
  },
  medium: {
    label: 'Medium',
    backgroundColor: baseTheme.color.softYellow,
  },
  high: {
    label: 'High',
    backgroundColor: baseTheme.color.orange,
  },
  critical: {
    label: 'Critical',
    backgroundColor: baseTheme.color.red,
  },
  none: {
    label: 'No known risk',
    backgroundColor: baseTheme.color.gray5,
  },
};

function SentinelOnePotentiallyVulnerableApplicationsCountWidgetComponent(props: WidgetRendererProps) {
  const connectionId = props?.configuration?.connectionId ?? null;
  const { data, loading, error } = useQuery(SENTINELONE_POTENTIALLY_VULNERABLE_APPLICATIONS_COUNT_QUERY, {
    variables: {
      connectionId,
    },
  });
  const potentiallyVulnerableApplicationsCount = data?.clientConnection
    ?.sentinelOnePotentiallyVulnerableApplicationsCount as ISentinelOneItemCount;

  if (!data && loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  if (potentiallyVulnerableApplicationsCount) {
    return (
      <Row justify="center">
        <CountsDoughnut
          counts={potentiallyVulnerableApplicationsCount.values}
          map={POTENTIALLY_VULNERABLE_APPLICATIONS_MAP}
        />
      </Row>
    );
  }

  const errorMsg = error?.message;
  if (errorMsg === 'Your API Key has expired.' || errorMsg === 'API token does not exist') {
    return <ApiTokenError connectionId={connectionId} errorMsg={errorMsg} />;
  }

  return (
    <NuCardContent>
      <EmptyState>Unable to fetch potentially vulnerable applications count.</EmptyState>
    </NuCardContent>
  );
}

const SentinelOnePotentiallyVulnerableApplicationsCountWidget: WidgetDefinition = {
  name: 'SentinelOne Potentially Vulnerable Applications Count',
  description: 'SentinelOne Potentially Vulnerable Applications Count',
  slug: 'sentinelone-potentially-vulnerable-applications-count',
  component: SentinelOnePotentiallyVulnerableApplicationsCountWidgetComponent,
  connectorSlug: 'sentinelone',
};

export default SentinelOnePotentiallyVulnerableApplicationsCountWidget;
