import { UserDeleteOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { message } from 'antd';
import MutationButton from 'components/mutation-button';
import { P } from 'components/shared-components';
import { UserActionButtonProps } from 'types';
import { formatUserName } from 'utils/users';

const FORCE_DELETE_USER_MUTATION = gql`
  mutation ForceDeleteUser($userId: String!) {
    forceDeleteUser(userId: $userId)
  }
`;

type ForceDeleteUserData = {
  forceDeleteUser?: boolean;
};

export default function ForceDeleteUserButton(props: UserActionButtonProps) {
  const { onSuccess, user, ...buttonProps } = props;

  const userName = formatUserName(user);
  const { id: userId } = user;
  const variables = {
    userId,
  };
  const modalTitle = (
    <P>
      Are you sure you want to <strong>completely</strong> delete {userName}?
    </P>
  );

  const onCompleted = (data: ForceDeleteUserData) => {
    if (data.forceDeleteUser !== undefined) {
      const { forceDeleteUser: result } = data;

      // Force delete was unsuccessful.
      if (!result) {
        onError();
        return;
      }

      // Force delete was successful.
      message.success(`${userName} has been completely deleted!`);
      if (onSuccess) onSuccess();
    }
  };

  const onError = (error?: any) => {
    // Display the error message if we have one.
    const errorMessage = `There was an error completely deleting ${userName}. ${
      error ? ` Error: ${error.message}` : `.`
    }`;

    message.error(errorMessage);
  };

  return (
    <MutationButton
      buttonIcon={<UserDeleteOutlined />}
      danger
      modalOkType="danger"
      modalTitle={modalTitle}
      modalContent="This action cannot be undone."
      mutation={FORCE_DELETE_USER_MUTATION}
      mutationVariables={variables}
      onCompleted={onCompleted}
      onError={onError}
      tooltipOverlay="Force Delete User"
      {...buttonProps}
    />
  );
}
