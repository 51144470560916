import { EyeOutlined } from '@ant-design/icons';
import { gql, useQuery } from '@apollo/client';
import { message } from 'antd';
import { ButtonType } from 'antd/es/button';
import { useAuthContext } from 'components/auth-context';
import { useAuthorize } from 'components/authorization';
import { CLIENT_CONTEXT } from 'components/client-context-provider';
import MutationButton from 'components/mutation-button';
import { Access, IUser } from 'types';
import { clearImpersonatedAccessToken, setImpersonatedAccessToken } from 'utils/access-tokens';

export const USER_IMPERSONATION_STARTED_EVENT = 'user-impersonation-started';
export const USER_IMPERSONATION_STOPPED_EVENT = 'user-impersonation-stopped';

export const dispatchUserImpersonatedEvent = (userId: string): void => {
  const startEvent = new CustomEvent(USER_IMPERSONATION_STARTED_EVENT, { detail: { userId } });
  document.dispatchEvent(startEvent);
};

export const dispatchUserImpersonationStoppedEvent = (): void => {
  const stopEvent = new CustomEvent(USER_IMPERSONATION_STOPPED_EVENT);
  document.dispatchEvent(stopEvent);
};

export const stopUserImpersonation = (whenDone?: () => void): void => {
  clearImpersonatedAccessToken();
  message.success('Impersonation has been stopped. You are now authenticated as yourself.');
  dispatchUserImpersonationStoppedEvent();
  whenDone?.();
};

export const IMPERSONATE_USER_MUTATION = gql`
  mutation ImpersonateUser($userId: String!) {
    impersonateUser(userId: $userId) {
      accessToken
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

type UserImpersonationData = {
  accessToken: string;
  user: IUser;
};

export type UserImpersonationButtonProps = {
  buttonType?: ButtonType;
  children?: any;
  style?: any;
  user: IUser;
  onSuccess?: (args: { accessToken: string; user: IUser }) => void;
  onError?: (err?: any) => void;
};

export function UserImpersonationButton(props: UserImpersonationButtonProps) {
  const { user, onSuccess, onError, buttonType = 'link', children, style, ...buttonProps } = props;
  const { user: currentUser, refresh: refreshAuthContext } = useAuthContext();
  const { data } = useQuery(CLIENT_CONTEXT, { variables: { clientId: currentUser?.clientId } });
  const authorize = useAuthorize({ effectivePermissions: data?.getClientById?.effectivePermissions });
  const { authorized } = authorize({
    requiredPermissions: {
      userImpersonation: Access.execute,
    },
  });

  const getDisplayName = (u: IUser): string => {
    return u.email || `${u.firstName} ${u.lastName}`;
  };

  const handleOnCompleted = (d: any) => {
    const { accessToken, user: impersonatedUser } = d.impersonateUser as UserImpersonationData;
    if (!accessToken) {
      handleOnError();
      return;
    }

    // save this (cleared on logout or manually stopping impersonation)
    setImpersonatedAccessToken(accessToken);
    refreshAuthContext({ redirectToFn: (cId: string) => `/${cId}/home` });

    const impersonatedUserMessage = `${getDisplayName(impersonatedUser)} is now being impersonated!`;

    message.success(impersonatedUserMessage);

    dispatchUserImpersonatedEvent(impersonatedUser.id);

    if (onSuccess) {
      onSuccess({ accessToken, user: impersonatedUser });
    }
  };

  const handleOnError = (error?: any) => {
    const errorMessage = `There was an error impersonating ${getDisplayName(user)}. ${
      error ? ` Error: ${error.message}` : `.`
    }`;

    clearImpersonatedAccessToken();
    console.error(errorMessage);
    message.error(errorMessage);

    if (onError) {
      onError({ error, message: errorMessage });
    }
  };

  return (
    <MutationButton
      authorized={authorized}
      buttonIcon={<EyeOutlined />}
      buttonType={buttonType}
      danger
      modalTitle={`Are you sure you want to impersonate ${getDisplayName(user)}?`}
      mutation={IMPERSONATE_USER_MUTATION}
      mutationVariables={{ userId: user.id }}
      onCompleted={handleOnCompleted}
      onError={handleOnError}
      tooltipOverlay="Impersonate User"
      style={{ ...style }}
      unauthorizedMessage="You do not have the required permissions to impersonate users. Requires 'User Impersonation: Execute' access."
      {...buttonProps}
    >
      {children}
    </MutationButton>
  );
}
