
export default () => {
  return (
    <svg
      width="2em"
      height="1em"
      viewBox="0 0 294 294"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Qualys-Horizontal</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <polygon id="path-1" points="21.884 22.3389 21.884 0.6959 0.4676 0.6959 0.4676 22.3389 21.884 22.3389" />
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Qualys-Horizontal">
          <g id="Logo-Horizontal">
            <path
              d="M123.0736,0.0004 C203.2306,0.0004 243.5346,42.8884 243.5346,42.8884 C243.5346,42.8884 248.1426,74.6344 245.2016,138.5554 C240.7586,235.1154 123.1016,293.1054 123.0916,293.1114 C123.0816,293.1164 5.4256,235.1154 0.9816,138.5554 C-1.9604,74.6344 2.6486,42.8884 2.6486,42.8884 C2.6486,42.8884 42.9156,0.0004 123.0736,0.0004"
              id="Fill-1"
              fill="#ED2E26"
            />
            <path
              d="M62.9242,126.9296 C62.9242,92.7876 89.9152,65.0116 123.0912,65.0116 C156.2952,65.0116 183.3082,92.0246 183.3082,125.2276 C183.3082,151.7356 160.5222,173.5436 142.9602,184.7506 C149.7732,188.6376 164.8092,192.7196 179.2052,194.0386 C200.2272,177.7696 213.0482,152.6206 213.0482,125.2276 C213.0482,75.6256 172.6942,35.2716 123.0912,35.2716 C73.4892,35.2716 33.1352,75.6256 33.1352,125.2276 C33.1352,155.1856 45.6092,181.7986 69.2072,202.1926 C91.2702,221.2576 123.5432,233.9836 160.2882,240.0086 C168.2492,235.6096 181.8642,222.6216 186.9702,215.0186 C156.2812,211.0716 127.2372,203.5876 107.7672,193.3736 C78.0122,177.7656 62.9242,155.4096 62.9242,126.9296"
              id="Fill-19"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
