import { gql, useMutation, useQuery } from '@apollo/client';
import { Switch } from 'antd';
import { ADD_CLIENT_FEATURE_FLAG } from './add-client-feature-button';
import { REMOVE_CLIENT_FROM_FEATURE } from './feature-client-list';
import { message } from 'antd';

const CLIENT_FEATURE_SWITCH = gql`
  query ClientFeatureSwitch($clientId: String!, $slug: String!) {
    clientFeatureFlag(clientId: $clientId, slug: $slug) {
      id
    }
  }
`;

export function ClientFeatureSwitch(props: { clientId: string; slug: string }) {
  const { clientId, slug } = props;
  const { data, loading: fetching } = useQuery(CLIENT_FEATURE_SWITCH, { variables: { clientId, slug } });
  const enabled = Boolean(data?.clientFeatureFlag?.id);

  // Mutations
  const [addFeature, { loading: adding }] = useMutation(ADD_CLIENT_FEATURE_FLAG);
  const [removeFeature, { loading: removing }] = useMutation(REMOVE_CLIENT_FROM_FEATURE);

  const addFeatureToClient = async () => {
    try {
      await addFeature({
        variables: {
          clientId,
          slug,
        },
        refetchQueries: ['ClientFeatureSwitch'],
      });
    } catch (err: any) {
      message.error(`Failed to Add Feature`);
    }
  };

  const removeFeatureFromClient = async () => {
    try {
      await removeFeature({
        variables: {
          clientId,
          slug,
        },
        refetchQueries: ['ClientFeatureSwitch'],
      });
    } catch (err: any) {
      message.error(`Failed to remove feature from client.`);
    }
  };

  // Handle Change
  const handleChange = async (checked: boolean) => {
    if (checked) return addFeatureToClient();

    return removeFeatureFromClient();
  };

  const loading = fetching || adding || removing;

  return <Switch checked={enabled} loading={loading} disabled={loading} onChange={handleChange} />;
}
