import { gql, useQuery } from '@apollo/client';

export const GET_INDUSTRIES = gql`
  query GetIndustry {
    industries {
      name
      id
    }
  }
`;

export type Industry = {
  id: string;
  name: string;
};

export type IndustryQuery = {
  industries: Industry[];
};

export function useIndustries() {
  return useQuery<IndustryQuery>(GET_INDUSTRIES);
}

export function alphabetizeIndustries(industries?: Industry[]): Industry[] {
  return industries?.slice().sort((a, b) => a.name.localeCompare(b.name)) ?? [];
}
