import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { Modal, Form, Select, message, Descriptions } from 'antd';
import { NuButton } from 'components/nuspire';
import { useClientContext } from 'components/client-context-provider';
import { useAuthContext } from 'components/auth-context';
import { Access } from 'types';
import { useClientIdentifier } from 'utils/react-hooks/use-client-identifier';
import { AccountContactsQuery } from 'types/graph-codegen/graph-types';
import { useEffect, useState } from 'react';
import { IServiceNowContact } from './case-detail';
import { ACCOUNT_CONTACTS_QUERY } from 'components/case-management/open-case';
import { IAccountContact } from 'types';

export type UpdateCaseContactArgs = {
  clientId?: string;
  caseNumber: string;
  contactId: string;
};

export const UPDATE_CASE_CONTACT_MUTATION = gql`
  mutation UpdateCaseContact($clientId: String, $caseNumber: String!, $contactId: String!) {
    updateCaseContact(clientId: $clientId, caseNumber: $caseNumber, contactId: $contactId) {
      number
      sys_id
    }
  }
`;

export type UpdateCaseContactModalProps = {
  accountId?: string;
  caseNumber: string;
  contact?: IServiceNowContact;
  close: Function;
  refetch?: Function;
  visible: boolean;
};

export function UpdateCaseContactModal(props: UpdateCaseContactModalProps) {
  const { authorize, client, clientId } = useClientContext();
  const serviceNowAccountId = useClientIdentifier({
    clientIdentifiers: client?.clientIdentifiers,
    type: 'serviceNowAccountId',
  })?.value;

  const { user: authUser } = useAuthContext();
  const { authorized: canWriteCaseManagement } = authorize({
    requiredPermissions: { caseManagement: Access.write },
  });

  const canUpdateCaseContact = !!authUser?.serviceNowUserId && !!serviceNowAccountId && canWriteCaseManagement;
  const { contact, caseNumber, close, refetch, visible } = props;

  const [form] = Form.useForm();
  const [updateCase, { data: updateCaseData, error: updateCaseError, loading }] =
    useMutation(UPDATE_CASE_CONTACT_MUTATION);

  const [contacts, setContacts] = useState<IAccountContact[]>([]);
  const [queryContacts, { data: contactsData, loading: contactsLoading, error: contactsError }] =
    useLazyQuery<AccountContactsQuery>(ACCOUNT_CONTACTS_QUERY);
  const accountContacts = contactsData?.accountContacts;

  useEffect(() => {
    if (!accountContacts) return;

    setContacts(accountContacts);
  }, [accountContacts]);

  useEffect(() => {
    queryContacts({ variables: { clientId } });
  }, [clientId]);

  useEffect(() => {
    if (!contact || !contacts.length) return;

    const serviceNowUserId = contact.sys_id!;
    if (contacts.find((i) => i.id === serviceNowUserId)) {
      form.setFieldsValue({ contact: serviceNowUserId });
    }
  }, [contact, contacts]);

  const onFinish = async (values: { contactId: string }) => {
    if (!canUpdateCaseContact) {
      if (!canWriteCaseManagement) {
        message.error('You do not have the required permissions to update a case.');
      } else if (!authUser?.serviceNowUserId) {
        message.error('Your user is not linked with ServiceNow. Unable to update case.');
      } else if (!serviceNowAccountId) {
        message.error('Your client is not linked with ServiceNow. Unable to update case.');
      } else {
        message.error('Unable to update case.');
      }
      return;
    }

    const { contactId } = values;
    await updateCase({
      variables: {
        clientId,
        caseNumber,
        contactId,
      },
    });

    close(false);

    if (refetch) {
      refetch();
    }

    if (updateCaseData) {
      message.success(`Case Contact Updated!`);
    }

    if (updateCaseError) {
      message.error('There was an error updating this case.');
    }
  };

  return (
    <>
      {!contactsLoading && (
        <Modal open={visible} title="Update Case Contact" onCancel={() => close(false)} footer={null}>
          <Form onFinish={onFinish}>
            {contact && (
              <Descriptions>
                <Descriptions.Item label="Current Contact">{contact?.name}</Descriptions.Item>
              </Descriptions>
            )}
            <Form.Item label="Contact" name="contactId" rules={[{ required: true, message: 'A contact is required!' }]}>
              <Select
                showSearch
                allowClear
                placeholder="New Contact"
                loading={contactsLoading}
                disabled={loading || contactsLoading}
                filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                options={contacts.map((c) => ({
                  value: c.id,
                  label: `${c.name} <${c.email}>`,
                }))}
              />
            </Form.Item>
            <NuButton
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={!canUpdateCaseContact || loading || contactsLoading}
            >
              OK
            </NuButton>
            <NuButton type="default" loading={loading} style={{ marginLeft: '1rem' }} onClick={() => close(false)}>
              Cancel
            </NuButton>
          </Form>
        </Modal>
      )}
    </>
  );
}
