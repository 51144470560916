
export default () => (
  <svg width="1.5em" height="1em" version="1.1" viewBox="-.533 -.533 156 18.83" xmlns="http://www.w3.org/2000/svg">
    <title>Fortinet logo</title>
    <desc>A cybersecurity company based in United States</desc>
    <path d="m100.5 17.6h-4.266v-17.41l13.72 0.0113s2.512-0.0937 4.11 1.485c0 0 1.631 1.362 1.631 4.691v11.22h-4.255v-11.51s0.0525-1.174-0.3475-1.586c0 0-0.3537-0.4475-1.026-0.4475h-9.577l9e-3 13.54zm18.16-3e-3v-16.3-1.094h17.12v3.869l-12.88-1e-3v2.958h8.948v3.736h-8.948v2.968h12.96v3.87h-17.21zm-29.86 0v-16.3-1.094h4.248v17.4h-4.248zm54.19-14.62v-0.5862 0.5862zm-1.095 14.62v-13.53h-4.01v-3.869h15.95v3.869h-7.689v13.53h-4.248zm-66.03-14.62v-0.5862 0.5862zm-1.095 14.62v-13.53h-4.01v-3.869h15.95v3.869h-7.689v13.53h-4.248zm-12.81-10.58c1.038-8e-3 1.659 1e-3 1.909-0.1512 0 0 0.4362-0.2412 0.4412-0.7588l1e-3 -1.138c0-0.52-0.4412-0.7588-0.4412-0.7588-0.3312-0.18-0.8925-0.1462-2.225-0.1475h-8.276v2.954h8.781-0.19zm2.289 10.58v-5.938c0-0.5338-0.4462-0.7575-0.4462-0.7575-0.2012-0.125-0.8038-0.1475-1.691-0.1475h0.2062-8.949v6.842h-4.248v-16.3-1.094h13.26c2.29 7e-3 3.871 0.3438 4.96 1.401 1.07 1.09 1.212 2.516 1.218 4v1.239c0.0138 1.261-0.6488 2.411-1.439 3.081l0.1588 0.0788c0.3225 0.1562 0.4988 0.3775 0.4988 0.3775 0.6012 0.7675 0.5938 1.428 0.5925 2.24v4.981h-4.121zm-64.24-17.4v1.095 16.3h4.248v-6.836h12.87v-3.738h-12.87v-2.956h14.96v-3.869h-19.21z" />
    <path d="m152.4 14.52v0.7988h0.7438c0.2012 0 0.3962-0.055 0.4262-0.3538 0.0425-0.3962-0.2438-0.445-0.4262-0.445h-0.7438zm-0.2987-0.2625h1.036c0.36 0 0.7075 0.0675 0.7375 0.5912 0.0125 0.3-0.0788 0.4088-0.305 0.58 0.2387 0.1525 0.245 0.1888 0.2812 0.5912 0.0238 0.305-0.0188 0.3538 0.11 0.555h-0.3538l-0.0487-0.2925c-0.0488-0.275 0.085-0.7012-0.3962-0.7012h-0.7625v0.9938h-0.2988v-2.318zm0.78-0.64c-1.024 0-1.859 0.835-1.852 1.866 0 1.024 0.8288 1.859 1.859 1.854 1.025 0 1.86-0.83 1.86-1.86 0-1.025-0.835-1.86-1.866-1.86m6e-3 3.962c-1.164 0-2.104-0.9388-2.104-2.102-5e-3 -1.159 0.94-2.104 2.104-2.104 1.159 0 2.104 0.945 2.104 2.104 0 1.164-0.945 2.098-2.104 2.102" />
    <path d="m43.28 17.77c1.38-0.4688 2.454-2.058 2.712-4.056v-0.5775h-7.075v4.634h4.362zm2.713-13.13v-0.5225c-0.2712-2.085-1.425-3.728-2.896-4.114h-4.179v4.636h7.075zm-24.9 8.497v0.5725c0.2562 2 1.33 3.592 2.714 4.061h4.34v-4.634h-7.054zm2.895-13.13c-1.469 0.3862-2.626 2.03-2.895 4.118v0.5188h7.054v-4.636h-4.159zm22.01 6.566h-7.075v4.636h7.075v-4.636zm-15.92 11.2h6.921v-4.634h-6.921v4.634zm6.921-17.77h-6.921v4.636h6.921v-4.636zm-15.9 11.2h7.054v-4.636h-7.054v4.636z" />
  </svg>
);
