const personaTypes = ['ciso', 'engineer'] as const;

export type PersonaTypes = (typeof personaTypes)[number];
export const personas: { label: string; value: PersonaTypes }[] = [
  {
    label: 'CISO',
    value: 'ciso',
  },
  {
    label: 'Engineer',
    value: 'engineer',
  },
];
