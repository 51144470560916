import Icon, {
  CopyOutlined,
  DownOutlined,
  EyeInvisibleOutlined,
  FormOutlined,
  MonitorOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useOktaAuth } from '@okta/okta-react';
import { Dropdown, MenuProps, Segmented, Space, Typography, message } from 'antd';
import { useAuthContext } from 'components/auth-context';
import { useAdminPage } from 'components/authorization';
import { useClientContext } from 'components/client-context-provider';
import { NotificationBell } from 'components/nuspire/nu-icon';
import { P } from 'components/shared-components';
import { LogOut as LogOutIcon, MoonIcon, SunIcon, User as UserIcon } from 'lucide-react';
import { ReactNode } from 'react';
import Avatar from 'react-avatar';
import { Link, useLocation } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { getAccessToken, isImpersonationActive } from 'utils/access-tokens';
import { config } from '../config';
import { personas } from '../personas';
import { capitalize } from '../utils/strings';
import { stopUserImpersonation } from './admin/users/user-impersonation-button';
import { DebugErrorButton } from './debug-error-button';
import { ThemeSetting, themeSettings, useMyNuspireThemeContext } from './theme';

const UserDetailsItem = styled(P)`
  margin: 0;
`;

const ThemeSelect = () => {
  const { setting, setThemeAndSave } = useMyNuspireThemeContext();
  const icons: {
    [k in ThemeSetting]: ReactNode;
  } = {
    light: <Icon component={SunIcon as any} />,
    dark: <Icon component={MoonIcon as any} />,
    system: <MonitorOutlined />,
  };

  return (
    <Space direction="vertical">
      <Typography.Text>Theme</Typography.Text>
      <Segmented
        value={setting}
        onChange={(v) => setThemeAndSave(v as ThemeSetting)}
        options={themeSettings.map((v) => ({
          value: v,
          label: capitalize(v),
          icon: icons[v],
        }))}
      />
    </Space>
  );
};

const ProfileMenu = () => {
  const theme = useTheme();
  const { user, isNuspireUser, logout, refresh: refreshAuthContext, isMyNuspireDev } = useAuthContext();
  const { oktaAuth } = useOktaAuth();
  const { clientId, client } = useClientContext();
  const { canViewAdmin } = useAdminPage();
  const location = useLocation();

  const isDebugErrorButtonEnabled = isMyNuspireDev;

  const userPersona = personas[user?.persona || ''] || 'Custom';

  const items: MenuProps['items'] = [
    {
      key: 'user-details',
      type: 'group',
      label: (
        <>
          <UserDetailsItem>
            <strong>{user?.client?.name}</strong>
          </UserDetailsItem>
          <UserDetailsItem>
            {user?.firstName} {user?.lastName}
          </UserDetailsItem>
          <UserDetailsItem>{user?.email}</UserDetailsItem>
          <UserDetailsItem>
            <span>Persona: </span>
            {userPersona}
          </UserDetailsItem>
          <UserDetailsItem>
            <span>myNuspire Version:</span> {!config.isLocal ? config.myNuspireVersion : 'local'}
          </UserDetailsItem>
          {isDebugErrorButtonEnabled && (
            <UserDetailsItem>
              <DebugErrorButton />
            </UserDetailsItem>
          )}
        </>
      ),
      style: {
        color: 'unset',
      },
    },
    {
      key: 'divider-1',
      type: 'divider',
    },
    ...(canViewAdmin
      ? [
          {
            key: 'admin',
            label: (
              <Link to="/admin">
                <SettingOutlined size={14} style={{ marginRight: '0.5rem' }} />
                Admin
              </Link>
            ),
          },
        ]
      : []),
    ...(!location.pathname.startsWith('/admin') && canViewAdmin
      ? [
          {
            key: 'admin-client-view',
            label: (
              <Link to={`/admin/clients/${clientId}`}>
                <FormOutlined size={14} style={{ marginRight: '0.5rem' }} />
                View Admin Page for {client?.name ?? 'Client'}
              </Link>
            ),
          },
        ]
      : []),
    ...(isNuspireUser
      ? [
          {
            key: 'copy-access-token',
            label: (
              <a
                href="#"
                onClick={async () => {
                  const accessToken = await getAccessToken();
                  if (accessToken === null) {
                    return;
                  }
                  navigator.clipboard.writeText(accessToken);
                  message.success('Access token copied to the clipboard!');
                }}
              >
                <CopyOutlined size={14} style={{ marginRight: '0.5rem' }} />
                Copy Access Token
              </a>
            ),
          },
        ]
      : []),
    {
      key: 'divider-2',
      type: 'divider',
    },
    {
      key: 'user-settings',
      label: 'User Settings',
      type: 'group',
    },
    {
      key: 'view-my-profile',
      label: (
        <Link to="/profile">
          <UserIcon size={14} style={{ marginRight: '0.5rem' }} />
          My Profile
        </Link>
      ),
    },
    {
      key: 'notification-settings',
      label: (
        <Link to="/notifications/settings">
          <NotificationBell size={14} style={{ marginRight: '0.5rem' }} />
          Notification Settings
        </Link>
      ),
    },
    {
      key: 'divider-3',
      type: 'divider',
    },
    ...(isImpersonationActive()
      ? [
          {
            key: 'stop-impersonation',
            label: (
              <a
                href="#"
                onClick={() => {
                  stopUserImpersonation(() => refreshAuthContext({ redirectToFn: (cId) => `/${cId}/home` }));
                }}
              >
                <EyeInvisibleOutlined size={14} style={{ marginRight: '0.5rem' }} />
                Stop Impersonation
              </a>
            ),
          },
        ]
      : []),
    {
      key: 'theme-select',
      label: <ThemeSelect />,
      type: 'group',
    },
    {
      key: 'theme-select-divider',
      type: 'divider',
    },

    {
      key: 'sign-out',
      label: (
        <a
          href="#"
          onClick={async () => {
            logout();
            oktaAuth.signOut();
          }}
        >
          <LogOutIcon size={14} style={{ marginRight: '0.5rem' }} />
          Sign Out
        </a>
      ),
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottomRight" arrow={{ pointAtCenter: true }}>
      <a onClick={(e) => e.preventDefault()} role="presentation">
        <Space>
          <Avatar
            name={`${user?.firstName} ${user?.lastName}`}
            round
            color={theme.color.primary}
            size="32px"
            email={user?.email}
            style={{ display: 'flex' }}
          />
          <DownOutlined style={{ fontSize: '0.5rem' }} />
        </Space>
      </a>
    </Dropdown>
  );
};

export default ProfileMenu;
