import React from 'react';
import { formatPhoneNumber, parsePhoneNumberFromFormattedString } from 'utils/phone-number';

export type TelephoneAnchorProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & {
  extension?: string;
  phoneNumber?: string;
};

export default function PhoneNumberAnchor(props: TelephoneAnchorProps) {
  const { extension, phoneNumber, ...rest } = props;

  const formattedPhone = formatPhoneNumber(phoneNumber, extension);
  const { uri } = parsePhoneNumberFromFormattedString(formattedPhone);

  return (
    <a {...rest} href={uri}>
      {formattedPhone}
    </a>
  );
}
