import React from 'react';
import { Input, Form, message } from 'antd';
import { NuButton } from 'components/nuspire';
import { gql, useMutation } from '@apollo/client';
import { useAuthContext } from 'components/auth-context';
import { mixpanelTrack } from 'utils/mixpanel/mixpanel-track';

const CREATE_COMMENT = gql`
  mutation CreateComment($taskId: String!, $text: String!) {
    taskComment(taskId: $taskId, text: $text) {
      id
      text
      type
      user {
        id
        firstName
        lastName
        email
      }
      ok
      data
      createdAt
    }
  }
`;

const READ_QUERY = gql`
  query ReadTaskActivity($id: String!) {
    clientTask(id: $id) {
      id
      activity {
        id
        text
        type
        ok
        user {
          id
          firstName
          lastName
          email
        }
        data
        createdAt
      }
    }
  }
`;

export function AddComment(props: { taskId: string }) {
  const { taskId } = props;
  const [form] = Form.useForm();

  const { user } = useAuthContext();

  const [addComment, { loading }] = useMutation(CREATE_COMMENT, {
    update: (cache, { data }) => {
      const { taskComment } = data;

      const cachedData = cache.readQuery({
        variables: { id: taskId },
        query: READ_QUERY,
      }) as any;

      if (typeof cachedData === 'object' && cachedData !== null) {
        const updatedData = {
          clientTask: {
            ...cachedData.clientTask,
            activity: [
              taskComment, // first?
              ...cachedData.clientTask.activity,
            ],
          },
        };

        cache.writeQuery({
          query: READ_QUERY,
          data: updatedData,
          variables: { id: taskId },
        });
      }
    },
  });

  const handleFinish = async (values) => {
    const { text } = values;

    const variables = {
      text,
      taskId,
    };
    form.resetFields();

    // make request
    try {
      // we can do some optimistic UI...
      await addComment({
        variables,
      });

      mixpanelTrack('task-comment-created');
    } catch (err: any) {
      console.error(err);
      message.error('Failed to save Comment. Please contact support');
    }
  };

  // === HANDLE SUBMISSION WHEN USER HITS ENTER KEY
  const handleKeyUp = (e) => {
    if (e.keyCode == 13 && !e.shiftKey) {
      form.submit();
    }
  };

  return (
    <Form form={form} onFinish={handleFinish} onKeyDownCapture={handleKeyUp}>
      <Form.Item
        name="text"
        rules={[{ required: true, message: 'Please Enter valid Comment.' }]}
        extra="Currently, comments do not notify Nuspire. Please open a Case if communication with Nuspire is necessary."
      >
        <Input.TextArea placeholder="Add a comment..." disabled={loading} />
      </Form.Item>
      <Form.Item>
        <NuButton htmlType="submit" type="primary" loading={loading}>
          Save
        </NuButton>
      </Form.Item>
    </Form>
  );
}
