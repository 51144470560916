import { gql, useQuery } from '@apollo/client';
import { CombinedInsightDefinition } from '../..';
import { useClientContext } from 'components/client-context-provider';
import { Tag, Col, Row } from 'antd';
import { Doughnut } from 'components/nuspire/charts/doughnut-chart';
import baseTheme from 'components/theme';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { Link, NuCard, NuCardContent, NuCardFooter, NuCardTitle } from 'components/nuspire';
import { QualysShield, SentinelOne } from 'components/nuspire/nu-icon';
import { getSeverity } from 'components/insights/insights-overview/inventory-list';
import InventoryInfiniteTable from 'components/insights/insights-infinite-table';
import { WidgetComponentProps } from '../../../../widgets/widget';
import { useEffect } from 'react';

/**
 * The goals here aren't exactly clear but for now...
 * the plan is to query Assets that have vulnerabilities AND do NOT have sentinelOne endpoint protection?
 *
 * It would be useful to show all qualys AND sentinelOne Connections. Provide links to set these up if they don't exist.
 */

const VULN_HOSTS_WO_EDP_DOUGHNUT = gql`
  query VulnHostsWoEdpDoughnut($clientId: String!) {
    assetsWoEdp: assetsByClientId(clientId: $clientId, options: { hasQualys: true, hasSentinelOne: false }) {
      count
    }

    assetsWithEdp: assetsByClientId(clientId: $clientId, options: { hasQualys: true, hasSentinelOne: true }) {
      count
    }
  }
`;

function transformDoughnutData(args: { inventoryWoEdp?: number; inventoryWithEdp?: number }) {
  const { inventoryWithEdp, inventoryWoEdp } = args;

  const total = inventoryWoEdp;

  const labels = ['With Endpoint Protection', 'Without Endpoint Protection'];

  const data = [inventoryWithEdp, inventoryWoEdp];

  const backgroundColor = [baseTheme.color.indigo, baseTheme.color.red];

  return {
    total,
    labels,
    data,
    backgroundColor,
  };
}

// next week we can make this a more structured widget.
// TODO: it's now 2 years later. the url is broken
export function NumberOfVulnHostWoEdpWidgetComponent(props: Omit<WidgetComponentProps<any, any>, 'clientId' | 'data' | 'configuration'>) {
  const { isReportWidget, setFooterContent } = props;
  const clientContext = useClientContext();
  const clientId = clientContext.clientId;
  const { data, loading } = useQuery(VULN_HOSTS_WO_EDP_DOUGHNUT, { variables: { clientId } });

  useEffect(() => {
    if (!isReportWidget && setFooterContent) {
      setFooterContent(
        <NuCardFooter
          label="View Vulnerable Hosts Without Protection"
          url={`/${clientId}/insights/combined/vuln-hosts-wo-edp`}
        />
      );
    }
  }, []);

  const inventoryWoEdp = data?.assetsWoEdp.count;
  const inventoryWithEdp = data?.assetsWithEdp.count;

  const doughnutData = transformDoughnutData({
    inventoryWoEdp,
    inventoryWithEdp,
  });

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  if (doughnutData) {
    return (
      <Row justify="center">
        <div style={{ width: '300px', minHeight: '250px' }}>
          <Doughnut
            options={{ cutout: 80 }}
            data={{
              labels: doughnutData.labels,
              datasets: [
                {
                  label: 'Inventory',
                  data: doughnutData.data.filter((v): v is number => v !== undefined),
                  backgroundColor: doughnutData.backgroundColor,
                },
              ],
            }}
            centerContent={(
              <h1>{`Total ${doughnutData.total}`}</h1>
            )}
          />
        </div>
      </Row>
    );
  }

  return <div>Data could not be found.</div>;
}

// TODO: the url is broken
export function VulnHostsWoEdpTableWidgetComponent(props: Omit<WidgetComponentProps<any, any>, 'clientId' | 'data' | 'configuration'>) {
  const { isReportWidget, setFooterContent } = props;
  const clientContext = useClientContext();
  const clientId = clientContext.clientId || '';

  useEffect(() => {
    if (!isReportWidget && setFooterContent) {
      setFooterContent(
        <NuCardFooter
          label="View Vulnerable Hosts Without Protection Details"
          url={`/${clientId}/insights/combined/vuln-hosts-wo-edp`}
        />
      );
    }
  }, []);

  return (
    <InventoryInfiniteTable
      clientId={clientId}
      options={{ hasQualys: true, hasSentinelOne: false, pageSize: 10 }}
      scrollHeight={250}
      columns={[
        {
          title: 'IP',
          key: 'ip',
          render: (record) => {
            return <Link to={`/${clientId}/insights/inventory/${record.id}`} mode="plain">{record.ip}</Link>;
          },
        },

        {
          title: 'Operating System',
          render: (record) => record.qualys?.host?.os ?? '--',
        },

        //  These could be placed in a vertical meta list
        {
          title: 'Hostname',
          render: (record) => record.qualys?.host?.dnsData?.hostname ?? '--',
        },
        {
          title: 'Domain',
          render: (record) => record.qualys?.host?.dnsData?.domain ?? '--',
        },
        {
          title: 'Active Detections',
          render: (record) => record?.qualys?.activeDetectionCount ?? '--',
        },
        {
          title: 'Severity (Counts)',
          render: (record) => {
            const sorted = record.qualys?.severityCounts ? [...record.qualys?.severityCounts] : [];

            sorted?.sort((a, b) => {
              console.log({ a, b });
              if (a.severity < b.severity) {
                return 1;
              }
              if (a.severity > b.severity) {
                return -1;
              }
              return 0;
            });

            if (!sorted) {
              return '--';
            }

            return (
              <>
                {sorted.map(({ severity, count }) => {
                  const severityData = getSeverity(severity);
                  return (
                    <div key={severity}>
                      <Tag color={severityData.color}>{`${severityData.type} (${count})`}</Tag>
                    </div>
                  );
                })}
              </>
            );
          },
        },
      ]}
    />
  );
}

function VulnHostsWoEdp() {
  return (
    <Row gutter={24}>
      <Col span={24} lg={8}>
        <NuCard fullHeight>
          <NuCardTitle title="Number of Vulnerable Hosts without Endpoint Protection" />
          <NuCardContent>
            <NumberOfVulnHostWoEdpWidgetComponent />
          </NuCardContent>
        </NuCard>
      </Col>

      <Col span={24} lg={16}>
        <NuCard fullHeight>
          <NuCardTitle title="Vulnerable Hosts without Endpoint Protection Details" />
          <NuCardContent>
            <VulnHostsWoEdpTableWidgetComponent />
          </NuCardContent>
        </NuCard>
      </Col>
    </Row>
  );
}

const definition: CombinedInsightDefinition = {
  name: 'Vulnerable Hosts without Endpoint Protection',
  slug: 'vuln-hosts-wo-edp',
  summary:
    'Discover gaps between Qualys and SentinelOne connections to understand where you are in need of Endpoint Protection.',
  description: `The following Vulnerable Hosts were discovered with your Qualys integration and need Endpoint Protection.`,
  render: VulnHostsWoEdp,
  icons: [<QualysShield />, <SentinelOne />],
  connectorSlugs: ['qualys', 'sentinelone'],
};

export default definition;
