import { SyncOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { message } from 'antd';
import MutationButton from 'components/mutation-button';
import { UserActionButtonProps } from 'types';
import { formatUserName } from 'utils/users';

const RESTORE_USER_MUTATION = gql`
  mutation RestoreUser($userId: String!) {
    restoreUser(userId: $userId)
  }
`;

type RestoreUserData = {
  restoreUser?: boolean;
};

export default function RestoreUserButton(props: UserActionButtonProps) {
  const { onSuccess, user, ...buttonProps } = props;

  const userName = formatUserName(user);
  const { id: userId } = user;
  const variables = {
    userId,
  };
  const modalTitle = `Are you sure you want to restore ${userName}?`;

  const onCompleted = (data: RestoreUserData) => {
    if (data.restoreUser !== undefined) {
      const { restoreUser: result } = data;

      // Restore was unsuccessful.
      if (!result) {
        onError();
        return;
      }

      // Restore was successful.
      message.success(`${userName} has been restored!`);
      if (onSuccess) onSuccess();
    }
  };

  const onError = (error?: any) => {
    // Display the error message if we have one.
    const errorMessage = `There was an error restoring ${userName}. ${error ? ` Error: ${error.message}` : `.`}`;

    message.error(errorMessage);
  };

  return (
    <MutationButton
      buttonIcon={<SyncOutlined />}
      buttonType="primary"
      modalTitle={modalTitle}
      mutation={RESTORE_USER_MUTATION}
      mutationVariables={variables}
      onCompleted={onCompleted}
      onError={onError}
      tooltipOverlay="Restore User"
      {...buttonProps}
    />
  );
}
