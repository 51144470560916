import styled from 'styled-components';
import { Input as AntInput } from 'antd';
import { SearchProps } from 'antd/es/input/Search';

interface InputSearchProps extends SearchProps {
  variation?: 'light' | 'dark';
}

const StyledSearch = styled(AntInput.Search)`
  &.dark {
    .ant-input {
      // background-color: #ededed;
    }
  }
`;

export function Search(props: InputSearchProps) {
  const { variation = 'light' } = props;

  return <StyledSearch {...props} className={`nu-search ${variation}`} />;
}

const Input = styled(AntInput)``;

export default Input;
