import { Dropdown, Modal, Typography, MenuProps, Space, Form, Alert, Tooltip } from 'antd';
import { LockOutlined, UnlockOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { CopyToClipboardIcon } from 'components/shared-components';
import { gql, useMutation } from '@apollo/client';
import { NuButton } from 'components/nuspire';
import { useState } from 'react';
import ConnectionInput from 'components/cyberx/actions/action-form/action-form-field/inputs/connection-input';
import { useClientContext } from 'components/client-context-provider';
import { ActionFormFieldDef } from 'components/cyberx/actions/types';

interface ConnectionsModalProps {
  visible: boolean;
  onCloseModal?: () => void;
  userPrincipalName: string;
}

interface ActionProps {
  userPrincipalName: string;
  actionSlug: string;
}

enum ActionSlugs {
  LOCKOUT_USER = 'azure-lockout-user',
  REVOKE_USER = 'azure-revoke-user-sessions',
  UNLOCK_RESET = 'azure-unlock-and-reset-user',
}

const ACTION_MUTATION = gql`
  mutation ExectueAction($clientId: String!, $actionId: String!, $input: JSONObject!) {
    executeAction(clientId: $clientId, actionId: $actionId, input: $input) {
      id
      ok
      summary
      data
    }
  }
`;

const connectionsField: ActionFormFieldDef = {
  key: 'connection',
  label: 'Connection',
  type: 'object',
  jsonSchemaString: `{
        properties: {
            id: { type: 'string' },
            name: { type: 'string' },
        },
    }`,

  required: true,
  inputType: 'connection',
  parameters: {
    connectorSlug: 'azure',
  },
  errorMessages: {
    required: 'Connection is required.',
  },
};

function ConnectionsForm(props: ActionProps) {
  const [connectionAction] = useMutation(ACTION_MUTATION);
  const { userPrincipalName, actionSlug } = props;
  const client = useClientContext();

  const [tempPassword, setTempPassword] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<string | undefined>(undefined);

  if (!client) {
    throw Error(`no client present`);
  }

  const onFinish = async (values) => {
    const input = {
      connectionId: values.connection?.id,
      userPrincipalName: userPrincipalName,
    };

    const result = await connectionAction({
      variables: {
        clientId: client.clientId,
        actionId: actionSlug,
        input,
      },
    });

    setStatus(result.data.executeAction.summary);
    if (result.data.executeAction.data.tempPassword) {
      setTempPassword(result.data.executeAction.data.tempPassword);
    }

    return result;
  };
  if (!tempPassword && status) {
    return <Alert message={status} />;
  }

  if (tempPassword) {
    return (
      <Alert
        action={<CopyToClipboardIcon copyText={tempPassword} />}
        message={
          <>
            {`Here is your temporary password for ${userPrincipalName}`}
            <br />
            {tempPassword}
          </>
        }
      />
    );
  }

  return (
    <>
      <Typography.Paragraph>Please select the connection you'd like to use for this call</Typography.Paragraph>
      <Form onFinish={onFinish}>
        <Form.Item name="connection" rules={[{ required: true, message: 'Please select a connection.' }]}>
          <ConnectionInput
            clientId={client.clientId}
            clientName={client.client?.name}
            name="connection"
            field={connectionsField}
            // `onChange` is required but not used. not sure why
            onChange={(_val) => { }}
          />
        </Form.Item>
        <Form.Item>
          <NuButton htmlType="submit" type="primary">
            Take Action
          </NuButton>
        </Form.Item>
      </Form>
    </>
  );
}

function LockUserConnectionsModal(props: ConnectionsModalProps) {
  let { visible, onCloseModal, userPrincipalName } = props;

  return (
    <Modal open={visible} title="Lock User Connections Modal" width={1200} footer={false} onCancel={onCloseModal}>
      <ConnectionsForm userPrincipalName={userPrincipalName} actionSlug={ActionSlugs.LOCKOUT_USER} />
    </Modal>
  );
}

function UnlockUserConnectionsModal(props: ConnectionsModalProps) {
  let { visible, onCloseModal, userPrincipalName } = props;

  return (
    <Modal open={visible} title="Unlock User Connections Modal" width={1200} footer={false} onCancel={onCloseModal} >
      <ConnectionsForm userPrincipalName={userPrincipalName} actionSlug={ActionSlugs.UNLOCK_RESET} />
    </Modal>
  );
}
function RevokeUserSessionModal(props: ConnectionsModalProps) {
  let { visible, onCloseModal, userPrincipalName } = props;

  return (
    <Modal open={visible} title="Revoke User Connections Modal" width={1200} footer={false} onCancel={onCloseModal}>
      <ConnectionsForm userPrincipalName={userPrincipalName} actionSlug={ActionSlugs.REVOKE_USER} />
    </Modal>
  );
}

export function AzureLockoutUser(props: { value: any; record: any }) {
  const { value, record } = props;
  const [showModal, setShowModal] = useState<boolean>(false);

  const openModal = () => {
    setShowModal(!showModal);
  };

  const userPrincipalName = record.username;

  return (
    //if button is clicked and multiple connections are found, allow the user to select which connection to use
    <>
      <Tooltip overlay="Clicking this will assist you in locking users out">
        <NuButton icon={<LockOutlined />} type="primary" onClick={openModal} style={{ marginRight: '0.25rem' }} />
      </Tooltip>
      <LockUserConnectionsModal
        visible={showModal}
        onCloseModal={() => {
          setShowModal(false);
        }}
        userPrincipalName={userPrincipalName}
      />
    </>
  );
}

export function AzureUnlockResetUser(props: { value: any; record: any }) {
  const { value, record } = props;
  const [showModal, setShowModal] = useState<boolean>(false);

  const openModal = () => {
    setShowModal(!showModal);
  };
  const userPrincipalName = record.username;

  return (
    //if button is clicked and multiple connections are found, allow the user to select which connection to use
    <>
      <Tooltip overlay="Clicking this will assist you in unlocking users">

        <NuButton icon={<UnlockOutlined />} type="primary" onClick={openModal} style={{ marginTop: '0.25rem' }} />
      </Tooltip>
      <UnlockUserConnectionsModal
        visible={showModal}
        onCloseModal={() => {
          setShowModal(false);
        }}
        userPrincipalName={userPrincipalName}
      />
    </>
  );
}

export function AzureRevokeUserSession(props: { value: any; record: any }) {
  const { value, record } = props;
  const [showModal, setShowModal] = useState<boolean>(false);

  const openModal = () => {
    setShowModal(!showModal);
  };
  const userPrincipalName = record.username;

  return (
    //if button is clicked and multiple connections are found, allow the user to select which connection to use
    <>
      <Tooltip overlay="Clicking this will assist you in revoking this current users sessions">

        <NuButton icon={<UserDeleteOutlined />} type="primary" onClick={openModal} style={{ marginRight: '0.25rem' }} />
      </Tooltip>
      <RevokeUserSessionModal
        visible={showModal}
        onCloseModal={() => {
          setShowModal(false);
        }}
        userPrincipalName={userPrincipalName}
      />
    </>
  );
}

export function Office365ActionsComponent(props: { value: any; record: any }) {
  const { value, record } = props;

  return (
    <>
      <AzureLockoutUser value={value} record={record} />
      <AzureRevokeUserSession value={value} record={record} />
      <AzureUnlockResetUser value={value} record={record} />
    </>
  );
}
export function AzureActionsComponent(props: { value: any; record: any }) {
  const { value, record } = props;

  return (
    <>
      <AzureLockoutUser value={value} record={record} />
      <AzureRevokeUserSession value={value} record={record} />
      <AzureUnlockResetUser value={value} record={record} />
    </>
  );
}
export const renderComponentsMap = {
  Office365ActionsComponent,
  AzureActionsComponent
};
