import { Col, Row } from 'antd';
import PageSection from 'components/page-section';
import ThreatBriefsCard from './threat-briefs-card';
import KnowledgeBaseArticlesCard from './knowledge-base-articles-card';
import { BookOutlined } from '@ant-design/icons';

export default function KnowledgeBaseSection(props: { clientId: string }) {
  const { clientId } = props;

  return (
    <PageSection title="Knowledge" icon={<BookOutlined />}>
      <Row gutter={20} style={{ height: '400px' }}>
        <Col lg={12} style={{ height: '100%' }}>
          <KnowledgeBaseArticlesCard clientId={clientId} />
        </Col>
        <Col lg={12} style={{ height: '100%' }}>
          <ThreatBriefsCard {...props} />
        </Col>
      </Row>
    </PageSection>
  );
}
