import { Meraki as MerakiIcon } from 'components/nuspire/nu-icon';
import { IConnector } from 'components/connections/connectors';
import MerakiRedirect from './meraki-redirect';
import { encodeOAuthState } from '../utils/oauth';

const MERAKI_CONNECTOR_DEFINITION: IConnector = {
  slug: 'meraki',
  name: 'Meraki',
  description: 'Cloud-Native Endpoint Protection',
  icon: <MerakiIcon />,
  connectionTypes: ['action'],
  handleAddConnection: ({ clientId }) => {
    if (!clientId) {
      throw new Error('Client Id must be passed to connector');
    }
    const authState = encodeOAuthState({ clientId });

    window.open(
      `${window.location.origin}/redirects/connections/meraki/auth?state=${authState}`,
      'Meraki Auth',
      'height=1000,width=800',
    );
  },
  redirects: [{ path: 'auth', component: MerakiRedirect }],
  widgets: [],
  updatableAuthFields: [
    { key: 'API Key', value: 'apiKey' },
    { key: 'Instance URL', value: 'baseURL' },
  ],
};

export default MERAKI_CONNECTOR_DEFINITION;
