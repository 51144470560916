/* eslint-disable camelcase */
import { gql } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import SearchServiceNowModal from '../search-servicenow-modal';

const SERVICENOW_USERS_QUERY = gql`
  query ServiceNowUsers(
    $limit: Int
    $skip: Int
    $sortBy: String
    $sortOrder: String
    $search: String
    $active: Boolean
  ) {
    serviceNowUsers(
      limit: $limit
      skip: $skip
      sortBy: $sortBy
      sortOrder: $sortOrder
      search: $search
      active: $active
    ) {
      users {
        account
        email
        name
        sys_id
        sys_updated_on
        user_name
      }
      total
    }
  }
`;

type SearchServiceNowUsersModalProps = {
  defaultSearchValue?: string;
  isVisible: boolean;
  setIsVisible: Function;
  updateServiceNowId: Function;
};

export const defaultSortBy = `created`;

export default function SearchServiceNowUsersModal(props: SearchServiceNowUsersModalProps) {
  const { defaultSearchValue, isVisible, setIsVisible, updateServiceNowId } = props;
  const columns: ColumnsType<object> = [
    {
      title: `Name`,
      dataIndex: `name`,
      key: `name`,
      sortDirections: [`ascend`, `descend`, `ascend`],
      sorter: (a: any, b: any) => a.name - b.name,
      showSorterTooltip: false,
    },
    {
      title: `Account`,
      dataIndex: `account`,
      key: `account`,
      sortDirections: [`ascend`, `descend`, `ascend`],
      sorter: (a: any, b: any) => a.account - b.account,
      showSorterTooltip: false,
    },
    {
      title: `Username`,
      dataIndex: `user_name`,
      key: `user_name`,
      sortDirections: [`ascend`, `descend`, `ascend`],
      sorter: (a: any, b: any) => a.user_name - b.user_name,
      showSorterTooltip: false,
    },
    {
      title: `E-mail Address`,
      dataIndex: `email`,
      key: `email`,
      sortDirections: [`ascend`, `descend`, `ascend`],
      sorter: (a: any, b: any) => a.email - b.email,
      showSorterTooltip: false,
    },
    {
      title: `Sys Id`,
      dataIndex: `sys_id`,
      key: `sys_id`,
      sortDirections: [`ascend`, `descend`, `ascend`],
      sorter: (a: any, b: any) => a.sys_id - b.sys_id,
      showSorterTooltip: false,
    },
    {
      title: `Updated At`,
      dataIndex: `sys_updated_on`,
      key: `sys_updated_on`,
      sortDirections: [`ascend`, `descend`, `ascend`],
      sorter: (a: any, b: any) => a.sys_updated_on - b.sys_updated_on,
      showSorterTooltip: false,
    },
  ];

  return (
    <SearchServiceNowModal
      dataSourceKey="users"
      defaultSearchValue={defaultSearchValue}
      defaultSortBy={defaultSortBy}
      formatDescriptor={(user: any) => `${user.name} (username: ${user.user_name})`}
      graphQuery={SERVICENOW_USERS_QUERY}
      isVisible={isVisible}
      modalTitle="Search for ServiceNow Users"
      rootGraphKey="serviceNowUsers"
      setIsVisible={setIsVisible}
      tableColumns={columns}
      updateServiceNowId={updateServiceNowId}
    />
  );
}
