import { INSPDomain, IControlScore, IDomainScore, INSPControl } from 'types/nsp';
import { gql } from '@apollo/client';
import baseTheme from 'components/theme';

// @todo: pull these color variables from somewhere higher in the app hierarchy -- themes or something
export const severityColors = {
  high: baseTheme.color.red,
  medium: baseTheme.color.orange,
  low: baseTheme.color.green,
  other: baseTheme.color.nuspireBlue
};

export const GET_ALL_DOMAINS_SCORES = gql`
  query DomainScores {
    domainScores {
      domainId
      score
      controlScores {
        controlId
        score
      }
    }
  }
`;

export const GET_DOMAIN_SCORE = gql`
  query DomainScore($domainId: String!) {
    domainScore(domainId: $domainId) {
      domainId
      score
      controlScores {
        controlId
        score
      }
    }
  }
`;

export const GET_CONTROL_SCORE = gql`
  query GetControlScore($controlId: String!) {
    controlScore(controlId: $controlId) {
      controlId
      score
    }
  }
`;

export const GET_CONTROL_SCORES = gql`
  query GetControlScores {
    controlScores {
      controlId
      score
      isEnabled
    }
  }
`;

export const SET_CONTROL_SCORE = gql`
  mutation SetControlScore($input: SetControlScoreInput, $clientId: String) {
    setControlScore(input: $input, clientId: $clientId) {
      controlId
      oldValues {
        id
        clientId
        domainId
        controlId
        score
        isEnabled
      }
      newValues {
        id
        clientId
        domainId
        controlId
        score
        isEnabled
      }
    }
  }
`;

export const SET_CONTROL_SCORE_ENABLED = gql`
  mutation SetControlScoreEnabled($clientId: String, $input: SetControlScoreEnabledInput) {
    setControlScoreEnabled(input: $input, clientId: $clientId) {
      id
      clientId
      controlId
      oldValues {
        id
        clientId
        domainId
        controlId
        score
        isEnabled
      }
      newValues {
        id
        clientId
        domainId
        controlId
        score
        isEnabled
      }
      createdAt
      email
    }
  }
`;

export const REMOVE_CONTROL = gql`
  mutation DeleteControlScore($clientId: String, $input: DeleteControlScoreInput) {
    deleteControlScore(input: $input, clientId: $clientId) {
      createdAt
    }
  }
`;

export const GET_OVERALL_SCORE = gql`
  query GetOverallScore($clientId: String) {
    overallScore(clientId: $clientId) {
      id
      clientId
      score
    }
  }
`;

export function findFirstControlWithoutScore(props: {
  domains: INSPDomain[];
  savedDomainScores: IDomainScore[];
}): INSPControl | undefined {
  const { domains, savedDomainScores } = props;

  const domainControlsWithMissingScores = domains.map((domain) => {
    // grab the domain scores
    const domainScore = savedDomainScores.find((ds: IDomainScore) => ds.domainId === domain.id);

    // figure out if any controls in the domain are missing scores
    const controlsWithMissingScores = domain.controls?.filter((control: INSPControl) => {
      const controlScore = domainScore?.controlScores?.find((c: IControlScore) => c.controlId === control.id);
      return !controlScore;
    });

    // return the first control without a score (enabling our resume button)
    if (controlsWithMissingScores.length > 0) {
      const [firstControl] = controlsWithMissingScores;
      return {
        ...firstControl,
        domainId: domain.id, // add in the domain id so we can more easily build a link in the other component
      };
    }

    return undefined;
  });

  // filter out domains without controls
  const scorelessControls = domainControlsWithMissingScores.filter(Boolean);
  if (scorelessControls.length > 0) return scorelessControls[0];

  return undefined;
}
