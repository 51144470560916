import { Tooltip } from 'antd';
import { UserAuthenticationType } from 'types';

export type UserAuthenticationTypeDisplayProps = {
  authenticationType?: UserAuthenticationType;
};

const authTypes = {
  [UserAuthenticationType.EMAIL_AND_PASSWORD]: {
    label: 'Email and Password',
    tooltip: 'This user uses an email and password to log in to myNuspire',
  },
  [UserAuthenticationType.FEDERATED]: {
    label: 'Federated',
    tooltip: 'This user uses a federated login strategy (ex: SAML) to log in to myNuspire',
  },
};

export default function UserAuthenticationTypeDisplay(props: UserAuthenticationTypeDisplayProps) {
  const { authenticationType } = props;

  if (!authenticationType || !authTypes[authenticationType]) return null;

  const { label, tooltip } = authTypes[authenticationType];

  return (
    <Tooltip overlay={tooltip}>
      <span>{label}</span>
    </Tooltip>
  );
}
