import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams as useRouterSearchParams } from 'react-router-dom';

/**
 * Custom hook to manage URL Search Params.
 *
 * @author Tyler Vollick <tylerjvollick@gmail.com>
 */

type GetDefaultFunc = () => string | number;
type Maybe<T> = T | null;

export interface SearchParamDefinition {
  key: string;
  defaultValue?: string | number | GetDefaultFunc;
}

interface InitSearchParams {
  dirty: boolean;
  searchParams: URLSearchParams;
}

function buildInitialSearch(searchParameters: SearchParamDefinition[], oldSearch: string): InitSearchParams {
  const initSearch = searchParameters.reduce(
    (acc: InitSearchParams, d) => {
      /**
       * Set defaultValue if search param does not exist.
       */
      if (!acc.searchParams.has(d.key) && d.defaultValue) {
        const newDefault = typeof d.defaultValue === 'function' ? d.defaultValue() : d.defaultValue;

        acc.searchParams.set(d.key, `${newDefault}`);
        acc.dirty = true;
      }

      return acc;
    },
    {
      dirty: false,
      searchParams: new URLSearchParams(oldSearch),
    },
  );

  return initSearch;
}

export type ParamConfig = {
  name: string;
  value: Maybe<string | number>;
};

type ParamChange = {
  paramConfig: ParamConfig | ParamConfig[];
};
type ParsedParams = { [key: string]: any };

export interface SearchParamsInterface {
  initializing: boolean;
  parsed: { [key: string]: any };

  // update methods
  setParameter: (name: string, value: string | number | null) => void;
  setParameters: (values: ParamConfig[]) => void;
  initializeSearchParams: (searchParameters: SearchParamDefinition[]) => void;
  clearAll: () => void;

  // passed
  // routerLocation: Location
  search: string;
}

function testChangeOrderCompleted(changeOrder: ParamChange, parsed: ParsedParams) {
  const { paramConfig } = changeOrder;

  const paramConfigArr: ParamConfig[] = Array.isArray(paramConfig) ? paramConfig : [paramConfig];

  const incompleteOrders = paramConfigArr.filter((config) => {
    // return false if complete;
    const { name, value } = config;
    const parsedValue = parsed[name];

    if (value === null || value === '') {
      return Boolean(parsedValue);
    }

    if (value === null && parsedValue) {
      return true; // incomplete
    }

    if (`${value}` !== parsedValue) {
      return true; // incomplete
    }

    return false;
  });

  if (incompleteOrders.length > 0) {
    return false;
  }

  return true;
}

function useSearchParams(searchParameters?: SearchParamDefinition[]): SearchParamsInterface {
  const [initializing, setInitializing] = useState<boolean>(true);
  const routerLocation = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useRouterSearchParams();

  const parsed = useMemo(() => {
    return queryString.parse(routerLocation.search) as {
      [key: string]: any;
    };
  }, [routerLocation.search]);

  /**
   * Problem:
   * if `setParameter` is called twice faster than search params can update, the first change is often lost.
   *
   * Solution:
   * Build up a queue of changes to be made. Make change one at a time until there are no more changes in teh queue
   */
  const [changeQueue, setChangeQueue] = useState<ParamChange[]>([]);

  const clearAll = () => {
    setSearchParams('?');
  };

  const updateSearchParams = (configs: ParamConfig[]): void => {
    const searchParams = new URLSearchParams(routerLocation.search);

    configs.forEach((param) => {
      if (param.value) {
        searchParams.set(param.name, `${param.value}`);
      } else {
        searchParams.delete(param.name);
      }
    });

    setSearchParams(searchParams);
  };

  const queueChange = (paramConfig: ParamConfig | ParamConfig[]): void => {
    const paramChange: ParamChange = {
      paramConfig,
    };

    setChangeQueue((prev) => {
      const updatedQueue = [...prev, paramChange];
      return updatedQueue;
    });
  };

  /**
   * Effect: Process Items in Queue
   */
  useEffect(() => {
    // Execute next change order.
    const queuedChange = changeQueue[0];
    if (!queuedChange) {
      return;
    }

    const { paramConfig } = queuedChange;

    const paramConfigArr = Array.isArray(paramConfig) ? paramConfig : [paramConfig];

    /**
     * Make Changes.
     */
    updateSearchParams(paramConfigArr);
  }, [JSON.stringify(changeQueue)]);

  /**
   * Effect: Remove changes from queue once, they have been fulfilled.
   * This will trigger the process effect to process the next change if there is one queued.
   */
  useEffect(() => {
    if (changeQueue.length === 0) {
      return;
    }

    /**
     * Loop through the queue for incomplete changes.
     */
    const cleanQueue = changeQueue.filter((changeOrder) => {
      // return false if change is complete.

      const isComplete = testChangeOrderCompleted(changeOrder, parsed);

      return !isComplete;
    });

    setChangeQueue(cleanQueue);
  }, [JSON.stringify(parsed), JSON.stringify(changeQueue)]);

  /**
   * Function: initializeSearchParams
   * - called once on render
   * - also passed down interface to be called whenever need be.
   */
  const initializeSearchParams = (initialParams: SearchParamDefinition[]) => {
    if (!initializing) {
      setInitializing(true);
    }

    const initSearch = buildInitialSearch(initialParams, routerLocation.search);

    if (initSearch.dirty) {
      const newSearch = `?${initSearch.searchParams.toString()}`;

      navigate(
        {
          pathname: routerLocation.pathname,
          search: newSearch,
        },
        { replace: true },
      );
    }

    setInitializing(false);
  };

  /**
   * Initialize Search Params
   *
   * COULD BE IMPROVED...
   * the initialize function is meant to initialize search params on a page load.
   * this uses an effect that fires only once the component renders.
   *
   * This is a problem if the user renavigates to the the same path as the search parameters
   * are lost and this effect does not get run again.
   *
   * ONE SOLUTION
   * add `?initialize=true` search param to the link navigating to page when we want to initialize variables.
   *
   * Add a hook/effect to the rendering component that sets the parameters to initial values and set's initialize to false.
   */
  useEffect(() => {
    if (searchParameters) {
      initializeSearchParams(searchParameters);
    } else {
      setInitializing(false);
    }
  }, []);

  const setParameter = (name: string, value: string | number | null) => {
    const paramConfig: ParamConfig = {
      name,
      value,
    };

    queueChange(paramConfig);
  };

  const setParameters = (paramConfigs: ParamConfig[]) => {
    queueChange(paramConfigs);
  };

  const searchParamsInterface: SearchParamsInterface = {
    initializing,
    initializeSearchParams,
    parsed,
    setParameter,
    setParameters,
    clearAll,
    search: routerLocation.search,
  };

  return searchParamsInterface;
}

export default useSearchParams;
