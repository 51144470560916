import { TextComposition, TextObject } from '../composition/text';
import { ButtonStyle } from './button';
import { HookAPI } from 'antd/lib/modal/useModal';

export type ConfirmObject = {
  title: TextObject & { type: 'plain_text' };
  text: TextObject; // text inside Confirmation Body
  confirm?: TextObject & { type: 'plain_text' }; // default to "Confirm"
  deny?: TextObject & { type: 'plain_text' }; // default to "Deny"
  style?: ButtonStyle;
};

export function showConfirm(args: { modal: HookAPI; confirm: ConfirmObject; onOk: () => void; onCancel: () => void }) {
  const {
    confirm: { title, text, confirm, deny },
    onOk,
    onCancel,
    modal,
  } = args;

  const okText = confirm ? <TextComposition text={confirm} /> : 'Confirm';
  const cancelText = deny ? <TextComposition text={deny} /> : 'Cancel';

  modal.confirm({
    title: <TextComposition text={title} />,
    content: <TextComposition text={text} />,
    okText,
    cancelText,
    onOk: async () => {
      await onOk();
    },
    onCancel: async () => {
      await onCancel();
    },
  });
}
