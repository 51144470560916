export function getSortByAndOrderFromAntdPaginationSorter(sorter: any): [string | undefined, string | undefined] {
  let sortBy: string | undefined;
  let sortOrder: string | undefined;

  if (sorter) {
    const { order, field } = sorter;
    sortBy = field;

    if (order === 'ascend') {
      sortOrder = 'asc';
    } else if (order === 'descend') {
      sortOrder = 'desc';
    }
  }

  return [sortBy, sortOrder];
}
