import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Divider, Flex, Form, Input, Layout, Popconfirm, Tooltip, Typography, message } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { useEffect, useState, type FC } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import {
  NutronChatsQuery,
  NutronStatusQuery,
  type DeleteNutronChatMutation,
  type DeleteNutronChatMutationVariables,
  type NutronChat,
  type NutronChatsQueryVariables,
  type NutronStatusQueryVariables,
} from '../../types/graph-codegen/graph-types';
import { useClientContext } from '../client-context-provider';
import { ImpersonationBanner } from '../layouts/impersonation-banner';
import { NuButton } from '../nuspire';
import Spin from '../nuspire/spin';
import baseTheme from '../theme';
import dayjs from 'dayjs';

export const NUTRON_STATUS_QUERY = gql`
  query NutronStatus {
    nutronStatus {
      status
    }
  }
`;

export const NUTRON_CHAT_FRAGMENT = gql`
  fragment NutronChatFields on NutronChat {
    client {
      name
    }
    clientId
    createdAt
    deletedAt
    id
    title
    userId
    updatedAt
  }
`;

export const NUTRON_CHAT_MESSAGE_FRAGMENT = gql`
  fragment NutronChatMessageFields on NutronChatMessage {
    chatId
    clientId
    createdAt
    errorMessages
    feedback
    id
    message
    sources {
      serviceNowKnowledgeBaseArticleNumber
      serviceNowKnowledgeBaseTitle
      type
    }
    updatedAt
    userId
    userType
  }
`;

export const NUTRON_CHATS_QUERY = gql`
  ${NUTRON_CHAT_FRAGMENT}

  query NutronChats($clientId: String, $cursor: String, $includeDeleted: Boolean) {
    nutronChats(clientId: $clientId, cursor: $cursor, includeDeleted: $includeDeleted) {
      chats {
        ...NutronChatFields
      }
      count
      cursor
    }
  }
`;

const DELETE_NUTRON_CHAT_MUTATION = gql`
  ${NUTRON_CHAT_FRAGMENT}

  mutation DeleteNutronChat($id: String!, $clientId: String) {
    deleteNutronChat(id: $id, clientId: $clientId) {
      ...NutronChatFields
    }
  }
`;

const SideNavItemsWrapper = styled.div`
  padding: 14px 20px;
`;

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const ChatWindowWrapper = styled(Layout)`
  &.ant-layout {
    height: calc(100vh - 64px);
    min-height: calc(100vh - 64px) !important;
  }
`;

export const ChatInputWrapper = styled.div`
  margin-bottom: 80px;
`;

export const newChatRoute = (args: { clientId: string }): string => `/${args.clientId}/nutron`;

export const chatDetailsRoute = (args: { chatId: string; clientId: string }): string =>
  `/${args.clientId}/nutron/chat/${args.chatId}`;

const NutronStatusIcon: FC = () => {
  const statusCheckIntervalMs = 10 * 1_000; // 10s in ms;
  const [checkNutronStatus, { data, loading }] = useLazyQuery<NutronStatusQuery, NutronStatusQueryVariables>(
    NUTRON_STATUS_QUERY,
  );
  const [isOk, setIsOk] = useState<boolean>();
  const [statusCheckInterval, setStatusCheckInterval] = useState<number>();

  const checkStatus = async () => {
    try {
      const response = await checkNutronStatus();

      if (response.error) {
        setIsOk(false);
        return;
      }

      const isNutronAlive: boolean = response.data?.nutronStatus.status.toLowerCase() === 'ok';
      setIsOk(isNutronAlive);
    } catch (err) {
      console.error(err);
      setIsOk(false);
    }
  };

  useEffect(() => {
    const interval = window.setInterval(checkStatus, statusCheckIntervalMs);
    setStatusCheckInterval(interval);

    return () => {
      if (statusCheckInterval) {
        clearInterval(statusCheckInterval);
      }
    };
  }, []);

  if ((!data?.nutronStatus && loading) || isOk === undefined) {
    return (
      <Tooltip overlay="The current status of Nutron is unknown">
        <QuestionCircleOutlined style={{ color: baseTheme.color.gray4, fontSize: 16 }} />
      </Tooltip>
    );
  }

  const statusMapping = {
    ok: {
      component: <CheckCircleOutlined style={{ color: baseTheme.color.success, fontSize: 16 }} />,
      tooltipMessage: 'All systems operational',
    },
    error: {
      component: <CloseCircleOutlined style={{ color: baseTheme.color.error, fontSize: 16 }} />,
      tooltipMessage: 'Nutron is currently experiencing an issue',
    },
  };

  const status = isOk ? statusMapping.ok : statusMapping.error;

  return <Tooltip overlay={status.tooltipMessage}>{status.component}</Tooltip>;
};

const DeleteChatButton: FC<{ chat: Omit<NutronChat, 'client'> }> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { clientId } = useClientContext();
  const [deleteChat, { error, loading }] = useMutation<DeleteNutronChatMutation, DeleteNutronChatMutationVariables>(
    DELETE_NUTRON_CHAT_MUTATION,
    {
      refetchQueries: ['NutronChats'],
    },
  );

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error('An error occurred while attempting to delete this chat');
    }
  }, [error]);

  const onDeleteConfirm = async () => {
    await deleteChat({
      variables: {
        clientId,
        id: props.chat.id,
      },
    });

    const isOnDeletedChatPage: boolean = location.pathname.includes(`/chat/${props.chat.id}`);
    if (isOnDeletedChatPage) {
      navigate(newChatRoute({ clientId: clientId! }));
    }
  };

  return (
    <Popconfirm
      disabled={loading}
      title="Delete this chat?"
      description="This action cannot be undone."
      onConfirm={onDeleteConfirm}
      okText="Delete"
      okButtonProps={{ danger: true, disabled: loading, loading }}
    >
      <NuButton type="text" icon={<DeleteOutlined />} size="small" disabled={loading} />
    </Popconfirm>
  );
};

const NutronChatList: FC = () => {
  const { clientId } = useClientContext();
  const { data, error, loading } = useQuery<NutronChatsQuery, NutronChatsQueryVariables>(NUTRON_CHATS_QUERY, {
    variables: {
      clientId,
      includeDeleted: false,
    },
  });

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error('An error occurred while attempting to fetch your chats');
    }
  }, [error]);

  if (loading) {
    return (
      <Flex align="center" justify="center">
        <Spin />
      </Flex>
    );
  }

  const chatLength: number = data?.nutronChats.chats.length ?? 0;

  return data?.nutronChats.chats.map((c, idx) => (
    <Flex vertical key={c.id}>
      <Typography.Text type="secondary">{c.client?.name ?? c.clientId}</Typography.Text>
      <Flex justify="space-between" align="center">
        <Link to={chatDetailsRoute({ chatId: c.id, clientId: clientId! })}>
          <Typography.Text>{c.title ?? 'Untitled Chat'}</Typography.Text>
        </Link>

        <DeleteChatButton chat={c} />
      </Flex>
      <Typography.Text
        style={{
          fontSize: '0.7rem',
        }}
        type="secondary"
      >
        {dayjs(new Date(c.updatedAt)).fromNow()}
      </Typography.Text>
      {idx !== chatLength - 1 && <Divider type="horizontal" style={{ margin: '0.5rem 0' }} />}
    </Flex>
  ));
};

const SideNav: FC = () => {
  const { clientId } = useClientContext();
  const theme = useTheme();

  return (
    <Sider
      id="chat-sider"
      style={{
        background: 'transparent',
        borderRight: `1px solid ${theme.token.colorBorder}`,
      }}
      width={260}
    >
      <SideNavItemsWrapper>
        <Flex justify="space-between" align="center">
          <Typography.Title level={3}>Nutron</Typography.Title>
          <NutronStatusIcon />
        </Flex>
        <Flex justify="space-between" align="center">
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            Chats
          </Typography.Title>
          <Tooltip overlay="Start a new chat">
            <Link to={newChatRoute({ clientId: clientId! })}>
              <Button type="text" icon={<EditOutlined />} size="small" shape="circle" />
            </Link>
          </Tooltip>
        </Flex>
        <Divider type="horizontal" style={{ margin: '0.5rem 0' }} />
        <NutronChatList />
      </SideNavItemsWrapper>
    </Sider>
  );
};

export const NutronLayout: FC = (_props) => {
  return (
    <>
      <ImpersonationBanner />
      <LayoutWrapper>
        <SideNav />
        <ChatWindowWrapper>
          <Outlet />
        </ChatWindowWrapper>
      </LayoutWrapper>
    </>
  );
};

export type NutronChatInputProps = {
  isDisabled?: boolean;
  isLoading?: boolean;
  onSend: (args: { message: string }) => Promise<void>;
};

export const NutronChatInput: FC<NutronChatInputProps> = (props) => {
  const [form] = Form.useForm();

  return (
    <Form
      autoComplete="off"
      layout="inline"
      style={{ maxWidth: 'none' }}
      form={form}
      onFinish={async (values: { message: string }) => {
        await props.onSend({ message: values.message });
        form.resetFields();
      }}
    >
      <Flex flex={1} vertical={false} align="center" justify="center">
        <Form.Item
          name="message"
          rules={[{ required: true, message: 'Please enter a message.' }]}
          style={{ marginRight: '0.5rem', width: '50%' }}
        >
          <Input.TextArea
            autoFocus
            size="large"
            placeholder="Message Nutron"
            autoSize
            style={{ width: '100%' }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();

                if (!props.isDisabled) {
                  form.submit();
                }
              }
            }}
          />
        </Form.Item>
        <Form.Item>
          <NuButton htmlType="submit" type="primary" loading={props.isLoading} disabled={props.isDisabled}>
            Send
          </NuButton>
        </Form.Item>
      </Flex>
    </Form>
  );
};
