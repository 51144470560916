import EmptyState from 'components/nuspire/nu-empty-state';
import { useEffect } from 'react';
import styled from 'styled-components';
import { WidgetComponentProps } from '../../index';
import { ParentSize } from '@visx/responsive';
import { FunnelChart, FunnelChartData } from 'components/nuspire/charts/funnel-chart';

const Footer = styled.div`
  position: absolute;
  color: ${(p) => p.theme.token.colorTextSecondary};
  font-size: ${(p) => p.theme.token.fontSizeSM}px;
  font-style: italic;
  height: 20px;
  bottom: 20px;
`;

export type ServiceValueWidgetData = {
  alerts?: number;
  logs?: number;
  mitigated?: number;
  serviceOfferingNames?: string[];
  errorMessage?: string;
};

const ServiceValue = (
  props: WidgetComponentProps<any, ServiceValueWidgetData> /* type these once we get real data */,
) => {
  const { data, size = 1 } = props;
  const showZeroLogsWarningEvent = new CustomEvent('zeroLogs:showWarning');

  useEffect(() => {
    if (data?.logs === 0 && data?.errorMessage?.toLowerCase() !== `no servicenow assets found for the account`) {
      document.dispatchEvent(showZeroLogsWarningEvent);
    }
  }, []);

  if (!data) return <EmptyState>No Data Found.</EmptyState>;

  const serviceOfferingNames: string[] = data?.serviceOfferingNames ?? [];
  const serviceOfferingsWithZeroThreatsBlocked = ['RISE OAS'].map((v) => v.toLowerCase());
  const shouldShowZeroThreatsBlockedFootnote = serviceOfferingNames.some((name) =>
    serviceOfferingsWithZeroThreatsBlocked.includes(name.toLowerCase()),
  );

  const funnelData: FunnelChartData[] = [{
    label: 'Logs Collected',
    value: data?.logs ?? 0,
  }, {
    label: `Threats ${shouldShowZeroThreatsBlockedFootnote ? 'Detected' : 'Blocked'}`,
    value: data?.alerts ?? 0,
  }];

  if (!shouldShowZeroThreatsBlockedFootnote) {
    funnelData.push({
      label: 'Threats Mitigated',
      value: data?.mitigated ?? 0,
    });
  }

  return (
    <>
      <ParentSize>
        {({ width, height }) => (
          <FunnelChart
            width={width}
            height={height}
            data={funnelData}
          />
        )}
      </ParentSize>
      <Footer>
        This data is based on the last 90 days.
        {shouldShowZeroThreatsBlockedFootnote && (
          <span>
            &nbsp;Due to your service configuration, Nuspire is unable to block threats.
          </span>
        )}
      </Footer>
    </>
  );
};

export default ServiceValue;
