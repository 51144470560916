import React, { useState } from 'react';
import { message, Space, Timeline, Typography } from 'antd';
import { EmptyState, NuButton } from 'components/nuspire';
import ReactMarkdown from 'react-markdown';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { ActivityTypeEnum, ClientTaskActivity } from 'types/graph-codegen/graph-types';
import { useAuthContext } from 'components/auth-context';
import { EditTaskComment } from './edit-task-comment';
import { gql, useMutation } from '@apollo/client';
import { DeleteTaskCommentButton } from './delete-task-comment';

export type ClientTaskActivityProps = {
  activity?: ClientTaskActivity[];
};

const TimelineContentRoot = styled.div`
  p:last-child {
    margin-bottom: 0;
  }
`;

export function humanReadableType(args: { type: ActivityTypeEnum }) {
  const { type } = args;

  if (type === ActivityTypeEnum.CommentAdded) {
    return 'added a Comment.';
  }

  if (type === ActivityTypeEnum.CommentDeleted) {
    return 'deleted a Comment.';
  }

  if (type === ActivityTypeEnum.CaseOpened) {
    return 'opened Case.';
  }

  if (type === ActivityTypeEnum.CommentEdited) {
    return 'edited a Comment';
  }

  if (type === ActivityTypeEnum.StatusUpdated) {
    return 'updated task Status.';
  }

  return type;
}

export function ActivityType(props: {
  user: { id: string; firstName: string; lastName: string; email: string };
  type: ActivityTypeEnum;
}) {
  const { user, type } = props;

  return (
    <Typography>
      <Typography.Text strong>{`${user.firstName} ${user.lastName} `}</Typography.Text>
      <Typography.Text>{humanReadableType({ type })}</Typography.Text>
    </Typography>
  );
}

const EDIT_COMMENT = gql`
  mutation EditComment($id: String!, $text: String) {
    editTaskComment(id: $id, text: $text) {
      id
      text
      edited
    }
  }
`;

export function TimelineEventContent(props: { activity: ClientTaskActivity }) {
  const {
    activity,
    activity: { id, text, createdAt, type, user, taskId, edited },
  } = props;

  const timeFromNow = dayjs(createdAt).fromNow();
  const { user: authUser } = useAuthContext();

  const isOwner = authUser && user?.id === authUser.id;

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const handleEdit = () => {
    setIsEditing(true);
  };
  const [editMutation, { loading: editLoading }] = useMutation(EDIT_COMMENT);

  const handleEditComplete = async (args: { text: string }) => {
    setIsEditing(false);

    try {
      const updatedText = args.text;

      await editMutation({
        variables: {
          id,
          text: updatedText,
        },
        optimisticResponse: {
          editTaskComment: {
            id,
            text: updatedText,
            edited: true,
            __typename: 'ClientTaskActivity',
          },
        },
      });
    } catch (err) {
      console.error(err);

      message.error('Failed to edit mutation');
    }
  };

  const canEdit = type === 'comment_added' && isOwner;

  return (
    <TimelineContentRoot>
      {type && user && <ActivityType type={type} user={user} />}

      {!isEditing && (
        <Space direction="vertical" size={2}>
          {text && (
            <Space direction="vertical" size={2}>
              {edited && <Typography.Text type="secondary">{`(edited)`}</Typography.Text>}
              <Typography.Text style={{ marginBottom: '0' }}>
                <ReactMarkdown>{text}</ReactMarkdown>
              </Typography.Text>
            </Space>
          )}

          <Space direction="horizontal">
            <Typography.Text type="secondary">{timeFromNow}</Typography.Text>

            {canEdit && (
              <>
                <NuButton size="small" type="text" onClick={handleEdit}>
                  Edit
                </NuButton>

                <DeleteTaskCommentButton id={id} taskId={taskId} />
              </>
            )}
          </Space>
        </Space>
      )}

      {isEditing && (
        <EditTaskComment
          activity={activity}
          onComplete={handleEditComplete}
          onCancel={() => setIsEditing(false)}
          loading={editLoading}
        />
      )}
    </TimelineContentRoot>
  );
}

export function TaskActivity(props: ClientTaskActivityProps) {
  const { activity } = props;

  if (!activity?.length) {
    return <EmptyState>This Task does not have any recorded activity.</EmptyState>;
  }

  return (
    <Timeline>
      {activity?.map((activityItem) => {
        return (
          <Timeline.Item>
            <TimelineEventContent key={activityItem.id} activity={activityItem} />
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
}
