import EmptyState from 'components/nuspire/nu-empty-state';
import { Row, Col, Typography } from 'antd';
import styled from 'styled-components';
import { WidgetComponentProps } from '..';

const RowContainer = styled(Row)`
  margin-bottom: 24px;
  height: 40%;
`;

const Title = styled(Typography.Title)`
  margin-top: 1rem !important;
`;

function CustomFortisiemCountsWidget(
  props: WidgetComponentProps<void, { hour: number; day: number; week: number; month: number }>,
) {
  const { data } = props;

  if (!data) {
    return <EmptyState>Could not fetch your data.</EmptyState>;
  }

  return (
    <>
      <RowContainer gutter={24}>
        <Col span={24} md={12}>
          <Typography.Text>Past Hour</Typography.Text>
          <Title level={2}>
            {data.hour?.toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
          </Title>
        </Col>
        <Col span={24} md={12}>
          <Typography.Text>Past Day</Typography.Text>
          <Title level={2}>
            {data.day?.toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
          </Title>
        </Col>
      </RowContainer>
      <RowContainer gutter={24}>
        <Col span={24} md={12}>
          <Typography.Text>Past Week</Typography.Text>
          <Title level={2}>
            {data.week?.toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
          </Title>
        </Col>
        <Col span={24} md={12}>
          <Typography.Text>Past Month</Typography.Text>
          <Title level={2}>
            {data.month?.toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
          </Title>
        </Col>
      </RowContainer>
    </>
  );
}

export default CustomFortisiemCountsWidget;
