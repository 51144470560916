import { Select } from 'antd';

export const timeRange = [
  { label: 'Past 24 hours', unit: 'day', value: 1, key: 1 },
  { label: 'Past 7 days', unit: 'week', value: 1, key: 2 },
  { label: 'Past month', unit: 'month', value: 1, key: 3 },
  { label: 'Past 3 months', unit: 'month', value: 3, key: 4 },
  { label: 'Past 6 months', unit: 'month', value: 6, key: 5 },
  { label: 'Past year', unit: 'year', value: 1, key: 6 },
];
const status = ['Open', 'Dismissed', 'Snoozed', 'Resolved'];

const WidgetFilter = ({ timeFilter, setTimeFilter, statusFilter, setStatusFilter }) => {
  return (
    <>
      <Select
        style={{ width: '150px' }}
        defaultValue={timeRange[timeFilter].label}
        onChange={(value) => setTimeFilter(+value - 1)}
      >
        {timeRange.map((t) => (
          <Select.Option value={t.key} key={t.key}>
            {t.label}
          </Select.Option>
        ))}
      </Select>
      <Select
        style={{ width: '120px', marginLeft: '10px' }}
        defaultValue={statusFilter}
        onChange={(value) => setStatusFilter(value.toLowerCase())}
      >
        {status.map((s) => (
          <Select.Option value={s} key={s}>
            {s}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default WidgetFilter;
