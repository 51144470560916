import { useEffect, useState } from 'react';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import styled from 'styled-components';
import { gql, useLazyQuery } from '@apollo/client';
import { useClientContext } from 'components/client-context-provider';
import Spin from './spin';
import { GetClientForBreadcrumbQuery, GetClientForBreadcrumbQueryVariables } from 'types/graph-codegen/graph-types';
import { useAuthContext } from 'components/auth-context';
import { Link } from 'components/nuspire';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';

const GET_CLIENT = gql`
  query GetClientForBreadcrumb($clientId: String!) {
    getClientById(id: $clientId) {
      id
      name
      parentId
    }
  }
`;

const BreadcrumbBar = styled(AntBreadcrumb)`
  &.ant-breadcrumb {
    margin-bottom: 16px;
  }
`;

type ClientBreadcrumb = {
  name: string;
  id: string;
};

function getClientBranchBreadcrumbs(args: { clientBranch: ClientBreadcrumb[]; target: string }) {
  const { clientBranch, target } = args;

  const firstNode = clientBranch[clientBranch.length - 1];
  const lastNode = clientBranch.length > 1 ? clientBranch[0] : null;
  const parentNode = clientBranch.length > 2 ? clientBranch[1] : null;
  const breadcrumbs: ItemType[] = [];

  if (lastNode) {
    breadcrumbs.push({
      key: lastNode.id,
      title: (
        <Link to={`/${lastNode.id}/${target}`} mode="plain">
          {lastNode.name}
        </Link>
      ),
    });
  }

  if (parentNode) {
    breadcrumbs.splice(0, 0, {
      key: 'ellipsis',
      title: (
        <Link to={`/${parentNode.id}/${target}`} mode="plain">
          ...
        </Link>
      ),
    });
  }

  if (firstNode) {
    breadcrumbs.splice(0, 0, {
      key: firstNode.id,
      title: (
        <Link to={`/${firstNode.id}/${target}`} mode="plain">
          {firstNode.name}
        </Link>
      ),
    });
  }

  return breadcrumbs;
}

function Breadcrumb(props: { items: ItemType[]; target: string }) {
  const { items, target } = props;
  const { clientId } = useClientContext();
  const { user } = useAuthContext();
  const [clientBranch, setClientBranch] = useState<ClientBreadcrumb[]>([]);
  const [loading, setLoading] = useState(true);

  const [getClientById] = useLazyQuery<GetClientForBreadcrumbQuery, GetClientForBreadcrumbQueryVariables>(GET_CLIENT);

  /**
   * recursively load the client path from the current viewing client to the
   * user's root client.
   *
   * example iteration steps:
   * [grandchild]
   * [child, grandchild]
   * [root, child, grandchild]
   */
  const loadClientPath = async (
    acc: ClientBreadcrumb[],
    currClientId: string,
    rootClientId: string,
  ): Promise<ClientBreadcrumb[]> => {
    const { data } = await getClientById({
      variables: {
        clientId: currClientId,
      },
      fetchPolicy: 'cache-first',
    });

    const currClient = data?.getClientById;
    if (!currClient?.name) return acc;

    acc.push({
      name: currClient.name,
      id: currClientId,
    });

    if (!currClient?.parentId || currClientId === rootClientId) return acc;

    return loadClientPath(acc, currClient.parentId, rootClientId);
  };

  useEffect(() => {
    if (clientId && user?.client) {
      loadClientPath([], clientId, user.client.id).then((v) => {
        setClientBranch(v);
        setLoading(false);
      });
    }
  }, []);

  if (!user?.client || !clientId) {
    return null;
  }

  if (loading) {
    return <Spin style={{ marginBottom: '16px' }} />;
  }

  return <BreadcrumbBar items={[...getClientBranchBreadcrumbs({ clientBranch, target }), ...items]} />;
}

export default Breadcrumb;
