import { gql } from '@apollo/client';
import { config } from '../../config';
import { getAccessToken } from '../access-tokens';

export const typeDefs = gql`
  input PartnerDetailsInput {
    additionalInfo: String
    businessCountry: String!
    businessEmail: String!
    businessPhone: String!
    businessPhoneExt: String
    businessType: String!
  }

  input SignUpRequest {
    firstName: String!
    lastName: String!
    email: String!
    companyName: String!
    persona: String
    password: String!
    isPartner: Boolean
    partnerDetails: PartnerDetailsInput
    industryId: String!
  }

  type SignUpResponse {
    admin: User
    client: Client
  }

  type Industry {
    id: String
    name: String
  }

  type Query {
    _: Boolean
    signUpIndustries: [Industry]
  }

  type Mutation {
    signIn(email: String!, password: String!): User
    signUp(request: SignUpRequest!): SignUpResponse
  }
`;

async function fetchApi(path: `/${string}`, options?: RequestInit) {
  const endpoint = config.apiBaseUrl + path;

  const accessToken = await getAccessToken();
  const headers = new Headers(options?.headers);
  if (accessToken) {
    headers.append('Authorization', `Bearer ${accessToken}`);
  }

  return fetch(endpoint, options);
}

export const resolvers = {
  Query: {
    signUpIndustries: async () => {
      const response = await fetchApi('/industries');
      if (response.status > 400) {
        throw new Error(`Request failed to ${response.url}. Response => ${response.statusText}`);
      }

      const { industries } = await response.json();
      return industries;
    },
  },
  Mutation: {
    signUp: async (_, args) => {
      const { request } = args;

      const response = await fetchApi('/sign-up', {
        body: JSON.stringify(request),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });
      if (response.status > 400) {
        throw new Error(`Request failed to ${response.url}. Response => ${response.statusText}`);
      }

      const { admin, client } = await response.json();
      return {
        admin,
        client,
      };
    },
  },
};
