import React from 'react';
import { Select } from 'antd';
import { ActionInputProps } from '../../action-form-field';
import { gql, useQuery } from '@apollo/client';

export const GET_TECHNOLOGIES = gql`
  query Technologies {
    technologies {
      id
      name
      technologyId
    }
  }
`;

const TechnologySelect = (props: ActionInputProps) => {
  const { value, onChange } = props;
  const { data, loading } = useQuery(GET_TECHNOLOGIES);

  return (
    <Select value={value} onChange={(values) => onChange(values)} placeholder="Technology" loading={loading}>
      {data?.technologies?.map((t) => (
        <Select.Option key={t.technologyId} value={t.technologyId}>
          {t.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default TechnologySelect;
