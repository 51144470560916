import { ClientIdentifier, ClientIdentifierType } from 'types';
import { ClientIdentifier as ClientIdentifierGraph } from 'types/graph-codegen/graph-types';

export type UseClientIdentifierArgs = {
  clientIdentifiers?: (ClientIdentifier | ClientIdentifierGraph)[];
  type: ClientIdentifierType;
};

export function useClientIdentifier(args: UseClientIdentifierArgs): ClientIdentifier | null {
  const { clientIdentifiers, type } = args;

  if (!clientIdentifiers || !clientIdentifiers.length) return null;

  return clientIdentifiers.find((i) => i.type === type) ?? null;
}
