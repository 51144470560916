import PageSection from '../../page-section'
import { Link } from 'react-router-dom';
import { NuButton } from 'components/nuspire';
import { Row, Col } from 'antd'; 
import {
  AreaChartOutlined,
} from '@ant-design/icons';
import { reportingAndAnalysisPath } from 'components/reporting-and-analysis/paths';
import FavoriteDashboardsCard from './favorite-dashboards-card';
import RecentlyViewedDashboardsCard from './recently-viewed-dashboards-card';
import RecentReportsCard from './recent-reports-card';

export default function ReportingAndAnalysisSection(props: {
  clientId: string;
}) {
  
  const { clientId } = props; 

  return (
    <PageSection
      title="Reporting and Analysis"
      icon={<AreaChartOutlined  />}
      actions={(
        <Link to={reportingAndAnalysisPath({ clientId })}>
          <NuButton type="link">
            View in Reporting and Analysis
          </NuButton>
        </Link>
      )}
    >
      <Row gutter={20} style={{ height: '320px' }}>
        <Col lg={8} style={{ height: '100%'}}>
          <FavoriteDashboardsCard clientId={clientId} />
        </Col>
        <Col lg={8} style={{ height: '100%'}}>
          <RecentlyViewedDashboardsCard clientId={clientId} />
        </Col>
        <Col lg={8} style={{ height: '100%'}}>
          <RecentReportsCard clientId={clientId} />
        </Col>
      </Row>
    </PageSection>
  );
}