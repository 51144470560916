import { useEffect, useState } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Formik, useField } from 'formik';
import * as yup from 'yup';
import { Checkbox, Form as AntForm, message, Modal, Select, Tooltip } from 'antd';
import { SelectValue } from 'antd/es/select';
import { IClient } from 'types';
import { config } from 'config';
import { FormikInput, FormikTextarea } from 'components/shared-components';
import { NuButton } from 'components/nuspire';
import { GET_ENTITLEMENTS } from 'components/entitlements';
import { useAuthContext } from 'components/auth-context';

const CREATE_SAML_APP_FOR_CLIENT = gql`
  mutation CreateSAML2AppForClient($input: SAML2AppInput!) {
    createSAML2AppForClient(input: $input)
  }
`;

const ADD_CLIENT_TO_APP_BY_ID = gql`
  mutation AddClientToApplicationById($input: AppIdInput!) {
    addClientToApplicationById(input: $input)
  }
`;

const USERS_BY_CLIENT_ID = gql`
  query GetUsersByClientId($clientId: String) {
    globalGetUsersByClientId(id: $clientId) {
      id
      email
      firstName
      lastName
    }
  }
`;

export const samlAppValidationSchema = yup.object().shape({
  audience: yup.string().required(),
  displayName: yup.string().required(),
  samlEndpoint: yup.string().url().required(),
  usersList: yup.array(),
});

const appIdSchema = yup.object().shape({
  appId: yup.string().required(),
  usersList: yup.array(),
});

export const refetchClientAppsEvent = new CustomEvent('refetch-client-apps');

export type SAML2AppInput = {
  input: {
    assignUsers: string;
    audience: string;
    clientId: string;
    cxpEventDescription?: string;
    displayName: string;
    entitlement?: string;
    samlEndpoint: string;
    sendCxpEvent: boolean;
    updateEntitlement: boolean;
    usersToAssign?: string[];
  };
};

type AppIdInput = {
  input: {
    assignUsers: string;
    appId: string;
    clientId: string;
    cxpEventDescription?: string;
    entitlement?: string;
    sendCxpEvent: boolean;
    updateEntitlement: boolean;
    usersToAssign: string[];
  };
};

type UsersSelectProps = {
  loading: boolean;
  users: any[];
};

function UsersSelect(props: UsersSelectProps) {
  const { loading, users } = props;
  const fieldName = 'usersList';
  const [{ value, onBlur }, { touched, error }, { setValue }] = useField({
    name: fieldName,
  });

  return (
    <AntForm.Item
      label="Users"
      name={fieldName}
      hasFeedback={Boolean(touched && error)}
      validateStatus={touched && error ? 'error' : undefined}
      status={touched && error ? 'error' : undefined}
      required
    >
      <Select
        allowClear
        mode="multiple"
        loading={loading}
        disabled={loading}
        value={value}
        onBlur={onBlur}
        onChange={(newValue) => setValue(newValue)}
      >
        {users.map((user) => (
          <Select.Option key={user.id} value={user.id}>
            {user.firstName || ''} {user.lastName || ''} ({user.email})
          </Select.Option>
        ))}
      </Select>
    </AntForm.Item>
  );
}

type EntitlementSelectProps = {
  entitlements: any[];
  loading: boolean;
};

function EntitlementSelect(props: EntitlementSelectProps) {
  const { entitlements, loading } = props;
  const fieldName = 'entitlement';
  const [{ value, onBlur }, { touched, error }, { setValue }] = useField({
    name: fieldName,
  });

  return (
    <AntForm.Item
      label="Entitlement"
      name={fieldName}
      hasFeedback={Boolean(touched && error)}
      validateStatus={touched && error ? 'error' : undefined}
      status={touched && error ? 'error' : undefined}
      required
    >
      <Select
        loading={loading}
        disabled={loading}
        value={value}
        onBlur={onBlur}
        onChange={(newValue) => setValue(newValue)}
      >
        {entitlements.map((entitlement) => (
          <Select.Option key={entitlement.sys_id} value={entitlement.sys_id}>
            {entitlement.entitlement_name}
          </Select.Option>
        ))}
      </Select>
    </AntForm.Item>
  );
}

export type SAMLAppFormProps = {
  client?: IClient;
  initialValues: any;
  isEditingApp?: boolean;
  loading?: boolean;
  onSubmit: Function;
};

export function SAMLAppForm(props: SAMLAppFormProps) {
  const { client, initialValues, isEditingApp = false, loading, onSubmit } = props;
  const [usersSelectedValue, setUsersSelectedValue] = useState<string>('all');
  const [sendCxpEvent, setSendCxpEvent] = useState<boolean>(false);
  const [updateEntitlement, setUpdateEntitlement] = useState<boolean>(false);
  const [getUsersForClient, { data: userData, error: usersError, loading: usersLoading }] = useLazyQuery(
    USERS_BY_CLIENT_ID,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        clientId: client?.id,
      },
    },
  );
  const [getEntitlementsForClient, { data: entitlementsData, error: entitlementsError, loading: entitlementsLoading }] =
    useLazyQuery(GET_ENTITLEMENTS, {
      notifyOnNetworkStatusChange: true,
      variables: {
        clientId: client?.id,
      },
    });

  if (usersError) {
    message.error(`There was an error fetching users for this client: ${usersError.message}`);
  }
  if (entitlementsError) {
    message.error(`There was an error fetching entitlements for this client: ${entitlementsError.message}`);
  }

  const onUsersSelectedValueChange = (newValue: SelectValue) => {
    if (newValue === 'select') {
      getUsersForClient();
    }
    setUsersSelectedValue(newValue as string);
  };

  useEffect(() => {
    if (!updateEntitlement) return;

    getEntitlementsForClient();
  }, [updateEntitlement]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, helpers) => {
        const allValues = {
          ...values,
          sendCxpEvent,
          updateEntitlement,
          usersSelectedValue,
        };
        onSubmit(allValues, helpers);
      }}
      validationSchema={samlAppValidationSchema}
    >
      {({ submitForm, isSubmitting, errors, dirty }) => (
        <AntForm layout="vertical" onFinish={() => submitForm()}>
          <FormikInput
            name="audience"
            label="Audience Restriction"
            required
            tooltip="This is the audience this app is restricted to (ex: FortiSIEM org name)."
          />
          <FormikInput
            name="displayName"
            label="Display Name"
            required
            placeholder={`FortiSIEM SSO for Client ${client?.name || 'Unknown'}`}
          />
          <FormikInput
            name="samlEndpoint"
            label="SAML Endpoint"
            required
            tooltip="This is the URL of the application where the SAML assertion is sent."
            placeholder={`${config.fortisiemBaseUrl}/phoenix/okta`}
          />

          <AntForm.Item label="Who do you want to assign to this app?" required>
            <Select value={usersSelectedValue} onChange={onUsersSelectedValueChange}>
              <Select.Option value="all">All users in this client</Select.Option>
              <Select.Option value="select">Manually select users</Select.Option>
            </Select>
          </AntForm.Item>

          {usersSelectedValue === 'select' && (
            <UsersSelect loading={usersLoading} users={userData?.globalGetUsersByClientId || []} />
          )}

          <AntForm.Item name="sendCxpEvent" valuePropName="checked">
            <Tooltip
              overlay={`Do you want to ${
                isEditingApp ? 'resend' : 'send'
              } an event through CXP (ex: to notify that additional steps are needed)?`}
            >
              <Checkbox checked={sendCxpEvent} onChange={(e) => setSendCxpEvent(e.target.checked)}>
                {isEditingApp ? 'Resend' : 'Send'} CXP Event
              </Checkbox>
            </Tooltip>
          </AntForm.Item>

          {sendCxpEvent && (
            <FormikTextarea
              name="cxpEventDescription"
              label="CXP Event Description"
              required
              tooltip="This will be the description of the CXP event/incident that is created."
              rows={2}
            />
          )}

          <AntForm.Item name="updateEntitlement" valuePropName="checked">
            <Tooltip overlay="This will allow you to select an entitlement to update.">
              <Checkbox checked={updateEntitlement} onChange={(e) => setUpdateEntitlement(e.target.checked)}>
                Update Entitlement URL
              </Checkbox>
            </Tooltip>
          </AntForm.Item>

          {updateEntitlement && (
            <div style={{ marginTop: '24px' }}>
              <EntitlementSelect entitlements={entitlementsData?.entitlements || []} loading={entitlementsLoading} />
            </div>
          )}

          <AntForm.Item>
            <NuButton
              type="primary"
              htmlType="submit"
              disabled={!dirty || Object.keys(errors).length > 0 || isSubmitting}
              loading={loading || isSubmitting}
            >
              Submit
            </NuButton>
          </AntForm.Item>
        </AntForm>
      )}
    </Formik>
  );
}

type AppIdFormProps = {
  client?: IClient;
  onClose: Function;
};

function AppIdForm(props: AppIdFormProps) {
  const { client, onClose } = props;
  const [usersSelectedValue, setUsersSelectedValue] = useState<string>('all');
  const [sendCxpEvent, setSendCxpEvent] = useState<boolean>(false);
  const [updateEntitlement, setUpdateEntitlement] = useState<boolean>(false);
  const [addClientToAppById, { loading }] = useMutation(ADD_CLIENT_TO_APP_BY_ID);
  const [getUsersForClient, { data: userData, error: usersError, loading: usersLoading }] = useLazyQuery(
    USERS_BY_CLIENT_ID,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        clientId: client?.id,
      },
    },
  );
  const [getEntitlementsForClient, { data: entitlementsData, error: entitlementsError, loading: entitlementsLoading }] =
    useLazyQuery(GET_ENTITLEMENTS, {
      notifyOnNetworkStatusChange: true,
      variables: {
        clientId: client?.id,
      },
    });

  if (usersError) {
    message.error(`There was an error fetching users for this client: ${usersError.message}`);
  }
  if (entitlementsError) {
    message.error(`There was an error fetching entitlements for this client: ${entitlementsError.message}`);
  }

  const onUsersSelectedValueChange = (newValue: SelectValue) => {
    if (newValue === 'select') {
      getUsersForClient();
    }
    setUsersSelectedValue(newValue as string);
  };

  useEffect(() => {
    if (!updateEntitlement) return;

    getEntitlementsForClient();
  }, [updateEntitlement]);

  return (
    <Formik
      initialValues={{
        appId: '',
      }}
      onSubmit={async (values: any, { setSubmitting }) => {
        if (!client || !client.id) {
          setSubmitting(false);
          message.error('Client not found. Unable to add client to application.');
          return;
        }

        const { appId, cxpEventDescription, entitlement, usersList } = values;

        const data: AppIdInput = {
          input: {
            assignUsers: usersSelectedValue,
            appId,
            clientId: client.id,
            cxpEventDescription,
            entitlement,
            sendCxpEvent,
            updateEntitlement,
            usersToAssign: usersSelectedValue === 'select' ? usersList! : [],
          },
        };

        try {
          await addClientToAppById({
            variables: data,
          });

          window.dispatchEvent(refetchClientAppsEvent);
          message.success('Client added to application successfully!');
          onClose();
        } catch (err: any) {
          message.error(`There was an error adding client to application application: ${err.message}`);
        } finally {
          setSubmitting(false);
        }
      }}
      validationSchema={appIdSchema}
    >
      {({ submitForm, isSubmitting, errors, dirty }) => (
        <AntForm layout="vertical" onFinish={() => submitForm()}>
          <FormikInput name="appId" label="Application ID" required />

          <AntForm.Item label="Who do you want to assign to this app?" required>
            <Select value={usersSelectedValue} onChange={onUsersSelectedValueChange}>
              <Select.Option value="all">All users in this client</Select.Option>
              <Select.Option value="select">Manually select users</Select.Option>
            </Select>
          </AntForm.Item>

          {usersSelectedValue === 'select' && (
            <UsersSelect loading={usersLoading} users={userData?.globalGetUsersByClientId || []} />
          )}

          <AntForm.Item name="sendCxpEvent" valuePropName="checked">
            <Tooltip overlay="Do you want to send an event through CXP (ex: to notify that additional steps are needed)?">
              <Checkbox checked={sendCxpEvent} onChange={(e) => setSendCxpEvent(e.target.checked)}>
                Send CXP Event
              </Checkbox>
            </Tooltip>
          </AntForm.Item>

          {sendCxpEvent && (
            <FormikTextarea
              name="cxpEventDescription"
              label="CXP Event Description"
              required
              tooltip="This will be the description of the CXP event/incident that is created."
              rows={2}
            />
          )}

          <AntForm.Item name="updateEntitlement" valuePropName="checked">
            <Tooltip overlay="This will allow you to select an entitlement to update.">
              <Checkbox checked={updateEntitlement} onChange={(e) => setUpdateEntitlement(e.target.checked)}>
                Update Entitlement URL
              </Checkbox>
            </Tooltip>
          </AntForm.Item>

          {updateEntitlement && (
            <div style={{ marginTop: '24px' }}>
              <EntitlementSelect entitlements={entitlementsData?.entitlements || []} loading={entitlementsLoading} />
            </div>
          )}

          <AntForm.Item>
            <NuButton
              type="primary"
              htmlType="submit"
              disabled={!dirty || Object.keys(errors).length > 0 || isSubmitting}
              loading={loading || isSubmitting}
            >
              Submit
            </NuButton>
          </AntForm.Item>
        </AntForm>
      )}
    </Formik>
  );
}

export type CreateAppModalProps = {
  client?: IClient;
  onClose: Function;
  visible: boolean;
};

export default function CreateAppModal(props: CreateAppModalProps) {
  const { client, onClose, visible } = props;
  const [action, setAction] = useState<string>();
  const [createSAMLApp, { loading }] = useMutation(CREATE_SAML_APP_FOR_CLIENT);
  const { user } = useAuthContext();

  return (
    <Modal
      title="Create Application"
      open={visible}
      onOk={() => onClose()}
      onCancel={() => onClose()}
      footer={null}
      width={600}
    >
      <AntForm layout="vertical">
        <AntForm.Item label="Action" rules={[{ required: true, message: 'Please select an action' }]}>
          <Select value={action} onChange={(newValue) => setAction(newValue)}>
            <Select.Option value="saml">Create a SAML Application</Select.Option>
            <Select.Option value="appId">Add Client to Existing Application by ID</Select.Option>
          </Select>
        </AntForm.Item>
      </AntForm>

      {action === 'saml' && (
        <SAMLAppForm
          client={client}
          initialValues={{
            audience: '',
            displayName: '',
            samlEndpoint: '',
            usersList: [] as string[],
          }}
          loading={loading}
          onSubmit={async (values: any, { setSubmitting }) => {
            if (!user?.isSuperUser) {
              message.error('You do not have the required permissions to create applications.');
              setSubmitting(false);
              return;
            }

            if (!client || !client.id) {
              setSubmitting(false);
              message.error('Client not found. Unable to create SAML application.');
              return;
            }

            const {
              audience,
              cxpEventDescription,
              displayName,
              entitlement,
              samlEndpoint,
              sendCxpEvent,
              updateEntitlement,
              usersList,
              usersSelectedValue,
            } = values;

            const data: SAML2AppInput = {
              input: {
                assignUsers: usersSelectedValue,
                audience,
                clientId: client.id,
                cxpEventDescription,
                displayName,
                entitlement,
                samlEndpoint,
                sendCxpEvent,
                updateEntitlement,
                usersToAssign: usersSelectedValue === 'select' ? usersList : [],
              },
            };

            try {
              await createSAMLApp({
                variables: data,
              });

              window.dispatchEvent(refetchClientAppsEvent);
              message.success('SAML application created successfully!');
              onClose();
            } catch (err: any) {
              message.error(`There was an error creating the SAML application: ${err.message}`);
            } finally {
              setSubmitting(false);
            }
          }}
        />
      )}
      {action === 'appId' && <AppIdForm client={client} onClose={onClose} />}
    </Modal>
  );
}
