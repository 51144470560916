import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { WidgetDefinition, WidgetRendererProps } from 'components/dashboard/widget-definitions/types';
import { CountsDoughnut, IDoughnutMapping, ISentinelOneItemCount } from '../sentinelone-doughnut';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { EmptyState, NuCardContent } from 'components/nuspire';
import ApiTokenError from '../api-token-error';

const SENTINELONE_THREATS_BY_TYPE_COUNT_QUERY = gql`
  query SentinelOneThreatsByTypeCountWidget($connectionId: String!, $endDate: String, $startDate: String) {
    clientConnection(id: $connectionId) {
      id
      publicConnectionDataJson
      sentinelOneThreatsByTypeCount(endDate: $endDate, startDate: $startDate) {
        key
        title
        values {
          count
          title
          value
        }
      }
    }
  }
`;

const defaultEndDate = dayjs().toDate();
const defaultStartDate = dayjs().subtract(3, 'months').toDate();

const THREATS_BY_TYPE_MAP: IDoughnutMapping = {
  Benign: {
    label: 'Benign',
    backgroundColor: 'rgb(215,0,217)',
  },
  Cryptominer: {
    label: 'Cryptominer',
    backgroundColor: 'rgb(69,162,255)',
  },
  Ransomware: {
    label: 'Ransomware',
    backgroundColor: 'rgb(81,130,255)',
  },
  Malware: {
    label: 'Malware',
    backgroundColor: 'rgb(43,6,160)',
  },
  Trojan: {
    label: 'Trojan',
    backgroundColor: 'rgb(175,142,239)',
  },
  Virus: {
    label: 'Virus',
    backgroundColor: 'rgb(94,227,234)',
  },
  Exploit: {
    label: 'Exploit',
    backgroundColor: 'rgb(79,89,98)',
  },
  'Malicious Office Document': {
    label: 'Malicious Office Document',
    backgroundColor: 'rgb(149,158,161)',
  },
  'Malicious PDF': {
    label: 'Malicious PDF',
    backgroundColor: 'rgb(198,202,203)',
  },
  Worm: {
    label: 'Worm',
    backgroundColor: 'rgb(81,130,155)',
  },
  Rootkit: {
    label: 'Rootkit',
    backgroundColor: 'rgb(200,250,150)',
  },
  Infostealer: {
    label: 'Infostealer',
    backgroundColor: 'rgb(150,50,90)',
  },
  Downloader: {
    label: 'Downloader',
    backgroundColor: 'rgb(100,150,150)',
  },
  Backdoor: {
    label: 'Backdoor',
    backgroundColor: 'rgb(150,90,150)',
  },
  Hacktool: {
    label: 'Hacktool',
    backgroundColor: 'rgb(60,60,255)',
  },
  Browser: {
    label: 'Browser',
    backgroundColor: 'rgb(255,90,150)',
  },
  Dialer: {
    label: 'Dialer',
    backgroundColor: 'rgb(100,130,255)',
  },
  Packed: {
    label: 'Packed',
    backgroundColor: 'rgb(70,80,90)',
  },
  Network: {
    label: 'Network',
    backgroundColor: 'rgb(53,150,150)',
  },
  Spyware: {
    label: 'Spyware',
    backgroundColor: 'rgb(255,130,255)',
  },
  Adware: {
    label: 'Adware',
    backgroundColor: 'rgb(100,150,100)',
  },
  PUA: {
    label: 'PUA',
    backgroundColor: 'rgb(100,100,255)',
  },
  'Interactive shell': {
    label: 'Interactive shell',
    backgroundColor: 'rgb(0,0,0)',
  },
  'Lateral Movement': {
    label: 'Lateral Movement',
    backgroundColor: 'rgb(200,100,45)',
  },
  'Remote shell': {
    label: 'Remote shell',
    backgroundColor: 'rgb(34,200,67)',
  },
  Manual: {
    label: 'Manual',
    backgroundColor: 'rgb(78,100,170)',
  },
  'Generic.Heuristic': {
    label: 'Generic.Heuristic',
    backgroundColor: 'rgb(110,42,240)',
  },
  'Application Control': {
    label: 'Application Control',
    backgroundColor: 'rgb(200,40,200)',
  },
};

function SentinelOneThreatsByTypeCountWidgetComponent(props: WidgetRendererProps) {
  const connectionId = props?.configuration?.connectionId ?? null;
  const { data, loading, error } = useQuery(SENTINELONE_THREATS_BY_TYPE_COUNT_QUERY, {
    variables: {
      connectionId,
      endDate: defaultEndDate.toISOString(),
      startDate: defaultStartDate.toISOString(),
    },
  });
  const threatsByTypeCounts = data?.clientConnection?.sentinelOneThreatsByTypeCount as ISentinelOneItemCount;

  if (!data && loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  if (threatsByTypeCounts) {
    return (
      <CountsDoughnut counts={threatsByTypeCounts.values} map={THREATS_BY_TYPE_MAP} />
    );
  }

  const errorMsg = error?.message;
  if (errorMsg === 'Your API Key has expired.' || errorMsg === 'API token does not exist') {
    return <ApiTokenError connectionId={connectionId} errorMsg={errorMsg} />;
  }

  return (
    <NuCardContent>
      <EmptyState>Unable to fetch threats by type counts.</EmptyState>
    </NuCardContent>
  );
}

const SentinelOneThreatsByTypeCountWidget: WidgetDefinition = {
  name: 'SentinelOne Threats By Type Count',
  description: 'SentinelOne Threats By Type Count',
  slug: 'sentinelone-threats-by-type-count',
  connectorSlug: 'sentinelone',
  component: SentinelOneThreatsByTypeCountWidgetComponent,
};

export default SentinelOneThreatsByTypeCountWidget;
