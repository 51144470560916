import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Table, Tag } from 'antd';
import { WidgetDefinition, WidgetRendererProps } from 'components/dashboard/widget-definitions/types';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { ALERTS_BY_SEVERITY_QUERY } from './alerts-by-severity';
import styled from 'styled-components';
import WidgetFilter, { timeRange } from '../widget-filter';
import { EmptyState, NuCardContent } from 'components/nuspire';

const TableContainer = styled.div`
  max-height: 330px;
  overflow-y: scroll;
  margin-top: 14px;
`;

const getColor = (severity) => {
  if (severity === 'low') {
    return 'yellow';
  }

  if (severity === 'medium') {
    return 'orange';
  }

  if (severity === 'high') {
    return 'red';
  }
  return 'default';
};

const tableColumns = [
  {
    title: 'Policy Name',
    dataIndex: 'policy',
    key: 'policy',
    render: (policy: any) => {
      return policy.name;
    },
  },
  {
    title: 'Alerts',
    dataIndex: 'alertCount',
    key: 'alertCount',
    align: 'center' as 'center',
    render: (alertCount: number) => {
      return alertCount;
    },
  },
  {
    title: `Policy Type`,
    dataIndex: `policy`,
    key: `policy`,
    align: 'center' as 'center',
    render: (policy: any) => policy.policyType,
  },
  {
    title: `Severity`,
    dataIndex: `policy`,
    key: `policy`,
    align: 'center' as 'center',
    render: (policy: any) => <Tag color={getColor(policy.severity)}>{policy.severity}</Tag>,
  },
];

function DetailsByTopAlertsWidgetComponent(props: WidgetRendererProps) {
  const [timeFilter, setTimeFilter] = useState(2);
  const [statusFilter, setStatusFilter] = useState('open');
  const connectionId = props?.configuration?.connectionId ?? null;
  const { data, loading } = useQuery(ALERTS_BY_SEVERITY_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      connectionId,
      input: {
        filters: [
          {
            name: 'alert.status',
            operator: '=',
            value: statusFilter,
          },
        ],
        timeRange: {
          type: 'relative',
          value: {
            amount: timeRange[timeFilter].value,
            unit: timeRange[timeFilter].unit,
          },
        },
      },
    },
  });
  const alerts = data?.clientConnection?.alertCountsByPolicy;
  const tableData = alerts
    ?.slice()
    .sort((a, b) => a?.alertCount < b?.alertCount)
    .slice(0, 10);

  if (!alerts && !loading) {
    return (
      <NuCardContent>
        <EmptyState>Unable to fetch Alerts.</EmptyState>
      </NuCardContent>
    );
  }

  return (
    <NuCardContent>
      <WidgetFilter
        timeFilter={timeFilter}
        setTimeFilter={setTimeFilter}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
      />
      {loading ? (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      ) : (
        <TableContainer>
          <Table rowKey="id" columns={tableColumns} dataSource={tableData} pagination={false} />
        </TableContainer>
      )}
    </NuCardContent>
  );
}

const DetailsByTopAlertsWidget: WidgetDefinition = {
  name: 'Prisma Cloud Details By Top Alerts',
  slug: 'prisma-cloud-details-by-top-alerts',
  connectorSlug: 'prisma-cloud',
  component: DetailsByTopAlertsWidgetComponent,
  description: 'See details on your most common alerts',
};

export default DetailsByTopAlertsWidget;
