import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';

export const defaultCountry = 'US';

export function getCountryCodeKey(phoneNumber?: string, country?: string): string | null {
  let phoneCode = phoneNumber?.includes(' ') ? phoneNumber.split(' ')[0]?.replace('+', '') ?? null : null;
  if (phoneNumber && !phoneCode) {
    const parsed = parsePhoneNumber(phoneNumber);

    phoneCode = parsed.countryCallingCode;
  }
  const phoneCountryCodeKey = country && phoneCode ? `${country}-${phoneCode}` : null;

  return phoneCountryCodeKey;
}

export function formatPhoneNumber(phone?: string, extension?: string): string | undefined {
  if (!phone) return undefined;

  try {
    const parsed = parsePhoneNumber(phone, defaultCountry);
    if (extension) parsed.setExt(extension);
    return parsed.formatInternational();
  } catch (err) {
    console.warn(err);
  }

  return undefined;
}

export function parsePhoneNumberFromFormattedString(
  input?: string,
  country?: CountryCode,
): {
  country?: string;
  extension?: string;
  nationalNumber?: string;
  phoneNumber?: string;
  uri?: string;
} {
  const values: { country?: string; extension?: string; nationalNumber?: string; phoneNumber?: string; uri?: string } =
    {};
  if (!input) return values;

  const parsed = parsePhoneNumber(input, country);
  values.country = parsed.country ?? defaultCountry;
  values.extension = parsed.ext;
  values.nationalNumber = parsed.nationalNumber;
  values.phoneNumber = parsed.number;
  values.uri = parsed.getURI();

  return values;
}
