import { JiraActionInputProps } from './jira-issue-type-fields';
import { gql, useQuery } from '@apollo/client';
import { Select } from 'antd';

const JIRA_AUTO_COMPLETE_INPUT = gql`
  query JiraAutoCompleteInput($connectionId: String!, $autoCompleteUrl: String!) {
    clientConnection(id: $connectionId) {
      id
      jiraAutoComplete(autoCompleteUrl: $autoCompleteUrl)
    }
  }
`;

interface UserAssignableSearchItem {
  self: string; // url to user profile?
  accountId: string; // id?
  accountType: string;
  emailAddress: string;
  displayName: string;
  active: boolean;
  //...
}

function JiraAutoCompleteInput(props: JiraActionInputProps) {
  const {
    value,
    onChange,
    formik,
    jiraField: { autoCompleteUrl },
  } = props;
  const values = formik?.values;
  const connectionId = values?.connection?.id ?? null;
  const { data, loading } = useQuery(JIRA_AUTO_COMPLETE_INPUT, {
    variables: {
      connectionId,
      autoCompleteUrl,
    },
    skip: !autoCompleteUrl,
  });

  const options = data?.clientConnection?.jiraAutoComplete
    ? (JSON.parse(data.clientConnection.jiraAutoComplete) as UserAssignableSearchItem[])
    : [];

  return (
    <Select
      loading={loading}
      value={value?.id}
      onChange={(id) => onChange({ id })}
      showSearch
      placeholder="Select Assignee"
      optionFilterProp="children"
      filterOption={(input, option) => {
        console.log({ input, option });

        return option?.children?.some((o) => input.toLowerCase() === o.label) ?? false;
      }}
    >
      {options.map((option) => {
        const { emailAddress, accountId, displayName } = option;

        return (
          <Select.Option key={accountId} value={accountId}>
            {`${displayName} (${emailAddress})`}
          </Select.Option>
        );
      })}
    </Select>
  );
}

export default JiraAutoCompleteInput;
