import { Table, Modal } from 'antd';

const dataset = [
  {
    priorityLevel: '1 - Critical',
    clientRequest: 'N/A',
    monitoringNotification: '15 Minutes',
  },
  {
    priorityLevel: '2 - High',
    clientRequest: 'N/A',
    monitoringNotification: '60 Minutes',
  },
  {
    priorityLevel: '3 - Moderate',
    clientRequest: '120 minutes',
    monitoringNotification: '120 Minutes',
  },
  {
    priorityLevel: '4 - Low',
    clientRequest: '120 minutes',
    monitoringNotification: '240 Minutes',
  },
];

const columns = [
  {
    title: 'Priority Level',
    dataIndex: 'priorityLevel',
    key: 'priorityLevel',
  },
  {
    title: 'Client Request',
    dataIndex: 'clientRequest',
    key: 'clientRequest',
  },
  {
    title: 'Monitoring Notification',
    dataIndex: 'monitoringNotification',
    key: 'monitoringNotification',
  },
];

const PriorityDetailsModal = (props: { visible: boolean; onClose: Function }) => {
  const { visible, onClose } = props;
  return (
    <Modal title="Priority Details" footer={false} open={visible} onCancel={() => onClose(false)}>
      <Table dataSource={dataset} columns={columns} pagination={false} />
    </Modal>
  );
};

export default PriorityDetailsModal;
