import { gql } from '@apollo/client';
import { message } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { ButtonGroupProps } from 'antd/es/button';
import { IClient } from 'types';
import MutationButton from 'components/mutation-button';

const RESTORE_CLIENT_MUTATION = gql`
  mutation RestoreClient($clientId: String!) {
    restoreClient(clientId: $clientId)
  }
`;

type RestoreClientButtonProps = ButtonGroupProps & {
  authorized: boolean;
  client: IClient;
  onSuccess?: Function;
};

type RestoreClientData = {
  restoreClient?: boolean;
};

export default function RestoreClientButton(props: RestoreClientButtonProps) {
  const { authorized, client, onSuccess, ...buttonProps } = props;

  const { id: clientId } = client;
  const variables = {
    clientId,
  };
  const modalTitle = `Are you sure you want to restore ${client.name}?`;

  const onCompleted = (data: RestoreClientData) => {
    if (data.restoreClient !== undefined) {
      const { restoreClient: result } = data;

      // Restore was unsuccessful.
      if (!result) {
        onError();
        return;
      }

      // Restore was successful.
      message.success(`${client.name} has been restored!`);
      if (onSuccess) onSuccess();
    }
  };

  const onError = (error?: any) => {
    // Display the error message if we have one.
    const errorMessage = `There was an error restoring ${client.name}.${error ? ` Error: ${error.message}` : `.`}`;

    message.error(errorMessage);
  };

  return (
    <MutationButton
      authorized={authorized}
      buttonIcon={<SyncOutlined />}
      buttonType="primary"
      modalTitle={modalTitle}
      mutation={RESTORE_CLIENT_MUTATION}
      mutationVariables={variables}
      onCompleted={onCompleted}
      onError={onError}
      tooltipOverlay="Restore Client"
      unauthorizedMessage="You do not have the required permissions to restore clients."
      {...buttonProps}
    />
  );
}
