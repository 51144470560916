import { gql, useMutation } from '@apollo/client';
import { message, Space, Typography } from 'antd';
import { BlockObject, Blocks } from 'components/blocks/blocks';
import { NuCard, NuCardContent, NuCardTitle } from 'components/nuspire';
import { TaskTaskActionStatus } from 'types/graph-codegen/graph-types';

// from `ClientTaskModalQuery`
type TClientTaskAction = {
  __typename?: 'ClientTaskAction';
  id: string;
  title: string;
  blocks: Array<Record<string, any>>;
  status: TaskTaskActionStatus;
  createdAt: Date | string;
};

const TAKE_ACTION = gql`
  mutation TakeAction($actionId: String!, $actions: [JSONObject!]!) {
    takeTaskAction(actionId: $actionId, actions: $actions) {
      id
      actionTaken {
        user {
          id
          firstName
          lastName
          email
        }
        actions
        datetime
      }

      clientTask {
        id
        snowCaseNumber
        actions {
          id
          title
          blocks
          status
          createdAt
        }
        activity {
          id
          text
          ok
          user {
            id
            firstName
            lastName
            email
          }
          data
        }
      }
    }
  }
`;

export function ActionCard(props: { action: TClientTaskAction }) {
  const {
    action: { id, title, blocks },
  } = props;

  const [takeAction] = useMutation(TAKE_ACTION);

  const handleAction = async (args) => {
    const { actions } = args;
    console.log('handleAction', { args });

    try {
      await takeAction({
        variables: {
          actions,
          actionId: id,
        },
      });

      message.success('Successfully Executed Action');
    } catch (err: any) {
      console.error(err);
      message.error('Failed to Execute Action');
    }
  };

  return (
    <NuCard>
      <NuCardTitle title={title} />
      <NuCardContent>
        <Blocks blocks={blocks as BlockObject[]} onAction={handleAction} />
      </NuCardContent>
    </NuCard>
  );
}

export function ClientTaskActions(props: { actions?: TClientTaskAction[] }) {
  const { actions } = props;

  if (!actions?.length) {
    return null;
  }

  return (
    <>
      <Typography.Title level={5}>{`Action${actions.length > 1 ? 's' : ''}`}</Typography.Title>
      <Space direction="vertical">
        {actions.map((action) => (
          <ActionCard action={action} key={action.id} />
        ))}
      </Space>
    </>
  );
}
