import { Modal, Dropdown, MenuProps } from 'antd';
import { useClientContext } from 'components/client-context-provider';
import { Link, NuButton } from 'components/nuspire';
import { useCallback, useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import Input from 'components/nuspire/input';
import debounce from 'lodash.debounce';
import InfiniteTable from 'components/nuspire/infinite-table';
import { client as graphClient } from 'utils/graphql';
import { DownOutlined } from '@ant-design/icons';
import * as NuIcon from 'components/nuspire/nu-icon';
import { TableRowSelection } from 'antd/es/table/interface';
import { IClient } from '../../types';
import { Features, useFeatureFlag } from 'components/feature-flags';
import { dashboardDetailPath } from 'components/reporting-and-analysis/paths';

const DASHBOARD_SEARCH = gql`
  query DashboardSearch(
    $clientId: String!
    $queryString: String
    $next: String
    $sharedWithMyClient: Boolean
    $shared: Boolean
  ) {
    dashboardSearch(
      clientId: $clientId
      queryString: $queryString
      size: 100
      next: $next
      sharedWithMyClient: $sharedWithMyClient
      shared: $shared
    ) {
      next
      total
      items {
        id
        name
        clientId
        shortId
        client {
          id
          name
        }
      }
    }
  }
`;

/**
 * Control search api.
 */

interface Dashboard {
  id: string;
  shortId: string;
  clientId: string;
}

enum SharedFilter {
  shared = 'shared',
  sharedWithMe = 'shared-with-me',
}

interface DashboardModalApi {
  queryString?: string;
  onQueryString: (q?: string) => void;

  sharedFilter?: SharedFilter;
  setSharedFilter: (f?: SharedFilter) => void;

  loading?: boolean;
  items?: Dashboard[];
}

/**
 * Filters/controls
 */
const DmcRoot = styled.div`
  display: flex;
  margin-bottom: 16px;

  > * {
    margin-right: 8px;
  }
`;

function getSharedLabel(sharedFilter?: SharedFilter) {
  if (sharedFilter === SharedFilter.shared) {
    return 'Shared with others';
  }

  if (sharedFilter === SharedFilter.sharedWithMe) {
    return 'Shared with us';
  }

  return 'Shared...';
}

function SharedFilterButton(props: { api: DashboardModalApi }) {
  const {
    api: { sharedFilter, setSharedFilter },
  } = props;

  const label = getSharedLabel(sharedFilter);

  const menu: MenuProps = {
    onClick: (e) => {
      const filter = e.key as any;

      setSharedFilter(filter !== 'clear' ? filter : undefined);
    },
    selectedKeys: sharedFilter ? [sharedFilter] : undefined,
    items: [
      {
        key: SharedFilter.shared,
        label: 'With others',
      },
      {
        key: SharedFilter.sharedWithMe,
        label: 'With us',
      },
      {
        type: 'divider',
      },
      {
        key: 'clear',
        label: 'Clear',
      },
    ],
  };

  return (
    <div>
      <Dropdown menu={menu} trigger={['click']}>
        <NuButton icon={<DownOutlined />} size="large" type={sharedFilter ? 'primary' : 'text'}>
          {label}
        </NuButton>
      </Dropdown>
    </div>
  );
}

function DashboardModalControls(props: { api: DashboardModalApi }) {
  const { queryString, onQueryString } = props.api;
  const [searchValue, setSearchValue] = useState<string | undefined>(queryString);

  /**
   * Debounce search
   */
  const debouncedSearch = useCallback(
    debounce((newSearch: string | undefined) => {
      onQueryString(newSearch);
    }, 600),
    [],
  );

  useEffect(() => {
    debouncedSearch(searchValue);
  }, [searchValue]);

  return (
    <DmcRoot>
      <Input
        placeholder="Search Dashboards"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        style={{ width: '250px', marginRight: '16px' }}
        size="large"
        allowClear
      />

      <SharedFilterButton {...props} />
    </DmcRoot>
  );
}

export function DashboardsList(props: { rowSelection?: TableRowSelection<any>; onClose?: Function }) {
  const { rowSelection, onClose } = props;
  const { clientId, client } = useClientContext();
  const [queryString, setQueryString] = useState<string | undefined>(undefined);
  const [sharedFilter, setSharedFilter] = useState<SharedFilter | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<Dashboard[]>([]);
  const [next, setNext] = useState<string | undefined>(undefined);

  // update search query string var
  const handleQueryString = (q?: string) => {
    if (q?.length) {
      setQueryString(q);
    } else {
      setQueryString(undefined);
    }
  };

  const shouldNotHaveSecurityReview = (args: { client?: IClient }): boolean => {
    const { client } = args;
    if (!client) {
      return false;
    }

    // these are the clients we do not want to see security review dashboards
    // include the client id here if you want that client to have dashboards filtered
    const clientIds = [
      '011c2230-3354-4eb0-aa27-00250e652b18',
      '2fb08710-325e-4d95-a158-3509641be1b8',
      'c443c493-7791-4fcf-a70b-c8d951a121c5',
      'ba696f93-8465-4d5d-ae5a-63b65fd3ffad',
      // 'b5799d40-d6bc-4513-a1e0-342335be5c79', // big acme
    ];

    // if the current client has any of those ids in their path, they should also not see security review dashboards
    return clientIds.some((clientId) => client?.path?.includes(clientId));
  };

  const isSecurityReviewDashboard = (args: { dashboardId: string }): boolean => {
    const { dashboardId } = args;

    // these are all of the dashboards for security review
    // add any others you don't want to show up in here
    // we can take a similar approach to widgets if we need to
    const dashboardIds = [
      '18f78d0f-c613-4d35-8cbe-d31aea3f882e', // Barracuda Firewalll
      'a443428c-f6a4-49c3-8217-4d488f1d9aa7', // Cato Networks Firewall
      'f49ae2f1-8627-4112-824b-e92d9a5e7cf4', // Check Point Firewall
      '5291075f-9f74-4674-b85c-61deb64f4080', // Cisco AMP
      '4d41c61f-c480-44ba-8434-f2009b4e8378', // Cisco ASA Firewall
      '12033397-2c41-4a18-8592-814bdd02109a', // Cisco Firepower Firewall
      '4b2e96dc-6008-4a23-8c27-227e64f3739d', // Cisco Meraki Firewall
      '4865b4bd-fbf5-4569-a458-b0530257adb6', // Cisco Umbrella
      'f861d220-0ca2-4653-9efd-13509a7c3f71', // Fortigate Firewall
      'fa602f61-68fc-45ba-bd2e-914b989f4019', // Linux
      'e1351316-94a2-4734-acb0-0280509d2c1c', // Office 365/Azure AD
      '6778e851-3434-4805-9d12-18774a6a2ec5', // Okta
      '85002bd3-847e-47c4-85d7-0965bd14e351', // Palo Alto Firewall
      '207f5534-533b-42f3-b034-816cb7875406', // Salesforce
      '2bc24f5a-daae-4aa9-aeac-dd59d194be87', // Security Review Summary:
      'bed19955-c2c0-4f08-87ff-c7d87b3621df', // SentinelOne
      '91138efc-d3ce-43f9-bf39-df4a0fc02e2d', // SonicWall
      '97423dd3-0d01-45ae-88ef-84cc0213ea3a', // Sophos
      '71e9d37a-8cee-4ce3-a479-6763c4cf5daf', // Watchguard
      '83bf67bf-97b5-464e-984b-bdeea8a1645c', // Windows
      '12c0ef1b-e02d-4117-b198-93d6e1d76c9d', // Zscalar Firewall
      // 'f2fbd8f7-220b-4cd6-9c76-32f8b5f96531', // ciso dashboard
    ];

    return dashboardIds.some((id) => dashboardId === id);
  };

  const getFilteredDashboards = (args: {
    client?: IClient;
    dashboards: Dashboard[];
    dashboardsAlreadyLoaded?: Dashboard[];
    isNewSearch?: boolean;
  }): Dashboard[] => {
    const { client, dashboards = [], dashboardsAlreadyLoaded = [], isNewSearch } = args;
    const noSecurityReview = shouldNotHaveSecurityReview({ client });
    const filteredDashboards = noSecurityReview
      ? dashboards.filter((d) => !isSecurityReviewDashboard({ dashboardId: d.id })) // filter out security review dashboards
      : dashboards; // whatever came back over the wire

    if (isNewSearch) {
      return filteredDashboards;
    }

    // new array or continue loading plus those already loaded
    return [...dashboardsAlreadyLoaded, ...filteredDashboards];
  };

  /**
   * Infinite Scrolling
   */
  const fetchData = async (args?: {
    newSearch?: boolean; // search variables have changed. data source should be new.
  }) => {
    const isNewSearch = args?.newSearch;

    setLoading(true);

    const variables = {
      clientId,
      queryString,
      // filters,
      shared: sharedFilter === SharedFilter.shared,
      sharedWithMyClient: sharedFilter === SharedFilter.sharedWithMe,
      next: !isNewSearch ? next : undefined,
    };

    const { data } = await graphClient.query({
      query: DASHBOARD_SEARCH,
      variables,
      fetchPolicy: 'network-only',
    });

    const items = data?.dashboardSearch?.items;
    const nextIdx = data?.dashboardSearch?.next;
    const total = data?.dashboardSearch?.total;
    if (items) {
      const dashboards = getFilteredDashboards({
        client,
        dashboards: items,
        dashboardsAlreadyLoaded: dataSource,
        isNewSearch,
      });

      setDataSource(dashboards);

      if (nextIdx && total > dashboards.length) {
        setNext(nextIdx);
      } else {
        setNext(undefined);
      }
    }

    setLoading(false);
  };

  /**
   * Start fresh when vars change.
   */
  useEffect(() => {
    fetchData({ newSearch: true });
  }, [clientId, queryString, sharedFilter]);

  // load more on scroll
  const handleScroll = async () => {
    if (next) {
      // load more
      await fetchData();
    }
  };

  const api: DashboardModalApi = {
    queryString,
    onQueryString: handleQueryString,

    sharedFilter,
    setSharedFilter,

    loading,
    items: dataSource,
  };

  return (
    <div data-intercom-target="dashboard-modal">
      <DashboardModalControls api={api} />
      <InfiniteTable
        loading={loading}
        dataSource={dataSource}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => {
              return (
                <Link
                  to={dashboardDetailPath({ clientId: clientId ?? '', id: record.id })}
                  mode="plain"
                  onClick={() => onClose?.()}
                >
                  {name}
                </Link>
              );
            },
          },
          {
            dataIndex: 'client',
            key: 'client',
            render: (owningClient) => owningClient?.name ?? '',
          },
        ]}
        lastId={dataSource[dataSource.length - 1]?.id}
        onFetch={handleScroll}
        scroll={{
          x: true as true,
          y: 500,
        }}
        rowKey="id"
        rowSelection={rowSelection}
      />
    </div>
  );
}

interface DashboardsModalProps {
  visible: boolean;
  onClose: () => void;
}

export function DashboardsModal(props: DashboardsModalProps) {
  const { visible, onClose } = props;

  return (
    <Modal title="Dashboards" onCancel={onClose} footer={false} open={visible} width={800}>
      {visible && <DashboardsList onClose={onClose} />}
    </Modal>
  );
}

/**
 * Button to open modal
 */
export function DashboardsModalButton() {
  // behind feature flag for now.
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  return (
    <>
      <NuButton
        data-intercom-target="dashboards-modal-btn"
        size="large"
        onClick={handleOpen}
        icon={<NuIcon.DashboardIcon />}
        type="text"
      >
        Dashboards
      </NuButton>
      <DashboardsModal onClose={handleClose} visible={isModalOpen} />
    </>
  );
}
