import { Layout, Alert } from 'antd';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useClientContext } from 'components/client-context-provider';
import { Link } from 'components/nuspire';
import { ImpersonationBanner } from './impersonation-banner';

export const ContentRoot = styled(Layout.Content)``;

const ContentPadding = styled.div`
  padding: 24px 60px;
`;

export function Content(props: {
  children: any;
  styles?: {
    root?: React.CSSProperties;
    contentPadding?: React.CSSProperties;
  };
}) {
  const { children, styles = {} } = props;
  const { clientId } = useClientContext();
  const [isZeroLogsWarningShowing, setIsZeroLogsWarningShowing] = useState(false);
  const [isZeroRisksWarningShowing, setIsZeroRisksWarningShowing] = useState(false);

  useEffect(() => {
    const toggleLogsWarning = () => {
      if (!isZeroLogsWarningShowing) setIsZeroLogsWarningShowing(true);
    };
    const toggleRisksWarning = () => {
      if (!isZeroRisksWarningShowing) setIsZeroRisksWarningShowing(true);
    };
    document.addEventListener('zeroLogs:showWarning', toggleLogsWarning);
    document.addEventListener('zeroRisks:showWarning', toggleRisksWarning);

    return () => {
      document.removeEventListener('zeroLogs:showWarning', toggleLogsWarning);
      document.removeEventListener('zeroRisks:showWarning', toggleRisksWarning);
    };
  }, []);

  return (
    <ContentRoot style={styles.root}>
      <ImpersonationBanner />

      {isZeroLogsWarningShowing && (
        <Alert
          type="warning"
          message={
            <>
              <p style={{ marginBottom: 0 }}>
                There is a configuration error receiving logs from your security device. Please contact support to open
                a case.
              </p>
              <Link to={`/${clientId}/case-management/cases`}>Open a Case</Link>
            </>
          }
        />
      )}
      {isZeroRisksWarningShowing && (
        <Alert
          type="warning"
          message={
            <>
              <p style={{ marginBottom: 0 }}>
                There is potentially a configuration error as no risks have been detected. Please contact support to open a case.
              </p>
              <Link to={`/${clientId}/case-management/cases`}>Open a Case</Link>
            </>
          }
        />
      )}
      <ContentPadding style={styles.contentPadding}>{children}</ContentPadding>
    </ContentRoot>
  );
}
