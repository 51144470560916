import { Typography } from 'antd';
import { type HTMLAttributeAnchorTarget, type MouseEventHandler, forwardRef, type PropsWithChildren } from 'react';
import { useHref, useLinkClickHandler } from 'react-router-dom';

type TypographyLinkProps = PropsWithChildren<{
  onClick?: MouseEventHandler;
  replace?: boolean;
  state?: any;
  target?: HTMLAttributeAnchorTarget;
  to: string;
}>;

export const TypographyLink = forwardRef<unknown, TypographyLinkProps>(
  ({ onClick, replace = false, state, target, to, ...rest }, ref) => {
    let href = useHref(to);
    let handleClick = useLinkClickHandler(to, { replace, state, target });

    return (
      <Typography.Link
        {...rest}
        href={href}
        onClick={(event) => {
          onClick?.(event);
          if (!event.defaultPrevented) {
            handleClick(event as any);
          }
        }}
        ref={ref as any}
        target={target}
      />
    );
  },
);
