import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Table, Row, Col } from 'antd';
import { WidgetDefinition, WidgetRendererProps } from 'components/dashboard/widget-definitions/types';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { ALERTS_BY_SEVERITY_QUERY } from './alerts-by-severity';
import styled from 'styled-components';
import WidgetFilter, { timeRange } from '../widget-filter';
import { EmptyState, NuCardContent } from 'components/nuspire';
import { WidgetComponentProps } from '../../../../widgets/widget';

const TableContainer = styled.div`
  max-height: 330px;
  overflow-y: scroll;
  margin-top: 14px;
`;

const Count = styled.p`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
`;

const CountLabel = styled.span`
  font-size: 14px;
  font-weight: normal;
`;

const isIncident = (policy): boolean => {
  const type = policy?.policyType;
  if (type === 'network' || type === 'audit_event' || type === 'anomaly') {
    return true;
  }
  return false;
};

const isRisk = (policy): boolean => {
  const type = policy?.policyType;
  if (type === 'config' || type === 'data' || type === 'iam') {
    return true;
  }
  return false;
};

const tableColumns = [
  {
    title: 'Policy Name',
    dataIndex: 'policy',
    key: 'policy',
    render: (policy: any) => {
      return policy.name;
    },
  },
  {
    title: 'Alerts',
    dataIndex: 'alertCount',
    key: 'alertCount',
    align: 'center' as 'center',
    render: (alertCount: number) => {
      return alertCount;
    },
  },
];

function TopIncidentsAndRisksWidgetComponent(props: WidgetRendererProps) {
  const [timeFilter, setTimeFilter] = useState(2);
  const [statusFilter, setStatusFilter] = useState('open');
  const connectionId = props?.configuration?.connectionId ?? null;
  const { data, loading } = useQuery(ALERTS_BY_SEVERITY_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      connectionId,
      input: {
        filters: [
          {
            name: 'alert.status',
            operator: '=',
            value: statusFilter,
          },
        ],
        timeRange: {
          type: 'relative',
          value: {
            amount: timeRange[timeFilter].value,
            unit: timeRange[timeFilter].unit,
          },
        },
      },
    },
  });
  const alerts = data?.clientConnection?.alertCountsByPolicy;
  const incidentTableData = alerts
    ?.slice()
    .filter((i) => isIncident(i?.policy))
    .sort((a, b) => a?.alertCount < b?.alertCount);

  const riskTableData = alerts
    ?.slice()
    .filter((r) => isRisk(r?.policy))
    .sort((a, b) => a?.alertCount < b?.alertCount);

  if (!alerts && !loading) {
    return (
      <NuCardContent>
        <EmptyState>Unable to fetch Incidents and Risks.</EmptyState>
      </NuCardContent>
    );
  }

  return (
    <NuCardContent>
      <WidgetFilter
        timeFilter={timeFilter}
        setTimeFilter={setTimeFilter}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
      />
      {loading ? (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      ) : (
        <Row gutter={12} style={{ marginTop: '14px' }}>
          <Col span={24} lg={12}>
            <Count>
              {incidentTableData?.reduce((acc, cur) => acc + cur?.alertCount, 0)} <CountLabel>Incidents</CountLabel>
            </Count>
            <TableContainer>
              <Table
                rowKey="id"
                columns={tableColumns}
                dataSource={incidentTableData.slice(0, 10)}
                pagination={false}
              />
            </TableContainer>
          </Col>
          <Col span={24} lg={12}>
            <Count>
              {riskTableData?.reduce((acc, cur) => acc + cur?.alertCount, 0)} <CountLabel>Risks</CountLabel>
            </Count>
            <TableContainer>
              <Table rowKey="id" columns={tableColumns} dataSource={riskTableData.slice(0, 10)} pagination={false} />
            </TableContainer>
          </Col>
        </Row>
      )}
    </NuCardContent>
  );
}

export function TopIncidentsAndRisksWidget(props: WidgetComponentProps<any, any>) {
  return (
    <TopIncidentsAndRisksWidgetComponent
      configuration={{
        connectionId: props.settings.connection.id,
      }}
    />
  );
}

const frontendWidgetDefinition: WidgetDefinition = {
  name: 'Prisma Cloud Top Incidents & Risks',
  slug: 'prisma-cloud-top-incidents-and-risks',
  connectorSlug: 'prisma-cloud',
  component: TopIncidentsAndRisksWidgetComponent,
  description: 'See your top incidents and risks',
};

export default frontendWidgetDefinition;
