import useSearchParams, { SearchParamsInterface } from 'utils/react-hooks/useSearchParams';
import { jsonSafeParse } from '../../utils/json-safe-parse';
import { DataTypeFiltersAndColumnsQuery, FilterInputOperator } from 'types/graph-codegen/graph-types';
import { Unpacked } from 'types';

/**
 * parseSearchParam
 */
export function parseSearchParamOld(searchParam: string, stringifyValues?: boolean) {
  let value: any = searchParam; // string, string[], null, undefined - i've seen it all come through here, cheating with any

  console.log({ searchParam });

  const [_match, operatorMatch, valueMatch] =
    value?.match(/(IS|IS_NOT|CONTAIN|NOT_CONTAIN|IS_SET|IS_NOT_SET):(.*)/) ?? [];

  /**
   * 'IS_NOT:Win-Security-4720'
   */
  if (['IS_SET', 'IS_NOT_SET'].includes(operatorMatch)) {
    value = null;
  }

  // unclear what this does, or why?
  if (valueMatch) {
    value = valueMatch;
  }

  console.log({ value, stringifyValues });
  // first json parse
  if (value && stringifyValues !== true) {
    /**
     * parse JSON string => string | array | object | number
     */
    value = jsonSafeParse(value, false) ?? null;
  }

  // then handle legacy filter values as strings, or arrays as comma separated strings
  if (typeof value === 'string' && value.includes(',')) {
    value = value.split(',');

    // if we find ourselves with a json blurb just clean each element up and rejoin it
    if (Array.isArray(value)) {
      // remove []"
      const stripChars = (str: string): string => str.replace(/[\[\]"]/g, '');
      value = value.map((v) => stripChars(v));
    }
  }

  console.log({ value, operatorMatch });
  return [value, operatorMatch];
}

/**
 * parseSearchParam
 */
export function parseSearchParam(searchParam: string, stringifyValues?: boolean) {
  let value: string | null = searchParam;

  const [_match, operatorMatch, valueMatch] =
    value?.match(/(IS|IS_NOT|CONTAIN|NOT_CONTAIN|IS_SET|IS_NOT_SET):(.*)/) ?? [];

  if (['IS_SET', 'IS_NOT_SET'].includes(operatorMatch)) {
    value = null;
  }

  if (valueMatch) {
    value = valueMatch;
  }

  if (value && stringifyValues !== true) {
    try {
      value = JSON.parse(value);
    } catch (err: any) {
      console.warn(`Failed to parse non valid json value: ${value}`, err);
    }
  }

  return [value, operatorMatch];
}

export type Filter = {
  key: string;
  value: string | null;
  operator: string | null;
};

// Should return filters.
// these could have better names
export function parseFilterSearchParams(params: { [key: string]: string }, stringifyValues?: boolean): Filter[] {
  const filters = Object.keys(params).map((key) => {
    let paramValue = params[key];

    const [value, operator = 'IS'] = parseSearchParam(paramValue, stringifyValues);

    return {
      key,
      value,
      operator,
    };
  });

  return filters;
}

// hook for dataExplorer to use to parse search params into filters
// and pass down in context.
export function getOperatorLabel(value: string) {
  if (value === 'IS') {
    return 'IS';
  }

  if (value === 'IS_NOT') {
    return 'IS NOT';
  }

  if (value === 'CONTAIN') {
    return 'CONTAINS';
  }

  if (value === 'NOT_CONTAIN') {
    return 'DOES NOT CONTAIN';
  }

  if (value === 'IS_SET') {
    return 'IS SET';
  }

  if (value === 'IS_NOT_SET') {
    return 'IS NOT SET';
  }

  return 'IS';
}
