import { NuCard } from 'components/nuspire';
import styled from 'styled-components';
import { IPermissions } from 'types';
import { RolePermissionsTable } from './role-permissions-table';

export interface PermissionsCardProps {
  permissions: IPermissions;
  loading?: boolean;
}

/**
 * antd doesn't support fitting table to parent height so we do it manually via this pile of hacks...
 */
const TableWrapper = styled.div<{
  colWidth: number;
}>`
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 400px;

  // force the entire antd table tree to fit to parent
  .ant-table-wrapper,
  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table,
  .ant-table-container,
  .ant-table-content,
  table {
    height: 100%;
  }

  .ant-table-small {
    --header-height: 39px;
  }

  thead {
    height: var(--header-height);
  }

  tbody {
    position: absolute;
    display: block;
    height: calc(100% - var(--header-height));
    width: 100%;
    bottom: 0;

    & > tr {
      display: flex;

      & > td {
        width: ${(p) => p.colWidth}px;

        &:first-child {
          width: auto;
          flex: 1;
        }
      }
    }
  }
`;

export function PermissionsCard({ permissions, loading }: PermissionsCardProps) {
  const colWidth = 100;
  return (
    <NuCard title="Permissions" fullHeight>
      <TableWrapper colWidth={colWidth}>
        <RolePermissionsTable permissions={permissions} colWidth={colWidth} size="small" loading={loading} />
      </TableWrapper>
    </NuCard>
  );
}
