import useSearchParams from 'utils/react-hooks/useSearchParams';
import { DataType, IInput, SEARCH } from './data-explorer';
import styled from 'styled-components';
import { Tag } from 'antd';
import { getOperatorLabel } from 'components/data-explorer/search-params';
import { jsonSafeParse } from '../../utils/json-safe-parse';
import { DataExplorerFilters } from './data-explorer-filters';

export function FilterTag(props: {
  label: string | JSX.Element;
  onRemove: () => void;
  color?:
    | 'default'
    | 'pink'
    | 'red'
    | 'yellow'
    | 'orange'
    | 'cyan'
    | 'green'
    | 'blue'
    | 'purple'
    | 'geekblue'
    | 'magenta'
    | 'volcano'
    | 'gold'
    | 'lime'
    | 'success'
    | 'processing'
    | 'error'
    | 'warning';
}) {
  const { label, onRemove, color = 'blue' } = props;

  return (
    <Tag
      style={{
        lineHeight: '24px',
        whiteSpace: 'normal',
      }}
      closable
      onClose={onRemove}
      color={color}
    >
      {label}
    </Tag>
  );
}

export function getFilterTagValue(args: {
  filterDefinition: IInput;
  searchParams: {
    [key: string]: any;
  };
}): [string, string?] | null {
  const {
    searchParams,
    filterDefinition: { key, parameters },
  } = args;
  let value = searchParams[key];
  if (!value?.length) {
    return null;
  }

  const [_match, operatorMatch, valueMatch] =
    value.match(/(IS|IS_NOT|CONTAIN|NOT_CONTAIN|IS_SET|IS_NOT_SET):(.*)/) ?? [];
  if (valueMatch) {
    value = valueMatch;
  }

  if (operatorMatch && ['IS_SET', 'IS_NOT_SET'].includes(operatorMatch)) {
    value = '';
  }

  // const jsonValue = Array.isArray(value) ? jsonSafeParse(value as any) : value;
  let jsonValue = jsonSafeParse(value, true) ?? value;

  // once we have tried to json parse the value, next we need to handle legacy string arrays that were comma separating values (lots of widgets still use this format)
  if (typeof jsonValue === 'string' && jsonValue.includes(',')) {
    jsonValue = jsonValue.split(',');
  }

  // console.log('Search params:', { key, value, options: parameters?.options, jsonValue });
  // {
  //   "key": "s1-endpoint",
  //   "value": "[\"desktop\"]",
  //   "options": [
  //     {
  //       "key": "desktop",
  //       "value": "desktop",
  //       "label": "Desktop"
  //     },
  //     {
  //       "key": "laptop",
  //       "value": "laptop",
  //       "label": "Laptop"
  //     },
  //     {
  //       "key": "server",
  //       "value": "server",
  //       "label": "Server"
  //     },
  //     {
  //       "key": "other",
  //       "value": "other",
  //       "label": "other"
  //     }
  //   ],
  //   "jsonValue": [
  //     "desktop"
  //   ]
  // }

  // i think the idea here is that we're trying to turn values into labels (the display text)
  // we don't know which one we have in value, or if it's an array of stuff
  // pre-json formatting of everything this kinda made sense, but now that individual values don't exactly parse as json when they're split that way
  // we just need to produce the display labels for whatever they picked and return that
  // this is display only, and not what is sent to the server (i think... need to do lots of testing)

  // the values to describe
  const values = Array.isArray(jsonValue) ? jsonValue : [jsonValue];

  // the labels
  const labels: string[] = values.map((v) => {
    // get the option label, or fallback on the raw value, for each selected input value
    const label = Array.isArray(parameters?.options)
      ? parameters?.options.find((opt) => opt.value === v || opt.label === v)?.label
      : undefined;
    return label ?? v;
  });

  return [labels.join(' or '), operatorMatch ?? 'IS']; // do not understand this syntax, or why, so i'm just rolling with it `[string, string] | null` --mark
}

export function FilterDefTag(props: {
  filterDefinition: IInput;
  searchParams: {
    [key: string]: any;
  };
  handleRemove: () => void;
}) {
  const {
    filterDefinition: { key, label: filterDefinitionLabel },
    handleRemove,
  } = props;

  const parsed = getFilterTagValue(props);

  if (!parsed) {
    return null;
  }

  const [value, operator] = parsed;

  const label = `${filterDefinitionLabel} ${operator ?? 'IS'} ${value}`;

  return <FilterTag label={label} key={key} onRemove={handleRemove} color="blue" />;
}

export const FilterTagsRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export function FilterTags(props: { filterInputs: IInput[] }) {
  const { parsed: params, setParameter } = useSearchParams();
  const { filterInputs } = props;

  const handleRemoveFilter = (key: string) => {
    setParameter(key, null);
  };

  const searchValue = params[SEARCH];

  return (
    <FilterTagsRoot>
      {filterInputs.map((def) => {
        return (
          <FilterDefTag
            key={def.key}
            filterDefinition={def}
            searchParams={params}
            handleRemove={() => handleRemoveFilter(def.key)}
          />
        );
      })}

      {Boolean(searchValue !== null && searchValue !== undefined) && (
        <FilterTag label={`Search IS ${searchValue}`} onRemove={() => handleRemoveFilter(SEARCH)} color="purple" />
      )}
    </FilterTagsRoot>
  );
}

export function DataExplorerFilterTags(props: { dataExplorerFilters: DataExplorerFilters }) {
  const { dataExplorerFilters } = props;
  const filterInputs = dataExplorerFilters.filterInputs;

  if (!filterInputs) {
    return null;
  }

  const handleRemoveFilter = (key: string) => dataExplorerFilters.removeFilter(key);
  const { searchQuery: searchValue, filters } = dataExplorerFilters;

  return (
    <FilterTagsRoot>
      {filters.map((filter) => {
        // this should exclude time + search as they are special filters with their own ui patterns.
        // they are not passed as filterInputs through the DataType definition.
        if (!filter.filterInput) {
          console.log('!filter.filterInput');
          return null;
        }

        return (
          <FilterTag
            key={filter.key}
            label={filter.filterTagLabel}
            onRemove={() => handleRemoveFilter(filter.key)}
            color="blue"
          />
        );
      })}

      {Boolean(searchValue !== null && searchValue !== undefined) && (
        <FilterTag label={`Search IS ${searchValue}`} onRemove={() => handleRemoveFilter(SEARCH)} color="purple" />
      )}
    </FilterTagsRoot>
  );
}
