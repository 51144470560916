import { useContext } from 'react';
import styled from 'styled-components';
import { Row, Col, Typography } from 'antd';
import { NuButton as Button, NuPaper as Paper } from 'components/nuspire';
import baseTheme from 'components/theme';
import { SubscriptionContext } from 'components/subscription/subscription-context';
import Modal from 'antd/es/modal/Modal';
import ContactNuspireLink from 'components/subscription/contact-nuspire-link';

const EN_UPGRADE_MODAL_DESC = `Contact a Nuspire representative for more information about becoming a Nuspire services client. Unlock the full power of myNuspire.`;
const EN_BASIC_PLAN_NAME = `Non-Nuspire Client`;
const EN_ADVANCED_PLAN_NAME = `Nuspire Client`;

/**
 * Styled compoennts
 */
const ModalContentRoot = styled.div`
  .advanced-paper {
    box-shadow: 0px 2px 4px rgba(59, 120, 230, 0.7);
  }

  .nu-paper {
    padding: 16px;
  }
`;
const ModalContentDesc = styled.div`
  margin-bottom: 24px;
  font-size: 18px;
  color: ${p => p.theme.color.gray6};
`;
const FeatureList = styled.ul`
  color: ${p => p.theme.color.gray5};
  margin-bottom: 24px;
`;

interface ModalContentProps {}

export function ModalContent(props: ModalContentProps) {
  return (
    <ModalContentRoot>
      <ModalContentDesc>{EN_UPGRADE_MODAL_DESC}</ModalContentDesc>

      <Row gutter={24}>
        <Col span={24} md={12}>
          <Paper fullHeight level={1}>
            <Typography.Title level={4} style={{ color: baseTheme.color.gray5 }}>
              {`${EN_BASIC_PLAN_NAME}`}
            </Typography.Title>

            <FeatureList>
              <li>Nuspire Security Program</li>
              <li>Nuspire Threat Modeling Tool </li>
              <li>Threat Brief & Curated Industry News </li>
            </FeatureList>
          </Paper>
        </Col>
        <Col span={24} md={12}>
          <Paper fullHeight classes={{ root: 'advanced-paper' }}>
            <Typography.Title style={{ color: baseTheme.color.primaryBlue }} level={4}>
              {EN_ADVANCED_PLAN_NAME}
            </Typography.Title>
            <FeatureList>
              <li>Case & Incident Management</li>
              <li>24x7 Security Operations Center </li>
              <li>400 days of log or alert storage </li>
              <li>Cyber X Platform Integration</li>
              <li>And more!</li>
            </FeatureList>
            <ContactNuspireLink>
              <Button type="primary" size="large" block>
                Contact Nuspire Today
              </Button>
            </ContactNuspireLink>
          </Paper>
        </Col>
      </Row>
    </ModalContentRoot>
  );
}

export interface BehindUpgradeModalProps {}

function BehindUpgradeModal(props: BehindUpgradeModalProps) {
  const subscriptionApi = useContext(SubscriptionContext);
  const { isUpgradeModalOpen, setIsUpgradeModalOpen } = subscriptionApi;

  return (
    <Modal
      open={isUpgradeModalOpen}
      title="Nuspire Services"
      onCancel={() => setIsUpgradeModalOpen(false)}
      width={800}
      footer={false}
    >
      <ModalContent />
    </Modal>
  );
}

export default BehindUpgradeModal;
