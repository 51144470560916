import { Doughnut } from 'components/nuspire/charts/doughnut-chart';

export interface ISentinelOneItemValue {
  count: number;
  title: string;
  value: string;
}

export interface ISentinelOneItemCount {
  key: string;
  title: string;
  values: ISentinelOneItemValue[];
}

export interface DoughnutDataset {
  backgroundColor: string[];
  borderColor?: string[];
  borderWidth?: number;
  data: number[];
  label: string;
}

export interface IDoughnutMapping {
  [key: string]: {
    label: string;
    backgroundColor: string;
  };
}

export function transformToDoughnutData(map: IDoughnutMapping, counts: ISentinelOneItemValue[]) {
  const doughnutData = Object.keys(map).reduce(
    (
      acc: {
        dataset: DoughnutDataset;
        labels: string[];
        total: number;
      },
      key,
    ) => {
      const mapping = map[key];
      const itemCount = counts.find((c) => c.value === key);

      if (itemCount && itemCount.count) {
        acc.labels.push(mapping.label);
        acc.dataset.backgroundColor.push(mapping.backgroundColor);
        acc.dataset.data.push(itemCount.count);
        acc.total += itemCount.count;
      }

      return acc;
    },
    {
      dataset: {
        label: 'Unpatched Devices',
        data: [],
        backgroundColor: [],
        borderWidth: 1,
      },
      labels: [],
      total: 0,
    },
  );

  return doughnutData;
}

export function CountsDoughnut(props: { counts: ISentinelOneItemValue[]; map: IDoughnutMapping }) {
  const { counts, map } = props;
  const doughnutData = transformToDoughnutData(map, counts);

  return (
    <Doughnut
      options={{
        cutout: 80,
      }}
      data={{
        labels: doughnutData.labels,
        datasets: [doughnutData.dataset],
      }}
      centerContent={<h1>{`Total: ${doughnutData.total}`}</h1>}
      // style={{ minHeight: '300px' }}
    />
  );
}
