import { gql, useMutation, useQuery } from '@apollo/client';
import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';
import { GetFavoriteDashboardsQuery, GetRecentlyViewedDashboardsQuery, UpdateFavoriteDashboardsMutation } from '../../../../types/graph-codegen/graph-types';
import { SortableListItemProps } from '../../../nuspire/sortable-list/sortable-list';
import { DashboardNavData, LinkedDashboardNavData } from './dashboard-menu';

export const GET_FAVORITE_DASHBOARDS = gql`
  query GetFavoriteDashboards($clientId: String!) {
    getUserDashboardsData(clientId: $clientId) {
      favoriteDashboards {
        id
        name
      }
    }
  }
`;

export const UPDATE_FAVORITE_DASHBOARDS = gql`
  mutation UpdateFavoriteDashboards($clientId: String!, $dashboardIds: [String!]!) {
    updateUserFavoriteDashboardIds(clientId: $clientId, dashboardIds: $dashboardIds) {
      favoriteDashboards {
        id
        name
      }
    }
  }
`;

export const GET_RECENTLY_VIEWED_DASHBOARDS = gql`
  query GetRecentlyViewedDashboards($clientId: String!) {
    getUserDashboardsData(clientId: $clientId) {
      recentDashboards {
        id
        name
      }
    }
  }
`;

export type IDashboardMenuContext = {
  favoriteDashboards: LinkedDashboardNavData[];
  recentDashboards: LinkedDashboardNavData[];

  reorderFavoriteDashboards: (items: SortableListItemProps<LinkedDashboardNavData>[]) => Promise<void>;
  removeFavoriteDashboard: (idToRemove: string) => Promise<void>;

  loadingFavorites?: boolean;
  loadingRecents?: boolean;

  currentDashboardId?: string;
  setCurrentDashboardId: Dispatch<SetStateAction<string | undefined>>;
};

export const DashboardMenuContext = createContext<IDashboardMenuContext>({
  favoriteDashboards: [],
  recentDashboards: [],

  reorderFavoriteDashboards: async () => {},
  removeFavoriteDashboard: async () => {},
  setCurrentDashboardId: () => undefined,
});

export function useDashboardMenuContext() {
  return useContext(DashboardMenuContext);
}

interface DashboardMenuContextProviderProps {
  clientId: string;
  children: ReactNode;
}

export function DashboardMenuContextProvider({ clientId, children }: DashboardMenuContextProviderProps) {
  const toDashboardRoute = (dashboard: DashboardNavData): LinkedDashboardNavData => {
    return {
      ...dashboard,
      to: `/${clientId}/dashboard/${dashboard.id}`,
    };
  };

  const [currentDashboardId, setCurrentDashboardId] = useState<string | undefined>();

  const {
    data: recentData,
    loading: loadingRecents,
  } = useQuery<GetRecentlyViewedDashboardsQuery>(GET_RECENTLY_VIEWED_DASHBOARDS, {
    variables: {
      clientId,
    },
  });

  const recentDashboards = recentData?.getUserDashboardsData?.recentDashboards?.map(toDashboardRoute) ?? [];

  const [favoriteDashboards, setFavoriteDashboards] = useState<LinkedDashboardNavData[]>([]);
  const { loading: loadingFavorites } = useQuery<GetFavoriteDashboardsQuery>(GET_FAVORITE_DASHBOARDS, {
    variables: {
      clientId,
    },
    onCompleted: (data) => {
      const dashboardsData = data.getUserDashboardsData?.favoriteDashboards;
      setFavoriteDashboards(dashboardsData?.map(toDashboardRoute) ?? []);
    },
  });

  const [updateFavorites] = useMutation<UpdateFavoriteDashboardsMutation>(UPDATE_FAVORITE_DASHBOARDS, {
    refetchQueries: [{ query: GET_FAVORITE_DASHBOARDS, variables: { clientId } }],
  });

  type ReorderCallback = IDashboardMenuContext['reorderFavoriteDashboards'];
  const reorderFavoriteDashboards = useCallback<ReorderCallback>(async (items) => {
    setFavoriteDashboards(items.map((i) => i.data!));

    await updateFavorites({
      variables: {
        clientId,
        dashboardIds: items.map((item) => item.id),
      },
    });
  }, []);

  type RemoveCallback = IDashboardMenuContext['removeFavoriteDashboard'];
  const removeFavoriteDashboard = useCallback<RemoveCallback>(async (idToRemove) => {
    const items = favoriteDashboards.filter(({ id }) => id !== idToRemove);

    await updateFavorites({
      variables: {
        clientId,
        dashboardIds: items.map((item) => item.id),
      },
    });
  }, [favoriteDashboards]);

  const context: IDashboardMenuContext = useMemo(() => ({
    favoriteDashboards,
    recentDashboards,
    reorderFavoriteDashboards,
    removeFavoriteDashboard,
    currentDashboardId,
    setCurrentDashboardId,
    loadingFavorites,
    loadingRecents,
  }), [favoriteDashboards, recentDashboards, currentDashboardId, loadingFavorites, loadingRecents]);

  return <DashboardMenuContext.Provider value={context}>{children}</DashboardMenuContext.Provider>;
}

