import { Select } from 'antd';
import { ActionInputProps } from '../../action-form-field';
import { gql, useQuery } from '@apollo/client';
import {
  SlackConversationsQuery,
  SlackConversationsQueryVariables,
} from '../../../../../../../types/graph-codegen/graph-types';

const SLACK_CONVERSATIONS = gql`
  query SlackConversations($connectionId: String!) {
    clientConnection(id: $connectionId) {
      id
      slackConversations {
        channels {
          id
          name
        }
      }
    }
  }
`;

function SlackChannelSelect(props: ActionInputProps) {
  // can only select if connection has been selected.
  const { value, onChange, formik } = props;
  const values = formik?.values;
  const connectionId = values?.connection?.id ?? null;

  const { data } = useQuery<SlackConversationsQuery, SlackConversationsQueryVariables>(SLACK_CONVERSATIONS, {
    variables: { connectionId },
    skip: !connectionId,
  });

  const conversations = data?.clientConnection?.slackConversations?.channels;

  const options = conversations ?? (value ? [value] : []);

  return (
    <Select
      value={value?.id}
      onChange={(selectedId) => {
        let selectedOption;

        if ('slackConversations' in options) {
          selectedOption = conversations?.find((o: any) => o.id === selectedId);
        } else {
          selectedOption = (options as any).find((o: any) => o.id === selectedId);
        }

        onChange(selectedOption);
      }}
      disabled={!connectionId}
      placeholder="Select Slack Channel"
    >
      {options.map((c: any) => (
        <Select.Option key={c.id} value={c.id}>
          {c.name}
        </Select.Option>
      ))}
    </Select>
  );
}

export default SlackChannelSelect;
