import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Button, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Maybe } from 'types';
import Paper, { OptionalPaperProps } from 'components/nuspire/nu-paper/nu-paper';
import classNames from 'classnames';
import CardTitle from './nu-card-title';
import CardContent from './nu-card-content';

/**
 * NuCard
 */
export interface NuCardProps {
  /**
   * Title describing contents of the card
   */
  title?: string | JSX.Element;
  /**
   * Optional tooltip to help user understand contents of card
   */
  tooltip?: Maybe<string | JSX.Element>;
  /**
   * Contents of card
   */
  children: React.ReactNode;
  /**
   * fullHeight
   */
  fullHeight?: boolean;
  /**
   * Use the NuCardFooter :)
   */
  footer?: ReactNode;
  /**
   * Custom Styles
   */
  styles?: {
    root?: React.CSSProperties;
  };
  dataIntercomTarget?: string;

  hoverable?: boolean;

  paperProps?: OptionalPaperProps;
}
const Root = styled.div`
  position: relative;

  &.full-height {
    height: 100%;
  }

  .nu-paper-content {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
`;

export const CARD_TEST_ID = 'nu-card';
export const TOOLTIP_BUTTON_TEST_ID = `${CARD_TEST_ID}-tooltip-button`;

/**
 * Nuspire Styled antd Card
 */
function NuCard(props: NuCardProps) {
  const {
    tooltip,
    title,
    children,
    styles = {},
    fullHeight = false,
    footer,
    dataIntercomTarget,
    hoverable,
    paperProps = {},
  } = props;

  const rootClasses = classNames('nu-card', {
    'full-height': fullHeight,
  });

  return (
    <Root
      data-testid={CARD_TEST_ID}
      className={rootClasses}
      style={styles.root}
      data-intercom-target={`${dataIntercomTarget}-card`}
    >
      <Paper
        fullHeight={fullHeight}
        classes={{ root: 'card-paper-root' }}
        level={2}
        hoverable={hoverable}
        {...paperProps}
      >
        {title && (
          <CardTitle
            title={title}
            dataIntercomTarget={dataIntercomTarget}
            actions={
              tooltip && (
                <Tooltip className="pull-right" placement="left" title={tooltip}>
                  <Button
                    data-testid={TOOLTIP_BUTTON_TEST_ID}
                    size="small"
                    type="text"
                    shape="circle"
                    icon={<QuestionCircleOutlined />}
                  />
                </Tooltip>
              )
            }
          />
        )}

        {title ? <CardContent>{children}</CardContent> : children}

        {footer}
      </Paper>
    </Root>
  );
}

export default NuCard;
