import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { message } from 'antd';
import { useState } from 'react';
import { GetReportDownloadUrlQuery } from 'types/graph-codegen/graph-types';
import { downloadUrlAsFile } from 'utils/download-url-as-file';

const GET_DOWNLOAD_URL = gql`
  query GetReportDownloadUrl($clientId: String!, $id: String!) {
    reportDownloadUrl(clientId: $clientId, id: $id)
  }
`;

export function useReportDownload() {
  const [getDownloadUrl] = useLazyQuery<GetReportDownloadUrlQuery>(GET_DOWNLOAD_URL);
  const [downloadsLoading, setDownloadsLoading] = useState<string[]>([]);

  type HandleDownloadArgs = {
    clientId: string;
    id: string;
    completedAt?: string;
  };
  const handleDownload = async (args: HandleDownloadArgs) => {
    console.log({ args });
    const { clientId, id, completedAt } = args;
    setDownloadsLoading((prevLoading) => [id, ...prevLoading]);

    try {
      const { data: downloadData } = await getDownloadUrl({
        variables: {
          clientId,
          id,
        },
      });

      if (!downloadData) {
        throw new Error('Missing download data');
      }

      const url = downloadData.reportDownloadUrl;
      const suffix = completedAt ? `-${completedAt}` : '';
      downloadUrlAsFile({
        url,
        fileName: `mynuspire-dashboard-report${suffix}.pdf`,
        newTab: true,
      });
    } catch (err) {
      message.error('Error downloading pdf');
    } finally {
      setDownloadsLoading((prevLoading) => prevLoading.filter((currId) => currId !== id));
    }
  };

  return {
    downloadsLoading,
    handleDownload,
  };
}
