import { DeleteOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { message } from 'antd';
import { useAuthContext } from 'components/auth-context';
import MutationButton from 'components/mutation-button';
import { UserActionButtonProps } from 'types';
import { formatUserName } from 'utils/users';

const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($userId: String!) {
    deleteUser(userId: $userId)
  }
`;

type DeleteUserData = {
  deleteUser?: boolean;
};

export default function DeleteUserButton(props: UserActionButtonProps) {
  const { onSuccess, user, ...buttonProps } = props;
  const { user: authUser } = useAuthContext();

  const userName = formatUserName(user);
  const { id: userId } = user;
  const variables = {
    userId,
  };
  const modalTitle = `Are you sure you want to delete ${userName}?`;

  const onCompleted = (data: DeleteUserData) => {
    if (data.deleteUser !== undefined) {
      const { deleteUser: result } = data;

      // Delete was unsuccessful.
      if (!result) {
        onError();
        return;
      }

      // Delete was successful.
      message.success(`${userName} has been deleted!`);
      if (onSuccess) onSuccess();
    }
  };

  const onError = (error?: any) => {
    let errorMessage = `There was an error deleting ${userName}.`;
    if (authUser?.isSuperUser) {
      errorMessage = `${errorMessage}${error ? ` Error: ${error.message}` : ``}`;
    }

    message.error(errorMessage);
  };

  return (
    <MutationButton
      buttonIcon={<DeleteOutlined />}
      danger
      modalOkType="danger"
      modalTitle={modalTitle}
      mutation={DELETE_USER_MUTATION}
      mutationVariables={variables}
      onCompleted={onCompleted}
      onError={onError}
      tooltipOverlay="Delete User"
      {...buttonProps}
    />
  );
}
