import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { ActionInputProps } from '../action-form-field';

const MultiInputText = (props: ActionInputProps) => {
  const { value, onChange } = props;
  const [values, setValues] = useState<string[]>(typeof value === `string` ? value?.split(',') : value ? value : ['']);

  useEffect(() => {
    setValues(typeof value === `string` ? value?.split(',') : value ? value : ['']);
  }, [value]);

  useEffect(() => {
    onChange(values);
  }, [values]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {values?.map((v, index) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
          <div style={{ width: 'calc(100% - 22px)' }}>
            <Input
              value={v}
              onChange={(e) => {
                setValues(
                  values?.map((oldValue, i) => {
                    if (i === index) {
                      return e.target.value;
                    }
                    return oldValue;
                  }),
                );
              }}
            />
          </div>
          <div style={{ margin: 'auto 0 auto .5rem' }}>
            <PlusCircleOutlined style={{ fontSize: '22px' }} onClick={() => setValues([...values, ''])} />
          </div>
          {values?.length > 1 && (
            <div style={{ margin: 'auto 0 auto .5rem' }}>
              <MinusCircleOutlined
                style={{ fontSize: '22px' }}
                onClick={() => setValues(values?.filter((_v, i) => i !== index))}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MultiInputText;
