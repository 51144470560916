import { ApartmentOutlined, ExclamationCircleOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { App, message, Modal, Tooltip } from 'antd';
import { NuButton, NuButtonProps } from 'components/nuspire';
import { UPDATE_CLIENT_PARENT_ID } from 'components/organization/client-settings';
import { useEffect, useState } from 'react';
import type { IClient } from 'types';
import { UpdateClientParentIdMutation, UpdateClientParentIdMutationVariables } from 'types/graph-codegen/graph-types';
import { ClientsTableWithSearch } from './clients';

export type ChangeParentClientButtonAndModalProps = NuButtonProps & {
  client: IClient;
  onSuccess: () => void;
};

function ChangeParentClientButton(
  props: ChangeParentClientButtonAndModalProps & { columnClient: IClient; setIsModalOpen: (isOpen: boolean) => void },
) {
  const { client, columnClient, onSuccess, setIsModalOpen } = props;
  const [updateClientParentId, { error, loading }] = useMutation<
    UpdateClientParentIdMutation,
    UpdateClientParentIdMutationVariables
  >(UPDATE_CLIENT_PARENT_ID);
  const { modal } = App.useApp();

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error(`An error occurred while updating this client's parent.`);
    }
  }, [error]);

  const setClientsParent = (newParentClient: IClient) => {
    modal.confirm({
      title: `Are you sure you want to change ${client.name}'s parent to ${newParentClient.name}?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      async onOk() {
        await updateClientParentId({
          variables: {
            clientId: client.id,
            parentId: newParentClient.id,
          },
        });
        setIsModalOpen(false);
        onSuccess();
      },
      width: 800,
    });
  };

  return (
    <NuButton
      disabled={loading}
      loading={loading}
      icon={<UserSwitchOutlined />}
      onClick={() => setClientsParent(columnClient)}
      type="primary"
    >
      Set as Parent
    </NuButton>
  );
}

export function ChangeParentClientButtonAndModal(props: ChangeParentClientButtonAndModalProps) {
  const { client, onSuccess: _onSuccess, ...buttonProps } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Tooltip overlay="Change Parent Client">
        <NuButton icon={<ApartmentOutlined />} onClick={() => setIsModalOpen(true)} type="default" {...buttonProps} />
      </Tooltip>
      <Modal
        footer={false}
        onCancel={() => setIsModalOpen(false)}
        open={isModalOpen}
        title="Search Clients"
        width={1200}
      >
        <ClientsTableWithSearch
          actionsColumnRender={(columnClient, _onActionSuccess) => {
            if (client.id !== columnClient.id) {
              return (
                <ChangeParentClientButton {...props} columnClient={columnClient} setIsModalOpen={setIsModalOpen} />
              );
            }

            return null;
          }}
          disableHistoryPush
          onActionSuccess={() => {}}
        />
      </Modal>
    </>
  );
}
