import { buildYup } from 'schema-to-yup';
import { ActionFormFieldDef } from '../types';
import { lazy, string, object } from 'yup';

function formFieldsToJsonSchema(formFields: ActionFormFieldDef[]) {
  const schema = formFields.reduce(
    (acc: any, field) => {
      const { key, type, required = false, jsonSchemaString } = field;

      let item: any = {
        type,
        required,

        // testing
        nullable: true,
      };

      if (type === 'object' && jsonSchemaString) {
        const jsonSchema = JSON.parse(jsonSchemaString);

        item = {
          ...item,
          ...jsonSchema,
        };
      }

      if (type === 'array' && jsonSchemaString) {
        const jsonSchema = JSON.parse(jsonSchemaString);

        item = {
          ...item,
          items: jsonSchema,
        };
      }

      acc.properties[key] = item;

      if (required) {
        acc.required.push(key);
      }

      return acc;
    },
    {
      type: 'object',
      properties: {},
      required: [],
    },
  );

  return schema;
}

function formFieldsToConfig(formFields: ActionFormFieldDef[]) {
  const config = formFields.reduce(
    (acc: any, field) => {
      const { key, errorMessages } = field;
      const requiredMessage = errorMessages?.required;
      const formatMessage = errorMessages?.format;

      if (requiredMessage || formatMessage) {
        acc.errorMessages[key] = {
          required: requiredMessage,
          format: formatMessage,
        };
      }

      return acc;
    },
    { errorMessages: {} },
  );

  return config;
}

export function formFieldsToYupSchema(formFields: ActionFormFieldDef[]) {
  const jsonSchema = formFieldsToJsonSchema(formFields);
  const config = formFieldsToConfig(formFields);
  const yupSchema = buildYup(jsonSchema, config);

  // Grab yup schemas from the transformed schemas given from the widget defs
  // Extend them to be able to accept a client identifier object
  const obj: any = {};

  Object.entries(yupSchema.fields).forEach(([key, schema]: any) => {
    const valueSchema = lazy((v) => {
      let type: string = typeof v;
      // check if array because typeof returns 'object'
      if (Array.isArray(v)) {
        type = 'array';
      }

      // v === null;
      if (v === null) {
        return schema.nullable();
      }

      switch (type) {
        case schema?.type:
          return schema;

        default:
          return object({
            [`$client-identifier`]: object({
              inputType: string(),
              label: string(),
              type: string(),
              valueType: string(),
            }).required(),
          });
      }
    });

    obj[key] = valueSchema;
  });

  const extendedClientIdentifierYupSchema = object(obj);

  return extendedClientIdentifierYupSchema;
}

/**
 * Forms that support "Operators" need slightly different schemas than simple key value pair models
 */
export function formFieldsToOperatorYupSchema(formFields: ActionFormFieldDef[]) {
  const jsonSchema = formFieldsToJsonSchema(formFields);
  const config = formFieldsToConfig(formFields);
  const yupSchema = buildYup(jsonSchema, config);

  // Grab yup schemas from the transformed schemas given from the widget defs
  // Extend them to be able to accept a client identifier object
  const obj: any = {};

  Object.entries(yupSchema.fields).forEach(([key, schema]: any) => {
    // obj[key] = lazy((v) => {
    const valueSchema = lazy((v) => {
      let type: string = typeof v;
      // check if array because typeof returns 'object'
      if (Array.isArray(v)) {
        type = 'array';
      }

      // v === null;
      if (v === null) {
        return schema.nullable();
      }

      switch (type) {
        case schema?.type:
          return schema;

        default:
          return object({
            [`$client-identifier`]: object({
              inputType: string(),
              label: string(),
              type: string(),
              valueType: string(),
            }).required(),
          });
      }
    });

    obj[key] = object({
      key: string(),
      operator: string().nullable(),
      value: valueSchema,
    });
  });

  const extendedClientIdentifierYupSchema = object(obj);

  return extendedClientIdentifierYupSchema;
}
