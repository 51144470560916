import { useCallback, useEffect, useState } from 'react';
import { Select } from 'antd';
import { gql, useQuery } from '@apollo/client';
import debounce from 'lodash.debounce';
import { FeatureClientSelectSearchOrgQuery } from 'types/graph-codegen/graph-types';

export type ClientSelectProps = {
  clientId?: string;
  // value
  // on change
  onChange?: (val: undefined | { name: string; id: string }[] | string) => void;
  value?: any;
  optionsFilter?: (option: { label: string; value: string }) => boolean;
  mode?: 'multiple' | 'tags' | undefined;
  placeholder?: string;
  width?: string;

  useNotificationSubscriptions?: true;
};

const FEATURE_SEARCH_CLIENTS = gql`
  query FeatureClientSelectSearchOrg($clientId: String, $queryString: String, $featureSlug: String!) {
    clientSearch(clientId: $clientId, queryString: $queryString) {
      items {
        id
        name

        # Check to see if the client has the feature we are trying to add them to.

        clientFeatureFlag(slug: $featureSlug) {
          id
        }
      }
    }
  }
`;

export function FeatureClientSelect(
  props: ClientSelectProps & {
    featureSlug: string;
  },
) {
  const { clientId, onChange, value, optionsFilter, mode, placeholder, width = '100%', featureSlug } = props;

  // ========= State Management ========
  const [queryString, setQueryString] = useState<string | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string | undefined>(queryString);

  // UI should update instantaneously
  const handleSearchValue = (val?: string) => {
    if (val?.length) {
      setSearchValue(val);
    } else {
      setSearchValue(undefined);
    }
  };

  // query should only execute after user stops typing
  const debouncedSearch = useCallback(
    debounce((newSearch: string | undefined) => {
      setQueryString(newSearch);
    }, 600),
    [],
  );

  useEffect(() => {
    debouncedSearch(searchValue);
  }, [searchValue]);

  // ========= Graphql Request =========
  const { data, loading } = useQuery<FeatureClientSelectSearchOrgQuery>(FEATURE_SEARCH_CLIENTS, {
    variables: { clientId, queryString, featureSlug },
  });

  const items = data?.clientSearch?.items;

  // build select options.
  let options = items?.map((i) => {
    const featureEnabled = i.clientFeatureFlag?.id;

    return {
      label: `${i.name} ${featureEnabled ? '(Feature Enabled)' : `(${i.id})`}`,
      value: i.id,
      disabled: Boolean(featureEnabled),
    };
  });

  if (options && optionsFilter) {
    options = options.filter(optionsFilter);
  }

  // ========= Render =========
  const selectValue =
    mode === 'multiple'
      ? value?.map((i) => ({
          label: i.name,
          value: i.id,
        }))
      : value;

  return (
    <Select
      labelInValue
      value={selectValue}
      options={options}
      placeholder={placeholder}
      showSearch
      onSearch={handleSearchValue}
      searchValue={searchValue}
      loading={loading}
      filterOption={false}
      style={{ width }}
      mode={mode}
      onChange={
        onChange
          ? (value) => {
              if (mode === 'multiple') {
                const transformedValues = value.length
                  ? value.map((option) => ({
                      name: option.label,
                      id: option.value,
                    }))
                  : undefined;
                return onChange(transformedValues);
              }

              // single object when single select mode
              return onChange(value.value);
            }
          : undefined
      }
    />
  );
}
