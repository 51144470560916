import { ReactNode } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

export interface NuEmptyStateProps {
  /**
   * Explanation of Empty State
   */
  children: ReactNode;

  /**
   * One to many Buttons to be acted on.
   */
  actions?: ReactNode;

  messageMaxWidth?: number;

  /**
   * Variants
   * Default: 'gray';
   */
  variant?: 'gray' | 'transparent';

  styles?: {
    root?: any;
    message?: any;
  };

  classNames?: {
    root?: string;
  };

  noBorderRadius?: boolean;

  fullHeight?: boolean;
}

const Root = styled.div<{ noBorderRadius?: boolean }>`
  border-radius: ${(p) => (p.noBorderRadius ? '0' : '8px')};
  padding: 40px 16px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;

  &.full-height {
    height: 100vh;
    justify-content: center;
  }

  &.variant-gray {
    background-color: ${(p) => p.theme.token.colorBgElevated};
  }
`;

const Message = styled.div<{ $maxWidth?: number }>`
  max-width: ${(p) => p.$maxWidth ?? 400}px;
  text-align: center;
  margin-bottom: 24px;
  color: ${(p) => p.theme.token.colorTextSecondary};
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  > * {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

function EmptyState(props: NuEmptyStateProps) {
  const { children, actions, variant = 'gray', classNames = {}, styles, fullHeight = false } = props;

  const rootClasses = classnames('empty-state', `variant-${variant}`, classNames.root, {
    'full-height': fullHeight,
  });

  return (
    <Root className={rootClasses} style={styles?.root} noBorderRadius={props.noBorderRadius}>
      <Message $maxWidth={props.messageMaxWidth} className="empty-state-message" style={styles?.message}>
        {children}
      </Message>

      {actions && <Actions>{actions}</Actions>}
    </Root>
  );
}

export default EmptyState;
