import { ReactNode, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Icon, { CopyOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useField } from 'formik';
import { Checkbox, Form as AntForm, Input as AntInput, Space, Table, Tooltip } from 'antd';

import baseTheme, { headingText2, headingText3 } from './theme';
import nuspireIcon from '../nuspire-svg';

const sharedHeadingStyles = `
    font-family: Helvetica Neue, Verdana, sans-serif;
    margin-bottom: 0.5em;
    font-style: normal;
`;
export const H1 = styled.h1`
  ${sharedHeadingStyles}
  color: ${(p) => p.theme.color.headingText1};
  font-weight: bold;
  font-size: 2.7em;
  line-height: 1.23;
`;

export const H2 = styled.h2`
  ${sharedHeadingStyles}
  color: ${(p) => p.theme.color.headingText1};
  font-weight: bold;
  font-size: 2.1em;
  line-height: 1.35;
`;

export const H3 = styled.h3`
  ${sharedHeadingStyles}
  font-size: 1.43em;
  font-weight: 400;
  color: ${headingText2};
  line-height: 1.2;
`;

export const H5 = styled.h5`
  ${sharedHeadingStyles}
  color: ${headingText3};
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.17;
`;

export const P = styled.p`
  color: ${(p) => p.theme.token.colorText};
  font-size: 1em;
`;

export function ContactBox() {
  const theme = useTheme();

  return (
    <Space align="center" style={{ border: `1px solid ${theme.color.gray4}`, width: '270px', padding: '1.5rem' }}>
      Need help?
      <a
        href="https://www.nuspire.com/support/contact-us"
        target="_blank"
        rel="noopener noreferrer"
        style={{ display: 'flex', marginLeft: '0.25rem', alignItems: 'center' }}
      >
        <span style={{ marginRight: '1rem' }}>Contact Nuspire</span>{' '}
        <Icon style={{ width: '2em' }} component={nuspireIcon} />
      </a>
    </Space>
  );
}

export const TableRow = styled.div`
  width: auto;
  padding-top: 20px;
`;

export const StyledTable = styled(Table)`
  .ant-table-thead tr th {
    font-weight: bolder !important;
  }
`;

export function ColoredCircle({ color = baseTheme.color.gray4 }: { color?: string }) {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4" r="4" fill={color} />
    </svg>
  );
}

export function FormikInput(props: {
  checkbox?: boolean;
  children?: ReactNode;
  disabled?: boolean;
  label?: string;
  name: string;
  onChange?: (newValue: string | number | boolean) => void;
  passwordInput?: boolean;
  placeholder?: string;
  required?: boolean;
  tooltip?: string | JSX.Element;
  type?: any;
}) {
  const {
    checkbox,
    children,
    disabled,
    label,
    name,
    onChange,
    passwordInput,
    placeholder,
    required = false,
    tooltip,
    type,
  } = props;
  const [{ value, onBlur }, { touched, error }, { setValue }] = useField({
    name,
  });

  const commonFormItemProps = {
    hasFeedback: Boolean(touched && error),
    label,
    required,
    status: touched && error ? ('error' as const) : undefined,
    tooltip,
    validateStatus: touched && error ? ('error' as const) : undefined,
  };
  const commonInputProps = {
    disabled,
    name,
    onBlur,
    placeholder,
    value,
  };

  if (checkbox) {
    return (
      <AntForm.Item {...commonFormItemProps} valuePropName="checked">
        <Checkbox
          {...commonInputProps}
          checked={commonInputProps.value}
          onChange={(e) => {
            setValue(e.target.checked);
            onChange?.(e.target.checked);
          }}
        >
          {children}
        </Checkbox>
      </AntForm.Item>
    );
  }

  if (passwordInput) {
    return (
      <AntForm.Item {...commonFormItemProps}>
        <AntInput.Password
          {...commonInputProps}
          onChange={(e) => {
            setValue(e.target.value);
            onChange?.(e.target.value);
          }}
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
      </AntForm.Item>
    );
  }

  return (
    <AntForm.Item {...commonFormItemProps}>
      <AntInput
        {...commonInputProps}
        onChange={(e) => {
          setValue(e.target.value);
          onChange?.(e.target.value);
        }}
        type={type}
      />
    </AntForm.Item>
  );
}

export function FormikTextarea(props: {
  name: string;
  label: string;
  required?: boolean;
  tooltip?: string | JSX.Element;
  placeholder?: string;
  rows?: number;
}) {
  const { name, label, required = false, tooltip, placeholder, rows } = props;
  const [{ value, onBlur }, { touched, error }, { setValue }] = useField({
    name,
  });

  return (
    <AntForm.Item
      label={label}
      hasFeedback={Boolean(touched && error)}
      validateStatus={touched && error ? 'error' : undefined}
      status={touched && error ? 'error' : undefined}
      required={required}
      tooltip={tooltip}
    >
      <AntInput.TextArea
        name={name}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
        placeholder={placeholder}
        rows={rows}
      />
    </AntForm.Item>
  );
}

type CopyToClipboardIconProps = {
  altTooltip?: string;
  copyText: string;
  style?: any;
};

export function CopyToClipboardIcon(props: CopyToClipboardIconProps) {
  const { altTooltip, copyText, style } = props;
  const defaultTooltipText = 'Copy to Clipboard';
  const [tooltipText, setTooltipText] = useState<string>(altTooltip ?? defaultTooltipText);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(copyText);
    setTooltipText('Copied!');
  };

  const resetTooltipText = () => {
    if (tooltipText !== defaultTooltipText) setTooltipText(altTooltip ?? defaultTooltipText);
  };

  useEffect(() => {
    if (tooltipText === defaultTooltipText) return;

    setTimeout(resetTooltipText, 2000);
  }, [defaultTooltipText, tooltipText]);

  return (
    <Tooltip overlay={tooltipText}>
      <CopyOutlined onClick={copyToClipboard} style={style} />
    </Tooltip>
  );
}
