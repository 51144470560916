import { Space } from 'antd';
import { BlockBase, BlockComponentProps } from '../blocks';
import { ButtonObject, ButtonElement } from './button';

/**
 * Actions are... well actionable.
 * Changes in an action will trigger the Block Kit onAction or something like that.
 */
export type ElementObject = ButtonObject;

export type ElementComponentProps<T> = {
  element: T;
};

export type ElementComponent<T> = (p: ElementComponentProps<T>) => JSX.Element;

const ELEMENT_MAP: {
  [key: string]: ElementComponent<ElementObject>;
} = {
  button: ButtonElement,
};

export type ActionsBlockObject = BlockBase & {
  elements: ElementObject[];
};

export function ActionElement(props: { element: ElementObject }) {
  const {
    element,
    element: { type },
  } = props;

  const ElementComponent = ELEMENT_MAP[type];
  if (!ElementComponent) {
    throw new Error(`Element Component not found for type: ${type}`);
  }

  return <ElementComponent element={element} />;
}

export function ActionsBlock(props: BlockComponentProps<ActionsBlockObject>) {
  const {
    key,
    block: { elements },
  } = props;

  const formattedElements = formatElements(elements, key);

  return (
    <Space>
      {formattedElements.map((formattedElement) => {
        const { key, ...element } = formattedElement;

        return <ActionElement key={key} element={element} />;
      })}
    </Space>
  );
}

export type FormattedElementObject = {
  key: string;
} & ElementObject;

export function formatElements(elements: ElementObject[], blockKey: string) {
  const elementKeys: {
    [key: string]: number;
  } = {};

  const formattedElements: FormattedElementObject[] = elements.map((element) => {
    const { type } = element;

    let typeIdx = 0;
    if (elementKeys[type] !== undefined) {
      typeIdx = elementKeys[type] += 1;
    } else {
      elementKeys[type] = typeIdx;
    }

    const key = `${blockKey}_${type}_${typeIdx}`;

    return {
      ...element,
      key,
    };
  });

  return formattedElements;
}
