import { Access, IClient } from 'types';
import RestoreClientButton from 'components/admin/clients/restore-client-button';
import DeleteClientButton from 'components/admin/clients/delete-client-button';
import ForceDeleteClientButton from 'components/admin/clients/force-delete-client-button';
import { useClientContext } from 'components/client-context-provider';
import { useAuthorize } from 'components/authorization';

type ClientActionButtonsProps = {
  client: IClient;
  onActionSuccess?: Function;
  onForceDelete?: Function;
};

export default function ClientActionButtons(props: ClientActionButtonsProps) {
  const { onActionSuccess, onForceDelete = onActionSuccess, client } = props;
  const { client: authClient } = useClientContext();
  const effectivePermissions = authClient?.effectivePermissions;
  const authorize = useAuthorize({ effectivePermissions });

  // Used for soft deleting clients.
  const { authorized: canExecuteClients } = authorize({ requiredPermissions: { clients: Access.execute } });
  // Used for restoring and force deleting clients.
  const { authorized: canExecuteAllClients } = authorize({ requiredPermissions: { allClients: Access.execute } });

  if (client.deletedAt) {
    return (
      <>
        <RestoreClientButton authorized={canExecuteAllClients} client={client} onSuccess={onActionSuccess} />

        <ForceDeleteClientButton
          authorized={canExecuteAllClients}
          style={{ marginLeft: `0.25rem` }}
          client={client}
          onSuccess={onForceDelete}
        />
      </>
    );
  }

  return <DeleteClientButton authorized={canExecuteClients} client={client} onSuccess={onActionSuccess} />;
}
