import { MobileOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { message } from 'antd';
import { useAuthContext } from 'components/auth-context';
import MutationButton from 'components/mutation-button';
import { UserActionButtonProps } from 'types';
import { formatUserName } from 'utils/users';

const RESET_MULTIFACTOR_MUTATION = gql`
  mutation ResetMultifactor($clientId: String, $userId: String!) {
    resetMultifactor(clientId: $clientId, userId: $userId) {
      result
    }
  }
`;

type ResetMultifactorData = {
  resetMultifactor?: {
    result: boolean;
  };
};

export default function ResetMultifactorForUserButton(props: UserActionButtonProps) {
  const { user, ...buttonProps } = props;
  const { user: authUser } = useAuthContext();

  const userName = formatUserName(user);
  const { id: userId } = user;
  const variables = {
    userId,
  };
  const modalTitle = `Are you sure you want to reset multifactor for ${userName}?`;

  const onCompleted = (data: ResetMultifactorData) => {
    if (data?.resetMultifactor !== undefined) {
      const {
        resetMultifactor: { result },
      } = data;

      // Reset was unsuccessful.
      if (!result) {
        onError();
        return;
      }

      // Reset was successful.
      message.success(`Multifactor reset for ${userName}!`);
    }
  };

  const onError = (error?: any) => {
    let errorMessage = `There was an error resetting multifactor for ${userName}.`;
    if (authUser?.isSuperUser) {
      errorMessage = `${errorMessage}${error ? ` Error: ${error.message}` : ``}`;
    }

    message.error(errorMessage);
  };

  return (
    <MutationButton
      buttonIcon={<MobileOutlined />}
      danger
      modalOkType="danger"
      modalTitle={modalTitle}
      mutation={RESET_MULTIFACTOR_MUTATION}
      mutationVariables={variables}
      onCompleted={onCompleted}
      onError={onError}
      tooltipOverlay="Reset Multifactor"
      {...buttonProps}
    />
  );
}
