import { Dropdown, Menu, Space, Tabs } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { NuButton } from 'components/nuspire';
import { ChevronDown, Time as TimeIcon } from 'components/nuspire/nu-icon';
import NuPaper from 'components/nuspire/nu-paper';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import styled from 'styled-components';
import { DatePicker } from '../../date-picker';
import { parseTimeString } from './time';
import { RangePickerTimeProps } from 'antd/lib/time-picker';

const { RangePicker } = DatePicker;

export type TimeRangeButtonProps = {
  disabled?: boolean;
  time?: string;
  onChange: (args: { time?: string; from?: string; to?: string; fromIso?: string; toIso?: string }) => void;
  relativeOptions?: RelativeRangeMenuItem[];
};

const TdRoot = styled.div`
  padding: 12px 24px;
`;

enum TabKey {
  relative = 'relative',
  absolute = 'asolute',
}

function checkIsAbsolute(value?: string) {
  if (!value) {
    return TabKey.relative;
  }

  const isAbsolute = /(from:\d{4}-\d{2}-\d{2}).*?(to:\d{4}-\d{2}-\d{2}).*/.test(value);

  return isAbsolute;
}

export function getActiveKey(value?: string): TabKey {
  if (checkIsAbsolute(value)) {
    return TabKey.absolute;
  }

  return TabKey.relative;
}

export type RelativeRangeMenuItem = {
  key: string;
  label: string;
};

const RELATIVE_MENU_ITEMS = [
  {
    key: 'from:now-24h,to:now',
    label: 'Last 24 Hours',
  },
  {
    key: 'from:now-7d,to:now',
    label: 'Last 7 Days',
  },
  {
    key: 'from:now-30d,to:now',
    label: 'Last 30 Days',
  },
  {
    key: 'from:now-60d,to:now',
    label: 'Last 60 Days',
  },
];

export function parseAbsoluteDateRangeValue(value?: string) {
  if (!value) {
    return undefined;
  }

  const { fromIso, toIso } = parseTimeString({ time: value });

  if (!fromIso || !toIso) {
    return undefined;
  }

  return [dayjs(new Date(fromIso)), dayjs(new Date(toIso))];
}

/**
 * Absolute Date Picker.
 *
 * @param props
 * @returns
 */
export function AbsoluteDatePicker(props: {
  value?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onChange: (value?: string) => void;
  rangePickerProps?: RangePickerTimeProps<Dayjs>;
}) {
  const { onChange, value, rangePickerProps } = props;

  const handleChange = (val: any) => {
    if (!val) {
      return onChange(undefined);
    }
    const [from, to] = val;

    const time = `from:${from.toISOString()},to:${to.toISOString()}`;

    onChange(time);
  };

  const rangePickerValue = parseAbsoluteDateRangeValue(value) as any;
  const now = new Date();

  return (
    <RangePicker
      onClick={props.onClick}
      value={rangePickerValue}
      onChange={handleChange}
      format="YYYY-MM-DD HH:mm"
      showTime={{
        hideDisabledOptions: true,
        use12Hours: true,
        defaultValue: [dayjs('00:00', 'HH:mm'), dayjs('11:59:59', 'HH:mm')],
      }}
      // showTime
      {...rangePickerProps}
      // we don't actually want to disable dates. Rather warn the user that things might fail.
      // disabledDate={(date) => {
      //   const minDate = 'now-30d';

      //   const dateInQuestion = date.toDate();
      //   const parsed = parseTimeSyntax(minDate, now);
      //   if (!parsed) return false;

      //   const parsedDate = new Date(parsed);

      //   if (dateInQuestion < parsedDate) {
      //     return true; // disabled
      //   }

      //   return false;
      // }}
    />
  );
}

export function TimeDropdown(props: {
  value?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onChange: (val?: string) => void;
  rangePickerProps?: RangePickerProps;
  relativeOptions: RelativeRangeMenuItem[];
}) {
  const { value, onChange, rangePickerProps, relativeOptions } = props;

  // should have some tabs for Relative / absolute.
  const [activeKey, setActiveKey] = useState<TabKey>(getActiveKey(value));

  return (
    <NuPaper level={2}>
      <TdRoot>
        <Tabs
          activeKey={activeKey}
          onChange={(activeKey) => setActiveKey(activeKey as TabKey)}
          items={[
            {
              key: TabKey.relative,
              label: 'Relative',
              children: (
                <Menu
                  style={{
                    boxShadow: 'none',
                  }}
                  items={relativeOptions}
                  onClick={({ key }) => onChange(key)}
                />
              ),
            },
            {
              key: TabKey.absolute,
              label: 'Absolute',
              children: (
                <div onClick={props.onClick}>
                  <AbsoluteDatePicker
                    onClick={props.onClick}
                    value={value}
                    onChange={onChange}
                    rangePickerProps={rangePickerProps as RangePickerTimeProps<Dayjs>}
                  />
                </div>
              ),
            },
          ]}
        />
      </TdRoot>
    </NuPaper>
  );
}

// parse iso range to human readable.
function parseAbsoluteStringLabel(absoluteRangeString: string) {
  const [fromStr, toStr] = absoluteRangeString.split(',');
  let from: string | undefined = fromStr.match(/from:(.*)$/)?.[1];
  let to: string | undefined = toStr.match(/to:(.*)$/)?.[1];

  if (!from || !to) {
    return 'Absolute Date Range';
  }

  from = new Date(from).toLocaleDateString('en');
  to = new Date(to).toLocaleDateString('en');

  return `Absolute: ${from} to ${to}`;
}

function getLabel(menuItems: RelativeRangeMenuItem[], value?: string) {
  if (!value) {
    return '--';
  }

  if (checkIsAbsolute(value)) {
    return parseAbsoluteStringLabel(value); // probably need to format.
  }

  const menuItem = menuItems.find((i) => i.key === value);
  if (menuItem) {
    return menuItem.label;
  }

  return value;
}

export function NuTimeRangeButton(props: TimeRangeButtonProps) {
  const { disabled, onChange, time, relativeOptions = RELATIVE_MENU_ITEMS } = props;

  const label = getLabel(relativeOptions, time);

  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const handleChange = (time?: string) => {
    const { from, to, fromIso, toIso } = parseTimeString({ time });

    onChange({
      time,
      from,
      to,
      fromIso,
      toIso,
    });

    setDropdownOpen(false);
  };

  return (
    <Dropdown
      disabled={disabled}
      open={isDropdownOpen}
      onOpenChange={setDropdownOpen}
      trigger={['click']}
      menu={{
        items: [
          {
            key: '1',
            label: (
              <TimeDropdown
                onClick={(e) => e.stopPropagation()}
                value={time}
                onChange={handleChange}
                relativeOptions={relativeOptions}
              />
            ),
            style: { padding: 0 },
          },
        ],
        style: {
          padding: 0,
        },
      }}
    >
      <NuButton
        icon={
          <TimeIcon
            style={{
              marginRight: '6px',
            }}
          />
        }
        size="large"
      >
        <Space>
          {label}
          <ChevronDown />
        </Space>
      </NuButton>
    </Dropdown>
  );
}
