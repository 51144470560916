import { message } from 'antd';
import { IUser } from '../../../types';
import { downloadBase64ContentAsFile } from '../../../utils/download-base64-content-as-file';

const exportColHeadingsMap = {
    "id": "User Id",
    "clientId": "Client Id",
    "lastLogin": "Last Login",
    "authenticationType": "Authentication Type",
    "serviceNowUserId": "ServiceNow ID",
    "actions": "Actions",
    "createdAt": "Created At",
    "login": "Login Name",
    "email": "Email",
    "firstName": "First Name",
    "lastName": "Last Name",
    "isSuperUser": "Is Superuser",
    "lastLogout": "Last Logout",
    "mobilePhoneNumber": "Mobile Phone Number",
    "mobilePhoneNumberCountry": "Mobile Phone Number Country",
    "phoneNumber": "Phone Number",
    "phoneNumberCountry": "Phone Number Country",
    "phoneNumberExt": "Phone Number Extension",
    "identityProviderIds": "Identity Provider Id's",
    "managedClientIds": "Managed Client Id's",
    "notificationCount": "Notification Count",
    "jiraCustomerId": "Jira Customer Id",
};

export const runExportUsersAndDownload = async (runExport, searchValue: String, showDeletedUsers: Boolean) => {
    let checkForData = true;
    let rows: Array<IUser> = [];
    let nextKey: Record<string, any> | undefined;

    while (checkForData) {
        const { data, error } = await runExport({
            variables: {
                search: searchValue,
                showDeleted: showDeletedUsers,
                options: {
                    nextKey: nextKey,
                    pageSize: 100,
                },
            },
        });

        if (error) {
            console.error(error);
            message.error(`An error occurred while attempting to export users containing [${searchValue}]`);
        }

        nextKey = data?.allUsers?.nextKey;
        if (!nextKey) {
            checkForData = false;
        }

        rows = rows.concat(data.allUsers.items);
    }

    processExportedUsers(rows, searchValue);

};

const processExportedUsers = async (rows: Array<IUser>, searchValue: String) => {
    if (rows?.length > 0) {
        // exclude the first column which is the __typename sent by graphql
        let fields = Object.keys(rows[0]);
        fields = fields.filter(item => item != '__typename');

        // use empty string in place of null
        const replacer = function (key, value) { return value === null ? '' : value }

        const csv = rows.map(function (row) {
            return fields.map(function (fieldName) {
                return JSON.stringify(row[fieldName], replacer)
            }).join(',');
        });

        // map internal column names to user friendly names
        fields = Array.from(fields.map(function (k) { return (k in exportColHeadingsMap) ? exportColHeadingsMap[k] : k }));
        // add column names as first array element
        csv.unshift(fields.join(','));

        // base64 encode rows as strings joined with new lines
        const b64Data = window.btoa(csv.join('\r\n'));

        downloadBase64ContentAsFile({
            content: b64Data,
            contentType: "text/csv",
            filename: "exportedAdminUsers.csv",
        });

        message.success(`All ${csv.length > 1 ? csv.length - 1 : 0} users matching your selection criteria [${searchValue}] have been exported`);
    } else {
        message.warning(`No users were found to export for this criteria [${searchValue}]`);
    }

};