import styled from 'styled-components';
import baseTheme from 'components/theme';
import { NuButton } from './nuspire';
import { Close as CloseIcon, Search as SearchIcon } from 'components/nuspire/nu-icon';
import { Dropdown } from 'antd';
import { useRef, useState } from 'react';
import { IInput } from './data-explorer/data-explorer';
import { SearchParamsInterface } from 'utils/react-hooks/useSearchParams';

// Consolidate all this
import { Input } from 'components/data-explorer/search-input';
import { FilterDropdownRoot, FilterInputListItem } from 'components/data-explorer/filter-dropdown';
import { FilterTags } from 'components/data-explorer/filter-tags';

export const ComplexSearchRoot = styled.div`
  flex: 1;
`;

export const ComplexSearchSelector = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  background-color: ${(p) => p.theme.token.colorBgContainer};
  border: 1px solid ${(p) => p.theme.token.colorBorder};
  border-radius: 8px;
  padding: 1px 4px;
  cursor: text;

  min-height: 40px;

  .complex-search:hover & {
    border-color: ${baseTheme.color.riverBlue};
  }
`;

export const ComplexSearchSelectorOverflow = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export function ClearFiltersButton(props: { onClick: () => void }) {
  const { onClick } = props;

  return (
    <NuButton shape="circle" type="text" style={{ color: '#a6a6a6' }} onClick={onClick}>
      <CloseIcon />
    </NuButton>
  );
}

export const FilterListRoot = styled.div`
  padding: 8px 0;
`;

export function FilterDropdown(props: {
  filterInputs: IInput[];
  onAddFilter: (key: string, value: string) => void;
  onRemoveFilter: (key: string) => void;
}) {
  const { onAddFilter, onRemoveFilter, filterInputs } = props;

  return (
    <FilterDropdownRoot onClick={(e) => e.stopPropagation()}>
      <FilterListRoot>
        {filterInputs.map((filterInput) => (
          <FilterInputListItem
            key={`filter-input-${filterInput.key}`}
            filterInput={filterInput}
            handleAddFilter={onAddFilter}
            handleRemoveFilter={onRemoveFilter}
          />
        ))}
      </FilterListRoot>
    </FilterDropdownRoot>
  );
}

export function ComplexSearchFilterButton(props: { onClick: () => void }) {
  const { onClick } = props;
  return (
    <NuButton
      type="text"
      style={{ marginRight: '4px', color: baseTheme.color.riverBlue }}
      onClick={onClick}
      icon={<SearchIcon />}
    >
      Filters
    </NuButton>
  );
}

export const SearchInputRoot = styled.div`
  input {
    cursor: auto;
    margin: 0;
    padding: 0;
    background: 0 0;
    border: none;
    outline: none;
  }

  input:focus {
    border: none;
    box-shadow: none;
    outline: none;
  }
`;

export const SEARCH = 'search';

export function ComplexSearch(props: {
  filterInputs: IInput[];
  searchParams: SearchParamsInterface;
  // onAddFilter: (key: string, value: string) => void;
  // onRemoveFilter: (key: string) => void;
}) {
  // Props
  const { filterInputs, searchParams } = props;
  // ========== Refs ===========
  const inputRef = useRef<HTMLInputElement>(null);

  const onClick = ({ target }) => {
    if (target !== inputRef.current) {
      const isIE = (document.body.style as any).msTouchAction !== undefined;
      if (isIE) {
        setTimeout(() => {
          inputRef?.current?.focus();
        });
      } else {
        inputRef?.current?.focus();
      }
    }
  };

  // ========== State ===========
  const [filterDropdownVisible, setFilterDropdownVisible] = useState<boolean>(false);
  const handleOpenSearch = () => setFilterDropdownVisible(true);
  const handleCloseSearch = () => {
    setFilterDropdownVisible(false);
  };

  // ============ Filters ===========
  const { parsed: params, setParameter, clearAll } = searchParams;
  const handleClearFilters = () => {
    clearAll();
  };

  const handleAddFilter = (key: string, value: string) => {
    setParameter(key, value);
  };
  const handleRemoveFilter = (key: string) => {
    setParameter(key, null);
  };

  // ========== Search Input =========
  const [searchValue, setSearchValue] = useState<string>('');

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const {
      target: { value },
    } = event;

    setSearchValue(value);
  };

  const deleteLastTag = () => {
    if (params[SEARCH]?.length) {
      // remove search tag.
      setParameter(SEARCH, null);
    } else {
      const tags = filterInputs?.filter((f) => params[f.key]?.length) ?? [];
      const lastTag = tags[tags.length - 1];

      if (lastTag) {
        setParameter(lastTag.key, null);
      }
    }
  };

  // ========== Text Query Input Node ==========
  const handleInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    const { key } = event;

    if (key === 'Enter') {
      updateSearchTag();
    }

    if (key === 'Backspace' && searchValue.length === 0) {
      deleteLastTag();
    }
  };

  const updateSearchTag = () => {
    if (searchValue.length) {
      setParameter(SEARCH, searchValue);
      setSearchValue('');
    }
  };

  const inputNode = (
    <SearchInputRoot>
      <Input ref={inputRef} value={searchValue} onChange={onInputChange} onKeyDown={handleInputKeyDown} />
    </SearchInputRoot>
  );

  return (
    <ComplexSearchRoot className="complex-search">
      <Dropdown
        trigger={['click']}
        open={filterDropdownVisible}
        overlay={
          <FilterDropdown
            filterInputs={filterInputs}
            onAddFilter={handleAddFilter}
            onRemoveFilter={handleRemoveFilter}
          />
        }
        onOpenChange={(open) => {
          if (!open) {
            handleCloseSearch();
          }
        }}
      >
        <ComplexSearchSelector onClick={onClick}>
          <ComplexSearchSelectorOverflow>
            <ComplexSearchFilterButton onClick={handleOpenSearch} />

            <FilterTags filterInputs={filterInputs} />
          </ComplexSearchSelectorOverflow>
        </ComplexSearchSelector>
      </Dropdown>
    </ComplexSearchRoot>
  );
}
