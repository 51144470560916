import { Table } from 'antd';
import { WidgetComponentProps } from '../..';
import { Link } from 'components/nuspire';
import EmptyState from 'components/nuspire/nu-empty-state';

const tableColumns = [
  {
    title: 'Location Name',
    key: 'location_name',
    dataIndex: 'location_name',
    render: (_, record) => {
      return (<Link to={`/${record.key}`}>{record.location_name}</Link>)
    },
  },
  {
    title: 'Install Type',
    dataIndex: 'rise_type',
    key: 'rise_type',
  },
  {
    title: 'Risky Web Count',
    dataIndex: 'risky_web_count',
    key: 'risky_web_count',
  },
  {
    title: 'Risky Application Control Count',
    dataIndex: 'risky_appctrl_count',
    key: 'risky_appctrl_count',
  },
  {
    title: 'Risky Blocked Web Count',
    dataIndex: 'blocked_web_count',
    key: 'blocked_web_count',
  },
];

export function TopRiskyLocationsWidget(props: WidgetComponentProps<any, any>) {
  const { data } = props;

  if (!data?.TopRiskyLocationsDataArray?.length) return <EmptyState>No results returned.</EmptyState>;

  return <Table columns={tableColumns} dataSource={data.TopRiskyLocationsDataArray} rowKey="key" pagination={false} />;
}
