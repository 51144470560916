import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { message } from 'antd';
import { useAuthContext } from 'components/auth-context';
import { useClientContext } from 'components/client-context-provider';
import { parseTimeString } from 'components/nuspire/nu-time-range-button/time';
import { UPDATE_PROFILE_MUTATION } from 'components/user-profile/user-profile-form';
import { config } from 'config';
import { useEffect } from 'react';
import { CaseManagementFilters } from 'types';
import { AllCasesQuery, AllCasesQueryVariables } from 'types/graph-codegen/graph-types';
import CaseManagementTable, { DefaultFilters, OnChangeArgs, hasResolutionCodes } from './case-management-table';

export const ALL_CASES_QUERY = gql`
  query AllCases($clientId: String, $filters: CaseFilters!) {
    cases(clientId: $clientId, filters: $filters) {
      cases {
        account {
          name
        }
        contact {
          name
        }
        category
        subcategory
        number
        priority
        short_description
        state
        sys_updated_on
        opened_by
      }
      total
    }
  }
`;

export const EXPORT_CASES_QUERY = gql`
  query ExportCases($clientId: String, $filters: CaseFilters!) {
    exportCases(clientId: $clientId, filters: $filters) {
      base64Data
      contentType
      filename
    }
  }
`;

interface ICasesTableProps {
  states: string[];
  statesLoading?: boolean;
}

export default function CasesTable({ states, statesLoading = false }: ICasesTableProps) {
  const { paginationDefaults } = config;
  const { clientId } = useClientContext();
  const { user: authUser } = useAuthContext();
  const caseManagementFilters = authUser?.caseManagementFilters;
  const defaultFilters = caseManagementFilters?.cases;
  const [getCases, { data, error, loading }] = useLazyQuery<AllCasesQuery, AllCasesQueryVariables>(ALL_CASES_QUERY, {
    fetchPolicy: 'network-only',
  });
  const [updateUserProfile, { error: updateProfileError }] = useMutation(UPDATE_PROFILE_MUTATION);

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error('An error occurred while fetching cases.');
    }
  }, [error]);

  useEffect(() => {
    if (updateProfileError) {
      console.error(error);
      message.error('There was an error syncing filter settings.');
    }
  }, [updateProfileError]);

  const allCasesData = data?.cases;

  const fetchData = async (args: OnChangeArgs) => {
    // setLoading(true);
    const {
      assignmentGroups,
      category,
      currentPage,
      dateField,
      includeChildren,
      pageSize,
      priorities: priorityFilters,
      search,
      sortBy,
      sortOrder,
      states: stateFilters,
      subcategory,
      time,
      user: userFilter,
    } = args;
    const resolutionCodes = hasResolutionCodes('cases', args) ? args.resolutionCodes : undefined;
    const { fromIso, toIso } = parseTimeString({ time });

    await getCases({
      variables: {
        clientId,
        filters: {
          assignmentGroups,
          category,
          dateField,
          includeChildren,
          limit: pageSize,
          priorities: priorityFilters ?? [],
          resolutionCodes,
          search,
          skip: paginationDefaults.calcSkip(currentPage, pageSize),
          sortBy,
          sortOrder,
          endDate: toIso,
          startDate: fromIso,
          states: stateFilters ?? [],
          subcategory,
          user: userFilter,
        },
      },
    });
  };

  const saveFilters = async (filters: DefaultFilters) => {
    const newFilters: CaseManagementFilters = {
      ...caseManagementFilters,
      cases: filters,
    };

    await updateUserProfile({
      variables: {
        input: {
          caseManagementFilters: newFilters,
        },
      },
    });
  };

  return (
    <CaseManagementTable
      data={allCasesData?.cases ?? []}
      defaultFilters={defaultFilters}
      exportQuery={EXPORT_CASES_QUERY}
      exportQueryName="exportCases"
      loading={loading}
      onChange={fetchData}
      onSearchClick={saveFilters}
      states={states}
      statesLoading={statesLoading}
      total={allCasesData?.total ?? 0}
      type="cases"
    />
  );
}
