
export default () => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0961 11.3641C14.1037 10.6913 15.2883 10.3325 16.4999 10.333C18.123 10.3351 19.6789 10.9808 20.8266 12.1285C21.9743 13.2762 22.62 14.8322 22.6221 16.4552C22.6227 17.6669 22.2638 18.8514 21.591 19.8591C20.9182 20.8667 19.9617 21.6522 18.8424 22.1161C17.7231 22.58 16.4913 22.7015 15.303 22.4653C14.1146 22.229 13.023 21.6456 12.1663 20.7889C11.3095 19.9321 10.7261 18.8405 10.4899 17.6522C10.2536 16.4638 10.3751 15.2321 10.8391 14.1128C11.303 12.9935 12.0884 12.0369 13.0961 11.3641ZM13.9788 20.2284C14.725 20.727 15.6024 20.9932 16.4999 20.9932C17.703 20.9918 18.8564 20.5132 19.7072 19.6625C20.5579 18.8118 21.0365 17.6583 21.0379 16.4552C21.0379 15.5577 20.7717 14.6803 20.2731 13.9341C19.7745 13.1878 19.0657 12.6062 18.2365 12.2627C17.4073 11.9192 16.4949 11.8294 15.6146 12.0045C14.7343 12.1796 13.9257 12.6118 13.2911 13.2464C12.6564 13.8811 12.2242 14.6896 12.0491 15.5699C11.874 16.4502 11.9639 17.3626 12.3074 18.1918C12.6508 19.021 13.2325 19.7298 13.9788 20.2284Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 5C21.2447 5 24.9449 7.75768 26.7306 9.39833C29.2439 11.7156 31 14.6156 31 16.455C31 18.2944 29.2439 21.1944 26.7306 23.5117C24.9476 25.1523 21.2447 27.91 16.5 27.91C11.7553 27.91 8.05241 25.1523 6.26944 23.5117C3.75611 21.1944 2 18.2944 2 16.455C2 14.6156 3.75611 11.7156 6.26944 9.39833C8.05509 7.75768 11.7553 5 16.5 5ZM3.58426 16.455C3.58426 19.159 9.45139 26.3231 16.5 26.3231C23.5486 26.3231 29.4157 19.159 29.4157 16.455C29.4157 13.751 23.5486 6.58694 16.5 6.58694C9.45139 6.58694 3.58426 13.751 3.58426 16.455Z"
      fill="currentColor"
    />
  </svg>
);
