import { App, ConfigProvider, theme as antdTheme } from 'antd';
import enUS from 'antd/es/locale/en_US';
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { darkAlgorithmShim } from './antd-dark-algorithm-shim';
import { ThemeProvider } from './styled-components-antd-token-shim';
import { GlobalStyle, ThemeMode, darkModeTheme, lightModeTheme, themeModes } from './theme';
import { THEME_MODE_KEY } from '../../localstorage';

export const themeSettings = [...themeModes, 'system'] as const;
export type ThemeSetting = (typeof themeSettings)[number];

export type IMyNuspireThemeContext = {
  theme: ThemeMode;
  setting: ThemeSetting;
  // save theme setting to localStorage
  setThemeAndSave: (newThemeSetting: ThemeSetting) => void;
};

export const MyNuspireThemeContext = createContext<IMyNuspireThemeContext>({
  theme: 'light',
  setting: 'light',
  setThemeAndSave: () => {},
});

export const useMyNuspireThemeContext = () => useContext(MyNuspireThemeContext);

interface MyNuspireThemeContextProviderProps {
  children: ReactNode;
}

export function MyNuspireThemeContextProvider({ children }: MyNuspireThemeContextProviderProps) {
  const [theme, setTheme] = useState<ThemeMode>('light');
  const [setting, setThemeSetting] = useState<ThemeSetting>('light');

  const handleSystemThemeUpdate = useCallback((event: MediaQueryListEvent) => {
    if (setting === 'system') {
      setTheme(event.matches ? 'dark' : 'light');
    }
  }, []);

  const setThemeAndSave = useCallback((newThemeSetting: ThemeSetting) => {
    setThemeSetting(newThemeSetting);
    window.localStorage.setItem(THEME_MODE_KEY, newThemeSetting);
  }, []);

  // init
  useEffect(() => {
    const savedTheme = window.localStorage.getItem(THEME_MODE_KEY);
    if (savedTheme && (savedTheme === 'light' || savedTheme === 'dark' || savedTheme === 'system')) {
      setThemeSetting(savedTheme);
    }
  }, []);

  useEffect(() => {
    if (setting === 'system') {
      const mql = window.matchMedia('(prefers-color-scheme: dark)');
      setTheme(mql.matches ? 'dark' : 'light');

      mql.addEventListener('change', handleSystemThemeUpdate);

      return () => {
        mql.removeEventListener('change', handleSystemThemeUpdate);
      };
    }

    setTheme(setting);
    return () => {};
  }, [setting]);

  const context: IMyNuspireThemeContext = useMemo(
    () => ({
      theme,
      setting,
      setThemeAndSave,
    }),
    [theme, setting],
  );

  return (
    <MyNuspireThemeContext.Provider value={context}>
      <ConfigProvider
        locale={enUS}
        theme={{
          algorithm: theme === 'dark' ? darkAlgorithmShim : antdTheme.defaultAlgorithm,
          token: theme === 'dark' ? darkModeTheme.token : lightModeTheme.token,
          components: theme === 'dark' ? darkModeTheme.componentOverrides : lightModeTheme.componentOverrides,
        }}
      >
        <ThemeProvider themeMode={theme}>
          <App>
            <GlobalStyle />
            {children}
          </App>
        </ThemeProvider>
      </ConfigProvider>
    </MyNuspireThemeContext.Provider>
  );
}
