import { gql, useQuery, useMutation } from '@apollo/client';
import { Modal, Form, Select, Input, message } from 'antd';
import { NuButton } from 'components/nuspire';
import { useClientContext } from 'components/client-context-provider';
import { useAuthContext } from 'components/auth-context';
import { Access } from 'types';
import { useClientIdentifier } from 'utils/react-hooks/use-client-identifier';
import { GetCaseResolutionCodesQuery, GetCaseResolutionCodesQueryVariables } from 'types/graph-codegen/graph-types';
import { mixpanelTrack } from 'utils/mixpanel/mixpanel-track';

const GET_CODES_QUERY = gql`
  query GetCaseResolutionCodes {
    getCaseResolutionCodes {
      id
      name
    }
  }
`;

export const RESOLVE_CASE_MUTATION = gql`
  mutation ResolveCase(
    $clientId: String
    $caseNumber: String!
    $serviceNowAccountId: String
    $resolutionCode: String!
    $notes: String!
  ) {
    resolveCase(
      clientId: $clientId
      caseNumber: $caseNumber
      serviceNowAccountId: $serviceNowAccountId
      resolutionCode: $resolutionCode
      notes: $notes
    )
  }
`;

const { Option } = Select;

type ResolveCaseModalProps = {
  accountId?: string;
  caseNumber: string;
  close: Function;
  refetch?: Function;
  visible: boolean;
};

export default function ResolveCaseModal(props: ResolveCaseModalProps) {
  const { authorize, client, clientId } = useClientContext();
  const serviceNowAccountId = useClientIdentifier({
    clientIdentifiers: client?.clientIdentifiers,
    type: 'serviceNowAccountId',
  })?.value;

  const { user: authUser } = useAuthContext();
  const { authorized: canExecuteCaseManagement } = authorize({
    requiredPermissions: { caseManagement: Access.execute },
  });
  const canResolveCase = !!authUser?.serviceNowUserId && !!serviceNowAccountId && canExecuteCaseManagement;
  const { accountId, caseNumber, close, refetch, visible } = props;
  const { data, loading: dataLoading } = useQuery<GetCaseResolutionCodesQuery, GetCaseResolutionCodesQueryVariables>(
    GET_CODES_QUERY,
  );
  const [resolveCase, { loading }] = useMutation(RESOLVE_CASE_MUTATION);

  const onFinish = async (values: { code: string; notes: string }) => {
    if (!canResolveCase) {
      if (!canExecuteCaseManagement) {
        message.error('You do not have the required permissions to resolve a case.');
      } else if (!authUser?.serviceNowUserId) {
        message.error('Your user is not linked with ServiceNow. Unable to resolve case.');
      } else if (!serviceNowAccountId) {
        message.error('Your client is not linked with ServiceNow. Unable to resolve case.');
      } else {
        message.error('Unable to resolve case.');
      }

      return;
    }

    const { code, notes } = values;
    try {
      const result = await resolveCase({
        variables: {
          clientId,
          caseNumber,
          serviceNowAccountId: accountId,
          resolutionCode: code,
          notes,
        },
      });
      close(false);

      if (refetch) {
        refetch();
      }

      if (result) {
        message.success(`Case Resolved!`);

        mixpanelTrack('case-closed', {
          resolutionCode: code,
        });
      } else {
        message.error('There was an error resolving this case.');
      }
    } catch (err) {
      message.error('There was an error resolving this case.');
    }
  };

  const resolutionCodes = data?.getCaseResolutionCodes ?? [];

  return (
    <>
      {!dataLoading && (
        <Modal open={visible} title="Resolve Case" onCancel={() => close(false)} footer={null}>
          <Form onFinish={onFinish}>
            <Form.Item name="code" label="Resolution Code" rules={[{ required: true, message: `A code is required!` }]}>
              <Select>
                {resolutionCodes.map((c) => (
                  <Option key={c.id} value={c.id}>
                    {c.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="notes"
              label="Resolution Notes"
              rules={[{ required: true, message: `A note is required!` }]}
            >
              <Input.TextArea />
            </Form.Item>
            <NuButton type="primary" htmlType="submit" loading={loading} disabled={!canResolveCase || loading}>
              Resolve Case
            </NuButton>
          </Form>
        </Modal>
      )}
    </>
  );
}
