import { Checkbox, Form, Input, Select } from 'antd';
import PhoneInput, { alphabetizedCountries } from 'components/phone-input';

export const partnerTypes = [
  {
    label: 'Referral Partner',
    value: 'Referral Partner',
  },
  {
    label: 'Channel Partner',
    value: 'Channel Partner',
  },
  {
    label: 'Technology Partner',
    value: 'Technology Partner',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const partnerBusinessTypes = [
  {
    label: 'MSP',
    value: 'MSP',
  },
  {
    label: 'ISP',
    value: 'ISP',
  },
  {
    label: 'Reseller',
    value: 'Reseller',
  },
  {
    label: 'Technology Partner',
    value: 'Potential Technology Partner',
  },
];

type PartnerPhoneInputProps = {
  name: string;
  initialExtValue?: string;
  initialPhoneValue?: string;
  partnerPhoneCountryCodeKey?: string;
};

function PartnerPhoneInput(props: PartnerPhoneInputProps) {
  const { name, initialExtValue, initialPhoneValue, partnerPhoneCountryCodeKey } = props;

  return (
    <PhoneInput
      name={name}
      initialValues={{
        countryCodeKey: partnerPhoneCountryCodeKey,
        number: initialPhoneValue,
        extension: initialExtValue,
      }}
      label="Business Phone"
      rules={{
        phoneNumber: [{ required: true, message: 'Please enter a valid phone number' }],
      }}
    />
  );
}

type PartnerClientFormItemsProps = {
  initialValues: {
    additionalInfo?: string;
    businessCountry?: string;
    businessEmail?: string;
    businessPhone?: string;
    businessPhoneExt?: string;
    businessSalesRepresentative?: string;
    businessType?: string;
    businessWebsite?: string;
    partnerType?: string;
  };
  inlineCheckbox?: boolean;
  isPartner: boolean;
  partnerPhoneCountryCodeKey?: string;
  setIsPartner: Function;
};

export default function PartnerClientFormItems(props: PartnerClientFormItemsProps) {
  const { initialValues, inlineCheckbox = false, isPartner, partnerPhoneCountryCodeKey, setIsPartner } = props;
  const defaultPartnerPhone = initialValues?.businessPhone?.includes(' ')
    ? initialValues.businessPhone.split(' ')[1] ?? null
    : null;

  const filterCountrySelect = (input: string, option: any) => {
    console.log({ option }, option.children);
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  const filterSelect = (input: string, option: any) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <>
      <Form.Item
        label={!inlineCheckbox ? 'Partner Client?' : undefined}
        name="isClientPartner"
        initialValue={isPartner}
        valuePropName="checked"
      >
        <Checkbox onChange={(e) => setIsPartner(e.target.checked)}>{inlineCheckbox ? 'I am a Partner' : null}</Checkbox>
      </Form.Item>
      {isPartner && (
        <>
          <Form.Item
            label="Partner Type"
            name="partnerType"
            initialValue={initialValues.partnerType}
            rules={[{ required: true, message: 'Please select a partner type' }]}
          >
            <Select showSearch filterOption={filterSelect}>
              {partnerTypes.map((pt) => (
                <Select.Option key={pt.value} value={pt.value}>
                  {pt.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Business Type"
            name="partnerBusinessType"
            initialValue={initialValues.businessType}
            rules={[{ required: true, message: 'Please select a business type' }]}
          >
            <Select showSearch filterOption={filterSelect}>
              {partnerBusinessTypes.map((bt) => (
                <Select.Option key={bt.value} value={bt.value}>
                  {bt.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Country"
            name="partnerCountry"
            initialValue={initialValues.businessCountry ?? 'US'}
            rules={[{ required: true, message: 'Please enter a country' }]}
          >
            <Select showSearch filterOption={filterCountrySelect}>
              {Object.entries(alphabetizedCountries).map(([key, countryData]) => (
                <Select.Option key={key} value={key}>
                  {countryData.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <PartnerPhoneInput
            name="partnerPhone"
            initialExtValue={initialValues.businessPhoneExt}
            initialPhoneValue={defaultPartnerPhone ?? undefined}
            partnerPhoneCountryCodeKey={partnerPhoneCountryCodeKey}
          />
          <Form.Item
            label="Sales Representative"
            name="partnerSalesRep"
            initialValue={initialValues.businessSalesRepresentative}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Business Website"
            name="partnerWebsite"
            initialValue={initialValues.businessWebsite}
            rules={[{ required: false, type: 'url', message: 'Please enter a valid website' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Business Email"
            name="partnerEmail"
            initialValue={initialValues.businessEmail}
            rules={[{ type: 'email', required: true, message: 'Please enter a valid email address' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Additional Information"
            name="partnerAdditionalInfo"
            initialValue={initialValues.additionalInfo}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
        </>
      )}
    </>
  );
}
