import { useCallback, useEffect, useState } from 'react';
import { Select } from 'antd';
import { gql, useQuery } from '@apollo/client';
import debounce from 'lodash.debounce';
import { ActionInputProps } from '../action-form-field';

export const SEARCH_CLIENTS = gql`
  query ClientSelectSearch($clientId: String, $queryString: String, $includeAll: Boolean) {
    clientSearch(clientId: $clientId, queryString: $queryString, includeAll: $includeAll) {
      items {
        id
        name
      }
    }
  }
`;

export function ClientSelect(props: ActionInputProps) {
  const { clientId, onChange, value } = props;

  // ========= State Management ========
  const [queryString, setQueryString] = useState<string | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string | undefined>(queryString);

  // UI should update instantaneously
  const handleSearchValue = (val?: string) => {
    if (val?.length) {
      setSearchValue(val);
    } else {
      setSearchValue(undefined);
    }
  };

  // query should only execute after user stops typing
  const debouncedSearch = useCallback(
    debounce((newSearch: string | undefined) => {
      setQueryString(newSearch);
    }, 600),
    [],
  );

  useEffect(() => {
    debouncedSearch(searchValue);
  }, [searchValue]);

  // ========= Graphql Request =========
  const { data, loading } = useQuery(SEARCH_CLIENTS, { variables: { clientId, queryString } });

  // build select options
  const items = data?.clientSearch?.items;
  let options = items?.map((i) => ({
    label: i.name,
    value: i.id,
  }));

  // ========= Render =========
  const selectValue = value?.map((i) => ({
    label: i.name,
    value: i.id,
  }));

  return (
    <Select
      labelInValue
      value={selectValue}
      options={options}
      placeholder="Select Client(s)"
      showSearch
      onSearch={handleSearchValue}
      searchValue={searchValue}
      loading={loading}
      filterOption={false}
      style={{ width: '100%' }}
      mode="multiple"
      onChange={(values) => {
        const transformedValues = values.length
          ? values.map((option) => ({
              name: option.label,
              id: option.value,
            }))
          : undefined;

        onChange(transformedValues);
      }}
    />
  );
}
