import { WidgetDefinition, WidgetRendererProps } from 'components/dashboard/widget-definitions/types';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import StepListItem, {
  StepListItemIcon,
  StepListItemLabel,
  StepListItemMeta,
  StepListStatus,
} from 'components/cyberx/event-pipeline2.0/steps/common/step-list-item';
import { CONNECTORS } from 'components/connections/connectors';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import EmptyState from 'components/nuspire/nu-empty-state';
import { Link, NuButton, NuCardContent } from 'components/nuspire';
import { Badge } from 'antd';
import { DataSourcesWidgetQuery } from '../../../../../types/graph-codegen/graph-types';

const DATA_SOURCES_WIDGET = gql`
  query DataSourcesWidget($clientId: String!) {
    dataSources(clientId: $clientId) {
      id
      name
      enabled

      status
      statusUpdatedAt
      dataSourceDefinition {
        id
        name
        slug

        connector {
          id
          name
        }
      }
    }
  }
`;

const DslRoot = styled.div`
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export type DataSource = {
  id: string;
  enabled: boolean;
  status?: 'healthy' | 'error';
  dataSourceDefinition: {
    connector: {
      id: string;
    };
  };
  name?: string;
};

export interface DataSourceListProps {
  dataSources: DataSource[];
  clientId: string;
}

export function DataSourceList(props: DataSourceListProps) {
  const { dataSources, clientId } = props;

  return (
    <>
      {dataSources.map((ds) => {
        const feConnectorDef = CONNECTORS.find((c) => c.slug === ds.dataSourceDefinition?.connector?.id);
        const icon = feConnectorDef?.icon ?? null;

        return (
          <StepListItem key={ds.id} linkTo={`/${clientId}/cyberx/data-sources/${ds.id}`}>
            {icon && <StepListItemIcon>{icon}</StepListItemIcon>}
            <StepListItemLabel>{ds.name}</StepListItemLabel>
            <StepListItemMeta>
              <Badge status={ds?.status === 'healthy' ? 'success' : 'error'} />
              {/* <StepListStatus enabled={ds.enabled}>{ds.enabled ? 'ON' : 'OFF'}</StepListStatus> */}
            </StepListItemMeta>
          </StepListItem>
        );
      })}
    </>
  );
}

const DswcRoot = styled.div``;
const DswcActions = styled.div`
  margin-bottom: 12px;
`;

// this widget has been migrated to the backend for dashboard rendering.
// however, this component has been kept here for rendering in cxp
// @see {@link src/widgets/widget/widget-types/program/data-source-list.tsx}
function DataSourcesLegacyWidget(props: WidgetRendererProps) {
  const { configuration } = props;
  const clientId = configuration?.clientId ?? null;
  const { data, loading } = useQuery<DataSourcesWidgetQuery>(DATA_SOURCES_WIDGET, { variables: { clientId } });
  const dataSources = data?.dataSources ?? null;

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  if (!dataSources) {
    return <EmptyState>Could not load Data Sources at this time.</EmptyState>;
  }

  return (
    <NuCardContent style={{ minHeight: '300px' }}>
      <DswcRoot>
        <DswcActions>
          <Link to={`/${clientId}/cyberx/data-sources`}>
            <NuButton size="small">Manage</NuButton>
          </Link>
        </DswcActions>

        <DslRoot>
          <DataSourceList dataSources={dataSources} clientId={clientId} />
        </DslRoot>
      </DswcRoot>
    </NuCardContent>
  );
}

const def: WidgetDefinition = {
  name: 'Data Sources',
  slug: 'cxp-data-source-list',
  component: DataSourcesLegacyWidget,
  description: 'Data Sources for given client',
  getConfig: ({ context }) => {
    return {
      clientId: context?.clientId ?? null,
    };
  },
  product: 'cxp',
};

export default def;
