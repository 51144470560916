import { Fortisiem as FortisiemIcon } from 'components/nuspire/nu-icon';
import { IConnector } from 'components/connections/connectors';
import FortisiemRedirect from './fortisiem-redirect';
import { encodeOAuthState } from '../utils/oauth';

const FORTISIEM_CONNECTOR_DEFINITION: IConnector = {
  slug: 'fortisiem',
  name: 'FortiSIEM',
  description: 'Detect Unusual User and Entity Behavior',
  icon: <FortisiemIcon />,
  connectionTypes: ['action'],
  handleAddConnection: ({ clientId }) => {
    if (!clientId) {
      throw new Error('Client Id must be passed to connector');
    }
    const authState = encodeOAuthState({ clientId });

    window.open(
      `${window.location.origin}/redirects/connections/fortisiem/auth?state=${authState}`,
      'Fortisiem Auth',
      'height=1000,width=800',
    );
  },
  redirects: [{ path: 'auth', component: FortisiemRedirect }],
  widgets: [],
  updatableAuthFields: [
    { key: 'Username', value: 'username' },
    { key: 'Password', value: 'password' },
    { key: 'Organization', value: 'organization' },
    { key: 'Instance URL', value: 'baseURL' },
  ],
};

export default FORTISIEM_CONNECTOR_DEFINITION;
