import { Space } from 'antd';
import styled from 'styled-components';
import { DraggableBoxItem } from '../../../nuspire/sortable-list/draggable-box-item';
import { SortableList, SortableListItemProps } from '../../../nuspire/sortable-list/sortable-list';
import { LinkedMenuItem } from './get-main-menu-items';

const StyledSortableList = styled(SortableList)`
  padding: 5px 16px 0 0;
  margin-left: -8px;

  .ant-list-item {
    padding: 13px 16px 13px 26px;
    margin: 0;
    margin-block: 4px;
  }
`;

interface MainMenuReorderListViewProps {
  menuItems: NonNullable<LinkedMenuItem>[];
  onReordered?: (items: NonNullable<LinkedMenuItem>[]) => void;
}

export function MainMenuReorderListView(props: MainMenuReorderListViewProps) {
  // const menuItems = getRawMainMenuItems({ clientId: 'fake-client' }).filter(isLinkedMenuItem);
  const { menuItems } = props;

  const sortableListItems = menuItems.map(({ key, label, icon }): SortableListItemProps => ({
    id: key,
    render: (renderProps, ref) => (
      <DraggableBoxItem {...renderProps} ref={ref}>
        <Space>
          {icon}
          {label}
        </Space>
      </DraggableBoxItem>
    ),
  }));

  const onReordered = (reorderedItems: SortableListItemProps[]) => {
    props.onReordered?.(reorderedItems.map(({ id: sortId }) => menuItems.find(({ key }) => key === sortId)!));
  }

  return (
    <StyledSortableList items={sortableListItems} onReordered={onReordered} />
  );
}

