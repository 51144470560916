import { Cybereason as CybereasonIcon } from 'components/nuspire/nu-icon';
import { IConnector } from 'components/connections/connectors';
import CybereasonRedirect from './cybereason-redirect';
import { encodeOAuthState } from '../utils/oauth';

const CYBEREASON_CONNECTOR_DEFINITION: IConnector = {
  slug: `cybereason`,
  name: `Cybereason`,
  description: `Cybersecurity Software To End Cyber Attacks`,
  connectionTypes: [`action`],
  icon: <CybereasonIcon />,
  handleAddConnection: ({ clientId }) => {
    if (!clientId) {
      throw new Error('Client Id must be passed to connector');
    }

    const authState = encodeOAuthState({ clientId });

    window.open(
      `${window.location.origin}/redirects/connections/cybereason/auth?state=${authState}`,
      `Cybereason Auth`,
      `height=1000,width=800`,
    );
  },
  redirects: [{ path: `auth`, component: CybereasonRedirect }],
  widgets: [],
  updatableAuthFields: [
    { key: 'Username', value: 'username' },
    { key: 'Password', value: 'password' },
    { key: 'Instance URL', value: 'baseURL' },
  ],
};

export default CYBEREASON_CONNECTOR_DEFINITION;
