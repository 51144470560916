import { gql } from '@apollo/client';

export const GET_DOMAIN = gql`
  query Domain($domainId: String!) {
    domain(domainId: $domainId) {
      id
      name
      description
    }
  }
`;

export const GET_DOMAINS = gql`
  query Domains {
    domains {
      id
      name
      description
      controls {
        id
        name
        description
        capabilities {
          id
          name
          description
        }
      }
    }
  }
`;

export const GET_CONTROL = gql`
  query SecurityProgramControl($controlId: String!) {
    securityProgramControl(controlId: $controlId) {
      id
      domainId
      name
      description
    }
  }
`;

export const GET_CONTROLS = gql`
  query SecurityProgramControls {
    securityProgramControls {
      id
      name
      description
      domainId
      capabilities {
        name
        id
      }
    }
  }
`;

export const GET_CAPABILITY = gql`
  query Capability($capabilityId: String!) {
    capability(capabilityId: $capabilityId) {
      id
      controlId
      name
      description
    }
  }
`;

export const GET_CAPABILITIES = gql`
  query Capabilities {
    capabilities {
      id
      name
      description
      controlId
    }
  }
`;

export const GET_INDUSTRY_CONTROLS = gql`
  query IndustryControls($industryId: String!, $domainId: String) {
    industryControls(industryId: $industryId, domainId: $domainId) {
      industryId
      domainId
      controlId
      targetScore
      relativeCost
      relativeEffort
      domain {
        id
        name
      }
      control {
        id
        name
      }
    }
  }
`;

export const GET_CONTROLS_DATA_AND_SCORES = gql`
  query ControlsAndScores($clientId: String) {
    securityProgramControls {
      id
      name
      description
      domainId
    }

    controlScores(clientId: $clientId) {
      id
      clientId
      domainId
      controlId
      score
      isEnabled
    }
  }
`;

export const GET_INDUSTRY_DOMAINS_AND_SCORE = gql`
  query GetIndustryDomainsAndScore($industryId: String!, $domainId: String!, $clientId: String) {
    domainScore(domainId: $domainId, clientId: $clientId) {
      domainId
      score
      controlScores {
        controlId
        score
      }
    }

    industryControls(industryId: $industryId, domainId: $domainId) {
      industryId
      domainId
      controlId
      targetScore
      relativeCost
      relativeCost
    }
  }
`;

export const GET_ALL_CONTROL_DATA = gql`
  query GetAllSecurityProgramControlData($controlId: String!, $industryId: String!, $clientId: String) {
    securityProgramControl(controlId: $controlId) {
      id
      name
      description
      domainId
      capabilities {
        id
        name
        description
      }
    }
    controlScore(controlId: $controlId, clientId: $clientId) {
      controlId
      score
      isEnabled
    }
    industryControl(industryId: $industryId, controlId: $controlId) {
      industryId
      domainId
      controlId
      targetScore
      relativeCost
      relativeCost
    }
  }
`;

export const GET_ALL_CONTROLS_DATA = gql`
  query GetAllSecurityProgramControlsData($industryId: String!, $clientId: String) {
    securityProgramControls {
      id
      name
      description
      domainId
      capabilities {
        id
        name
        description
      }
    }
    controlScores(clientId: $clientId) {
      controlId
      score
      isEnabled
    }
    industryControls(industryId: $industryId) {
      industryId
      domainId
      controlId
      targetScore
      relativeCost
      relativeCost
    }
  }
`;

export const GET_ALL_DOMAIN_DATA = gql`
  query GetAllDomainData($clientId: String, $industryId: String!, $domainId: String) {
    domainScores(clientId: $clientId) {
      id
      clientId
      domainId
      score
      controlScores {
        id
        clientId
        controlId
        domainId
        score
        isEnabled
      }
    }

    industryControls(industryId: $industryId, domainId: $domainId) {
      industryId
      domainId
      controlId
      targetScore
      relativeCost
      relativeCost
    }

    domains {
      id
      name
      description
      controls {
        id
        name
        description
        capabilities {
          id
          name
          description
        }
      }
    }

    controlScores(clientId: $clientId) {
      controlId
      domainId
      score
      isEnabled
    }
  }
`;

export const GET_DOMAIN_DATA_AND_SCORE = gql`
  query GetDomainDataAndScore($domainId: String!, $clientId: String) {
    domainScore(domainId: $domainId, clientId: $clientId) {
      id
      clientId
      domainId
      score
      controlScores {
        id
        clientId
        controlId
        domainId
        score
        isEnabled
      }
    }

    domain(domainId: $domainId) {
      id
      name
      description
      controls {
        id
        domainId
        name
        description
        capabilities {
          id
          controlId
          name
          description
        }
      }
    }

    controlScores(domainId: $domainId, clientId: $clientId) {
      controlId
      domainId
      score
      isEnabled
    }
  }
`;
