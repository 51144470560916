import { useEffect, useRef } from 'react';
import styled, { useTheme, DefaultTheme, css } from 'styled-components';
import { useDashboardContext } from '../../../dashboard/dashboard-context';
import { WidgetComponentProps } from '../index';
import { Chart, ChartData } from 'chart.js';
import { Bar as BarChart, getElementAtEvent } from 'react-chartjs-2';
import { Link, useNavigate } from 'react-router-dom';
import { Space, Typography } from 'antd';
import { ColoredCircle } from '../../../shared-components';
import useElementSize from '../../../../utils/react-hooks/use-element-size';

/**
 * - custom: shows custom legend originally designed for cases by priority
 * - default: shows native legend from chart.js
 * - hide: no legend is shown
 */
export const LEGEND_MODE = ['custom', 'default', 'hide'] as const;
type LegendMode = (typeof LEGEND_MODE)[number];

type BarConfig = {
  legendMode?: LegendMode;

  /*
   * @default - none (all bars are nuspire blue)
   * @see {@link src/theme.ts#theme.palette}
   */
  palette?: keyof DefaultTheme['palette'];

  /**
   * adds time search params to bar & legend links
   * example: ?time=from:now-30d,to:now
   */
  timeSearchParams?: boolean;

  subAction?: {
    link: string;
    title: string;

    // @default - true
    prependClientId?: boolean;
  };
};

export type BarData = {
  bars: {
    label: string;
    link?: string;
  }[];
  datasets: ChartData<'bar', number[]>['datasets'];
};

function appendTimeSearchParams(path: string, time: string | undefined) {
  if (!time) return path;

  // must use fake base url here for the URL class to not error
  const url = new URL(path, 'http://fake.url');
  url.searchParams.append('time', time);

  return `${url.pathname}${url.search}${url.hash}`;
}

interface BarProps {
  showLegend?: boolean;
  timeSearchParams?: boolean;
  data: BarData;
  isReportWidget?: boolean;
}

export function Bar(props: BarProps) {
  const { showLegend = true, timeSearchParams = false, data, isReportWidget } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const { time } = useDashboardContext();
  const ref = useRef<Chart<'bar'>>(null);

  const onBarClick = (event: any) => {
    if (!ref.current) return;

    const dataset = getElementAtEvent(ref.current, event);
    const barElement = dataset[0];
    if (!barElement) return;
    const { index } = barElement;

    const path = data.bars[index].link;
    if (!path) return;

    if (timeSearchParams) {
      navigate(appendTimeSearchParams(path, time));
    } else {
      navigate(path);
    }
  };

  const barData: ChartData<'bar'> = {
    datasets: data.datasets,
    labels: data.bars.map(({ label }) => label),
  };

  return (
    <BarChart
      ref={ref}
      data={barData}
      onClick={onBarClick}
      options={{
        scales: {
          x: { stacked: true },
          y: { stacked: true },
        },
        plugins: {
          legend: {
            display: showLegend,
          },
        },
        maintainAspectRatio: false,
        backgroundColor: theme.color.nuspireBlue,
        animation: isReportWidget ? { duration: 0 } : undefined,
      }}
    />
  );
}

const FlexSpace = styled(Space)`
  display: flex;
  width: 100%;
`;

const ItemSpace = styled(FlexSpace)`
  justify-content: space-between;
  padding: 0 1.5rem;
  height: 100%;
`;

interface CustomBarLegendItemProps {
  label: string;
  count: number;
  color: string;
  link?: string;
}

export function CustomBarLegendItem(props: CustomBarLegendItemProps) {
  const { label, count, color, link } = props;

  const element = (
    <ItemSpace>
      <Space>
        <ColoredCircle color={color} />
        <Typography.Text>{label}</Typography.Text>
      </Space>
      <Typography.Text>{count}</Typography.Text>
    </ItemSpace>
  );

  if (!link) return element;

  return <Link to={link}>{element}</Link>;
}

const LegendList = styled(FlexSpace).attrs({})`
  flex: 0;
  justify-content: center;
  flex-wrap: wrap;
  > .ant-space-item {
    width: 48%;
    background-color: ${(p) => p.theme.token.colorFillAlter};
    height: 50px;
  }
`;

interface CustomBarLegendProps {
  palette?: keyof DefaultTheme['palette'];
  timeSearchParams?: boolean;
  data: BarData;
}

export function CustomBarLegend(props: CustomBarLegendProps) {
  const { palette, timeSearchParams, data } = props;
  const { time } = useDashboardContext();
  const theme = useTheme();

  const legendData: CustomBarLegendItemProps[] = data.bars.map((v, i) => {
    const { label } = v;
    let { link } = v;

    if (link && timeSearchParams) {
      link = appendTimeSearchParams(link, time);
    }

    const count = data.datasets.reduce((acc, curr) => acc + curr.data[i], 0);
    let color: string = theme.color.primary;
    if (palette) {
      color = theme.palette[palette]?.[i] ?? theme.color.primary;
    }

    return {
      count: Array.isArray(count) ? count.reduce((sum, curr) => sum + curr) : count,
      label,
      color,
      link,
    };
  });

  return (
    <LegendList>
      {legendData.map((v) => (
        <CustomBarLegendItem key={v.label} {...v} />
      ))}
    </LegendList>
  );
}

const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['sizeMode'].includes(prop),
})<{
  sizeMode: 'sm' | 'md';
}>`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 24px;
  align-items: stretch;

  padding-bottom: 8px;

  flex-direction: column;

  ${(p) =>
    p.sizeMode === 'md' &&
    css`
      flex-direction: row;
      ${LegendList} {
        flex: 0.4;
        flex-wrap: none;
        padding: 1.5rem 0;
        flex-direction: column;

        > .ant-space-item {
          width: 100%;
          height: 60px;
        }
      }
    `};
`;

export function BarWidget(props: WidgetComponentProps<BarConfig, BarData>) {
  const {
    data,
    setSubAction,
    configuration: { legendMode, palette, timeSearchParams, subAction } = {},
    clientId,
    isReportWidget,
  } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const { width } = useElementSize(wrapperRef);
  const sizeMode = width > 768 ? 'md' : 'sm';

  useEffect(() => {
    if (setSubAction && subAction) {
      const prependClientId = subAction.prependClientId ?? true;
      const link = `${prependClientId ? `/${clientId}` : ''}${subAction.link}`;

      setSubAction(
        <Link to={link} style={{ marginRight: '1rem' }}>
          {subAction.title}
        </Link>,
      );
    }
  }, []);

  return (
    <Wrapper ref={wrapperRef} sizeMode={sizeMode}>
      <div style={{ flex: 1, position: 'relative', minWidth: 0, minHeight: 0 }}>
        <Bar
          data={data}
          timeSearchParams={timeSearchParams}
          showLegend={legendMode === 'default'}
          isReportWidget={isReportWidget}
        />
      </div>
      {legendMode === 'custom' && <CustomBarLegend data={data} palette={palette} timeSearchParams={timeSearchParams} />}
    </Wrapper>
  );
}
