
export default () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 192.000000 192.000000"
    fill="currentColor"
  >
    <g transform="translate(0.000000,192.000000) scale(0.100000,-0.100000)">
      <path
        fill="currentColor"
        d="M923 1777 c-110 -92 -300 -149 -557 -167 -95 -7 -134 -14 -145 -25 -21 -21 -27 -231 -12 -393 31 -325 97 -567 203 -746 47 -82 149 -215 202 -265 l36 -35 0 107 c0 202 40 303 199 501 53 66 56 71 59 137 3 38 8 69 12 69 4 0 13 -20 20 -45 10 -34 11 -81 5 -183 -5 -75 -9 -189 -9 -252 -1 -104 1 -120 24 -165 15 -29 43 -63 68 -82 50 -38 76 -44 44 -10 -11 12 -33 49 -48 82 -24 51 -28 72 -27 150 1 151 36 230 128 287 42 25 48 23 29 -14 -25 -49 -31 -161 -10 -193 22 -33 61 -49 88 -34 44 23 62 127 34 194 -8 19 -37 63 -65 97 -142 175 -175 350 -98 512 19 39 44 82 56 96 l22 25 -6 -30 c-37 -189 -34 -207 38 -279 29 -28 93 -78 142 -111 50 -33 114 -83 143 -112 54 -53 111 -156 113 -203 1 -36 35 73 59 190 33 160 52 348 54 510 1 198 -2 206 -101 214 -268 23 -378 45 -503 103 -41 18 -92 47 -112 63 -44 36 -49 36 -85 7z"
      />
    </g>
  </svg>
);
