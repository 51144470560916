import { Chart as ChartJS, TimeScale, CategoryScale, LinearScale, BarElement, ArcElement, LineElement, PointElement, Legend, Tooltip, Title } from 'chart.js';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';

// https://react-chartjs-2.js.org/docs/migration-to-v4#tree-shaking
ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  LineElement,
  PointElement,
  Legend,
  Title,
  Tooltip,
);

