import { theme } from 'antd';
import { MappingAlgorithm } from 'antd/es/theme/interface';
import { darkModeTheme } from './theme';

/**
 * antd's dark algorithm does not include our brand color for some reason.
 * This overrides the generated `colorPrimary` with our brand color (#3b87e6)
 *
 * @see {@link https://github.com/ant-design/ant-design/issues/44006}
 */
export const darkAlgorithmShim: MappingAlgorithm = (designToken, derivativeToken) => ({
  ...theme.darkAlgorithm(designToken, derivativeToken),
  colorPrimary: darkModeTheme.color.primary,
  colorLink: darkModeTheme.color.primary,
});

