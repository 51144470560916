import { gql } from '@apollo/client';

export const APP_NOTIFICATION_ENTITY_FIELDS = gql`
  fragment AppNotificationEntityFields on AppNotificationEntity {
    id
    title
    status {
      label
      value
    }
    link {
      url
    }
    # icon
  }
`;

export const USER_NOTIFICATION_FIELDS = gql`
  ${APP_NOTIFICATION_ENTITY_FIELDS}

  fragment UserNotificationFields on UserNotification {
    id
    userId
    clientId
    notificationType
    timestamp
    title
    entityNotificationId
    entity {
      ...AppNotificationEntityFields
    }
  }
`;

export const ENTITY_NOTIFICATION_FIELDS = gql`
  ${USER_NOTIFICATION_FIELDS}

  fragment EntityNotificationFields on EntityNotification {
    id
    userId
    typeNotificationId
    notifications {
      ...UserNotificationFields
    }
    timestamp
    count
    otherType
    typeNotificationId
  }
`;

export const TYPE_NOTIFICATION_FIELDS = gql`
  ${ENTITY_NOTIFICATION_FIELDS}

  fragment TypeNotificationFields on TypeNotification {
    id
    userId
    pluralTitle
    notificationType
    timestamp
    count
    entityNotifications {
      ...EntityNotificationFields
    }
  }
`;
