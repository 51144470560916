import { UnlockOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { message } from 'antd';
import { useAuthContext } from 'components/auth-context';
import MutationButton from 'components/mutation-button';
import { UserActionButtonProps } from 'types';
import { formatUserName } from 'utils/users';

const UNLOCK_USER_MUTATION = gql`
  mutation UnlockUserAccount($clientId: String, $userId: String!) {
    unlockUserAccount(clientId: $clientId, userId: $userId)
  }
`;

type UnlockUserData = {
  unlockUserAccount?: boolean;
};

export default function UnlockUserButton(props: UserActionButtonProps) {
  const { onSuccess, user, ...buttonProps } = props;
  const { user: authUser } = useAuthContext();

  const userName = formatUserName(user);
  const { id: userId } = user;
  const variables = {
    userId,
  };
  const modalTitle = `Are you sure you want to unlock ${userName}?`;

  const onCompleted = (data: UnlockUserData) => {
    if (data.unlockUserAccount !== undefined) {
      const { unlockUserAccount } = data;

      // Unlock was unsuccessful.
      if (!unlockUserAccount) {
        onError();
        return;
      }

      // Unlock was successful.
      message.success(`${userName} has been unlocked!`);
      if (onSuccess) onSuccess();
    }
  };

  const onError = (error?: any) => {
    let errorMessage = `There was an error unlocking ${userName} account.`;
    if (authUser?.isSuperUser) {
      errorMessage = `${errorMessage}${error ? ` Error: ${error.message}` : ``}`;
    }

    message.error(errorMessage);
  };

  return (
    <MutationButton
      buttonIcon={<UnlockOutlined />}
      modalTitle={modalTitle}
      mutation={UNLOCK_USER_MUTATION}
      mutationVariables={variables}
      onCompleted={onCompleted}
      onError={onError}
      tooltipOverlay="Unlock User"
      {...buttonProps}
    />
  );
}
