import { SentinelOne as SentinelOneIcon } from 'components/nuspire/nu-icon';
import { IConnector } from 'components/connections/connectors';
import SentinelOneRedirect, { RegenerateWarningAlert } from './sentinelone-redirect';
import SentinelOneSecuredDevicesByOSCountWidget from './widgets/sentinelone-secured-devices-by-os-count-widget';
import SentinelOneThreatsByTypeCountWidget from './widgets/sentinelone-threats-by-type-count-widget';
import SentinelOneUnpatchedDeviceCountWidget from './widgets/sentinelone-unpatched-device-count-widget';
import SentinelOneInfectedEndpointsCountWidget from './widgets/sentinelone-infected-endpoints-count-widget';
import SentinelOnePotentiallyVulnerableApplicationsCountWidget from './widgets/sentinelone-potentially-vulnerable-applications-count-widget';
import SentinelOneUnresolvedThreatsCountWidget from './widgets/sentinelone-unresolved-threats-count-widget';
import { encodeOAuthState } from '../utils/oauth';

const SENTINELONE_CONNECTOR_DEFINITION: IConnector = {
  slug: 'sentinelone',
  name: 'SentinelOne',
  description: 'Autonomous AI Endpoint Security Platform',
  connectionTypes: ['action'],
  icon: <SentinelOneIcon />,
  handleAddConnection: ({ clientId }) => {
    if (!clientId) {
      throw new Error('Client Id must be passed to connector');
    }

    const authState = encodeOAuthState({ clientId });

    window.open(
      `${window.location.origin}/redirects/connections/sentinelone/auth?state=${authState}`,
      'SentinelOne Auth',
      'height=1000,width=800',
    );
  },
  redirects: [{ path: 'auth', component: SentinelOneRedirect }],
  widgets: [
    SentinelOneInfectedEndpointsCountWidget,
    SentinelOnePotentiallyVulnerableApplicationsCountWidget,
    SentinelOneSecuredDevicesByOSCountWidget,
    SentinelOneThreatsByTypeCountWidget,
    SentinelOneUnpatchedDeviceCountWidget,
    SentinelOneUnresolvedThreatsCountWidget,
  ],
  updatableAuthFields: [
    { inputType: 'string', key: 'API Token', value: 'apiToken' },
    { inputType: 'string', key: 'Instance URL', value: 'baseURL' },
    {
      alertWhenChecked: <RegenerateWarningAlert style={{ marginBottom: '0.5rem' }} />,
      inputType: 'checkbox',
      key: 'Automatically regenerate API token before it expires',
      required: false,
      value: 'regenerateAPIToken',
    },
  ],
};

export default SENTINELONE_CONNECTOR_DEFINITION;
