import { createContext, useContext, useState, useEffect, useMemo } from 'react';
import useSearchParams from '../../utils/react-hooks/useSearchParams';
import { parseTimeString } from '../nuspire/nu-time-range-button/time';
import Spin, { SpinContainer } from '../nuspire/spin';
import { IDashboard } from './dashboard-details';

/**
 * Context for Time Filtering stuff...
 */
export type IDashboardContext = {
  time?: string;
  from?: string;
  to?: string;
  fromIso?: string;
  toIso?: string;
  onTimeChange: (time: string | null) => void;
};

export const DashboardContext = createContext<IDashboardContext>({
  time: undefined,

  onTimeChange: () => {}, // set default, this gets a new func in the Provider
});

export function useDashboardContext(): IDashboardContext {
  return useContext(DashboardContext);
}

export enum SearchParam {
  time = 'time',
}

export function DashboardContextProvider(props: { dashboard: IDashboard; children: React.ReactNode }) {
  const { children, dashboard } = props;
  const searchParams = useSearchParams([
    { key: SearchParam.time, defaultValue: dashboard.timeRangeEnabled ? 'from:now-30d,to:now' : undefined },
  ]);
  const { initializing, parsed, setParameter } = searchParams;
  const timeString = parsed?.time;

  const [time, setTime] = useState<{
    from?: string;
    to?: string;
    fromIso?: string;
    toIso?: string;
  }>({});

  /**
   * This should ensure we don't retrigger all widget queries any time there is a render cycle.
   */
  useEffect(() => {
    const time = parseTimeString({ time: timeString });

    setTime(time);
  }, [timeString]);

  // Handle Time Change
  const handleTimeChange = (time: string | null) => {
    setParameter(SearchParam.time, time);
  };

  const context: IDashboardContext = useMemo(
    () => ({
      time: timeString,
      ...time,
      onTimeChange: handleTimeChange,
    }),
    [time],
  );

  if (initializing) {
    return (
      <SpinContainer>
        <Spin tip="Initializing Time...">
          <div className="content" />
        </Spin>
      </SpinContainer>
    );
  }

  return <DashboardContext.Provider value={context}>{children}</DashboardContext.Provider>;
}
