import { gql, useQuery } from '@apollo/client';
import ClientDetailsForm from 'components/admin/clients/client-details-form';
import ClientDetailsInfo from 'components/admin/clients/client-details-info';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';
import { IClient, IUser } from '../../../types';
import { GetClientForAdminQuery, GetClientForAdminQueryVariables } from '../../../types/graph-codegen/graph-types';
import { BreadcrumbBar } from '../../bread-crumb-bar';
import { Content } from '../../layouts/content';

const GET_CLIENT = gql`
  query GetClientForAdmin($id: String) {
    globalGetClientById(id: $id) {
      id
      createdAt
      name
      serviceNowAccount {
        number
        name
        sys_id
        u_client_category
        u_parent_escalation_contacts
        u_parent_passphrase
      }
      oktaGroupId
      ownerId
      apiKeyId
      parentId
      parent {
        name
      }
      industry {
        id
        name
      }
      partnerDetails {
        additionalInfo
        businessCountry
        businessEmail
        businessPhone
        businessPhoneExt
        businessSalesRepresentative
        businessType
        businessWebsite
        isApproved
        partnerType
      }
      deletedAt
      clientIdentifiers {
        type
        value
      }
      isMultiTenancyEnabled
      type
      mlOptions {
        anomalyThreshold
      }
    }
    globalGetUsersByClientId(id: $id) {
      id
      clientId
      email
      firstName
      lastName
      persona
      customTags
      isSuperUser
      deletedAt
      status
      managedClientIds
    }
    globalExternalUsersByClientId(id: $id) {
      id
      clientId
      email
      firstName
      lastName
      persona
      customTags
      isSuperUser
      deletedAt
      status
      managedClientIds
    }
    globalUserGroups(clientId: $id) {
      id
      clientId
      createdAt
      description
      isHidden
      isSystemControlled
      name
      updatedAt
      members {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export type ClientDetailsProps = {
  client?: IClient;
  clientId: string;
  groups?: GetClientForAdminQuery['globalUserGroups'];
  loading: boolean;
  refetch: () => void;
  tabKeysToShow?: string[];
  users?: IUser[];
};

export const ClientDetails = (props: ClientDetailsProps) => {
  // sent from clients table to persist search state through the breadcrumb bar
  const [isEditing, setIsEditing] = useState(false);

  if (isEditing && props.client) {
    return <ClientDetailsForm {...props} client={props.client} setIsEditing={setIsEditing} />;
  }

  return <ClientDetailsInfo {...props} refetchUsers={props.refetch} setIsEditing={setIsEditing} />;
};

export function AdminClientDetailsView() {
  const location = useLocation();
  const previousSearchValue: string | undefined = location?.state?.search;
  const { clientId } = useParams<{ clientId: string }>();
  const { data, error, loading, refetch } = useQuery<GetClientForAdminQuery, GetClientForAdminQueryVariables>(
    GET_CLIENT,
    {
      variables: {
        id: clientId,
      },
      skip: !clientId,
    },
  );
  const client: IClient | undefined = data?.globalGetClientById as IClient | undefined;

  const { globalGetUsersByClientId = [], globalExternalUsersByClientId = [] } = data ?? {};
  const users = [...globalGetUsersByClientId, ...globalExternalUsersByClientId].sort(
    (a, b) => a?.id.localeCompare(b?.id ?? '') ?? 0,
  ) as IUser[];

  const breadCrumbBar = (
    <BreadcrumbBar
      items={[
        { text: 'Home', to: '/' },
        { text: 'Admin', to: '/admin' },
        {
          text: 'Clients',
          to: previousSearchValue ? `/admin/clients?search=${previousSearchValue}` : '/admin/clients',
        },
        { text: client ? `${client!.name}` : 'Client Details', to: `/admin/clients/${client?.id}` },
      ]}
      isLoading={loading}
      error={error}
    />
  );

  return (
    <Content>
      <Helmet title={client?.name || `Client Details`} />
      {breadCrumbBar}
      <ClientDetails
        client={client}
        clientId={clientId ?? ''}
        groups={data?.globalUserGroups}
        loading={loading}
        refetch={() => refetch({ id: clientId })}
        users={users}
      />
    </Content>
  );
}
