import { gql, useQuery } from '@apollo/client';
import { Flex, Space, Tooltip, Typography } from 'antd';
import { NuButton, NuCard, NuCardContent, NuCardTitle } from 'components/nuspire';
import { NuEmptyState } from 'components/nuspire/nu-empty-state/nu-empty-state.stories';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Unpacked } from 'types';
import { ThreatBriefsCardQuery } from 'types/graph-codegen/graph-types';
import { TypographyLink } from '../../typography-link';

export const THREAT_BRIEFS_CARD_QUERY = gql`
  query ThreatBriefsCard(
    $limit: Int
    $skip: Int
    $sortBy: String
    $sortOrder: String
    $search: String
    $startDate: String
    $endDate: String
    $targetEmailTemplateIds: [String]!
  ) {
    serviceNowActivePublications(
      limit: $limit
      skip: $skip
      sortBy: $sortBy
      sortOrder: $sortOrder
      search: $search
      startDate: $startDate
      endDate: $endDate
      targetEmailTemplateIds: $targetEmailTemplateIds
    ) {
      activePublications {
        number
        publish_date
        target_email_template {
          value
        }
        short_description
        text
      }
      total
    }
  }
`;

const securityAlertTemplates = {
  critical: {
    id: 'b55894da87b50d10386d2f49cebb354e',
  },
  high: {
    id: '8e8868d287390d10386d2f49cebb3507',
  },
  informational: {
    id: '41e5893187593810386d2f49cebb35df',
  },
  moderate: {
    id: '4018ac9287390d10386d2f49cebb35af',
  },
} as const;

const allSecurityAlertTemplateIds = Object.values(securityAlertTemplates).map((item) => item.id);

type ActivePublication = NonNullable<
  Unpacked<NonNullable<NonNullable<ThreatBriefsCardQuery['serviceNowActivePublications']>['activePublications']>>
>;

function ActivePublicationListItem(props: { clientId: string; activePublication: ActivePublication }) {
  const { clientId, activePublication } = props;
  const { number, short_description, publish_date } = activePublication;

  return (
    <Flex vertical>
      <TypographyLink to={`/${clientId}/threats/threat-brief/${number}`}>{short_description}</TypographyLink>
      <Flex>
        <Typography.Text
          style={{ fontSize: '12px', marginRight: '4px', display: 'block' }}
        >{`${number} ••  `}</Typography.Text>
        <Tooltip title={dayjs.utc(publish_date).local().format('MMMM Do YYYY, h:mm:ss a')}>
          <Typography.Text style={{ fontSize: '12px' }}>
            Published {dayjs.utc(publish_date).local().fromNow()}
          </Typography.Text>
        </Tooltip>
      </Flex>
    </Flex>
  );
}

function ThreatBriefsCardContent(props: { clientId: string }) {
  const { clientId } = props;

  const { data, loading } = useQuery<ThreatBriefsCardQuery>(THREAT_BRIEFS_CARD_QUERY, {
    variables: {
      limit: 10,
      skip: 0,
      sortOrder: 'desc',
      sortBy: 'publish_date',
      targetEmailTemplateIds: allSecurityAlertTemplateIds,
    },
  });

  const activePublications = data?.serviceNowActivePublications?.activePublications;

  if (activePublications) {
    return (
      <Space direction="vertical">
        {activePublications.map((pub) => {
          if (pub) {
            return <ActivePublicationListItem key={pub.number} clientId={clientId} activePublication={pub} />;
          }

          return null;
        })}
      </Space>
    );
  }

  // loading state
  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return <NuEmptyState>No Threat Briefs could be found.</NuEmptyState>;
}

export default function ThreatBriefsCard(props: { clientId: string }) {
  const { clientId } = props;

  return (
    <NuCard fullHeight>
      <NuCardTitle
        title="Recent Threat Briefs"
        actions={
          <Link to={`/${clientId}/threats/threat-brief`}>
            <NuButton type="link">View All</NuButton>
          </Link>
        }
      />
      <NuCardContent>
        <ThreatBriefsCardContent {...props} />
      </NuCardContent>
    </NuCard>
  );
}
