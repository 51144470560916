import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { gql, useSubscription } from '@apollo/client';
import { Checkbox, Descriptions, Table, Tabs, type TableColumnsType } from 'antd';
import { useEffect, useState, type FC } from 'react';
import styled, { useTheme } from 'styled-components';
import type {
  QueryDataTypeDebugPayload,
  QueryDataTypeDebugSubscription,
  QueryDataTypeDebugSubscriptionVariables,
} from '../../types/graph-codegen/graph-types';
import { NuButton } from '../nuspire';
import { CopyToClipboardIcon } from '../shared-components';
import { useDataExplorerContext } from './data-explorer';

const ActionsRow = styled.div`
  margin-bottom: 1rem;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const DATA_EXPLORER_DEBUG_SUBSCRIPTION = gql`
  subscription QueryDataTypeDebug($requestId: String!) {
    queryDataTypeDebug(requestId: $requestId) {
      request {
        body
        headers
        method
        origin
        pathname
        search
      }
      requestHash
      requestId
      response {
        body
        headers
        statusCode
      }
    }
  }
`;

const renderHeaders = (headers: Record<string, any>): JSX.Element => (
  <>
    {Object.entries(headers).map(([key, value]) => (
      <p key={`${key}:${value}`}>
        <strong>{key}:</strong> {value}
      </p>
    ))}
  </>
);

const renderBody = (body: string): string => {
  try {
    const parsed = JSON.parse(body);
    return JSON.stringify(parsed, null, 2);
  } catch (err) {
    //
  }

  return body;
};

const RequestInfo: FC<{ request: QueryDataTypeDebugPayload['request'] }> = (props) => {
  const Headers = props.request?.headers ? renderHeaders(props.request.headers) : undefined;
  const Body = props.request?.body ? <code>{renderBody(props.request.body)}</code> : undefined;

  return (
    <Descriptions
      bordered
      items={[
        {
          key: 'search',
          label: (
            <>
              <span style={{ marginRight: '0.25rem' }}>Search</span>{' '}
              <CopyToClipboardIcon copyText={props.request?.search ?? ''} />
            </>
          ),
          span: 3,
          children: props.request?.search,
        },
        {
          key: 'headers',
          label: 'Headers',
          span: 3,
          children: Headers,
        },
        {
          key: 'body',
          label: (
            <>
              <span style={{ marginRight: '0.25rem' }}>Body</span>
              <CopyToClipboardIcon copyText={props.request?.body ?? ''} />
            </>
          ),
          span: 3,
          children: Body,
        },
      ]}
    />
  );
};

const ResponseInfo: FC<{ response: QueryDataTypeDebugPayload['response'] }> = (props) => {
  const Headers = props.response?.headers ? renderHeaders(props.response.headers) : undefined;
  const Body = props.response?.body ? <code>{renderBody(props.response.body)}</code> : undefined;

  return (
    <Descriptions
      bordered
      items={[
        {
          key: 'headers',
          label: 'Headers',
          span: 3,
          children: Headers,
        },
        {
          key: 'body',
          label: (
            <>
              <span style={{ marginRight: '0.25rem' }}>Body</span>
              <CopyToClipboardIcon copyText={props.response?.body ?? ''} />
            </>
          ),
          span: 3,
          children: Body,
        },
      ]}
    />
  );
};

export const DataExplorerDebugView: FC<{ debugRequestId: string }> = (props) => {
  const theme = useTheme();
  const { data } = useSubscription<QueryDataTypeDebugSubscription, QueryDataTypeDebugSubscriptionVariables>(
    DATA_EXPLORER_DEBUG_SUBSCRIPTION,
    {
      variables: {
        requestId: props.debugRequestId,
      },
    },
  );
  const dataExplorerContext = useDataExplorerContext();
  const [debugItems, setDebugItems] = useState<QueryDataTypeDebugPayload[]>([]);
  const [clearOnSearch, setClearOnSearch] = useState(true);

  const clearDebugItems = (): void => {
    setDebugItems([]);
  };

  useEffect((): void => {
    if (!data?.queryDataTypeDebug) {
      return;
    }

    const existingItemIdx = debugItems.findIndex((i) => i.requestHash === data.queryDataTypeDebug?.requestHash);
    if (existingItemIdx >= 0) {
      // Update
      const existing = debugItems[existingItemIdx];

      const updatedItems: QueryDataTypeDebugPayload[] = debugItems.map((item, idx): QueryDataTypeDebugPayload => {
        if (idx === existingItemIdx) {
          return {
            request: data.queryDataTypeDebug?.request ?? existing.request,
            requestHash: existing.requestHash,
            requestId: existing.requestId,
            response: data.queryDataTypeDebug?.response ?? existing.response,
          };
        }

        return item;
      });

      setDebugItems(updatedItems);
    } else {
      // Insert new
      const newItem: QueryDataTypeDebugPayload = {
        request: data.queryDataTypeDebug.request,
        requestHash: data.queryDataTypeDebug.requestHash,
        requestId: data.queryDataTypeDebug.requestId,
        response: data.queryDataTypeDebug.response,
      };

      setDebugItems((prevItems) => [...prevItems, newItem]);
    }
  }, [data?.queryDataTypeDebug]);

  useEffect((): void => {
    if (dataExplorerContext?.loading && clearOnSearch) {
      clearDebugItems();
    }
  }, [dataExplorerContext?.loading]);

  const expandedRowRender = (record: QueryDataTypeDebugPayload) => {
    return (
      <Tabs
        defaultActiveKey="request"
        items={[
          {
            key: 'request',
            label: 'Request',
            children: <RequestInfo request={record.request} />,
          },
          {
            key: 'response',
            label: 'Response',
            children: <ResponseInfo response={record.response} />,
          },
        ]}
      />
    );
  };

  const columns: TableColumnsType<QueryDataTypeDebugPayload> = [
    {
      key: 'request',
      dataIndex: 'request',
      title: 'Request',
      render(request?: QueryDataTypeDebugPayload['request']) {
        if (!request?.origin) {
          return 'Unknown';
        }

        const url = new URL(`${request.origin}${request.pathname}`);

        return (
          <span>
            <strong>{request.method}</strong> {url.toString()}
          </span>
        );
      },
    },
    {
      key: 'response',
      dataIndex: 'response',
      title: 'Response',
      render(response?: QueryDataTypeDebugPayload['response']) {
        if (!response?.statusCode) {
          return 'Unknown';
        }

        if (response.statusCode >= 400) {
          return (
            <>
              <CloseCircleOutlined style={{ color: theme.color.error }} />
              <span style={{ marginLeft: '0.25rem' }}>{response.statusCode}</span>
            </>
          );
        }

        return (
          <>
            <CheckCircleOutlined style={{ color: theme.color.success }} />
            <span style={{ marginLeft: '0.25rem' }}>{response.statusCode}</span>
          </>
        );
      },
    },
  ];

  return (
    <>
      <ActionsRow>
        <NuButton icon={<DeleteOutlined />} onClick={clearDebugItems}>
          Clear
        </NuButton>
        <Checkbox
          checked={clearOnSearch}
          onChange={(): void => {
            setClearOnSearch(!clearOnSearch);
          }}
        >
          Clear on Search
        </Checkbox>
      </ActionsRow>
      <Table
        rowKey="requestHash"
        columns={columns}
        expandable={{ expandedRowRender, expandRowByClick: true }}
        dataSource={debugItems}
        pagination={false}
      />
    </>
  );
};
