import React from 'react';
import { ButtonProps } from 'antd/es/button';
import { Button } from 'antd';
import styled from 'styled-components';

/**
 * NuButton
 */
export interface NuButtonProps extends ButtonProps {
  iconRight?: React.ReactNode;
  theme?: object;
}

const IconRight = styled.span`
  display: inline-block;
  margin-left: 8px;
`;

/**
 * Testing Ids
 */
export const ROOT_TEST_ID = 'nu-button';

/**
 * Wrapper around antd button.
 *
 * https://ant.design/components/button/
 */
function NuButton(props: NuButtonProps) {
  const { children, iconRight, ...rest } = props;

  return (
    <Button data-testid={ROOT_TEST_ID} {...rest}>
      {children}
      {iconRight && <IconRight>{iconRight}</IconRight>}
    </Button>
  );
}

export default NuButton;
