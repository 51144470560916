import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { WidgetDefinition, WidgetRendererProps } from 'components/dashboard/widget-definitions/types';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { Doughnut } from 'components/nuspire/charts/doughnut-chart';
import { ALERTS_BY_SEVERITY_QUERY } from './alerts-by-severity';
import WidgetFilter, { timeRange } from '../widget-filter';
import { EmptyState, NuCardContent } from 'components/nuspire';

const labels = ['Anomaly', 'Audit Event', 'Network', 'Config', 'Data', 'IAM'];

function AlertCoverageWidgetComponent(props: WidgetRendererProps) {
  const [timeFilter, setTimeFilter] = useState(2);
  const [statusFilter, setStatusFilter] = useState('open');
  const connectionId = props?.configuration?.connectionId ?? null;
  const { data, loading } = useQuery(ALERTS_BY_SEVERITY_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      connectionId,
      input: {
        filters: [
          {
            name: 'alert.status',
            operator: '=',
            value: statusFilter,
          },
        ],
        timeRange: {
          type: 'relative',
          value: {
            amount: timeRange[timeFilter].value,
            unit: timeRange[timeFilter].unit,
          },
        },
      },
    },
  });
  const alerts = data?.clientConnection?.alertCountsByPolicy;

  const doughnutData = {
    labels,
    datasets: [
      {
        data: labels.map(
          (label) =>
            alerts
              ?.filter((a) => a?.policy?.policyType === label.toLowerCase())
              ?.reduce((acc, cur) => acc + cur?.alertCount, 0) ?? 0,
        ),
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
      },
    ],
  };

  if (!alerts && !loading) {
    return (
      <NuCardContent>
        <EmptyState>Unable to fetch Alerts.</EmptyState>
      </NuCardContent>
    );
  }

  return (
    <NuCardContent>
      <WidgetFilter
        timeFilter={timeFilter}
        setTimeFilter={setTimeFilter}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
      />
      {loading ? (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      ) : (
        <div style={{ display: 'flex' }}>
          <div style={{ width: '400px', margin: 'auto' }}>
            <Doughnut
              data={doughnutData}
              centerContent={(
                <h1>{`Total: ${alerts?.reduce((acc, cur) => acc + cur.alertCount, 0)}`}</h1>
              )}
            />
          </div>
        </div>
      )}
    </NuCardContent>
  );
}

const AlertCoverageWidget: WidgetDefinition = {
  name: 'Prisma Cloud Alert Coverage',
  slug: 'prisma-cloud-alert-coverage',
  connectorSlug: 'prisma-cloud',
  component: AlertCoverageWidgetComponent,
  description: 'Prisma Cloud Alert coverage',
};

export default AlertCoverageWidget;
