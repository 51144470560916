import { gql } from '@apollo/client';
import { ButtonGroupProps } from 'antd/es/button';
import { SyncOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { IUser } from 'types';
import { formatUserName } from 'utils/users';
import MutationButton from 'components/mutation-button';
import { P } from 'components/shared-components';

export const SYNC_USER_PROFILE_MUTATION = gql`
  mutation SyncUserProfile($userId: String) {
    syncUserProfile(userId: $userId) {
      id
      mobilePhoneNumber
      mobilePhoneNumberCountry
      phoneNumber
      phoneNumberCountry
      phoneNumberExt
      title
    }
  }
`;

type SyncUserProfileButtonProps = ButtonGroupProps & {
  onSuccess?: () => void;
  user: IUser;
};

export default function SyncUserProfileButton(props: SyncUserProfileButtonProps) {
  const { onSuccess, user, ...buttonProps } = props;

  const userName = formatUserName(user);
  const { id: userId } = user;
  const variables = {
    userId,
  };
  const modalTitle = <P>Are you sure you want to sync {userName}&apos;s profile?</P>;

  const onCompleted = () => {
    message.success(`${userName}'s profile has been synced!`);
    onSuccess?.();
  };

  const onError = (error?: any) => {
    const errorMessage = `There was an error syncing ${userName}'s profile. ${error ? `Error: ${error.message}` : '.'}`;

    message.error(errorMessage);
  };

  return (
    <MutationButton
      buttonIcon={<SyncOutlined />}
      modalTitle={modalTitle}
      modalContent="This action cannot be undone."
      mutation={SYNC_USER_PROFILE_MUTATION}
      mutationVariables={variables}
      onCompleted={onCompleted}
      onError={onError}
      tooltipOverlay="Sync User's Profile"
      buttonType="default"
      {...(buttonProps as any)}
    />
  );
}
