import { ApolloClient, ApolloLink, FetchPolicy, InMemoryCache, ServerError, from, split } from '@apollo/client';
import { NetworkError } from '@apollo/client/errors';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { SentryLink } from 'apollo-link-sentry';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { config } from 'config';
import fetch from 'cross-fetch';
import { createClient } from 'graphql-ws';
import omitDeep from 'omit-deep-lodash';
import { getAccessToken } from 'utils/access-tokens';
import { oktaAuth } from 'utils/okta';
import { REDIRECT_TO_KEY } from '../../localstorage';
import { resolvers, typeDefs } from './client-resolvers';

const isServerError = (error?: NetworkError): error is ServerError => error?.name.toLowerCase() === 'servererror';

const authLink = setContext(async () => {
  try {
    const accessToken = await getAccessToken();

    return {
      headers: {
        authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    };
  } catch (e) {
    return {};
  }
});

const errorHandler = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    if (
      window.location.pathname !== '/login' &&
      isServerError(networkError) &&
      (networkError.response.status === 401 ||
        (networkError.result as Record<string, any>)?.message?.toLowerCase() === 'unauthorized')
    ) {
      window.localStorage.setItem(REDIRECT_TO_KEY, window.location.pathname);
      window.location.replace('/login');
      return;
    }

    console.error(`GraphQL network error`, JSON.stringify(networkError, null, 2));
  }

  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(`GraphQL error: message: ${message}, location: ${locations}, path: ${path}`),
    );
  }
});

const typenameRemover = new ApolloLink((operation, forward) => {
  /*
   * Why do we need this? https://sd-at-nuspire.atlassian.net/browse/MYN-2562
   * Graph mutations will fail with validation errors (400s) if we do not remove the `__typename` field.
   *
   * Previously we were using JSON.parse(JSON.stringify(....)) to
   * remove `__typename` but that fails to retain File & Blob objects
   * since they can't be serialized. Switching over to omit-deep-lodash.
   */
  operation.variables = omitDeep(operation.variables, '__typename');
  return forward(operation);
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: config.graphPubSubUrl,
    connectionParams: async () => {
      const accessToken = await getAccessToken();

      return {
        authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      };
    },
  }),
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },

  wsLink, // subscription link?

  typenameRemover, // query and mutation link?
);

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: import.meta.env.DEV,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network' as FetchPolicy,
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'cache-and-network' as FetchPolicy,
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  link: errorHandler.concat(
    from([
      authLink,
      splitLink,
      new SentryLink(/* See options */),
      createUploadLink({
        uri: config.graphUrl,
        credentials: 'omit',
        fetch,
      }),
    ]),
  ),
  typeDefs,
  resolvers,
});

export async function logout() {
  await oktaAuth.signOut();
}

export default client;
