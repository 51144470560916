import { LinkOutlined } from '@ant-design/icons';
import { gql, useQuery } from '@apollo/client';
import { Table } from 'antd';
import { useClientContext } from 'components/client-context-provider';
import { EmptyState, NuButton } from 'components/nuspire';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import PageSection from 'components/page-section';
import { ClientType } from 'types';
import { isDefined } from 'utils/is-defined';
import { oktaAuth } from 'utils/okta';

const ENTITLEMENTS_SECTION = gql`
  query EntitlementsSection($clientId: String!) {
    entitlements(clientId: $clientId) {
      active
      end_date
      entitlement_name
      start_date
      sys_id
      u_service_description
      u_url
    }
  }
`;

export function EntitlementsTable(props: { clientId: string }) {
  const { clientId } = props;
  const { data, loading } = useQuery(ENTITLEMENTS_SECTION, { variables: { clientId } });

  const entitlements = data?.entitlements?.filter((entitlement) => {
    if (!isDefined(entitlement)) return false;

    if (!entitlement.entitlement_name || !entitlement.u_url) return false;

    return true;
  });

  if (entitlements?.length) {
    return (
      <Table
        rowKey="sys_id"
        dataSource={entitlements}
        pagination={false}
        columns={[
          {
            key: 'entitlement_name',
            dataIndex: 'entitlement_name',
            title: 'Application',
          },
          {
            key: 'description',
            dataIndex: 'description',
            title: 'Description',
            render: (val) => val ?? '--',
          },
          {
            key: 'u_url',
            dataIndex: 'u_url',
            // title: 'URL'
            align: 'right',
            render: (uUrl) => {
              return (
                <NuButton
                  type="link"
                  onClick={async () => {
                    const session: any = await oktaAuth.session.get();
                    const url = `${uUrl}?session_id=${session.id}`;

                    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
                    if (newWindow) {
                      newWindow.opener = null;
                    }
                  }}
                  icon={<LinkOutlined />}
                >
                  Open Login
                </NuButton>
              );
            },
          },
        ]}
      />
    );
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return <EmptyState>No Entitlements were found.</EmptyState>;
}

export default function EntitlementsSection(props: { clientId: string }) {
  const { clientId } = props;
  const clientContext = useClientContext();

  if (clientContext.client?.type === ClientType.program) return null;

  return (
    <PageSection title="Your Nuspire Logins">
      <EntitlementsTable clientId={clientId} />
    </PageSection>
  );
}
