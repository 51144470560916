import { Tooltip } from 'antd';
import { adminFeaturesPath } from 'components/admin/features/paths';
import { adminNotificationsPublishPath } from 'components/admin/notifications/paths';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { IUser, Access } from '../../../../types';
import { useAuthContext } from '../../../auth-context';
import { AuthorizeFunc, useAdminPage, useAuthorize } from '../../../authorization';
import { useClientContext } from '../../../client-context-provider';
import { Features, useFeatureFlag } from '../../../feature-flags';
import { LinkedMenuItem, MenuItem } from './get-main-menu-items';

export const adminItemKeys = [
  'audit-logs',
  'cache',
  'clients',
  'dashboards',
  'health-checks',
  'neces',
  'schedules',
  'superusers',
  'task-handler',
  'users',
  'nsp',
  'notifications',
  'feature-management',
] as const;
export type AdminItemKey = (typeof adminItemKeys)[number];

type AdminMenuItem<Key extends string = string> = {
  label: string;
  key?: Key;
  to?: string;
  canViewPage?: (authorize: AuthorizeFunc, user?: IUser) => ReturnType<typeof authorize> | { authorized: boolean };
  description?: string;
  children?: AdminMenuItemMap;
};

type AdminMenuItemMap<Key extends string = string> = {
  [k in Key]: AdminMenuItem<Key>;
};

// only one level of children supported. see #getAdminMenuItems() below
const rootItemsMap: AdminMenuItemMap<AdminItemKey> = {
  'audit-logs': {
    label: 'Audit Logs',
    description: 'Manage Nuspire audit logs',
    to: '/admin/audit-logs',
    canViewPage: (authorize) => authorize({ requiredPermissions: { allAuditLogs: Access.read } }),
  },
  cache: {
    label: 'Cache',
    description: 'Manage myNuspire Cache',
    to: '/admin/cache',
    canViewPage: (_, user) => ({ authorized: !!user?.isSuperUser }),
  },
  clients: {
    label: 'Clients',
    description: 'Manage Nuspire clients',
    to: '/admin/clients',
    canViewPage: (authorize) => authorize({ requiredPermissions: { allClients: Access.all } }),
  },
  dashboards: {
    label: 'Dashboards',
    description: 'Manage Nuspire Dashboards',
    to: '/admin/dashboards',
    canViewPage: (authorize) => authorize({ requiredPermissions: { allDashboards: Access.all } }),
  },
  'health-checks': {
    label: 'Health Checks',
    description: 'View the health of myNuspire integrations',
    to: '/admin/health-checks',
    canViewPage: (_authorize, user) => ({ authorized: user?.isSuperUser ?? false }),
  },
  neces: {
    label: 'NECES',
    description: 'Nuspire Enterprise Cybersecurity Enablement System',
    to: '/admin/neces',
    canViewPage: (authorize) => authorize({ requiredPermissions: { allNeces: Access.read } }),
  },
  notifications: {
    label: 'Notifications',
    description: 'Monitor and Publish Notifications',
    to: adminNotificationsPublishPath(),
    canViewPage: (authorize) => authorize({ requiredPermissions: { notifications: Access.all } }),
  },
  'feature-management': {
    label: 'Feature Management',
    description: 'Manage Feature Lifecycle and Beta Testing',
    to: adminFeaturesPath(),
    canViewPage: (authorize) => authorize({ requiredPermissions: { featureFlags: Access.all } }),
  },
  nsp: {
    label: 'Security Program',
    children: {
      'nsp|capabilities': {
        label: 'Capabilities',
        description: 'Manage Nuspire capabilities',
        to: '/admin/capabilities',
        canViewPage: (authorize) => authorize({ requiredPermissions: { capabilities: Access.all } }),
      },
      'nsp|controls': {
        label: 'Controls',
        description: 'Manage Nuspire controls',
        to: '/admin/controls',
        canViewPage: (authorize) => authorize({ requiredPermissions: { controls: Access.all } }),
      },
      'nsp|domains': {
        label: 'Domains',
        description: 'Manage Nuspire domains',
        to: '/admin/domains',
        canViewPage: (authorize) => authorize({ requiredPermissions: { domains: Access.all } }),
      },
      'nsp|industries': {
        label: 'Industries',
        description: 'Manage Nuspire industries',
        to: '/admin/industries',
        canViewPage: (authorize) => authorize({ requiredPermissions: { industries: Access.all } }),
      },
      'nsp|industry-controls': {
        label: 'Industry Controls',
        description: 'Manage Nuspire industry controls',
        to: '/admin/industry-controls',
        canViewPage: (authorize) => authorize({ requiredPermissions: { industryControls: Access.all } }),
      },
      'nsp|recommendations': {
        label: 'Recommendations',
        description: 'Manage Nuspire recommendations',
        to: '/admin/recommendations',
        canViewPage: (authorize) => authorize({ requiredPermissions: { recommendations: Access.all } }),
      },
    },
  },
  schedules: {
    label: 'Schedules',
    description: 'Manage Nuspire Schedules',
    to: '/admin/schedules',
    canViewPage: (authorize) => authorize({ requiredPermissions: { allSchedules: Access.write } }),
  },
  superusers: {
    label: 'Superusers',
    description: 'View myNuspire users with superuser privileges',
    to: '/admin/superusers',
    canViewPage: (authorize) => authorize({ requiredPermissions: { allUsers: Access.all } }),
  },
  'task-handler': {
    label: 'Task Handler',
    description: 'Manage Task handler Errors, Tasks, Schedules, etc..',
    to: `/admin/task-handler`,
    canViewPage: (authorize) => authorize({ requiredPermissions: { allSchedules: Access.write } }),
    children: {
      'task-handler|overview': {
        label: 'Overview',
        to: '/admin/task-handler/overview',
      },
      'task-handler|errors': {
        label: 'Errors',
        to: '/admin/task-handler/errors',
      },
      'task-handler|logs': {
        label: 'All Logs',
        to: '/admin/task-handler/logs',
      },
      'task-handler|tasks': {
        label: 'Tasks',
        to: '/admin/task-handler/tasks',
      },
    },
  },
  users: {
    label: 'Users',
    description: 'Manage Nuspire users',
    to: '/admin/users',
    canViewPage: (authorize) => authorize({ requiredPermissions: { allUsers: Access.all } }),
  },
};

export function useViewableAdminKeys() {
  const { canViewAdmin } = useAdminPage();
  const { client } = useClientContext();
  const effectivePermissions = client?.effectivePermissions;
  const authorize = useAuthorize({ effectivePermissions });
  const { user } = useAuthContext();
  const isNECESEnabled = useFeatureFlag(Features.NECES);

  if (!canViewAdmin) return [];

  const keys = Object.keys(rootItemsMap) as AdminItemKey[];
  return keys.flatMap((key) => {
    if (key === 'neces' && !isNECESEnabled) return [];

    const item = rootItemsMap[key];
    const { authorized } = item.canViewPage?.(authorize, user) ?? {};

    const { children } = item;
    const childKeys = Object.keys(children ?? {});

    if (authorized) {
      // all children are authorized if the root is viewable
      return children ? childKeys : key;
    }

    if (!children) return [];

    // if root key isn't authorized, check for individual authorization in each child
    return childKeys.filter((childKey) => {
      const childItem = children[childKey];
      const { authorized: childAuthorized } = childItem.canViewPage?.(authorize, user) ?? {};

      return childAuthorized;
    });
  });
}

const Link = styled(NavLink)`
  color: currentcolor;
  width: 100%;
  display: block;
`;

interface AdminMenuItemLabelProps {
  label: string;
  to?: string;
  description?: string;
}

function AdminMenuItemLabel(props: AdminMenuItemLabelProps) {
  const { label, to, description } = props;

  if (label == null) return null;

  const innerLabel = to ? (
    <Link to={to} style={{ color: 'currentcolor', width: '100%' }}>
      {label}
    </Link>
  ) : (
    <span>{label}</span>
  );

  if (!description) return innerLabel;

  return (
    <Tooltip title={description} placement="right">
      {innerLabel}
    </Tooltip>
  );
}

function transformAdminMenuItem<Key extends string = AdminItemKey>(
  item: Omit<AdminMenuItem<Key>, 'canViewPage'>,
): LinkedMenuItem {
  const { key, ...props } = item;
  return {
    key: `admin|${item.key}`,
    label: <AdminMenuItemLabel key={key} {...props} />,
    to: item.to,
  };
}

interface GetAdminMenuItemsArgs {
  viewableAdminKeys: string[];
}

export function getAdminMenuItems({ viewableAdminKeys }: GetAdminMenuItemsArgs) {
  const menuItemsMap = viewableAdminKeys.reduce(
    (acc, key) => {
      // only one level of children supported. 2+ level admin menus can be dealt with as it comes
      const [rootKey, childKey] = key.split('|') as [AdminItemKey, string];
      const item = transformAdminMenuItem({ ...rootItemsMap[rootKey], key: rootKey });

      const childrenMap = rootItemsMap[rootKey].children;
      if (childKey && childrenMap) {
        item.children = Object.entries(childrenMap).map(([mapKey, adminMenuItem]) => {
          return transformAdminMenuItem({ ...adminMenuItem, key: mapKey });
        });
      }

      acc[rootKey] = item;

      return acc;
    },
    {} as Record<AdminItemKey, MenuItem>,
  );

  return Object.values(menuItemsMap);
}
