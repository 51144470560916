import { gql, useMutation } from '@apollo/client';
import { message } from 'antd';
import { ButtonType } from 'antd/es/button';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { Footer } from 'antd/es/layout/layout';
import Modal from 'antd/es/modal/Modal';
import { TableRowSelection } from 'antd/es/table/interface';
import Typography from 'antd/es/typography/Typography';
import { DashboardsList } from 'components/dashboard/dashboards-modal';
import { NuButton } from 'components/nuspire';
import { useState } from 'react';

const ADD_WIDGET_TO_DASHBOARDS = gql`
  mutation AddWidgetToDashboards($widgetId: String!, $dashboardIds: [String]) {
    addWidgetToDashboards(widgetId: $widgetId, dashboardIds: $dashboardIds) {
      ok
    }
  }
`;

function ModalContent(props: { onClose: () => void; widgetId: string }) {
  const { onClose, widgetId } = props;

  /**
   * Row Selection
   */
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const handleRowSelection = (selectedKeys, _selectedRows) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection: TableRowSelection<any> = {
    onChange: handleRowSelection,
    selectedRowKeys,
  };

  /**
   * Handle Save
   */
  const [addWidgetToDashboards] = useMutation(ADD_WIDGET_TO_DASHBOARDS, {
    refetchQueries: ['DashboardWidgetsByWidgetId']
  });
  const handleSave = async () => {
    const variables = {
      widgetId,
      dashboardIds: selectedRowKeys,
    };

    const result = await addWidgetToDashboards({ variables });

    const ok = result?.data?.addWidgetToDashboards?.ok ?? false;
    if (ok) {
      message.success('Widgets have been added to Dashboards');
    }
  };

  return (
    <>
      <DashboardsList rowSelection={rowSelection} />

      <Footer>
        <NuButton onClick={onClose}>Cancel</NuButton>
        <NuButton onClick={handleSave} type="primary">
          Add
        </NuButton>
      </Footer>
    </>
  );
}

export function AddToDashboardsButton(props: { widgetId: string, size?: SizeType, type?: ButtonType, buttonContent?: string }) {
  const { widgetId, size, type, buttonContent } = props;
  const [visible, setVisible] = useState<boolean>(false);

  const handleOpenModal = () => setVisible(true);
  const handleCloseModal = () => setVisible(false);

  return (
    <>
      <NuButton onClick={handleOpenModal} size={size ?? 'large'} type={type ?? 'text'}  >
        <Typography >
          {buttonContent ?? "Add to Dashboards"}
        </Typography>
      </NuButton >

      <Modal width={800} open={visible} title="Add to Dashboards" onCancel={handleCloseModal} footer={false}>
        {visible && <ModalContent onClose={handleCloseModal} widgetId={widgetId} />}
      </Modal>
    </>
  );
}

export default AddToDashboardsButton;
