import useSearchParams from 'utils/react-hooks/useSearchParams';
import { FilterInputOperator, QueryDataTypeQueryVariables } from 'types/graph-codegen/graph-types';
import { Unpacked } from 'types';
import { FilterInput, FiltersByKey, SearchParamFilters } from 'utils/react-hooks/search-param-filters';
import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

export type QueryDataTypeFilter = Unpacked<NonNullable<QueryDataTypeQueryVariables['filters']>>;
/**
 * Extend Search Param Filters with Data Explorer specific methods
 */
export class DataExplorerFilters extends SearchParamFilters {
  queryFilters: QueryDataTypeFilter[];

  constructor(args) {
    super(args);

    this.queryFilters = this.formatFiltersForQuery();
  }
  /**
   * Format parsed filters to use in get data type query.
   */
  formatFiltersForQuery(): QueryDataTypeFilter[] {
    return this.filters.map(({ key, operator, value }) => ({
      key,
      operator,
      value: value ? JSON.stringify(value) : undefined, // can just be an operator with no value (EXISTS)
    }));
  }
}

/**
 * React hook to manage parsing and state management for data explorer search param filters.
 * @param args
 * filterInputs: are the filter definitions and should come from the chosen data type.
 * this will be empty if no data type has been selected.
 */
export function useDataExplorerFilters(args: { filterInputs?: FilterInput[] }) {
  const { filterInputs } = args;

  // search params hook we've always used.
  const searchParamsApi = useSearchParams();

  // simple function to parse search params to
  const getFiltersFromSearchParams = () => {
    const initialValues = new DataExplorerFilters({
      searchParamsApi,
      filterInputs,
    }).filtersByKey;

    return initialValues;
  };

  // we store values in state.
  // we initialize state based off of values in search params.
  const [values, setValues] = useState(() => {
    return getFiltersFromSearchParams();
  });

  // initialize values based off search params.
  const initializeValues = () => {
    const initialValues = getFiltersFromSearchParams();

    setValues(initialValues);
  };

  useEffect(() => {
    initializeValues();
  }, [filterInputs]);

  // update function used by UI components below.
  const handleUpdate = (args: { key: string; value: any; operator?: FilterInputOperator }) => {
    const { key, value, operator } = args;

    const updatedFilter = new DataExplorerFilters({
      searchParamsApi,
      filterInputs,
    }).updateFilterValue({
      key,
      operator,
      value,
    });

    // update state with all values. in it.
    setValues({
      ...values, // previous state. we should only need to update one key at a time.
      [key]: updatedFilter,
    });
  };

  const updateFilters = (
    updates: {
      key: string;
      value: any;
      operator?: FilterInputOperator;
    }[],
  ) => {
    const updatedFilters = updates.reduce(
      (acc: FiltersByKey, update) => {
        const { key, value, operator } = update;

        const updatedFilter = new DataExplorerFilters({
          searchParamsApi,
          filterInputs,
        }).updateFilterValue({
          key,
          operator,
          value,
        });

        acc[key] = updatedFilter;

        return acc;
      },
      { ...values },
    );

    setValues(updatedFilters);
  };

  // filter tags can be clicked on to be removed.
  // we need to update state and allow state to update search params.
  const handleRemove = (key: string) => {
    handleUpdate({
      key,
      value: undefined,
      operator: undefined,
    });
  };

  /**
   * Debounce updates and wait for user to finish updating filter values (ie typing)
   * - after debounce duration, update search params so values are stored for a refresh and copy paste.
   */
  const debouncedUpdateSearchParams = debounce((values) => {
    new DataExplorerFilters({
      searchParamsApi,
      filterInputs,
      filtersByKey: values,
    }).updateFilterSearchParams();
  }, 500);

  // Every time values state changes call the debounced update search params.
  useEffect(() => {
    debouncedUpdateSearchParams(values);
  }, [values]);

  /**
   * The only con I can think of with this approach is that I don't think
   * manually updating the search params would update the filters.
   * we'd have to think about this as trying to do this often leads to cyclical problems...
   *
   * also, we'll want to pass method for getting filters to pass over the wire.
   */
  return new DataExplorerFilters({
    searchParamsApi,
    filterInputs,
    filtersByKey: values,
    onUpdateFilter: handleUpdate,
    onUpdateFilters: updateFilters,
    onRemoveFilter: handleRemove,
  });
}
