import React from 'react';
import { Select } from 'antd';
import { ActionInputProps } from '../../action-form-field';

const reports = [
  `firewallData`,
  `firewallIdsIps`,
  `edrAvData`,
  `windows-events`,
  `microsoft-events`,
  `salesforceReview`,
  `linuxReview`,
  `fortigateReview`,
  `okta-events`,
  `wafAndProxy`,
  `ticketReview`,
  `sentinel-one-events`,
  `sentinel-one-compliance-events`,
  `qualysVulnerabilities`,
  `qualysWebApps`,
];

const SocReviewReportSelect = (props: ActionInputProps) => {
  const { value, onChange } = props;

  return (
    <Select value={value} onChange={(values) => onChange(values)} mode="multiple">
      {reports.map((r) => (
        <Select.Option key={r} value={r}>
          {r}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SocReviewReportSelect;
