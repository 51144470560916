import { App, Divider, Typography } from 'antd';
import { EmptyState, NuCardBody } from 'components/nuspire';
import { WidgetComponentProps } from 'components/widgets/widget';
import { mixpanelTrack } from 'utils/mixpanel/mixpanel-track';
import styled from 'styled-components';

type IndustryNews = {
  link: string;
  subText: string;
  title: string;
};

const NewsDivider = styled(Divider)`
  margin: 8px !important;
`;

interface NewsItemProps {
  newsItem: IndustryNews;
}

export function NewsItem({ newsItem }: NewsItemProps) {
  const { link, subText, title } = newsItem;
  const openInNewTab = (urlConfirm: string) => {
    const newWindow = window.open(urlConfirm, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const { modal } = App.useApp();

  const openUrlConfirm = (e: React.MouseEvent, urlConfirm: string) => {
    e.preventDefault();

    modal.confirm({
      title: 'You are about to leave myNuspire',
      content: 'Would you like to proceed?',
      onOk() {
        openInNewTab(urlConfirm);
        mixpanelTrack('industry-news', { url: urlConfirm });
      },
    });
  };

  return (
    <>
      <li>
        <div>
          <a href={link} onClick={(e) => openUrlConfirm(e, link)}>
            <div style={{ marginBottom: '1rem' }}>
              <Typography.Text strong style={{ color: 'inherit' }}>
                {title}
              </Typography.Text>
              <br />
              <small>{subText}</small>
            </div>
          </a>
        </div>
      </li>
      <NewsDivider />
    </>
  );
}

interface IndustryNewsWidgetViewProps {
  newsItems: IndustryNews[];
}

export function IndustryNewsWidgetView({ newsItems }: IndustryNewsWidgetViewProps) {
  return (
    <NuCardBody>
      <ul style={{ paddingLeft: 0, listStyle: 'none' }}>
        {newsItems.map((news) => (
          <NewsItem key={news.link} newsItem={news} />
        ))}
      </ul>
    </NuCardBody>
  );
}

const IndustryNewsWidget = (props: WidgetComponentProps<any, any>) => {
  const { data } = props;

  if (!data?.newsItems) return <EmptyState>No Data Found.</EmptyState>;

  return <IndustryNewsWidgetView newsItems={data.newsItems} />;
};

export default IndustryNewsWidget;
