import { Azure as AzureIcon } from 'components/nuspire/nu-icon';
import { IConnector } from 'components/connections/connectors';
import AzureRedirect from './azure-redirect'
import { encodeOAuthState } from '../utils/oauth';

const AZURE_CONNECTOR_DEFINITION: IConnector = {
    slug: 'azure',
    name: 'Azure',
    description: 'Azure AD and Microsoft Graph Connection',
    connectionTypes: ['action'],
    icon: <AzureIcon />,
    handleAddConnection: ({ clientId }) => {
        if (!clientId) {
            throw new Error('Client Id must be passed to connector');
        }

        const authState = encodeOAuthState({ clientId });

        window.open(
            `${window.location.origin}/redirects/connections/azure/auth?state=${authState}`,
            'Azure Auth',
            'height=1000,width=800',
        );
    },
    redirects: [{ path: 'auth', component: AzureRedirect }],

    updatableAuthFields: [
        { inputType: 'string', key: 'Connection Name', value: 'name' },
        { inputType: 'string', key: 'Application Client Secret Value', value: 'appSecret' },
        { inputType: 'string', key: 'Application Client Id', value: 'appClientId' },
        { inputType: 'string', key: 'Tenant ID', value: 'tenantId' },
        { inputType: 'string', key: 'Graph API URL', value: 'baseURL' },
        { inputType: 'string', key: 'Authentication URL', value: 'authURL' }
    ],
};

export default AZURE_CONNECTOR_DEFINITION;
