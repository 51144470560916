import { EmptyState, NuCardContent, Link } from 'components/nuspire';
import { useClientContext } from 'components/client-context-provider';

const ApiTokenError = (props: { connectionId: string; errorMsg: string }) => {
  const { clientId } = useClientContext();
  const { connectionId, errorMsg } = props;
  return (
    <NuCardContent>
      <EmptyState>
        {errorMsg}
        <Link to={{ pathname: `/${clientId}/connections/connectors/sentinelone/${connectionId}`, search: '?tab=configuration' }}>
          {' '}
          Update it here.
        </Link>
      </EmptyState>
    </NuCardContent>
  );
};

export default ApiTokenError;
