import { useContext, useEffect } from 'react';
import { SubscriptionContext } from 'components/subscription/subscription-context';
import styled from 'styled-components';
import { useAuthContext } from 'components/auth-context';
import Chart from 'chart.js';
import { NuCardContent, NuCardFooter, NuSampleOverlay } from 'components/nuspire';
import { InsightsIcon } from 'components/nuspire/nu-icon';
import baseTheme from 'components/theme';
import { Doughnut } from 'components/nuspire/charts/doughnut-chart';
import { Typography } from 'antd';
import { WidgetComponentProps } from '../../widgets/widget';

const WidgetContentRoot = styled.div`
  display: flex;
`;
const ChartContainer = styled.div`
  width: 150px;
  height: 122px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const Info = styled.div`
  flex: 1 0;
`;
function WidgetContent() {
  const data: Chart.ChartData<'doughnut'> = {
    datasets: [
      {
        data: [64, 36],
        backgroundColor: [baseTheme.color.red, baseTheme.color.gray1],
      },
    ],
  };

  const message = `64 vulnerable hosts were discovered in Qualys without Endpoint Protection on SentinelOne.`;

  return (
    <WidgetContentRoot>
      <ChartContainer>
        <div style={{ width: '122px' }}>
          <Doughnut
            data={data}
            centerContent={(
              <h1>64</h1>
            )}
          />
        </div>
      </ChartContainer>
      <Info>
        <Typography>{message}</Typography>
      </Info>
    </WidgetContentRoot>
  );
}

// TODO: should we delete this widget? this is a sample widget from 2 years ago
function Widget({ isReportWidget, setFooterContent }: WidgetComponentProps<any, any>) {
  const subApi = useContext(SubscriptionContext);
  const { user } = useAuthContext();

  useEffect(() => {
    if (!isReportWidget && setFooterContent) {
      setFooterContent(
        <NuCardFooter
          icon={<InsightsIcon style={{ color: baseTheme.color.slateBlue }} />}
          label="View Full Insight Details"
          url="/Insights"
        />
      );
    }
  }, []);

  return (
    <NuSampleOverlay onClick={() => subApi.setIsUpgradeModalOpen(!user?.serviceNowUserId)}>
      <NuCardContent>
        <WidgetContent />
      </NuCardContent>
    </NuSampleOverlay>
  );
}

export default Widget;
