import React, { createContext, useContext, useState } from 'react';
import ClientTaskModal from './client-task-modal';

export const ClientTaskModalContext = createContext({
  visible: false,
  showModal: (id) => id,
  hideModal: () => {},
});

export function useClientTaskModalContext() {
  return useContext(ClientTaskModalContext);
}

const ClientTaskModalContextProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const [visible, setVisible] = useState(false);
  const [id, setId] = useState<string | undefined>(undefined);
  const handleClose = () => setId(undefined);

  const showModal = (id) => {
    setVisible(true);
    setId(id);
  };
  const hideModal = () => setVisible(false);

  const context = {
    visible,
    showModal,
    hideModal,
  };

  return (
    <ClientTaskModalContext.Provider value={context}>
      {children}
      <ClientTaskModal id={id} onClose={handleClose} />
    </ClientTaskModalContext.Provider>
  );
};

export default ClientTaskModalContextProvider;
