import { ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'components/nuspire';
import * as NuIcon from 'components/nuspire/nu-icon';
import classNames from 'classnames';

export interface NuCardFooterProps {
  icon?: ReactNode;
  label?: string;
  url?: string;
  dataIntercomTarget?: string;
  actions?: ReactNode;
  onClick?: () => void;

}

const Root = styled.div`
  position: relative;
  display: flex;
  height: 64px;
  align-items: center;
`;
const Nav = styled.div`
  color: ${(p) => p.theme.token.colorTextSecondary};
  height: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  transition: all 0.25s ease;

  &.full-width {
    flex: 1 0;
  }

  &.is-link {
    &:hover {
      color: ${(p) => p.theme.color.primaryBlue};
      background-color: ${(p) => p.theme.token.colorFillContentHover};
    }
  }
`;
const Icon = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isChevron'].includes(prop),
}) <{
  isChevron?: boolean;
}>`
  height: 32px;
  width: 32px;
  font-size: 24px;
  line-height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => (p.isChevron ? p.theme.color.gray4 : p.theme.color.primaryBlue)};
  margin: ${(p) => (p.isChevron ? '0 0 0 8px' : '0 8px 0 0')};
  transition: all 0.25s ease;

  .card-footer-nav:hover & {
    color: ${(p) => p.theme?.color?.primaryBlue ?? 'blue'};
  }
`;
const Label = styled.div`
  flex: 1 0;
`;
const Actions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0 8px;
`;
const StyledLink = styled(Link)`
  display: block;
  flex: 1 0;
  &:hover {
    color: inherit;
  }
`;

const StyledATag = styled.a`
  display: block;
  flex: 1 0;
  &:hover {
    color: inherit;
  }
`;

function CardFooterNav(props: NuCardFooterProps) {
  const { icon, label, url, actions, dataIntercomTarget, onClick } = props;

  const navClasses = classNames('card-footer-nav', {
    'full-width': Boolean(!actions),
    'is-link': Boolean(url),
  });

  const isUrl = url ? /^(https:|http:|www\.)\S*/.test(url) : false;

  const element = (
    <Nav className={navClasses} data-intercom-target={dataIntercomTarget}>
      {icon && <Icon>{icon}</Icon>}

      {label && <Label className="card-footer-nav-label">{label}</Label>}

      {url && !actions && (
        <Icon isChevron>
          <NuIcon.ChevronRight />
        </Icon>
      )}
      {onClick && !actions && (
        <Icon isChevron>
          <NuIcon.ChevronRight onClick={async () => onClick()}
          />

        </Icon>

      )}
    </Nav>
  );

  if (url && !isUrl) {
    return (
      <StyledLink to={url} mode="plain">
        {element}
      </StyledLink>
    );
  }

  if (url && isUrl) {
    return (
      <StyledATag href={url} target="_blank">
        {element}
      </StyledATag>
    );
  }

  return element;
}

function CardFooter(props: NuCardFooterProps) {
  const { actions } = props;
  return (
    <Root>
      <CardFooterNav {...props} />
      {actions && <Actions>{actions}</Actions>}
    </Root>
  );
}

export default CardFooter;
