import { EyeFilled } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Button, Table, message } from 'antd';
import { useClientContext } from 'components/client-context-provider';
import { NuButton } from 'components/nuspire';
import InfiniteTable from 'components/nuspire/infinite-table';
import { Filter } from 'components/nuspire/nu-icon';
import { renderComponentsMap } from 'components/widgets/widget/widget-types/render-components';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import objectPath from 'object-path';
import { ComponentProps, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { QueryDataTypeQuery, type QueryDataTypeQueryVariables } from 'types/graph-codegen/graph-types';
import { client } from 'utils/graphql';
import { mixpanelTrack } from 'utils/mixpanel/mixpanel-track';
import useSearchParams from 'utils/react-hooks/useSearchParams';
import { IDataExplorerContext } from './data-explorer';
import { IDataType } from './data-explorer-results';
import { parseFilterSearchParams } from './search-params';

const QUERY_DATA_TYPE = gql`
  query QueryDataType(
    $clientId: String!
    $dataType: String!
    $debug: Boolean
    $debugRequestId: String
    $filters: [QueryDataTypeFilter!]
    $limit: Int
    $sort: ColumnSortOrder
    $sortBy: String
    $start: String
    $time: String
  ) {
    queryDataType(
      clientId: $clientId
      debug: $debug
      debugRequestId: $debugRequestId
      filters: $filters
      limit: $limit
      sort: $sort
      sortBy: $sortBy
      start: $start
      time: $time
      type: $dataType
    ) {
      data
      next
    }
  }
`;

const PaddingTop = styled.div`
  padding-top: 10px;
`;

const InspectButtonContainer = styled.div`
  opacity: 0;
  transition: 0.25s opacity ease;

  .ant-table-row:hover & {
    opacity: 1;
  }
`;

const FilterOnButtonRoot = styled.div`
  display: flex;
`;

const FobValue = styled.div`
  margin-right: 8px;
`;

const FobBtnWrap = styled.div`
  opacity: 0;

  transition: opacity ease 0.2s;

  .fob-root:hover & {
    opacity: 1;
  }
`;

function FilterOnColumnButton(props: {
  column: any;
  value: any; // column value.
  record: any; // entire row.
  dataExplorerContext: IDataExplorerContext;
}) {
  const {
    value,
    record,
    column,

    dataExplorerContext,
  } = props;

  const { dataExplorerFilters } = dataExplorerContext;

  const handleAddFilter = () => {
    const { filterInputs } = column;
    if (!filterInputs) {
      // ideally, we would never show them the button if this was the case.
      return;
    }

    const changes = filterInputs.reduce((acc: { key: string; value: any }[], filter) => {
      const dataIndex = filter.dataIndex ?? column.dataIndex;
      // find value from dot notation.
      const pathValue = objectPath.get(record, dataIndex);
      if (pathValue) {
        acc.push({
          key: filter.key,
          value: pathValue,
        });
      }

      return acc;
    }, []);

    if (changes.length) {
      dataExplorerFilters.updateFilters(changes);
    }
  };

  return (
    <FilterOnButtonRoot className="fob-root">
      <FobValue>{value}</FobValue>
      <FobBtnWrap>
        <NuButton onClick={handleAddFilter} size="small" type="link">
          <Filter />
        </NuButton>
      </FobBtnWrap>
    </FilterOnButtonRoot>
  );
}

export function millisecondsToHumanReadableDuration(durationMs: number): string {
  const duration = dayjs.duration(durationMs);
  const mos = duration.months();
  const w = duration.weeks();
  const d = duration.days() - w * 7; // subtract weeks part to get remaining days
  const hrs = duration.hours();
  const m = duration.minutes();
  const s = duration.seconds();

  let humanReadable = '';
  if (mos > 0) humanReadable += `${mos}mos `;
  if (w > 0) humanReadable += `${w}w `;
  if (d > 0) humanReadable += `${d}d `;
  if (hrs > 0) humanReadable += `${hrs}hrs `;
  if (m > 0) humanReadable += `${m}m `;
  if (s > 0) humanReadable += `${s}s`;

  return humanReadable.trim();
}

function getColumnsFromDataType(dataType: IDataType, dataExplorerContext: IDataExplorerContext) {
  const { detailWidgets } = dataType;
  const backendColumns = dataType.columns as any;
  const { searchParamsInterface } = dataExplorerContext;
  const { parsed } = searchParamsInterface ?? useSearchParams();
  const sort: SortType | undefined = parsed?.[SORT];
  const sortBy: string | undefined = parsed?.[SORT_BY];

  const getSortOrder = (key: string) => {
    if (key === sortBy) {
      return sort;
    }

    return null;
  };

  const columns = backendColumns.map((backendColumn) => ({
    ...backendColumn,
    dataIndex: backendColumn.dataIndex?.split('.'),
    render: (value, record) => {
      if (backendColumn.filterInputs) {
        return (
          <FilterOnColumnButton
            column={backendColumn}
            record={record}
            dataExplorerContext={dataExplorerContext}
            value={value}
          />
        );
      }

      if (backendColumn.renderComponent) {
        //render with value and record
        const RenderComponent = renderComponentsMap[backendColumn.renderComponent];

        if (RenderComponent) {
          return <RenderComponent value={value} record={record} />;
        }
      }

      const dateKeys = ['timestamp', 'time-received', 'createdBy', 'createdAt', 'updatedBy', 'updatedAt'];
      if (dateKeys.includes(backendColumn.key)) {
        const formattedValue = new Date(value).toLocaleString(navigator.language, { timeZoneName: 'short' });
        // console.log(`Formatting ${backendColumn.key} as date: ${formattedValue}`);
        return formattedValue;
      }

      if (backendColumn.key === 'durationMSec') {
        return millisecondsToHumanReadableDuration(Number(value));
      }

      return value ?? '--';
    },
    sortOrder: getSortOrder(backendColumn.key),
    width: '150px',
  }));

  if (detailWidgets.length) {
    /**
     * Inspect Row Button/Icon
     */
    columns.unshift({
      key: 'select',
      width: 50,
      render: (_text, record) => {
        return (
          <InspectButtonContainer>
            <NuButton
              onClick={() => {
                console.log('Selected', { record });
              }}
              size="small"
              shape="circle"
              type="link"
            >
              <EyeFilled />
            </NuButton>
          </InspectButtonContainer>
        );
      },
    });
  }

  return columns;
}

export function useSearchParamFilters() {
  const { parsed } = useSearchParams();

  const { time, sort: _sort, sort_by: _sort_by, ...filterParams } = parsed;

  const filters = parseFilterSearchParams(filterParams, true);

  return [filters, time];
}

const DataExplorerTableRoot = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /*
  .ant-table-cell {
    min-width: 150px; /* Adjust this value as needed */
  }
  */

  .ant-table-selection-column .ant-checkbox {
    display: none;
  }

  & .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: ${(p) => p.theme.token.colorPrimaryBg};
  }
`;

const SORT = 'sort';
const SORT_BY = 'sort_by';
export type SortType = 'ascend' | 'descend';

export function useSortParams() {
  const { parsed, setParameters } = useSearchParams();

  const sort: SortType | undefined = parsed?.[SORT];
  const sortBy: string | undefined = parsed?.[SORT_BY];

  const handleSortChange = (args: { sort?: SortType; sortBy?: string }) => {
    const { sort: sortChanged, sortBy: sortByChanged } = args;
    if (!sortChanged && !sortByChanged) {
      // RESET
      setParameters([
        { name: SORT, value: null },
        { name: SORT_BY, value: null },
      ]);
      return;
    }

    const changes: { name: string; value: string }[] = [];
    if (sortChanged) {
      changes.push({ name: SORT, value: sortChanged });
    }

    if (sortByChanged) {
      changes.push({ name: SORT_BY, value: sortByChanged });
    }
    setParameters(changes);
  };

  return {
    onSortChange: handleSortChange,
    sort,
    sortBy,
  };
}

export const DEFAULT_DATA_EXPLORER_LIMIT = 100;

/**
 * Infite Table for querying data types.
 */
export function DataExplorerTable(props: {
  dataType: IDataType;
  dataExplorerContext: IDataExplorerContext;
  debugRequestId: string;
  toggleSelectedItem: (selected: { [key: string]: any }) => void;
  selectedItem?: any;
}) {
  const {
    dataType: { id: dataTypeSlug },
    dataType,
    selectedItem,
    toggleSelectedItem,
    dataExplorerContext,
    dataExplorerContext: { dataExplorerFilters },
  } = props;

  // data explorer Context

  // client context
  const { clientId } = useClientContext();
  const sortApi = useSortParams();

  const rowKey = dataType.rowKey || 'id';

  // Sorting
  const defaultSortColumn = dataType.columns?.find((c) => Boolean(c.defaultSortOrder));
  const { sort: paramsSort, sortBy: paramsSortBy, onSortChange } = sortApi;

  const debouncedSortRefetch = useCallback(
    debounce((sort: SortType, sortBy: string) => {
      fetchData({ newSearch: true, sort, sortBy });
    }, 3000),
    [],
  );

  // ========== Transform Column definitions ==========

  // ========== Get filters from search params ==========

  // const [filters, time] = useSearchParamFilters();
  const { queryFilters: filters, time } = dataExplorerFilters;

  // ========== Fetching and scrolling
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [next, setNext] = useState<string | undefined | null>(undefined);

  const columns = getColumnsFromDataType(dataType, dataExplorerContext);
  // Query variables
  const variables = {
    clientId,
    dataType: dataTypeSlug,
    limit: DEFAULT_DATA_EXPLORER_LIMIT,
    filters,
    sort: paramsSort ?? defaultSortColumn?.defaultSortOrder,
    sortBy: paramsSortBy ?? defaultSortColumn?.key,
    time,
  };

  type TableOnChange = ComponentProps<typeof Table>['onChange'];
  const handlePagination: TableOnChange = (_pagination, _filters, sorter, extra) => {
    if (Array.isArray(sorter)) return;
    if (extra.action !== 'sort') return;
    if (sorter?.order == null || !sorter?.columnKey) return;

    const sort = sorter.order;
    const sortBy = sorter.columnKey as string;

    onSortChange({
      sort,
      sortBy,
    });

    debouncedSortRefetch(sort, sortBy);
  };

  // fetch and load table rows
  const fetchData = async (args?: { newSearch?: boolean; sort?: SortType; sortBy?: string }) => {
    setLoading(true);
    dataExplorerContext?.setLoading(true);
    const isNewSearch = args?.newSearch;
    const { sort, sortBy } = args ?? {};

    const queryVariables: QueryDataTypeQueryVariables = {
      ...variables,
      clientId: variables.clientId!,
      debug: true,
      debugRequestId: props.debugRequestId,
      start: !isNewSearch && next ? next : undefined,
    };

    if (sort && sortBy) {
      queryVariables.sort = sort;
      queryVariables.sortBy = sortBy;
    }

    const { data, error, errors } = await client.query<QueryDataTypeQuery, QueryDataTypeQueryVariables>({
      query: QUERY_DATA_TYPE,
      variables: queryVariables,
      fetchPolicy: 'network-only',
    });

    if (error) {
      console.error(error);
      message.error(error.message);
    }

    if (errors) {
      const e = errors.at(0); // or undefined if it does not exist
      if (e) {
        console.error(e);
        message.error(e.message);
      }
    }

    const items = data?.queryDataType?.data;
    if (items) {
      const newDataSource = isNewSearch ? [...items] : [...dataSource, ...items];
      setDataSource(newDataSource);
    }

    mixpanelTrack('data-explorer-search-executed', {
      dataType: dataTypeSlug,
    });

    // use for next batch on scroll
    setNext(data?.queryDataType?.next);
    setLoading(false);
    dataExplorerContext?.setLoading(false);
  };

  // Clear data when data type changes?

  // trigger refetch when variables change.
  useEffect(() => {
    // fetchData({ newSearch: true });
    const executeQuery = () => fetchData({ newSearch: true });

    if (dataExplorerContext) {
      // Pass anon function to bypass function initializing.
      dataExplorerContext.setSearchFunction(() => executeQuery);
    }
  }, [JSON.stringify(variables)]);

  // handle scroll
  const handleScroll = async () => {
    if (next) {
      console.log('handleScroll... ');
      await fetchData();
    }
  };

  const lastId = dataSource[dataSource.length - 1]?.[rowKey];
  const isMoreAvailable = next && !loading;
  const selectedRowKeys = selectedItem ? [selectedItem[rowKey]] : undefined;

  return (
    <DataExplorerTableRoot>
      <InfiniteTable
        columns={columns ?? undefined}
        onChange={handlePagination}
        dataSource={dataSource}
        rowKey={rowKey}
        loading={loading}
        lastId={lastId}
        onFetch={handleScroll}
        scroll={{
          y: 600,
        }}
        rowSelection={{
          selectedRowKeys,
          hideSelectAll: true,
          columnWidth: 0,
          renderCell: () => null,
        }}
        onRow={(record) => {
          return {
            onClick: (_event) => {
              toggleSelectedItem(record);
            },
          };
        }}
      />
      <PaddingTop>
        <Button type="primary" onClick={handleScroll} disabled={!isMoreAvailable}>
          Load More
        </Button>
      </PaddingTop>
    </DataExplorerTableRoot>
  );
}
