import { message, Popconfirm, Table, Tooltip, Typography } from 'antd';
import { NuButton } from 'components/nuspire';
import { Delete as DeleteIcon } from 'components/nuspire/nu-icon';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { UserGroupApi } from '../user-group-detail';
import AddUsersToGroupBtn from './add-users-to-group-btn';
import { gql, useMutation } from '@apollo/client';
import { useClientContext } from 'components/client-context-provider';
import { Access } from 'types';
import { useAuthContext } from 'components/auth-context';

const UgabRoot = styled.div`
  position: relative;
`;
const UgabContent = styled.div`
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const UgabLeft = styled.div`
  flex: 1;
`;
const UgabRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface DeleteMembersApi {
  idsToRemove: string[];
  setIdsToRemove: (ids: string[]) => void;
}

const RmbRoot = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.token.colorErrorBg};
`;

export const REMOVE_USER_GROUP_MEMBER = gql`
  mutation RemoveUserGroupMember($clientId: String, $userGroupId: String!, $userId: String!) {
    removeUserGroupMember(clientId: $clientId, userGroupId: $userGroupId, userId: $userId)
  }
`;

function RemoveMembersBar(props: { deleteMembersApi: DeleteMembersApi; userGroupApi: UserGroupApi }) {
  const {
    deleteMembersApi: { idsToRemove, setIdsToRemove },
    userGroupApi: { userGroup, refetchUserGroup },
  } = props;
  const countSelectedText = `${idsToRemove.length} selected`;
  const [removing, setRemoving] = useState<boolean>(false);
  const [removeUser] = useMutation(REMOVE_USER_GROUP_MEMBER);

  const handleRemove = async () => {
    setRemoving(true);

    const removeResults = await Promise.all(
      idsToRemove.map(async (userId) => {
        try {
          const variables = {
            userGroupId: userGroup.id,
            userId,
            clientId: userGroup.clientId,
          };
          const result = await removeUser({ variables });

          return { ok: result.data?.removeUserGroupMember ?? false };
        } catch (err) {
          console.log('error removing member', { err });
          return { ok: false };
        }
      }),
    );

    setRemoving(false);

    // refetch up to date list
    refetchUserGroup();

    // Summarize Results;
    const { successCount, failedCount } = removeResults.reduce(
      (
        acc: {
          successCount: number;
          failedCount: number;
        },
        result,
      ) => {
        if (result.ok) {
          acc.successCount += 1;
        } else {
          acc.failedCount += 1;
        }

        return acc;
      },
      {
        successCount: 0,
        failedCount: 0,
      },
    );

    const successSummary =
      successCount > 0 ? `${successCount} member${successCount !== 1 ? 's' : ''} were successfully removed. ` : false;

    const failedSummary =
      failedCount > 0 ? `Failed to remove ${failedCount} member${failedCount !== 1 ? 's' : ''}` : false;

    if (successSummary) {
      message.success(successSummary);
    }
    if (failedSummary) {
      message.error(failedSummary);
    }
    setIdsToRemove([]);
  };

  return (
    <RmbRoot>
      <UgabContent>
        <UgabLeft>
          <Typography.Text type="danger">{countSelectedText}</Typography.Text>
        </UgabLeft>
        <UgabRight>
          <Popconfirm
            title="Are you sure you want to remove selected members from this User Group?"
            okText="Yes"
            cancelText="No"
            onConfirm={handleRemove}
            placement="left"
          >
            <NuButton icon={<DeleteIcon />} ghost danger loading={removing}>
              {removing ? 'Removing' : 'Remove'}
            </NuButton>
          </Popconfirm>
        </UgabRight>
      </UgabContent>
    </RmbRoot>
  );
}

function UserGroupActionBar(props: {
  userGroupApi: UserGroupApi;
  setDeleteMembersApi: (api: DeleteMembersApi) => void;
}) {
  const { userGroupApi, setDeleteMembersApi } = props;
  const [idsToRemove, setIdsToRemove] = useState<string[]>([]);

  const deleteMembersApi: DeleteMembersApi = {
    idsToRemove,
    setIdsToRemove,
  };

  useEffect(() => {
    // allows parent component to interact with api.
    setDeleteMembersApi(deleteMembersApi);
  }, [idsToRemove]);

  const showRemoveMembersBar = idsToRemove.length > 0;
  const { user: authUser } = useAuthContext();
  const { authorize } = useClientContext();
  const { authorized, message: unauthorizedMessage } = authorize({
    requiredPermissions: { users: Access.write },
  });

  const canWriteUsers = authorized || authUser?.isSuperUser;

  let addBtn = <AddUsersToGroupBtn disabled={!canWriteUsers} userGroupApi={userGroupApi} />;

  if (unauthorizedMessage) {
    addBtn = (
      <Tooltip title={unauthorizedMessage}>
        <div>{addBtn}</div>
      </Tooltip>
    );
  }

  return (
    <UgabRoot>
      <UgabContent>
        <UgabRight>{addBtn}</UgabRight>
      </UgabContent>

      {showRemoveMembersBar && <RemoveMembersBar deleteMembersApi={deleteMembersApi} userGroupApi={userGroupApi} />}
    </UgabRoot>
  );
}

function UserGroupMembers(props: { userGroupApi: UserGroupApi }) {
  const {
    userGroupApi: {
      userGroup: { members },
    },
  } = props;
  const [deleteMembersApi, setDeleteMembersApi] = useState<DeleteMembersApi | null>(null);
  const { user: authUser } = useAuthContext();
  const { authorize } = useClientContext();
  const { authorized } = authorize({
    requiredPermissions: {
      users: Access.write,
    },
  });

  const canWriteUsers = authorized || authUser?.isSuperUser;

  return (
    <>
      <UserGroupActionBar {...props} setDeleteMembersApi={setDeleteMembersApi} />
      <Table
        rowKey="id"
        dataSource={members ?? []}
        pagination={false}
        columns={[
          {
            key: 'name',
            title: 'Name',
            dataIndex: 'firstName',
            render: (_value, user) => {
              if (!user) {
                return '--';
              }
              return `${user.firstName} ${user.lastName}`;
            },
          },
          {
            key: 'email',
            title: 'Email',
            dataIndex: 'email',
          },
        ]}
        rowSelection={
          canWriteUsers
            ? {
                selectedRowKeys: deleteMembersApi?.idsToRemove,
                onChange: deleteMembersApi ? (keys) => deleteMembersApi.setIdsToRemove(keys as any) : undefined,
              }
            : undefined
        }
      />
    </>
  );
}

export default UserGroupMembers;
