import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Form, Input, Modal, Radio, Select, Spin, Tooltip, message } from 'antd';
import { LabeledValue } from 'antd/es/select';
import { useClientContext } from 'components/client-context-provider';
import { FC, useEffect, useState } from 'react';
import { Cron } from 'react-js-cron';
import 'react-js-cron/dist/styles.css';
import {
  CreateReportScheduleMutation,
  CreateReportScheduleMutationVariables,
  GenerateReportMutation,
  GenerateReportMutationVariables,
  GetDashboardsForReportQuery,
  GetDashboardsForReportQueryVariables,
  GetReportScheduleQuery,
  GetReportScheduleQueryVariables,
  ReportType,
  UpdateReportScheduleMutation,
  UpdateReportScheduleMutationVariables,
} from 'types/graph-codegen/graph-types';
import { useAuthContext } from '../auth-context';
import { SortType } from '../data-explorer/data-explorer-table';
import InfiniteSelect, { NO_MORE_DATA, OnFetch } from '../nuspire/infinite-select/infinite-select';
import { GET_ACTIVE_REPORTS } from './active-reports';
import { GET_REPORTS } from './scheduled-reports';
import { CopyToClipboardIcon } from '../shared-components';
import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined';
import { emailListValidator } from 'utils/validator';
import { useTimezoneSelect, ITimezone, ITimezoneOption } from 'react-timezone-select';

const GET_DASHBOARDS = gql`
  query GetDashboardsForReport($clientId: String!, $queryString: String, $next: String) {
    dashboardSearch(clientId: $clientId, queryString: $queryString, next: $next) {
      next
      total
      items {
        id
        name
      }
    }
  }
`;

export const CREATE_REPORT_SCHEDULE = gql`
  mutation CreateReportSchedule($input: CreateReportScheduleInput!) {
    createReportSchedule(input: $input) {
      id
    }
  }
`;

export const GENERATE_REPORT = gql`
  mutation GenerateReport($input: GenerateReportInput!) {
    generateReport(input: $input) {
      id
    }
  }
`;

export const GET_REPORT = gql`
  query GetReportSchedule($clientId: String!, $id: String!) {
    reportSchedule(clientId: $clientId, id: $id) {
      cron
      timezone
      configuration
      dashboards {
        name
        id
      }
      description
      name
      recipients
      reportType
    }
  }
`;

const UPDATE_REPORT_SCHEDULE = gql`
  mutation UpdateReportSchedule($input: UpdateReportInput!) {
    updateReport(input: $input) {
      id
    }
  }
`;

export type ReportMode = 'once' | 'recurring';

export type ReportTypeOptions = {
  label: string;
  value: ReportType;
}[];

export const reportTypes: ReportTypeOptions = [
  { label: 'Case Management', value: 'caseManagement' as const },
  { label: 'Dashboard', value: 'dashboard' as const },
  { label: 'Data Explorer', value: 'dataExplorer' as const },
];

export interface ScheduledReportFormValues {
  cron?: string;
  timezone?: string;
  description?: string;
  mode: ReportMode;
  name: string;
  recipients?: string;
  reportType?: ReportType;

  // case management reports
  type?: string;
  template?: string;

  // dashboard reports
  dashboards?: LabeledValue[];
  dashboardIds?: string[];

  // data explorer reports
  dataTypeSlug?: string;
  url?: string;
}

export interface ScheduledReportModalProps {
  initialValues?: Partial<ScheduledReportFormValues>;
  isLoading?: boolean;
  isModalOpen: boolean;
  setIsModalOpen: (newValue: boolean) => void;
  onFinish?: (values: ScheduledReportFormValues) => void;
}

export function ScheduledReportModal(props: ScheduledReportModalProps) {
  const { initialValues, isLoading = false, isModalOpen, setIsModalOpen } = props;
  const { clientId } = useClientContext();
  const [form] = Form.useForm<ScheduledReportFormValues>();

  const [reportType, setReportType] = useState<ReportType | undefined>(initialValues?.reportType);
  const [mode, setMode] = useState<ReportMode>(initialValues?.mode ?? 'once');

  const { options: timezoneOptions, parseTimezone } = useTimezoneSelect({});
  const getDefaultTimezone = () => {
    return parseTimezone(initialValues?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone);
  };
  const [timezone, setTimezone] = useState<ITimezone>(getDefaultTimezone());

  // reload the form when the initial values change (i.e., changing which scheduled report we are editing)
  useEffect(() => {
    form.setFieldsValue(initialValues ?? {});
    setReportType(initialValues?.reportType);
    setTimezone(getDefaultTimezone());
  }, [initialValues]);

  const onFinish = (values: ScheduledReportFormValues) => {
    const mergedValues = {
      ...values,
      timezone: (timezone as ITimezoneOption).value ?? timezone,
    };

    props.onFinish?.({
      ...mergedValues,
      dashboardIds: values.dashboards?.map((v) => v.value as string),
    });

    props.setIsModalOpen?.(false);
  };

  let reportTypeOptions = reportTypes;
  // must remove data explorer from options if this is not a data explorer report
  if (initialValues?.dataTypeSlug === undefined) {
    reportTypeOptions = reportTypes.filter((rt) => rt.value !== 'dataExplorer');
  }

  const DashboardReportFormItems: FC<{ clientId?: string | null }> = (props) => {
    const [nextDashboardsToken, setNextDashboardsToken] = useState<string>();
    const [queryDashboards] = useLazyQuery<GetDashboardsForReportQuery, GetDashboardsForReportQueryVariables>(
      GET_DASHBOARDS,
    );

    const fetchDashboards: OnFetch = async (searchQuery, reset) => {
      const currNext = reset ? undefined : nextDashboardsToken;

      const { data } = await queryDashboards({
        variables: {
          clientId: props.clientId!,
          next: currNext,
          queryString: searchQuery,
        },
      });

      const { items, next } = data?.dashboardSearch ?? {};

      if (!items) {
        return Promise.reject(NO_MORE_DATA);
      }

      setNextDashboardsToken(next);

      return {
        selectOptions: items
          .filter((v) => v !== undefined)
          .map((v) => ({
            value: v!.id,
            label: v!.name,
          })),
      };
    };

    return (
      <Form.Item name="dashboards" label="Dashboard(s)" rules={[{ required: true }]}>
        <InfiniteSelect onFetch={fetchDashboards} mode="multiple" labelInValue allowClear showSearch />
      </Form.Item>
    );
  };

  const CaseManagementReportFormItems: FC<{}> = (_props) => {
    return (
      <>
        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
          <Select
            options={[
              { label: 'Cases', value: 'cases' },
              { label: 'Incidents', value: 'incidents' },
            ]}
          />
        </Form.Item>
        <Form.Item name="template" label="Report Template" rules={[{ required: true }]}>
          <Select options={[{ label: 'Opened', value: 'opened' }]} />
        </Form.Item>
      </>
    );
  };

  const DataExplorerReportFormItems: FC<{}> = (_props) => {
    const { url } = initialValues || {};

    return (
      <>
        <ul>
          <li>You may export up to 30 days of data, or 100k search results.</li>
          <li>Results are based on the filters you currently have set.</li>
          <li>
            The data export function runs as a background task. You should receive the CSV file via an email attachment
            within 10-15 minutes of the scheduled time. If you do not see it check your spam folder for an email from
            no-reply@mynuspire.io.
          </li>
        </ul>
        {url && (
          <Form.Item name="url">
            <Tooltip className="pull-right" placement="left" title="A url to the live Data Explorer search results.">
              <Button size="large" type="text" shape="circle" icon={<QuestionCircleOutlined />} />
            </Tooltip>
            <span style={{ marginRight: '0.5rem' }} className="url-title">
              Shareable Url
            </span>
            <CopyToClipboardIcon altTooltip="Copy to the clipboard." copyText={url} />
          </Form.Item>
        )}
      </>
    );
  };

  return (
    <Modal
      title="Scheduled Report Settings"
      footer={
        <Button key="submit" type="primary" onClick={form.submit}>
          Save
        </Button>
      }
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      destroyOnClose
      width={800}
    >
      <Spin spinning={isLoading}>
        <Form form={form} initialValues={initialValues} layout="vertical" onFinish={onFinish}>
          <Form.Item name="dataTypeSlug" label="Data Type" style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="reportType" label="Report Type" rules={[{ required: true }]}>
            <Select
              onSelect={(newReportType: ReportType) => {
                setReportType(newReportType);
              }}
              options={reportTypeOptions}
            />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea value={initialValues?.description ?? ''} />
          </Form.Item>
          {reportType === 'dashboard' && <DashboardReportFormItems clientId={clientId} />}
          {reportType === 'caseManagement' && <CaseManagementReportFormItems />}
          {reportType === 'dataExplorer' && <DataExplorerReportFormItems />}
          <Form.Item name="mode" label="Schedule">
            <Radio.Group value={mode} onChange={(e) => setMode(e.target.value)}>
              <Radio value="once">Now</Radio>
              <Radio value="recurring">Later</Radio>
            </Radio.Group>
          </Form.Item>
          {mode === 'recurring' && (
            <>
              <Form.Item name="cron">
                <Cron
                  value={form.getFieldValue('cron')}
                  setValue={(v: string) => {
                    form.setFieldValue('cron', v);
                  }}
                  allowedPeriods={['month', 'week', 'day']}
                  defaultPeriod="month"
                  mode="single"
                  clearButton={false}
                  leadingZero
                />
              </Form.Item>
              <Form.Item label="Time Zone">
                <Select
                  value={timezone}
                  onSelect={setTimezone}
                  options={timezoneOptions}
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                  showSearch
                />
              </Form.Item>
            </>
          )}
          <Form.Item
            name="recipients"
            label="Recipients"
            tooltip="Comma separated emails"
            rules={[
              {
                validator: (_rule, value) => emailListValidator(value),
              },
            ]}
          >
            <Input placeholder="john.smith@example.com, jane.doe@example.com" />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

export interface UpdateScheduledReportModalProps extends ScheduledReportModalProps {
  scheduleId: string;
}

export function UpdateScheduledReportModal(props: UpdateScheduledReportModalProps) {
  const { initialValues, scheduleId, isLoading = false, isModalOpen, setIsModalOpen } = props;
  const { clientId } = useClientContext();

  const [filters, setFilters] = useState<any>(undefined);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [sort, setSort] = useState<string | undefined>(undefined);
  const [sortBy, setSortBy] = useState<string | undefined>(undefined);

  const { data } = useQuery<GetReportScheduleQuery, GetReportScheduleQueryVariables>(GET_REPORT, {
    variables: {
      clientId: clientId!,
      id: scheduleId,
    },
    skip: scheduleId === '',
  });

  const [updateReport] = useMutation<UpdateReportScheduleMutation, UpdateReportScheduleMutationVariables>(
    UPDATE_REPORT_SCHEDULE,
    {
      refetchQueries: [
        { query: GET_REPORTS, variables: { clientId: clientId! } },
        { query: GET_ACTIVE_REPORTS, variables: { clientId: clientId! } },
      ],
    },
  );

  const reportSchedule = data?.reportSchedule;
  let reportType: ReportType | undefined = undefined;

  if (reportSchedule?.reportType) {
    reportType = reportSchedule.reportType;
  } else if (reportSchedule?.dashboards) {
    reportType = 'dashboard';
  }

  useEffect(() => {
    console.log('Switching report...', {
      scheduleId,
      ...reportSchedule,
    });

    // track these items in state, as they are not exposed on the form
    setFilters(reportSchedule?.configuration?.filters);
    setStartDate(reportSchedule?.configuration?.from);
    setEndDate(reportSchedule?.configuration?.to);
    setSort(reportSchedule?.configuration?.sort);
    setSortBy(reportSchedule?.configuration?.sortBy);

    // always empty
    console.log('After switching...', {
      filters: filters,
      startDate: startDate,
      endDate: endDate,
      sort: sort,
      sortBy: sortBy,
      configuration: reportSchedule?.configuration,
    });
  }, [reportSchedule]);

  useEffect(() => {
    console.log('After switching...', {
      filters: filters,
      startDate: startDate,
      endDate: endDate,
      sort: sort,
      sortBy: sortBy,
      configuration: reportSchedule?.configuration,
    });
  }, [filters, startDate, endDate, sort, sortBy]);

  const onFinish = async (values: ScheduledReportFormValues) => {
    if (!values.reportType) {
      message.error('No report type selected. Cannot update schedule.');
      return;
    }

    props.onFinish?.(values);

    const recipients: string[] = values.recipients?.split(',') ?? [];
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const input = {
      clientId: clientId!,
      cron: values.cron,
      description: values.description,
      id: scheduleId,
      name: values.name,
      recipients,
      reportConfig: {
        // case management reports
        template: values.template,
        type: values.type,

        // dashboard reports
        dashboardIds: values.dashboardIds,

        // data explorer reports
        dataTypeSlug: values.dataTypeSlug,
        filters,
        from: startDate,
        to: endDate,
        sort,
        sortBy,
      },
      reportType: values.reportType,
      timezone,
    };

    console.log('Updating report...', { ...input });

    // todo: what happens if you edit and save a data explorer report?
    // i'm guessing we will need to pull those values out of the config and re-apply them here for it to work
    // otherwise i suspect it will just wipe those out and break it on save --mark
    await updateReport({
      variables: {
        input,
      },
    });

    message.success('Your report has been updated.');
  };

  return (
    <ScheduledReportModal
      initialValues={{
        cron: reportSchedule?.cron ?? '00 00 1 * *',
        timezone: reportSchedule?.timezone,
        dashboards: reportSchedule?.dashboards.map((d) => ({
          label: d.name,
          value: d.id,
        })),
        description: reportSchedule?.description,
        name: reportSchedule?.name ?? '',
        mode: 'recurring',
        recipients: reportSchedule?.recipients.join(',') ?? '',
        reportType,
        ...initialValues,
      }}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      onFinish={onFinish}
      setIsModalOpen={setIsModalOpen}
    />
  );
}

export interface NewScheduledReportModalProps extends ScheduledReportModalProps {
  filters?: any;
  startDate?: string;
  endDate?: string;
  sort?: SortType;
  sortBy?: string;
}

export function NewScheduledReportModal(props: NewScheduledReportModalProps) {
  const { initialValues, isLoading, isModalOpen, setIsModalOpen } = props;
  const { clientId, client } = useClientContext();
  const { user } = useAuthContext();

  const [scheduleReport] = useMutation<CreateReportScheduleMutation, CreateReportScheduleMutationVariables>(
    CREATE_REPORT_SCHEDULE,
    {
      refetchQueries: [
        { query: GET_REPORTS, variables: { clientId: clientId! } },
        { query: GET_ACTIVE_REPORTS, variables: { clientId: clientId! } },
        'ScheduledReportsTable',
      ],
    },
  );

  const [runReport] = useMutation<GenerateReportMutation, GenerateReportMutationVariables>(GENERATE_REPORT, {
    refetchQueries: [
      { query: GET_REPORTS, variables: { clientId: clientId! } },
      { query: GET_ACTIVE_REPORTS, variables: { clientId: clientId! } },
    ],
  });

  const onFinish = async (values: ScheduledReportFormValues) => {
    if (!values.reportType) {
      message.error(`No report type selected. Cannot ${values.mode === 'recurring' ? 'schedule' : 'generate'} report.`);
      return;
    }

    props.onFinish?.(values);

    const recipients: string[] = values.recipients?.split(',') ?? [];

    const commonInputs = {
      clientId: clientId!,
      description: values.description,
      name: values.name,
      recipients,
      reportConfig: {
        // case management reports
        template: values.template,
        type: values.type,

        // data explorer reports
        dataTypeSlug: values.dataTypeSlug,
        filters: props.filters,
        from: props.startDate,
        to: props.endDate,
        sort: props.sort,
        sortBy: props.sortBy,
        url: values.url,

        // dashboard reports
        dashboardIds: values.dashboardIds,
      },
      reportType: values.reportType,
    };

    // now
    if (values.mode === 'once') {
      const input = {
        ...commonInputs,
      };

      console.log('Running report...', { ...input });
      await runReport({
        variables: {
          input,
        },
      });

      // later (scheduled)
    } else if (values.mode === 'recurring') {
      const input = {
        ...commonInputs,
        cron: values.cron!,
        enabled: true,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      console.log('Scheduling report...', { ...input });
      await scheduleReport({
        variables: {
          input,
        },
      });
    }

    message.success('Your report has been scheduled.');
  };

  return (
    <ScheduledReportModal
      // forceRender
      onFinish={onFinish}
      initialValues={{
        cron: '00 00 1 * *',
        mode: 'once',
        name: `${client?.name} report for ${new Date().toLocaleDateString()}`,
        recipients: user?.email || '',
        ...initialValues,
      }}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    />
  );
}
