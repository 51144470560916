import { type ApolloError } from '@apollo/client';
import { adminPages } from 'components/admin/admin-dashboard';
import { useClientContext } from 'components/client-context-provider';
import { useCallback } from 'react';
import {
  Access,
  type IAuthorizationResponse,
  type IPermissions,
  type IUser,
  type IUserAuthorizationRequest,
} from '../types';
import { useAuthContext } from './auth-context';

/**
 * Most of the time we'll be authorizing from within the context of a client.
 * - So `authorize` is made available via the `useClientContext` hook.
 * - But there might be circumstances where we pass effective permissions to `useAuthorize` outside of a client context.
 * - This hook can be imported directly in those cases (ie: user profile);
 */
export function useAuthorize(props: IUserAuthorizationRequest) {
  const { user } = useAuthContext();
  const { effectivePermissions } = props;

  return useCallback(
    (args: { requiredPermissions: IPermissions }): IAuthorizationResponse => {
      const { requiredPermissions } = args;

      return checkPermissions({
        user,
        requiredPermissions,
        effectivePermissions,
      });
    },
    [user, effectivePermissions],
  );
}

export type AuthorizeFunc = ReturnType<typeof useAuthorize>;

type UseAdminPageData = {
  canViewAdmin: boolean;
  isNuspireUser: boolean;
  user?: IUser;
  userError?: ApolloError;
};

export function useAdminPage(): UseAdminPageData {
  const { isNuspireUser, error, user } = useAuthContext();
  const { authorize } = useClientContext();

  const hasAtLeastPartialAdminPageVisibility = adminPages.some(
    (page) => page.canViewPage({ authorize, isNuspireUser, user }).authorized,
  );
  const canViewAdmin = !!user?.isSuperUser || hasAtLeastPartialAdminPageVisibility;

  return {
    canViewAdmin: isNuspireUser && canViewAdmin,
    isNuspireUser,
    user,
    userError: error,
  };
}

export function checkPermissions(props: IUserAuthorizationRequest): IAuthorizationResponse {
  const { user, requiredPermissions, effectivePermissions } = props;

  if (user?.isSuperUser) {
    return {
      authorized: true,
    };
  }

  if (requiredPermissions) {
    if (!effectivePermissions) {
      return {
        authorized: false,
        message: `Effective permissions for this client could not be found.`,
      };
    }

    const failedPermissions = Object.keys(requiredPermissions).reduce((acc: IPermissions, key) => {
      const required = requiredPermissions[key];
      const access = effectivePermissions[key];

      if (!checkAccess({ access, required })) {
        acc[key] = required;
      }

      return acc;
    }, {});

    const failedKeys = Object.keys(failedPermissions);
    if (failedKeys.length) {
      return {
        authorized: false,
        message: `Effective Permissions not met for this client: ${failedKeys.join(', ')}`,
      };
    }
  }

  return {
    authorized: true,
  };
}

function checkAccess(args: { access: Access; required: Access }): boolean {
  const { access, required } = args;

  if (required === Access.none && access !== Access.none) return false;

  return (access & required) === required;
}
