import { useEffect } from 'react';
import { Select } from 'antd';
import { ActionInputProps } from '../../action-form-field';
import { gql, useQuery } from '@apollo/client';
import { NuButton } from 'components/nuspire';
import * as NuIcon from 'components/nuspire/nu-icon';
import styled from 'styled-components';
import { useConnector } from 'components/connections/connectors';
import ConnectionDisplay from './connection-display';
import { ClientIdentifierType, ConnectorConnectionsQuery } from 'types/graph-codegen/graph-types';

const DdRoot = styled.div``;
const DdMenu = styled.div`
  margin-bottom: 16px;
`;
const DdFooter = styled.div`
  padding: 8px;
`;
const CONNECTOR_CONNECTIONS = gql`
  query ConnectorConnections($clientId: String!, $slug: String!) {
    getClientById(id: $clientId) {
      id
      clientIdentifiers {
        type
        value
      }
    }
    systemConnector(slug: $slug) {
      id
      name
      clientConnections(clientId: $clientId) {
        id
        name
      }
    }
  }
`;

function ConnectionInput(props: ActionInputProps) {
  const { name, clientId, value, onChange, onBlur, field, mode = 'edit', formik } = props;
  const connectorSlug = field?.parameters?.connectorSlug ?? null;
  const sharedClientIdentifiers = field?.parameters?.sharedClientIdentifiers as undefined | ClientIdentifierType[];
  const { data, loading, refetch } = useQuery<ConnectorConnectionsQuery>(CONNECTOR_CONNECTIONS, {
    variables: { clientId, slug: connectorSlug },
  });

  const connectorName = data?.systemConnector?.name;
  const connections = data?.systemConnector?.clientConnections;
  const clientIdentifiers = data?.getClientById?.clientIdentifiers ?? [];

  // we could default to first connection if only one...
  const connector = useConnector(connectorSlug ?? '');

  useEffect(() => {
    const updateEventName = `new-connection-${connectorSlug}`;

    // register event listener to know when connection is complete.
    const handleUpdateEvent = (e: any) => {
      const newConnection = e?.detail?.connection ?? null;

      if (newConnection) {
        onChange({
          id: newConnection.id,
          name: newConnection.name,
        });
      }

      refetch();
    };

    window.addEventListener(updateEventName, handleUpdateEvent);

    return () => {
      window.removeEventListener(updateEventName, handleUpdateEvent);
    };
  }, []);

  const options = connections ? connections.slice() : value ? [value] : [];

  // Let's try adding a client identifier option when relevant.
  if (sharedClientIdentifiers?.length) {
    const hasClientIdentifiers = Boolean(
      sharedClientIdentifiers.find((ci) => clientIdentifiers.find((c) => c.type === ci)),
    );

    if (hasClientIdentifiers) {
      options.push({
        id: `$client-identifier`,
        name: `Shared ${connectorName ? `${connectorName} ` : ''}Connection from Nuspire MSSP`,
      });
    }
  }

  useEffect(() => {
    if (value || mode !== 'edit') {
      return;
    }

    if (options.length === 1) {
      onChange(options[0]);
    }
  }, [options]);

  if (value?.id && mode === 'display') {
    return <ConnectionDisplay connectorSlug={connectorSlug} id={value.id} name={value.name} />;
  }

  return (
    <Select
      style={{ width: '100%' }}
      loading={!data && loading}
      dropdownMatchSelectWidth
      value={value?.id}
      onChange={(selectedId) => {
        const selectedOption = options.find((o: any) => o.id === selectedId);

        onChange(selectedOption);
      }}
      placeholder="Select Connection"
      onBlur={(e) => {
        formik?.setFieldTouched(name, true);
      }}
      dropdownRender={(menu) => {
        return (
          <DdRoot>
            <DdMenu>{menu}</DdMenu>

            {connector?.handleAddConnection !== undefined && (
              <DdFooter>
                <NuButton
                  style={{ width: '100%' }}
                  icon={<NuIcon.Plus />}
                  onClick={() => {
                    if (connector.handleAddConnection) {
                      connector.handleAddConnection({ clientId });
                    }
                  }}
                >
                  Add Connection
                </NuButton>
              </DdFooter>
            )}
          </DdRoot>
        );
      }}
    >
      {options.map((c: any) => {
        return (
          <Select.Option key={c.id} value={c.id}>
            {c.name}
          </Select.Option>
        );
      })}
    </Select>
  );
}

export default ConnectionInput;
