import { ExclamationCircleOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { App, Form, message } from 'antd';
import { useAuthorize } from 'components/authorization';
import { useClientContext } from 'components/client-context-provider';
import { ActionInputProps, TextInput } from 'components/cyberx/actions/action-form/action-form-field/action-form-field';
import { NuButton, NuCard, NuCardContent, NuCardTitle } from 'components/nuspire';
import InlineInput from 'components/nuspire/form/inline-input';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { ClientIdentifiersFieldArray, clientIdentifiersList } from 'components/organization/add-client';
import { P } from 'components/shared-components';
import { useState } from 'react';
import baseTheme from 'components/theme';
import { Access } from 'types';
import {
  ClientIdentifier,
  ClientIdentifiersQuery,
  ClientIdentifiersQueryVariables,
  UpdateClientIdentifiersMutation,
  UpdateClientIdentifiersMutationVariables,
} from 'types/graph-codegen/graph-types';
import { useClientIdentifier } from 'utils/react-hooks/use-client-identifier';
import SearchServiceNowAccountsModal from './search-servicenow-accounts-modal';
import * as yup from 'yup';

const CLIENT_IDENTIFIERS = gql`
  query ClientIdentifiers($clientId: String!) {
    getClientById(id: $clientId) {
      id
      name
      clientIdentifiers {
        type
        value
      }
    }
  }
`;

type CommonProps = {
  clientId: string;
  clientName?: string;
  onUpdateComplete?: () => void;
};

const UPDATE_CLIENT_IDENTIFIERS = gql`
  mutation UpdateClientIdentifiers($input: UpdateClientInput, $force: Boolean) {
    updateClient(input: $input, force: $force) {
      id
      clientIdentifiers {
        type
        value
      }
    }
  }
`;

const clientIdentifiersValidationSchema = yup.object().shape({
  clientIdentifiers: yup.array().of(
    yup.object().shape({
      type: yup.string().required('Please select a type'),
      value: yup.string().required('Please enter a value'),
    }),
  ),
});

export function ClientIdentifiersInlineInput(props: {
  clientId: string;
  clientIdentifiers?: ClientIdentifier[];
  clientName?: string;
  disabled?: boolean;
  onUpdateComplete?: () => void;
}) {
  const { clientId, clientIdentifiers = [], clientName, disabled, onUpdateComplete } = props;
  const [updateClient] = useMutation<UpdateClientIdentifiersMutation, UpdateClientIdentifiersMutationVariables>(
    UPDATE_CLIENT_IDENTIFIERS,
  );
  const fieldName = 'clientIdentifiers';
  const { modal } = App.useApp();

  const { client: authClient } = useClientContext();
  const effectivePermissions = authClient?.effectivePermissions;
  const authorize = useAuthorize({ effectivePermissions });
  const { authorized: canWriteAllClients } = authorize({ requiredPermissions: { allClients: Access.write } });

  
	
  return (
    <InlineInput
	
	canEdit={canWriteAllClients}
      readView={
        <ul style={{ marginBottom: 0, paddingLeft: '1rem' }}>
          {clientIdentifiers?.map((identifier) => {
            const idItem = clientIdentifiersList.find((id) => id.type === identifier.type);

            return (
              <li key={identifier.type} style={{ maxWidth: '700px', overflowWrap: 'break-word' }}>
                {idItem ? `(${idItem.label}) ` : null}
                {identifier.value}
              </li>
            );
          })}
        </ul>
      }
      initialValues={{
        [fieldName]: clientIdentifiers ?? [],
      }}
      editView={({ formikProps }) => {
        return (
          <ClientIdentifiersFieldArray
            clientId={clientId}
            clientName={clientName}
            fieldName={fieldName}
            identifiers={formikProps.values[fieldName]}
            onChange={(newIdentifiers) => {
              formikProps.setFieldValue(fieldName, newIdentifiers);
            }}
            errors={formikProps.errors}
          />
        );
      }}
      validationSchema={clientIdentifiersValidationSchema}
      validateOnChange={false}
      onFinish={async ({ values: { clientIdentifiers: newClientIdentifiers } }) => {
        const input: UpdateClientIdentifiersMutationVariables['input'] = {
          id: clientId,
          clientIdentifiers: newClientIdentifiers,
        };

        const { errors } = await updateClient({ variables: { input } });
        const isDuplicateSnowErrorMsg = (error: Error) =>
          error.message.toLowerCase() === 'a client with this servicenow account id already exists';

        if (errors) {
          const error = errors.at(0);

          if (error && isDuplicateSnowErrorMsg(error)) {
            const snowId =
              useClientIdentifier({
                clientIdentifiers: newClientIdentifiers,
                type: 'serviceNowAccountId',
              })?.value ?? '';

            modal.confirm({
              title: 'A client with this ServiceNow account ID already exists',
              icon: <ExclamationCircleOutlined />,
              content: (
                <>
                  <P>Do you still want to link this client with this ServiceNow account?</P>
                  <P>
                    You can review the clients that have this ServiceNow account ID{' '}
                    <a
                      href={`/admin/clients?search=${snowId}`}
                      target="_blank"
                      style={{ color: baseTheme.color.primaryBlue }}
                    >
                      here
                    </a>
                    .
                  </P>
                </>
              ),
              okText: 'Yes',
              okType: 'primary',
              cancelText: 'No',
              async onOk() {
                try {
                  await updateClient({
                    variables: {
                      input,
                      force: true,
                    },
                  });

                  message.success('Client updated');
                  onUpdateComplete?.();
                } catch (err) {
                  console.error(err);
                  message.error('An error occurred while attempting to update client identifiers');
                }
              },
              width: 600,
            });
          }
        }

        message.success('Client updated');
        onUpdateComplete?.();
      }}
      closeOnOutsideClick={false}
	  useEditButton
	  
    />
  );
}

function ClientIdentifiersView(props: CommonProps & { clientIdentifiers?: ClientIdentifier[] }) {
  return (
    <NuCard
      styles={{
        root: {
          maxWidth: '1200px',
        },
      }}
    >
      <NuCardTitle title="Client Identifiers" />
      <NuCardContent
        style={{
          paddingBottom: '40px',
        }}
      >
        <Form layout="vertical">
          <Form.Item>
            <ClientIdentifiersInlineInput {...props} />
          </Form.Item>
        </Form>
      </NuCardContent>
    </NuCard>
  );
}

export function ClientIdentifiers(props: CommonProps) {
  const { data, loading } = useQuery<ClientIdentifiersQuery, ClientIdentifiersQueryVariables>(CLIENT_IDENTIFIERS, {
    variables: {
      clientId: props.clientId,
    },
  });
  const { clientIdentifiers = [] } = data?.getClientById ?? {};
  const { client } = useClientContext();
  const authorize = useAuthorize({ effectivePermissions: client?.effectivePermissions });
  const canReadAllClients = authorize({
    requiredPermissions: {
      allClients: Access.read,
    },
  }).authorized;

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  if (!canReadAllClients) {
    return null;
  }

  return (
	<ClientIdentifiersView {...props} clientName={data?.getClientById?.name} clientIdentifiers={clientIdentifiers} />
  );

	
}

export function ServiceNowAccountIdClientIdentifierInput(props: ActionInputProps) {
  const { clientName } = props;
  const [isSearchModalVisible, setIsSearchModalVisible] = useState(false);
  const { client } = useClientContext();
  const authorize = useAuthorize({ effectivePermissions: client?.effectivePermissions });
  const canReadAllClients = authorize({
    requiredPermissions: {
      allClients: Access.read,
    },
  }).authorized;

  if (!canReadAllClients) {
    return <TextInput {...props} />;
  }

  return (
    <>
      <TextInput {...props} />
      <NuButton type="primary" style={{ marginLeft: `1rem` }} onClick={() => setIsSearchModalVisible(true)}>
        Search ServiceNow
      </NuButton>
      <SearchServiceNowAccountsModal
        defaultSearchValue={clientName?.toLowerCase() ?? ''}
        isVisible={isSearchModalVisible}
        setIsVisible={setIsSearchModalVisible}
        updateServiceNowId={props.onChange}
      />
    </>
  );
}
