import { gql, useMutation } from '@apollo/client';
import { App, message } from 'antd';
import { NuButton } from 'components/nuspire';

const DELETE_COMMENT = gql`
  mutation DeleteComment($id: String!) {
    deleteTaskComment(id: $id) {
      id
      text
      type
      ok
      user {
        id
        firstName
        lastName
        email
      }
      data
      createdAt
    }
  }
`;

const READ_QUERY = gql`
  query ReadTaskActivityDeleted($id: String!) {
    clientTask(id: $id) {
      id
      activity {
        id
        text
        type
        ok
        user {
          id
          firstName
          lastName
          email
        }
        data
        createdAt
      }
    }
  }
`;

export function DeleteTaskCommentButton(props: { id: string; taskId: string }) {
  const { id, taskId } = props;
  const { modal } = App.useApp();

  const [mutate, { loading }] = useMutation(DELETE_COMMENT, {
    update: (cache, { data: { deleteTaskComment } }) => {
      const cachedData = cache.readQuery({
        variables: { id: taskId },
        query: READ_QUERY,
      }) as any;

      if (typeof cachedData === 'object' && cachedData !== null) {
        const updatedData = {
          clientTask: {
            ...cachedData.clientTask,
            activity: [
              deleteTaskComment, // record of deleted task
              ...cachedData.clientTask.activity.filter((a) => a.id !== id), // filter deleted comment out of cache.
            ],
          },
        };

        cache.writeQuery({
          query: READ_QUERY,
          data: updatedData,
          variables: { id: taskId },
        });
      }
    },
  });

  const handleDelete = async () => {
    try {
      await mutate({
        variables: {
          id,
        },
      });
    } catch (err: any) {
      message.error('Failed to delete comment. Please contact support');
    }
  };

  const handleClick = () => {
    modal.confirm({
      title: 'Delete Comment?',
      content: 'Once Deleted, comment will be gone for good.',
      okText: 'Delete',
      okButtonProps: {
        danger: true,
      },
      onOk: handleDelete,
    });
  };

  return (
    <NuButton size="small" type="text" onClick={handleClick}>
      Delete
    </NuButton>
  );
}
