
export default () => (
  <svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.64 149.43">
    <title>LOGO-Web-Owl-Full</title>
    <g id="Layer_3" data-name="Layer 3">
      <path
        style={{ fill: `#fff` }}
        d="M163.33,140l-.05.1.09-.06c9.29-6.07,19.32-8,30.08-8.33,1.21,0,2.42,0,3.64,0,23.33,0,42.24-18.41,42.24-41.12a40.08,40.08,0,0,0-5.9-21,41.87,41.87,0,0,0-8.64-10.08c-1-.87-2.12-1.7-3.24-2.48A334.91,334.91,0,0,0,184,102.59q-1.94,2.86-3.8,5.78c-3.07,4.82-6,9.76-8.73,14.82C168.47,128.66,165.75,134.27,163.33,140Zm12.44-13.7.12-.19,8.27-12.16,4.13-6.08,9.81-14.43L214.59,104,208,83.14l.76-1.08,9.17-12.74c.55-1.22,22,19.38,3.9,40.91-5.84,7-13.61,8.9-22.16,9.72-1,.1-2,.17-3,.24-2.59.17-5.32.32-8,.68-4.75.63-9.34,1.94-12.65,5.26C175.88,126.18,175.82,126.23,175.77,126.29Z"
        transform="translate(-44.34 -19.28)"
      />
      <path
        style={{ fill: `#24272a` }}
        d="M184.16,113.94l4.13-6.08,9.81-14.43L214.59,104,208,83.14l.76-1.08,9.17-12.74c.55-1.22,22,19.38,3.9,40.91-5.84,7-13.61,8.9-22.16,9.72-1,.1-2,.17-3,.24-2.59.17-5.32.32-8,.68-4.75.63-9.34,1.94-12.65,5.26-.05.05-.11.1-.16.16l.12-.19Z"
        transform="translate(-44.34 -19.28)"
      />
      <path
        style={{ fill: `#005677` }}
        d="M228.69,49.86A148.47,148.47,0,0,1,264,19.28c-2.53,3.09-19.64,22.29-27.4,37.78A55.17,55.17,0,0,0,228.69,49.86Z"
        transform="translate(-44.34 -19.28)"
      />
      <path
        style={{ fill: `#fff` }}
        d="M136.9,123.19c-2.72-5.06-5.65-10-8.72-14.82-1.25-1.94-2.51-3.87-3.8-5.78A335.65,335.65,0,0,0,86.78,57c-1.12.78-2.2,1.61-3.24,2.48a41.69,41.69,0,0,0-8.65,10.08,40.17,40.17,0,0,0-5.9,21c0,22.71,18.91,41.12,42.24,41.12,1.23,0,2.44,0,3.64,0C125.64,132,135.66,134,145,140l.09.06,0-.1C142.58,134.27,139.86,128.66,136.9,123.19Zm-4.51,2.94c-3.31-3.32-7.89-4.63-12.65-5.26-2.68-.36-5.41-.51-8-.68-1-.07-2.07-.14-3-.24-8.56-.82-16.33-2.75-22.17-9.72C68.47,88.7,89.88,68.1,90.44,69.32l9.17,12.74.76,1.08L93.73,104l16.5-10.53,9.8,14.43,4.14,6.08,8.26,12.16.13.19Z"
        transform="translate(-44.34 -19.28)"
      />
      <path
        style={{ fill: `#24272a` }}
        d="M124.17,113.94,120,107.86l-9.8-14.43L93.73,104l6.64-20.82-.76-1.08L90.44,69.32c-.56-1.22-22,19.38-3.91,40.91,5.84,7,13.61,8.9,22.17,9.72,1,.1,2,.17,3,.24,2.58.17,5.31.32,8,.68,4.76.63,9.34,1.94,12.65,5.26l.17.16-.13-.19Z"
        transform="translate(-44.34 -19.28)"
      />
      <path
        style={{ fill: `#005677` }}
        d="M79.64,49.86a148.2,148.2,0,0,0-35.3-30.58C46.88,22.37,64,41.57,71.75,57.06A54.64,54.64,0,0,1,79.64,49.86Z"
        transform="translate(-44.34 -19.28)"
      />
      <path
        style={{ fill: `#009abf` }}
        d="M264,19.28h0l-.06,0a413.84,413.84,0,0,1-109.74,15,413.77,413.77,0,0,1-109.73-15l-.06,0h0l.06,0A366.84,366.84,0,0,1,79.64,49.86a54.64,54.64,0,0,0-7.89,7.2,48.35,48.35,0,0,0,0,65.39,56.42,56.42,0,0,0,31.38,17.47A98.45,98.45,0,0,0,115,141.24c6.23.36,12.39.15,18.33,1.74a31.69,31.69,0,0,1,12.5,7.21,26.76,26.76,0,0,1,3.5,4.36,29.48,29.48,0,0,1,1.89,3.17l.29.68c.16.49.66,2,.93,2.9.65,2.46,1.25,4.93,1.77,7.42q.79-3.74,1.78-7.42c.27-.88.77-2.41.92-2.9l.3-.68a29.44,29.44,0,0,1,1.88-3.17,27.3,27.3,0,0,1,3.5-4.36A31.84,31.84,0,0,1,175,143c6-1.59,12.1-1.38,18.33-1.74a98,98,0,0,0,11.86-1.32,56.42,56.42,0,0,0,31.39-17.47,48.35,48.35,0,0,0,0-65.39,55.17,55.17,0,0,0-7.89-7.2,365.44,365.44,0,0,1,35.24-30.54ZM145,140c-9.29-6.07-19.31-8-30.08-8.33-1.2,0-2.41,0-3.64,0C87.9,131.65,69,113.24,69,90.53a40.17,40.17,0,0,1,5.9-21,41.69,41.69,0,0,1,8.65-10.08c1-.87,2.12-1.7,3.24-2.48a335.65,335.65,0,0,1,37.6,45.59c1.29,1.91,2.55,3.84,3.8,5.78,3.07,4.82,6,9.76,8.72,14.82,3,5.47,5.68,11.08,8.1,16.8l0,.1Zm88.48-70.47a40.08,40.08,0,0,1,5.9,21c0,22.71-18.91,41.12-42.24,41.12-1.22,0-2.43,0-3.64,0-10.76.33-20.79,2.26-30.08,8.33l-.09.06.05-.1c2.42-5.72,5.14-11.33,8.09-16.8,2.73-5.06,5.66-10,8.73-14.82q1.86-2.91,3.8-5.78A334.91,334.91,0,0,1,221.55,57c1.12.78,2.2,1.61,3.24,2.48A41.87,41.87,0,0,1,233.43,69.56Z"
        transform="translate(-44.34 -19.28)"
      />
    </g>
  </svg>
);
