import { TreeSelect } from 'antd';
import { useAuthContext } from 'components/auth-context';
import { ClientNode, ClientTree } from './client-tree-view';

interface TreeSelectOptionData {
  title: string;
  value: string;
  children?: TreeSelectOptionData[];
  disabled?: boolean;
}

type IsOptionDisabledFunc = (
  clientNode: ClientNode,
  args: {
    parentOption?: TreeSelectOptionData;
  },
) => boolean;

function buildClientTreeSelectOptions(
  tree: ClientTree,
  args: {
    isOptionDisabled?: IsOptionDisabledFunc;
    parentOption?: TreeSelectOptionData;
  },
) {
  const { isOptionDisabled, parentOption } = args;
  return tree.reduce((acc: TreeSelectOptionData[], node) => {
    const { id, name, children } = node;

    const option: TreeSelectOptionData = {
      title: name,
      value: id,
    };

    if (isOptionDisabled) {
      option.disabled = isOptionDisabled(node, { parentOption });
    }

    if (children && Object.keys(children).length) {
      const optionChildren = buildClientTreeSelectOptions(children, { ...args, parentOption: option });

      if (children.length) {
        option.children = optionChildren;
      }
    }

    acc.push(option);

    return acc;
  }, []);
}

function ClientTreeSelect(props: {
  value: string | null;
  onChange: (selectedId: string) => void;
  isOptionDisabled?: IsOptionDisabledFunc;
  tree?: ClientTree | null;
  defaultOpen?: boolean;
}) {
  const { value, onChange, isOptionDisabled, defaultOpen = false } = props;
  const { user } = useAuthContext();
  const clientTree = props.tree ?? user?.client?.clientTree ?? [];
  const treeOptions = buildClientTreeSelectOptions(clientTree, {
    isOptionDisabled,
  });

  return (
    <TreeSelect
      style={{ width: '100%' }}
      value={value ?? undefined}
      dropdownStyle={{
        maxHeight: 400,
        overflow: 'auto',
      }}
      treeData={treeOptions}
      placeholder="Select Client"
      treeDefaultExpandAll
      onChange={onChange}
      defaultOpen={defaultOpen}
    />
  );
}

export default ClientTreeSelect;
