import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Form as AntForm, Input, message, Select, Tooltip } from 'antd';
import { useAuthContext } from 'components/auth-context';
import { useClientContext } from 'components/client-context-provider';
import { getInput } from 'components/cyberx/actions/action-form/action-form-field/action-form-field';
import { InputValueType } from 'components/cyberx/actions/types';
import { alphabetizeIndustries, useIndustries } from 'components/industries';
import { NuButton } from 'components/nuspire';
import Breadcrumb from 'components/nuspire/nu-breadcrumb';
import PageHeader from 'components/nuspire/nu-page-header';
import { ErrorMessage, FieldArray, Formik, FormikErrors, FormikTouched, useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ClientIdentifier, ClientIdentifierType, ClientType } from 'types';
import { ActionFormFieldType, CreateClientFormMutationVariables } from 'types/graph-codegen/graph-types';
import * as yup from 'yup';
import baseTheme from '../theme';
import { ClientTypesSelect } from '../clients/client-types-select';

/**
 * name,
 * parentId,
 * industry
 */
function ClientNameInput(props: { name: string }) {
  const { name } = props;
  const [{ value, onBlur }, { touched, error }, { setValue }] = useField({ name });

  return (
    <AntForm.Item
      label="Client Name" // *
      required // *
      hasFeedback={Boolean(touched && error)}
      validateStatus={touched && error ? 'error' : undefined}
      status={touched && error ? 'error' : undefined}
    >
      <Input name={name} value={value} onChange={(e) => setValue(e.target.value)} onBlur={onBlur} />
    </AntForm.Item>
  );
}

function IndustrySelect(props: { fieldName: string; required?: boolean }) {
  const { fieldName, required = false } = props;
  const [{ value, onBlur }, { touched, error }, { setValue }] = useField({ name: fieldName });
  const { data, loading } = useIndustries();
  const alphabetizedIndustries = alphabetizeIndustries(data?.industries);

  return (
    <AntForm.Item
      label="Industry"
      required={required}
      hasFeedback={Boolean(touched && error)}
      validateStatus={touched && error ? 'error' : undefined}
      status={touched && error ? 'error' : undefined}
    >
      <Select
        value={value}
        onChange={(val) => setValue(val)}
        placeholder="Select Industry"
        onBlur={onBlur}
        disabled={loading}
        loading={loading}
        showSearch
        filterOption={(input, option: any) => option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {alphabetizedIndustries?.map((industry) => (
          <Select.Option key={industry.id} value={industry.id}>
            {industry.name}
          </Select.Option>
        ))}
      </Select>
    </AntForm.Item>
  );
}

export interface IClientIdentifiersDef {
  label: string;
  type: ClientIdentifierType | 'string';
  inputType?: string;
  valueType?: InputValueType; // 'string' | 'number' | 'object' | 'array';
}

export type ClientIdentifiersList = IClientIdentifiersDef[];

export const clientIdentifiersList: ClientIdentifiersList = [
  {
    label: 'Cybereason Group Name',
    type: 'cybereasonGroupName',
  },
  {
    label: 'FortiSIEM Org ID',
    type: 'fortisiemOrgId',
    valueType: 'string',
    inputType: 'fortisiemOrgId',
  },
  {
    label: 'Nusiem Sources',
    type: 'nusiemSources',
    valueType: 'array',
    inputType: 'nusiemSourcesSelect',
  },
  {
    label: 'SentinelOne Account ID',
    type: 'sentinelOneAccountId',
    valueType: 'string',
    inputType: 'sentineloneAccountId',
  },
  {
    label: 'SentinelOne Site ID',
    type: 'sentinelOneSiteId',
    valueType: 'string',
    inputType: 'sentineloneSiteId',
  },
  {
    label: 'ServiceNow Account ID',
    type: 'serviceNowAccountId',
    valueType: 'string',
    inputType: 'serviceNowAccountId',
  },
  {
    label: 'Siem',
    type: 'siem',
    valueType: 'string',
    inputType: 'text',
  },
  {
    label: 'Location Code',
    type: 'locationCode',
    valueType: 'string',
    inputType: 'text',
  },
  {
    label: 'Qualys Networks',
    type: 'qualysNetworks',
    valueType: 'array',
    inputType: 'qualys_network_ids',
  },
  {
    label: 'Qualys Web Apps',
    type: 'qualysWebApps',
    valueType: 'string',
    inputType: 'qualys_web_app_ids',
  },
];
clientIdentifiersList.sort((a, b) => a.label.localeCompare(b.label));

export const ClientIdentifierSpace = styled.div`
  margin-bottom: 1rem;
`;

function formatValue(args: { valueType?: InputValueType; value?: string }) {
  const { value, valueType } = args;

  if (!value) {
    return value;
  }

  if (valueType === 'array' || valueType === 'object') {
    return JSON.parse(value);
  }

  if (valueType === 'number') {
    return Number(value);
  }

  return value;
}

function stringifyValue(value: any, valueType?: InputValueType) {
  if (valueType === 'object' || valueType === 'array') {
    return JSON.stringify(value);
  }

  return `${value}`;
}

function IdentifierValueInput(props: {
  clientId?: string;
  clientName?: string;
  name: string;
  type?: string;
  onChange: (value: string) => void;
  value?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}) {
  const { name, type, onChange, onBlur } = props;
  const clientIdentifierDef = type ? clientIdentifiersList.find((i) => i.type === type) : undefined;

  const valueType = clientIdentifierDef?.valueType ?? 'string'; // default to text input
  const inputType = clientIdentifierDef?.inputType;

  const inputDef = getInput({ type: valueType as ActionFormFieldType, inputType });
  const InputFunc = inputDef.input;

  const value = formatValue({ valueType, value: props.value });

  const handleChange = (val: any) => {
    const stringifiedValue = stringifyValue(val, valueType);
    onChange(stringifiedValue);
  };

  const { user } = useAuthContext();
  const clientId = props.clientId ?? user?.clientId;

  return (
    <InputFunc
      clientId={clientId}
      clientName={props.clientName}
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
    />
  );
}

function IdentifierInput(props: {
  clientId?: string;
  clientName?: string;
  name: string;
  type?: string;
  onTypeChange: (type: string) => void;

  value?: string;
  onValueChange: (value: string) => void;
  onValueBlur?: React.FocusEventHandler<HTMLInputElement>;
  onRemove: () => void;
  selectableIdentifierTypes: ('string' | ClientIdentifierType)[];
  errorMsg?: { type?: string; value?: string };
}) {
  const { clientId, clientName, name, type, onTypeChange, value, onValueChange, onRemove, selectableIdentifierTypes } =
    props;

  const isTypeDisabled = (t: ClientIdentifierType | 'string'): boolean =>
    t !== 'string' && !selectableIdentifierTypes.includes(t);

  return (
    <ClientIdentifierSpace>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <Select
          placeholder="Type"
          value={type}
          onChange={onTypeChange}
          style={{ maxWidth: '250px', minWidth: '250px', marginRight: '1rem' }}
          showSearch
          filterOption={(input, option: any) => {
            return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {clientIdentifiersList.map((id) => (
            <Select.Option key={id.type} value={id.type} disabled={isTypeDisabled(id.type)}>
              {id.label}
            </Select.Option>
          ))}
        </Select>

        <div style={{ flex: 1, display: 'flex' }}>
          <IdentifierValueInput
            clientId={clientId}
            clientName={clientName}
            name={name}
            onChange={onValueChange}
            type={type}
            value={value}
            onBlur={props.onValueBlur}
          />

          <Tooltip overlay="Remove client identifier">
            <NuButton danger type="text" onClick={() => onRemove()}>
              <MinusCircleOutlined />
            </NuButton>
          </Tooltip>
        </div>
      </div>
      {props.errorMsg && (
        <div>
          <span
            style={{
              paddingLeft: '0.25rem',
              width: 'calc(250px + 1rem)',
              display: 'inline-block',
              color: baseTheme.color.error,
            }}
          >
            {props?.errorMsg?.type ?? ''}
          </span>
          <span style={{ paddingLeft: '0.25rem', display: 'inline-block', color: baseTheme.color.error }}>
            {props?.errorMsg?.value ?? ''}
          </span>
        </div>
      )}
    </ClientIdentifierSpace>
  );
}

export function ClientIdentifiersFieldArray(props: {
  clientId?: string;
  clientName?: string;
  fieldName: string;
  identifiers: ClientIdentifier[];
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange: (newValue: ClientIdentifier[]) => void;
  errors?: FormikErrors<any>;
}) {
  const { clientId, clientName, fieldName, identifiers, onChange, onBlur } = props;

  const filterOutTypes = (clientIdentifiers: typeof identifiers) =>
    clientIdentifiersList.filter((i) => !clientIdentifiers.find((ci) => ci.type === i.type)).map((i) => i.type);

  const [selectableIdentifierTypes, setSelectableIdentifierTypes] = useState(filterOutTypes(identifiers));

  useEffect(() => {
    setSelectableIdentifierTypes(filterOutTypes(identifiers));
  }, [identifiers]);

  return (
    <FieldArray
      name={fieldName}
      render={({ push, remove }) => (
        <>
          {identifiers.map((item, index: number) => {
            const errorMsg: { type?: string; value?: string } | undefined = props.errors?.clientIdentifiers?.[index];

            return (
              <>
                <IdentifierInput
                  clientId={clientId}
                  clientName={clientName}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${index}`}
                  name={`${index}`}
                  type={item.type}
                  value={item.value}
                  onTypeChange={(newValue) => {
                    const newIdentifiers = [...identifiers];
                    const updatedIdentifier = {
                      ...identifiers[index],
                      type: newValue as ClientIdentifierType,
                    };
                    newIdentifiers[index] = updatedIdentifier;

                    onChange(newIdentifiers);
                  }}
                  onValueBlur={onBlur}
                  onValueChange={(val) => {
                    const newIdentifiers = [...identifiers];
                    const updatedIdentifier = {
                      ...identifiers[index],
                      value: val,
                    };
                    newIdentifiers[index] = updatedIdentifier;

                    onChange(newIdentifiers);
                  }}
                  onRemove={() => remove(index)}
                  selectableIdentifierTypes={selectableIdentifierTypes}
                  errorMsg={errorMsg}
                />
              </>
            );
          })}

          <AntForm.Item>
            <NuButton type="dashed" onClick={() => push('')} block icon={<PlusOutlined />}>
              Add client identifier
            </NuButton>
          </AntForm.Item>
        </>
      )}
    />
  );
}

function ClientIdentifierInput(props: { fieldName: string }) {
  const { fieldName } = props;
  const [{ value, onBlur }, { touched, error }, { setValue }] = useField({ name: fieldName });

  return (
    <AntForm.Item
      label="Client Identifiers"
      required={false}
      hasFeedback={Boolean(touched && error)}
      validateStatus={touched && error ? 'error' : undefined}
      status={touched && error ? 'error' : undefined}
    >
      <ClientIdentifiersFieldArray
        fieldName={fieldName}
        identifiers={value}
        onBlur={onBlur}
        onChange={(newIdentifiers) => setValue(newIdentifiers)}
      />
    </AntForm.Item>
  );
}

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  parentId: yup.string().required(),
  industryId: yup.string().required(), // we should default this to that of the parent client if possible
  clientIdentifiers: yup.array(),
});

const CREATE_CLIENT = gql`
  mutation CreateClientForm($input: CreateClientInput) {
    createClient(input: $input) {
      id
      name
    }
  }
`;
interface CreateClientMutation {
  createClient: {
    id: string;
    name: string;
  };
}

export function ChildClientForm(props: { defaultParentId: string; defaultType?: ClientType | null }) {
  const { defaultParentId } = props;
  const [createClient] = useMutation<CreateClientMutation, CreateClientFormMutationVariables>(CREATE_CLIENT);
  const navigate = useNavigate();
  const [form] = AntForm.useForm();

  return (
    <Formik
      initialValues={{
        name: '',
        parentId: defaultParentId,
        clientIdentifiers: [],
        type: props.defaultType,
      }}
      onSubmit={async (input, helpers) => {
        try {
          const { data, errors } = await createClient({
            variables: {
              input: {
                ...input,
                type: input.type as string | undefined,
              },
            },
          });
          const newClient = data?.createClient ?? null;

          if (errors) {
            message.error('Failed to create client');
          } else if (newClient) {
            message.success('Client was successfully created.');
            helpers.setSubmitting(false);

            // set timeout to take to new client?
            navigate(`/${newClient.id}/settings/managed-clients`);
          }
        } catch (err) {
          message.error('Failed to create client');
        }
      }}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, dirty, errors = {} }) => {
        return (
          <AntForm layout="vertical" onFinish={() => submitForm()} form={form}>
            <ClientNameInput name="name" />

            <AntForm.Item
              name="type"
              label="Client Type"
              rules={[{ required: true, message: 'Please select a client type' }]}
            >
              <ClientTypesSelect fieldName="type" form={form} initialValue={props.defaultType} />
            </AntForm.Item>

            <IndustrySelect required fieldName="industryId" />

            <ClientIdentifierInput fieldName="clientIdentifiers" />

            <AntForm.Item>
              <NuButton
                htmlType="submit"
                type="primary"
                disabled={isSubmitting || !dirty || Boolean(Object.keys(errors).length)}
                loading={isSubmitting}
              >
                Submit
              </NuButton>
            </AntForm.Item>
          </AntForm>
        );
      }}
    </Formik>
  );
}

export function AddClient() {
  const { clientId } = useClientContext();

  return (
    <>
      <Breadcrumb
        target="organization"
        items={[
          {
            key: `${clientId}-organization`,
            title: <Link to={`/${clientId}/organization`}>Organization</Link>,
          },
          {
            key: `${clientId}-organization-client-tree`,
            title: <Link to={`/${clientId}/organization/client-tree`}>Client Tree</Link>,
          },
          {
            key: 'add',
            title: 'Add',
          },
        ]}
      />
      <PageHeader title="Add Child Client" />

      <ChildClientForm defaultParentId={`${clientId}`} />
    </>
  );
}
