import { StopOutlined } from '@ant-design/icons';
import { gql, useQuery } from '@apollo/client';
import { message } from 'antd';
import { ButtonGroupProps } from 'antd/es/button';
import { useAuthContext } from 'components/auth-context';
import { useAuthorize } from 'components/authorization';
import { CLIENT_CONTEXT } from 'components/client-context-provider';
import MutationButton from 'components/mutation-button';
import { P } from 'components/shared-components';
import { Access, IUser } from 'types';
import {
  RemoveExternalUserFromClientMutation,
  RemoveExternalUserFromClientMutationVariables,
} from 'types/graph-codegen/graph-types';
import { formatUserName } from 'utils/users';

export const REMOVE_EXTERNAL_USER_MUTATION = gql`
  mutation RemoveExternalUserFromClient($clientId: String, $userId: String!) {
    removeExternalUserFromClient(clientId: $clientId, userId: $userId)
  }
`;

export type ExternalUserActionButtonsProps = {
  clientId?: string;
  onActionSuccess?: () => void;
  user: IUser;
};

export function RemoveExternalUserButton(props: ButtonGroupProps & ExternalUserActionButtonsProps) {
  const { clientId, onActionSuccess, user, ...buttonProps } = props;

  const userName = formatUserName(user);
  const { id: userId } = user;
  const variables: RemoveExternalUserFromClientMutationVariables = {
    clientId,
    userId,
  };
  const modalTitle = <P>Are you sure you want to remove {userName} as an external user?</P>;

  const onError = (error?: any) => {
    // Display the error if we have one.
    const errorMessage = `There was an error removing ${userName} as an external user. ${`Error: ${
      error?.message ?? 'Unknown Error'
    }`}`;

    message.error(errorMessage);
  };
  const onCompleted = (data: RemoveExternalUserFromClientMutation) => {
    if (data.removeExternalUserFromClient !== undefined) {
      const { removeExternalUserFromClient: result } = data;

      // Removal was unsuccessful.
      if (!result) {
        onError();
        return;
      }

      // Removal was successful.
      message.success(`${userName} has been removed as an external user!`);
      onActionSuccess?.();
    }
  };

  return (
    <MutationButton
      buttonIcon={<StopOutlined />}
      danger
      modalOkType="danger"
      modalTitle={modalTitle}
      modalContent="This action cannot be undone."
      mutation={REMOVE_EXTERNAL_USER_MUTATION}
      mutationVariables={variables}
      onCompleted={onCompleted}
      onError={onError}
      tooltipOverlay="Remove External User"
      {...buttonProps}
    />
  );
}

export default function ExternalUserActionButtons(props: ExternalUserActionButtonsProps) {
  const { user } = props;
  const { user: authUser } = useAuthContext();
  const { data } = useQuery(CLIENT_CONTEXT, { variables: { clientId: authUser?.clientId } });
  const isSuperUser = authUser?.isSuperUser ?? false;
  const authorize = useAuthorize({
    effectivePermissions: data?.getClientById?.effectivePermissions,
  });

  const { authorized } = authorize({
    requiredPermissions: {
      users: Access.write,
    },
  });

  // Safeguard in case the user doesn't have permission to perform an action on a user.
  if (!authorized && !isSuperUser) return null;

  // Can't modify your own user.
  if (authUser && authUser.id === user.id) return null;

  return <RemoveExternalUserButton {...props} />;
}
