import { useMutation } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';
import { Collapse, Form, Result, Typography, Alert } from 'antd';
import { NuButton } from 'components/nuspire';
import { NuspireIcon } from 'components/nuspire/nu-icon';
import { FormikInput } from 'components/shared-components';
import baseTheme from 'components/theme';
import { Formik } from 'formik';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { MAKE_CONNECTION_MUTATION } from '..';
import { ConnectionInstructionsWrapper } from '../index';
import { decodeOAuthState } from '../utils/oauth';
import connector from './crowdstrike';

const validationSchema = yup.object().shape({
  clientId: yup.string().required(),
  clientSecret: yup.string().required(),
  baseURL: yup.string().url().required(),
  name: yup.string().required(),
});

interface IAuthFormValues {
  clientId: string;
  clientSecret: string;
  baseURL: string;
  name: string;
  testConnection: boolean;
}

interface ICrowdStrikeAuthFormProps {
  onSubmit: (args: {
    values: IAuthFormValues;
    setSubmitting: (isSubmitting: boolean) => void;
    setError: (value: string) => void;
  }) => Promise<void>;
}

function CrowdStrikeAuthForm({ onSubmit }: ICrowdStrikeAuthFormProps) {
  const [error, setError] = useState<string | undefined>();

  return (
    <Formik
      initialValues={{
        clientId: '',
        clientSecret: '',
        baseURL: '',
        name: '',
        testConnection: true,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit({ values, setSubmitting, setError });
      }}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, errors, dirty }) => (
        <Form
          layout="vertical"
          onFinish={() => {
            submitForm();
          }}
        >
          {error ? <Alert type="error" message={error} /> : null}

          <FormikInput
            name="name"
            label="API Client Name"
            required
            tooltip="This will be used in myNuspire when referencing this API client."
          />
          <FormikInput name="clientId" label="Client ID" required tooltip="Client ID" />
          <FormikInput name="clientSecret" label="Client Secret" required tooltip="Client Secret" />
          <FormikInput
            name="baseURL"
            label="Instance URL"
            required
            tooltip="Ex: https://api.us-2.crowdstrike.com"
            placeholder="Ex: https://api.us-2.crowdstrike.com"
          />

          <FormikInput name="testConnection" checkbox>
            Test credentials before creation
          </FormikInput>
          <Form.Item>
            <NuButton
              type="primary"
              htmlType="submit"
              disabled={!dirty || Object.keys(errors).length > 0 || isSubmitting}
              loading={isSubmitting}
            >
              Submit
            </NuButton>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}

function CrowdStrikeConnectionInstructions() {
  return (
    <Collapse style={{ marginBottom: '32px' }} defaultActiveKey="instructions">
      <Collapse.Panel key="instructions" header="API Token Instructions">
        <ConnectionInstructionsWrapper>
          <Typography.Paragraph>
            myNuspire leverages CrowdStrike REST APIs which are authenticated with a Client ID and a Client Secret.
            Follow the instructions below in order to generate a Client Id and Client Secret within the CrowdStrike
            Management Console in order to create a Connection.
          </Typography.Paragraph>

          <Typography.Title level={4}>Generating your Auth Information</Typography.Title>

          <Typography.Paragraph>
            To generate a new API Client, login to your CrowdStrike dashboard, you must be a Falcon Administrator to
            create a new API client. Click the CrowdStrike icon in the top left corner then navigate to Support &gt; API
            Clients and Keys &gt; Add new API Client. Enter the information for the new client, select the appropriate
            scope and click add. Crowdstrike will present you with all the needed information. The minimum scope needed
            is read access to incidents, read/write access to detections, and read/write access to hosts.
          </Typography.Paragraph>
        </ConnectionInstructionsWrapper>
      </Collapse.Panel>
    </Collapse>
  );
}

const Layout = styled.div``;

const Header = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid ${(p) => p.theme.token.colorBorder};
`;

const Content = styled.div`
  padding: 16px 32px;
`;

export default function CrowdStrikeRedirect() {
  const location = useLocation();
  const search = location.search;
  const { authState: oktaAuthState } = useOktaAuth();

  const [makeConnection] = useMutation(MAKE_CONNECTION_MUTATION);
  const [success, setSuccess] = useState<boolean>(false);

  const stateString = new URLSearchParams(search).get('state');
  const authState = decodeOAuthState(stateString);

  if (!oktaAuthState?.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Layout>
      <Header>
        <Typography.Title level={2} style={{ marginBottom: 0 }}>
          <NuspireIcon style={{ color: baseTheme.color.primaryBlue, marginRight: '8px' }} />
          Connect to CrowdStrike
        </Typography.Title>
      </Header>
      <Content>
        {!success ? (
          <>
            <CrowdStrikeConnectionInstructions />

            <Typography.Title level={3}>Create Connection</Typography.Title>
            <CrowdStrikeAuthForm
              onSubmit={async ({ values, setSubmitting, setError }) => {
                const { testConnection, ...payload } = values;
                const { clientId } = authState;

                if (!clientId) {
                  throw new Error('No client id was passed');
                }

                const { data, errors } = await makeConnection({
                  variables: {
                    connectorSlug: connector.slug,
                    payloadJson: JSON.stringify(payload),
                    clientId,
                    testConnection,
                  },
                });

                setSubmitting(false);

                if (errors?.length) {
                  setError(errors[0].message);
                  return;
                }

                const newConnection = data?.makeConnection;

                if (newConnection && !errors) {
                  const newEvent = new CustomEvent(`new-connection-${connector.slug}`, {
                    detail: {
                      connection: newConnection,
                    },
                  });

                  window?.opener?.dispatchEvent(newEvent);

                  setSuccess(true);
                }
              }}
            />
          </>
        ) : (
          <Result status="success" title="Connection was successfully created!" />
        )}
      </Content>
    </Layout>
  );
}
