import NuButton from 'components/nuspire/nu-button';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Checkmark, Cancel as CancelIcon } from 'components/nuspire/nu-icon';
import baseTheme from 'components/theme';
import { Formik, FormikConfig, FormikProps } from 'formik';

const IiRoot = styled.div`
  position: relative;
  margin-right: -12px;
  margin-left: -12px;
`;
// This should have similar padding to inputs?
const IiReadView = styled.div`
  padding: 8px 12px;
  border-radius: 8px;

  .editable &:hover {
    background-color: ${(p) => p.theme.token.controlItemBgHover};
  }
  display: flex;
`;
const RvContainer = styled.div`
  flex: 1;
`;
const EditViewRoot = styled.div`
  position: relative;
`;
const EditViewActions = styled.div`
  position: absolute;
  bottom: -40px;
  right: 0;
  z-index: 1;

  > * {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(args: { ref: any; onClickOutside: () => void }) {
  const { ref, onClickOutside } = args;
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert('You clicked outside of me!');
        onClickOutside();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

function OutsideAlerter(props: { children: React.ReactElement; onClickOutside: () => void }) {
  const { children, onClickOutside } = props;
  const wrapperRef = useRef(null);
  useOutsideAlerter({ ref: wrapperRef, onClickOutside });

  return <div ref={wrapperRef}>{children}</div>;
}

interface OnFinishArgs {
  values: { [key: string]: any };
}

function EditView(props: {
  onFinish: (args: OnFinishArgs) => void;
  onCancel: () => void;
  closeOnOutsideClick: boolean;
  initialValues: { [key: string]: any };
  inlineInputApi: InlineInputApi;
  editView: (props: { inlineInputApi: InlineInputApi; formikProps: FormikProps<any> }) => any;
  validationSchema?: FormikConfig<any>['validationSchema'];
  validateOnChange?: boolean;
}) {
  const { editView, onFinish, onCancel, closeOnOutsideClick, initialValues, inlineInputApi } = props;

  return (
    <Formik
      onSubmit={async (values) => {
        await onFinish({ values });
      }}
      initialValues={initialValues}
      validationSchema={props.validationSchema}
      validateOnChange={props.validateOnChange}
    >
      {(formikProps) => {
        const { submitForm, isSubmitting } = formikProps;

        return (
          <OutsideAlerter onClickOutside={() => (closeOnOutsideClick ? onCancel() : null)}>
            <EditViewRoot>
              {editView({
                inlineInputApi,
                formikProps,
              })}

              <EditViewActions>
                <NuButton
                  onClick={() => {
                    formikProps.validateForm().then(() => submitForm());
                  }}
                  style={{ color: baseTheme.color.green }}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  <Checkmark />
                </NuButton>
                <NuButton
                  onClick={() => {
                    onCancel();
                  }}
                  disabled={isSubmitting}
                >
                  <CancelIcon style={{ color: baseTheme.color.red }} />
                </NuButton>
              </EditViewActions>
            </EditViewRoot>
          </OutsideAlerter>
        );
      }}
    </Formik>
  );
}

export interface InlineInputApi {
  onClose: () => void;
  onFinish: (args: OnFinishArgs) => void;
}

function InlineInput(props: {
  onFinish?: (args: OnFinishArgs) => void;
  onEdit?: () => void;
  onCancel?: () => void;
  readView: any;
  editView: (props: { inlineInputApi: InlineInputApi; formikProps: FormikProps<any> }) => any;
  canEdit?: boolean; // set to false to prevent editing
  isEditing?: boolean; // override managed state
  useEditButton?: boolean;
  closeOnOutsideClick?: boolean;
  initialValues: { [key: string]: any };
  validationSchema?: FormikConfig<any>['validationSchema'];
  validateOnChange?: boolean;
}) {
  const {
    onEdit,
    onFinish,
    onCancel,
    readView,
    editView,
    canEdit = true,
    isEditing,
    useEditButton = false,
    closeOnOutsideClick = true,
    initialValues,
  } = props;
  const [localIsEditing, setLocalIsEditing] = useState<boolean>(false);
  const handleClose = () => setLocalIsEditing(false);
  const handleEdit = () => {
    if (!canEdit) {
      return;
    }
    if (onEdit) {
      onEdit();
    }
    setLocalIsEditing(true);
  };
  const handleFinish = async (args: OnFinishArgs) => {
    if (onFinish) {
      await onFinish(args);
    }
    setLocalIsEditing(false);
  };
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    setLocalIsEditing(false);
  };

  const editing = isEditing ?? localIsEditing;

  const inlineInputApi: InlineInputApi = {
    onFinish: handleFinish,
    onClose: handleClose,
  };

  return (
    <IiRoot className={canEdit ? 'editable' : undefined}>
      {!editing && (
        <IiReadView onClick={!useEditButton ? handleEdit : undefined}>
          <RvContainer>{readView}</RvContainer>
          {canEdit && useEditButton && (
            <NuButton type="link" onClick={handleEdit} size="small">
              Edit
            </NuButton>
          )}
        </IiReadView>
      )}

      {editing && (
        <EditView
          onFinish={handleFinish}
          onCancel={handleCancel}
          closeOnOutsideClick={closeOnOutsideClick}
          initialValues={initialValues}
          inlineInputApi={inlineInputApi}
          editView={editView}
          validationSchema={props.validationSchema}
          validateOnChange={props.validateOnChange}
        />
      )}
    </IiRoot>
  );
}

export default InlineInput;
