import { ChartData, ChartOptions } from 'chart.js';
import dayjs from 'dayjs';
import { Bar } from 'react-chartjs-2';
import { useTheme } from 'styled-components';
import { WidgetComponentProps } from '..';

type BarData = ChartData<'bar', number[], string>;
type HistogramBucket = {
  docCount: number;
  keyAsString: string;
};

interface HistogramProps {
  buckets: HistogramBucket[];
  maxTicksLimit?: number;
  onUpdateRange?: (newFrom: string, newTo: string) => void;
  legendLabel?: string;
}

function buildBarDataFromBuckets(args: { buckets: HistogramBucket[], legendLabel?: string }) {
  const { buckets } = args;
  const label = args.legendLabel ?? '# of Events';

  const theme = useTheme();

  return buckets.reduce<BarData>((acc, bucket) => {
    const { keyAsString, docCount } = bucket;

    acc.labels!.push(dayjs(keyAsString).format('M/D - k:mm'));
    acc.datasets[0].data.push(docCount);

    return acc;
  }, {
    labels: [],
    datasets: [{
      label,
      data: [],
      borderWidth: 1,
      backgroundColor: theme.color.nuspireBlue,
      borderColor: theme.color.nuspireBlue,
    }]
  });
}

export function Histogram(props: HistogramProps) {
  const { buckets, onUpdateRange, legendLabel } = props;
  const maxTicksLimit = props.maxTicksLimit ?? 5;

  const data = buildBarDataFromBuckets({
    buckets,
    legendLabel,
  });

  const options: ChartOptions<'bar'> = {
    onClick: (_e, activeElements: any) => {
      const clickedIdx = activeElements?.length === 1 ? activeElements[0].index : null;

      if (clickedIdx !== null) {
        const m = dayjs(buckets[clickedIdx].keyAsString);
        const newFrom = m.toISOString();
        const newTo = m.add(1, 'day').toISOString();

        onUpdateRange?.(newFrom, newTo);
      }
    },
    scales: {
      x: {
        axis: 'x',
        ticks: {
          maxTicksLimit,
        },
      },
    },
  };

  return (
    <Bar options={options} data={data} />
  );
}

export function HistogramWidget(props: WidgetComponentProps<any, { buckets: HistogramBucket[] }>) {
  const { data } = props;

  return (
    <Histogram buckets={data.buckets} />
  );
}

