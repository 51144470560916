import React from 'react';
import { WidgetComponentProps } from '../..';
import styled from 'styled-components';

export type MetaConfig = {
  key: string;
  label: string;
  value?: string | number;
};

export type MetaWidgetData = {
  meta: MetaConfig[];
};

export type MetaWidgetConfig = null;

const MetaWidgetRoot = styled.div`
  font-size: 12px;
`;
const MwTable = styled.table``;
const MwRow = styled.tr``;
const MwCol = styled.td`
  vertical-align: top;
  padding-right: 16px;
  padding-bottom: 12px;

  &:first-child {
    font-weight: 600;
  }
`;

export function MetaWidgetContent(props: { meta: MetaConfig[] }) {
  const { meta } = props;

  return (
    <MetaWidgetRoot>
      <MwTable>
        {meta.map((metaItem) => {
          return (
            <MwRow key={metaItem.key}>
              <MwCol>{metaItem.label}</MwCol>
              <MwCol>{metaItem.value ?? '--'}</MwCol>
            </MwRow>
          );
        })}
      </MwTable>
    </MetaWidgetRoot>
  );
}

export function MetaWidget(props: WidgetComponentProps<MetaWidgetConfig, MetaWidgetData>) {
  const {
    data: { meta },
  } = props;

  return <MetaWidgetContent meta={meta} />;
}
