export function checkIsDate(time: string) {
  const isDate = /^\d{4}-\d{2}-\d{2}/.test(time);

  return isDate;
}

function daysFromNow(days: number, now: Date) {
  const date = new Date(now);
  date.setDate(date.getDate() - days);

  return date.toISOString();
}

function hoursFromNow(hours: number, now: Date) {
  const date = new Date(now);
  date.setHours(date.getHours() - hours);

  return date.toISOString();
}

function monthsFromNow(months: number, now: Date) {
  const date = new Date(now);
  date.setMonth(date.getMonth() - months);

  return date.toISOString();
}

function minutesFromNow(minutes: number, now: Date) {
  const date = new Date(now);
  date.setMinutes(date.getMinutes() - minutes);

  return date.toISOString();
}

export function matchFromNow(fromNow: string): [val: number, unit: string] | undefined {
  const unitsMatch = fromNow.match(/(\d*)([a-zA-Z]*)/);
  if (!unitsMatch) {
    return undefined;
  }

  const [_match, num, unit] = unitsMatch;

  const val = Number(num);

  return [val, unit];
}

type Unit = {
  matchingKeys: string[];
  fn: (val: number, now: Date) => string; // Returns iso string,
  label: string;
};

const units: Unit[] = [
  {
    label: 'day', // Days
    matchingKeys: ['d', 'D'],
    fn: daysFromNow,
  },
  {
    label: 'hour',
    matchingKeys: ['h', 'hr'],
    fn: hoursFromNow,
  },
  {
    label: 'minute',
    matchingKeys: ['m'],
    fn: minutesFromNow,
  },
  {
    label: 'month',
    matchingKeys: ['M'],
    fn: monthsFromNow,
  },
];

export function parseFromNow(fromNow: string, now: Date) {
  const unitsMatch = matchFromNow(fromNow);
  if (!unitsMatch) {
    return undefined;
  }

  const [val, unit] = unitsMatch;

  const unitDef = units.find((u) => u.matchingKeys.includes(unit));
  if (!unitDef) {
    throw new Error(`Unsupported unit: ${unit}`);
  }

  return unitDef.fn(val, now);
}

export function checkTimeRangeSupportedMin(timeRange: string, minTime: string): boolean {
  // should return false if minTime > from
  const now = new Date();
  const { fromIso } = parseTimeString({ time: timeRange, now });

  const parsedMin = parseTimeSyntax(minTime, now);
  if (!parsedMin) {
    console.warn('No min to compare to');
    return true; // no min to compare to...
  }

  if (!fromIso) {
    return true; // ??? nothing to compare...
  }

  const minDate = new Date(parsedMin);
  const fromDate = new Date(fromIso);

  if (fromDate < minDate) {
    return false;
  }

  return true;
}

export function fromNowReadable(fromNow: string) {
  const unitsMatch = matchFromNow(fromNow);
  if (!unitsMatch) {
    return undefined;
  }

  const [val, unit] = unitsMatch;

  const unitDef = units.find((u) => u.matchingKeys.includes(unit));
  if (!unitDef) {
    throw new Error(`Unsupported unit: ${unit}`);
  }

  return `${val} ${unitDef.label}${val > 1 ? 's' : ''}`;
}

export function timeSyntaxToReadable(time: string) {
  const isDate = checkIsDate(time);
  if (isDate) {
    return time;
  }

  const fromNow = time.match(/(now-)(.*)/)?.[2];
  if (fromNow) {
    return fromNowReadable(fromNow);
  }

  return time;
}

export function parseTimeSyntax(time: string, now: Date) {
  // if date, just use date string.
  const isDate = checkIsDate(time);
  if (isDate) {
    return new Date(time).toISOString();
  }

  const fromNow = time.match(/(now-)(.*)/)?.[2];
  if (fromNow) {
    return parseFromNow(fromNow, now);
  }

  if (time === 'now') {
    return now.toISOString();
  }

  return undefined;
}

export function parseTimeString(args: { time?: string; now?: Date }): {
  from?: string;
  to?: string;
  fromIso?: string;
  toIso?: string;
} {
  const { time } = args;
  if (!time) {
    return {};
  }

  let [fromStr, toStr] = time.split(',');
  // let from: string | undefined = fromStr.match(/(?<=from:).*$/)?.[0];
  // let to: string | undefined = toStr.match(/(?<=to:).*$/)?.[0];
  let from: string | undefined = fromStr.match(/(from:)(.*)$/)?.[2];
  let to: string | undefined = toStr.match(/(to:)(.*)$/)?.[2];

  if (!from || !to) {
    // failed to get from or two.
    return {};
  }

  // all relative dates should be based off of now.
  const now = args.now ?? new Date();

  const fromIso = parseTimeSyntax(from, now);
  const toIso = parseTimeSyntax(to, now);

  return {
    from,
    to,
    fromIso,
    toIso,
  };
}
