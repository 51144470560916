import { useMutation, gql, useQuery, useLazyQuery } from '@apollo/client';
import { Checkbox, Input, Form, message, Select, Alert, Typography, App } from 'antd';
import { Link, NuButton, NuCard, NuCardContent } from 'components/nuspire';
import { mixpanelTrack } from 'utils/mixpanel/mixpanel-track';
import { useAuthContext } from 'components/auth-context';
import { useClientContext } from 'components/client-context-provider';
import { Access, IUser } from 'types';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { useState } from 'react';
import {
  InviteUserMutation,
  InviteUserMutationVariables,
  UserExistsQuery,
  UserExistsQueryVariables,
} from 'types/graph-codegen/graph-types';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import PhoneInput, { PhoneInputFormValues } from 'components/phone-input';
import { formatPhoneNumber, parsePhoneNumberFromFormattedString } from '../../utils/phone-number';

const USER_GROUP_SELECT = gql`
  query UserGroupSelect($clientId: String!) {
    userGroups(clientId: $clientId) {
      id
      name
      description
    }
    getClientById(id: $clientId) {
      id
      name
      industry {
        id
        name
      }

      # Returns permission object for current user.
      # This should be used in UI to authorize functionality.
      effectivePermissions
    }
  }
`;

interface UserGroup {
  id: string;
  name: string;
  description: string;
}
interface UserGroupSelectQuery {
  userGroups: UserGroup[];
  getClientById: any;
}

function UserGroupSelect(props: { clientId?: string; value: string | null; onChange: (value: string) => void }) {
  const { user } = useAuthContext();
  const { clientId = user?.clientId, onChange, value } = props;
  const { data, loading } = useQuery<UserGroupSelectQuery>(USER_GROUP_SELECT, {
    variables: {
      clientId,
    },
    skip: !clientId,
  });
  const userGroups = data?.userGroups.slice().sort((a, b) => a.name.localeCompare(b.name)) ?? null;

  const userGroupOptions = userGroups
    ? userGroups.map((group) => (
        <Select.Option value={group.id} key={group.id}>
          {group.name}
        </Select.Option>
      ))
    : null;

  return (
    <Select
      size="large"
      loading={loading}
      placeholder="Select User Group"
      onChange={onChange}
      value={value ?? undefined}
    >
      {userGroupOptions}
    </Select>
  );
}

const INVITE_USER = gql`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      id
      clientId
      email
      firstName
      lastName
      isSuperUser
    }
  }
`;

const USER_EXISTS_QUERY = gql`
  query UserExists($email: String!) {
    userExists(email: $email)
  }
`;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

export type InviteUserFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  phone?: PhoneInputFormValues;
  mobilePhone?: PhoneInputFormValues;
  userExistsConfirmed?: boolean;
  userGroupId: string;
  nonNuspireEmailToNuspireMsspClientConfirmed?: boolean;
  openUserProfile?: boolean;
};

export type UserInviteFormProps = {
  clientId?: string;
  onFinish?: (user?: IUser) => void;
};

const UserInviteForm = (props: UserInviteFormProps) => {
  const { user } = useAuthContext();
  const { clientId = user?.clientId } = props;
  const [inviteMutation, { loading }] = useMutation<InviteUserMutation, InviteUserMutationVariables>(INVITE_USER);
  const [checkUserExists, { loading: userExistsLoading }] = useLazyQuery<UserExistsQuery, UserExistsQueryVariables>(
    USER_EXISTS_QUERY,
  );
  const [form] = Form.useForm();
  const { authorize } = useClientContext();
  const { modal } = App.useApp();
  const { authorized, message: authMessage } = authorize({
    requiredPermissions: {
      users: Access.write,
    },
  });
  const { authorized: readAllUsersAuthorized } = authorize({
    requiredPermissions: {
      allUsers: Access.read,
    },
  });
  const [userExists, setUserExists] = useState(false);

  const canInvite = user?.isSuperUser || authorized;
  const canReadAllUsers = user?.isSuperUser || readAllUsersAuthorized;

  const onFinish = async (values: InviteUserFormValues) => {
    console.log('Form submitted', { values });
    const {
      firstName,
      lastName,
      email,
      phone,
      mobilePhone,
      userGroupId,
      userExistsConfirmed = false,
      nonNuspireEmailToNuspireMsspClientConfirmed = false,
      openUserProfile = false,
    } = values;

    /*

      NOC is accidentally adding non-nuspire users to the Nuspire MSSP client, adding a pop-up to hopefully prevent this
      Jira: https://sd-at-nuspire.atlassian.net/browse/MYN-2413

    */
    const isANuspireEmail = email?.includes('@nuspire.com') ?? false;
    if (
      clientId === '6d260b5f-cf9f-46ee-9c0c-4b02a4923937' /* Nuspire MSSP clientId */ &&
      !isANuspireEmail &&
      !nonNuspireEmailToNuspireMsspClientConfirmed
    ) {
      modal.confirm({
        title: `Add ${email} as a Nuspire MSSP user?`,
        content: `User ${email} is not a Nuspire email. Do you wish to add them as a non-Nuspire user to the Nuspire MSSP client?`,
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        okType: 'primary',
        onOk: async () => {
          onFinish({ ...values, nonNuspireEmailToNuspireMsspClientConfirmed: true });
        },
        cancelText: 'No',
        width: 500,
      });
      return;
    }

    if (canReadAllUsers && userExists && !userExistsConfirmed) {
      modal.confirm({
        title: `Add ${email} as an external user?`,
        content: `User ${email} already exists in myNuspire outside of this organization. Do you wish to add them as an external user to this client?`,
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        okType: 'primary',
        onOk: async () => {
          onFinish({ ...values, userExistsConfirmed: true });
        },
        cancelText: 'No',
        width: 500,
      });
      return;
    }

    let formattedPhoneNumber, formattedMobilePhoneNumber: string | undefined;
    if (phone?.number) {
      const { countryCodeKey, number, extension } = phone;
      const [, countryCallingCode] = countryCodeKey.split('-');
      const { phoneNumber } = parsePhoneNumberFromFormattedString(`+${countryCallingCode}${number}`);

      formattedPhoneNumber = formatPhoneNumber(phoneNumber, extension);
    }

    if (mobilePhone?.number) {
      const { countryCodeKey, number, extension } = mobilePhone;
      const [, countryCallingCode] = countryCodeKey.split('-');
      const { phoneNumber } = parsePhoneNumberFromFormattedString(`+${countryCallingCode}${number}`);

      formattedMobilePhoneNumber = formatPhoneNumber(phoneNumber, extension);
    }

    try {
      const response = await inviteMutation({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            clientId,
            userExistsConfirmed,
            userGroupId,
            phone: formattedPhoneNumber,
            mobilePhone: formattedMobilePhoneNumber,
          },
        },
      });

      const user = response?.data?.inviteUser;

      if (response.errors) {
        message.error(`Unable to invite user with email: ${email}`);
        throw new Error(`Unable to invite user with email: ${email}`);
      }

      let successMessage = 'User invited!';
      if (!userExists) successMessage = `${successMessage} Email invitation sent to ${email}`;
      message.success(successMessage);

      if (user && openUserProfile) {
        window.open(`${window.location.origin}/admin/users/${user?.id}`, '_blank');
      }

      mixpanelTrack('invite-user');
      form.resetFields();
      setUserExists(false);

      if (props.onFinish) {
        props.onFinish(user as IUser);
      }
    } catch (err) {
      console.log(`Invite failed. ${err.message}`);
    }
  };

  const onGroupChange = (userGroupId: string) => {
    form.setFieldsValue({ userGroupId });
  };

  if (loading) {
    return (
      <NuCard title="Invite User">
        <SpinContainer>
          <Spin />
        </SpinContainer>
      </NuCard>
    );
  }

  const checkUsernameExists = async (e: any) => {
    if (!canReadAllUsers) return;
    // Only check if a user exists if there aren't any validation errors.
    if (form.getFieldError('email').length >= 1) return;

    const { value: email } = e.target;

    try {
      const resp = await checkUserExists({ variables: { email } });
      setUserExists(resp.data?.userExists ?? false);
    } catch (err) {
      setUserExists(false);
    }
  };

  return (
    <NuCard title="Invite User">
      <NuCardContent>
        {canInvite ? (
          <Form
            {...layout}
            form={form}
            labelAlign="right"
            onFinish={onFinish}
            initialValues={{ openUserProfile: canReadAllUsers }}
          >
            <Form.Item
              name="firstName"
              label="First"
              rules={[{ required: true, message: 'Please input a first name' }]}
            >
              <Input size="large" placeholder="First Name" />
            </Form.Item>

            <Form.Item name="lastName" label="Last" rules={[{ required: true, message: 'Please input a last name' }]}>
              <Input size="large" placeholder="Last Name" />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              rules={[{ type: 'email', required: true, message: 'Please input an email' }]}
              extra={
                userExists && <Typography.Text type="warning">A user with this email already exists.</Typography.Text>
              }
            >
              <Input
                size="large"
                placeholder="Email"
                onBlur={checkUsernameExists}
                disabled={userExistsLoading}
                suffix={userExistsLoading ? <Spin /> : <span />} // https://ant.design/components/input/#FAQ
              />
            </Form.Item>

            <PhoneInput label="Phone" name="phone" />
            <PhoneInput label="Mobile Phone" name="mobilePhone" showExtension={false} />

            <Form.Item
              name="userGroupId"
              label="Group"
              rules={[{ required: true, message: 'Please select a Group' }]}
              help={
                <Link to={`/${user?.clientId}/organization/groups`} target="_blank">
                  View Groups
                </Link>
              }
            >
              <UserGroupSelect
                clientId={user?.clientId}
                value={form.getFieldValue('userGroupId')}
                onChange={onGroupChange}
              />
            </Form.Item>

            {canReadAllUsers ? (
              <Form.Item name="openUserProfile" label="Admins" valuePropName="checked">
                <Checkbox>Open user's profile page after inviting</Checkbox>
              </Form.Item>
            ) : null}

            <NuButton
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={!canInvite || loading || userExistsLoading}
              style={{ marginTop: '24px' }}
            >
              Invite
            </NuButton>
          </Form>
        ) : (
          <Alert message={authMessage} type="info" />
        )}
      </NuCardContent>
    </NuCard>
  );
};

export default UserInviteForm;
