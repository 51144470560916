import { useClientContext } from 'components/client-context-provider';
import { NuCard, NuCardContent, NuCardTitle } from 'components/nuspire';
import { EntitlementsTable } from 'components/services/entitlements-section';
import { ClientType } from 'types';

export function EntitlementsCard(props: { clientId: string }) {
  const { clientId } = props;

  const clientContext = useClientContext();

  if (clientContext.client?.type === ClientType.program) return null;

  return (
    <NuCard>
      <NuCardTitle title="Your Nuspire Logins" />
      <NuCardContent>
        <EntitlementsTable clientId={clientId} />
      </NuCardContent>
    </NuCard>
  );
}
