import { ReactNode } from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

export interface NuCardTitleProps {
  title?: JSX.Element | string;
  subTitle?: string;
  actions?: ReactNode;
  dataIntercomTarget?: string;
  /**
   * Icon placed left of title.
   */
  icon?: ReactNode;

  styles?: {
    title?: React.CSSProperties;
    icon?: React.CSSProperties;
  };
}

/**
 * Styled Components;
 */
const Root = styled.div`
  padding: 8px 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

const TitleIcon = styled.div`
  display: flex; 
  align-items: center; 
  background-color: center;
  flex: 1; 
`

const Icon = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${p => p.theme.token.colorText}; 
`;

const Titles = styled.div`
  flex: 1;
`;
export const Title = styled.div`
  padding: 5px 0;
  flex: 1;
  font-family: Helvetica Neue, Verdana, sans-serif;
  font-size: 16px;
  color: ${(p) => p.theme.mode === 'light' ? p.theme.color.slateBlue : p.theme.color.breeze};
  text-transform: uppercase;
  font-weight: 700;

  .variation-gray & {
    color: ${p => p.theme.token.colorText}; 
  }
`;
const Actions = styled.div``;
const ActionsFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

function CardTitle(props: NuCardTitleProps) {
  const { title, actions, icon, subTitle, dataIntercomTarget, styles = {} } = props;

  return (
    <Root className="nu-card-title" data-intercom-target={dataIntercomTarget}>
      <TitleIcon>
        {icon && <Icon style={styles.icon}>{icon}</Icon>}

        {(title || subTitle) && (
          <Titles>
            {title && <Title style={styles.title}>{title}</Title>}
            {subTitle && <Typography.Paragraph type="secondary">{subTitle}</Typography.Paragraph>}
          </Titles>
        )}
      </TitleIcon>

      {actions && (
        <Actions>
          <ActionsFlex>{actions}</ActionsFlex>
        </Actions>
      )}
    </Root>
  );
}

export default CardTitle;
