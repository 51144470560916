import { ActionInputProps } from '../action-form-field';
import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce'
import { useClientContext } from 'components/client-context-provider';
import { gql, useQuery } from '@apollo/client';
import { Select } from 'antd';

const USER_SELECT_SEARCH = gql`
  query UserSelectSearch($clientId: String!, $search: String) {
    getUsersByClientId(id: $clientId, search: $search) {
      id
      email
      firstName
      lastName
    }
    externalUsersByClientId(id: $clientId, search: $search) {
      id
      email
      firstName
      lastName
    }
  }
`;

export function UserSelect(props: ActionInputProps) {
  const { value, onChange, field } = props;
  const { clientId } = useClientContext();

  // state for user entered search
  const [queryString, setQueryString] = useState<string | undefined>(undefined);
  // value actually sent over query
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const handleQueryString = (val?: string) => {
    if (val?.length) {
      setQueryString(val);
    } else {
      setQueryString(undefined);
    }
  };

  // debounce search
  const debouncedSearch = useCallback(
    debounce((newSearch: string | undefined) => {
      setSearchValue(newSearch);
    }, 600),
    [],
  );

  useEffect(() => {
    debouncedSearch(queryString);
  }, [queryString]);

  const parameters = field?.parameters ?? {};

  const variables = {
    clientId,
    search: searchValue,
  };

  const { data, loading } = useQuery(USER_SELECT_SEARCH, { variables });

  let users = data?.getUsersByClientId;
  const externalUsers = data?.externalUsersByClientId;

  if (users && externalUsers) {
    users = [...users, ...externalUsers];
  }

  // get select options
  const options = users?.map((u) => ({
    key: u.id,
    label: `${u.firstName} ${u.lastName} <${u.email}>`,
    value: u.email, // ??
  }));

  return (
    <Select
      allowClear
      onChange={(val) => onChange(val)}
      value={value}
      showSearch
      searchValue={queryString}
      loading={loading}
      onSearch={handleQueryString}
      options={options}
      style={{ width: '100%' }}
      filterOption={false}
      {...parameters}
    />
  );
}
