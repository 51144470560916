import styled from 'styled-components';
import baseTheme from 'components/theme';
import { Typography } from 'antd';

export type Priority = 1 | 2 | 3 | 4 | 5;

export interface PriorityDefinition {
  priority: Priority;
  label: string;
  color: string;
}

export const priorityMap: PriorityDefinition[] = [
  {
    priority: 1,
    label: 'Critical',
    color: baseTheme.color.red,
  },
  {
    priority: 2,
    label: 'High',
    color: baseTheme.color.orange,
  },
  {
    priority: 3,
    label: 'Moderate',
    color: baseTheme.color.yellow,
  },
  {
    priority: 4,
    label: 'Low',
    color: baseTheme.color.nuspireBlue,
  },
  {
    priority: 5,
    label: 'Planning',
    color: baseTheme.color.gray4,
  },
];

function matchPriorityDef(priority: string | number) {
  const match = priorityMap.find(def => `${def.priority}` === `${priority[0] ?? priority}`) ?? null;

  return match;
}

interface PriorityBadgeProps {
  /**
   * 1-5
   */
  priority: string | number;
}

const BadgeRoot = styled.div<{
  color: string;
}>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${p => p.color};
`;

export function PriorityBadge(props: PriorityBadgeProps) {
  const { priority } = props;
  const def = matchPriorityDef(priority);

  if (!def) {
    return null;
  }

  return <BadgeRoot color={def.color} />;
}

const PriorityLabelRoot = styled.div`
  display: flex;
  align-items: center;
`;
const PriorityLabelWrapper = styled(Typography.Text)`
  .badge-right & {
    margin-right: 8px;
  }

  .badge-left & {
    margin-left: 8px;
  }
`;

export function PriorityLabel(props: { priority: string | number; badgePosition?: 'left' | 'right' | 'none' }) {
  const { priority, badgePosition = 'right' } = props;
  const def = matchPriorityDef(priority);

  if (!def) {
    return null;
  }

  const badge = <PriorityBadge priority={priority} />;

  return (
    <PriorityLabelRoot className={`priority-label badge-${badgePosition}`}>
      {badgePosition === 'left' && badge}

      <PriorityLabelWrapper>{`${def.priority} - ${def.label}`}</PriorityLabelWrapper>

      {badgePosition === 'right' && badge}
    </PriorityLabelRoot>
  );
}
