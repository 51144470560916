import { Space } from 'antd';
import styled from 'styled-components';

/**
 * +-------------------+
 * |v                  |
 * |e                  |
 * |r                  |
 * |t                  |
 * |i                  |
 * |c                  |
 * |a                  |
 * |l                  |
 * |w     i     d     e|
 * +-------------------+
 */
export const WideSpace = styled(Space).attrs({
  direction: 'vertical'
})`
  width: 100%;
`;
