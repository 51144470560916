import { FormInstance, Select } from 'antd';
import { FC, useEffect, useState } from 'react';

export const ClientTypesSelect: FC<{ fieldName?: string; form?: FormInstance<any>; initialValue?: string | null }> = (
  props,
) => {
  const [value, setValue] = useState(props.initialValue);

  useEffect(() => {
    if (props.fieldName && props.form && props.initialValue) {
      props.form.setFieldValue(props.fieldName, props.initialValue);
    }
  }, []);

  return (
    <Select
      onChange={(value: string) => {
        setValue(value);

        if (props.form && props.fieldName) {
          props.form.setFieldValue(props.fieldName, value);
        }
      }}
      value={value}
      options={[
        {
          value: 'direct',
          label: 'Direct',
        },
        {
          value: 'partner',
          label: 'Partner',
        },
        {
          value: 'program',
          label: 'Program',
        },
      ]}
    />
  );
};
