import { EyeOutlined } from '@ant-design/icons';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Col, Form, Input, Row, Space, Tooltip, Typography, message } from 'antd';
import { useAuthContext } from 'components/auth-context';
import { useClientContext } from 'components/client-context-provider';
import Breadcrumb from 'components/nuspire/nu-breadcrumb';
import baseTheme from 'components/theme';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Access } from 'types';
import { mixpanelTrack } from 'utils/mixpanel/mixpanel-track';
import { useClientIdentifier } from 'utils/react-hooks/use-client-identifier';
import type {
  CaseAdditionalDetailsQuery,
  CaseAdditionalDetailsQueryVariables,
  CaseByNumberQuery,
  CaseByNumberQueryVariables,
  FindCaseForUserQuery,
  FindCaseForUserQueryVariables,
  ServiceNowActivityStreamEntry,
  ServiceNowCaseAdditionalDetails,
} from '../../types/graph-codegen/graph-types';
import InfoCard from '../info-card';
import Spin, { SpinContainer } from '../nuspire/spin';
import { P } from '../shared-components';
import AttachmentsList from './attachments-list';
import CaseDetailsTimeline from './case-details-timeline';
import { PriorityText } from './case-management-table';
import ResolveCaseModal from './resolve-case-modal';
import FileUploader from './file-uploader';
import { UpdateCaseContactModal } from './update-case-contact-modal';
import UpdateWatchListModal from './update-watch-list-modal';
import { EmptyState, NuButton } from '../nuspire';
import { Content } from '../layouts/content';

export const CASE_BY_NUMBER_QUERY = gql`
  query CaseByNumber($caseNumber: String!, $clientId: String, $displayValue: Boolean) {
    case(caseNumber: $caseNumber, clientId: $clientId, displayValue: $displayValue) {
      account {
        name
      }
      asset
      assigned_to
      category
      close_notes
      closed_by
      contact {
        name
      }
      contract
      description
      entitlement
      number
      priority
      product
      resolution_code
      resolved_at
      state
      subcategory
      short_description
      sys_created_on
      sys_updated_on
      u_enrichments
      watch_list
    }
  }
`;

export const CASE_ADDITIONAL_DETAILS_QUERY = gql`
  query CaseAdditionalDetails($caseNumber: String!, $clientId: String) {
    caseAdditionalDetails(caseNumber: $caseNumber, clientId: $clientId) {
      additionalDetails {
        detail
        meta
        sys_created_on
        sys_id
        type
        user_name
        user_sys_id
        user_email
      }
      attachments {
        attachment {
          content_type
          file_name
          path
          size
          state
          sys_id
          thumbnail_path
        }
        element
        field_label
        initials
        login_name
        sys_created_on
        sys_id
        user_sys_id
        value
      }
    }
  }
`;

export const CASE_COMMENT_MUTATION = gql`
  mutation CaseComment($caseNumber: String!, $comment: String!, $clientId: String) {
    caseComment(caseNumber: $caseNumber, comment: $comment, clientId: $clientId) {
      caseId
      comments
      userId
    }
  }
`;

export const FIND_CASE_FOR_USER_QUERY = gql`
  query FindCaseForUser($caseNumber: String!, $userId: String) {
    findCaseForUser(caseNumber: $caseNumber, userId: $userId) {
      case {
        number
        sys_id
      }
      client {
        id
        name
      }
    }
  }
`;

export interface IAttachment {
  content_type: string;
  file_name: string;
  path: string;
  size: string;
  state: string;
  sys_id: string;
  thumbnail_path?: string;
}

export interface ICaseActivityStreamData {
  attachment?: IAttachment;
  element: string;
  field_label: string;
  initials: string;
  login_name: string;
  sys_created_on: string;
  sys_updated_on: string;
  sys_id: string;
  user_sys_id: string;
  value: string;
}

export interface ICaseAdditionalDetailItem {
  detail?: string;
  meta?: any;
  sys_created_on: string;
  sys_id: string;
  type: string;
  user_name?: string;
  user_sys_id?: string;
  user_email?: string;
}

export interface ICaseAdditionalDetails {
  additionalDetails: ICaseAdditionalDetailItem[];
  attachments: ICaseActivityStreamData[];
}

export interface ICase {
  account: {
    name: string;
  };
  asset: string;
  assigned_to: string;
  category: string;
  close_notes?: string;
  closed_by?: string;
  contact?: IServiceNowContact;
  contract: string;
  description?: string;
  entitlement: string;
  number: string;
  priority: string;
  product: string;
  resolution_code?: string;
  resolved_at?: string;
  state: string;
  subcategory?: string;
  short_description: string;
  sys_created_on: string;
  sys_updated_on: string;
  u_enrichments?: string;
  watch_list?: string;
}

export interface IServiceNowContact {
  sys_id?: String;
  country?: String;
  first_name?: String;
  last_name?: String;
  name?: String;
  phone?: String;
  state?: String;
  street?: String;
  mobile_phone?: String;
  title?: String;
  zip?: String;
}

export const Description = styled(P)`
  white-space: pre-wrap;
`;

export function CaseDetailPage() {
  const { authorize, clientId, client } = useClientContext();
  const serviceNowAccountId = useClientIdentifier({
    clientIdentifiers: client?.clientIdentifiers,
    type: 'serviceNowAccountId',
  })?.value;

  const { user: authUser } = useAuthContext();
  const { authorized: canWriteCaseManagement } = authorize({ requiredPermissions: { caseManagement: Access.write } });
  const { authorized: canExecuteCaseManagement } = authorize({
    requiredPermissions: { caseManagement: Access.execute },
  });
  const canSubmitComment: boolean = !!authUser?.serviceNowUserId && !!serviceNowAccountId && canWriteCaseManagement;
  const canResolveCase: boolean = !!authUser?.serviceNowUserId && !!serviceNowAccountId && canExecuteCaseManagement;

  const { number: caseNumber } = useParams<{ number: string }>();
  const [form] = Form.useForm();
  const [addComment, { loading: commentLoading }] = useMutation(CASE_COMMENT_MUTATION);
  const {
    data,
    loading,
    error,
    refetch: refetchCaseData,
  } = useQuery<CaseByNumberQuery, CaseByNumberQueryVariables>(CASE_BY_NUMBER_QUERY, {
    variables: {
      caseNumber: caseNumber ?? '',
      clientId,
    },
  });
  const {
    data: additionalDetailsData,
    loading: additionalDetailsLoading,
    error: additionalDetailsError,
    refetch: refetchAdditionalDetails,
  } = useQuery<CaseAdditionalDetailsQuery, CaseAdditionalDetailsQueryVariables>(CASE_ADDITIONAL_DETAILS_QUERY, {
    variables: {
      caseNumber: caseNumber ?? '',
      clientId,
    },
  });
  const [findCaseForUser, { data: findCaseData, loading: findCaseLoading }] = useLazyQuery<
    FindCaseForUserQuery,
    FindCaseForUserQueryVariables
  >(FIND_CASE_FOR_USER_QUERY);
  const [isResolveCaseModalOpen, setIsResolveCaseModalOpen] = useState<boolean>(false);
  const [isUpdateContactModalOpen, setIsUpdateContactModalOpen] = useState<boolean>(false);
  const [isUpdateWatchListModalOpen, setIsUpdateWatchListModalOpen] = useState<boolean>(false);

  const attemptToFindCase = async () => {
    const result = await findCaseForUser({
      variables: {
        caseNumber: caseNumber ?? '',
        userId: authUser?.id,
      },
    });

    if (result.error) {
      console.error(result.error);
      message.error(`An error occurred while attempting to find case ${caseNumber} from your other clients.`);
    }
  };

  useEffect(() => {
    if (error) {
      message.error(`There was an error fetching case ${caseNumber}`);
    }
  }, [error]);

  useEffect(() => {
    if (loading || data?.case) return;

    if (!loading && !data?.case) {
      attemptToFindCase();
    }
  }, [caseNumber, clientId, authUser, data, loading]);

  const caseIsOpen = !!data?.case && !['Closed', 'Resolved', 'Cancelled'].includes(data?.case?.state || '');
  // customers are allowed to comment on resolved cases which reopen the case
  const canCommentOnCase = !!data?.case && !['Closed', 'Cancelled'].includes(data?.case?.state || '');

  const validationMessages = {
    required: 'A ${name} is required!',
  };

  const refetchAllCaseData = () => {
    refetchCaseData();
    refetchAdditionalDetails();
  };

  const submitComment = async ({ comment }: any) => {
    if (!canSubmitComment) {
      if (!canWriteCaseManagement) {
        message.error('You do not have the required permissions to submit a comment.');
      } else if (!authUser?.serviceNowUserId) {
        message.error('Your user is not linked with ServiceNow. Unable to submit comment.');
      } else if (!serviceNowAccountId) {
        message.error('Your client is not linked with ServiceNow. Unable to submit comment.');
      } else {
        message.error('Unable to submit comment.');
      }

      return;
    }

    try {
      await addComment({
        variables: {
          clientId,
          caseNumber,
          comment,
        },
      });

      mixpanelTrack('case-comment');
      message.success('Comment submitted successfully!');
      form.resetFields();
      refetchAllCaseData();
    } catch (err) {
      console.error('An error occurred...', { message: err.message });
      console.error('An error occurred while submitting the comment.');
    }
  };

  // Analytics
  useEffect(() => {
    mixpanelTrack('case-details-view');
  }, [caseNumber]);

  const breadcrumb = (
    <Breadcrumb
      items={[
        {
          key: 'case-management',
          title: 'Case Management',
        },
        {
          key: `${clientId}-case-management-cases`,
          title: <Link to={`/${clientId}/case-management/cases`}>Cases</Link>,
        },
        {
          key: caseNumber,
          title: caseNumber,
        },
      ]}
      target="case-management"
    />
  );

  if (loading) {
    return (
      <Content>
        <Helmet title={`Case ${caseNumber}`} />
        {breadcrumb}
        <SpinContainer>
          <Spin />
        </SpinContainer>
      </Content>
    );
  }

  if (!loading && !data?.case && !findCaseData?.findCaseForUser) {
    return (
      <Content>
        <Helmet title={`Case ${caseNumber}`} />
        {breadcrumb}
        <EmptyState
          actions={
            <Link to={`/${clientId}/case-management/cases`}>
              <NuButton type="primary">Back to Case Management</NuButton>
            </Link>
          }
        >
          <div>
            {findCaseLoading
              ? `We're attempting to find case ${caseNumber} in the other clients you have access to.`
              : `Could not find case ${caseNumber}.`}
          </div>
          {findCaseLoading && <Spin />}
        </EmptyState>
      </Content>
    );
  }

  if (!loading && !data?.case && findCaseData?.findCaseForUser.client && findCaseData.findCaseForUser?.case) {
    return (
      <Content>
        <Helmet title={`Case ${caseNumber}`} />
        {breadcrumb}
        <EmptyState
          messageMaxWidth={1200}
          actions={
            <Link
              to={`/${findCaseData?.findCaseForUser?.client.id}/case-management/cases/${caseNumber}`}
              target="_blank"
            >
              <NuButton type="primary">Go to Case</NuButton>
            </Link>
          }
        >
          Case {caseNumber} belongs to another client you manage ({findCaseData?.findCaseForUser.client.name})
        </EmptyState>
      </Content>
    );
  }

  const attachments: ServiceNowActivityStreamEntry[] =
    additionalDetailsData?.caseAdditionalDetails?.attachments.filter((a): a is ServiceNowActivityStreamEntry => !!a) ??
    [];
  const additionalDetails: ServiceNowCaseAdditionalDetails[] =
    additionalDetailsData?.caseAdditionalDetails?.additionalDetails.filter(
      (ad): ad is ServiceNowCaseAdditionalDetails => !!ad,
    ) ?? [];

  return (
    <Content>
      <Helmet title={`Case ${caseNumber}`} />
      <UpdateCaseContactModal
        caseNumber={caseNumber ?? ''}
        contact={data?.case?.contact}
        close={setIsUpdateContactModalOpen}
        refetch={refetchAllCaseData}
        visible={isUpdateContactModalOpen}
      />
      <ResolveCaseModal
        caseNumber={caseNumber ?? ''}
        close={setIsResolveCaseModalOpen}
        refetch={refetchAllCaseData}
        visible={isResolveCaseModalOpen}
      />
      <UpdateWatchListModal
        caseNumber={caseNumber ?? ''}
        clientId={clientId ?? ''}
        open={isUpdateWatchListModalOpen}
        close={setIsUpdateWatchListModalOpen}
      />
      {breadcrumb}

      <Row justify="space-between">
        <Col lg={16}>
          <Typography.Title>{data?.case?.short_description}</Typography.Title>

          <P>
            <Tooltip
              open={data?.case ? undefined : false}
              placement="right"
              title={dayjs.utc(data?.case?.sys_created_on).local().format('MMMM Do YYYY, h:mm:ss a')}
            >
              <strong>Created:</strong> {data?.case && dayjs.utc(data?.case?.sys_created_on).local().fromNow()}
            </Tooltip>
          </P>

          <P>
            <Tooltip
              open={data?.case ? undefined : false}
              placement="right"
              title={dayjs.utc(data?.case?.sys_updated_on).local().format('MMMM Do YYYY, h:mm:ss a')}
            >
              <strong>Updated:</strong> {data?.case && dayjs.utc(data?.case?.sys_updated_on).local().fromNow()}
            </Tooltip>
          </P>

          {data?.case?.resolved_at && (
            <P>
              <Tooltip
                open={data?.case ? undefined : false}
                placement="right"
                title={dayjs.utc(data?.case?.resolved_at).local().format(`MMMM Do YYYY, h:mm:ss a`)}
              >
                <strong>Resolved:</strong> {data?.case && dayjs.utc(data?.case.resolved_at).local().fromNow()}
              </Tooltip>
            </P>
          )}

          <P>
            <strong>Contact:</strong> {data?.case?.contact?.name}
          </P>

          <P>
            <strong>Assigned To:</strong> {data?.case?.assigned_to}
          </P>

          <P>
            <strong>State:</strong> {data?.case?.state}
          </P>

          {data?.case?.closed_by && (
            <P>
              <strong>Closed by:</strong> {data?.case.closed_by}
            </P>
          )}
          {data?.case?.resolution_code && (
            <P>
              <strong>Resolution Code:</strong> {data?.case.resolution_code}
            </P>
          )}
          {data?.case?.close_notes && (
            <P>
              <strong>Close Notes:</strong> {data?.case.close_notes}
            </P>
          )}

          <P>
            <strong>Priority:</strong> {data?.case && <PriorityText priority={data?.case?.priority} />}
          </P>

          <P>
            <strong>Contract:</strong> {data?.case?.contract}
          </P>

          <P>
            <strong>Asset:</strong> {data?.case?.asset}
          </P>

          <P>
            <strong>Product:</strong> {data?.case?.product}
          </P>

          <P>
            <strong>Entitlement:</strong> {data?.case?.entitlement}
          </P>

          <P>
            <strong>Category:</strong> {data?.case?.category}
          </P>

          <P>
            <strong>Subcategory:</strong> {data?.case?.subcategory}
          </P>

          <P style={{ marginBottom: 0 }}>
            <strong>Description:</strong>
          </P>
          <Description>{data?.case?.description}</Description>
        </Col>
        <Col lg={8}>
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Row justify="end">
              {caseIsOpen && (
                <Tooltip title="Update Watch List">
                  <Button
                    type="default"
                    onClick={() => setIsUpdateWatchListModalOpen(true)}
                    disabled={!canResolveCase || loading}
                    style={{ marginRight: '1rem' }}
                    loading={loading}
                    icon={<EyeOutlined />}
                  />
                </Tooltip>
              )}

              {caseIsOpen && (
                <Button
                  type="primary"
                  onClick={() => setIsUpdateContactModalOpen(true)}
                  disabled={!canResolveCase || loading}
                  style={{ marginRight: '1rem' }}
                  loading={loading}
                >
                  Update Contact
                </Button>
              )}

              {caseIsOpen && (
                <Button
                  type="primary"
                  onClick={() => setIsResolveCaseModalOpen(true)}
                  disabled={!canResolveCase || loading}
                  loading={loading}
                >
                  Resolve Case
                </Button>
              )}
            </Row>
            <div>
              <Typography.Paragraph type="secondary" style={{ marginBottom: '0.25rem' }}>
                Account
              </Typography.Paragraph>
              {data?.case?.account?.name}
            </div>
            <div>
              <AttachmentsList
                attachments={attachments}
                caseIsOpen={caseIsOpen}
                caseNumber={caseNumber ?? ''}
                error={additionalDetailsError}
                loading={additionalDetailsLoading}
                onDelete={refetchAllCaseData}
              />
              {caseIsOpen && (
                <FileUploader
                  caseNumber={caseNumber ?? ''}
                  loading={additionalDetailsLoading}
                  onUploadComplete={refetchAllCaseData}
                />
              )}
            </div>
            {data?.case?.u_enrichments ? (
              <>
                <Typography.Paragraph type="secondary" style={{ marginBottom: '0.25rem' }}>
                  Enrichments
                </Typography.Paragraph>
                <Input.TextArea
                  rows={7}
                  value={data?.case.u_enrichments}
                  style={{ backgroundColor: baseTheme.color.primaryGray }}
                />
              </>
            ) : null}
          </Space>
        </Col>
        {data?.case && (
          <InfoCard title="Case Details" style={{ marginTop: '5rem', width: '100%' }}>
            {canCommentOnCase && (
              <div style={{ padding: '1.5rem', paddingBottom: 0 }}>
                <Form
                  style={{ paddingTop: '0.5rem' }}
                  form={form}
                  onFinish={submitComment}
                  validateMessages={validationMessages}
                >
                  <Form.Item name={['comment']} required rules={[{ required: true }]}>
                    <Input.TextArea placeholder="Type your message here..." disabled={loading || commentLoading} />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={!canSubmitComment || loading || commentLoading}
                      loading={commentLoading}
                    >
                      Send
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            )}
            <div style={{ padding: '1.5rem', paddingBottom: 0 }}>
              <CaseDetailsTimeline
                caseNumber={caseNumber ?? ''}
                createdOn={data?.case?.sys_created_on}
                details={additionalDetails}
                error={additionalDetailsError}
                loading={additionalDetailsLoading}
              />
            </div>
          </InfoCard>
        )}
        <Row style={{ marginTop: '1rem', width: '100%' }} justify="end">
          {caseIsOpen && (
            <Button
              type="primary"
              onClick={() => setIsResolveCaseModalOpen(true)}
              disabled={!canResolveCase || loading}
              loading={loading}
            >
              Resolve Case
            </Button>
          )}
        </Row>
      </Row>
    </Content>
  );
}
