import { gql, useQuery } from '@apollo/client';
import { PropsWithChildren, createContext, useContext } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { IAuthorizationResponse, IClient, IPermissions } from 'types';
import {
  ClientContextQuery,
  ClientContextServiceNowQuery,
  type ClientContextServiceNowQueryVariables,
} from '../types/graph-codegen/graph-types';
import { useAuthorize } from './authorization';
import { NuButton } from './nuspire';
import { FullPageSpin } from './nuspire/full-page-spin';
import EmptyState from './nuspire/nu-empty-state';

export const CLIENT_CONTEXT = gql`
  query ClientContext($clientId: String!) {
    getClientById(id: $clientId) {
      id
      createdAt
      clientIdentifiers {
        type
        value
      }
      effectivePermissions
      industry {
        id
        name
      }
      isMultiTenancyEnabled
      name
      partnerDetails {
        isApproved
      }
      path
      samlIdp {
        childrenInheritSSO
        id
        inheritedFromClientId
      }
      type
    }
  }
`;

export const CLIENT_CONTEXT_SERVICENOW_QUERY = gql`
  query ClientContextServiceNow($clientId: String!) {
    getClientById(id: $clientId) {
      serviceOfferings {
        id
        sysId
        serviceOfferingSysId
        serviceOfferingName
      }
    }
  }
`;

export type PartnerDetails = {
  isApproved?: boolean;
};

export const ClientContext = createContext<IClientContext>({
  loading: true,
  authorize: () => ({ authorized: false }),
});

export function useClientContext(): IClientContext {
  return useContext(ClientContext);
}

export interface IClientContext {
  clientId?: string;
  client?: IClient;
  loading: boolean;
  authorize: (args: { requiredPermissions: IPermissions }) => IAuthorizationResponse;
}

export function ClientContextProvider(props: PropsWithChildren<{ clientId: string }>) {
  const { clientId, children } = props;
  const { data, error, loading } = useQuery<ClientContextQuery>(CLIENT_CONTEXT, {
    variables: { clientId },
    errorPolicy: 'all',
  });
  const { data: serviceNowData } = useQuery<ClientContextServiceNowQuery, ClientContextServiceNowQueryVariables>(
    CLIENT_CONTEXT_SERVICENOW_QUERY,
    {
      variables: { clientId },
      errorPolicy: 'all',
    },
  );
  const client = data?.getClientById as Omit<IClient, 'serviceOfferings'> | undefined;
  const clientServiceNow = serviceNowData?.getClientById as Pick<IClient, 'serviceOfferings'>;
  const effectivePermissions = client?.effectivePermissions;
  const authorize = useAuthorize({ effectivePermissions });

  const context: IClientContext = {
    clientId,
    loading,
    client: client
      ? {
          ...client,
          ...(clientServiceNow ?? {}),
        }
      : undefined,
    authorize,
  };

  const isUnauthenticated: boolean = error?.message?.includes('401') ?? false;

  if (isUnauthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (error) {
    return (
      <EmptyState
        noBorderRadius
        fullHeight
        actions={
          <Link to="/">
            <NuButton type="primary">Back Home</NuButton>
          </Link>
        }
      >
        {error?.message ?? 'There was a problem fetching this client.'}
      </EmptyState>
    );
  }

  if (client) {
    return <ClientContext.Provider value={context}>{children}</ClientContext.Provider>;
  }

  if (loading) {
    return <FullPageSpin tip="Loading Client..." />;
  }

  return <EmptyState>Could not find matching client.</EmptyState>;
}
