import { WidgetComponentProps } from '..';
import { Statistic, Row, Col, Tooltip } from 'antd';
import { useContext } from 'react';
import { useDataExplorerContext } from 'components/data-explorer/data-explorer';
import * as NuIcon from 'components/nuspire/nu-icon';
import baseTheme from 'components/theme';

export type Statistic = {
  key: string;
  value: string | number;
  title?: string;
  prefix?: string;
  suffix?: string;
};

export type StatisticsData = {
  statistics: Statistic[];
};

export type StatisticsConfig = {};

export default function Statistics(props: WidgetComponentProps<StatisticsConfig, StatisticsData>) {
  const {
    data: { statistics },
  } = props;

  const dataExplorerContext = useDataExplorerContext();

  return (
    <Row gutter={16}>
      {statistics.map((stat) => {
        const showSizeWarning = (() => {
          if (!dataExplorerContext) {
            return false;
          }

          if (statistics.length > 1) return false;
          if (stat.suffix) {
            return false;
          }

          if (typeof stat.value === 'number' && stat.value > 500000) {
            return true;
          }

          return false;
        })();

        return (
          <Col key={stat.key} md={8}>
            <Statistic
              title={stat.title}
              value={stat.value}
              prefix={stat.prefix}
              suffix={
                stat.suffix ?? showSizeWarning ? (
                  <Tooltip overlay="For best performance adjust the filters (including the date picker) before executing a query. Use the counter to determine how many records you are retrieving from the SIEM. 50,000 or less records is ideal.">
                    <NuIcon.Warning
                      style={{
                        color: baseTheme.color.orange,
                      }}
                    />
                  </Tooltip>
                ) : undefined
              }
            />
          </Col>
        );
      })}
    </Row>
  );
}
