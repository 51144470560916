/* eslint-disable camelcase */
import { ColumnsType } from 'antd/es/table';
import { gql } from '@apollo/client';
import SearchServiceNowModal from '../search-servicenow-modal';
import { getServiceNowAccountCategory } from '../../../utils/servicenow';
import { humanReadableBoolean } from '../../../utils/strings';

const SERVICENOW_ACCOUNTS_QUERY = gql`
  query ServiceNowAccounts(
    $limit: Int
    $skip: Int
    $sortBy: String
    $sortOrder: String
    $search: String
    $active: Boolean
  ) {
    serviceNowAccounts(
      limit: $limit
      skip: $skip
      sortBy: $sortBy
      sortOrder: $sortOrder
      search: $search
      active: $active
    ) {
      accounts {
        name
        number
        parent {
          name
          number
        }
        sys_id
        sys_updated_on
        u_client_category
        u_parent_escalation_contacts
        u_parent_passphrase
      }
      total
    }
  }
`;

type SearchServiceNowAccountsModalProps = {
  defaultSearchValue?: string;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  updateServiceNowId: (newId: string) => void;
};

export const defaultSortBy = 'created';

export default function SearchServiceNowAccountsModal(props: SearchServiceNowAccountsModalProps) {
  const { defaultSearchValue, isVisible, setIsVisible, updateServiceNowId } = props;

  const columns: ColumnsType<object> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.name - b.name,
      showSorterTooltip: false,
    },
    {
      title: 'Account Number',
      dataIndex: 'number',
      key: 'number',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.number - b.number,
      showSorterTooltip: false,
    },
    {
      title: 'Sys Id',
      dataIndex: 'sys_id',
      key: 'sys_id',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.sys_id - b.sys_id,
      showSorterTooltip: false,
    },
    {
      title: 'Parent',
      dataIndex: 'parent',
      key: 'parent',
      render(parent?: { name?: string; number?: string }) {
        if (!parent) return '';
        return `${parent?.name} (${parent?.number})`;
      },
    },
    {
      title: 'Updated At',
      dataIndex: 'sys_updated_on',
      key: 'sys_updated_on',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.sys_updated_on - b.sys_updated_on,
      showSorterTooltip: false,
    },
    {
      title: 'Account Type',
      dataIndex: 'u_client_category',
      key: 'u_client_category',
      render: getServiceNowAccountCategory,
    },
    {
      title: 'Escalations on Parent?',
      dataIndex: 'u_parent_escalation_contacts',
      key: 'u_parent_escalation_contacts',
      render: humanReadableBoolean,
    },
    {
      title: 'Passphrase on Parent?',
      dataIndex: 'u_parent_passphrase',
      key: 'u_parent_passphrase',
      render: humanReadableBoolean,
    },
  ];

  return (
    <SearchServiceNowModal
      dataSourceKey="accounts"
      defaultSearchValue={defaultSearchValue}
      defaultSortBy={defaultSortBy}
      formatDescriptor={(account: any) => `${account.name}`}
      graphQuery={SERVICENOW_ACCOUNTS_QUERY}
      isVisible={isVisible}
      modalTitle="Search for ServiceNow Accounts"
      rootGraphKey="serviceNowAccounts"
      setIsVisible={setIsVisible}
      tableColumns={columns}
      updateServiceNowId={updateServiceNowId}
    />
  );
}
