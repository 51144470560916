export type ClientIdArgs = {
  clientId: string;
}

export const settingsPath = (args: ClientIdArgs) => `/${args.clientId}/settings`

export const settingsOverviewPath = (args: ClientIdArgs) => `${settingsPath(args)}/overview`

export const escalationsPath = (args: ClientIdArgs) => `${settingsPath(args)}/escalations`
export const addEscalationContactPath = (args: ClientIdArgs) => `${escalationsPath(args)}/add`; 
export const editEscalationContactPath = (args: ClientIdArgs & { userId: string }) => `${escalationsPath(args)}/user/${args.userId}`
export const integrationsPath = (args: ClientIdArgs) => `${settingsPath(args)}/integrations`;

export const blockListPath = (args: ClientIdArgs) => `${settingsPath(args)}/block-list`;

export const selfServiceSSOPath = (args: ClientIdArgs) => `${settingsPath(args)}/sso`;

export const automationsPath = (args: ClientIdArgs) => `${settingsPath(args)}/automations`;

export const assetsPath = (args: ClientIdArgs) => `${settingsPath(args)}/assets`

export const necesPath = (args: ClientIdArgs) => `${settingsPath(args)}/neces`;

// ORGANIZATION
export const clientUsersPath = (args: ClientIdArgs) => `${settingsPath(args)}/users`; 
export const clientUserDetailPath = (args: ClientIdArgs & { userId: string }) => `${clientUsersPath(args)}/${args.userId}`;

export const clientUserGroupsPath = (args: ClientIdArgs) => `${settingsPath(args)}/groups`

export const managedClientsPath = (args: ClientIdArgs) => `${settingsPath(args)}/managed-clients`; 
export const addManagedClientPath = (args: ClientIdArgs) => `${managedClientsPath(args)}/add`;

