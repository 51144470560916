import { gql, useMutation, useQuery } from '@apollo/client';
import { ClientFeatureFlagsQuery } from 'types/graph-codegen/graph-types';
import { Delete as DeleteIcon } from 'components/nuspire/nu-icon';
import { CLIENT_FEATURE_FLAG_FRAGMENT } from './feature-fragments';
import { Link, NuButton, NuCard, NuCardTitle } from 'components/nuspire';
import { message, Table, App } from 'antd';
import { Unpacked } from 'types';
import { AddClientFeatureButton } from './add-client-feature-button';
import { ManagedClientFeatureCheckbox } from './managed-client-feature-checkbox';

export const CLIENT_FEATURE_FLAGS = gql`
  ${CLIENT_FEATURE_FLAG_FRAGMENT}

  query ClientFeatureFlags($slug: String!) {
    clientFeatureFlags(slug: $slug) {
      ...ClientFeatureFlagFields
      #  This will have client here momentarily

      client {
        id
        name
      }
    }
  }
`;

// get types from graph.
type ClientFeatureFlag = Unpacked<NonNullable<ClientFeatureFlagsQuery['clientFeatureFlags']>>;
type Client = ClientFeatureFlag['client'];

export const REMOVE_CLIENT_FROM_FEATURE = gql`
  mutation RemoveClientFromFeature($clientId: String!, $slug: String!) {
    removeClientFeatureFlag(clientId: $clientId, slug: $slug) # Returns boolean
  }
`;

export function ClientFeatureActions(props: { client: Client; slug: string }) {
  const [removeClientFeature] = useMutation(REMOVE_CLIENT_FROM_FEATURE);
  const {
    client: { name, id: clientId },
    slug,
  } = props;

  const { modal } = App.useApp();

  const handleDelete = () =>
    modal.confirm({
      title: 'Remove Feature from Client',
      content: `Client "${name}" (${clientId}) will no longer have access to this feature.`,
      onOk: async () => {
        try {
          await removeClientFeature({
            variables: {
              clientId,
              slug,
            },
            refetchQueries: ['ClientFeatureFlags', 'FeatureFlagsByClient'],
          });
          message.error(`Successfully removed Feature from Client`);
        } catch (err: any) {
          console.error(err);
          message.error(`Failed to remove Feature from Client.`);
        }
      },
    });

  return (
    <NuButton shape="circle" onClick={handleDelete} danger ghost>
      <DeleteIcon />
    </NuButton>
  );
}

export function FeatureClientList(props: { slug: string; cardTitle?: string; addClientModalTitle?: string }) {
  const { slug, cardTitle = 'Clients', addClientModalTitle } = props;

  const { data, loading, error } = useQuery<ClientFeatureFlagsQuery>(CLIENT_FEATURE_FLAGS, {
    variables: { slug },
  });

  const dataSource = data?.clientFeatureFlags;

  return (
    <NuCard>
      <NuCardTitle
        title={cardTitle}
        actions={<AddClientFeatureButton modalTitle={addClientModalTitle} slug={slug} />}
      />
      <Table
        loading={loading}
        dataSource={dataSource}
        pagination={{
          pageSize: 100,
        }}
        columns={[
          {
            key: 'name',
            title: 'Client',
            dataIndex: ['client', 'name'],
            render: (name, record) => (
              <Link to={`/admin/clients/${record?.clientId}`} mode="plain">
                {name ?? record?.clientId}
              </Link>
            ),
          },

          //  Should have a column here to allow us to remove a client from a feature.

          {
            key: 'include-child-clients',
            title: 'Include Managed Clients',
            dataIndex: 'includeChildClients',
            render: (_include, record) => (
              <ManagedClientFeatureCheckbox
                slug={slug}
                clientId={record.clientId}
                includeChildClients={record.includeChildClients}
              />
            ),
            align: 'center',
          },

          {
            key: 'actions',
            dataIndex: 'client',
            render: (client, record) => <ClientFeatureActions client={client} slug={slug} />,
          },
        ]}
      />
    </NuCard>
  );
}
