import { useCallback, useEffect, useState } from 'react';
import { Select } from 'antd';
import { ActionInputProps } from '../../action-form-field';
import { useClientContext } from 'components/client-context-provider';
import { gql, useQuery } from '@apollo/client';
import debounce from 'lodash.debounce';

const SEARCH_QUALYS_NETWORKS = gql`
  query SearchQualysNetworks($clientId: String!, $queryString: String) {
    searchQualysNetworks(clientId: $clientId, queryString: $queryString) {
      items {
        id
        name
      }
    }
  }
`;

function QualysNetworkIdSelect(props: ActionInputProps) {
  const { clientId: contextClientId } = useClientContext();
  const clientId = props.clientId ?? contextClientId;

  const { value, onChange } = props;

  const [queryString, setQueryString] = useState<string | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string | undefined>(queryString);
  const { data, loading } = useQuery(SEARCH_QUALYS_NETWORKS, { variables: { clientId, queryString } });

  const handleQueryString = (val?: string) => {
    if (val?.length) {
      setSearchValue(val);
    } else {
      setSearchValue(undefined);
    }
  };
  const networks = data?.searchQualysNetworks?.items;

  // debounce search
  const debouncedSearch = useCallback(
    debounce((newSearch: string | undefined) => {
      setQueryString(newSearch);
    }, 600),
    [],
  );

  useEffect(() => {
    debouncedSearch(searchValue);
  }, [searchValue]);

  const options = networks?.map((n) => ({
    label: n.name,
    value: n.id,
  }));

  const selectValue = value?.map((n) => ({
    label: n.name,
    value: n.id,
  }));

  return (
    <Select
      labelInValue
      value={selectValue}
      mode="multiple"
      loading={loading}
      showSearch
      searchValue={searchValue}
      filterOption={false}
      onSearch={handleQueryString}
      options={options}
      onChange={(values) => {
        const transformedValues = values.length
          ? values.map((option) => ({
              name: option.label,
              id: option.value,
            }))
          : undefined;

        onChange(transformedValues);
      }}
      placeholder="Select Qualys Network"
    />
  );
}

export default QualysNetworkIdSelect;
