import React, { ReactNode } from 'react';
import styled from 'styled-components';
import baseTheme from 'components/theme';
import classnames from 'classnames';
import * as NuIcon from 'components/nuspire/nu-icon';
import { Link } from 'components/nuspire';
import { Space, Typography } from 'antd';

export interface NuPageHeaderProps {
  /**
   * Will Default to Left arrow if absent and backUrl is present.
   */
  backIcon?: ReactNode | boolean;
  /**
   * will wrap back icon in Link with this path
   */

  backUrl?: string;
  /**
   * Title of page or section
   */
  title: ReactNode;

  onTitleEdit?: (value: string) => void;

  /**
   *
   */
  subHeading?: ReactNode;
  /**
   * Default to 1 for Name of page.
   * use 2 and 3 for sub and sub sub sections.
   */
  level?: 1 | 2 | 3 | 4 | 5;

  /**
   * Actions to be in line with title.
   */
  actions?: ReactNode;

  /**
   * Actions underneatht he subHeading
   */
  subActions?: ReactNode;

  styles?: {
    root?: React.CSSProperties;
  };
  dataIntercomTarget?: string;
}

const Root = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Left = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  @media (min-width: ${(p) => p.theme.bp.md}) {
    width: initial;
  }
`;

const IconWrapper = styled.div`
  height: 40px;
  width: 40px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  color: ${(p) => p.theme.color.primaryBlue};
  border-radius: 8px;

  &.clickable {
    cursor: pointer;
    transition: all 0.25s ease;

    &:hover {
      background-color: ${(p) => p.theme.color.gray2};
    }
  }
`;

const TitleWrapper = styled.div`
  padding-right: 32px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1em;
    margin-bottom: 0;
  }

  .ant-typography-edit-content {
    flex: 1;
    width: 100%;
    margin-left: 16px;
    margin-top: 24px;
    position: relative;
    top: 12px;
  }
`;

const Actions = styled.div`
  display: flex;

  @media (min-width: ${(p) => p.theme.bp.md}) {
    justify-content: flex-end;
  }
`;

const HeaderTitle = styled(Typography.Title)`
  h1.ant-typography&,
  h2.ant-typography&,
  h3.ant-typography&,
  h4.ant-typography&,
  h5.ant-typography&,
  h6.ant-typography& {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

const SubActions = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * User can pass custom back Icon
 * If no custom icon is passed BUT a backUrl is passed, we default to the left arrow icon.
 */
function BackIcon(props: { backIcon?: ReactNode | boolean; backUrl?: string }) {
  const { backIcon, backUrl } = props;

  const icon =
    backIcon ||
    (backUrl ? (
      <NuIcon.ArrowLeft
        style={{
          color: baseTheme.color.gray5,
        }}
      />
    ) : null);

  if (!icon) return null;
  const backIconClasses = classnames('back-icon-wrapper', {
    clickable: Boolean(backUrl),
  });

  const iconJsx = <IconWrapper className={backIconClasses}>{icon}</IconWrapper>;

  if (backUrl) {
    return <Link to={backUrl} mode="plain">{iconJsx}</Link>;
  }

  return iconJsx;
}

function PageHeader(props: NuPageHeaderProps) {
  const {
    title,
    backIcon = false,
    backUrl,
    level = 1,
    actions,
    subHeading,
    subActions,
    styles = {},
    onTitleEdit,
    dataIntercomTarget,
  } = props;

  return (
    <Root className={`page-header level-${level}`} style={styles.root}>
      <Left>
        <BackIcon backIcon={backIcon} backUrl={backUrl} />
        <TitleWrapper>
          <Space direction="vertical" style={{ width: '100%'}}>
            <HeaderTitle
              editable={
                onTitleEdit
                ? {
                  tooltip: 'Click to edit',
                  onChange: onTitleEdit,
                  icon: <NuIcon.Edit style={{ fontSize: '0.75em', color: baseTheme.color.gray4 }} />,
                }
                : undefined
              }
              level={level}
              data-intercom-target={dataIntercomTarget}
              >
              {title}
            </HeaderTitle>

            {subHeading && <Typography.Paragraph>{subHeading}</Typography.Paragraph>}

            {subActions && <SubActions>{subActions}</SubActions>}
          </Space>
        </TitleWrapper>
      </Left>

      {actions && <Actions>{actions}</Actions>}
    </Root>
  );
}

export default PageHeader;
