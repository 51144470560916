import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GetEntitlementsForMainMenuQuery } from '../../../../types/graph-codegen/graph-types';
import { isDefined } from '../../../../utils/is-defined';
import { oktaAuth } from '../../../../utils/okta';
import { useClientContext } from '../../../client-context-provider';
import { MenuItem } from './get-main-menu-items';

const GET_ENTITLEMENTS = gql`
  query GetEntitlementsForMainMenu($clientId: String) {
    entitlements(clientId: $clientId) {
      sys_id
      entitlement_name
      u_url
    }
  }
`;

function openInNewTab(url?: string) {
  if (!url) {
    return;
  }

  const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
  if (newWindow) {
    newWindow.opener = null;
  }
}

export function useEntitlementsSubmenuItems() {
  const { clientId } = useClientContext();
  const [items, setItems] = useState<MenuItem[]>([]);
  const { data, loading } = useQuery<GetEntitlementsForMainMenuQuery>(GET_ENTITLEMENTS, {
    variables: {
      clientId: clientId ?? undefined,
    },
  });

  useEffect(() => {
    const entitlements = data?.entitlements.filter(isDefined) ?? [];
    const filtered = entitlements.filter((curr, index) => {
      if (curr.entitlement_name === undefined) return false;
      if (curr.u_url === undefined) return false;
      return entitlements.findIndex((e) => e.entitlement_name === curr.entitlement_name) === index;
    });

    setItems(
      filtered.map((e) => {
        const menuItem: MenuItem = {
          key: e.entitlement_name!,
          label: e.entitlement_name,
          onClick: () => openInNewTab(e.u_url),
        };

        // special handling for nuSIEM entitlement
        // sends the Okta session ID as a URL parameter to nuSIEM for it to validate and use the current Okta session
        // must match what is in ServiceNow to work
        if (e.entitlement_name!.toLowerCase() === 'nusiem') {
          menuItem.onClick = async () => {
            const session: any = await oktaAuth.session.get();
            openInNewTab(`${e.u_url}?session_id=${session.id}`);
          };
        }

        return menuItem;
      })
    );
  }, [data]);

  return {
    submenuItems: items,
    loading,
  };
}
