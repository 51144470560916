export function jsonSafeParse<T = any>(s: string, logErrors: boolean = true): T | undefined {
  try {
    return JSON.parse(s);
  } catch (err) {
    if (logErrors) {
      console.warn('Failed to parse JSON: ', s);
    }
    return undefined;
  }
}
