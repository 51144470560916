
export default () => (
  <svg width="1em" height="1em" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.4711 11.1785H40.7178C41.3135 11.1785 41.8002 10.6932 41.8002 10.0966C41.8002 9.49992 41.3144 9.01416 40.7178 9.01416H16.4711C15.8745 9.01416 15.3892 9.49992 15.3892 10.0966C15.3892 10.6932 15.8745 11.1785 16.4711 11.1785Z"
      fill="currentColor"
    />
    <path
      d="M4.91898 12.4674C5.2481 12.9232 5.7343 13.185 6.25262 13.185L6.3085 13.1842C6.84794 13.1648 7.33414 12.8678 7.6417 12.3688L12.0536 5.22632C12.366 4.719 12.2085 4.05108 11.7011 3.73736C11.5295 3.63132 11.3333 3.57544 11.1327 3.57544C10.7543 3.57544 10.4093 3.76772 10.2113 4.08936L6.21258 10.5613L4.15954 7.71892C3.82338 7.25296 3.11718 7.1368 2.64902 7.47472C2.4145 7.64368 2.26006 7.89448 2.21386 8.1796C2.16766 8.46516 2.23542 8.7516 2.40482 8.98568L4.91898 12.4674Z"
      fill="currentColor"
    />
    <path
      d="M40.7178 22.634H16.4711C15.8745 22.634 15.3892 23.1198 15.3892 23.716C15.3892 24.3126 15.8745 24.7988 16.4711 24.7988H40.7178C41.3135 24.7988 41.8002 24.3131 41.8002 23.716C41.8002 23.1198 41.3144 22.634 40.7178 22.634Z"
      fill="currentColor"
    />
    <path
      d="M11.7007 17.3575C11.5295 17.2519 11.3333 17.1956 11.1327 17.1956C10.7543 17.1956 10.4093 17.3883 10.2113 17.7095L6.21258 24.181L4.15954 21.3386C3.82338 20.8722 3.11718 20.7565 2.64902 21.0944C2.4145 21.2634 2.26006 21.5142 2.21386 21.7993C2.16766 22.0848 2.23542 22.3713 2.40482 22.6054L4.91942 26.0871C5.24854 26.5429 5.7343 26.8047 6.25262 26.8047L6.30806 26.8038C6.84838 26.7845 7.33414 26.4875 7.64214 25.9881L12.0536 18.8464C12.3664 18.3391 12.2085 17.6708 11.7007 17.3575Z"
      fill="currentColor"
    />
    <path
      d="M40.7178 36.2542H16.4711C15.8745 36.2542 15.3892 36.7399 15.3892 37.3361C15.3892 37.9327 15.8745 38.4189 16.4711 38.4189H40.7178C41.3135 38.4189 41.8002 37.9332 41.8002 37.3361C41.8002 36.7399 41.3144 36.2542 40.7178 36.2542Z"
      fill="currentColor"
    />
    <path
      d="M11.7007 30.9769C11.5295 30.8717 11.3333 30.8154 11.1327 30.8154C10.7543 30.8154 10.4093 31.0081 10.2113 31.3293L6.21258 37.8009L4.15954 34.9585C3.82294 34.4916 3.1163 34.3772 2.64902 34.7143C2.4145 34.8837 2.25962 35.134 2.21386 35.42C2.16766 35.7043 2.23542 35.9911 2.40482 36.2252L4.91942 39.7069C5.24854 40.1628 5.7343 40.4246 6.25262 40.4246L6.3085 40.4237C6.84794 40.4043 7.33414 40.1069 7.6417 39.6093L12.0536 32.4663C12.3664 31.959 12.2085 31.2906 11.7007 30.9769Z"
      fill="currentColor"
    />
  </svg>
);
