
export default () => (
  <svg width="1em" height="1em" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3898 2.30005H7.78049C4.75875 2.30005 2.30005 4.75875 2.30005 7.78049V15.3898C2.30005 18.4116 4.75875 20.8698 7.78049 20.8698H15.3898C18.4116 20.8698 20.8702 18.4116 20.8702 15.3898V7.78049C20.8702 4.75875 18.4116 2.30005 15.3898 2.30005ZM18.607 15.3898C18.607 17.1636 17.1636 18.607 15.3898 18.607H7.78049C6.00627 18.607 4.56325 17.1636 4.56325 15.3898V7.78049C4.56325 6.00627 6.00627 4.56279 7.78049 4.56279H15.3898C17.1636 4.56279 18.607 6.00627 18.607 7.78049V15.3898Z"
      fill="currentColor"
    />
    <path
      d="M38.2196 2.30005H30.6103C27.5886 2.30005 25.1299 4.75875 25.1299 7.78049V15.3898C25.1299 18.4116 27.5886 20.8698 30.6103 20.8698H38.2196C41.2409 20.8698 43.7001 18.4116 43.7001 15.3898V7.78049C43.7001 4.75875 41.2409 2.30005 38.2196 2.30005ZM41.4369 15.3898C41.4369 17.1636 39.9939 18.607 38.2196 18.607H30.6103C28.8366 18.607 27.3931 17.1636 27.3931 15.3898V7.78049C27.3931 6.00627 28.8366 4.56279 30.6103 4.56279H38.2196C39.9939 4.56279 41.4369 6.00627 41.4369 7.78049V15.3898Z"
      fill="currentColor"
    />
    <path
      d="M15.3898 25.1299H7.78049C4.75875 25.1299 2.30005 27.5886 2.30005 30.6103V38.2196C2.30005 41.2409 4.75875 43.7001 7.78049 43.7001H15.3898C18.4116 43.7001 20.8702 41.2409 20.8702 38.2196V30.6103C20.8702 27.5886 18.4116 25.1299 15.3898 25.1299ZM18.607 38.2196C18.607 39.9939 17.1636 41.4369 15.3898 41.4369H7.78049C6.00627 41.4369 4.56325 39.9939 4.56325 38.2196V30.6103C4.56325 28.8366 6.00627 27.3931 7.78049 27.3931H15.3898C17.1636 27.3931 18.607 28.8366 18.607 30.6103V38.2196Z"
      fill="currentColor"
    />
    <path
      d="M38.2196 25.1299H30.6103C27.5886 25.1299 25.1299 27.5886 25.1299 30.6103V38.2196C25.1299 41.2409 27.5886 43.7001 30.6103 43.7001H38.2196C41.2409 43.7001 43.7001 41.2409 43.7001 38.2196V30.6103C43.7001 27.5886 41.2409 25.1299 38.2196 25.1299ZM41.4369 38.2196C41.4369 39.9939 39.9939 41.4369 38.2196 41.4369H30.6103C28.8366 41.4369 27.3931 39.9939 27.3931 38.2196V30.6103C27.3931 28.8366 28.8366 27.3931 30.6103 27.3931H38.2196C39.9939 27.3931 41.4369 28.8366 41.4369 30.6103V38.2196Z"
      fill="currentColor"
    />
  </svg>
);
