import { gql, useQuery } from '@apollo/client';
import { NuButton, NuCard, NuCardContent, NuCardTitle } from 'components/nuspire';
import { reportsPath } from 'components/reporting-and-analysis/paths';
import { Link } from 'react-router-dom';
import { Table, Typography } from 'antd';
import { useReportDownload } from 'components/reports/use-report-download';
import { DownloadOutlined } from '@ant-design/icons';

const RECENT_REPORTS_CARD = gql`
  query RecentReportsCard($clientId: String!) {
    reportArchives(clientId: $clientId) {
      id
      dashboards {
        name
      }
      name
      completedAt
    }
  }
`;

export default function RecentReportsCard(props: { clientId: string }) {
  const { clientId } = props;
  const { data, loading } = useQuery(RECENT_REPORTS_CARD, { variables: { clientId } });
  const { handleDownload, downloadsLoading } = useReportDownload();

  const reports = data?.reportArchives ?? [];

  return (
    <NuCard fullHeight>
      <NuCardTitle
        title="Recent Reports"
        actions={
          <Link to={reportsPath({ clientId })}>
            <NuButton type="link">View All</NuButton>
          </Link>
        }
      />

      <NuCardContent>
        <Table
          rowKey="id"
          dataSource={reports}
          pagination={false}
          loading={loading}
          columns={[
            {
              key: 'name',
              dataIndex: 'name',
              title: 'Name',
              render: (name, { id, completedAt }) => (
                <Typography.Link
                  onClick={() =>
                    handleDownload({
                      clientId,
                      id,
                      completedAt,
                    })
                  }
                >
                  {name}
                </Typography.Link>
              ),
            },
            {
              key: 'download',
              render: (_, { id, completedAt }) => {
                return (
                  <NuButton
                    type="link"
                    onClick={() =>
                      handleDownload({
                        clientId,
                        id,
                        completedAt,
                      })
                    }
                    size="small"
                  >
                    <DownloadOutlined style={{ margin: '0 6px' }} />
                  </NuButton>
                );
              },
            },
          ]}
        />
      </NuCardContent>
    </NuCard>
  );
}
