import { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Input, Select, Form } from 'antd';
import { NuButton, NuCard, NuCardContent } from 'components/nuspire';
import { CaseManagementFilters, IUser } from 'types/index';
import PhoneInput, { defaultCountryCodeKey, PhoneInputFormValues } from 'components/phone-input';
import { getCountryCodeKey, parsePhoneNumberFromFormattedString } from 'utils/phone-number';
import { Features, useFeatureFlag } from 'components/feature-flags';
import { personas } from '../../personas';

export const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateUserProfileAndClientDetails($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      id
      email
      firstName
      lastName
      mobilePhoneNumber
      mobilePhoneNumberCountry
      persona
      phoneNumber
      phoneNumberCountry
      phoneNumberExt
      title
      caseManagementFilters {
        cases {
          includeChildren
          priorities
          search
          states
          user
        }
        incidents {
          includeChildren
          priorities
          search
          states
          user
        }
      }
    }
  }
`;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

export type UpdateUserProfileMutation = {
  updateUserProfile?: {
    id: string;
    escalationOrder?: string;
    email: string;
    firstName: string;
    lastName: string;
    mobilePhoneNumber?: string;
    mobilePhoneNumberCountry?: string;
    persona: string;
    phoneNumber?: string;
    phoneNumberCountry?: string;
    phoneNumberExt?: string;
    title?: string;
    caseManagementFilters?: CaseManagementFilters;
  };
};

export type UpdateUserProfileInput = {
  caseManagementFilters?: CaseManagementFilters;
  escalationOrder?: string;
  email: string;
  firstName: string;
  lastName: string;
  mobilePhoneNumber?: string;
  mobilePhoneNumberCountry?: string;
  persona: string;
  phoneNumber?: string;
  phoneNumberCountry?: string;
  phoneNumberExt?: string;
  title?: string;
};

export type UpdateUserProfileMutationVariables = {
  input: UpdateUserProfileInput;
};

export type UserProfileFormItems = {
  email: string;
  firstName: string;
  lastName: string;
  persona: string;
  phone?: PhoneInputFormValues;
  mobilePhone?: PhoneInputFormValues;
  title?: string;
};

export const UserProfileForm = ({
  user,
  setIsEditing,
}: {
  user: IUser;
  setIsEditing: (isEditing: boolean) => void;
}) => {
  const [updateMutation, { loading }] = useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(
    UPDATE_PROFILE_MUTATION,
  );
  const defaultUserPhoneCountryCodeKey = getCountryCodeKey(user.phoneNumber, user.phoneNumberCountry);
  const defaultUserMobilePhoneCountryCodeKey = getCountryCodeKey(user.mobilePhoneNumber, user.mobilePhoneNumberCountry);
  const { nationalNumber: defaultUserPhoneNumber } = parsePhoneNumberFromFormattedString(user.phoneNumber);
  const { nationalNumber: defaultUserMobilePhoneNumber } = parsePhoneNumberFromFormattedString(user.mobilePhoneNumber);
  const [form] = Form.useForm();

  const onFinish = async (values: UserProfileFormItems) => {
    const input: UpdateUserProfileInput = {
      firstName: values.firstName,
      lastName: values.lastName,
      persona: values.persona,
      title: values.title,
      email: values.email,
    };

    const { phone, mobilePhone } = values;

    if (phone?.number) {
      const { countryCodeKey, number, extension } = phone;
      const [country, countryCallingCode] = countryCodeKey.split('-');
      const { phoneNumber } = parsePhoneNumberFromFormattedString(`+${countryCallingCode}${number}`);

      input.phoneNumber = phoneNumber;
      input.phoneNumberCountry = country;
      input.phoneNumberExt = extension;
    }

    if (mobilePhone?.number) {
      const { countryCodeKey, number } = mobilePhone;
      const [country, countryCallingCode] = countryCodeKey.split('-');
      const { phoneNumber } = parsePhoneNumberFromFormattedString(`+${countryCallingCode}${number}`);

      input.mobilePhoneNumber = phoneNumber;
      input.mobilePhoneNumberCountry = country;
    }

    try {
      await updateMutation({
        variables: {
          input,
        },
      });

      setIsEditing(false);
    } catch (err: any) {
      console.log(`Update failed. ${err.message}`);
    }
  };

  return (
    <NuCard title="Edit User Profile">
      <NuCardContent>
        <Form {...layout} form={form} labelAlign="left" requiredMark="optional" onFinish={onFinish}>
          <Form.Item
            name="email"
            label="Email"
            initialValue={user.email}
            rules={[
              { required: true, message: 'Please input your email' },
              {
                required: true,
                type: 'email',
                message: 'The input is not a valid Email!',
              },
            ]}
          >
            <Input size="large" placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="firstName"
            label="First Name"
            initialValue={user.firstName}
            rules={[{ required: true, message: 'Please input your first name' }]}
          >
            <Input size="large" placeholder="First Name" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            initialValue={user.lastName}
            rules={[{ required: true, message: 'Please input your last name' }]}
          >
            <Input size="large" placeholder="Last Name" />
          </Form.Item>
          <PhoneInput
            name="phone"
            initialValues={{
              countryCodeKey: defaultUserPhoneCountryCodeKey ?? undefined,
              number: defaultUserPhoneNumber,
              extension: user.phoneNumberExt,
            }}
            label="Business Phone"
          />
          <PhoneInput
            name="mobilePhone"
            initialValues={{
              countryCodeKey: defaultUserMobilePhoneCountryCodeKey ?? undefined,
              number: defaultUserMobilePhoneNumber,
            }}
            label="Mobile Phone"
            showExtension={false}
          />
          <Form.Item name="title" label="Title" initialValue={user.title}>
            <Input size="large" placeholder="Title" />
          </Form.Item>
          <Form.Item
            name="persona"
            label="Persona"
            initialValue={user.persona}
            rules={[{ required: true, message: 'Please confirm your company role' }]}
          >
            <Select size="large" placeholder="Persona or Role" options={personas} />
          </Form.Item>
          <NuButton type="primary" htmlType="submit" loading={loading}>
            Save
          </NuButton>
          <NuButton type="primary" style={{ marginLeft: '1rem' }} onClick={() => setIsEditing(false)}>
            Cancel
          </NuButton>
        </Form>
      </NuCardContent>
    </NuCard>
  );
};

export default UserProfileForm;
