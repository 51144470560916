import { QualysVulnerabilityDetailsModal } from './qualys-vulnerability-details';
import { RowModalProps } from './types';

export type RowModal = {
  slug: string;
  title: string;
  component: (props: RowModalProps) => JSX.Element;
  width?: string | number;
  height?: string | number;
};

export const ROW_MODALS: RowModal[] = [QualysVulnerabilityDetailsModal];
