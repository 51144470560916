import { Checkbox as AntCheckbox, Form as AntForm, Input as AntInput, Input, Select, Typography } from 'antd';
import { ServiceNowAccountIdClientIdentifierInput } from 'components/admin/clients/client-identifiers';
import { NuButton } from 'components/nuspire';
import { Cancel as CancelIcon, ClientIdentifier as ClientIdentifierIcon } from 'components/nuspire/nu-icon';
import { clientIdentifiersList, IClientIdentifiersDef } from 'components/organization/add-client';
import { FormikContextType, useField, ErrorMessage } from 'formik';
import styled, { useTheme } from 'styled-components';
import { ActionFormFieldType } from 'types/graph-codegen/graph-types';
import { ActionFormFieldDef } from '../../types';
import * as inputs from './inputs';

// props to actually render input.
export interface ActionInputProps {
  clientId?: string;
  clientName?: string;
  name: string;
  value?: any;
  searchValue?: string; // some inputs (selects) support searching to filter down options
  onSearchChange?: (query: string | undefined) => void;
  onChange: (val: any, operator?: string) => void;
  // action: Action;
  formik?: FormikContextType<any>;
  onBlur?: any;
  field?: ActionFormFieldDef; // this is a problem.... Should filters just be inputs?
  mode?: 'display' | 'edit'; // sometimes we just want to display the value or prevent the user from editing.
  operator?: string;
  dataTypeKey?: string;
  required?: boolean;
}

export function Checkbox(props: ActionInputProps) {
  const { name, value, onChange, field } = props;

  return (
    <AntCheckbox
      name={name}
      checked={Boolean(value)}
      onChange={(e) => {
        onChange(e.target.checked);
      }}
      children={field?.parameters?.description}
    />
  );
}

export function TextInput(props: ActionInputProps) {
  const { name, value, onChange, onBlur } = props;

  return <AntInput name={name} value={value} onChange={(e) => onChange(e.target.value)} onBlur={onBlur} />;
}

export function TextArea(props: ActionInputProps) {
  const { value, onChange, name, onBlur } = props;

  return <AntInput.TextArea onBlur={onBlur} name={name} value={value} onChange={(e) => onChange(e.target.value)} />;
}

function SlackMessage(props: ActionInputProps) {
  const { value, onChange, name, onBlur } = props;

  return <AntInput.TextArea onBlur={onBlur} name={name} value={value} onChange={(e) => onChange(e.target.value)} />;
}

interface ActionInputConfig {
  input: (props: ActionInputProps) => JSX.Element;
  hideLabel?: boolean;
}

const INPUT_MAP: {
  [key: string]: ActionInputConfig;
} = {
  checkbox: { input: Checkbox },
  connection: { input: inputs.ConnectionInput },
  fortisiemOrgId: { input: TextInput },
  jira_project: { input: inputs.jira.JiraProjectSelect },
  jira_issue_type: { input: inputs.jira.JiraProjectIssueTypeSelect },
  jira_issue_type_fields: { input: inputs.jira.JiraIssueTypeFields, hideLabel: true },
  qualys_network_ids: { input: inputs.qualys.QualysNetworkIdSelect },
  qualys_web_app_ids: { input: TextInput },
  sentineloneAccountId: { input: TextInput },
  sentineloneSiteId: { input: TextInput },
  serviceNowAccountId: { input: ServiceNowAccountIdClientIdentifierInput },
  slack_message: { input: SlackMessage },
  slack_channel: { input: inputs.slack.SlackChannelSelect },
  soc_review_reports: { input: inputs.SocReviewReportSelect },
  soc_review_firewall_types: { input: inputs.SocReviewFirewallTypesSelect },
  text: { input: TextInput },
  text_area: { input: TextArea },
  radio: { input: inputs.RadioInput },
  select: { input: inputs.SelectInput },
  switch: { input: inputs.SwitchInput },
  user_select: { input: inputs.UserSelect },
  client_select: { input: inputs.ClientSelect },
  technology_dashboard_select: { input: inputs.TechnologyDashboardSelect },
  technology_select: { input: inputs.TechnologySelect },
  multi_input_text: { input: inputs.MultiInputText },
  operator_filter: { input: inputs.OperatorFilter },
  nusiemSourcesSelect: { input: inputs.NusiemSourceTypesSelect },
  healthCheckType: { input: inputs.HealthCheckTypesSelect },
};

const BASIC_INPUT_MAP: {
  [key: string]: ActionInputConfig;
} = {
  string: { input: TextInput },
  boolean: { input: inputs.SwitchInput },
};

export function getFieldInput(field: ActionFormFieldDef): ActionInputConfig {
  const { inputType, type } = field;

  return getInput({ type, inputType: inputType ?? undefined });
}

export function getInput(args: { type: ActionFormFieldType; inputType?: string }): ActionInputConfig {
  const { inputType, type } = args;

  if (inputType) {
    const inputByType = INPUT_MAP[inputType] ?? null;

    if (!inputByType) {
      throw new Error(`Could not find input with type: ${inputType}.`);
    }

    return inputByType;
  }

  // try to find basic type.
  const basicType = BASIC_INPUT_MAP[type] ?? null;
  if (!basicType) {
    throw new Error(`could not find basic type for ${type}`);
  }

  return basicType;
}

function getValidClientIdentifierOptions(args: { field: ActionFormFieldDef }) {
  const {
    field: { type, inputType },
  } = args;

  const validOptions = clientIdentifiersList.filter((ciDef) => {
    if (inputType) {
      if (ciDef.inputType !== inputType) {
        return false;
      }
    }

    // string | number | etc..
    if (type) {
      if (ciDef.valueType !== type) {
        return false;
      }
    }

    return true;
  });

  return validOptions;
}

const CiSingleRoot = styled.div`
  display: flex;
`;

function ClientIdentifierInput(props: {
  value: null | IClientIdentifiersDef;
  onChange: (ci: IClientIdentifiersDef) => void;
  onClear: () => void;
  clientIdentifierOptions: IClientIdentifiersDef[];
}) {
  const { value, clientIdentifierOptions, onChange, onClear } = props;
  const clearButton = (
    <NuButton iconRight={<CancelIcon />} type="text" danger onClick={onClear}>
      Clear
    </NuButton>
  );

  if (value && clientIdentifierOptions.length === 1) {
    return (
      <CiSingleRoot>
        <Typography.Text
          type="secondary"
          style={{
            fontSize: '1.4em',
            flex: 1,
          }}
        >{`$ClientIdentifier: ${value.label}`}</Typography.Text>
        {clearButton}
      </CiSingleRoot>
    );
  }

  return (
    <Input.Group compact style={{ width: '100%' }} size="large">
      <Select
        size="large"
        value={value?.type}
        onChange={(_selectedValue, option: any) => {
          onChange(option.def);
        }}
        options={clientIdentifierOptions.map((ci) => ({
          value: ci.type,
          label: ci.label,
          def: ci,
        }))}
        style={{ width: 'calc(100% - 100px)' }}
      />
      <NuButton onClick={onClear} style={{ width: '100px' }} size="large">
        <CancelIcon />
      </NuButton>
    </Input.Group>
  );
}

function getDefaultClientIdentifier({ inputType }: { inputType?: string }) {
  if (!inputType) {
    return null;
  }

  return clientIdentifiersList.find((ci) => ci.inputType === inputType) ?? null;
}

interface ActionFormFieldProps {
  clientId?: string;
  field: ActionFormFieldDef;
  formik?: FormikContextType<any>;
  mode?: 'edit' | 'display';
  enableClientIdentifiers?: boolean;
  dataTypeKey?: string;
  supportOperators?: boolean; // default to true;
  showLabel?: boolean;
}

export function checkShouldRemoveFilter(val: any, operator?: string) {
  if (operator && ['IS_SET', 'IS_NOT_SET'].includes(operator)) {
    // value can be empty in this case.
    return false;
  }

  if (typeof val === 'string' && val.length === 0) {
    return true;
  }

  if (Array.isArray(val) && val.length === 0) {
    return true;
  }

  return false;
}

/**
 * Assumption: Any widget using operatorInputs is also using filters over settings.
 * This is complicated because Formik... uses key value pairs.
 * Can we update formik to optionally allow operators?
 */
export function ActionFormField(props: ActionFormFieldProps) {
  const {
    clientId,
    field,
    field: { key, label, required, inputType },
    formik,
    mode = 'edit',
    enableClientIdentifiers = false,
    dataTypeKey,
    supportOperators = false,
    showLabel = true,
  } = props;

  const theme = useTheme();

  // formik react hook
  const [{ name, value: formikValue, onBlur }, { touched, error }, { setValue }] = useField({
    name: key,
  });

  // find input type.
  const inputConfig = getFieldInput(field);
  const FilterInputComponent = inputConfig.input;

  const operator = supportOperators ? formikValue?.operator : undefined;
  const value = supportOperators ? formikValue?.value : formikValue;

  // build input / display
  let input: JSX.Element = (
    <FilterInputComponent
      clientId={clientId}
      onBlur={onBlur}
      name={name}
      onChange={(newVal, op = 'IS') => {
        // we get operator here.
        console.log('ActionFormField.onChange', { newVal, operator: op });

        const shouldRemoveFilter = checkShouldRemoveFilter(newVal, op);
        if (shouldRemoveFilter) {
          setValue(undefined);
          return;
        }

        const formikVal = supportOperators
          ? {
              key: name,
              value: newVal,
              operator: op,
            }
          : newVal;

        setValue(formikVal);
      }}
      value={value}
      operator={operator}
      formik={formik}
      field={field}
      mode={mode}
      dataTypeKey={dataTypeKey}
    />
  );

  // Client Identifiers.
  const handleUseClientIdentifierChange = (def: IClientIdentifiersDef | null) => {
    const clientIdentifierValue = {
      '$client-identifier': def,
    };
    const formikVal = supportOperators
      ? {
          key: name,
          value: clientIdentifierValue,
          operator,
        }
      : clientIdentifierValue;
    setValue(formikVal);
  };
  const handleClearClientIdentifier = () => setValue(undefined);
  const clientIdentifierOptions = getValidClientIdentifierOptions({ field });
  const usingClientIdentifier: boolean = value?.['$client-identifier'] !== undefined;

  if (value?.['$client-identifier'] !== undefined) {
    input = (
      <ClientIdentifierInput
        value={value['$client-identifier']}
        clientIdentifierOptions={clientIdentifierOptions}
        onChange={(def) => handleUseClientIdentifierChange(def)}
        onClear={handleClearClientIdentifier}
      />
    );
  }

  const handleUseClientIdentifier = () => {
    const defaultClientIdentifier = getDefaultClientIdentifier({ inputType: inputType ?? undefined });

    handleUseClientIdentifierChange(defaultClientIdentifier);

    formik?.validateForm();
  };

  const useClientIdentifierButton = (
    <NuButton
      shape="circle"
      style={{ marginTop: '4px', color: theme.color.primary }}
      onClick={handleUseClientIdentifier}
      type="text"
      size="small"
      icon={<ClientIdentifierIcon />}
    >
      Use Client Identifier
    </NuButton>
  );

  if (inputConfig.hideLabel) {
    return input;
  }

  console.log(`filte: ${label}, required: ${required}`);

  return (
    <>
      <AntForm.Item
        label={showLabel ? label : undefined}
        extra={enableClientIdentifiers && !usingClientIdentifier ? useClientIdentifierButton : undefined}
        hasFeedback={Boolean(touched && error)}
        validateStatus={touched && error ? 'error' : undefined}
        status={touched && error ? 'error' : undefined}
        required={required ?? false}
        help={
          touched &&
          error &&
          (() => {
            if (typeof error === 'string') return error;

            if (typeof error === 'object') {
              const nestedError = Object.values(error).find((v) => typeof v === 'string') as string | undefined;

              if (nestedError) {
                return nestedError;
              }
            }
            return;
          })()
        }
      >
        {input}
      </AntForm.Item>
    </>
  );
}

export default ActionFormField;
