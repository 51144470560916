import { gql, useQuery } from '@apollo/client';
import { Row } from 'antd';
import { ISentinelOneItemCount, CountsDoughnut, IDoughnutMapping } from '../sentinelone-doughnut';
import { WidgetDefinition, WidgetRendererProps } from 'components/dashboard/widget-definitions/types';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import baseTheme from 'components/theme';
import dayjs from 'dayjs';
import { EmptyState, NuCardContent } from 'components/nuspire';
import ApiTokenError from '../api-token-error';

const defaultEndDate = dayjs().toDate();
const defaultStartDate = dayjs().subtract(3, 'months').toDate();

const SENTINELONE_UNRESOLVED_THREATS_COUNT_QUERY = gql`
  query SentinelOneUnresolvedThreatsCountWidget($connectionId: String!, $endDate: String, $startDate: String) {
    clientConnection(id: $connectionId) {
      id
      publicConnectionDataJson
      sentinelOneUnresolvedThreatsCount(endDate: $endDate, startDate: $startDate) {
        key
        title
        values {
          count
          title
          value
        }
      }
    }
  }
`;

const UNRESOLVED_THREATS_MAP: IDoughnutMapping = {
  not_mitigated: {
    label: 'Not mitigated',
    backgroundColor: baseTheme.color.red,
  },
  mitigated: {
    label: 'Mitigated',
    backgroundColor: baseTheme.color.green,
  },
  marked_as_benign: {
    label: 'Marked as benign',
    backgroundColor: baseTheme.color.gray5,
  },
};

function SentinelOneUnresolvedThreatsCountWidgetComponent(props: WidgetRendererProps) {
  const connectionId = props?.configuration?.connectionId ?? null;
  const { data, loading, error } = useQuery(SENTINELONE_UNRESOLVED_THREATS_COUNT_QUERY, {
    variables: {
      connectionId,
      endDate: defaultEndDate.toISOString(),
      startDate: defaultStartDate.toISOString(),
    },
  });
  const unresolvedThreatsCount = data?.clientConnection?.sentinelOneUnresolvedThreatsCount as ISentinelOneItemCount;

  if (!data && loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  if (unresolvedThreatsCount) {
    return (
      <Row justify="center">
        <CountsDoughnut counts={unresolvedThreatsCount.values} map={UNRESOLVED_THREATS_MAP} />
      </Row>
    );
  }

  const errorMsg = error?.message;
  if (errorMsg === 'Your API Key has expired.' || errorMsg === 'API token does not exist') {
    return <ApiTokenError connectionId={connectionId} errorMsg={errorMsg} />;
  }

  return (
    <NuCardContent>
      <EmptyState>Unable to fetch unresolved threats count.</EmptyState>
    </NuCardContent>
  );
}

const SentinelOneUnresolvedThreatsCountWidget: WidgetDefinition = {
  name: 'SentinelOne Unresolved Threats Count',
  description: 'SentinelOne Unresolved Threats Count',
  slug: 'sentinelone-unresolved-threats-count',
  connectorSlug: 'sentinelone',
  component: SentinelOneUnresolvedThreatsCountWidgetComponent,
};

export default SentinelOneUnresolvedThreatsCountWidget;
