import * as NuIcon from 'components/nuspire/nu-icon';
import { IConnector } from 'components/connections/connectors';
import SlackRedirect from './slack-redirect';
import { encodeOAuthState } from '../utils/oauth';

/**
 * SLACK CONNECTOR
 */
export const SLACK_APP_CLIENT_ID = '1857376941717.2101163813872';
export const SLACK_REDIRECT_PATH = `${window.location.origin}/redirects/connections/slack/oauth-redirect`;

// https://api.slack.com/scopes
const SLACK_SCOPES = [
  'groups:read',
  
  'mpim:read', // I think this is required to read private channels

  'channels:read',

  // Post messages in approved channels & conversations
  'chat:write',
  // Send messages to channels @your_slack_app isn't a member of
  'chat:write.public',

];

const OAUTH_URL = `https://slack.com/oauth/v2/authorize?scope=${SLACK_SCOPES.join(
  ',',
)}&client_id=${SLACK_APP_CLIENT_ID}&redirect_uri=${SLACK_REDIRECT_PATH}`;

const SLACK_CONNECTOR_DEFINITION: IConnector = {
  slug: 'slack',
  name: 'Slack',
  description: 'Send notifications to Slack Channels',
  connectionTypes: ['action'],
  icon: <NuIcon.Slack />,
  renderId: (props) => {
    const { clientConnection } = props;

    return <>{`Account: ${clientConnection.meta.accountId}`}</>;
  },
  handleAddConnection: ({ sessionId, clientId }) => {
    const oAuthState = encodeOAuthState({ sessionId, clientId });

    window.open(`${OAUTH_URL}&state=${oAuthState}`, 'Slack OAuth', 'height=800,width=800');
  },
  redirects: [{ path: 'oauth-redirect', component: SlackRedirect }],
};

export default SLACK_CONNECTOR_DEFINITION;
