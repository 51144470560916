import { ExportOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Select, TablePaginationConfig, Tooltip, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useAuthContext } from 'components/auth-context';
import { useClientContext } from 'components/client-context-provider';
import { Link, NuButton } from 'components/nuspire';
import { NuTimeRangeButton } from 'components/nuspire/nu-time-range-button/nu-time-range-button';
import { config } from 'config';
import { mixpanelTrack } from 'utils/mixpanel/mixpanel-track';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import baseTheme from 'components/theme';
import { CaseManagementFilters as CaseManagementFiltersType } from 'types';
import { listify } from 'utils/listify';
import useSearchParams, { SearchParamDefinition } from 'utils/react-hooks/useSearchParams';
import { ColoredCircle, StyledTable, TableRow } from '../shared-components';
import { CaseManagementFilters } from './case-management-filters';
import { jsonSafeParse } from '../../utils/json-safe-parse';
import { DocumentNode } from 'graphql';
import { useLazyQuery } from '@apollo/client';
import { downloadBase64ContentAsFile } from '../../utils/download-base64-content-as-file';
import { parseTimeString } from '../nuspire/nu-time-range-button/time';
import { getSortByAndOrderFromAntdPaginationSorter } from '../../utils/pagination';

const FilterRow = styled.div`
  display: flex;
`;

export interface IPaginationArgs {
  currentPage: number;
  pageSize: number;
}

export interface ISortArgs {
  sortBy?: string;
  sortOrder?: string;
}

const userFieldMappings = {
  cases: [`closed`, `created`, `opened`, `resolved`, `updated`],
  incidents: [`closed`, `created`, `opened`, `reopened`, `resolved`, `updated`],
};

export type DefaultFilters = CaseManagementFiltersType['cases'] | CaseManagementFiltersType['incidents'];

export type OnChangeArgs = IPaginationArgs &
  ISortArgs &
  DefaultFilters & {
    includeChildren?: boolean;
    search?: string;
  };

interface ICaseManagementTableProps {
  data: any[];
  defaultFilters?: DefaultFilters;
  exportQuery: DocumentNode;
  exportQueryName: string;
  loading?: boolean;
  onChange?: (props: OnChangeArgs) => void;
  onSearchClick?: (filterItem: DefaultFilters) => void;
  states: string[];
  statesLoading: boolean;
  total?: number;
  type: 'cases' | 'incidents';
}

const now = new Date();
export function getUpdatedAt(minusDays: number, minusHours?: number): string {
  const updatedAt = new Date();
  updatedAt.setDate(now.getDate() - minusDays);

  if (minusHours) {
    updatedAt.setHours(now.getHours() - minusHours);
  }

  return updatedAt.toISOString();
}

export const priorities = {
  '1 - Critical': {
    color: baseTheme.color.red,
  },
  '2 - High': {
    color: baseTheme.color.orange,
  },
  '3 - Moderate': {
    color: baseTheme.color.yellow,
  },
  '4 - Low': {
    color: baseTheme.color.green,
  },
  '5 - Planning': {
    color: baseTheme.color.nuspireBlue,
  },
};

export const PriorityText = ({ priority }: { priority?: string }) => {
  const defaultPriority = '4 - Low';
  const p = priority || defaultPriority;
  const priorityColor = Object.keys(priorities).includes(p) ? priorities[p].color : priorities[defaultPriority].color;

  return (
    <>
      <ColoredCircle color={priorityColor} />
      <span style={{ marginLeft: '0.5rem' }}>{priority}</span>
    </>
  );
};

export const defaultSortBy = 'updated';

export const getDirectlyAssociatedTooltipText = (type: 'cases' | 'incidents') => {
  const mappings = userFieldMappings[type];
  const list = listify(mappings, { conjunction: `or ` });

  return `Only show ${type} that I've ${list}`;
};

export function hasResolutionCodes(
  type: 'cases' | 'incidents',
  filters?: DefaultFilters,
): filters is CaseManagementFiltersType['cases'] {
  return type === 'cases' && !!filters && typeof (filters as any).resolutionCodes !== 'undefined';
}

export function hasCloseCodes(
  type: 'cases' | 'incidents',
  filters?: DefaultFilters,
): filters is CaseManagementFiltersType['incidents'] {
  return type === 'incidents' && !!filters && typeof (filters as any).closeCodes !== 'undefined';
}

export default function CaseManagementTable(props: ICaseManagementTableProps) {
  const { data, defaultFilters, loading = false, onChange, onSearchClick, states, statesLoading, total = 0, type } = props;
  const { paginationDefaults } = config;
  const { client, clientId } = useClientContext();
  const { user: authUser } = useAuthContext();
  const tableData = useMemo(() => data.map((d) => ({ ...d, key: d.number })), [data]);

  const [runExport, { loading: exportLoading }] = useLazyQuery(props.exportQuery);

  const isMultiTenancyEnabled = client?.isMultiTenancyEnabled ?? false;
  const defaultIncludeChildClients: boolean | undefined = isMultiTenancyEnabled
    ? defaultFilters?.includeChildren
    : undefined;

  const [filterParams, setFilterParams] = useState<DefaultFilters>({
    assignmentGroups: defaultFilters?.assignmentGroups,
    category: defaultFilters?.category,
    closeCodes: hasCloseCodes(type, defaultFilters) ? defaultFilters?.closeCodes : undefined,
    dateField: defaultFilters?.dateField,
    includeChildren: defaultIncludeChildClients,
    priorities: defaultFilters?.priorities,
    resolutionCodes: hasResolutionCodes(type, defaultFilters) ? defaultFilters?.resolutionCodes : undefined,
    search: defaultFilters?.search,
    states: defaultFilters?.states ?? [paginationDefaults.filter],
    subcategory: defaultFilters?.subcategory,
    time: defaultFilters?.time,
    user: defaultFilters?.user ?? paginationDefaults.filter,
  });
  const [currentPage, setCurrentPage] = useState<number>(paginationDefaults.currentPage);
  const [pageSize, setPageSize] = useState<number>(paginationDefaults.pageSize);

  const { parsed, setParameter, initializeSearchParams } = useSearchParams();

  const [selectedDateField, setSelectedDateField] = useState<string>(
    parsed?.dateField ?? filterParams?.dateField ?? 'sys_created_on',
  );
  const [sortParams, setSortParams] = useState<{ by?: string; order?: string }>();

  const createFilterParams: () => DefaultFilters = () => {
    const {
      assignmentGroups,
      category,
      closeCodes,
      dateField,
      includeChildren,
      priorities: prioritiesFilters,
      resolutionCodes,
      search,
      states: statesFilters,
      subcategory,
      time,
      user,
    } = parsed;

    const assignmentGroupsArr: string[] | undefined = jsonSafeParse(assignmentGroups);
    const closeCodesArr: string[] | undefined = type === 'incidents' ? jsonSafeParse(closeCodes) : undefined;
    const prioritiesArr: string[] | undefined = jsonSafeParse(prioritiesFilters);
    const resolutionCodesArr: string[] | undefined = type === 'cases' ? jsonSafeParse(resolutionCodes) : undefined;
    const statesArr: string[] | undefined = jsonSafeParse(statesFilters);
    const onlyMy: boolean = user === 'true' && !!authUser?.serviceNowUserId;

    const newFilterParams: DefaultFilters = {
      assignmentGroups: assignmentGroupsArr,
      category,
      closeCodes: closeCodesArr,
      dateField,
      includeChildren: includeChildren === 'true',
      priorities: prioritiesArr,
      resolutionCodes: resolutionCodesArr,
      search,
      states: statesArr?.length ? statesArr : [paginationDefaults.filter],
      subcategory,
      time,
      user: onlyMy ? `${authUser?.serviceNowUserId}` : paginationDefaults.filter,
    };

    return newFilterParams;
  };

  useEffect(() => {
    const initialSearchParams: SearchParamDefinition[] = [
      {
        key: 'assignmentGroups',
        defaultValue:
          filterParams?.assignmentGroups
            ? JSON.stringify(filterParams.assignmentGroups)
            : undefined,
      },
      {
        key: 'category',
        defaultValue: filterParams?.category,
      },
      {
        key: 'closeCodes',
        defaultValue:
          filterParams?.closeCodes && hasCloseCodes(type, filterParams)
            ? JSON.stringify(filterParams.closeCodes)
            : undefined,
      },
      {
        key: 'dateField',
        defaultValue: filterParams?.dateField?.trim() ?? 'sys_created_on',
      },
      {
        key: 'includeChildren',
        defaultValue: filterParams?.includeChildren?.toString() ?? 'true',
      },
      {
        key: 'priorities',
        defaultValue:
          filterParams?.priorities && filterParams.priorities.includes(paginationDefaults.filter)
            ? JSON.stringify(filterParams.priorities)
            : undefined,
      },
      {
        key: 'resolutionCodes',
        defaultValue:
          filterParams?.resolutionCodes && hasResolutionCodes(type, filterParams)
          ? JSON.stringify(filterParams.resolutionCodes.map((i) => i.replace(/,/g, '   ')))
          : undefined,
      },
      {
        key: 'search',
        defaultValue: filterParams?.search,
      },
      {
        key: 'states',
        defaultValue:
          filterParams?.states && !filterParams.states.includes(paginationDefaults.filter)
            ? JSON.stringify(filterParams.states)
            : undefined,
      },
      { key: 'subcategory', defaultValue: filterParams?.subcategory },
      { key: 'time', defaultValue: filterParams?.time },
      {
        key: 'user',
        defaultValue: filterParams?.user && filterParams.user !== paginationDefaults.filter ? 'true' : undefined,
      },
    ];

    initializeSearchParams(initialSearchParams);
  }, []);

  // Set filterParams when query params change
  useEffect(() => {
    const newFilterParams = createFilterParams();

    setFilterParams(newFilterParams);
  }, [parsed]);

  // Set query param whenever select changes
  const handleDateFieldChange = (value: string) => {
    setParameter('dateField', value);

    setSelectedDateField(value);
  };

  const columns: ColumnsType<object> = [
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
      render: (number: string) => (
        <Link
          to={`/${clientId}/case-management/${type}/${number}`}
          onClick={() => mixpanelTrack(`open-${type === 'incidents' ? 'incident' : 'case'}`)}
        >
          {number}
        </Link>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.number - b.number,
      showSorterTooltip: false,
    },
    {
      title: 'Name',
      dataIndex: 'short_description',
      key: 'name',
      render: (name: string) => name,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.short_description - b.short_description,
      showSorterTooltip: false,
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (state: number) => state,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.state - b.state,
      showSorterTooltip: false,
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      render: (priority: string) => <PriorityText priority={priority} />,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.priority - b.priority,
      showSorterTooltip: false,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Subcategory',
      dataIndex: 'subcategory',
      key: 'subcategory',
    },
    {
      title: 'Updated',
      dataIndex: 'sys_updated_on',
      key: 'updated',
      render: (updatedOn: string) => (
        <Tooltip title={dayjs.utc(updatedOn).local().format('MMMM Do YYYY, h:mm:ss a')}>
          {dayjs.utc(updatedOn).local().fromNow()}
        </Tooltip>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.sys_updated_on - b.sys_updated_on,
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
    },
  ];

  if (type === 'cases') {
    columns.push(
      {
        title: 'Opened By',
        dataIndex: 'opened_by',
        key: 'opened_by',
      },
      {
        title: 'Contact',
        dataIndex: 'contact', // does this not support nested paths?
        key: 'contact',
        render: (contact: any) => contact?.name || '', // what is this item defined as?
      },
    );
  }

  if (type === 'incidents') {
    columns.splice(4, 0, {
      title: 'Impact',
      dataIndex: 'impact',
      key: 'impact',
      render: (impact: string) => impact,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.impact - b.impact,
      showSorterTooltip: false,
    });

    columns.splice(5, 0, {
      title: 'Urgency',
      dataIndex: 'urgency',
      key: 'urgency',
      render: (urgency: string) => urgency,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.urgency - b.urgency,
      showSorterTooltip: false,
    });
  }

  if (isMultiTenancyEnabled) {
    columns.splice(0, 0, {
      title: 'Account',
      dataIndex: type === 'incidents' ? 'u_account' : 'account',
      key: 'account',
      render: (account?: any) => {
        if (type === 'incidents') return account?.display_value;
        return account?.name;
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a?.account?.name.localeCompare(b?.account?.name),
      showSorterTooltip: false,
    });
  }

  const paginationSettings: TablePaginationConfig = {
    current: currentPage,
    defaultCurrent: paginationDefaults.currentPage,
    defaultPageSize: paginationDefaults.pageSize,
    onChange: (page: number, numItems?: number | undefined) => {
      setCurrentPage(page);
      setPageSize(numItems as number);
    },
    pageSize,
    showSizeChanger: true,
    showTotal: (t: number) => `Total: ${t}`,
    total,
  };

  const handleTablePagination = (pagination: TablePaginationConfig, _filters: any, sorter, _extra: any) => {
    const [sortBy, sortOrder] = getSortByAndOrderFromAntdPaginationSorter(sorter);

    setSortParams({
      by: sortBy,
      order: sortOrder,
    });

    onChange?.({
      ...filterParams,
      category: filterParams?.category ? JSON.parse(filterParams.category) : undefined,
      subcategory: filterParams?.subcategory ? JSON.parse(filterParams.subcategory) : undefined,
      currentPage: pagination.current as number,
      pageSize: pagination.pageSize as number,
      sortBy,
      sortOrder,
    });
  };

  const onApplyFiltersClick = () => {
    onChange?.({
      ...filterParams,
      category: filterParams?.category ? JSON.parse(filterParams.category) : undefined,
      subcategory: filterParams?.subcategory ? JSON.parse(filterParams.subcategory) : undefined,
      currentPage: paginationDefaults.currentPage,
      pageSize: paginationDefaults.pageSize,
      sortBy: defaultSortBy,
      sortOrder: paginationDefaults.sortOrder,
    });

    const newFilterParams = createFilterParams();

    onSearchClick?.(newFilterParams);
  };

  const runExportAndDownload = async () => {
    const { fromIso: startDate, toIso: endDate } = parseTimeString({ time: filterParams?.time });

    const { data, error } = await runExport({
      variables: {
        clientId,
        filters: {
          assignmentGroups: filterParams?.assignmentGroups,
          category: filterParams?.category,
          dateField: filterParams?.dateField,
          endDate,
          includeChildren: filterParams?.includeChildren,
          priorities: filterParams?.priorities ?? [],
          search: filterParams?.search,
          sortBy: sortParams?.by ?? defaultSortBy,
          sortOrder: sortParams?.order ?? paginationDefaults.sortOrder,
          startDate,
          states: filterParams?.states ?? [],
          subcategory: filterParams?.subcategory,
          user: filterParams?.user,
        },
      },
    });

    if (error) {
      console.error(error);
      message.error(`An error occurred while attempting to export your ${props.type}.`);
    }

    downloadBase64ContentAsFile({
      content: data?.[props.exportQueryName].base64Data,
      contentType: data?.[props.exportQueryName].contentType,
      filename: data?.[props.exportQueryName].filename,
    });
  };

  return (
    <>
      <FilterRow>
        <CaseManagementFilters
          isMultiTenancyEnabled={client?.isMultiTenancyEnabled}
          states={states}
          statesLoading={statesLoading}
          type={type}
        />
        <Select
          size="large"
          style={{ minWidth: '125px' }}
          placeholder="Select a Date Field"
          defaultValue="sys_created_on"
          // onChange={(newValue) => setSelectedDateField(newValue)}
          onChange={handleDateFieldChange}
          value={selectedDateField}
        >
          <Select.Option value="closed_at">Closed</Select.Option>
          <Select.Option value="sys_created_on">Created</Select.Option>
          <Select.Option value="resolved_at">Resolved</Select.Option>
          <Select.Option value="sys_updated_on">Updated</Select.Option>
        </Select>
        <Button.Group>
          <NuTimeRangeButton
            time={filterParams?.time}
            onChange={(args) => {
              setParameter('time', args.time ?? null);
            }}
          />
          <NuButton
            type="primary"
            size="large"
            onClick={onApplyFiltersClick}
            disabled={loading}
            loading={loading}
            icon={<SearchOutlined />}
          >
            Search
          </NuButton>
          <NuButton
            type="default"
            size="large"
            onClick={runExportAndDownload}
            disabled={loading || exportLoading}
            loading={exportLoading}
            icon={<ExportOutlined />}
          >
            Export
          </NuButton>
        </Button.Group>
      </FilterRow>
      <TableRow>
        <StyledTable
          columns={columns}
          dataSource={tableData}
          loading={loading}
          onChange={handleTablePagination}
          pagination={paginationSettings}
        />
      </TableRow>
    </>
  );
}
