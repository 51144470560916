import { message } from 'antd';
import { IUser } from '../../../types';
import { downloadBase64ContentAsFile } from '../../../utils/download-base64-content-as-file';

const exportColHeadingsMap = {
    "id": "ClientId",
    "name": "Name",
    "clientIdentifiers": "Client identifiers",
    "type": "Type",
    "stats": "Stats",
    "path": "Path",
    "isMultiTenancyEnabled": "Multitenancy",
    "userCount": "Number of users"
};

const newLine = navigator.userAgent.indexOf('Win') > -1 ? "\r\n" : "\n";

export const runExportClientsAndDownload = async (runExport, searchValue: String, showDeletedUsers: Boolean) => {
    let checkForData = true;
    let rows: Array<IUser> = [];
    let nextKey: Record<string, any> | undefined;

    while (checkForData) {
        const { data, error } = await runExport({
            variables: {
                search: searchValue,
                showDeleted: showDeletedUsers,
                options: {
                    nextKey: nextKey,
                    pageSize: 100,
                },
            },
        });

        if (error) {
            console.error(error);
            message.error(`An error occurred while attempting to export clients containing [${searchValue}]`);
        }
        nextKey = data?.allClients?.nextKey;
        if (!nextKey) {
            checkForData = false;
        }

        rows = rows.concat(data.allClients.items);
    }

    processExportedClients(rows, searchValue);

};

const processExportedClients = async (rows: Array<IUser>, searchValue: String) => {
    if (rows?.length > 0) {
        // exclude the first column which is the __typename sent by graphql
        let fields = Object.keys(rows[0]);
        fields = fields.filter(item => item != '__typename');

        // use empty string in place of null
        const replacer = function (key, value) { return value === null ? '' : value }

        const csv = rows.map(function (row) {
            return fields.map(function (fieldName) {
                // use JSON array string and omit __typename property
                if (row[fieldName] && Array.isArray(row[fieldName])) {
                    const col = row[fieldName].map(function (e) {
                        const {__typename, ...props} = e;
                        return props
                    });
                    return `"${JSON.stringify(col, replacer).replaceAll("\"", "\"\"")}"`;
                } 
                // use JSON object string and omit __typename property
                if (row[fieldName] && typeof row[fieldName] === 'object') {
                    // if the object contains a mapped property use it's value rather than the whole object
                    for (const prop in exportColHeadingsMap) {
                        if (prop in row[fieldName]) {
                            return JSON.stringify(row[fieldName][prop], replacer)
                        }
                    };
                    // if no mapped properties were in the object, stringify the whole object
                    const { __typename, ...col } = row[fieldName];
                    return `"${JSON.stringify(col, replacer).replaceAll("\"", "\"\"")}"`;
                }
                return JSON.stringify(row[fieldName], replacer)
            }).join(',');
        });

        // map internal column names to user friendly names
        fields = Array.from(fields.map(function (k) { return (k in exportColHeadingsMap) ? exportColHeadingsMap[k] : k }));
        // add column names as first array element
        csv.unshift(fields.join(','));

        // base64 encode rows as strings joined with new lines
        const b64Data = window.btoa(csv.join(newLine));

        downloadBase64ContentAsFile({
            content: b64Data,
            contentType: "text/csv",
            filename: "exportedAdminClients.csv",
        });

        message.success(`All ${csv.length > 1 ? csv.length - 1 : 0} clients matching your selection criteria [${searchValue}] have been exported`);
    } else {
        message.warning(`No clients were found to export for this criteria [${searchValue}]`);
    }

};