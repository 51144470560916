import { useEffect, useMemo, useState } from 'react';
import { Form as AntForm, Input, Tag } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { ActionInputProps, TextInput, TextArea } from '../../../action-form-field';
import { FormikContextType, useField } from 'formik';
import EmptyState from 'components/nuspire/nu-empty-state';
import * as NuIcon from 'components/nuspire/nu-icon';
import Spin, { SpinContainer } from 'components/nuspire/spin';

import JiraAutoCompleteInput from './jira-assignee-input';
import JiraAllowedValuesInput from './jira-allowed-values-input';

type IssueTypeField = {
  required: boolean;
  schema: {
    type: string;
    system: string;
  };
  name: string;
  key: string;
  hasDefaultValue: boolean;
  autoCompleteUrl?: string;
  allowedValues?: {
    self: string;
    id: string;
    name: string;
    iconUrl?: string;
    key?: string;
  }[];
};

// parse stringified object into array of fields.
function jsonStringToFields(jsonString: string): IssueTypeField[] {
  // parse json.
  const fieldsObject = JSON.parse(jsonString);

  return Object.keys(fieldsObject).map((fieldKey) => fieldsObject[fieldKey]);
}

export interface JiraActionInputProps extends ActionInputProps {
  jiraField: IssueTypeField;
}

function JiraLabelsInput(props: JiraActionInputProps) {
  const { value, onChange } = props;

  // state
  const [editIdx, setEditIdx] = useState<number | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const tags: string[] = value ?? [];

  const handleConfirm = () => {
    if (inputValue.length && editIdx === -1) {
      // new tag.
      const newTags = [...tags, inputValue];

      onChange(newTags);
    }

    if (inputValue.length && editIdx && editIdx > 0) {
      const newTags = [...tags];
      newTags[editIdx] = inputValue;

      onChange(newTags);
    }
    setInputValue('');
    setEditIdx(null);
  };

  const inputVisible = editIdx === -1;

  const tagInput = (
    <Input
      size="small"
      type="text"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onBlur={handleConfirm}
      onPressEnter={handleConfirm}
      className="tag-input"
      style={{ width: '100px' }}
    />
  );

  return (
    <>
      {tags.map((tag, idx) => {
        if (editIdx === idx) {
          return (
            <Input
              key={tag}
              size="small"
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onBlur={handleConfirm}
              onPressEnter={handleConfirm}
              className="tag-input"
              width={100}
              style={{ width: '100px' }}
            />
          );
        }
        return (
          <Tag
            key={tag}
            closable
            onDoubleClick={() => {
              setInputValue(tag);
              setEditIdx(idx);
            }}
            onClose={() => {
              const newTags = [...tags];
              newTags.splice(idx, 1);

              onChange(newTags);
            }}
          >
            {tag}
          </Tag>
        );
      })}

      {inputVisible && tagInput}

      {!inputVisible && (
        <Tag
          style={{ borderStyle: 'dashed', backgroundColor: 'transparent', cursor: 'pointer' }}
          onClick={() => setEditIdx(-1)}
        >
          <NuIcon.Plus style={{ marginRight: '4px' }} />
          New Tag
        </Tag>
      )}
    </>
  );
}

function JiraIssuetypeAuto(props: JiraActionInputProps) {
  const {
    value,
    jiraField: { allowedValues },
    onChange,
  } = props;
  const allowedValueId = allowedValues?.[0]?.id ?? null;

  useEffect(() => {
    if (allowedValueId && value?.id !== allowedValueId) {
      onChange({ id: allowedValueId });
    }
  }, [allowedValueId]);

  return <>{null}</>;
}

function JiraProjectAuto(props: JiraActionInputProps) {
  const {
    value,
    jiraField: { allowedValues },
    onChange,
  } = props;
  const allowedValueId = allowedValues?.[0]?.id ?? null;

  useEffect(() => {
    if (allowedValueId && value?.id !== allowedValueId) {
      onChange({ id: allowedValueId });
    }
  }, [allowedValueId]);

  return <>{null}</>;
}

type JiraFieldInput = (props: JiraActionInputProps) => JSX.Element | null;
interface JiraFieldSystem {
  input: JiraFieldInput;
  hidden?: boolean;
}

const JIRA_FIELD_MAP: {
  [system: string]: JiraFieldSystem;
} = {
  summary: {
    input: TextInput,
  },
  description: {
    input: TextArea,
  },
  labels: { input: JiraLabelsInput },
  issuetype: {
    input: JiraIssuetypeAuto,
    hidden: true,
  },
  project: {
    input: JiraProjectAuto,
    hidden: true,
  },
  assignee: { input: JiraAutoCompleteInput },
  allowedValues: { input: JiraAllowedValuesInput },
};

function getJiraFieldInput(field: IssueTypeField): JiraFieldSystem | null {
  const {
    schema: { system },
    allowedValues,
  } = field;

  const matchingJiraSystem = JIRA_FIELD_MAP[system] ?? null;

  if (matchingJiraSystem) {
    return matchingJiraSystem;
  }

  if (allowedValues?.length && allowedValues.length > 0) {
    return JIRA_FIELD_MAP.allowedValues;
  }

  return null;
}

function IssueTypeFieldInput(props: { field: IssueTypeField; formik?: FormikContextType<any> }) {
  const {
    formik,
    field,
    field: { key, name, required },
  } = props;
  const formikKey = `issue_type_fields.values.${key}`;

  const fieldContext = useField({ name: formikKey });
  const { value, onBlur } = fieldContext[0];
  const { setValue } = fieldContext[2];

  const jiraSystem = getJiraFieldInput(field);

  if (!jiraSystem) {
    return null;
  }
  const InputFunc = jiraSystem.input;

  const input = (
    <InputFunc jiraField={field} value={value} formik={formik} name={formikKey} onBlur={onBlur} onChange={(val) => setValue(val)} />
  );

  if (jiraSystem.hidden) {
    return input;
  }

  return (
    <AntForm.Item label={name} required={required}>
      {input}
    </AntForm.Item>
  );
}

function IssueTypeFields(props: { fieldsJsonString: string; formik?: FormikContextType<any> }) {
  const { fieldsJsonString, formik } = props;

  const fields = useMemo(() => {
    return jsonStringToFields(fieldsJsonString);
  }, [fieldsJsonString]);

  return (
    <>
      {fields.map((field) => (
        <IssueTypeFieldInput key={field.key} formik={formik} field={field} />
      ))}
    </>
  );
}

const JIRA_ISSUE_TYPE_FIELDS = gql`
  query JiraIssueTypeFields($connectionId: String!, $projectKey: String!, $issuetypeName: String!) {
    clientConnection(id: $connectionId) {
      id
      jiraIssueTypeFieldsJson(projectKey: $projectKey, issuetypeName: $issuetypeName)
    }
  }
`;

/**
 * We fetch the Fields schema based off of the issue type chosen in previous step
 */
function JiraIssueTypeFields(props: ActionInputProps) {
  const { formik } = props;
  const values = formik?.values;
  const connectionId = values?.connection?.id ?? null;
  const projectKey = values?.project?.key ?? null;
  const issuetypeName = values?.issue_type?.name ?? null;

  const { data, loading } = useQuery(JIRA_ISSUE_TYPE_FIELDS, {
    variables: { connectionId, projectKey, issuetypeName },
    skip: !connectionId || !projectKey || !issuetypeName,
  });
  const fieldsJsonString = data?.clientConnection?.jiraIssueTypeFieldsJson ?? null;

  const fieldContext = useField('issue_type_fields.fields_json_string');
  const fieldsField = fieldContext[0];
  const fieldsHelpers = fieldContext[2];

  useEffect(() => {
    if (!fieldsJsonString) {
      return;
    }

    if (fieldsField.value !== fieldsJsonString) {
      fieldsHelpers.setValue(fieldsJsonString);
    }
  }, [fieldsJsonString]);

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  if (!fieldsField.value) {
    return <EmptyState>Select Issue Type before continuing...</EmptyState>;
  }

  return <IssueTypeFields fieldsJsonString={fieldsField.value} formik={formik} />;
}

export default JiraIssueTypeFields;
