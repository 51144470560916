import { Link } from 'components/nuspire';
import styled from 'styled-components';
import { PriorityLabel } from 'components/case-management';
import { WidgetComponentProps } from '../../../widgets/widget';
import { useEffect } from 'react';
import { Typography } from 'antd';

const CaseListItemRoot = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.token.colorBorder};
  padding: 12px 0;
`;
const CaseListItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;
const CaseListItemShortDesc = styled(Typography.Text)`
  margin-bottom: 4px;
`;

export function CaseListItem(props: {
  case: { number: string; priority: string; short_description: string };
  clientId: string;
}) {
  const {
    case: { number, priority, short_description: shortDescription },
    clientId,
  } = props;
  return (
    <CaseListItemRoot>
      <CaseListItemHeader>
        <Link to={`/${clientId}/case-management/cases/${number}`}>{number}</Link>

        <PriorityLabel priority={priority ?? ''} />
      </CaseListItemHeader>

      <CaseListItemShortDesc>{shortDescription}</CaseListItemShortDesc>
    </CaseListItemRoot>
  );
}

interface LatestOpenCasesWidgetViewProps {
  cases: { number: string; priority: string; short_description: string }[];
  clientId: string;
}

function LatestOpenCasesWidgetView(props: LatestOpenCasesWidgetViewProps) {
  const { cases, clientId } = props;

  return (
    <>
      {cases.map((item) => (
        <CaseListItem case={item} key={item.number} clientId={clientId} />
      ))}
    </>
  );
}

function LatestOpenCasesWidget(
  props: WidgetComponentProps<
    any,
    Required<{ result: { number: string; priority: string; short_description: string }[]; total: number }>
  >,
) {
  const { isReportWidget, setSubAction, clientId, data } = props;
  const { result } = data;

  useEffect(() => {
    if (!isReportWidget && setSubAction) {
      setSubAction(
        <Link to={`/${clientId}/case-management`} style={{ marginRight: '1rem' }} mode="plain">
          View Cases
        </Link>,
      );
    }
  }, []);

  return <LatestOpenCasesWidgetView cases={result} clientId={clientId} />;
}

export default LatestOpenCasesWidget;
