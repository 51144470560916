import { Typography } from 'antd';
import { UnauthenticatedLayout } from 'components/layouts/unauthenticated-layout';
import { Helmet } from 'react-helmet';

export function SignUpConfirmationPage() {
  return (
    <UnauthenticatedLayout>
      <Helmet title="Email Confirmation" />
      <div>
        <Typography.Title level={2}>Email Confirmation</Typography.Title>
        <Typography.Paragraph>
          We have sent a confirmation email to your email address. Please follow the link in the email to finish setting
          up your account.
        </Typography.Paragraph>
      </div>
    </UnauthenticatedLayout>
  );
}
