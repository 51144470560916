export function capitalize([firstLetter, ...restOfWord]: string): string {
  return `${firstLetter.toUpperCase()}${restOfWord.join(``)}`;
}

// Avoids XSS issues.
// https://stackoverflow.com/a/34064434
export function htmlDecode(input: string): string | null {
  const doc = new DOMParser().parseFromString(input, `text/html`);
  return doc.documentElement.textContent;
}

export const humanReadableBoolean = (value?: boolean): string => {
  return value ? 'Yes' : 'No';
};
