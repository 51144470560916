export default () => (
  <svg
    version="1.1"
    id="svg29"
    width="1em"
    height="1em"
    //   width="1332.52" height="429.93332"
    viewBox="0 0 1332.52 429.93332"
  >
    <g id="g37" transform="matrix(1.3333333,0,0,-1.3333333,0,429.93333)">
      <g id="g39" transform="scale(0.1)">
        <path
          d="M 1612.25,-0.0117188 H -0.00390625 V 1531.63 H 3222.49 C 3180.48,678.66 2475.63,-0.0117188 1612.25,-0.0117188"
          id="path41"
          inkscape:connector-curvature="0"
        />
        <path
          d="M 1612.25,3224.49 H -0.00390625 V 1692.86 H 3222.49 c -42.01,852.96 -746.86,1531.63 -1610.24,1531.63"
          id="path43"
          inkscape:connector-curvature="0"
        />
        <path
          d="m 8383.68,3224.49 c -863.38,0 -1568.23,-678.67 -1610.23,-1531.63 h 3220.48 c -42.01,852.96 -746.86,1531.63 -1610.25,1531.63"
          id="path45"
          inkscape:connector-curvature="0"
        />
        <path
          d="M 8383.68,-0.0117188 C 7520.3,-0.0117188 6815.45,678.66 6773.45,1531.63 H 9993.93 C 9951.92,678.66 9247.07,-0.0117188 8383.68,-0.0117188"
          id="path47"
          inkscape:connector-curvature="0"
        />
        <path
          d="M 3385.72,3224.49 V 1612.24 C 3385.72,748.859 4064.39,44.0117 4917.36,2 V 3224.49 H 3385.72"
          id="path49"
          inkscape:connector-curvature="0"
        />
        <path
          d="M 6610.22,-0.0117188 H 5078.58 V 3224.49 H 6610.22 V 1612.24 -0.0117188"
          id="path51"
          inkscape:connector-curvature="0"
        />
      </g>
    </g>
  </svg>
);
