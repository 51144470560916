import { gql } from '@apollo/client';

export const FEATURE_FLAG_FRAGMENT = gql`
  fragment FeatureFlagFields on FeatureFlag {
    id
    slug
    stage
    label
    description
    createdByUserId
  }
`;

export const CLIENT_FEATURE_FLAG_FRAGMENT = gql`
  fragment ClientFeatureFlagFields on ClientFeatureFlag {
    id
    clientId
    slug
    createdAt
    includeChildClients
    # featureFlag
    # client
  }
`;
