import { useEffect, useState } from 'react';
import { SLACK_REDIRECT_PATH } from './slack';
import { client } from 'utils/graphql';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { Result } from 'antd';
import { useOktaAuth } from '@okta/okta-react';
import { decodeOAuthState } from '../utils/oauth';
import { MAKE_CONNECTION_MUTATION } from '..';
import { useLocation } from 'react-router-dom';

export const SLACK_REDEEM_TOKEN_URI = 'https://slack.com/api/oauth.v2.access';

function SlackRedirect() {
  const { oktaAuth } = useOktaAuth();
  const location = useLocation();
  const search = location.search;
  const searchParams = new URLSearchParams(search);
  const code = searchParams.get('code');
  const stateString = searchParams.get('state');
  const oAuthState = decodeOAuthState(stateString);

  // State
  const [loading, setLoading] = useState<boolean>(true);
  const [success, setSuccess] = useState<boolean>(false);

  // TODO: ADD STATE PARAMETER.

  useEffect(() => {
    // TODO: handle access denied.
    if (code) {
      const retrieveToken = async () => {
        // make sure state matches session id.
        const { id: sessionId } = (await oktaAuth.session.get()) as any;

        if (sessionId !== oAuthState.sessionId) {
          setSuccess(false);
          return;
        }

        const payloadJson = JSON.stringify({
          code,
          redirectUri: SLACK_REDIRECT_PATH,
        });

        // this entire thing could be abscracted into a shared function.
        if (!oAuthState.clientId) {
          throw new Error('No client id was passed in state');
        }
        const { data, errors } = await client.mutate({
          mutation: MAKE_CONNECTION_MUTATION,
          variables: {
            clientId: oAuthState.clientId,
            connectorSlug: 'slack',
            payloadJson,
          },
        });

        if (data?.makeConnection && !errors) {
          const newEvent = new CustomEvent('new-connection-slack', {
            detail: {
              connection: data.makeConnection,
            },
          });

          window?.opener?.dispatchEvent(newEvent);

          setSuccess(true);
        }
        setLoading(false);
      };
      retrieveToken();
    }
  }, [code]);

  if (loading) {
    return (
      <SpinContainer>
        <Spin size="large" tip="Creating Connection" />
      </SpinContainer>
    );
  }

  return (
    <Result
      status={success ? 'success' : 'error'}
      title={success ? 'Connection was successfully created!' : 'There was a problem creating this connection.'}
    />
  );
}

export default SlackRedirect;
