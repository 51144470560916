import PageSection from 'components/page-section';
import { Space, Row, Col } from 'antd';
import { WidgetPreview } from 'components/widgets/widget-editor/widget-editor';
import { EntitlementsCard } from '../entitlements-card';
import { Services as ServicesIcon } from 'components/nuspire/nu-icon';

export default function ServicesSection(props: { clientId: string }) {
  const { clientId } = props;

  return (
    <PageSection title="Nuspire Services" icon={<ServicesIcon />}>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Row gutter={20} style={{ height: '500px' }}>
          <Col lg={12} style={{ height: '100%' }}>
            <WidgetPreview slug="escalation-contacts" showHeader={false} />
          </Col>
          <Col lg={12} style={{ height: '100%' }}>
            <WidgetPreview slug="nuspire-services" showHeader={false} />
          </Col>
        </Row>
        <EntitlementsCard clientId={clientId} />
      </Space>
    </PageSection>
  );
}
