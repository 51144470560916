import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Popconfirm, Switch, Table, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useClientContext } from 'components/client-context-provider';
import { NuButton, NuPageHeader } from 'components/nuspire';
import Breadcrumb from 'components/nuspire/nu-breadcrumb';
import { useState } from 'react';
import styled from 'styled-components';
import {
  DeleteReportScheduleMutation,
  DeleteReportScheduleMutationVariables,
  GetReportSchedulesQuery,
  ReportType,
  ToggleReportScheduleMutation,
  ToggleReportScheduleMutationVariables,
} from 'types/graph-codegen/graph-types';
import { ActiveReports } from './active-reports';
import { NewScheduledReportModal, UpdateScheduledReportModal, reportTypes } from './scheduled-report-modal';

export type ReportSchedule = GetReportSchedulesQuery['reportSchedules'][number];

export const GET_REPORTS = gql`
  query GetReportSchedules($clientId: String!) {
    reportSchedules(clientId: $clientId) {
      dashboards {
        id
        name
      }
      enabled
      humanReadableCron
      id
      name
      recipients
      reportType
      timezone
    }
  }
`;

export const TOGGLE_SCHEDULE = gql`
  mutation ToggleReportSchedule($id: String!, $input: UpdateScheduleInput!) {
    updateSchedule(id: $id, input: $input) {
      enabled
    }
  }
`;

export const DELETE_SCHEDULE = gql`
  mutation DeleteReportSchedule($id: String!) {
    deleteSchedule(id: $id)
  }
`;

function ScheduleReportButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <NuButton size="large" type="primary" onClick={() => setIsModalOpen(true)}>
        Schedule Report
      </NuButton>
      <NewScheduledReportModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
}

const ActionsBar = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const DeleteButton = styled(Button).attrs({
  icon: <DeleteOutlined />,
  type: 'text',
  size: 'small',
})`
  color: ${(p) => p.theme.color.slate};
  background-color: transparent;
  &:hover {
    color: ${(p) => p.theme.color.red};
    background-color: transparent;
  }
`;

const EditButton = styled(Button).attrs({
  icon: <EditOutlined />,
  type: 'text',
  size: 'small',
})`
  color: ${(p) => p.theme.color.slate};
  background-color: transparent;
  &:hover {
    color: ${(p) => p.theme.color.red};
    background-color: transparent;
  }
`;

interface ScheduledReportActionsProps {
  enabled: boolean;
  onEnabledToggle?: (enabled: boolean) => void;
  onDelete?: () => void;
  onEdit?: () => void;
}

function ScheduleReportActions(props: ScheduledReportActionsProps) {
  const { enabled, onEnabledToggle = () => {}, onDelete = () => {}, onEdit = () => {} } = props;

  return (
    <ActionsBar>
      <Switch checked={enabled} onChange={onEnabledToggle} />
      <Popconfirm title="Are you sure you want to delete this schedule?" placement="bottomLeft" onConfirm={onDelete}>
        <DeleteButton />
      </Popconfirm>
      <EditButton onClick={onEdit} />
    </ActionsBar>
  );
}

export function ScheduledReports() {
  const { clientId } = useClientContext();
  const { data, loading } = useQuery<GetReportSchedulesQuery>(GET_REPORTS, {
    variables: {
      clientId,
    },
  });

  const [toggleSchedule] = useMutation<ToggleReportScheduleMutation, ToggleReportScheduleMutationVariables>(
    TOGGLE_SCHEDULE,
    {
      refetchQueries: [{ query: GET_REPORTS, variables: { clientId: clientId! } }],
    },
  );

  const [deleteSchedule] = useMutation<DeleteReportScheduleMutation, DeleteReportScheduleMutationVariables>(
    DELETE_SCHEDULE,
    {
      refetchQueries: [{ query: GET_REPORTS, variables: { clientId: clientId! } }],
    },
  );

  const [editingScheduleId, setEditingScheduleId] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const tableColumns: ColumnsType<ReportSchedule> = [
    {
      title: 'Report Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Type',
      key: 'reportType',
      dataIndex: 'reportType',
      render: (reportType: ReportType | null, item) => {
        if (reportType) {
          return reportTypes.find((rt) => rt.value === reportType)?.label ?? reportType;
        }
        if (item?.dashboards?.length) {
          return reportTypes.find((rt) => rt.value === 'dashboard')?.label;
        }

        return 'Unknown';
      },
    },
    {
      title: 'Schedule',
      key: 'schedule',
      dataIndex: 'humanReadableCron',
      render: (cron: string, record) => {
        // heh
        const dtf = new Intl.DateTimeFormat(undefined, {
          timeZone: record.timezone ?? 'UTC',
          timeZoneName: 'short',
        });
        const timezone = dtf.formatToParts(new Date()).find(({ type }) => type === 'timeZoneName')?.value ?? 'UTC';

        return (
          <span>
            {cron} ({timezone})
          </span>
        );
      },
    },
    {
      title: 'Recipients',
      key: 'recipients',
      dataIndex: 'recipients',
      render: (recipients: string[]) => recipients.join(', '),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <ScheduleReportActions
          enabled={record.enabled}
          onEnabledToggle={(enabled) => {
            toggleSchedule({
              variables: {
                id: record.id,
                input: {
                  enabled,
                },
              },
            });
          }}
          onDelete={async () => {
            await deleteSchedule({
              variables: {
                id: record.id,
              },
            });

            message.success('Scheduled report deleted!');
          }}
          onEdit={() => {
            setEditingScheduleId(record.id);
            setIsModalOpen(true);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Breadcrumb
        items={[
          {
            key: 'scheduled-reports',
            title: 'Scheduled Reports',
          },
        ]}
        target=""
      />
      <NuPageHeader title="Scheduled Reports" actions={<ScheduleReportButton />} />
      <ActiveReports />
      <Table rowKey="id" columns={tableColumns} dataSource={data?.reportSchedules} loading={loading} />
      {editingScheduleId !== '' && (
        <UpdateScheduledReportModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          scheduleId={editingScheduleId}
        />
      )}
    </>
  );
}
