import { DeleteOutlined, EditOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Badge,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Skeleton,
  Space,
  Table,
  Typography,
  message,
  Tooltip,
} from 'antd';
import { useClientContext } from 'components/client-context-provider';
import { ClientTaskCard } from 'components/client-tasks/client-task-card';
import MutationButton from 'components/mutation-button';
import { EmptyState, Link, NuButton, NuCard, NuCardContent, NuCardFooter, NuPaper } from 'components/nuspire';
import Breadcrumb from 'components/nuspire/nu-breadcrumb';
import { NuEmptyState } from 'components/nuspire/nu-empty-state/nu-empty-state.stories';
import PageHeader from 'components/nuspire/nu-page-header';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import PageSection from 'components/page-section';
import theme from 'components/theme';
import dayjs from 'dayjs';
import { useState } from 'react';
import EDRLogo from 'resources/images/edr.png';
import MSSLogo from 'resources/images/mss.png';
import styled from 'styled-components';
import {
  GetSupportDownloadsByClientQuery,
  ServiceNowClientServiceOffering,
  UpdateSupportDownloadMutation,
} from 'types/graph-codegen/graph-types';
import EntitlementsSection from './entitlements-section';
import { Content } from '../layouts/content';
import { useAuthContext } from 'components/auth-context';
import { ColumnsType } from 'antd/es/table';

type SupportDownloads = NonNullable<GetSupportDownloadsByClientQuery['getSupportDownloadByClientId']>;

type Unpacked<T> = T extends (infer U)[] ? U : T;
type SupportDownload = Unpacked<SupportDownloads>;

export const CLIENTS_RUNBOOK_ESCALATIONS_QUERY = gql`
  query ClientsRunbookEscalations($clientId: String, $contactType: String) {
    clientRunbookEscalationsForClient(clientId: $clientId, contactType: $contactType) {
      account {
        name
        passphrase
      }
      accountSysId
      contactType
      escalationOrder
      runbookSysId
      sysId
      user {
        email
        mobile_phone
        name
        phone
        title
      }
      userSysId
    }
  }
`;

export const EXPORT_RUNBOOK_ESCALATIONS_QUERY = gql`
  query ExportRunbookEscalations($filesToDownload: [ExportOptions]) {
    exportRunbookEscalations(filesToDownload: $filesToDownload) {
      data
      metadata {
        file_name
        content_type
      }
      content_disposition
    }
  }
`;
const CLIENT_SERVICES = gql`
  query ClientServices($clientId: String!) {
    clientServiceOfferings(clientId: $clientId) {
      id
      sysId
      serviceOfferingSysId
      serviceOfferingName
      serviceOfferingShortDesc
      parentServiceSysId
      parentServiceName
      grandParentServiceSysId
      grandParentServiceName

      healthCheckResults(clientId: $clientId) {
        key
        clientId
        name
        status
        statusSummary
        criticality
        clientTasks {
          id
          type
          shortId
          clientId
          label
          status
          summary
          createdAt
          priority
        }
      }
    }

    # services to promote to the client
    moreServices(clientId: $clientId) {
      key
      serviceName
      serviceType
      serviceTypeName
      description
      moreInfoUrl
    }
  }
`;

const ExpandedServiceRoot = styled.div`
  padding-left: 64px;
  padding-bottom: 64px;

  .empty-health-check-tasks {
    padding: 20px 16px;
    margin-bottom: 0;

    .empty-state-message {
      margin-bottom: 0;
    }
  }

  .ant-table-thead {
    background-color: ${(p) => p.theme.token.colorBgElevated};

    & > tr > th {
      background-color: transparent;
    }
  }
`;

function ServiceDescription(props: { service: ServiceNowClientServiceOffering }) {
  const { service } = props;

  if (service?.serviceOfferingShortDesc) {
    return (
      <>
        <Typography.Title level={5}>Short Description</Typography.Title>
        <Typography.Paragraph>{service?.serviceOfferingShortDesc}</Typography.Paragraph>
      </>
    );
  }

  return <Skeleton style={{ maxWidth: '1000px' }} />;
}

const HealthCheckStatus = styled.div<{ status: string }>`
  text-transform: capitalize;
  color: ${(p) => {
    const { status } = p;

    if (status === 'pass') {
      return p.theme.color.success;
    }

    if (status === 'fail') {
      return p.theme.color.error;
    }

    return p.theme.color.gray1;
  }};
`;

const CRITICALITY_SCALE: {
  min: number;
  label: string;
  color: string;
}[] = [
  {
    min: 51,
    label: 'Critical',
    color: theme.color.error,
  },
  {
    min: 26,
    label: 'High',
    color: theme.color.orange,
  },
  {
    min: 1,
    label: 'Medium',
    color: theme.color.riverBlue,
  },
  {
    min: 0,
    label: 'Low',
    color: '#9B59B6',
  },
];

function getCriticalityDef(value: number) {
  const match = CRITICALITY_SCALE.find((d) => value >= d.min) ?? CRITICALITY_SCALE[CRITICALITY_SCALE.length - 1];

  return match;
}

function HealthCheckCriticality(props: { criticality: number }) {
  const criticalDef = getCriticalityDef(props.criticality);

  return <Badge color={criticalDef.color} text={criticalDef.label} />;
}

function HealthCheckTasksList(props: { tasks?: any[] }) {
  const { tasks = [] } = props;

  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      {tasks.map((task) => (
        <ClientTaskCard key={task.id} {...task} />
      ))}
    </Space>
  );
}

function HealthCheckTasks(props: { tasks?: any[] }) {
  const { tasks = [] } = props;

  if (tasks.length) {
    return <HealthCheckTasksList tasks={tasks} />;
  }

  return (
    <EmptyState
      classNames={{
        root: 'empty-health-check-tasks',
      }}
    >
      No Issues found for this Health Check
    </EmptyState>
  );
}

function ServiceHealthChecksTable(props: { healthChecks: any[] }) {
  const { healthChecks } = props;

  if (!healthChecks) {
    return <NuEmptyState>No Health Checks</NuEmptyState>;
  }

  return (
    <Table
      size="small"
      dataSource={healthChecks}
      pagination={false}
      data-intercom-target="health-checks"
      columns={[
        {
          key: 'check',
          title: 'Check',
          dataIndex: 'name',
          render: (name) => <strong>{name}</strong>,
        },
        {
          key: 'status',
          title: 'Status',
          dataIndex: 'status',
          render: (status) => <HealthCheckStatus status={status}>{status}</HealthCheckStatus>,
        },
        {
          key: 'criticality',
          title: 'Criticality',
          dataIndex: 'criticality',
          render: (c) => <HealthCheckCriticality criticality={c} />,
        },
        {
          key: 'summary',
          title: 'Summary',
          dataIndex: 'statusSummary',
          width: '400px',
        },
        {
          key: 'issues',
          title: 'Tasks',
          dataIndex: 'clientTasks',
          render: (tasks) => <HealthCheckTasks tasks={tasks} />,
          width: '700px',
        },
      ]}
    />
  );
}

function ServiceHealthChecks(props: { service: any }) {
  const {
    service: { healthCheckResults },
  } = props;

  if (!healthCheckResults.length) {
    return null;
  }

  return (
    <>
      <Divider />

      <PageHeader level={5} title="Health Checks" />

      <ServiceHealthChecksTable healthChecks={healthCheckResults} />
    </>
  );
}

function ExpandedService(props: { service: any }) {
  const { service } = props;
  console.log({ service });

  return (
    <ExpandedServiceRoot>
      <Space direction="vertical" style={{ display: 'flex' }} size="large">
        <ServiceDescription service={service} />
        <ServiceHealthChecks service={service} />
      </Space>
    </ExpandedServiceRoot>
  );
}

const ServiceColRoot = styled.div`
  display: flex;
  align-items: center;
`;
const SctGroup = styled.div`
  width: 240px;
  margin-right: 24px;
`;
const ServiceTypeName = styled.div`
  color: #9c9c9c;
  font-size: 12px;
`;
const ServiceName = styled.div`
  font-size: 16px;
  font-weight: bold;
`;
const SubTypeServiceName = styled.div`
  color: #7c7c7c;
`;

function getServiceLogoSource(serviceType: string) {
  if (serviceType === 'mss') {
    return MSSLogo;
  }

  return EDRLogo;
}

const ServiceLogoRoot = styled.div`
  margin-right: 16px;
`;
function ServiceLogo(props: { serviceType: string }) {
  const { serviceType } = props;

  return (
    <ServiceLogoRoot>
      <img
        alt="service-logo"
        src={getServiceLogoSource(serviceType)}
        style={{
          width: '32px',
          height: '32px',
        }}
      />
    </ServiceLogoRoot>
  );
}

function flattenServiceHierarchy(clientServiceOffering: any) {
  const serviceOffering = clientServiceOffering.serviceOfferingName;
  const serviceOfferingParent = clientServiceOffering.parentServiceName ?? null;
  const serviceOfferingGrandparent = clientServiceOffering.grandParentServiceName ?? null;

  const flatServiceHierarchy: string[] = [serviceOffering];

  if (serviceOfferingParent) {
    flatServiceHierarchy.unshift(serviceOfferingParent);
  }

  if (serviceOfferingGrandparent) {
    flatServiceHierarchy.unshift(serviceOfferingGrandparent);
  }

  return flatServiceHierarchy;
}

export function ClientServiceOfferingCol(props: { clientServiceOffering: any }) {
  const { clientServiceOffering } = props;

  const flatServiceHierarchy = flattenServiceHierarchy(clientServiceOffering);

  const serviceTypeName = flatServiceHierarchy[0];
  const serviceName = flatServiceHierarchy[1];
  const subTypeServiceName = flatServiceHierarchy[2];

  return (
    <ServiceColRoot>
      <ServiceLogo serviceType="mss" />
      <SctGroup className="service-titles">
        {serviceTypeName && <ServiceTypeName>{serviceTypeName}</ServiceTypeName>}
        <ServiceName>{serviceName}</ServiceName>
      </SctGroup>

      {subTypeServiceName && <SubTypeServiceName>{subTypeServiceName}</SubTypeServiceName>}
    </ServiceColRoot>
  );
}

export function ServiceCol(props: { service: any }) {
  const {
    service: { serviceType, serviceName, subTypeServiceName, serviceTypeName },
  } = props;

  return (
    <ServiceColRoot>
      <ServiceLogo serviceType={serviceType} />
      <SctGroup className="service-titles">
        {serviceTypeName && <ServiceTypeName>{serviceTypeName}</ServiceTypeName>}
        <ServiceName>{serviceName}</ServiceName>
      </SctGroup>

      {subTypeServiceName && <SubTypeServiceName>{subTypeServiceName}</SubTypeServiceName>}
    </ServiceColRoot>
  );
}

export const ClientServiceStatusRoot = styled.div<{ status: 'healthy' | 'moderate' | 'critical' | 'coming soon' }>`
  font-weight: bold;

  text-transform: capitalize;

  color: ${(p) => {
    if (p.status === 'healthy') {
      return p.theme.color.success;
    }

    if (p.status === 'critical') {
      return p.theme.color.error;
    }

    if (p.status === 'moderate') {
      return p.theme.color.orange;
    }

    return p.theme.color.gray5;
  }};
`;

const ClientServicesTableRoot = styled.div``;

export function getServiceStatus(service: any) {
  const healthCheckResults = service.healthCheckResults ?? [];

  if (!healthCheckResults.length) {
    return 'coming soon';
  }

  const failingHealthCheck = healthCheckResults.find((result) => result.status === 'fail');

  if (failingHealthCheck) {
    return 'critical';
  }

  return 'healthy';
}

export const statuses = ['healthy', 'unknown', 'coming soon'];

export function EmptyServicesState() {
  const { clientId } = useClientContext();

  return (
    <NuEmptyState actions={<Link to={`/${clientId}/case-management/cases`}>Open Case</Link>}>
      There is a configuration error with displaying your services. Please contact support to open a case.
    </NuEmptyState>
  );
}

function ClientServicesTable(props: { clientServiceOfferings: any[] }) {
  const { clientServiceOfferings } = props;

  return (
    <NuPaper level={1}>
      <ClientServicesTableRoot data-intercom-target="services-table">
        <Table
          rowKey="id"
          dataSource={clientServiceOfferings}
          pagination={false}
          columns={[
            {
              key: 'service',
              title: 'Service',
              render: (_val, clientServiceOffering) => {
                return <ClientServiceOfferingCol clientServiceOffering={clientServiceOffering} />;
              },
            },
            {
              key: 'status',
              dataIndex: 'healthCheckResults',
              title: 'Status',
              render: (_healthCheckResults, record) => {
                const status = getServiceStatus(record);

                const formattedStatus = statuses.includes(status) ? status : 'Needs Attention';

                return <ClientServiceStatusRoot status={status}>{formattedStatus}</ClientServiceStatusRoot>;
              },
            },
            {
              key: 'implemented',
              dataIndex: 'implementedAt',
              title: 'Implementation Date',
              render: (d) => {
                if (!d) {
                  return '';
                }

                const m = dayjs(d);
                return m.format('MM/DD/YYYY');
              },
            },
            {
              key: 'renewalDate',
              dataIndex: 'renewalDate',
              title: 'Renewal Date',
              render: (d) => {
                if (!d) {
                  return '';
                }

                const m = dayjs(d);
                return m.format('MM/DD/YYYY');
              },
            },
          ]}
          expandable={{
            // expandIcon,
            expandedRowRender: (service) => <ExpandedService service={service} />,
          }}
        />
      </ClientServicesTableRoot>
    </NuPaper>
  );
}

export function GetKnowledgeBase(props: { title: string; url: string }) {
  const { title, url } = props;
  const [exportRunbook] = useLazyQuery(EXPORT_RUNBOOK_ESCALATIONS_QUERY);

  const onClick = async () => {
    window.open(url, '_blank');
  };

  return <NuCardFooter label={title} onClick={() => onClick()} />;
}

const RESOURCES: {
  title: string;
  linkLabel: string;
  url: string;
}[] = [
  {
    title: 'Nuspire Support and Responses Guide',
    linkLabel: 'Open',
    url: `${window.origin}/knowledge/KB0012580`,
  },
  {
    title: 'Nuspire Service Assurance and Escalation Path',
    linkLabel: 'Open',
    url: `${window.origin}/knowledge/KB0012578`,
  },
  {
    title: 'Nuspire Change Management Overview',
    linkLabel: 'Open',
    url: `${window.origin}/knowledge/KB0012577`,
  },
];
function UsefulResourceCard(props: { resource: any }) {
  const {
    resource: { title, url },
  } = props;
  return (
    <NuCard fullHeight>
      <GetKnowledgeBase title={title} url={url} />
    </NuCard>
  );
}

const UsefulResourcesRoot = styled.div`
  .card-footer-nav-label {
    font-weight: bold;
    font-size: 18px;
  }
`;
export function UsefulResources() {
  return (
    <UsefulResourcesRoot>
      <Row gutter={24}>
        {RESOURCES.map((resource) => (
          <Col key={resource.url} lg={8} span={24} style={{ marginBottom: '24px' }}>
            <UsefulResourceCard resource={resource} />
          </Col>
        ))}
      </Row>
    </UsefulResourcesRoot>
  );
}

function MoreServiceCard(props: { service: any }) {
  const { service } = props;

  return (
    <NuCard fullHeight>
      <NuCardContent style={{ paddingTop: '16px' }}>
        <Space direction="vertical">
          <ServiceCol service={service} />
          {service.serviceOfferingShortDesc ? (
            <Typography.Paragraph>{service.serviceOfferingShortDesc}</Typography.Paragraph>
          ) : (
            <Skeleton />
          )}
        </Space>
      </NuCardContent>
      <NuCardFooter url={service.moreInfoUrl} label="Learn More" />
    </NuCard>
  );
}

const MoreServicesRoot = styled.div`
  .service-titles {
    width: initial;
  }
`;
function MoreServices(props: { moreServices: any }) {
  const { moreServices } = props;

  const lgSpan = moreServices?.length < 3 ? 12 : 8;

  if (moreServices.length) {
    return (
      <MoreServicesRoot>
        <PageHeader title="More Services" level={4} />
        <Row gutter={24}>
          {moreServices.map((service) => (
            <Col key={service.key} lg={lgSpan} span={24} style={{ marginBottom: '24px' }}>
              <MoreServiceCard service={service} />
            </Col>
          ))}
        </Row>
      </MoreServicesRoot>
    );
  }

  return null;
}

function ClientServicesView(props: { clientServiceOfferings: any; moreServices: any; clientId: string }) {
  const { clientId, clientServiceOfferings, moreServices } = props;
  const { isNuspireUser } = useAuthContext();

  return (
    <Space direction="vertical" size="large" style={{ display: 'flex', gap: '48px' }}>
      <PageSection title="Your Services" actions={<NuButton type="primary">Contact Support</NuButton>}>
        <ClientServicesTable clientServiceOfferings={clientServiceOfferings} />
      </PageSection>

      <EntitlementsSection clientId={clientId} />

      <Divider />

      <PageSection title="Support Documents">
        <UsefulResources />
      </PageSection>

      <PageSection title="Support Downloads">
        <SupportDownloads />
        {isNuspireUser && <SupportDownloadFormModal />}
      </PageSection>

      <MoreServices moreServices={moreServices} />
    </Space>
  );
}

function ClientServicesQuery(props: { clientId }) {
  const { clientId } = props;

  const { data, loading } = useQuery(CLIENT_SERVICES, {
    variables: {
      clientId,
    },
  });

  const clientServiceOfferings = data?.clientServiceOfferings;
  const moreServices = data?.moreServices;

  if (clientServiceOfferings) {
    return (
      <ClientServicesView
        clientServiceOfferings={clientServiceOfferings}
        moreServices={moreServices}
        clientId={clientId}
      />
    );
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin tip="Loading your Services">
          <div className="content" />
        </Spin>
      </SpinContainer>
    );
  }

  return <EmptyState>There was problem fetching your Services. Please contact Support</EmptyState>;
}

export function ClientServicesPage() {
  const { clientId } = useClientContext();

  // Render
  return (
    <Content>
      <Breadcrumb
        items={[
          {
            key: 'services',
            title: 'Services',
          },
        ]}
        target="/services"
      />
      <PageHeader
        title="myNuspire Services"
        styles={{
          root: {
            paddingBottom: '24px',
          },
        }}
      />
      <ClientServicesQuery clientId={clientId} />
    </Content>
  );
}

const CREATE_SUPPORT_DOWNLOAD = gql`
  mutation CreateSupportDownload(
    $clientId: String!
    $title: String!
    $url: String!
    $hash: String!
    $description: String
    $password: String
  ) {
    createSupportDownload(
      clientId: $clientId
      title: $title
      url: $url
      hash: $hash
      description: $description
      password: $password
    ) {
      id
      clientId
      title
      url
      hash
      description
      password
    }
  }
`;

function SupportDownloadFormModal() {
  const [form] = Form.useForm();

  const { clientId } = useClientContext();

  const [createSupportDownload, { loading }] = useMutation(CREATE_SUPPORT_DOWNLOAD);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    form.submit();
  };
  const onCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <NuButton type="primary" htmlType="submit" loading={loading} onClick={showModal} style={{ marginTop: '1.0rem' }}>
        Create Support Download
      </NuButton>
      <Modal open={isModalOpen} onOk={handleOk} onCancel={onCancel}>
        Create Support Download
        <Form
          layout="vertical"
          style={{ marginBottom: '1.5rem' }}
          form={form}
          onFinish={async (formValues) => {
            const { title, url, hash, description, password } = formValues;

            try {
              await createSupportDownload({
                variables: {
                  clientId,
                  title,
                  url,
                  hash,
                  description,
                  password,
                },
                refetchQueries: ['GetSupportDownloadsByClient'],
              });

              setIsModalOpen(false);
            } catch (err: any) {
              console.error('Please Try Again');
              message.error('Failed to create Support Download');
            }
          }}
        >
          <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Please enter a title' }]}>
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="URL"
            name="url"
            rules={[
              { required: true, message: 'Please enter a url' },
              { type: 'url', message: 'Please enter a valid url' },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            label="Hash"
            name="hash"
            rules={[
              { required: true, message: 'Please enter a hash' },
              { pattern: /[A-Fa-f0-9]{64}/, message: 'Please enter a valid SHA 256 Hash' },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: false, message: 'Please enter a description ' }]} //add pattern for space
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item label="Password" name="password">
            <Input.Password size="large" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

const SUPPORT_DOWNLOAD = gql`
  query GetSupportDownloadsByClient($clientId: String!) {
    getSupportDownloadByClientId(clientId: $clientId) {
      id
      clientId
      title
      url
      hash
      description
      password
    }
  }
`;
function SupportDownloads() {
  const { clientId } = useClientContext();
  const { isNuspireUser } = useAuthContext();

  const [loading] = useState<boolean>(false);
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [passwordsToShow, setPasswordsToShow] = useState<{ [key in string]: boolean }>({});

  const variables = {
    clientId,
  };

  const { data } = useQuery<GetSupportDownloadsByClientQuery>(SUPPORT_DOWNLOAD, {
    variables,
  });

  //controls open/close modal
  const [updateSupportDownload, setUpdateSupportDownload] = useState<SupportDownload | undefined>();

  const columns: ColumnsType<any> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (title, item) => (
        <a href={item.url} target="_blank" rel="noreferrer">
          {title}
        </a>
      ),
    },
    {
      title: <Tooltip title="The URL of the file to be downloaded">URL</Tooltip>,
      dataIndex: 'url',
      key: 'url',
      render: (url) => (
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      ),
    },
    {
      title: (
        <Tooltip title="SHA 256 hash of the file.  This can be used by the user to validate the file integrity.">
          Hash
        </Tooltip>
      ),
      dataIndex: 'hash',
      key: 'hash',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Password',
      dataIndex: 'password',
      key: 'password',
      render: (password, item) => {
        let pass = '';
        if (!passwordsToShow[item.id] && password) {
          for (const _ of password) {
            pass += '*';
          }
        } else {
          pass = password;
        }

        const icon = passwordsToShow[item.id] ? (
          <EyeInvisibleOutlined onClick={() => setPasswordsToShow({ ...passwordsToShow, [item.id]: false })} />
        ) : (
          <EyeOutlined onClick={() => setPasswordsToShow({ ...passwordsToShow, [item.id]: true })} />
        );
        return (
          <div style={{ display: 'flex' }}>
            <Typography.Text>{pass}</Typography.Text>
            <Typography.Text style={{ marginLeft: 'auto' }}>{icon}</Typography.Text>
          </div>
        );
      },
    },
  ];

  if (isNuspireUser) {
    columns.push(
      {
        key: 'update-options',
        render: (supportDownload) => {
          return (
            <SupportDownloadOptions
              supportDownload={supportDownload}
              onUpdate={() => {
                setShowUpdateModal(true);
                setUpdateSupportDownload(supportDownload);
              }}
            />
          );
        },
      },
      {
        key: 'delete-options',
        render: (supportDownload) => {
          return <DeletePopConfirm supportDownload={supportDownload} />;
        },
      },
    );
  }

  return (
    <>
      {updateSupportDownload && (
        <UpdateSupportDownloadOptions
          supportDownload={updateSupportDownload}
          onCloseModal={() => setUpdateSupportDownload(undefined)}
          setShowUpdateModal={setShowUpdateModal}
          showUpdateModal={showUpdateModal}
        />
      )}
      <Table loading={loading} dataSource={data?.getSupportDownloadByClientId} columns={columns} />
    </>
  );
}

const DELETE_SUPPORT_DOWNLOAD = gql`
  mutation DeleteSupportDownload($id: String!) {
    deleteSupportDownload(id: $id)
  }
`;

function DeletePopConfirm(props: { supportDownload: SupportDownload }) {
  const {
    supportDownload: { id },
  } = props;

  const modalTitle = `Are you sure you want to delete ?`;

  const variables = {
    id,
  };

  const onCompleted = (data) => {
    try {
      message.success(data);
    } catch (err: any) {
      console.error(err);
    }
  };

  const onError = (error?: any) => {
    const errorMessage = `There was an error . ${error ? ` Error: ${error.message}` : `.`}`;

    message.error(errorMessage);
  };

  return (
    <MutationButton
      buttonIcon={<DeleteOutlined />}
      danger
      modalOkType="danger"
      modalTitle={modalTitle}
      mutationVariables={variables}
      refetchQueries={['GetSupportDownloadsByClient']}
      mutation={DELETE_SUPPORT_DOWNLOAD}
      onError={onError}
      onCompleted={onCompleted}
      tooltipOverlay="Delete Support Download"
    />
  );
}

const UPDATE_SUPPORT_DOWNLOAD = gql`
  mutation UpdateSupportDownload(
    $id: String!
    $hash: String
    $url: String
    $title: String
    $description: String
    $password: String
  ) {
    updateSupportDownload(
      id: $id
      url: $url
      hash: $hash
      title: $title
      description: $description
      password: $password
    ) {
      id
      hash
      url
      title
      description
      password
    }
  }
`;
function UpdateSupportDownloadOptions(props: {
  supportDownload: SupportDownload;
  onCloseModal: () => void;
  showUpdateModal: boolean;
  setShowUpdateModal: (boolean) => void;
}) {
  const { onCloseModal, showUpdateModal, setShowUpdateModal } = props;

  const [form] = Form.useForm();

  const handleOk = () => {
    form.submit();
  };

  const {
    supportDownload: { id, url, hash, title, description, password },
  } = props;

  const [updateSupportDownload] = useMutation<UpdateSupportDownloadMutation>(UPDATE_SUPPORT_DOWNLOAD);

  return (
    <Modal onOk={handleOk} onCancel={() => setShowUpdateModal(false)} open={showUpdateModal}>
      <Form
        initialValues={{ url, hash, title, description, password }}
        layout="vertical"
        style={{ marginBottom: '1.5rem' }}
        form={form}
        onFinish={async (formValues) => {
          try {
            const data = await updateSupportDownload({
              variables: {
                id,
                url: formValues.url,
                hash: formValues.hash,
                title: formValues.title,
                description: formValues.description,
                password: formValues.password,
              },
              refetchQueries: ['GetSupportDownloadsByClient'],
            });
            console.log(data);
            onCloseModal();
          } catch (err: any) {
            console.error('Could Not Update');
          }
        }}
      >
        <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Please enter a title' }]}>
          <Input size="large" />
        </Form.Item>

        <Form.Item
          label="URL"
          name="url"
          rules={[
            { required: true, message: 'Please enter a url' },
            { type: 'url', message: 'Please enter a valid url' },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Hash"
          name="hash"
          rules={[
            { required: true, message: 'Please enter a hash' },
            { pattern: /[A-Fa-f0-9]{64}/, message: 'Please enter a valid SHA 256 Hash' },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: false, message: 'Please enter a description ' }]} //add pattern for space
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Password" name="password">
          <Input.Password size="large" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

function SupportDownloadOptions(props: { supportDownload: SupportDownload; onUpdate: () => void }) {
  const { supportDownload, onUpdate } = props;

  return <NuButton type="default" icon={<EditOutlined />} onClick={onUpdate} />;
}
