import { TCountries, TCountryCode, countries, getEmojiFlag } from 'countries-list';
import { Form, Input, Select } from 'antd';
import { PatternFormat } from 'react-number-format';
import styled from 'styled-components';
import { Rule } from 'antd/es/form';
import { ChangeEvent } from 'react';
import { parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';

export const alphabetizedCountries: TCountries = Object.entries(countries)
  .sort(([, countryDataA], [, countryDataB]) => countryDataA.name.localeCompare(countryDataB.name))
  .reduce((obj, [key]) => {
    obj[key] = countries[key];
    return obj;
  }, {} as TCountries);

// https://dev.to/sarahscode/props-are-not-forever-preventing-props-from-being-passed-to-the-dom-with-styled-components-v5-1-l47
const StyledPhoneInput = styled(Input).withConfig({
  shouldForwardProp: (prop) => !['addonWidth'].includes(prop),
})<{ addonWidth?: string }>`
  .ant-input-group-addon {
    width: ${(p) => p.addonWidth ?? '20%'};
    padding-left: 0;
    padding-right: 0;
  }
`;

type PhoneInputProps = {
  countryCodeSelectWidth?: string;
  showExtension?: boolean;
  name: string;
  initialValues?: {
    countryCodeKey?: string;
    number?: string;
    extension?: string;
  };
  /**
   * setting this will attempt to extract the country code & number.
   * overrides initialValues.
   */
  initialValue?: string;
  label?: string;
  onCountryCodeSelect?: (newCountryCode: string) => void;
  onExtensionChange?: (newExtension: string) => void;
  onPhoneNumberChange?: (newPhoneNumber: string) => void;
  onPressEnter?: () => void;
  rules?: {
    phoneNumber?: Rule[];
    phoneNumberExt?: Rule[];
  };
};

export type PhoneInputFormValues = {
  number?: string;
  extension?: string;
  /**
   * @example US-1
   */
  countryCodeKey: string;
};

export const defaultCountryCodeKey = 'US-1';

export default function PhoneInput(props: PhoneInputProps) {
  const {
    countryCodeSelectWidth,
    showExtension = true,
    name,
    initialValues = {},
    initialValue,
    label,
    onCountryCodeSelect,
    onExtensionChange,
    onPhoneNumberChange,
    onPressEnter,
    rules = {},
  } = props;
  const filterCountrySelect = (input: string, option: any) => {
    return option.children.join().toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  let parsedInitialValue: PhoneNumber | undefined;
  if (initialValue) {
    try {
      parsedInitialValue = parsePhoneNumber(initialValue, 'US');
    } catch (err) {
      console.warn('Failed to parse phone number from `initialValue`');
    }
  }

  let initialCountryCodeKey = initialValues.countryCodeKey ?? defaultCountryCodeKey;
  if (parsedInitialValue?.country) {
    initialCountryCodeKey = `${parsedInitialValue.country}-${parsedInitialValue.countryCallingCode}`;
  }

  const initialNumber = parsedInitialValue?.nationalNumber ?? initialValues.number;
  const initialExtension = parsedInitialValue?.ext ?? initialValues.extension;

  const countryCodeSelect = (
    <Form.Item name={[name, 'countryCodeKey']} initialValue={initialCountryCodeKey} noStyle>
      <Select
        showSearch
        filterOption={filterCountrySelect}
        style={{ width: '100%' }}
        className="select-before"
        onSelect={onCountryCodeSelect}
        optionLabelProp="shortlabel"
        dropdownStyle={{ minWidth: '400px' }}
      >
        {Object.entries(alphabetizedCountries).map(([key, countryData]) =>
          countryData.phone.map((phoneCode) => (
            <Select.Option
              key={`${key}-${phoneCode}`}
              value={`${key}-${phoneCode}`}
              shortlabel={`${getEmojiFlag(key as TCountryCode)} (+${phoneCode})`}
            >
              <span role="img" aria-label={countryData.name}>
                {getEmojiFlag(key as TCountryCode)}
              </span>{' '}
              {countryData.name} (+{phoneCode})
            </Select.Option>
          )),
        )}
      </Select>
    </Form.Item>
  );

  return (
    <Form.Item label={label} style={{ marginBottom: 0 }}>
      <Form.Item
        name={[name, 'number']}
        initialValue={initialNumber}
        style={{ display: 'inline-block', width: showExtension ? 'calc(75% - 8px)' : '100%' }}
        rules={rules.phoneNumber}
      >
        <PatternFormat
          customInput={StyledPhoneInput}
          addonWidth={countryCodeSelectWidth}
          format="(###) ###-####"
          placeholder="(555) 555-5555"
          addonBefore={countryCodeSelect}
          type="tel"
          onChange={(e: ChangeEvent<HTMLInputElement>) => onPhoneNumberChange?.(e.target.value)}
          onPressEnter={onPressEnter}
        />
      </Form.Item>
      {showExtension && (
        <Form.Item
          name={[name, 'extension']}
          style={{ display: 'inline-block', width: 'calc(25% - 8px)' }}
          initialValue={initialExtension}
          rules={rules.phoneNumberExt}
        >
          <Input
            type="tel"
            placeholder="Ext."
            style={{ flex: 2, marginLeft: '1rem' }}
            onChange={(e) => onExtensionChange?.(e.target.value)}
            onPressEnter={onPressEnter}
          />
        </Form.Item>
      )}
    </Form.Item>
  );
}
