import { ReactNode } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export type SubNavItemType = {
  key: string;
  type?: 'group';
  title: ReactNode;
  to?: string;
  items?: SubNavItemType[];
  exact?: boolean;
  'data-intercom-target'?: string;
};

export type SubNavProps = {
  items: SubNavItemType[];
};

// STYLED COMPONENTS
const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

const SubNavLink = styled(NavLink)`
  position: relative;
  padding: 8px 16px 8px 32px;

  color: ${(p) => p.theme.token.colorText};

  &:hover {
    color: ${(p) => p.theme.token.colorLinkHover};
  }

  &.active {
    color: ${(p) => p.theme.token.colorLinkActive};
  }

  &.active::after {
    position: absolute;
    display: block;
    content: '';
    width: 3px;
    top: 0;
    left: 0;
    height: 100%;
    background-color: ${(p) => p.theme.token.colorLinkActive};
  }
`;

export function SubNavItem(props: { title: ReactNode; to: string; exact?: boolean; intercomTarget?: string }) {
  const { title, to, exact, intercomTarget } = props;

  return (
    <SubNavLink to={to} exact={exact} data-intercom-target={intercomTarget}>
      {title}
    </SubNavLink>
  );
}

const SubNavGroupRoot = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0;
`;
const SubNavGroupTitle = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  color: ${(p) => p.theme.token.colorText};
  padding: 8px 16px 8px 32px;
`;
const SubNavGroupItems = styled.div`
  display: flex;
  flex-direction: column;
`;
export function SubNavGroup(props: { title: ReactNode; items?: SubNavItemType[] }) {
  const { title, items } = props;

  return (
    <SubNavGroupRoot>
      <SubNavGroupTitle>{title}</SubNavGroupTitle>
      <SubNavGroupItems>
        {items?.map((i) => (
          <SubNavItem
            key={i.key}
            title={i.title}
            to={i.to ?? ''}
            exact={i.exact}
            intercomTarget={i['data-intercom-target']}
          />
        ))}
      </SubNavGroupItems>
    </SubNavGroupRoot>
  );
}

export function RootSubNavItem(props: { item: SubNavItemType }) {
  const {
    item,
    item: { title, items, to, type, exact },
  } = props;

  if (type === 'group') {
    return <SubNavGroup title={title} items={items} />;
  }

  return <SubNavItem title={title} to={to ?? ''} exact={exact} intercomTarget={item['data-intercom-target']} />;
}

export default function SubNav(props: SubNavProps) {
  const { items } = props;

  return (
    <Root>
      {items.map((i) => (
        <RootSubNavItem key={i.key} item={i} />
      ))}
    </Root>
  );
}
