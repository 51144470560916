import { gql } from '@apollo/client';

export const CLIENT_TASK_ACTION_FIELDS = gql`
  fragment ClientTaskActionFields on ClientTaskAction {
    id
    title
    blocks
    status
    createdAt
    taskId
    clientId
  }
`;

export const CLIENT_TASK_ACTIVITY_FIELDS = gql`
  fragment ClientTaskActivityFields on ClientTaskActivity {
    id
    clientId
    text
    type
    ok
    taskId
    edited
    user {
      id
      firstName
      lastName
      email
    }
    data
    createdAt
  }
`;

export const CLIENT_TASK_DETAIL_FIELDS = gql`
  ${CLIENT_TASK_ACTION_FIELDS}
  ${CLIENT_TASK_ACTIVITY_FIELDS}

  fragment ClientTaskDetailFields on ClientTask {
    id
    shortId
    type
    clientId
    label
    status
    snowCaseNumber
    snowIncidentNumber
    members

    startDate
    endDate
    createdAt

    summary

    impact
    remediation
    info
    tags
    priority

    actions {
      ...ClientTaskActionFields
    }

    activity {
      ...ClientTaskActivityFields
    }

    widgets {
      id
      name
      description
      widgetSlug
      configuration
      widgetDefinition {
        id
        name
        type
      }
      data(viewingClientId: $clientId)
    }
  }
`;
