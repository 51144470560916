import Sider from 'antd/es/layout/Sider';
import Layout from 'antd/es/layout/layout';
import { Outlet } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useClientContext } from '../client-context-provider';
import SettingsSubNav from './settings-sub-nav';
import { Content } from '../layouts/content';

export function SettingsLayout() {
  const { clientId } = useClientContext();
  const theme = useTheme();

  return (
    <Layout>
      <Sider
        style={{
          background: 'transparent',
          borderRight: `1px solid ${theme.token.colorBorder}`,
        }}
      >
        <SettingsSubNav clientId={clientId ?? ''} />
      </Sider>
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
}
