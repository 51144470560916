import { useEffect, useRef } from 'react';
import baseTheme from 'components/theme';
import { WidgetComponentProps } from '../..';
import EmptyState from 'components/nuspire/nu-empty-state';
import { Link } from 'components/nuspire';
import { useClientContext } from 'components/client-context-provider';
import styled from 'styled-components';
import { Typography } from 'antd';
import { ClientTaskCard } from 'components/client-tasks/client-task-card';
import useElementSize from 'utils/react-hooks/use-element-size';

interface BarScale {
  good: number;
  moderate: number;
  critical?: number;
}

/*

  Takes in the upper bound of a three tier scale.
  ex. { good: 500, moderate: 3000 } would give us
  good = 0 to 500 logs, moderate = 501 to 3000 logs, critical = 3001+ logs

*/
const getBarTabPosition = (args: BarScale & { count: number }): number => {
  const { good, moderate, critical = 5000, count } = args;

  // === if count if larger than critical return 100%
  if (count >= critical) return 100;

  // === find what section it should be in ===
  let section;

  if (count >= 0 && count <= good) {
    section = { add: 0, start: 0, end: good };
  } else if (count > good && count <= moderate) {
    section = { add: 100, start: good + 1, end: moderate };
  } else {
    section = { add: 200, start: moderate + 1, end: critical };
  }

  const { end, add } = section;

  return (((count / end) * 100 + add) / 300) * 100;
};

const Bar = styled.div`
  height: 2rem;
  position: relative;
  border-radius: 20px;
  display: flex;
`;

const BarSectionBackground = styled.div<{ color: string }>`
  width: calc(100% / 3);
  background-color: ${(props) => props.color};
  display: flex;
`;

const BarSection = styled.div<{ color: string }>`
  width: 100%;
  background-color: ${(props) => props.color};
  display: flex;
`;

const BarSectionText = styled.div`
  margin: auto;
  color: white;
  fontweight: 700;
`;

const BarTabContainer = styled.div<{ position: number }>`
  position: absolute;
  left: ${(props) => `${props.position}px`};
  top: -45px;
  display: flex;
  flex-direction: column;
`;

const BarTab = styled.div`
  background-color: ${baseTheme.color.gray5};
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  margin: auto;
`;

const BarTabInnerCircle = styled.div`
  background-color: white;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  margin: auto;
`;

const BarTabCount = styled.div`
  font-size: 20px;
  margin: auto;
`;

const BarTabLine = styled.div`
  border-left: 1px solid ${baseTheme.color.gray5};
  height: 10px;
  margin: auto;
`;

const ServiceRiskTitle = styled(Typography.Title)`
  margin-bottom: 2.5rem !important;
`;

const ServiceRiskInfoContainer = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const ServiceRiskInfoItem = styled.div`
  width: 50%;
`;

const BarScale = (props: { count: number; scale: BarScale }) => {
  const {
    count,
    scale: { good, moderate, critical },
  } = props;
  const ref = useRef(null);
  const { width } = useElementSize(ref);
  const v = getBarTabPosition({ good, moderate, critical, count }) / 100;
  const position = v * width;

  return (
    <Bar ref={ref}>
      <BarSectionBackground
        color={baseTheme.color.orange}
        style={{
          borderRadius: '20px 0 0 20px',
        }}
      >
        <BarSection color={baseTheme.color.green} style={{ borderRadius: '20px' }}>
          <BarSectionText>GOOD</BarSectionText>
        </BarSection>
      </BarSectionBackground>
      <BarSectionBackground color={baseTheme.color.red}>
        <BarSection color={baseTheme.color.orange} style={{ borderRadius: '0 20px 20px 0' }}>
          <BarSectionText>MODERATE</BarSectionText>
        </BarSection>
      </BarSectionBackground>
      <BarSectionBackground
        color={baseTheme.color.red}
        style={{
          borderRadius: '0 20px 20px 0',
        }}
      >
        <BarSectionText>CRITICAL</BarSectionText>
      </BarSectionBackground>
      <BarTabContainer position={position + 40 > width ? position - 40 : position}>
        <BarTabCount>{count.toLocaleString()}</BarTabCount>
        <BarTabLine />
        <BarTab>
          <BarTabInnerCircle />
        </BarTab>
      </BarTabContainer>
    </Bar>
  );
};

interface ServiceRiskData {
  count: number | string;
  title: string;
  description: string;
  recommendation: any;
  scale: BarScale;
}

const ServiceRisk = (props: { data: ServiceRiskData }) => {
  const {
    data: { count, title, description, recommendation, scale },
  } = props;

  return (
    <>
      {typeof count === 'number' ? (
        <>
          <ServiceRiskTitle level={5}>{title}</ServiceRiskTitle>
          <BarScale count={count as number} scale={scale} />
          <ServiceRiskInfoContainer>
            <ServiceRiskInfoItem style={{ marginRight: '.5rem' }}>
              <Typography.Text style={{ fontSize: '14px' }}>{description}</Typography.Text>
            </ServiceRiskInfoItem>
            <ServiceRiskInfoItem style={{ marginLeft: '.5rem' }}>
              {recommendation ? (
                <ClientTaskCard
                  id={recommendation.id}
                  shortId={recommendation.shortId}
                  label={recommendation.label}
                  type={recommendation?.type}
                  showSchedule={false}
                  showUsers={false}
                  size="small"
                />
              ) : (
                <EmptyState styles={{ root: { padding: '1rem' }, message: { margin: '0' } }}>
                  No Recommendation at this time.
                </EmptyState>
              )}
            </ServiceRiskInfoItem>
          </ServiceRiskInfoContainer>
        </>
      ) : (
        <EmptyState>Failed to get {title}.</EmptyState>
      )}
    </>
  );
};

const ServiceRiskWidget = (props: WidgetComponentProps<any, any> /* type these once we get real data */) => {
  const { data, setSubAction } = props;
  const { clientId } = useClientContext();

  useEffect(() => {
    const showZeroRisksWarningEvent = new CustomEvent('zeroRisks:showWarning');
    if (setSubAction)
      setSubAction(
        <Link to={`/${clientId}/services`} style={{ marginRight: '1rem' }} mode="plain">
          View all Health Checks
        </Link>,
      );
    // Present warning if no risks are returned
    if (data?.serviceRisks && Object.keys(data.serviceRisks).length === 0) {
      document.dispatchEvent(showZeroRisksWarningEvent);
    }
  }, []);

  if (!data?.serviceRisks || (data?.serviceRisks && Object.keys(data.serviceRisks).length === 0)) return <EmptyState>No Data Found.</EmptyState>;

  return (
    <>
      {data?.serviceRisks?.map((serviceRisk) => (
        <ServiceRisk data={serviceRisk} />
      ))}
    </>
  );
};

export default ServiceRiskWidget;
