import { gql, useMutation, useQuery } from '@apollo/client';
import { App, Form, Input, Select, UploadFile } from 'antd';
import PartnerClientFormItems from 'components/clients/partner-client-form-items';
import { NuButton, NuCard, NuCardContent } from 'components/nuspire';
import { useState } from 'react';
import { ClientType, IClient } from 'types/index';
import { getCountryCodeKey } from 'utils/phone-number';
import { useClientIdentifier } from 'utils/react-hooks/use-client-identifier';
import {
  DeleteClientLogoMutation,
  UpdateClientFromAdminClientDetailsFormMutation,
  UpdateClientFromAdminClientDetailsFormMutationVariables,
  UpdateClientLogoMutation,
} from '../../../types/graph-codegen/graph-types';
import { ClientLogoUpload, DELETE_CLIENT_LOGO, UPDATE_CLIENT_LOGO } from '../../clients/client-logo-upload';
import { alphabetizeIndustries, useIndustries } from '../../industries';
import { PhoneInputFormValues } from '../../phone-input';
import { ClientTypesSelect } from '../../clients/client-types-select';

export const UPDATE_CLIENT = gql`
  mutation UpdateClientFromAdminClientDetailsForm($input: UpdateClientInput) {
    globalUpdateClient(input: $input) {
      id
      name
      clientIdentifiers {
        type
        value
      }
      oktaGroupId
      apiKeyId
      industry {
        id
        name
      }
      mlOptions {
        anomalyThreshold
      }
    }
  }
`;

const GET_CLIENT_AND_PARENT_USERS = gql`
  query GetClientAndParentUsers($clientId: String, $parentId: String) {
    parent: globalGetUsersByClientId(id: $parentId) {
      id
      firstName
      lastName
      email
    }

    child: globalGetUsersByClientId(id: $clientId) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const anomaliThresholdOptions: { key: string; value: number }[] = [
  {
    key: 'Off',
    value: 1,
  },
  {
    key: 'Low - 1%',
    value: 0.99,
  },
  {
    key: 'Medium - 5%',
    value: 0.95,
  },
  {
    key: 'High - 10%',
    value: 0.9,
  },
  {
    key: 'Aggressive - 25%',
    value: 0.75,
  },
];

export const ClientDetailsForm = ({
  client,
  setIsEditing,
  clientId,
  refetch,
}: {
  client: IClient;
  setIsEditing: Function;
  clientId: string;
  refetch: Function;
}) => {
  const { data, loading: fetchingIndustries } = useIndustries();
  const { data: users, loading: loadingUsers } = useQuery(GET_CLIENT_AND_PARENT_USERS, {
    variables: { clientId, parentId: client?.parentId || '' },
  });
  const clientUsers = users?.child || [];
  const parentUsers = users?.parent || [];
  const allUsers = loadingUsers && !data ? [] : [...clientUsers, ...parentUsers];
  const partnerDetails = client?.partnerDetails;
  const defaultPartnerCountry = partnerDetails?.businessCountry;
  const defaultPartnerPhoneCountryCodeKey = getCountryCodeKey(partnerDetails?.businessPhone, defaultPartnerCountry);

  const disableClientNameInput = !!useClientIdentifier({
    clientIdentifiers: client.clientIdentifiers,
    type: 'serviceNowAccountId',
  });

  const [updateClient, { loading }] = useMutation<
    UpdateClientFromAdminClientDetailsFormMutation,
    UpdateClientFromAdminClientDetailsFormMutationVariables
  >(UPDATE_CLIENT);
  const [updateClientLogo] = useMutation<UpdateClientLogoMutation>(UPDATE_CLIENT_LOGO);
  const [deleteClientLogo] = useMutation<DeleteClientLogoMutation>(DELETE_CLIENT_LOGO);
  const [isPartner, setIsPartner] = useState<boolean>(client?.type === ClientType.partner);
  const [form] = Form.useForm();
  const { message } = App.useApp();

  const onFinish = async (values: any) => {
    const {
      name,
      oktaGroupId,
      ownerId,
      apiKeyId,
      industryId,
      isClientPartner,
      partnerType,
      partnerBusinessType,
      partnerCountry,
      partnerPhone,
      partnerSalesRep,
      partnerWebsite,
      partnerEmail,
      partnerAdditionalInfo,
      anomalyThreshold,
      isLogoDeleted,
      logo,
    } = values;

    let partnerBusinessPhone: string | undefined;
    let partnerBusinessPhoneExt: string | undefined;

    if (isClientPartner) {
      const { countryCodeKey, number, extension } = partnerPhone as PhoneInputFormValues;
      const [, partnerPhoneCountryCode] = countryCodeKey.split('-');
      const businessPhone = number!.replace(/\D/g, '');

      partnerBusinessPhone = `+${partnerPhoneCountryCode} ${businessPhone}`;
      partnerBusinessPhoneExt = extension;
    }

    const { errors: updateClientErrors } = await updateClient({
      variables: {
        input: {
          id: clientId,
          name,
          type: values.type,
          oktaGroupId,
          ownerId,
          apiKeyId,
          industryId,
          partnerDetails: isClientPartner
            ? {
                additionalInfo: partnerAdditionalInfo,
                businessCountry: partnerCountry,
                businessEmail: partnerEmail,
                businessPhone: partnerBusinessPhone ?? '',
                businessPhoneExt: partnerBusinessPhoneExt,
                businessSalesRepresentative: partnerSalesRep,
                businessWebsite: partnerWebsite,
                businessType: partnerBusinessType,
                partnerType,
              }
            : undefined,
          isPartner: isClientPartner,
          mlOptions: anomalyThreshold
            ? {
                anomalyThreshold: Number(anomalyThreshold),
              }
            : undefined,
        },
      },
    });

    if (updateClientErrors) {
      message.error(`Failed to save client: ${updateClientErrors[0]?.message}`);
      setIsEditing(false);
      return;
    }

    const logoFile = logo?.[0];

    if (logoFile) {
      const { errors } = await updateClientLogo({
        variables: {
          input: {
            clientId: client.id,
            logo: (logoFile as UploadFile).originFileObj,
          },
        },
      });

      if (errors) {
        console.error(errors);
        message.error('Failed to save client logo.');
        setIsEditing(false);
        return;
      }
    }

    if (logoFile === undefined && isLogoDeleted) {
      await deleteClientLogo({
        variables: {
          clientId: client.id,
        },
      });
    }

    message.success('Client updated');
    setIsEditing(false);
    refetch();
  };

  const alphabetizedIndustries = alphabetizeIndustries(data?.industries);

  return (
    <NuCard title="Client Details">
      <NuCardContent>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            name="name"
            label="Client Name"
            initialValue={client?.name}
            rules={[{ required: true, message: 'Please input the client name' }]}
            tooltip={disableClientNameInput ? 'Client Name is read only if connected to a ServiceNow account.' : null}
          >
            <Input size="large" disabled={disableClientNameInput} />
          </Form.Item>
          <Form.Item
            name="type"
            label="Client Type"
            initialValue={client?.type}
            rules={[{ required: true, message: 'Please select a client type' }]}
          >
            <ClientTypesSelect fieldName="type" form={form} initialValue={client?.type} />
          </Form.Item>

          <ClientLogoUpload label="Client Logo" name="logo" deleteName="isLogoDeleted" />

          <Form.Item name="apiKeyId" label="Api Key Id" initialValue={client?.apiKeyId} rules={[{ required: false }]}>
            <Input size="large" />
          </Form.Item>
          <Form.Item
            name="oktaGroupId"
            label="Okta Group Id"
            initialValue={client?.oktaGroupId}
            rules={[{ required: true, message: 'Please input an Okta Group Id' }]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            name="ownerId"
            label="Owner Id"
            initialValue={client?.ownerId}
            rules={[{ required: true, message: 'Please input an Owner Id' }]}
          >
            <Select size="large" loading={loading}>
              {allUsers?.map((u) => (
                <Select.Option
                  value={u?.id}
                  key={u?.id}
                >{`${u?.firstName} ${u?.lastName} <${u?.email}>`}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="industryId"
            label="Industry"
            initialValue={client?.industry?.id}
            rules={[{ required: true, message: 'Please input your industry' }]}
          >
            <Select size="large" placeholder="Industry" disabled={fetchingIndustries} loading={fetchingIndustries}>
              {alphabetizedIndustries.map((i) => (
                <Select.Option key={i.id} value={i.id}>
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="anomalyThreshold"
            label="Anomaly Threshold"
            initialValue={client?.mlOptions?.anomalyThreshold ?? 0.95}
            tooltip="The odds of your environment's number of failed logins for any day, compared to your historical averages, must be greater than the selected percentage. Any day that exceeds this threshold will generate an anomaly for the specific event type."
          >
            <Select>
              {anomaliThresholdOptions.map(({ key, value }) => (
                <Select.Option key={key} value={value}>
                  {key}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <PartnerClientFormItems
            initialValues={{
              ...client?.partnerDetails,
            }}
            isPartner={isPartner}
            partnerPhoneCountryCodeKey={defaultPartnerPhoneCountryCodeKey ?? undefined}
            setIsPartner={setIsPartner}
          />
          <NuButton type="primary" htmlType="submit" loading={loading}>
            Save
          </NuButton>
          <NuButton type="primary" style={{ marginLeft: '1rem' }} onClick={() => setIsEditing(false)}>
            Cancel
          </NuButton>
        </Form>
      </NuCardContent>
    </NuCard>
  );
};

export default ClientDetailsForm;
