import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ActionInputProps } from '../action-form-field';
import { Select } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { useClientContext } from 'components/client-context-provider';
import debounce from 'lodash.debounce';
import { useDataExplorerContext } from 'components/data-explorer/data-explorer';

const GET_SELECT_OPTIONS = gql`
  query GetSelectOptions($clientId: String!, $input: String!, $search: String, $dataTypeKey: String) {
    getInputOptions(clientId: $clientId, input: $input, search: $search, dataTypeKey: $dataTypeKey) {
      options {
        key
        label
        value
      }
      next
    }
  }
`;

export function SelectInput(props: ActionInputProps) {
  const { clientId } = useClientContext();
  const { name, value, onChange, field } = props;

  // state for user entered search
  const [queryString, setQueryString] = useState<string | undefined>(undefined);
  // value actually sent over query
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  // data explorer context
  const dataExplorerContext = useDataExplorerContext();
  const dataTypeKey = props.dataTypeKey || dataExplorerContext?.dataTypeSlug;

  const handleQueryString = (val?: string) => {
    if (val?.length) {
      setQueryString(val);
    } else {
      setQueryString(undefined);
    }
  };

  // debounce search
  const debouncedSearch = useCallback(
    debounce((newSearch: string | undefined) => {
      setSearchValue(newSearch);
    }, 600),
    [],
  );

  useEffect(() => {
    debouncedSearch(queryString);
  }, [queryString]);

  // parameters to customize input props;
  const hasGetOptions = field?.hasGetOptions;
  const parameters = field?.parameters ?? {};

  const variables = {
    clientId,
    input: name,
    search: searchValue,
    dataTypeKey,
  };

  // query options
  const { data, loading } = useQuery(GET_SELECT_OPTIONS, {
    variables,
    skip: !hasGetOptions,
  });

  const options = data?.getInputOptions?.options ?? parameters?.options;

  return (
    <Select
      allowClear
      onChange={(value) => {
        onChange(value);
      }}
      value={value}
      searchValue={queryString}
      loading={loading}
      showSearch
      onSearch={handleQueryString}
      options={options}
      style={{
        width: '100%',
      }}
      filterOption={!hasGetOptions}
      {...parameters}
    />
  );
}
