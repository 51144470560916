export default () => (
  <svg width="100px" height="20px" viewBox="0 0 217 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M90.0128 19.4254V32.959H83.872V20.2801C83.872 19.2687 83.872 18.1611 83.7822 16.9423C83.6076 14.4136 82.2259 12.7042 79.2428 12.7042C76.2597 12.7042 73.9052 15.3188 73.9052 21.9946V32.959H67.7644V8.95664H73.9052V12.8357L75.7309 9.9934C77.422 8.77457 79.153 8.32446 81.4277 8.32446C86.456 8.32446 89.3493 10.8532 89.8382 15.0508C89.9768 16.5046 90.0351 17.9651 90.0128 19.4254"
        fill="currentColor"
      />
      <path
        d="M116.137 32.959H109.996V28.9131L108.17 31.9829C106.49 33.1249 104.495 33.6937 102.474 33.6063C97.58 33.6063 94.6418 30.8096 94.1081 27.5374C93.9236 26.0854 93.8486 24.6213 93.8836 23.1578V8.99706H100.024V21.858C100.029 22.9578 100.089 24.0567 100.204 25.1504C100.518 27.6335 101.94 29.1963 104.788 29.1963C107.861 29.1963 109.996 26.6221 109.996 21.2107V8.9566H116.137V32.959Z"
        fill="currentColor"
      />
      <path
        d="M138.525 15.9055H132.379C131.98 13.6954 130.778 12.4311 128.733 12.4311C126.907 12.4311 125.929 13.5133 125.929 15.0053C125.929 16.8057 127.396 17.7565 131.312 18.7477C136.165 20.0121 139.009 21.9946 139.009 26.4198C139.009 31.2446 134.694 33.5912 129.266 33.5912C123.525 33.5912 119.474 31.0625 118.791 25.6511H124.951C125.45 28.2658 127.216 29.4846 129.356 29.4846C131.312 29.4846 132.848 28.6299 132.848 26.8699C132.848 25.0189 131.287 23.9821 127.196 22.9454C122.787 21.8176 119.764 19.8755 119.764 15.7234C119.764 11.1212 123.5 8.32446 129.152 8.32446C134.804 8.32446 138.006 11.5308 138.545 15.9105"
        fill="currentColor"
      />
      <path
        d="M166.296 20.4925C166.296 29.1559 161.043 33.576 154.942 33.576C152.987 33.576 150.582 33.0703 149.38 32.2257L147.824 29.7425V41.7892H141.683V8.95664H147.824V12.6131L149.604 9.9934C150.941 8.91112 153.341 8.32446 155.745 8.32446C162.375 8.32446 166.296 13.4223 166.296 20.5077V20.4925ZM159.931 20.9123C159.931 15.9965 157.616 12.7042 153.944 12.7042C150.562 12.7042 147.804 14.8232 147.804 20.6898V21.8176C147.804 27.2745 150.877 29.2165 153.725 29.2165C157.282 29.2165 159.911 26.4653 159.911 20.9123"
        fill="currentColor"
      />
      <path
        d="M175.639 3.29742C175.639 5.27992 174.257 6.58978 172.257 6.58978C170.256 6.58978 168.875 5.27992 168.875 3.29742C168.875 1.31492 170.251 0 172.257 0C174.262 0 175.639 1.35538 175.639 3.29742ZM169.184 8.93641H175.325V32.9388H169.184V8.93641Z"
        fill="currentColor"
      />
      <path
        d="M193.692 8.5521V13.5589C188.574 13.1998 186.035 15.501 186.035 21.9491V32.9591H179.909V8.95669H186.05V13.3364L187.921 9.99346C189.031 8.97033 190.471 8.39309 191.971 8.37004C192.555 8.3532 193.139 8.41441 193.707 8.5521"
        fill="currentColor"
      />
      <path
        d="M216.893 22.5813H200.072C200.471 27.6387 202.696 29.2166 205.559 29.2166C208.183 29.2166 210.099 27.7246 210.498 25.1707H216.639C215.442 30.7641 210.987 33.6064 205.425 33.6064C198.94 33.6064 193.896 29.4998 193.896 21.0641C193.896 13.7106 198.66 8.33966 205.914 8.33966C213.167 8.33966 216.998 13.9331 216.998 20.4774C217.008 21.1849 216.978 21.8924 216.908 22.5964L216.893 22.5813ZM200.262 18.5505H210.653C210.254 14.7625 208.562 12.7295 205.624 12.7295C202.776 12.7295 200.771 14.5805 200.237 18.5505"
        fill="currentColor"
      />
      <path
        d="M36.9408 19.6226C36.5118 24.1743 35.9182 28.7664 35.5091 33.3383H29.6278C29.9869 29.3429 30.4858 25.3476 30.8948 21.3573C31.0336 20.1066 31.0919 18.848 31.0694 17.5896C30.9647 15.2126 29.7325 13.9128 27.4777 13.9128C24.8239 13.9128 23.2825 16.2089 22.7387 21.5293C22.3347 25.4639 21.8408 29.4036 21.4916 33.3383H15.6552C16.0093 29.3429 16.5082 25.3476 16.9172 21.3573C17.0551 20.1065 17.115 18.8481 17.0968 17.5896C16.9871 15.2126 15.715 13.9128 13.4603 13.9128C10.8064 13.9128 9.30491 16.2089 8.76117 21.5293C8.3571 25.4639 7.86824 29.4036 7.51406 33.3383H1.6377C2.32111 25.6662 3.5333 17.9942 3.59815 10.3221H9.47451C9.47451 11.4904 9.42962 12.6586 9.37475 13.8269C9.97336 12.9064 10.557 11.9809 11.1307 11.0605C12.4227 10.1501 13.9092 9.72026 15.9545 9.72026C19.6609 9.72026 22.0204 11.5763 22.7836 14.0898C23.5751 13.0784 24.35 12.0669 25.1082 11.0554C26.4002 10.1451 27.9317 9.71521 29.972 9.71521C34.277 9.71521 36.7662 12.2439 37.0655 15.425C37.1503 16.8235 37.1086 18.2268 36.9408 19.6176V19.6226ZM64.1028 10.317H57.6677C55.7721 16.4769 53.1332 22.6318 50.624 28.7917C49.4118 22.6318 48.3294 16.4769 46.5086 10.317H39.9438C42.9618 18.3482 44.7128 26.3844 47.1371 34.4155L46.778 35.1488C45.6705 37.4398 44.3286 38.2187 42.3233 38.2187C41.74 38.233 41.1565 38.2043 40.5773 38.1327C40.5125 39.5032 40.4676 40.8738 40.4676 42.2443C41.3414 42.3715 42.2238 42.429 43.1065 42.4163C47.7457 42.4163 50.0903 40.4237 52.9486 34.198C56.655 26.2428 61 18.2824 64.1028 10.3221V10.317Z"
        fill="#3B78E6"
      />
      <path
        d="M36.9408 19.6226C36.5118 24.1743 35.9182 28.7664 35.5091 33.3383H29.6278C29.9869 29.3429 30.4858 25.3476 30.8948 21.3573C31.0336 20.1066 31.0919 18.848 31.0694 17.5896C30.9647 15.2126 29.7325 13.9128 27.4777 13.9128C24.8239 13.9128 23.2825 16.2089 22.7387 21.5293C22.3347 25.4639 21.8408 29.4036 21.4916 33.3383H15.6552C16.0093 29.3429 16.5082 25.3476 16.9172 21.3573C17.0551 20.1065 17.115 18.8481 17.0968 17.5896C16.9871 15.2126 15.715 13.9128 13.4603 13.9128C10.8064 13.9128 9.30491 16.2089 8.76117 21.5293C8.3571 25.4639 7.86824 29.4036 7.51406 33.3383H1.6377C2.32111 25.6662 3.5333 17.9942 3.59815 10.3221H9.47451C9.47451 11.4904 9.42962 12.6586 9.37475 13.8269C9.97336 12.9064 10.557 11.9809 11.1307 11.0605C12.4227 10.1501 13.9092 9.72026 15.9545 9.72026C19.6609 9.72026 22.0204 11.5763 22.7836 14.0898C23.5751 13.0784 24.35 12.0669 25.1082 11.0554C26.4002 10.1451 27.9317 9.71521 29.972 9.71521C34.277 9.71521 36.7662 12.2439 37.0655 15.425C37.1503 16.8235 37.1086 18.2268 36.9408 19.6176V19.6226ZM64.1028 10.317H57.6677C55.7721 16.4769 53.1332 22.6318 50.624 28.7917C49.4118 22.6318 48.3294 16.4769 46.5086 10.317H39.9438C42.9618 18.3482 44.7128 26.3844 47.1371 34.4155L46.778 35.1488C45.6705 37.4398 44.3286 38.2187 42.3233 38.2187C41.74 38.233 41.1565 38.2043 40.5773 38.1327C40.5125 39.5032 40.4676 40.8738 40.4676 42.2443C41.3414 42.3715 42.2238 42.429 43.1065 42.4163C47.7457 42.4163 50.0903 40.4237 52.9486 34.198C56.655 26.2428 61 18.2824 64.1028 10.3221V10.317Z"
        stroke="#3B78E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="217" height="44" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
