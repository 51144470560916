import React from 'react';

export const NUSPIRE_CONTACT_URL = 'https://www.nuspire.com/support/contact-us/';

function ContactNuspireButton(props: { children: React.ReactNode }) {
  const { children } = props;
  return (
    <a href={NUSPIRE_CONTACT_URL} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}

export default ContactNuspireButton;
