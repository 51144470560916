/* eslint-disable camelcase */
import { useEffect } from 'react';
import { ApolloError, gql, useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, message, Modal, Row, Spin, Tooltip, Typography } from 'antd';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  PaperClipOutlined,
  PictureOutlined,
  VideoCameraOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { ICaseActivityStreamData } from './case-detail';
import { NuButton } from 'components/nuspire';
import { useClientContext } from 'components/client-context-provider';
import { downloadBase64ContentAsFile } from 'utils/download-base64-content-as-file';
import dayjs from 'dayjs';
import { ServiceNowActivityStreamEntry } from '../../types/graph-codegen/graph-types';

export const DELETE_ATTACHMENT_MUTATION = gql`
  mutation DeleteCaseAttachment($clientId: String, $caseNumber: String!, $attachmentId: String!) {
    deleteCaseAttachment(clientId: $clientId, caseNumber: $caseNumber, attachmentId: $attachmentId)
  }
`;

export const DOWNLOAD_CASE_ATTACHMENT_QUERY = gql`
  query DownloadCaseAttachment($clientId: String, $caseNumber: String!, $attachmentId: String!) {
    downloadCaseAttachment(clientId: $clientId, caseNumber: $caseNumber, attachmentId: $attachmentId) {
      data
      metadata {
        content_type
        file_name
        sys_id
      }
    }
  }
`;

const { confirm } = Modal;

interface IAttachmentsListProps {
  attachments?: ServiceNowActivityStreamEntry[];
  caseIsOpen: boolean;
  caseNumber: string;
  error?: ApolloError;
  loading: boolean;
  onDelete?: Function;
}

interface IFileTypeIconProps {
  contentType: string;
}

function FileTypeIcon({ contentType }: IFileTypeIconProps) {
  if (contentType === 'application/pdf') {
    return <FilePdfOutlined />;
  }
  if (
    contentType === 'application/msword' ||
    contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    return <FileWordOutlined />;
  }
  if (
    contentType === 'text/csv' ||
    contentType === 'application/vnd.ms-excel' ||
    contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ) {
    return <FileExcelOutlined />;
  }
  if (contentType.startsWith('video/')) {
    return <VideoCameraOutlined />;
  }
  if (contentType.startsWith('image/')) {
    return <PictureOutlined />;
  }
  // More can be added here as needed.

  return <FileTextOutlined />;
}

type DownloadedAttachment = {
  data: string;
  metadata: {
    content_type: string;
    file_name: string;
    sys_id: string;
  };
};

function AttachmentItem({
  caseNumber,
  isCaseOpen,
  item,
  onDelete,
}: {
  caseNumber: string;
  isCaseOpen: boolean;
  item: ServiceNowActivityStreamEntry;
  onDelete?: Function;
}) {
  const { clientId } = useClientContext();
  const [mutate, { loading: deleteLoading, error: deleteError }] = useMutation(DELETE_ATTACHMENT_MUTATION);
  const [downloadAttachment, { loading: downloadLoading, error: downloadError }] = useLazyQuery(
    DOWNLOAD_CASE_ATTACHMENT_QUERY,
    {
      onCompleted: (data) => {
        if (data?.downloadCaseAttachment) {
          downloadAttachmentData(data!.downloadCaseAttachment);
        } else {
          message.error(`There was a problem downloading the attachment.`, 5);
        }
      },
    },
  );

  useEffect(() => {
    if (deleteError) {
      message.error(`There was a problem deleting the attachment.`, 5);
    }
  }, [deleteError]);

  useEffect(() => {
    if (downloadError) {
      message.error(`There was a problem downloading the attachment.`, 5);
    }
  }, [downloadError]);

  const downloadAttachmentData = (data: DownloadedAttachment) => {
    const {
      data: base64Data,
      metadata: { content_type: contentType, file_name: filename },
    } = data;

    downloadBase64ContentAsFile({
      content: base64Data,
      contentType,
      filename,
    });
  };

  const onAttachmentClick = async (attachmentId: string) => {
    downloadAttachment({
      variables: {
        clientId,
        attachmentId,
        caseNumber,
      },
    });
  };

  const deleteAttachment = async (attachmentId: string) => {
    const result = await mutate({
      variables: {
        clientId,
        caseNumber,
        attachmentId,
      },
    });

    if (result.data?.deleteCaseAttachment) {
      if (onDelete) onDelete();
    }
  };

  const onDeleteAttachmentButtonClick = (attachmentId: string) => {
    confirm({
      title: 'Are you sure you want to delete this attachment?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteAttachment(attachmentId);
      },
    });
  };

  return (
    <Row justify="space-between">
      <Tooltip
        title={`Attached on ${dayjs.utc(item.sys_created_on).local().format('MMMM Do YYYY, h:mm:ss a')}`}
        placement="left"
      >
        <Row>
          <NuButton
            type="text"
            onClick={() => item.attachment?.sys_id && onAttachmentClick(item.attachment.sys_id)}
            loading={downloadLoading}
            disabled={downloadLoading}
          >
            <FileTypeIcon contentType={item.attachment?.content_type as string} />
            <span style={{ marginLeft: '1rem' }}>
              {item.attachment?.file_name} ({item.attachment?.size})
            </span>
          </NuButton>
        </Row>
      </Tooltip>

      {isCaseOpen && (
        <Tooltip title="Delete attachment" placement="left">
          <Button
            danger
            type="text"
            onClick={() => item.sys_id && onDeleteAttachmentButtonClick(item.sys_id)}
            disabled={deleteLoading}
            loading={deleteLoading}
            icon={<DeleteOutlined />}
          />
        </Tooltip>
      )}
    </Row>
  );
}

export default function AttachmentsList({
  attachments,
  caseIsOpen,
  caseNumber,
  error,
  loading,
  onDelete,
}: IAttachmentsListProps) {
  useEffect(() => {
    if (error) {
      console.error(error);
      message.error('An error occurred while attempting to load attachments.');
    }
  }, [error]);

  return (
    <>
      <Row justify="space-between">
        <Col xs={23}>
          <Typography.Text type="secondary">Attachments</Typography.Text>
        </Col>
        <Col xs={1}>
          <PaperClipOutlined rotate={135} />
        </Col>
      </Row>
      {loading ? (
        <Row justify="center" style={{ margin: '1rem' }}>
          <Spin />
        </Row>
      ) : (
        attachments?.map((a) => (
          <AttachmentItem key={a.sys_id} caseNumber={caseNumber} isCaseOpen={caseIsOpen} item={a} onDelete={onDelete} />
        ))
      )}
    </>
  );
}
