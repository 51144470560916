import { WidgetComponentProps } from '../../index';
import { DataSource, DataSourceList } from '../../../../cyberx/events/event-widgets/event-widget-definitions/data-sources-widget';
import { useEffect } from 'react';
import { Link, EmptyState } from 'components/nuspire';

export function DataSourceListWidget(props: WidgetComponentProps<any, { dataSources: DataSource[] }>) {
  const { setSubAction, clientId, data } = props;
  const { dataSources } = data;

  useEffect(() => {
    if (setSubAction)
      setSubAction(
        <Link to={`/${clientId}/cyberx/data-sources`} style={{ marginRight: '1rem' }} mode="plain">
          Manage
        </Link>
      );
  }, []);

  if (!dataSources || dataSources.length === 0) {
    return <EmptyState>Could not load Data Sources at this time.</EmptyState>;
  }

  return (
    <DataSourceList dataSources={dataSources} clientId={clientId} />
  );
}

