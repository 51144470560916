import { ClientTaskActivity } from 'types/graph-codegen/graph-types';
import { Form, Input, Space } from 'antd';
import { NuButton } from 'components/nuspire';

export function EditTaskComment(props: {
  activity: ClientTaskActivity;
  onCancel: () => void;
  onComplete: (args: { text: string }) => void;
  loading: boolean;
}) {
  const {
    activity: { text },
    onComplete,
    onCancel,
    loading,
  } = props;
  const [form] = Form.useForm();

  const handleFinish = async (values) => {
    onComplete({ text: values.text });
  };

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      initialValues={{
        text,
      }}
    >
      <Form.Item name="text" rules={[{ required: true, message: 'Please Enter valid Comment.' }]}>
        <Input.TextArea placeholder="Edit comment..." disabled={loading} />
      </Form.Item>

      <Space>
        <Form.Item>
          <NuButton htmlType="submit" type="primary" loading={loading}>
            Update
          </NuButton>
        </Form.Item>
        <Form.Item>
          <NuButton onClick={onCancel} disabled={loading}>
            Cancel
          </NuButton>
        </Form.Item>
      </Space>
    </Form>
  );
}
