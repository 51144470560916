import styled from 'styled-components';
import { Spin as AntSpin } from 'antd';
import { SpinProps } from 'antd/es/spin';
import { LoadingOutlined } from '@ant-design/icons';

export const SpinContainer = styled.div`
  padding: 24px;
  display: grid;

  &&& {
    .ant-spin-text {
      padding-top: 11px;
    }
  }
`;

const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export function Spin(props: SpinProps) {
  return <AntSpin indicator={spinIcon} {...props} />;
}

export default Spin;
