import { ApolloProvider } from '@apollo/client';
import {
  captureConsoleIntegration,
  httpClientIntegration,
  init as initSentry,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react';
import { withoutGraphQLFetch } from 'apollo-link-sentry';
import SubscriptionProvider from 'components/subscription/subscription-context';
import { MyNuspireThemeContextProvider } from 'components/theme';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  RouterProvider,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import 'resources/fonts/HelveticaNeue/style.css';
import 'utils/chartjs-register-components';
import 'utils/dayjs-register-plugins';
import apolloClient from 'utils/graphql/client';
import { version } from '../package.json';
import { FullPageSpin } from './components/nuspire/full-page-spin';
import { config } from './config';
import { router } from './router';

initSentry({
  beforeBreadcrumb: withoutGraphQLFetch((breadcrumb, _hint) => {
    if (breadcrumb.category === 'fetch') {
      const url: string = breadcrumb.data?.url ?? '';

      if (url.includes('/graph')) {
        return null;
      }
    }

    return breadcrumb;
  }),
  debug: config.sentry.debug,
  dsn: config.sentry.dsn,
  enabled: config.sentry.enabled,
  environment: config.environment,
  ignoreErrors: [
    /ResizeObserver/i,
    'signal is aborted without reason',
    /status code 401/i,
    /unauthorized/i,
    'Network request failed',
    'Failed to fetch',
    /aborted/i,
    'Client Id must be passed to connector',
    /not implemented/i,
    'Illegal invocation',
    /Cannot find query for vendor/,
    /Cannot find qury for vendor/,
    /Cannot find event type filter for vendor/,
    /GraphQL network error {}/i,
    /Socket closed/i,
    /Unable to find client with id/i,
    /Client does not have associated ServiceNow Account Id/i,
    /Could not find undefined Client Identifier for Client/i,
    /findDOMNode is deprecated/i,
    /Event Type filter not supported for vendor/i,
  ],
  integrations: [
    replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [window.location.origin, config.apiBaseUrl, config.coreBaseUrl, config.graphPubSubUrl],
    }),
    captureConsoleIntegration({
      levels: ['error'],
    }),
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    httpClientIntegration(),
  ],
  release: `frontend@${config.myNuspireVersion ?? version}`,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

const mixpanelToken = import.meta.env.PROD ? 'c3498b136a0b37c8ee4fccf3205a299d' : '5c2074efba3d64f0bc1b48ccdc1206c6';
mixpanel.init(mixpanelToken);

// Set Super Properties to track for all events.
mixpanel.register({
  platform: 'web',
});

function App() {
  return (
    <MyNuspireThemeContextProvider>
      <ApolloProvider client={apolloClient}>
        <IntercomProvider appId={config.intercomAppId}>
          <SubscriptionProvider>
            <RouterProvider router={router} fallbackElement={<FullPageSpin />} />
          </SubscriptionProvider>
        </IntercomProvider>
      </ApolloProvider>
    </MyNuspireThemeContextProvider>
  );
}

createRoot(document.getElementById('root')!).render(<App />);
