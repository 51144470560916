import { useMutation } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';
import { Alert, Collapse, Form, Result, Typography } from 'antd';
import { NuButton } from 'components/nuspire';
import { NuspireIcon } from 'components/nuspire/nu-icon';
import { FormikInput, P } from 'components/shared-components';
import baseTheme from 'components/theme';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { MAKE_CONNECTION_MUTATION } from '..';
import { ConnectionInstructionsWrapper } from '../index';
import { decodeOAuthState } from '../utils/oauth';
import connector from './sentinelone';

const validationSchema = yup.object().shape({
  apiToken: yup.string().required(),
  baseURL: yup.string().url().required(),
  name: yup.string().required(),
});

interface IAuthFormValues {
  apiToken: string;
  baseURL: string;
  name: string;
  testConnection: boolean;
}

interface ISentinelOneAuthFormProps {
  onSubmit: (args: {
    values: IAuthFormValues;
    setSubmitting: (isSubmitting: boolean) => void;
    setError: (value: string) => void;
  }) => Promise<void>;
}

export function RegenerateWarningAlert(props: { style?: React.CSSProperties }) {
  return (
    <Alert
      message="Warning"
      description={
        <>
          <P>
            Enabling this will revoke your existing API token and create a new one behind-the-scenes{' '}
            <strong>on the first day of the month</strong>. We only recommend enabling this if you don't have any other
            existing integrations that use this API token.
          </P>
        </>
      }
      type="warning"
      showIcon
      {...props}
    />
  );
}

function SentinelOneAuthForm({ onSubmit }: ISentinelOneAuthFormProps) {
  const [regenChecked, setRegenChecked] = useState(false);
  const [error, setError] = useState<string | undefined>();

  return (
    <Formik
      initialValues={{
        apiToken: '',
        baseURL: '',
        name: '',
        regenerateAPIToken: false,
        testConnection: true,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit({ values, setSubmitting, setError });
      }}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, errors, dirty }) => (
        <Form
          layout="vertical"
          onFinish={() => {
            submitForm();
          }}
        >
          {error ? <Alert type="error" message={error} /> : null}

          <FormikInput
            name="name"
            label="API Token Name"
            required
            tooltip="This will be used in myNuspire when referencing this API token."
          />
          <FormikInput name="apiToken" label="API Token" required tooltip="API Token" />
          <FormikInput
            name="baseURL"
            label="Instance URL"
            required
            tooltip="Ex: https://my-instance.sentinelone.net"
            placeholder="Ex: https://my-instance.sentinelone.net"
          />

          <FormikInput checkbox name="regenerateAPIToken" onChange={(e) => setRegenChecked(!!e)}>
            Automatically regenerate API token before it expires
          </FormikInput>
          {regenChecked && <RegenerateWarningAlert style={{ marginBottom: '0.5rem' }} />}

          <FormikInput name="testConnection" checkbox>
            Test credentials before creation
          </FormikInput>
          <Form.Item>
            <NuButton
              type="primary"
              htmlType="submit"
              disabled={!dirty || Object.keys(errors).length > 0 || isSubmitting}
              loading={isSubmitting}
            >
              Submit
            </NuButton>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}

function SentinelOneConnectionInstructions() {
  return (
    <Collapse style={{ marginBottom: '32px' }} defaultActiveKey="instructions">
      <Collapse.Panel key="instructions" header="API Token Instructions">
        <ConnectionInstructionsWrapper>
          <Typography.Paragraph>
            myNuspire leverages SentinelOne REST APIs which are authenticated with API tokens. Follow the instructions
            below in order to generate an API token within the SentinelOne Management Console in order to create a
            Connection.
          </Typography.Paragraph>

          <Typography.Title level={4}>Generating an API Token</Typography.Title>

          <Typography.Paragraph>
            To generate a new API token (and invalidate an old one), login to the SentinelOne Management Console with an
            admin account. Navigate to Settings &gt; Users &gt; New User. Enter the information for the new user, select
            the <strong>Admin</strong> role and click Save. Login to the SentinelOne Management Console with the
            credentials of the new user you just created. Navigate to Settings &gt; Users and then click on the new
            user. Next to the API Token text, click Generate. Click Download if you wish to download the credentials to
            a text file, or click Copy to copy the API token to your clipboard.{' '}
            <strong>The API token expiration date will be displayed underneath the API token.</strong>
          </Typography.Paragraph>

          <Typography.Title level={4}>Ensure the User Has Proper Permissions</Typography.Title>

          <Typography.Paragraph>
            Certain actions in the SentinelOne REST API require specific permissions in order to access them. To
            mitigate threats, the user of the provided API token must have one of the following roles assigned to it:
            <ul>
              <li>Admin</li>
              <li>IR Team</li>
              <li>SOC</li>
            </ul>
            Please make sure that the user has the proper permissions to use the desired actions from the SentinelOne
            REST API.
          </Typography.Paragraph>

          <Typography.Title level={4}>Finding the Instance URL</Typography.Title>

          <Typography.Paragraph>
            In your browser&apos;s navbar, you should see your SentinelOne instance URL. It should look like this:{' '}
            <code>https://my-instance.sentinelone.net</code>. Copy this URL (<strong>only up to the</strong>{' '}
            <code>sentinelone.net</code>
            <strong>!</strong>) to use for the Instance URL field below.
          </Typography.Paragraph>
        </ConnectionInstructionsWrapper>
      </Collapse.Panel>
    </Collapse>
  );
}

const Layout = styled.div``;

const Header = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid ${(p) => p.theme.token.colorBorder};
`;

const Content = styled.div`
  padding: 16px 32px;
`;

export default function SentinelOneRedirect() {
  const location = useLocation();
  const search = location.search;
  const { authState: oktaAuthState } = useOktaAuth();

  const [makeConnection] = useMutation(MAKE_CONNECTION_MUTATION);
  const [success, setSuccess] = useState<boolean>(false);

  const stateString = new URLSearchParams(search).get('state');
  const authState = decodeOAuthState(stateString);

  if (!oktaAuthState?.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Layout>
      <Header>
        <Typography.Title level={2} style={{ marginBottom: 0 }}>
          <NuspireIcon style={{ color: baseTheme.color.primaryBlue, marginRight: '8px' }} />
          Connect to SentinelOne
        </Typography.Title>
      </Header>
      <Content>
        {!success ? (
          <>
            <SentinelOneConnectionInstructions />

            <Typography.Title level={3}>Create Connection</Typography.Title>
            <SentinelOneAuthForm
              onSubmit={async ({ values, setSubmitting, setError }) => {
                const { testConnection, ...payload } = values;
                const { clientId } = authState;

                if (!clientId) {
                  throw new Error('No client id was passed');
                }

                const { data, errors } = await makeConnection({
                  variables: {
                    connectorSlug: connector.slug,
                    payloadJson: JSON.stringify(payload),
                    clientId,
                    testConnection,
                  },
                });

                setSubmitting(false);

                if (errors?.length) {
                  setError(errors[0].message);
                  return;
                }

                const newConnection = data?.makeConnection;

                if (newConnection && !errors) {
                  const newEvent = new CustomEvent(`new-connection-${connector.slug}`, {
                    detail: {
                      connection: newConnection,
                    },
                  });

                  window?.opener?.dispatchEvent(newEvent);

                  setSuccess(true);
                }
              }}
            />
          </>
        ) : (
          <Result status="success" title="Connection was successfully created!" />
        )}
      </Content>
    </Layout>
  );
}
