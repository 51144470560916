import { EmptyState, NuCard, NuCardContent, NuCardTitle } from 'components/nuspire';
import { RowModalProps } from './types';
import { gql, useQuery } from '@apollo/client';
import { QualysDetectionsTableQuery, QualysVulnerabilityModalQuery } from 'types/graph-codegen/graph-types';
import styled from 'styled-components';
import { Space, Table, Typography } from 'antd';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { MetaWidget } from '../meta-widget';
import Line from '../line';
import { RowModal } from './row-modals';
import { Link } from 'react-router-dom';
import { useClientContext } from 'components/client-context-provider';
import { queryDataTypePath } from 'components/reporting-and-analysis/paths';

export function getIdFromRecord(record: any, idDataIndex?: string | string[]): string | undefined {
  if (!idDataIndex) {
    const id = record.id;
    if (id && typeof id === 'string') return id;

    return undefined;
  }

  if (Array.isArray(idDataIndex)) {
    let val: any = record;

    for (const pointer of idDataIndex) {
      val = val[pointer];
    }

    if (typeof val === 'string') return val;
    return undefined;
  }

  const id = record[idDataIndex];

  if (id && typeof id === 'string') return id;

  return id;
}

const QUALYS_VULNERABILITY_MODAL_QUERY = gql`
  query QualysVulnerabilityModal($qid: String!, $clientId: String!) {
    qualysVulnerability(qid: $qid) {
      id
      qid
      consequence
      title
      category
      diagnosis
      solution

      detectionsHistogram(clientId: $clientId) {
        datasets {
          key
          label
          data {
            x
            y
          }
        }
      }
    }
  }
`;

type QualysVulnerability = NonNullable<QualysVulnerabilityModalQuery['qualysVulnerability']>;

const ModalLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding-top: 30px;
`;

const ModalGrid = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 24px 500px;
  grid-template-areas: 'modal-body-col . modal-side-col';
`;
const ModalMainCol = styled.div`
  grid-area: modal-body-col;
  overflow-y: auto;
`;
const ModalSideCol = styled.div`
  grid-area: modal-side-col;
  position: relative;
`;
const SideColOverflow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
const HistogramHeight = styled.div`
  height: 400px;
`;

type QualysHistogramData = QualysVulnerability['detectionsHistogram'];

export function VulnDetectionsHistogram(props: { clientId: string; qualysHistogramData: QualysHistogramData }) {
  const {
    clientId,
    qualysHistogramData: { datasets },
  } = props;

  return (
    <HistogramHeight>
      <NuCard fullHeight>
        <NuCardTitle title="Detection History" />
        <NuCardContent>
          <Line
            clientId={clientId}
            configuration={{
              options: {
                scales: {
                  x: {
                    type: 'time',
                    time: {
                      tooltipFormat: 'MM/DD/YY',
                      unit: 'day',
                    },
                    title: {
                      display: true,
                      text: 'Date',
                    },
                  },
                },
              },
            }}
            data={{
              // @ts-ignore
              datasets,
            }}
          />
        </NuCardContent>
      </NuCard>
    </HistogramHeight>
  );
}

const VULNERABILITY_DETECTIONS_TABLE_QUERY = gql`
  query QualysDetectionsTable($clientId: String!, $qids: [String!], $cursor: String) {
    qualysDetections(clientId: $clientId, cursor: $cursor, qids: $qids, size: 100) {
      items {
        id
        detection {
          qid
          status
          host {
            id
            ip
            os
            dns
          }
        }
      }
      cursor
    }
  }
`;

export function VulnerabilityDetectionsTable(props: { clientId: string; qid: string }) {
  const { clientId, qid } = props;

  const { data, loading, error } = useQuery<QualysDetectionsTableQuery>(VULNERABILITY_DETECTIONS_TABLE_QUERY, {
    variables: {
      clientId,
      qids: [qid],
      // cursor;
    },
  });

  const qualysDetectionsResults = data?.qualysDetections;

  if (qualysDetectionsResults) {
    return (
      <Table
        dataSource={qualysDetectionsResults.items}
        columns={[
          {
            key: 'id',
            title: 'Id',
            dataIndex: ['detection', 'host', 'id'],
          },
          {
            key: 'ip',
            title: 'IP',
            dataIndex: ['detection', 'host', 'ip'],
          },
          {
            key: 'os',
            title: 'OS',
            dataIndex: ['detection', 'host', 'os'],
          },
          {
            key: 'dns',
            title: 'DNS',
            dataIndex: ['detection', 'host', 'dns'],
          },
        ]}
      />
    );
  }

  if (loading)
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );

  return <EmptyState>Failed to query detections</EmptyState>;
}

function buildDataExplorerPath(args: { clientId: string; qid: string }) {
  const { clientId, qid } = args;

  const searchParams = new URLSearchParams();
  searchParams.set('qualys-vuln-id', qid);
  searchParams.set('contains-prioritized-threat-indicator', 'true');

  return `${queryDataTypePath({ clientId, dataType: 'qualys-detection' })}?${searchParams.toString()}`;
}

export function VulnerabilityDetectionsTableCard(props: { clientId: string; qid: string }) {
  return (
    <NuCard>
      <NuCardTitle title="Hosts" actions={<Link to={buildDataExplorerPath(props)}>View in Data Explorer</Link>} />
      <NuCardContent>
        <VulnerabilityDetectionsTable {...props} />
      </NuCardContent>
    </NuCard>
  );
}

export function QualysVulnerabilityDetails(props: { clientId: string; qualysVulnerability: QualysVulnerability }) {
  const { qualysVulnerability, clientId } = props;

  const histogramData = qualysVulnerability.detectionsHistogram;
  return (
    <ModalLayout>
      <ModalGrid>
        <ModalMainCol>
          {/* Vuln Info Card */}
          <Space direction="vertical">
            <NuCard>
              <NuCardContent>
                <MetaWidget
                  clientId={clientId}
                  configuration={null}
                  data={{
                    meta: [
                      {
                        key: 'qid',
                        label: 'QID',
                        value: qualysVulnerability.qid ?? '--',
                      },
                      {
                        key: 'title',
                        label: 'Title',
                        value: qualysVulnerability.title ?? '--',
                      },
                      {
                        key: 'category',
                        label: 'Category',
                        value: qualysVulnerability.category ?? '--',
                      },
                      {
                        key: 'diagnosis',
                        label: 'Diagnosis',
                        value: qualysVulnerability.diagnosis ?? '--',
                      },
                      {
                        key: 'consequence',
                        label: 'Consequence',
                        value: qualysVulnerability.consequence ?? '--',
                      },
                      {
                        key: 'solution',
                        label: 'Solution',
                        value: qualysVulnerability.solution ?? '--',
                      },
                    ],
                  }}
                />
              </NuCardContent>
            </NuCard>

            <VulnerabilityDetectionsTableCard clientId={clientId} qid={qualysVulnerability.qid} />
          </Space>
        </ModalMainCol>
        <ModalSideCol>
          <SideColOverflow>
            <VulnDetectionsHistogram clientId={clientId} qualysHistogramData={histogramData} />
          </SideColOverflow>
        </ModalSideCol>
      </ModalGrid>
    </ModalLayout>
  );
}

export function QualysVulnerabilityDetailsModalComponent(props: RowModalProps): JSX.Element {
  const {
    config,
    record,
    widgetProps: { clientId },
  } = props;
  const { idDataIndex } = config;
  const qid = getIdFromRecord(record, idDataIndex ?? 'qid');

  const { data, loading, error } = useQuery<QualysVulnerabilityModalQuery>(QUALYS_VULNERABILITY_MODAL_QUERY, {
    variables: {
      clientId,
      qid,
    },
    skip: !qid,
  });

  if (!qid) {
    return <EmptyState>Failed to find id from record. Please contact support.</EmptyState>;
  }

  const qualysVulnerability = data?.qualysVulnerability;

  if (qualysVulnerability) {
    return <QualysVulnerabilityDetails clientId={clientId} qualysVulnerability={qualysVulnerability} />;
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  // add graph query
  return <EmptyState>Failed to find Vulnerability</EmptyState>;
}

export const QualysVulnerabilityDetailsModal: RowModal = {
  slug: 'qualys-vulnerability-details',
  component: QualysVulnerabilityDetailsModalComponent,
  title: 'Vulnerability Details',
  width: 1280,
  height: '80vh',
}
