import { useVT } from 'virtualizedtableforantd4';
import { TableProps } from 'antd';
import { StyledTable } from 'components/shared-components';

const scrollConfig = {
  x: true,
  y: 500,
};

interface InfiniteTableProps extends TableProps<any> {
  onFetch?: () => void;
  scroll?: any;
  debug?: boolean;
  lastId?: any;
  offset?: number;
}

function InfiniteTable({
  debug = false,
  dataSource = [],
  scroll = scrollConfig,
  onFetch = () => null,
  lastId,
  offset = 0,
  ...props
}: InfiniteTableProps) {
  const [vt] = useVT(
    () => ({
      onScroll: ({ isEnd }) => {
        if (isEnd) {
          onFetch();
        }
      },
      scroll: { y: scroll.y },
      debug,
      offset,
    }),
    [lastId, dataSource, onFetch],
  );
  return <StyledTable dataSource={dataSource} scroll={scroll} components={vt} pagination={false} {...props} />;
}

export default InfiniteTable;
