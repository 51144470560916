import React from 'react';
import baseTheme from 'components/theme';
import { WidgetComponentProps } from '../..';
import EmptyState from 'components/nuspire/nu-empty-state';
import styled from 'styled-components';
import { Typography, Progress } from 'antd';
import { PRIORITY_SCALE, getPriority } from 'components/client-tasks/client-task-card';
import { Dot } from 'components/widgets/widget/widget-types/program/managed-asset-health';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`;
const ProgressRoot = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  height: 210px;
  overflow: hidden;
`;
const ScoresRoot = styled.div`
  display: flex;
  margin: auto;
`;
const ScoreItemRoot = styled.div`
  margin: 1rem;
  display: flex;
`;
const ScoreDisplayRoot = styled.div`
  position: relative;
  top: -50px;
`;
const Text = styled.div`
  font-size: 14px;
  color: ${baseTheme.color.gray6};
`;
const ScoreLabel = styled.div`
  font-size: 18px;
  margin: auto 0.5rem;
`;

const ScoreItem = (props: { count: number; label?: string; color?: string }) => {
  const { count, label, color } = props;
  return (
    <ScoreItemRoot>
      <Dot color={color} style={{ margin: 'auto' }} />
      <ScoreLabel>
        {count} {label}
      </ScoreLabel>
    </ScoreItemRoot>
  );
};

const getHighestPriorityItems = (counts: { Critical: number; High: number; Medium: number }) => {
  if (counts.Critical > 0) {
    return PRIORITY_SCALE.find((p) => p.label === 'Critical');
  } else if (counts.High > 0) {
    return PRIORITY_SCALE.find((p) => p.label === 'High');
  } else if (counts.Medium > 0) {
    return PRIORITY_SCALE.find((p) => p.label === 'Medium');
  } else {
    return PRIORITY_SCALE.find((p) => p.label === 'Low');
  }
};

type SecurityScoreCardData = {
  tasks: { key: number; doc_count: number }[];
};

const SecurityScoreCardWidget = (props: WidgetComponentProps<any, SecurityScoreCardData>) => {
  const { data } = props;

  if (!data) return <EmptyState>No Data Found.</EmptyState>;

  const counts = {
    Critical: 0,
    High: 0,
    Medium: 0,
  };
  data?.tasks?.forEach((t) => {
    const priority = getPriority(t?.key);
    counts[`${priority.label}`] += t?.doc_count;
  });

  const config = getHighestPriorityItems(counts);

  return (
    <div style={{ display: 'flex', height: '90%' }}>
      <Root>
        <ProgressRoot className="meter-score">
          <Progress
            percent={100}
            strokeColor={config?.color}
            type="dashboard"
            gapDegree={140}
            width={350}
            format={() => {
              return (
                <ScoreDisplayRoot>
                  <Text>You have {counts[`${config?.label}`]}</Text>
                  <Typography.Title style={{ margin: '0', color: config?.color }}>{config?.label}</Typography.Title>
                  <Text>{counts[`${config?.label}`] === 1 ? 'Task' : 'Tasks'} that requires your attention</Text>
                </ScoreDisplayRoot>
              );
            }}
          />
        </ProgressRoot>
        <ScoresRoot>
          <ScoreItem {...PRIORITY_SCALE.find((p) => p.label === 'Critical')} count={counts.Critical} />
          <ScoreItem {...PRIORITY_SCALE.find((p) => p.label === 'High')} count={counts.High} />
          <ScoreItem {...PRIORITY_SCALE.find((p) => p.label === 'Medium')} label="Moderate" count={counts.Medium} />
        </ScoresRoot>
        <Typography.Text style={{ color: baseTheme.color.gray5, textAlign: 'center' }}>
          Your security score card is based on analyzing malicious cyberactivity in your business and on the health of
          your cybersecurity services
        </Typography.Text>
      </Root>
    </div>
  );
};

export default SecurityScoreCardWidget;
