import { gql, useQuery } from '@apollo/client';
import { ActionInputProps } from '../action-form-field';
import { useClientContext } from 'components/client-context-provider';
import { Radio, Space } from 'antd';
import Spin, { SpinContainer } from 'components/nuspire/spin';

const GET_RADIO_OPTIONS = gql`
  query GetRadioOptions($clientId: String!, $input: String!) {
    getInputOptions(clientId: $clientId, input: $input) {
      options {
        key
        label
        value
      }
    }
  }
`;

export function RadioInput(props: ActionInputProps) {
  const { clientId } = useClientContext();
  const { name, value, onChange, field } = props;
  const hasGetOptions = field?.hasGetOptions;
  const parameters = field?.parameters ?? {};

  const variables = {
    clientId,
    input: name,
  };

  // query options
  const { data, loading } = useQuery(GET_RADIO_OPTIONS, { variables, skip: !hasGetOptions });

  const options = data?.getInputOptions?.options ?? parameters?.options;

  const handleChange = (e) => {
    const targetValue = e?.target?.value;

    onChange(targetValue);
  };

  if (!options && loading) {
    return (
      <SpinContainer>
        <Spin tip="Loading Options">
          <div className="content" />
        </Spin>
      </SpinContainer>
    );
  }

  return (
    <Radio.Group onChange={handleChange} value={value}>
      <Space direction="vertical">
        {options?.map((option) => (
          <Radio key={option.key} value={option.value}>
            {option.label}
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );
}
