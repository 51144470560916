import { Form, Input, message, Select } from 'antd';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useClientContext } from 'components/client-context-provider';
import { NuCard, NuCardContent, NuCardTitle } from 'components/nuspire';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import EmptyState from 'components/nuspire/nu-empty-state';
import InlineInput from 'components/nuspire/form/inline-input';
import { useAuthContext } from 'components/auth-context';
import { client as gqlClient } from 'utils/graphql';
import { Access } from 'types';
import {
  ClientNameQuery,
  ClientSettingsClientTreeQuery,
  ClientSettingsQuery,
  Industry,
} from 'types/graph-codegen/graph-types';
import { useField } from 'formik';
import { useState } from 'react';
import InfiniteSelect, { OnFetch, NO_MORE_DATA } from 'components/nuspire/infinite-select/infinite-select';
import { useClientIdentifier } from 'utils/react-hooks/use-client-identifier';

const ORGANIZATION_DETAILS = gql`
  query OrganizationDetails($clientId: String) {
    currentClient {
      id
      clientTree(leafId: $clientId)
    }
  }
`;

const CLIENT_SETTINGS = gql`
  query ClientSettings($clientId: String!) {
    getClientById(id: $clientId) {
      id
      name
      industry {
        id
        name
      }
      parentId
      owner {
        id
        firstName
        lastName
        email
      }
      clientIdentifiers {
        type
        value
      }

      isMultiTenancyEnabled
    }
    industries {
      name
      id
    }
  }
`;

const CLIENT_NAME = gql`
  query ClientName($clientId: String!) {
    getClientById(id: $clientId) {
      id
      name
    }
  }
`;

type Client = NonNullable<ClientSettingsQuery['getClientById']>;

const UPDATE_CLIENT = gql`
  mutation UpdateClient($input: UpdateClientInput) {
    updateClient(input: $input) {
      id
      name
      industry {
        id
        name
      }
      clientIdentifiers {
        type
        value
      }
    }
  }
`;

function ClientNameInlineInput(props: { client: Client; disabled?: boolean }) {
  const {
    client: { id, name },
    disabled = false,
  } = props;
  const [updateClient] = useMutation(UPDATE_CLIENT);

  return (
    <InlineInput
      canEdit={!disabled}
      readView={name}
      initialValues={{ name }}
      editView={({ formikProps }) => (
        <Input
          size="large"
          value={formikProps.values.name}
          onChange={(e) => {
            const newVal = e.target.value;
            formikProps.setFieldValue('name', newVal);
          }}
          onPressEnter={() => formikProps.submitForm()}
          autoFocus
        />
      )}
      onFinish={async ({ values }) => {
        try {
          const input = {
            id,
            name: values.name,
          };

          await updateClient({ variables: { input } });
          message.success('Client updated');
        } catch (err) {
          message.error('Failed to update Client');
        }
      }}
    />
  );
}

function ClientIndustryInlineInput(props: { client: Client; disabled?: boolean; industries: Industry[] }) {
  const {
    client: { id, industry },
    disabled = false,
    industries,
  } = props;
  const [updateClient] = useMutation(UPDATE_CLIENT);
  const fieldName = 'industry';

  return (
    <InlineInput
      canEdit={!disabled}
      readView={industry?.name}
      initialValues={{
        [fieldName]: industry?.id,
      }}
      editView={({ formikProps }) => (
        <Select
          size="large"
          placeholder="Select Industry"
          value={formikProps.values[fieldName]}
          onChange={(val) => formikProps.setFieldValue(fieldName, val)}
          defaultOpen
        >
          {industries.map((i) => (
            <Select.Option key={i.id} value={i.id}>
              {i.name}
            </Select.Option>
          ))}
        </Select>
      )}
      onFinish={async ({ values }) => {
        try {
          const input = {
            id,
            industryId: values[fieldName],
          };

          await updateClient({ variables: { input } });
          message.success('Client updated');
        } catch (err) {
          message.error('Failed to update Client');
        }
      }}
      closeOnOutsideClick={false}
    />
  );
}

export const UPDATE_CLIENT_PARENT_ID = gql`
  mutation UpdateClientParentId($clientId: String!, $parentId: String, $autoEnabledMultiTenancy: Boolean) {
    updateClientParentId(clientId: $clientId, parentId: $parentId, autoEnabledMultiTenancy: $autoEnabledMultiTenancy) {
      id
      parentId
    }
  }
`;

const CLIENT_TREE_QUERY = gql`
  query ClientSettingsClientTree($searchQuery: String, $excludedClientIds: [String!], $next: String) {
    currentUser {
      client {
        clientPath(searchQuery: $searchQuery, excludedClientIds: $excludedClientIds, next: $next) {
          clientTree
          next
          total
        }
      }
    }
  }
`;

function ParentClientEditView(props: { clientId: string }) {
  const { clientId } = props;
  const parentIdField = useField('parentId');
  const { setValue } = parentIdField[2];

  const [nextClientsToken, setNextClientsToken] = useState<string>();
  const [totalClients, setTotalClients] = useState(0);
  const [queryClientTree] = useLazyQuery<ClientSettingsClientTreeQuery>(CLIENT_TREE_QUERY);

  const fetchClients: OnFetch = async (searchQuery, reset) => {
    const currNext = reset ? undefined : nextClientsToken;
    const currTotalClients = reset ? 0 : totalClients;

    const { data } = await queryClientTree({
      variables: {
        excludedClientIds: [clientId],
        next: currNext,
        searchQuery,
      },
    });

    const { clientTree: items, next, total = 0 } = data?.currentUser?.client?.clientPath ?? {};

    if (!items) {
      return Promise.reject(NO_MORE_DATA);
    }

    const numClients = currTotalClients + items.length;
    setTotalClients(numClients);
    setNextClientsToken(next);

    return {
      selectOptions: items
        .filter((v): v is Client => v?.name !== undefined)
        .map((v) => ({
          value: v.id,
          label: v.name!,
        })),
      hasNoMoreData: !next || numClients >= total,
    };
  };

  return (
    <InfiniteSelect
      onFetch={fetchClients}
      onChange={(v) => setValue(v)}
      placeholder="Select Client"
      defaultOpen
      showSearch
      allowClear
    />
  );
}

function ParentClientInlineInput(props: { client: Client; disabled?: boolean }) {
  const { client, disabled = false } = props;
  const { id: clientId, parentId } = client;
  const [moveClient] = useMutation(UPDATE_CLIENT_PARENT_ID);
  const { user } = useAuthContext();
  const clientTree = user?.client?.clientTree ?? null;
  const parentClientQuery = parentId
    ? useQuery<ClientNameQuery>(CLIENT_NAME, {
        variables: {
          clientId: parentId,
        },
      })
    : null;
  const parentClientName = parentClientQuery?.data?.getClientById?.name;

  if (parentId) {
    return (
      <InlineInput
        canEdit={!disabled}
        readView={parentClientName}
        initialValues={{ parentId }}
        editView={() => <ParentClientEditView clientId={clientId} />}
        onFinish={async ({ values: { parentId: newParentId } }) => {
          try {
            const variables = {
              clientId,
              parentId: newParentId,
            };

            await moveClient({
              variables,
            });

            // Refetch current client tree branch to update cache.
            await gqlClient.query({
              query: ORGANIZATION_DETAILS,
              variables: { clientId },
              fetchPolicy: 'network-only',
            });

            message.success('Client has been moved');
          } catch (err) {
            message.error('There was an error moving this Client');
          }
        }}
        closeOnOutsideClick={false}
        useEditButton
      />
    );
  }

  if (parentId && clientTree) {
    return <>The current Client belongs to a parent Client you are not able to see.</>;
  }

  return <>None</>;
}

function ClientSettingsView(props: { client: Client; industries: Industry[] }) {
  const { client, industries } = props;
  const { authorize } = useClientContext();
  const { authorized: canWriteClients } = authorize({
    requiredPermissions: {
      clients: Access.write,
    },
  });

  const disableClientNameInput = !!useClientIdentifier({
    clientIdentifiers: client.clientIdentifiers,
    type: 'serviceNowAccountId',
  });

  return (
    <div style={{ maxWidth: '600px' }}>
      <NuCard>
        <NuCardTitle title="Client Settings" />
        <NuCardContent style={{ paddingBottom: '24px' }}>
          <Form layout="vertical">
            <Form.Item label="Client Name">
              <ClientNameInlineInput client={client} disabled={!canWriteClients || disableClientNameInput} />
            </Form.Item>
            <Form.Item label="Industry">
              <ClientIndustryInlineInput client={client} disabled={!canWriteClients} industries={industries} />
            </Form.Item>
            <Form.Item label="Parent Client">
              <ParentClientInlineInput client={client} disabled={!canWriteClients} />
            </Form.Item>
            <Form.Item label="Multi Tenancy">{client.isMultiTenancyEnabled ? 'Enabled' : 'Disabled'}</Form.Item>
          </Form>
        </NuCardContent>
      </NuCard>
    </div>
  );
}

function ClientSettings() {
  const { clientId } = useClientContext();
  const { data, loading } = useQuery<ClientSettingsQuery>(CLIENT_SETTINGS, { variables: { clientId } });
  const client = data?.getClientById;
  const industries = data?.industries;

  if (client && industries) {
    return <ClientSettingsView client={client} industries={industries ?? []} />;
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin tip="Loading Client Details">
          <div className="content" />
        </Spin>
      </SpinContainer>
    );
  }

  return <EmptyState>Could not find Client.</EmptyState>;
}

export default ClientSettings;
