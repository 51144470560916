import { theme as antdTheme } from 'antd';
import { ReactNode } from 'react';
import { ThemeProvider as BaseThemeProvider } from 'styled-components';
import { ThemeMode, baseTheme } from './theme';

const { useToken } = antdTheme;

interface ThemeProviderProps {
  themeMode: ThemeMode;
  children: ReactNode;
}

/*
 * We want to access antd theme token in styled-components.
 * @see {@link https://ant.design/docs/react/customize-theme}
 * This component facilitates that by:
 *
 * 1. Get the current antd theme token.
 * 2. Augment our theme with the antd token and pass it to ThemeProvider.
 *
 * We also inject the theme mode here to conveniently access it in any styled component.
 */
export function ThemeProvider(props: ThemeProviderProps) {
  const { themeMode, children } = props;
  const { token } = useToken();

  const theme = {
    ...baseTheme,
    token,
    mode: themeMode,
  };

  return <BaseThemeProvider theme={theme}>{children}</BaseThemeProvider>;
}

