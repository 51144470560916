import { Row, Col } from "antd";
import { NuCard, NuCardContent, NuCardTitle, NuButton } from "components/nuspire";
import { SettingsIcon } from 'components/nuspire/nu-icon' 
import { escalationsPath, integrationsPath, settingsPath, automationsPath, assetsPath, necesPath, blockListPath, managedClientsPath, clientUsersPath, clientUserGroupsPath } from 'components/settings/paths'; 
import styled from 'styled-components'; 
import { Link } from 'react-router-dom'; 
import { notificationSettingsRoute } from 'components/notifications/routes';

const SettingsMenuLink = styled(Link)`
  display: block;
  padding: 24px 20px;
  background-color: ${p => p.theme.token.colorBgContainerDisabled}; 
  border-radius: 10px; 
  font-size: 18px;
  color: ${p => p.theme.token.colorText}; 

  &:hover {
    background-color: ${p => p.theme.token.colorPrimaryBg}; 
  }
`

export function SettingsMenuItem(props: {
  title: string; 
  to: string;
}) {
  const { title, to } = props; 

  return (
    <Col lg={8}>
      <SettingsMenuLink to={to}>
        {title}
      </SettingsMenuLink>
    </Col>
  )
}

export default function SettingsSection(props: { clientId: string }) {
  const { clientId } = props; 

  return (
    <NuCard fullHeight paperProps={{ variation: "gray" }}>
      <NuCardTitle 
        title="Settings"
        styles={{title: {
          textTransform: 'none',
          fontWeight: 500
        }}}
        icon={<SettingsIcon />}
        actions={(
          <Link to={settingsPath({ clientId })}>
            <NuButton type="text">
              View in Settings
            </NuButton>
          </Link>
        )}
      />
      <NuCardContent>
        <Row gutter={20} style={{ rowGap: '20px'}}>
          {/* Escalations */}
          <SettingsMenuItem
            title="Escalations"
            to={escalationsPath(props)}
          />

          {/* Notifications */}
          <SettingsMenuItem
            title="Notifications"
            to={notificationSettingsRoute()}
          />

          {/* Integrations */}
          <SettingsMenuItem
            title="Integrations"
            to={integrationsPath(props)}
          />
          
          {/* Automations */}
          <SettingsMenuItem
            title="Automations"
            to={automationsPath(props)}
          />

          <SettingsMenuItem
            title="Assets"
            to={assetsPath(props)}
          />

          <SettingsMenuItem
            title="NECES"
            to={necesPath(props)}
          />

          <SettingsMenuItem
            title="Block List"
            to={blockListPath(props)}
          />

          <SettingsMenuItem
            title="Managed Clients"
            to={managedClientsPath(props)}
          />

          <SettingsMenuItem
            title="Users"
            to={clientUsersPath(props)}
          />

          <SettingsMenuItem
            title="Groups"
            to={clientUserGroupsPath(props)}
          />

        </Row>
      </NuCardContent>
    </NuCard>
  ) 
}