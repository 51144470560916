import { CheckSquareOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Alert, Input, message, Modal } from 'antd';
import MutationButton from 'components/mutation-button';
import { CopyToClipboardIcon, P } from 'components/shared-components';
import { useState } from 'react';
import styled from 'styled-components';
import { UserActionButtonProps } from 'types';
import { formatUserName } from 'utils/users';

const SET_PASSWORD_AND_ACTIVATE_MUTATION = gql`
  mutation SetPasswordAndActivate($userId: String!) {
    setPasswordAndActivate(userId: $userId) {
      result
      tempPassword
    }
  }
`;

function TemporaryPasswordField(props: { tempPassword: string | null }) {
  const { tempPassword } = props;

  if (!tempPassword) return null;

  return (
    <>
      <Input disabled value={tempPassword} addonAfter={<CopyToClipboardIcon copyText={tempPassword} />} />
      <small>The user will be required to set a new password as soon as they log in</small>
    </>
  );
}

type SetPasswordAndActivateData = {
  setPasswordAndActivate?: {
    result: boolean;
    tempPassword: string;
  };
};

export default function SetPasswordAndActivateUserButton(props: UserActionButtonProps) {
  const { onSuccess, user, ...buttonProps } = props;
  const [isTempPasswordModalShowing, setIsTempPasswordModalShowing] = useState<boolean>(false);
  const [tempPassword, setTempPassword] = useState<string | null>(null);

  const userName = formatUserName(user);
  const { id: userId } = user;
  const variables = {
    userId,
  };
  const modalTitle = `Are you sure you want to set a temporary password and activate ${userName}?`;

  const onCompleted = (data: SetPasswordAndActivateData) => {
    if (data.setPasswordAndActivate !== undefined) {
      const {
        setPasswordAndActivate: { result, tempPassword: newTempPassword },
      } = data;

      // Activation was unsuccessful.
      if (!result) {
        onError();
        return;
      }

      // Activation was successful.
      setTempPassword(newTempPassword);
      setIsTempPasswordModalShowing(true);
    }
  };

  const onError = (error?: any) => {
    // Display the error message if we have one.
    const errorMessage = `There was an error activating ${userName}. ${error ? ` Error: ${error.message}` : `.`}`;

    message.error(errorMessage);
  };

  const closeModal = () => {
    setIsTempPasswordModalShowing(false);
    setTempPassword(null);
    if (onSuccess) onSuccess();
  };

  return (
    <>
      <Modal
        title="User Activated"
        open={isTempPasswordModalShowing}
        onOk={closeModal}
        onCancel={closeModal}
        width={600}
      >
        <Alert type="success" message="User's password updated with a temporary password" />
        <P style={{ marginTop: `1rem`, marginBottom: 0 }}>The temporary account password is:</P>
        <TemporaryPasswordField tempPassword={tempPassword} />
      </Modal>

      <MutationButton
        buttonIcon={<CheckSquareOutlined />}
        buttonType="primary"
        modalTitle={modalTitle}
        modalContent="The activation link that was sent to the user's email will no longer be valid. They will have to set a new password when they log in with the new temporary password."
        mutation={SET_PASSWORD_AND_ACTIVATE_MUTATION}
        mutationVariables={variables}
        onCompleted={onCompleted}
        onError={onError}
        tooltipOverlay="Set Password and Activate User"
        {...buttonProps}
      />
    </>
  );
}
