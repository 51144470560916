
export default () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 77 96"
    version="1.1"
    id="svg5"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    >
    <defs
      id="defs2" />
    <g
      id="layer1">
      <image
        width="77"
        height="96"
        preserveAspectRatio="none"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE0AAABgCAYAAABYO/9YAAABhWlDQ1BJQ0MgcHJvZmlsZQAAKJF9
  kT1Iw0AcxV9btSIVUYuKOGSoThZERRy1CkWoEGqFVh1MLv0QmjQkKS6OgmvBwY/FqoOLs64OroIg
  +AHi5uak6CIl/i8ptIjx4Lgf7+497t4B/mqRqWbLGKBqlpGMx4R0ZkUIvqINfehBN/olZuqzopiA
  5/i6h4+vd1Ge5X3uz9GpZE0G+ATiGaYbFvE68dSmpXPeJw6zgqQQnxOPGnRB4keuyy6/cc477OeZ
  YSOVnCMOEwv5JpabmBUMlXiSOKKoGuX70y4rnLc4q8Uyq9+TvzCU1ZaXuE5zCHEsYBEiBMgoYwNF
  WIjSqpFiIkn7MQ//oOMXySWTawOMHPMoQYXk+MH/4He3Zm5i3E0KxYDWF9v+GAaCu0CtYtvfx7Zd
  OwECz8CV1vCXqsD0J+mVhhY5Arq2gYvrhibvAZc7wMCTLhmSIwVo+nM54P2MvikD9N4CHatub/V9
  nD4AKeoqcQMcHAIjecpe83h3e3Nv/56p9/cDQ8JylDq2WIIAAAAGYktHRAD/AP8A/6C9p5MAAAAJ
  cEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQflBhYSExlqSUM+AAAFiklEQVR42u3dXWxTZRgH8P/z
  tnQ9px1jstMtfFz4FSVIDGi8UEKA4BKNxIRAiEHBIIzRUx1qosOQWBJNRAmQUTrAGAyoIeGKxEiC
  GozxQmJESbxAZ+KNge2cZsCkp4zN83ixiW5srKc7pV37/C+X7T3tL8973o/TviOUOKZudQGYke/v
  EzCQcmKzSvmagyh9GrygMTBY6hesICk+WhIcqiSAQt6PRzQmO2LvqiQ0W8/sWwMOFA3NjNhvgHlV
  JaEx8YqYbm8vClpcsxaD8U6F3qaSiai91Fe0OKwoEY6UyWhbjATY5WOvTr96l29opFEawH0VPijO
  uTHYf8gXtLjWswrEL1TJbGJ1QrPWTgqtpb63jog6qmkOxoTUy9FLRsFoof6BDwDMrrK5a4PrBt4v
  CM0MW0sYtKlKJ/0vxiM9zZ7Q1oADUEgNrY+rM8SUHm+1MCZao25tBrCgypeY99qa3ZoX2sYGu5ZB
  b/te7/gjnIjaS03Nfs3PduMRq93Urac3Nti1/pcbkmbtnzNH//iWyarm8HYATb68IVhRRLAaTOsI
  vJhdDoPQDWCPf90IJoA5msODpm6dJfAnwVDo+L4r9Vd8aL4e7rS3ALw+bqW1RXoaAWyb7JUSke4F
  pm59TDouEeMIgVcACBe5OwUBPMGgzoEbA5cSkZ7PTN1e5MMcxGzVMrPHRRtkSgDQCq6saGaeqdlH
  mdVPADYAiJbofhRmpucA/jGu219OEq8mAG4bE60FF3UArYV2w4Ru7SfX/WV49RBAmWSoyvkHU7fT
  Bd/3iLe01PfW3YIW0oIvYWjr2VPMsLUEEZxjIIHy3QlWAG/VHL4Q1+2VBfz99NCNgc0j0JJgxeT9
  XpYEKyh8Q4z7p8g0YhaBTybBnndrmKmtBTztJpod6VkG4J6Ce8AUm7cWugsS0DPN/3VPVs9DMmEC
  5K4DADW8P/6skOTRRV16JgkOqZhmPQagXkjy6ti1mWjmcQWiZaLhIa67XAFYKBIeuijwsAIwXyg8
  9dH5CkBMIDyVWkwBqBMJT4NBVKFyn2UWjU0+NVTYQlYiaIImaIImaBJBEzRBEzRBkwiaoAmaoAma
  RNAETdAETdAkgiZogiZogiYRNEETNEETNImgCZqgCZqgSQTNf7TrwuApfysAfeLgKX0KwO/i4CXc
  pQj4WSDyDwHnFYNOCYWHOmOcUiHn+tcALgtHfvczLcen1V7MzQE4KB55ldmHu9GUDQ6Noe6+gKta
  UYLvsjPxWmLl4VRjdktVZURqDzD8tcWD15qshG63M/jQnX4l6Wzj6SkxADB2pHLGxRErgpRjHAbh
  uPTBMclOpnJGasxllJZ1N4HwrSCNADurwmo9QDwm2m40ZQeyg08B+EKwADDOqLBq3t87s++2C/bD
  mOUYjrGSgB0ABqqUyyXGe0bOuAXs5kAwOkmQCwfvmjX2CSi3E0TLqwjsvMtua2eu6fvb7XKMmwP9
  xm8HcrEVTLwBQKbCsRwQ2g3HePR2YBOi/TvYprONR0PBmgcAPgworkCwz4McnHcgG9uVBE34P1zy
  Pl5ib19dL4AtvhxOWU73etddn56gsgqotFFd1jHOTfkx8X/p9AhWENroAYMVLQfjwpTQInQp4uZ8
  umDR0AAgfc04E8r1LyLwTgD9ZeqVI/DOQPbqgv3Zxq8m25gvR+bsxdwcHCRfqbE+HQxQevgw4LKZ
  oLpB2tr5V+xXv5r09Zyhjv5YF4AnE5q1hgkplPbErG4mfjPtNB71u+GiPMJL5WIn3GmhB0HoAHCn
  t3JcMB0LBWvmp7P+g/leaSNGpaszLgNoi2vWCUXoZOChOzqbzxXvIkV/WJzOxb5rcIyFxNgG4FqR
  LpMFod1yjEcKmUKUdRLh7rtN3frIzzZN3eoYfYp7sfMPY2a+dONOeq4AAAAASUVORK5CYII=
  "
        id="image44"
        x="0"
        y="0" />
    </g>
  </svg>
);
