import { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { mixpanelTrack } from 'utils/mixpanel/mixpanel-track';

import { useLocation } from 'react-router-dom';

// Util hook to time how long components are viewed (rendered)
export function useTimeMixpanelEvent(eventName: string, payload?: any) {
  useEffect(() => {
    const track = () => {
      mixpanelTrack(eventName, payload);
    };

    mixpanel.time_event(eventName);

    // attach incase tab closes.
    window.addEventListener('beforeunload', track);

    return () => {
      // Fired when component is
      window.removeEventListener('beforeunload', track);

      track();
    };
  }, []);
}

export const useMixpanelTimeOnPage = () => {
  const [previousPath, setPreviousPath] = useState<string | undefined>(undefined);

  const location = useLocation();
  // Remove clientId from path
  const path = location.pathname
    .split('/')
    .filter((i, index) => index !== 1)
    .join('/');

  useEffect(() => {
    if (path !== previousPath) {
      // stop timer for previous path and send the event
      if (previousPath) {
        mixpanelTrack(previousPath);
      }

      // start timer for new path
      mixpanel.time_event(path);
      setPreviousPath(path);
    }

    // if the tab is closed this will stop the timer and send the event
    window.addEventListener('beforeunload', () => mixpanelTrack(path));
    return () => {
      // cleanup beforeunload listener
      window.removeEventListener('beforeunload', () => mixpanelTrack(path));
    };
  }, [path]);
};
