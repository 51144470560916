import { useState } from 'react';
import { config } from '../config';
import { NuButton } from './nuspire';

export function DebugErrorButton() {
  const [shouldThrowError, setShouldThrowError] = useState(false);

  if (shouldThrowError) {
    throw new Error(`Are you still there? myNuspire version: ${config.myNuspireVersion}`);
  }

  return (
    <NuButton
      danger
      type="primary"
      onClick={() => {
        setShouldThrowError(true);
      }}
    >
      Trigger Debug Error Event
    </NuButton>
  );
}
