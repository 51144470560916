import { ReactNode } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useAuthContext } from 'components/auth-context';
import { useClientIdentifier } from 'utils/react-hooks/use-client-identifier';

const Root = styled.div`
  flex: 1 0;
  position: relative;
  z-index: 1;
`;
const Overlay = styled.div`
  background-color: ${(p) => p.theme.token.colorBgMask};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.25 ease all;
  z-index: 10;
  .clickable & {
    cursor: pointer;

    &:hover {
      background-color: ${(p) => p.theme.token.colorBgMask};
    }
  }
`;

const OverlayLabel = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 48px;
  text-align: center;
  transition: 0.25 ease all;

  .clickable:hover & {
    color: rgba(255, 255, 255, 1);
  }
`;

export interface NuSampleOverlayProps {
  children: ReactNode;
  onClick?: () => void;
  clickable?: boolean;
}

function SampleOverlay(props: NuSampleOverlayProps) {
  const { children, onClick, clickable: isClickable } = props;
  const clickable = onClick ?? isClickable ?? false;
  const rootClasses = classnames('nu-sample-overlay', { clickable });

  const { user } = useAuthContext();
  const serviceNowAccountId = useClientIdentifier({
    clientIdentifiers: user?.client?.clientIdentifiers,
    type: 'serviceNowAccountId',
  })?.value;

  return (
    <Root onClick={onClick} className={rootClasses}>
      <Overlay>
        <OverlayLabel>{(!serviceNowAccountId && 'Upgrade to Unlocked') || 'Coming Soon'}</OverlayLabel>
      </Overlay>
      {children}
    </Root>
  );
}

export default SampleOverlay;
