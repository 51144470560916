import { gql, useMutation, useQuery } from '@apollo/client';
import { Modal, Form, Select, Space, message, Typography, Table } from 'antd';
import { Features } from 'components/feature-flags';
import { NuButton, NuCardContent, NuCardTitle, NuPaper } from 'components/nuspire';
import { useState, useRef } from 'react';
import type { FormInstance } from 'antd/es/form';
import { FEATURE_FLAG_FRAGMENT } from '../features/feature-fragments';
import { DefaultOptionType } from 'antd/es/select';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { ADD_CLIENT_FEATURE_FLAG } from '../features/add-client-feature-button';
import { ClientFeatureActions } from '../features/feature-client-list';
import { ClientFeatureSwitch } from '../features/client-feature-switch';
import { CenteredContent } from 'components/centered-content';
import { ManagedClientFeatureCheckbox } from '../features/managed-client-feature-checkbox';

const FEATURE_FLAGS_BY_CLIENT = gql`
  query FeatureFlagsByClient($clientId: String!) {
    getClientById(id: $clientId) {
      id
      name
      clientFeatureFlags(includeBeta: false) {
        id
        slug
        clientId
        includeChildClients
        # Info on Feature Flag
        featureFlag {
          id
          stage
          label
          description
          createdAt
        }
      }
    }
  }
`;

const CLIENT_FEATURE_FLAGS = gql`
  ${FEATURE_FLAG_FRAGMENT}

  query ClientFeatureManagementFeatureFlags($clientId: String!) {
    featureFlags {
      ...FeatureFlagFields
    }

    getClientById(id: $clientId) {
      id
      clientFeatureFlags {
        id
        slug
        clientId
      }
    }
  }
`;

export function ClientFeatureSelect(props: { clientId: string }) {
  const { clientId } = props;
  const featureKeys = Object.values(Features);

  const { data, loading } = useQuery(CLIENT_FEATURE_FLAGS, { variables: { clientId } }); // ADD CLIENT ID HERE.

  const serverFeatureFlags = data?.featureFlags;

  const clientFeatureFlags = data?.getClientById?.clientFeatureFlags ?? [];

  const options: DefaultOptionType[] = featureKeys.map((featureKey) => {
    const featureFlag = serverFeatureFlags?.find((i) => i.slug === featureKey);

    const disabled = Boolean(clientFeatureFlags.find((clientFeature) => clientFeature.slug === featureKey));

    return {
      key: featureKey,
      label: featureFlag?.label ?? featureKey,
      value: featureKey,
      disabled,
    };
  });

  return (
    <Form.Item name="featureSlug" label="Feature" rules={[{ required: true }]}>
      <Select placeholder="Select Feature" loading={loading} disabled={loading} options={options} />
    </Form.Item>
  );
}

export function AddFeatureToClientModal(props: { clientId: string; onClose: () => void }) {
  const { clientId, onClose } = props;
  const formRef = useRef<FormInstance>(null);

  const [addClientFeature] = useMutation(ADD_CLIENT_FEATURE_FLAG);

  const handleFinish = async (values: any) => {
    const { featureSlug, includeChildClients } = values;

    try {
      await addClientFeature({
        variables: {
          slug: featureSlug,
          includeChildClients,
          clientId,
        },
        refetchQueries: ['FeatureFlagsByClient'],
      });

      // Show success message;
      message.success(`Successfully added Client to Feature`);

      // reset form
      formRef.current?.resetFields();

      // close modal;
      onClose();
    } catch (err: any) {
      message.error(`Failed to add Feature to Client`);
    }
  };

  return (
    <Modal open title="Add Feature to Client" footer={false} onCancel={onClose}>
      <Form name="add_feature_to_client" layout="vertical" onFinish={handleFinish}>
        {/* Includes the Form.Item */}
        <ClientFeatureSelect clientId={clientId} />

        <Form.Item name="includeChildClients" valuePropName="checked">
          <Checkbox>Include Child Clients?</Checkbox>
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <Space>
            <NuButton type="primary" htmlType="submit">
              Add Feature
            </NuButton>
            <NuButton onClick={onClose}>Cancel</NuButton>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export function AddFeatureToClientButton(props: { clientId: string }) {
  // really should pass clientFeatureFlags so we don't try and add the same feature twice.
  const { clientId } = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <NuButton type="primary" onClick={() => setOpen(true)}>
        Add Feature
      </NuButton>
      {open && <AddFeatureToClientModal clientId={clientId} onClose={() => setOpen(false)} />}
    </>
  );
}

export function ClientFeatureManagement(props: { clientId: string }) {
  const { clientId } = props;

  const { data, loading } = useQuery(FEATURE_FLAGS_BY_CLIENT, { variables: { clientId } });
  const client = data?.getClientById;
  const clientFeatureFlags = client?.clientFeatureFlags;

  return (
    <CenteredContent>
      <Space direction="vertical" style={{ width: '100%' }} size="large">
        <NuPaper>
          <NuCardTitle title="Beta Client" actions={<ClientFeatureSwitch clientId={clientId} slug="beta" />} />
          <NuCardContent>
            <Typography.Paragraph>
              When a Client is declared as a "Beta Client", all users in this client will receive any features in the
              stage "beta".
            </Typography.Paragraph>
          </NuCardContent>
        </NuPaper>
        <NuPaper>
          <NuCardTitle title="Expose specific Features" actions={<AddFeatureToClientButton clientId={clientId} />} />
          <Table
            loading={loading}
            dataSource={clientFeatureFlags}
            columns={[
              {
                key: 'feature',
                title: 'Feature',
                dataIndex: ['featureFlag', 'label'],
                render: (label, r) => label ?? r.slug,
              },
              {
                key: 'stage',
                title: 'Stage',
                dataIndex: ['featureFlag', 'stage'],
                render: (v) => v ?? '--',
              },
              {
                key: 'include-child-clients',
                title: 'Include Child Clients',
                dataIndex: 'includeChildClients',
                render: (_include, record) => (
                  <ManagedClientFeatureCheckbox
                    slug={record.slug}
                    clientId={record.clientId}
                    includeChildClients={record.includeChildClients}
                  />
                ),
                align: 'center',
              },
              {
                key: 'description',
                title: 'Description',
                dataIndex: ['featureFlag', 'description'],
                render: (v) => v ?? '--',
              },
              {
                key: 'actions',
                dataIndex: 'slug',
                render: (slug) => <ClientFeatureActions client={client} slug={slug} />,
              },
            ]}
          />
        </NuPaper>
      </Space>
    </CenteredContent>
  );
}
