import { Spin, Typography } from 'antd';
import { CopyToClipboardIcon } from './shared-components';

interface DetailsItemProps {
  title: string;
  value: string | React.ReactNode;
  url?: string;
  showCopy?: boolean;
  altCopyText?: string;
  showLoading?: boolean;
  trailing?: string | React.ReactNode;
}

export function DetailsItem({
  title,
  value,
  url = '',
  showCopy = false,
  altCopyText,
  showLoading = false,
  trailing,
}: DetailsItemProps) {
  return (
    <div style={{ display: 'flex' }}>
      <p style={{ margin: 0, marginBottom: '1rem' }}>
        <Typography.Text>
          <strong>{title}</strong>:{' '}
          {url ? (
            <a
              href={url}
              target="_blank"
              rel="noreferrer noopener"
              style={{ marginRight: showCopy ? '0.5rem' : undefined }}
            >
              {value}
            </a>
          ) : (
            <>
              <span style={{ marginRight: showCopy || trailing ? '0.5rem' : undefined }}>{value}</span>
              <span style={{ marginRight: showCopy ? '0.5rem' : undefined }}>{trailing}</span>
            </>
          )}
          {showCopy && value && <CopyToClipboardIcon copyText={altCopyText ?? (value as string)} />}
        </Typography.Text>
      </p>
      {showLoading && <Spin style={{ marginLeft: '0.5rem' }} size="small" />}
    </div>
  );
}
