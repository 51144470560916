import { useEffect, useState } from 'react';
import { App, message, Modal, Table, Tooltip } from 'antd';
import { CloudDownloadOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Application, IClient } from 'types';
import { NuButton } from 'components/nuspire';
import { refetchClientAppsEvent, SAML2AppInput, SAMLAppForm } from './create-app-modal';
import { CopyToClipboardIcon, P } from 'components/shared-components';
import { downloadBase64ContentAsFile } from 'utils/download-base64-content-as-file';

const CLIENT_APPS = gql`
  query ClientApplications($clientId: String) {
    clientApplications(clientId: $clientId)
  }
`;

const UPDATE_SAML_APP_FOR_CLIENT = gql`
  mutation UpdateSAML2ApplicationForClient($appId: String!, $input: SAML2AppInput!) {
    updateSAML2ApplicationForClient(appId: $appId, input: $input)
  }
`;

const REMOVE_CLIENT_FROM_APP = gql`
  mutation RemoveClientFromApp($appId: String!, $clientId: String!) {
    removeClientFromApplication(appId: $appId, clientId: $clientId)
  }
`;

const DOWNLOAD_SAML_APP_CERTIFICATE = gql`
  query DownloadSAMLAppCertificate($appId: String!) {
    downloadSAMLApplicationCertificate(appId: $appId) {
      application
      certificate
    }
  }
`;

type CertificateData = {
  application: Application;
  certificate: string;
};

type EditApplicationModalProps = {
  application?: Application;
  client?: IClient;
  onClose: Function;
  refetch: Function;
  visible: boolean;
};

export const isSAMLAppFunc = (app?: Application) => app?.signOnMode === 'SAML_2_0';

function EditApplicationModal(props: EditApplicationModalProps) {
  const { application, client, onClose, refetch, visible } = props;
  const [updateSAMLApp, { loading }] = useMutation(UPDATE_SAML_APP_FOR_CLIENT);

  const isSAMLApp = isSAMLAppFunc(application);

  return (
    <Modal
      title="Edit Application"
      open={visible}
      onOk={() => onClose()}
      onCancel={() => onClose()}
      footer={null}
      width={600}
    >
      {isSAMLApp ? (
        <SAMLAppForm
          client={client}
          initialValues={{
            audience: application!.settings.signOn?.audience,
            displayName: application!.label,
            samlEndpoint: application!.settings.signOn?.destination,
            usersList: [] as string[],
          }}
          isEditingApp
          loading={loading}
          onSubmit={async (values: any, { setSubmitting }) => {
            if (!client || !client.id) {
              setSubmitting(false);
              message.error('Client not found. Unable to update SAML application.');
              return;
            }

            if (isSAMLApp) {
              const {
                audience,
                cxpEventDescription,
                displayName,
                entitlement,
                samlEndpoint,
                sendCxpEvent,
                updateEntitlement,
                usersList,
                usersSelectedValue,
              } = values;

              const data: SAML2AppInput = {
                input: {
                  assignUsers: usersSelectedValue,
                  audience,
                  clientId: client.id,
                  cxpEventDescription,
                  displayName,
                  entitlement,
                  samlEndpoint,
                  sendCxpEvent,
                  updateEntitlement,
                  usersToAssign: usersSelectedValue === 'select' ? usersList : [],
                },
              };

              try {
                await updateSAMLApp({
                  variables: {
                    ...data,
                    appId: application!.id,
                  },
                });

                message.success('SAML application updated successfully!');
                onClose();
                refetch();
              } catch (err: any) {
                message.error(`There was an error editing the SAML app: ${err.message}`);
              } finally {
                setSubmitting(false);
              }
            }
          }}
        />
      ) : (
        <P>Application not found.</P>
      )}
    </Modal>
  );
}

type AppSSOLinkProps = {
  href?: string;
};

function AppSSOLink(props: AppSSOLinkProps) {
  const { href } = props;

  if (!href) return null;

  return (
    <>
      <a href={href} target="_blank" rel="noreferrer noopener" style={{ marginRight: '0.5rem' }}>
        {href}
      </a>
      <CopyToClipboardIcon copyText={href} />
    </>
  );
}

type DownloadSAMLAppCertificateButtonProps = {
  app: Application;
};

function DownloadSAMLAppCertificateButton(props: DownloadSAMLAppCertificateButtonProps) {
  const { app } = props;
  const [downloadCertificate, { loading: certLoading, error: certError }] = useLazyQuery(
    DOWNLOAD_SAML_APP_CERTIFICATE,
    {
      onCompleted: (data) => {
        if (data?.downloadSAMLApplicationCertificate) {
          downloadSAMLAppCertificate(data!.downloadSAMLApplicationCertificate);
        } else {
          message.error('There was a problem downloading the SAML certificate.');
        }
      },
    },
  );

  const downloadSAMLAppCertificate = (data: CertificateData) => {
    const { application, certificate } = data;
    const base64Data = window.btoa(certificate);

    downloadBase64ContentAsFile({
      content: base64Data,
      contentType: 'text/plain',
      filename: `${application.label}.cert`,
    });
  };

  if (certError) {
    message.error(`There was an error downloading the SAML certificate: ${certError.message}`);
  }

  return (
    <Tooltip overlay="Download SAML Certificate">
      <NuButton
        type="default"
        icon={<CloudDownloadOutlined />}
        style={{ marginRight: '1rem' }}
        disabled={certLoading}
        loading={certLoading}
        onClick={() =>
          downloadCertificate({
            variables: {
              appId: app.id,
            },
          })
        }
      />
    </Tooltip>
  );
}

type ApplicationTableProps = {
  applications: Application[];
  loading: boolean;
  onEdit: Function;
  onRemove: Function;
};

function ApplicationsTable(props: ApplicationTableProps) {
  const { applications, loading, onEdit, onRemove } = props;

  const columns = [
    {
      title: 'Application ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Type',
      dataIndex: 'signOnMode',
      key: 'signOnMode',
    },
    {
      title: 'SSO Link',
      dataIndex: '',
      key: 'ssoLink',
      render: (app: Application) => {
        const { _links: links } = app;

        let href: string | undefined;
        if (links && links.appLinks && links.appLinks.length) {
          const [ssoLink] = links.appLinks;
          href = ssoLink.href;
        }

        return <AppSSOLink href={href} />;
      },
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (app: Application) => (
        <>
          <Tooltip overlay="Edit Application">
            <NuButton
              type="primary"
              icon={<EditOutlined />}
              style={{ marginRight: '1rem' }}
              onClick={() => onEdit(app)}
            />
          </Tooltip>
          {isSAMLAppFunc(app) && <DownloadSAMLAppCertificateButton app={app} />}
          <Tooltip overlay="Remove from Application">
            <NuButton danger icon={<DeleteOutlined />} onClick={() => onRemove(app)} />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={applications}
      rowKey="id"
      loading={loading}
      pagination={false}
      size="small"
      bordered
    />
  );
}

export type ClientApplicationsProps = {
  client?: IClient;
};

export default function ClientApplications(props: ClientApplicationsProps) {
  const { client } = props;
  const { data, loading, error, refetch } = useQuery(CLIENT_APPS, {
    variables: {
      clientId: client?.id,
    },
    skip: !client?.id,
  });
  const [removeClientFromApp, { loading: removeLoading, error: removeError }] = useMutation(REMOVE_CLIENT_FROM_APP);
  const [isEditingApp, setIsEditingApp] = useState<boolean>(false);
  const [editingApp, setEditingApp] = useState<Application | undefined>();
  const { modal } = App.useApp();

  if (error) {
    message.error(`There was an error fetching the client's applications: ${error.message}`);
  }
  if (removeError) {
    message.error(`There was an error removing the client from the application: ${removeError.message}`);
  }

  useEffect(() => {
    window.addEventListener(refetchClientAppsEvent.type, () => {
      refetch();
    });

    return () => {
      window.removeEventListener(refetchClientAppsEvent.type, () => {
        refetch();
      });
    };
  }, []);

  const apps = data?.clientApplications || [];

  return (
    <>
      <ApplicationsTable
        applications={apps}
        loading={loading || removeLoading}
        onEdit={(app: Application) => {
          setEditingApp(app);
          setIsEditingApp(true);
        }}
        onRemove={(app: Application) => {
          modal.confirm({
            title: 'Are you sure you want to remove this client from the application?',
            icon: <ExclamationCircleOutlined />,
            content: (
              <>
                <P>This action cannot be undone</P>
                <P>
                  <strong>
                    You cannot delete the application entirely from here. You must go into Okta to fully delete the
                    application.
                  </strong>
                </P>
              </>
            ),
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
              if (!client || !client.id) {
                message.error('Client not found. Unable to remove client from application.');
                return;
              }

              await removeClientFromApp({
                variables: {
                  appId: app.id,
                  clientId: client.id,
                },
              });

              refetch();
            },
            width: 600,
          });
        }}
      />
      <EditApplicationModal
        application={editingApp}
        client={client}
        visible={isEditingApp}
        onClose={() => {
          setIsEditingApp(false);
          setEditingApp(undefined);
        }}
        refetch={refetch}
      />
    </>
  );
}
