import { NuButton } from 'components/nuspire';
import { useState } from 'react';
import { IDashboard } from '../dashboard';
import * as NuIcons from 'components/nuspire/nu-icon';
import { Modal, Form as AntForm } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import { FormikInput } from 'components/shared-components';

const validationSchema = yup.object().shape({
  name: yup.string().required(),
});

interface CreateDashboardFields {
  name: string;
}

function AddDashboardForm(props: {
  onSubmit: (createDashboardFields: CreateDashboardFields, helpers: FormikHelpers<CreateDashboardFields>) => void;
}) {
  const { onSubmit } = props;

  return (
    <Formik
      initialValues={{ name: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, helpers) => {
        onSubmit(values, helpers);
      }}
    >
      {({ submitForm, isSubmitting, errors, dirty }) => (
        <AntForm layout="vertical" onFinish={() => submitForm()}>
          <FormikInput name="name" label="Name" required tooltip="You can always change this later." />
          <AntForm.Item>
            <NuButton
              type="primary"
              htmlType="submit"
              disabled={!dirty || Object.keys(errors).length > 0 || isSubmitting}
              loading={isSubmitting}
            >
              Create
            </NuButton>
          </AntForm.Item>
        </AntForm>
      )}
    </Formik>
  );
}

const CREATE_DASHBOARD = gql`
  mutation CreateDashbaord($input: CreateDashboardInput!) {
    createDashboard(input: $input) {
      id
      shortId
      name
    }
  }
`;

interface AddDashboardButtonProps {
  clientId: string | null;
  onNewDashboard: (newDashboard: IDashboard) => void;
}

export function AddDashboardModal(props: {
  clientId: string | null;
  visible: boolean;
  onClose: () => void;
  onNewDashboard: (newDashboard: IDashboard) => void;
}) {
  const { visible, onClose, onNewDashboard, clientId } = props;
  const [createDashboard] = useMutation(CREATE_DASHBOARD);

  return (
    <Modal onCancel={() => onClose()} open={visible} title="Add Dashboard" footer={false}>
      <AddDashboardForm
        onSubmit={async (formInput, helpers) => {
          try {
            const input = {
              ...formInput,
              clientId,
            };
            const results = await createDashboard({ variables: { input } });
            const newDashboard = results.data?.createDashboard;

            if (!newDashboard) {
              throw new Error('Failed to create dashboard');
            }
            helpers.setSubmitting(false);
            onNewDashboard(newDashboard);

            // close modal;
            onClose();
          } catch (err) {
            console.error(err);
          }
        }}
      />
    </Modal>
  );
}

function AddDashboardButton(props: AddDashboardButtonProps) {
  const { onNewDashboard, clientId } = props;

  /**
   * State to manage add modal being open.
   */
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const handleCloseModal = () => setModalOpen(false);
  const handleOpenModal = () => setModalOpen(true);

  return (
    <>
      <NuButton type="text" size="large" icon={<NuIcons.Plus />} onClick={handleOpenModal}>
        New Dashboard
      </NuButton>
      {modalOpen && (
        <AddDashboardModal visible onClose={handleCloseModal} onNewDashboard={onNewDashboard} clientId={clientId} />
      )}
    </>
  );
}

export default AddDashboardButton;
